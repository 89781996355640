import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid, InputBase, Chip, Tooltip, Typography, IconButton, InputAdornment} from '@material-ui/core';
import { white } from 'src/components/colors';
import { DownArrowIcon, UpArrow, ViewIcon } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { DevicePopup } from '../step2/DevicePopup';

export function DropDown(props) {
  const classes = useStyles();
  const [isDropDown, setIsDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [selectedValues, setSelectedValues] = useState(props.value || []);
  const [value,setValue] = useState("")

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() =>{
    console.log(props.value)
    if(props.isSingle){
      setValue(props.value)
    }
  },[props.value])

  const handleClick = () => {
    if (props.onBatchDropClick && props.type === 'batchNumber') {
      props.onBatchDropClick ();
    }
    if (props.onMainDropClick && props.type === 'mainTestCase') {
      props.onMainDropClick ();
    }
    if (props.onSubsDropClick && props.type === 'subTestCase') {
      props.onSubsDropClick ();
    }
    if (props.onTestTypeDropClick && props.type === 'testingMethod') {
      props.onTestTypeDropClick ();
    }
    handleDropDown();
  };

  const handleEyeClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleValue = (e) => {
    if (props.isSingle) {
      setValue(e)
      if (props.type === 'streamingMethod') {
        handleStreamingMethod(e);
      } else if (props.type === 'batchNumber') {
        handleBatchNumber(e);
      } else if (props.type === 'successCriteria') {
        handleSuccessCriteria(e);
      } else if (props.type === 'mainTestCase') {
        handleSelectMainTestCase(e);
      } else if (props.type === 'subTestCase') {
        handleSelectSubTestCase(e);
      } else if (props.type === 'testingMethod') {
        handleTestingMethod(e);
      } else if (props.type === 'repeatPeriod') {
        handleRepeatPeriod(e);
      } else if (props.type === 'scheduleTime') {
        handleScheduleTime(e);
      } else if(props.type === 'shedulePeriod'){
        handleShedulePeriod(e);
      }else if (props.type === 'subSuccessCriteria') {
        handleSubSuccessCriteria(e);
      }else if(props.type === 'label') {
        handleLabel(e);
      }else {
        props.onClick(e);
      }
      setIsDropDown(false);
    } else {
      setValue("")
      const updatedValues = selectedValues.includes(e)
        ? selectedValues.filter(val => val !== e)
        : [...selectedValues, e];
      setSelectedValues(updatedValues);
      props.setSelectOption(updatedValues);
      const filterOptions = props.options.filter(option => !updatedValues.includes(option))
      setSelectOptions(filterOptions);
      setIsDropDown(true);
    }
  };

  const handleStreamingMethod = (e) => {
    props.setSelectOption(e);
  };
  const handleBatchNumber = (e) => {
    props.setSelectOption(e.toString());
  };
  const handleSelectMainTestCase = (e) => {
    props.setSelectOption(e);
  };
  const handleSuccessCriteria = (e) => {
    props.setSelectOption(e);
  };
  const handleSelectSubTestCase = (e) => {
    props.setSelectOption(e);
  };
  const handleTestingMethod = (e) => {
    props.setSelectOption(e);
  };
  const handleRepeatPeriod = (e) => {
    props.setSelectOption(e);
  };
  const handleScheduleTime= (e) => {
    props.setSelectOption(e);
  };

  const handleSubSuccessCriteria = (e) => {
    props.setSelectOption(e);
  };
  const handleLabel = (e) => {
    props.setSelectOption(e);
  };
  const handleBatch = (e) =>{
    props.setSelectOption(e);
  }
  const handleShedulePeriod = (e) => {
    props.setSelectOption(e);
  }

  useEffect(() =>{
    if (!props.disabled) {
      setSelectOptions(props.options);
    }
  },[props.options])

  const handleDropDown = () => {
    setSelectOptions(props.options);
    if (!props.disabled) {
      setIsDropDown(!isDropDown);
    }
  };

  const handleRemove = (value) => {
    const updatedValues = selectedValues.filter(val => val !== value);
    setSelectedValues(updatedValues);
    props.setSelectOption(updatedValues);
    setSelectOptions((prev) => ([
      ...prev,
      value
    ]));
  };

  const handleFilterOption = (event) => {
    const value = event.target.value.toLowerCase();
    setValue(value)
    let filteredOption = []
    if(props.isSingle){
       filteredOption = props.options.filter(option => option.toLowerCase().includes(value))
    }else{
      const filterValues = props.options.filter(option => !selectedValues.includes(option))
      filteredOption = filterValues.filter(option => option.toLowerCase().includes(value))
    }
    setSelectOptions(filteredOption)
  };  
  
  return (
    <Grid ref={dropdownRef} container style={{ position: 'relative' }}>
      <Grid container 
        className={classes.inputBaseStyle} 
        style={{ backgroundColor: props.type === "successCriteria" || props.type === "subSuccessCriteria" || props.type === 'shedulePeriod' ? white : undefined ,
                 borderColor: props.type === "repeatPeriod" ? "#A9A9A9" : undefined
        }} 
        onClick={handleClick}
      >
        <Grid item xs={11} className={classes.inputContainer} 
          style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "5px",minWidth:'100px' }}
        >
          {props.isSingle ? (
            <InputBase
              placeholder={props.emptyTag}
              style={{ border: 'none', cursor: 'pointer', marginLeft: '10px', fontSize: '13px' }}
              value={value || ''}
              fullWidth
              onChange={handleFilterOption}
            />
          ) : (
            <>
              {selectedValues.length > 0 &&
                  selectedValues.map((chipValue, index) => (
                      <Chip
                          key={index}
                          label={chipValue}
                          onDelete={() => handleRemove(chipValue)}
                          className={classes.chip}
                          style={{margin:'0px'}}
                      />
                  ))}

              <InputBase
                  placeholder={props.emptyTag}
                  style={{
                      border: "none",
                      fontSize: "13px",
                      flex: 1, // Allows input to take remaining space
                      minWidth: "100px", // Ensures input remains visible
                      marginLeft: '10px'
                  }}
                  value={value || ""}
                  fullWidth
                  onChange={handleFilterOption}
              />
          </>
          )}
        </Grid>
        <Grid item xs={1} className={classes.iconContainer}>
          {isDropDown ? <UpArrow /> : <DownArrowIcon />}
        </Grid>
      </Grid>

      {isDropDown && !props.disabled && (
        <div className={classes.dropdown}>
          {selectOptions.map((option, index) => (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className={classes.option}
              key={index}
              onClick={() => handleValue(option)}
              onMouseEnter={() => handleMouseEnter(option)}
              onMouseLeave={handleMouseLeave}
            >
              <Grid item xs={props.type === "batchNumber" || props.icon ? 9 : 12} >
                {option}

              </Grid>
              {props.icon && 
                <Grid item onClick={()=>props.iconClick(index)}>
                    {props.icon}
                </Grid>
              }

              {props.type === 'batchNumber' && (
                <Tooltip title='View Batch Details'>
                  <Grid
                    item
                    xs={3}
                    onClick={handleEyeClick}
                    container
                    justifyContent="flex-end"
                  >
                    <ViewIcon color = '#707070'/> 
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          ))}
        </div>
      )}


      {/* View Devices in Batch*/}
      {open && (
        <DevicePopup
          open={open}
          close={handleClose}
          batchNumber={props.value}
        />
      )}
      
    </Grid>
  );
}