import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography,Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import useStyles from '../../assets/styles';


export const DeletePopup = ({title,content,onClose,onDelete,open}) =>{
    const classes = useStyles()
    return(
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014',marginBottom:'15px' }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography style={{fontWeight:'bold'}} className={classes.typo}>{title}</Typography>
                    <IconButton onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container >
                    <Typography>{content}</Typography>
                    <Grid container justifyContent='flex-end' style={{ margin: '15px 0px' }}>
                        <Button variant='outlined' style={{ marginRight: '15px', color: '#7F7C7C' }} onClick={onClose}>No</Button>
                        <Button variant='contained' style={{ backgroundColor: 'red', color: 'white' }} onClick={onDelete}> Yes</Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}