import * as React from 'react'
import { useEffect, useState } from 'react';
import { Chart} from 'chart.js';
import useStyles from '../../assets/styles';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Typography } from 'antd';

export const LineChart = ({ graph_details,isExpand,index }) => {

    useEffect(() => {
      const canvas = isExpand ? document.getElementById(`myChartExpand-${index}`) as HTMLCanvasElement : document.getElementById(`myChart-${index}`) as HTMLCanvasElement;
      if (!canvas) return;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
  
        if (ctx) {
            new Chart(ctx, {
            type: 'line',
            data: {
                labels: graph_details.creation_dates,
              datasets: [
                {
                    label: graph_details.title,
                    data: graph_details.values,
                    borderColor: '#5E5C5C',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    fill: false,
                    tension: 0.1,
                    pointBackgroundColor: '#2A7BEC',
                    pointBorderColor: '#2A7BEC',
                    pointBorderWidth: 1,
                    pointRadius: 3,
                },
              ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: graph_details.x_label,
                    },
                  }],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 20
                      },
                      scaleLabel: {
                        display: true,
                        labelString: graph_details.y_label,
                      },
                    },
                  ],
                },
                title: {
                  display: true,
                  text: graph_details.title,
                },
            },  
          });
        }
    }, [graph_details]);
  
    return (
      <div style={{ height: '500px',width :isExpand && graph_details.creation_dates.length > 100 ? '3500px' :'auto', overflow : 'auto' }}>
        <canvas id={isExpand ? `myChartExpand-${index}` :  `myChart-${index}`} style={{display: "block" , height: "500px" , width: "100%"}} className="chartjs-render-monitor"></canvas>
      </div>
    )
};


