import { Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import { Link } from 'react-router-dom';
import { Arrow, NonSuccessCriteriaIcon } from 'src/components/Icons'

export const BackToHomeComponent = ({icon,title}) => {
    return(
        <Grid container style={{display: "flex",margin:'50px 0px',alignItems:'center',backgroundColor:'white',padding:'15px',width:'fit-content'}}>
                    <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                        <NonSuccessCriteriaIcon color={"#324054"}/>
                        <Link to={'/TestAutomation'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                            <Typography style={{color: '#858DAA'}}>Setup Device Pulse</Typography>
                        </Link>
                    </Grid>
                    <Arrow/>
                    <Grid item style={{color:'#2A7BEC',display:'flex',alignItems:'center'}}>
                        {icon}
                        <Typography style={{marginLeft:'10px'}}>{title}</Typography>
                    </Grid>      
                </Grid>
    )
}