import * as React from "react";
import {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  topic: {
    color: "#606060",
    borderBottomColor: "white",
    backgroundColor: "white",
    height: "20px",
  },
  topicText: {
    fontSize: "14px",
    marginTop: "20px",
  },
  content: {
    borderColor: "white",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export const dataRestructure = (testSummaryData, mainTestTitle) => {
  return testSummaryData.map(deviceData => {
    const result = {};
    for (const deviceId in deviceData) {
      result[deviceId] = {[mainTestTitle]: {}};
      for (const caseId in deviceData[deviceId]) {
        for (const testName in deviceData[deviceId][caseId]) {
          if (!result[deviceId][mainTestTitle][testName]) {
            result[deviceId][mainTestTitle][testName] = [];
          }
          result[deviceId][mainTestTitle][testName].push(...deviceData[deviceId][caseId][testName]);
        }
      }
    }
    return result;
  });
}

export function DataTable(props) {
  const classes = useStyles();


  const [tableHeadData, setTableHeadData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceMap, setDeviceMap] = useState([]);

  const changeMainTest = (title) => {

  }

  const createSubTestMap = (main, StartTime, EndTime) => {
    var testObject = {};
    const continuousTransmissionTest = [
      "Battery",
      "No Of Data",
      "Electrical Conductivity",
      "Humidity",
      "External Temperature",
      "Internal Temperature",
    ];
    const GSM_Connectivity_Test = [
      "Medium Coverage & Low Voltage",
      "Medium Coverage & High Voltage",
      "Low Coverage & High Voltage",
      "Low Coverage & Low Voltage",
      "High Coverage & Low Voltage",
      "High Coverage & High Voltage",
      "No Of Data",
    ];
    const Flash_Saving_Test = [
      "Flash Saving Test Day",
      "Flash Saving Test Week",
      "No Of Data",
    ];

    const Coverage_Test = [
      "Medium Coverage",
      "High Coverage",
      "No Coverage",
      "Very Low Coverage",
      "Low Coverage",
      "No Of Data",
    ];

    if (main == "Continuous Transmission Test") {
      continuousTransmissionTest.forEach((item) => {
        testObject[item] = "";
      });
    }
    if (main == "GSM Connectivity Test") {
      GSM_Connectivity_Test.forEach((item) => {
        testObject[item] = "";
      });
    }
    if (main == "Flash Saving Test") {
      Flash_Saving_Test.forEach((item) => {
        testObject[item] = "";
      });
    }
    if (main == "Coverage Test") {
      Coverage_Test.forEach((item) => {
        testObject[item] = "";
      });
    }

    testObject["StartTime"] = StartTime;
    testObject["EndTime"] = EndTime;

    return testObject;
  };

  const tableStructure = () => {
    var deviceDataMap = {};
    var testSummary = dataRestructure(props.testSummaryData, props.mainTestTitle);

    var deviceSet = [];
    Object.entries(testSummary).forEach(([device, mainTestCase]) => {
      Object.entries(mainTestCase).forEach(([deviceId, mainTestArr]) => {
        deviceDataMap[deviceId] = [];
        Object.entries(mainTestArr).forEach(([main, subArr]) => {
          deviceDataMap[deviceId][main] = [];
          var result = [];

          Object.keys(subArr).forEach((key) => {
            Object.keys(subArr[key]).forEach((testKey) => {
              var index = result.findIndex(
                (obj) =>
                  obj.StartTime ==
                  subArr[key][testKey]["subTestCaseStartTime"] &&
                  obj.EndTime == subArr[key][testKey]["subTestCaseEndTime"] &&
                  main === subArr[key][testKey]["mainTestTitle"]
              );
              if (index != -1) {
                result[index][subArr[key][testKey]["subTestTitle"]] =
                  subArr[key][testKey]["testResult"];
              } else {
                var temp = createSubTestMap(
                  main,
                  subArr[key][testKey]["subTestCaseStartTime"],
                  subArr[key][testKey]["subTestCaseEndTime"]
                );
                temp[subArr[key][testKey]["subTestTitle"]] =
                  subArr[key][testKey]["testResult"];
                temp["deviceId"] = subArr[key][testKey]["deviceId"];
                result.push(temp);
              }
            });
          });

          deviceDataMap[deviceId][main] = result;
        });
      });
    });
    deviceSet.push(deviceDataMap);
    return deviceSet;
  };

  useEffect(() => {
    if (props.mainTestTitle !== "") {
      const reorganizedData = dataRestructure(props.testSummaryData, props.mainTestTitle)

      let dataArray = [];
      setDeviceData([]);
      const deviceSet = tableStructure();
      setDeviceMap(deviceSet);

      reorganizedData.map((device, i) => {
        const deviceId = Object.keys(device)[0];
        const dataObj = device[deviceId][props.mainTestTitle];
        if (dataObj !== undefined) {
          dataObj["0"] = deviceId;
        }
        dataArray.push(dataObj);
        return dataArray;
      });

      var temp = [];
      deviceSet.forEach((device, i) => {
        Object.entries(device).forEach(([d, test]) => {
          test[props.mainTestTitle].forEach((test) => {
            temp.push(test);
          });
        });
      });

      if (dataArray[0] !== undefined) {
        var tableHeaders = Object.keys(dataArray[0]).sort();
        tableHeaders.push("StartTime");
        tableHeaders.push("EndTime");

        setTableHeadData(tableHeaders);
        setDeviceData(temp);
      }
    }
    props.loadingClose();
  }, [props.testSummaryData]);


  useEffect(() => {
  }, [deviceMap]);

  return (
    <div>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {tableHeadData.map((topic, i) => {
                return (
                  <TableCell align="left" className={classes.topic} key={i}>
                    <Typography className={classes.topicText}>
                      {topic == "0" ? "Device Id" : topic}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceData.length > 0 && deviceData != null ? (
              deviceData.map((test, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 || index / 2 === 0 ? "#ECEDEF" : "white",
                  }}
                >
                  {tableHeadData.map((head, i) => {
                    if (head == 0) {
                      return (
                        <TableCell
                          align="left"
                          className={classes.content}
                          key={i}
                        >
                          <Typography className={classes.contentText}>
                            {test["deviceId"]}
                          </Typography>
                        </TableCell>
                      );
                    }
                    if (test[head] == "") {
                      return (
                        <TableCell
                          align="left"
                          className={classes.content}
                          key={i}
                        >
                          <Typography className={classes.contentText}>
                            {"NOT_APPLICABLE"}
                          </Typography>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          align="left"
                          className={classes.content}
                          key={i}
                          style={{
                            color:
                              test[head] === "SUCCESS"
                                ? "#22C674"
                                : test[head] === "FAILURE"
                                  ? "#FA5246"
                                  : "black",
                          }}
                        >
                          <Typography className={classes.contentText}>
                            {test[head]}
                          </Typography>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={tableHeadData.length}>
                  <Typography className={classes.contentText}>
                    No Data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
