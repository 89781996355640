import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {createTheme, FormControl, Grid, InputBase, ThemeProvider, Typography} from '@material-ui/core';
import useStyles from '../../../assets/styles';
import AddNewDecoder from '../CreateCodec';
import {DownArrow, UpArrow} from '../../../../../components/Icons';
import {environment} from '../../../../../environments/environment';
import axios from 'axios';
import {Loader} from '../../../test-automation/components/Loader';
import {ToasterComponent} from '../../../test-automation/components/Toaster';
import Cookies from "js-cookie";

const theme = createTheme({
  typography: {
    fontFamily: '\'Poppins\', sans-serif',
  },
});

export default function Step3GetDeviceData({
                                             formData,
                                             setFormData,
                                             apiKey,
                                             setApiKey,
                                             apiKeyError,
                                             setApiKeyError,
                                             url,
                                             setUrl,
                                             urlError,
                                             setUrlError,
                                             primaryKey,
                                             setPrimaryKey,
                                             primaryKeyError,
                                             setPrimaryKeyError,
                                             databaseName,
                                             setDatabaseName,
                                             databaseNameError,
                                             setDatabaseNameError,
                                             containerName,
                                             setContainerName,
                                             containerNameError,
                                             setContainerNameError,
                                             dataTranscoding,
                                             setDataTranscoding,
                                             dataTranscodingError,
                                             setDataTranscodingError
                                           }) {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('');
  const [extraFieldsVisible, setExtraFieldsVisible] = useState(false);
  const [step, setStep] = useState("select");
  const [codecs, setCodecs] = useState([]);
  const [showCreateCodecDialog, setShowCreateCodecDialog] = useState(false);
  const [loadings, setLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reqSuccess, setReqSuccess] = useState(false);
  const [isCodecDropDown, setIsCodecDropDown] = useState(false);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    const trimmedValue = value.trim();
    if (name === "url") {
      setUrl(trimmedValue);
      setUrlError(false);
    } else if (name === "primaryKey") {
      setPrimaryKey(trimmedValue);
      setPrimaryKeyError(false);
    } else if (name === "databaseName") {
      setDatabaseName(trimmedValue);
      setDatabaseNameError(false);
    } else if (name === "containerName") {
      setContainerName(trimmedValue);
      setContainerNameError(false);
    }
    setFormData({
      ...formData,
      [name]: trimmedValue
    });
  }

  const handleCreateCodecClick = () => {
    setStep("create");
    setShowCreateCodecDialog(!showCreateCodecDialog);
  };
  const handleSelectCodecClick = () => {
    setStep("select");
    fetchCodecs();
  };

  useEffect(() => {
    if (step === 'select') {
      fetchCodecs();
    }
  }, [step]);

  const handleCodecChange = (option) => {
    setDataTranscoding(option);
    setIsCodecDropDown(false);
    setDataTranscodingError(false); // Reset error when a codec is selected
    setFormData({
      ...formData,
      DataTranscoding: option // Pass the selected value
    });
  };
  const successCallback = (message) => {
    if (message === "Success"){
      setLoading(false);
      setToaster(true);
      setErrorMessage('Codec Added Successfully');
      setReqSuccess(true);
    }
  }
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isCodecDropDown) {
        if (
          !event.target.closest('.codec-dropdown')
        ) {

          setIsCodecDropDown(false);
        }
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isCodecDropDown]);
  const fetchCodecs = () => {
    const accessToken = Cookies.get("ACCESS_TOKEN");

    axios({
      method: 'GET',
      url: `${environment.host}/core/codec`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          setCodecs(data.content);
        } else {
          console.error('Failed to fetch codecs:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Error fetching codecs:', error);
      });
  };


  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    // const value = event.target.value;
    setSelectedOption(value);
    setExtraFieldsVisible(value === 'CosmosDb');
    const trimmedValue = value.trim();
    if (name === "apiKey") {
      setApiKey(trimmedValue);
      setApiKeyError(false);
    }
    setFormData({
      ...formData,
      [name]: trimmedValue
    });
  };

  return (
    <ThemeProvider theme={theme}>
       <Grid container className={classes.root} style={{padding: "0px"}}>
         <Grid container className={classes.formContent}>

        <Grid container alignItems="center">
          <Grid item xs={12}>
          <Grid container spacing={2}>
          <Grid item xs={12}> 
          <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.typo}>
                  Custom Endpoint Option
                  <span style={{color: 'red'}}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <select
                    className={classes.formInput}
                    name="apiKey"
                    value={apiKey}
                    onChange={handleOptionChange}
                    placeholder="Enter API Key/Token Here"
                  >
                    <option value="">Enter API Key/Token Here</option>
                    <option value="CosmosDb">Cosmos DB</option>
                    <option value="EventHubs">Event Hubs</option>
                  </select>
                </Grid>
                </Grid>
                </Grid>
                {apiKeyError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px"}}>
                    <Typography className={classes.errorText}>
                      API Key/Token Required
                    </Typography>
                  </Grid>
                )}
            {extraFieldsVisible && (
                <>
  <Grid item xs={12}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Typography className={classes.typo}>
          URL
                  <span style={{color: 'red'}}>*</span>
        </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter URL Here"
                    name="url"
                    value={url}
                    onChange={handleInputChange}
                  />
                </Grid>
                {urlError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px"}}>
                    <Typography className={classes.errorText}>
                      Url is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
  </Grid>
  <Grid item xs={12}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Typography className={classes.typo}>
          Primary Key
          <span style={{ color: 'red' }}>*</span>
        </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter Primary Key Here"
                    name="primaryKey"
                    value={primaryKey}
                    onChange={handleInputChange}
                  />
                </Grid>
                {primaryKeyError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px"}}>
                    <Typography className={classes.errorText}>
                      Primary Key is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
  </Grid>
 
              <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.typo}>
                  Database Name
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter Database Name Here"
                    name="databaseName"
                    value={databaseName}
                    onChange={handleInputChange}
                  />
                </Grid>
                {databaseNameError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px"}}>
                    <Typography className={classes.errorText}>
                      Database Name is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
              </Grid>
              <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center"> 
              <Grid item xs={12}>
                <Typography className={classes.typo}>
                  Container Name
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter Container Name Here"
                    name="containerName"
                    value={containerName}
                    onChange={handleInputChange}
                  />
                </Grid>
                {containerNameError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px"}}>
                    <Typography className={classes.errorText}>
                      Container Name is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
              </Grid>
                </>
                )}

              <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.typo}>
                  Data Transcoding
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <Grid container
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => setStep("select")}
                            className={`${classes.formInput} ${classes.codecButton}`}
                            style={{
                              borderBottom: step === "select" ? "3px solid #2A7CED" : "1px solid #ccc", }}
                      >
                        <div> Select Codec Method</div>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => setStep("create")}
                            className={`${classes.formInput} ${classes.codecButton}`}
                            style={{
                              borderBottom: step === "create" ? "3px solid #2A7CED" : "1px solid #ccc", }}
                      >
                        <div> Create Codec</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* create codec form elements */}
                  {step === 'create' && (
                    <Grid container spacing={3} style={{marginTop: "20px"}}>
                      <Grid item xs={12} md={12}>
                        <AddNewDecoder
                          onClose={handleSelectCodecClick}
                          codecs={codecs}
                          onCodecToPlaceholder={handleCodecChange}
                          successCallback={successCallback}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* select codec form elements */}
                  {step !== 'create' && (
                    <Grid container spacing={3} style={{marginTop: "20px"}}>
                      <Grid item xs={12} md={12}>
                        <FormControl className={classes.input}>
                          <Grid container style={{height: '40px'}}>
                            <Grid container
                                  className={classes.formInput}
                                  style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderRadius: '4px', // Optional: add border-radius for rounded corners
                                  }}
                                  onClick={() => setIsCodecDropDown(true)}
                            >
                              <Grid item style={{flex: 1}}>
                                <div
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: '#000', // Optional: Set the text color
                                  }}
                                >
                                  {dataTranscoding === '' ? "Select Codec" : dataTranscoding}
                                </div>
                              </Grid>
                              <Grid item>
                                {isCodecDropDown ? <UpArrow/> : <DownArrow/>}
                              </Grid>
                            </Grid>

                            {isCodecDropDown && (
                              <div style={{width: '100%', zIndex: 1}}>
                                <div className={classes.dropdown}>
                                  {codecs.map((codec, index) => {
                                    return (
                                      <Grid item xs={12} md={12}
                                            className={classes.option}
                                            key={index}
                                            onClick={() => {
                                              handleCodecChange(codec.codecName)
                                            }}
                                      >
                                        <span style={{marginLeft: '10px'}}> {codec.codecName} </span>
                                      </Grid>
                                    )
                                  })
                                  }
                                </div>
                                <div style={{marginTop: '20px'}}/>
                              </div>
                            )}
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {dataTranscodingError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                    <Typography className={classes.errorText}>
                      Transcoding is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Loader loading={loadings}/>
      <ToasterComponent
        toaster={toaster}
        message={errorMessage}
        reqSuccess={reqSuccess}
      />
    </ThemeProvider>

  );
}
