import {Dialog, Grid, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import {useState} from "react";
import {textBarColor} from "src/components/colors";
import {TextBar} from "src/components/sub-components/TextBar";
import {environment} from "src/environments/environment";
import {CloseIcon, CloseIconColor} from "../../../components/Icons";
import axios from "axios";

export function ViewProductType(props) {
  const classes = useStyles();
  const [sensorsOfDevice, setSensorsOfDevice] = useState([]);
  const [actuatorsOfDevice, setActuatorsOfDevice] = useState([]);

  const productTypeData = props.productType.productType || props.productType;
  const { productName, connectivity, protocol, persistence, sensorCodes, actuatorCodes, codecName, transcoder } = productTypeData;

  // Fetch MetaData from the API endpoint
  React.useEffect(() => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    axios.get(`${environment.host}/core/meta-data`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (sensorCodes) {
          const names = sensorCodes.map(code => {
            const sensor = response.data.content.sensors.find(s => s.code === code);
            return sensor ? sensor.name : code;
          });
          setSensorsOfDevice(names);
        }
        if (actuatorCodes) {
          const actuatorNames = actuatorCodes.map(code => {
            const actuator = response.data.content.actuators.find(s => s.code === code);
            return actuator ? actuator.name : code;
          });
          setActuatorsOfDevice(actuatorNames);
        }
      })
      .catch((error) => {
        console.error('Error fetching metadata:', error);
        throw new Error('Failed to fetch metadata');
      });
  }, []);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "750px"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: props.opacity,
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid container
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: "1",
                boxShadow: "0px 4px 30px #00000008",
              }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>View Product Type</DialogTitle>
          </Grid>
          <Grid item xs={2} md={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
          >
            <IconButton onClick={props.onClose}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Product Name :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={productName}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Sensors :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} className={`cloud-container`}
                    style={{backgroundColor: textBarColor, marginBottom: "20px"}}>
                {sensorsOfDevice.length === 0 && (
                  <label className="no-data-available"
                         style={{width: "100%"}}>
                    No Sensors
                  </label>
                )}
                {sensorsOfDevice.map((sensor, index) => (
                  <div key={index} className="cloud-item">
                    <span className="sensor-number">{index + 1}</span>
                    <span className="cloud-item-text">{sensor}</span>
                    <div className="cloud-item-close">
                      <i
                        className="fa fa-times-circle"
                        style={{color: "#d94737"}}>
                      </i>
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Actuators :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} className={`cloud-container`}
                    style={{backgroundColor: textBarColor, marginBottom: "20px"}}>
                {actuatorsOfDevice.length === 0 && (
                  <label className="no-data-available" style={{width: "100%"}}>
                    No Actuators
                  </label>
                )}
                {actuatorsOfDevice.map((actuator, index) => (
                  <div key={index} className="cloud-item">
                    <span className="sensor-number">{index + 1}</span>
                    <span className="cloud-item-text">{actuator}</span>
                    <div className="cloud-item-close">
                      <i
                        className="fa fa-times-circle"
                        style={{color: "#d94737"}}>
                      </i>
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Connectivity :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={connectivity}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Protocol :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={protocol}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Persistence :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={persistence}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
                >
                <Typography className={classes.labelStyle}>
                  Transcoding :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={
                    transcoder ? 
                      codecName !== ""  ? codecName : "No transcoder added" 
                    : "Transcoding not enabled"
                  }
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </>
      </Dialog>
    </div>
  );
};


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px"
  },
  gridStyle: {
    textAlign: "left",
    marginBottom: "25px",
    paddingRight: "2%",
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "25px",
  },
  textBarStyle: {
    backgroundColor: textBarColor,
    height: "38px",
    borderRadius: "5px",
    border: "1px solid black",
  },
}));
