import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid, InputBase, Chip, Tooltip, Typography, IconButton} from '@material-ui/core';
import { white } from 'src/components/colors';
import { DownArrowIcon, UpArrow, ViewIcon } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { DevicePopup } from '../step2/DevicePopup';

export function DropDown(props) {
  const classes = useStyles();
  const [isDropDown, setIsDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [selectedValues, setSelectedValues] = useState(props.value || []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    if (props.onBatchDropClick && props.type === 'batchNumber') {
      props.onBatchDropClick ();
    }
    if (props.onMainDropClick && props.type === 'mainTestCase') {
      props.onMainDropClick ();
    }
    if (props.onSubsDropClick && props.type === 'subTestCase') {
      props.onSubsDropClick ();
    }
    if (props.onTestTypeDropClick && props.type === 'testingMethod') {
      props.onTestTypeDropClick ();
    }
    handleDropDown();
  };

  const handleEyeClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleValue = (e) => {
    if (props.isSingle) {
      if (props.type === 'streamingMethod') {
        handleStreamingMethod(e);
      } else if (props.type === 'batchNumber') {
        handleBatchNumber(e);
      } else if (props.type === 'successCriteria') {
        handleSuccessCriteria(e);
      } else if (props.type === 'mainTestCase') {
        handleSelectMainTestCase(e);
      } else if (props.type === 'subTestCase') {
        handleSelectSubTestCase(e);
      } else if (props.type === 'testingMethod') {
        handleTestingMethod(e);
      } else if (props.type === 'repeatPeriod') {
        handleRepeatPeriod(e);
      } else if (props.type === 'scheduleTime') {
        handleScheduleTime(e);
      } else {
        props.onClick(e);
      }
      setIsDropDown(false);
    } else {
      const updatedValues = selectedValues.includes(e)
        ? selectedValues.filter(val => val !== e)
        : [...selectedValues, e];
      setSelectedValues(updatedValues);
      props.setSelectOption(updatedValues);
      setIsDropDown(false);
    }
  };

  const handleStreamingMethod = (e) => {
    props.setSelectOption(e);
  };
  const handleBatchNumber = (e) => {
    props.setSelectOption(e.toString());
  };
  const handleSelectMainTestCase = (e) => {
    props.setSelectOption(e);
  };
  const handleSuccessCriteria = (e) => {
    props.setSelectOption(e);
  };
  const handleSelectSubTestCase = (e) => {
    props.setSelectOption(e);
  };
  const handleTestingMethod = (e) => {
    props.setSelectOption(e);
  };
  const handleRepeatPeriod = (e) => {
    props.setSelectOption(e);
  };
  const handleScheduleTime= (e) => {
    props.setSelectOption(e);
  };

  const handleBatch = (e) =>{
    props.setSelectOption(e);
  }

  const handleDropDown = () => {
    setIsDropDown(!isDropDown);
    setSelectOptions(props.options);
  };

  const handleRemove = (value) => {
    const updatedValues = selectedValues.filter(val => val !== value);
    setSelectedValues(updatedValues);
    props.setSelectOption(updatedValues);
  };

  return (
    <Grid ref={dropdownRef} container style={{ position: 'relative' }}>
      <Grid container 
        className={classes.inputBaseStyle} 
        style={{ backgroundColor: props.type === "successCriteria" ? white : undefined ,
                 borderColor: props.type === "repeatPeriod" ? "#A9A9A9" : undefined
        }} 
        onClick={handleClick}
      >
        <Grid item xs={10} className={classes.inputContainer}>
          {props.isSingle ? (
            <InputBase
              placeholder={props.emptyTag}
              style={{ border: 'none', cursor: 'pointer', marginLeft: '10px', fontSize: '13px' }}
              value={props.value || ''}
              fullWidth
              autoFocus
              readOnly
            />
          ) : (
            <>
              {selectedValues.length === 0 ? (
                <InputBase
                  placeholder={props.emptyTag}
                  style={{ border: 'none', cursor: 'pointer', marginLeft: '10px', fontSize: '13px' }}
                  value=""
                  fullWidth
                  autoFocus
                  readOnly
                />
              ) : (
                selectedValues.map((value, index) => (
                  <Chip
                    key={index}
                    label={value}
                    className={classes.chip}
                    onDelete={() => handleRemove(value)}
                    style={{ margin: '2px' }}
                  />
                ))
              )}
            </>
          )}
        </Grid>
        <Grid item xs={2} className={classes.iconContainer}>
          {isDropDown ? <UpArrow /> : <DownArrowIcon />}
        </Grid>
      </Grid>

      {isDropDown && (
        <div className={classes.dropdown}>
          {selectOptions.map((option, index) => (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className={classes.option}
              key={index}
              onClick={() => handleValue(option)}
              onMouseEnter={() => handleMouseEnter(option)}
              onMouseLeave={handleMouseLeave}
            >
              <Grid item xs={props.type === "batchNumber" || props.icon ? 5 : 12} >
                {option}

              </Grid>
              {props.icon && 
                <Grid item onClick={props.iconClick}>
                    {props.icon}
                </Grid>
              }

              {props.type === 'batchNumber' && (
                <Grid
                  item
                  xs={7}
                  onClick={handleEyeClick}
                  container
                  justifyContent="flex-end"
                >
                  {hoveredOption === option && (
                    <Typography style={{ fontSize: '10px', marginRight: '5px' }}>
                      View Batch Details
                    </Typography>
                  )}
                  <ViewIcon />
                </Grid>
              )}
            </Grid>
          ))}
        </div>
      )}


      {/* View Devices in Batch*/}
      {open && (
        <DevicePopup
          open={open}
          close={handleClose}
          batchNumber={props.value}
        />
      )}
      
    </Grid>
  );
}