import React from "react";
import { DialogContent, DialogContentText, Grid, IconButton, } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface DeviceSettingUpdatePopupProps {
  id?: string;
  ids?: string[];
  productType: string;
  detailsArray: {
    parameter: string;
    defaultValue: string;
    checkUpdate: boolean;
  }[];
  open: boolean;
}

const DeviceSettingUpdatePopup: React.FC<DeviceSettingUpdatePopupProps> = ({
  ids,
  id,
  productType,
  detailsArray,
  open,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
      <MuiAlert
        elevation={6}
        severity={"success"}>
        {id ? (
          <p style={{ textAlign: "left", marginTop: "0px" }}>
            Device ID: {id} -&gt; {productType} Config message sent with 
            the following new parameters:
          </p>
        ) :
          ids ? (
            <p>
              Device IDs: {ids.join(", ")} -&gt; {productType} Config message sent with the following new parameters:
            </p>
          ) : <p>No device updates</p>}
        <p>{detailsArray.map((item, index) => {
          if (item.checkUpdate) {
            return (
              <li key={index}>
                {item.parameter} : {item.defaultValue}
              </li>
            );
          }
          return null;
        })}
        </p>
      </MuiAlert>
    </Snackbar>
  );
};

export default DeviceSettingUpdatePopup;
