import { ThemeProvider, Grid, Typography, createTheme, Button, IconButton } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState,useRef } from 'react';
import { lightGray, primaryBlue, primaryGray, white } from 'src/components/colors';
import { ProjectClientConfigurationsComponent } from './ProjectClientConfigurations';
import useStyles from 'src/app/maintenance/assets/styles';
import {useHistory} from 'react-router-dom';
import { KnowledgeBasedUploadsComponents } from './KnowledgeBasedUploads';
import { RedirectIcon, SaveIcon, TrashIcon, UploadFile, UploadIcon } from 'src/components/Icons';
import { ApiConfigurationComponent } from './ApiConfiguration';
import { message } from 'antd';
import { environment } from 'src/environments/environment';
import { addApiConfiguration, analyticsConfiguration, apiConfiguration, getAuthenticationToken, uploadFile } from '../components/apiHelper';
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';
import { clickBack } from 'src/app/utils';
import Cookies from "js-cookie";

const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
});

const STEPS = [
    {
      text: "Project Configurations",
      activate: true,
    },
    {
      text: "Knowledge Based Uploads & API Configurations",
      activate: false,
    }
];

export const HumanoidNotificationSystemComponent = () =>{
    const [steps,setSteps] = useState(STEPS)
    const [step,setStep] = useState(0)
    const [fileStatus, setFileStatus] = useState("")
    const classes = useStyles()
    const history = useHistory();
    const [files, setFiles] = useState([])
    const [sensorApi,setSensorApi] = useState('')
    const [errors,setErrors] = useState([])  
    const [projectName, setProjectName] = useState("")
    const [isLoading,setIsLoading] = useState(false)
    const [batchNumber,setBatchNumber] = useState('')
    const [metaApi,setMetaApi] = useState('')
    const [deleteFileType,setDeleteFileType] = useState('')
    const [isDeleteConformation,setIsDeleteConformation] = useState(false)
    const [headerValue,setHeaderValue] = useState(0)
    const [allTableData,setAllTableData] = useState([])
    const isMounted = useRef(true)
    const [jsonFiles,setJsonFiles] = useState([])
    const [existToken, setExistToken] = useState([])
    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
        updateStepActivation(step);
    }, [step]);

    function updateStepActivation(currentStep) {
      const updatedSteps = steps.map((step, index) => ({
        ...step,
        activate: index === currentStep,
      }));
      setSteps(updatedSteps);
    }

    function decreaseStep() {
      if (step === 0) {
        // resetFormFields();
        history.push(`/TestAutomation`)
      } else {
        setStep(prevStep => 
          Math.max(prevStep - 1, 0)
        );
      }
    }

    function handleSubmit() {
      // Submit logic here
      if (step === 2) {
        // resetFormFields();
        history.push(`/TestAutomation`)
      }
    }

    const handleProjectConfiguration = async () =>{
      let error : any = {}
      if(projectName == "") {
        error.projectConfig = "Analysis Name is Required"
      }
      if(batchNumber == ''){
        error.batchNumberError = 'Batch Number is Required'
      }
      setErrors(error)
      if (Object.keys(error).length > 0) {
        return true;
      }
        try{
          setIsLoading(true)
          let analytics = {
            analysis_configs:projectName,
            batchNumber:batchNumber,
            referenceId : Cookies.get('ACCOUNT')
          }
          try{
            let response = await analyticsConfiguration(analytics)
            console.log(response.status)
            if(response && response.status === "success"){
              setIsToasterOpen(true);
              setReqSuccess(true);
              setToasterMessage('Project Created Successfully.');
            }
          }catch(error){
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage(error.message);
          }finally{
            setTimeout(() => {
              setIsToasterOpen(false);
            }, 2500);
            setIsLoading(false)
          }
          setStep(prevStep => Math.min(prevStep + 1, STEPS.length - 1));
        }catch(err){
          setIsToasterOpen(true);
          setReqSuccess(false);
          setToasterMessage("Some thing went wrong");
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 2500);
        }finally{
          setIsLoading(false)
        }
    }

    const handleKnowledgedBasedUpload = () =>{
      if(files.length == 0){
        setErrors(prevError =>({
          ...prevError,
          KnowledgedBase:'Please select a file'
        }))
        return true;
      }
    }

    const handleApiConfigSubmit = () =>{
      let error : any = {}
      if(sensorApi == ""){
        error.sensorApiError = "Sensor API is Required"
      }
      if(metaApi == ""){
        error.metaApiError = "Meta API is Required"
      }
      setErrors(error)
      if(Object.values(error).length > 0){
        return true;
      }
      handleApiSubmit()
    }

    const getToken = async () => {
      try {
        const response = await getAuthenticationToken()
          setExistToken(response)
        } catch (error) {
      }
    }
    
    useEffect(() => {
      getToken()
    }, [])

    const uploadApiConfigure = async () => {
      if (!isMounted.current) return; // Prevent updates if unmounted
  
      setIsLoading(true);
      try {
        const apis = allTableData.reduce((acc, data) => acc.concat(data.apis), []);
        const apisWithAuth = apis.map(api => {
          const tokenMatch = existToken.find(auth => auth.name === api.authenticationToken);
          return {
              ...api,
              authenticationToken: tokenMatch ? tokenMatch.token : ""
          };
      });
        const response = await addApiConfiguration(apisWithAuth);
        if (isMounted.current) {
          if (response) {
            setIsToasterOpen(true);
            setReqSuccess(true);
            setToasterMessage("APIs Uploaded Successfully.");
            setHeaderValue(1)
          }
        }
      } catch (error) {
        if (isMounted.current) {
          setIsToasterOpen(true);
          setReqSuccess(false);
          setToasterMessage(error.message);
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
          setTimeout(() => {
            if (isMounted.current) {
              setIsToasterOpen(false);
            }
          }, 3000);
        }
      }
    };

    function increaseStep() {
      const stepHandlers = [
          handleProjectConfiguration,
          handleKnowledgedBasedUpload,
          // handleApiConfigSubmit,
      ];
  
      const currentHandler = stepHandlers[step];
      
      if (currentHandler) {
          if (currentHandler()) {
              return; // If the handler returns true, exit early
          }
      }
  
      // If we're at the last step or the handler returned false
      if (step === 2) {
          history.push(`/TestAutomation`);
      } else {
          setStep(prevStep => Math.min(prevStep + 1, STEPS.length - 1));
      }
  }
  

    const handleSaveAll = async () =>{
      let formData = new FormData()
      if(Object.values(errors).some(error => error !== null)){
        return
      }
      try{
        setIsLoading(true)
        files.forEach((file,index)=>{
          if (file.file) {
              formData.append('files', file.file, file.name);
          }
        })
        const jsonFile = {
          batchNumber: batchNumber, 
          header_token: "Bearer " + Cookies.get("ACCESS_TOKEN"),
          proto: environment.host ,
          referenceId : Cookies.get('ACCOUNT')
        };
        formData.append('json_data', JSON.stringify(jsonFile));
        
        const response = await uploadFile(formData)
      
        if(response){
          history.push(`/TestAutomation`);
        }
      }catch(err){
        setIsToasterOpen(true);
        setReqSuccess(false);
        setToasterMessage("Some thing went wrong");
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 2500);
      }finally{
        setIsLoading(false)
      }
      
    }

    const handleApiSubmit =async () =>{
      
      try{
        setIsLoading(true)
        let api = {sensor_data_api:sensorApi}
        let response = await apiConfiguration(api)
        setIsToasterOpen(true);
        setReqSuccess(true);
        setToasterMessage("Success");
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 2500);
      }catch(err){
        setIsToasterOpen(true);
        setReqSuccess(false);
        setToasterMessage("Some thing went wrong");
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 2500);
      }finally{
        setIsLoading(false)
      }
    }
    
    const onChangeProjectName = (event) =>{
      let {value} = event.target
      if(batchNumber == ''){
        setErrors(prevError =>({
          ...prevError,
          batchNumberError:'Batch is required'
        }))
        return;
      }
      setProjectName(value)
      setErrors(prevError => ({
        ...prevError,
        projectConfig:null
      }))
    }

    const handleRemoveAllFile = () =>{
      setDeleteFileType('removeAllFile')
      setIsDeleteConformation(true)
    }

    const skipApiConfig = () =>{
      if(jsonFiles.length != 0){
        setToasterMessage('Please remove all attached source files')
        setIsToasterOpen(true)
        setReqSuccess(false)
        setTimeout(() =>{
          setIsToasterOpen(false)
        },2000)
      }else{
        setHeaderValue(1)
      }
    }

    function renderStepsComponent() {
      if (step === 1) {
        return <KnowledgeBasedUploadsComponents 
                files={files} 
                setFiles={setFiles} 
                errors={errors} 
                setErrors={setErrors}
                setDeleteFileType = {setDeleteFileType}
                deleteFileType = {deleteFileType}
                setIsDeleteConformation = {setIsDeleteConformation}
                isDeleteConformation = {isDeleteConformation}
                setLoading = {setIsLoading}
                setHeaderValue = {setHeaderValue}
                headerValue = {headerValue}
                allTableData = {allTableData}
                setAllTableData = {setAllTableData}
                selectedBatchNumber = {batchNumber}
                jsonFiles={jsonFiles}
                setJsonFiles={setJsonFiles}
                setExistToken = {setExistToken}
                existToken = {existToken}
                getToken = {getToken}
                />;
      }
      // else if(step == 2){
      //   return <ApiConfigurationComponent setMetaApi={setMetaApi} setSensorApi={setSensorApi} errors={errors} setErrors={setErrors} />
      // }
      else {
        return <ProjectClientConfigurationsComponent 
          setProjectName={setProjectName} 
          projectName={projectName} 
          errors={errors} 
          setErrors={setErrors} 
          onChangeProjectName={onChangeProjectName}
          setLoading = {setIsLoading}
          setSelectedBatchNumber={setBatchNumber}
          selectedBatchNumber = {batchNumber}
        />;
      }
    };
    
    return(
      <ThemeProvider theme={theme}>
      <div className={`testing`}>
      <Grid container className={classes.pageTitleContainer}>
          <IconButton onClick={() => clickBack(history, "/TestAutomation")}>
            <RedirectIcon />
          </IconButton>
          <Grid className={classes.pageTitle} style={{margin:'0px'}}>Knowledge Based Configurations - Step 05</Grid>
        </Grid>

        <Grid container style={{textAlign: "center",paddingRight: "120px"}}>
        <Grid item xs={2} md={3}></Grid>
        <Grid item xs={6} md={6}>
          <Grid container>
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <Grid item xs={4} md={5}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Circle number={index + 1} active={step.activate}completed={steps.findIndex(s => s.activate) > index}/>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.circleText}
                        style={{
                          color: step.activate ? primaryBlue : primaryGray,
                          paddingTop:'5px'
                        }}
                      >
                        {step.text}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {index !== steps.length - 1 && (
                  <Grid item xs={2} md={2}>
                    <hr
                      style={{
                        marginTop: "20px",
                        opacity: steps[index + 1].activate ? "1" : "0.12",
                      }}
                      color={
                        steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                      }
                    />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={4} md={3}></Grid>
      </Grid>
      <Grid container className={classes.customBoxContainer} style={{ width:'90%'}}>
        <Grid item className={classes.contentContainer}>
          {renderStepsComponent()}
        </Grid>
        
        <Grid item xs={12} md={12} className={classes.root}  style={{width:'100%',paddingBottom:'0px'}}>
            <Grid container spacing={2} justifyContent="flex-end" className="testing">
              
                {(step == 1 && headerValue == 0) && 
                    <Button
                      variant="outlined"
                      className={classes.skipButton}
                      onClick={skipApiConfig}
                    >
                      Skip
                    </Button>
                }
                {step !== 3 && (
                    <Button
                      onClick={decreaseStep}
                      variant="outlined"
                      className={classes.backButton}
                      style={{margin:'10px'}}
                    >
                      {step === 1 || step === 2 ? "Back" : "Cancel"}
                    </Button>
                )}
                {(step == 1 && files.length != 0) && 
                    <Button
                      variant="outlined"
                      className={classes.button}
                      style={{margin:'10px',color:'#FF4343',borderColor:'#FF4343'}}
                      startIcon={<TrashIcon/>}
                      onClick={handleRemoveAllFile}
                    >
                      Remove All
                    </Button>
                }
                    <Button
                      onClick={ (step === 1)
                        ? (headerValue === 0 
                            ? uploadApiConfigure 
                            : (files.length !== 0 
                                ? handleSaveAll 
                                : increaseStep))
                        : increaseStep
                      }
                      variant="contained"
                      className={classes.devicePulseSaveButton}
                      style={{margin:'10px'}}
                      disabled = {step == 1 && headerValue ==0 && allTableData.length ==0}
                      startIcon = {
                        step === 1 ?
                          headerValue == 0
                          ?
                            null
                          : files.length !== 0
                            ? <SaveIcon color="white" />
                            : <UploadIcon color='white' />
                          : null
                      }
                    >
                      {step === 1 
                        ?headerValue == 0
                          ?
                          'Save'
                        : files.length == 0
                          ? 'Upload All' 
                          : 'Save All'
                        : "Submit"}
                    </Button>
              </Grid>
          </Grid>
      </Grid>
      <Loader loading={isLoading} />
      <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
      </div>
      </ThemeProvider>
    )
} 

const Circle = (props) => {
    const paddedNumber = (props.number).toString().padStart(2, '0');
    return (
      <div>
        <div
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            backgroundColor: props.completed ? primaryBlue : white,
            border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
            textAlign: "center",
            paddingTop: "7px",
            boxShadow: "0px 4px 8px #0000001F",
          }}
        >
          <h5 style={{
            fontSize: "16px",
            color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
          }}>
            {paddedNumber}
          </h5>
        </div>
      </div>
    );
};