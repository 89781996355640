import * as React from "react";
import {CircularProgress} from "@material-ui/core";


export function LoadingScreen(props) {
  return (
   props.loading&&
      <div style={{
        position: 'fixed',
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        opacity: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        width: '100vw',
        height: '100vh'
      }}>
        <CircularProgress size={80}/>
      </div>
  );
}
