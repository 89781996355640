import {AbstractControl} from '@angular/forms';
import { environment } from 'src/environments/environment';
import Cookies from "js-cookie";

export function timeDurationToReadableForm(time) {
  // let startDate = new Date(startTime);
  // let endDate = new Date(endTime);
  let diffMs = (time * 60 * 1000); // milliseconds between now & Christmas
  let diffDays = Math.floor(diffMs / 86400000); // days
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  if (diffDays === 0) {
    if (diffHrs === 0) {
      return (diffMins + ' minutes');
    }
    return (diffHrs + ' hours, ' + diffMins + ' minutes');
  }
  return (diffDays + ' days, ' + diffHrs + ' hours, ' + diffMins + ' minutes');
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function newDateFromGiven(date) {
  const dashSplit = date.toString().split('-')
  const spaceSplit = dashSplit[2].toString().split(' ')
  const colonSplit = spaceSplit[1].toString().split(':')
  return new Date(dashSplit[0], dashSplit[1] - 1, spaceSplit[0], colonSplit[0], colonSplit[1], colonSplit[2])
}

export function timeSince(date) {
  if (!date) {
    return;
  }
  let sinceDate = newDateFromGiven(date).getTime()
  let seconds = Math.floor((new Date().getTime() - sinceDate) / 1000);
  let sinceTime = null
  let min, hours, days
  if (seconds < 60) {
    return sinceTime = "few seconds ago"
  } else if (seconds < 3600) {
    min = Math.floor(seconds / 60)
    return sinceTime = min + " minutes ago"
  } else if (seconds < 86400) {
    hours = Math.floor(seconds / 3600)
    return sinceTime = hours + " hours ago"
  } else if (seconds > 86400) {
    days = Math.floor(seconds / 86400)
    return sinceTime = days + " days ago"
  } else {
    return date
  }
}

export function convert24Hr(time) {
  if (time.includes('AM') || time.includes('PM')) {
    const convertTime = time.split(/:|\s/)

    var hours = parseInt(convertTime[0], 10)
    var min = parseInt(convertTime[1], 10)
    var sec = parseInt(convertTime[2], 10)
    var period = convertTime[3].toUpperCase()

    if (period === 'PM' && hours < 12) {
      hours += 12;
      console.log(hours)
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    var time24hr = (hours < 10 ? '0' : '') + hours + ':' + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
    return time24hr;
  }
}

export function numericValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const isValid = /^\d+$/.test(control.value);
  return isValid ? null : {'notNumeric': true};
}

export function isValidBatchNumber(control: AbstractControl): { [key: string]: boolean } | null{
  const hasLetter = /[a-zA-Z]/.test(control.value); // Check for at least one letter
  const hasNumber = /\d/.test(control.value);       // Check for at least one digit
  const isAlphanumeric = /^[a-zA-Z0-9_-]+$/.test(control.value); // Only alphanumeric characters
  const hasUnderscoreOrHyphen = /[_-]/.test(control.value);
  const isValid = hasLetter && hasNumber && isAlphanumeric && hasUnderscoreOrHyphen;
  return isValid ?  null : {'notAlphaNumeric': true};
};

export function numberToWords(num) {
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  if (num < 10) return ones[num];
  if (num < 20) return teens[num - 10];
  if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 === 0 ? '' : ' ' + ones[num % 10]);
  if (num < 1000) return ones[Math.floor(num / 100)] + ' hundred' + (num % 100 === 0 ? '' : ' ' + numberToWords(num % 100));
}

export const formatSuccessCriteria = (criteria: string): string => {
  return criteria
      .replace(/_/g, ' ')
      .split(' ') 
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '); 
};

//device parameter configurations - default value validations

export const isCharValid=(value) =>{
  return /^.$/.test(value); // any single character
 // return /^[a-zA-Z]$/.test(value);  //any single alphabet
}
export const isNumeric=(value)=> {
  return !isNaN(value) && !isNaN(parseFloat(value));
}
export const isStrictInteger=(value)=> {
  if(isNaN(value)){
    return false; // Return false for invalid numbers
  }
  const num = parseFloat(value);
  return Number.isInteger(num) && value.indexOf('.') === -1;
}

export const convertToSriLankanTime = (utcDateString) => {
  const [datePart, timePart] = utcDateString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

  const sriLankanOffset = 5.5 * 60;
  const sriLankanTime = new Date(utcDate.getTime() + sriLankanOffset * 60 * 1000);

  const formattedDate = sriLankanTime.toISOString().replace('T', ' ').substring(0, 19);

  return formattedDate;
};

export const formatLargeNumber = (num) => {
  let scale = "";
  if (num >= 1e9) {
    scale = "Billions";
    return { value: (num / 1e9).toFixed(3), scale }; // Billions
  } else if (num >= 1e6) {
    scale = "Millions";
    return { value: (num / 1e6).toFixed(3), scale }; // Millions
  } else if (num >= 1e3) {
    scale = "Thousands";
    return { value: (num / 1e3).toFixed(3), scale }; //Thousands
  }
  return { value: num.toString(), scale: "" }; // Less than 1000, return as-is
};

export const azureService = () =>{
  const planId = Cookies.get('PLANID')
  
    const isAzureFreePackage = () =>{
      return environment.azurepackageFreeId == planId
    }
  
    const isAzureBasicPackage = () =>{
      return environment.azurepackageBasicId == planId
    }
  
    const isAzurePremiumPackage = () =>{
      return environment.azurepackagePremiumId == planId
    }

  return { isAzureFreePackage, isAzureBasicPackage, isAzurePremiumPackage };
}

export const isTestAutomationUser=(): boolean =>{
  const role = parseInt(Cookies.get('ROLE'), 10);
  return role >= 10 && role < 100;
}

export function hasPermision(path) {
  const { isAzureFreePackage, isAzureBasicPackage, isAzurePremiumPackage } = azureService();

  if (isAzureFreePackage() && ['TestCases', 'RootCauseConfig', 'ChatbotConfig', 'ChatHistory','RootCauseAnalysis','HumanoidNotificationSystem','Automl','problemType', 'DeviceFailure'].includes(path)) {
    return false;
  }
  if (isAzureBasicPackage() && ['ChatbotConfig', 'ChatHistory','HumanoidNotificationSystem','RootCauseConfig','RootCauseAnalysis','Automl','problemType', 'DeviceFailure'].includes(path)) {
    return false;
  }
  if (isAzurePremiumPackage() && ['problemType'].includes(path)) {
    return false;
  }
  return true;
}

export const convertToMinutes = (value: number, period: string): number => {
  switch (period.toLowerCase()) {
    case "days":
      return value * 24 * 60;
    case "weeks":
      return value * 7 * 24 * 60;
    case "months":
      return value * 30 * 24 * 60;
    case "hours":
      return value * 60;
    case "minutes":
    default:
      return value;
  }
};

export enum userActionType{
  USER_ADD = 'userAdd',
  ALERT_EDIT = 'alert-edit',
  ALERT_ADD = 'alert-add'
}

export enum status{
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const convertToPeriod = (minutes, period) => ({
  DAYS: minutes / 1440,
  WEEKS: minutes / 10080,
  MONTHS: minutes / 43200,
  HOURS: minutes / 60,
  MINUTES: minutes,
}[period.toUpperCase()] || minutes);

export const periodMap = { 
  "Hours": "HOURS", 
  "Days": "DAYS", 
  "Minutes": "MINUTES",
  "Weeks":"WEEKS",
  "MONTHS":"Months"
};

export const capitalizeFirstLetter = (word) =>{
  if (!word) return ""; // Handle empty strings
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const  capitalizeWords = (sentence) => {
  if (!sentence) return sentence; // Handle empty strings
  return sentence
    .split(' ')
    .map(word => capitalizeFirstLetter(word))
    .join(' ');
}

export const clickBack = (history: any, path: any) => {
  history.push(path);
};

export const getUserRoleName = (userRoleId: number, userRoles: { id: number; name: string }[]): string => {
  const role = userRoles.find(role => role.id === Number(userRoleId));
  return role ? role.name : 'N/A';
};

export const getPrefixes = (batchNumbers) => {
  const prefixSet = new Set();

  batchNumbers.forEach((res) => {
      if (res.includes('_') || res.includes('-')) {
          prefixSet.add(res.split(/[_-]/)[0]);
      }
  });

  return Array.from(prefixSet);
};

export const toCamelCaseWithSpaces = (str) => {
  return str
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const isPlanId = (): boolean => {
  const planId = Cookies.get('PLANID');
  return planId !== null && planId !== 'undefined';
}

export const getColor = (status) => {
  if(status === 'To Do'){
    return '#868DAA'
  } else if(status === 'In Progress'){
    return '#005B96'
  } else if(status === 'On Hold'){
    return '#DDA01E'
  } else if(status === 'Done'){
    return '#00B1A5'
  }
}

export const getBackgroundColor = (status) => {
  if(status === 'To Do'){
    return 'rgba(134, 141, 170, 0.1)'
  } else if(status === 'In Progress'){
    return 'rgba(0, 91, 150, 0.1)'
  } else if(status === 'On Hold'){
    return 'rgba(221, 160, 30, 0.1)'
  } else if(status === 'Done'){
    return 'rgba(0, 177, 165, 0.1)'
  }
}

export const formatSriLankanDateTime = (dateString) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Asia/Colombo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);

  const year = parts.find(part => part.type === 'year').value;
  const month = parts.find(part => part.type === 'month').value;
  const day = parts.find(part => part.type === 'day').value;
  const hour = parts.find(part => part.type === 'hour').value;
  const minute = parts.find(part => part.type === 'minute').value;
  const second = parts.find(part => part.type === 'second').value;

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const handleDevicePulseClicked = () => {
  const event = new CustomEvent('devicePulseClicked');
    window.dispatchEvent(event);
}