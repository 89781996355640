export class MagmaResponse<T> {
  statusCode: string;
  statusDescription: string;
  content: T;
}

export class MagmaUser {
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
  grant_type: string;
  scope: string;

  constructor(client_id, client_secret, grant_type, scope) {
    this.client_id = client_id;
    this.client_secret = client_secret;
    this.grant_type = grant_type;
    this.scope = scope;
  }
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  reference: string;
  role: number;
  id: string;
  planId: string
}

export interface AlertLimit {
  id?: string;
  kitId?: string;
  propertyNumber?: number;
  code?: string;
  level: number;
  low: number;
  high: number;
  currentLevelPeriod?;
  nextLevelPeriod?;
  persistence?;
  time?: string;
  status?: string;
}

export interface AlertHistory {
  user: string;
  maintain: boolean;
  alert: boolean;
  time: string;
  level: number;
}

export interface AlertStatus {
  enable: boolean;
  modes: Modes;
}

export interface Modes {
  SMS: boolean;
  EMAIL: boolean;
}

export interface Battery {
  low: number;
  high: number;
  reading: number;
}

export interface Model {
  id: string;
  name: string;
  noOfProperties: number;
  properties?: (string)[] | null;
  type: string;
  gpsEnabled: boolean;
  lbsEnabled: boolean;
  batteryEnabled: boolean;
}

export interface Device {
  isActive: boolean;
  favourite?: any;
  referenceName?: any;
  id: string;
  name: string;
  noOfSensors: number;
  sensorCodes: String[];
  actuatorCodes: String[];
  noOfActuators: number;
  devices: String[];
  interval: number;
  persistence: boolean;
  connectivity: string;
  protocol: string;
  status: string;
  modifiedDate: string;
  properties?: (PropertiesEntity)[] | null;
  sensors?: (PropertiesEntity)[] | null;
  alerts: any;
  shiftMap: any;
  alertLevel: number;
  alertStatus?: AlertStatus;
  model: Model;
  battery: Battery;
  maintain: boolean;
  lastSeen: string;
  metaData: any;
  createdBy: any;
  modifiedBy: any;
}

export interface FormKitRadioFields {
  maintain: boolean;
  persistence: boolean;
}

export interface KitModel {
  id: String;
  name: String;
  noOfSensors: number;
  noOfProperties: number;
  sensors: Sensor [];
  actuators: Actuator [];
  properties: string [];
  operations: String [];
  actions: String [];
  lbsEnabled: boolean;
  gpsEnabled: boolean;
  batteryEnabled: boolean;
  type: String;
}

export interface Kit {
  id: string;
  name: string;
  devices: String[];
  alertLevel: number;
  interval: number;
  persistence: boolean;
  maintain: boolean;
  model: KitModel;
  metaData: any;
  shiftMap: any;
  properties: any[];
  inputMethod: String;
  createdBy: any;
  modifiedBy: any;
  images: any[];
  referenceName: String;
  refImageStatus: String | null;
}

export interface GraphEntity {
  time: string;
  value: number;
}

export interface SelectedSensor {
  code: string;
  codeValue: string;
  isDisabled: boolean;
}

export class DeviceGraph {
  graphDate: any[];
  graphValue: any[];
  graphData: any[];
  entityName: string;
  propertyDisplayName: string;
}

export interface Application {
  id: string;
  name: string;
  password: string;
  dataUrl: string;
}

export interface PropertiesEntity {
  number: number;
  code: string;
  time: string;
  alert: boolean;
  error: boolean;
  showThreshold: boolean;
  displayValue: string;
  displayName: string;
}

export interface Sensor {
  name: string;
  code: string;
}

export interface Actuator {
  name: string;
  code: string;
}

export interface KitType {
  name: string;
  value: string;
}


export interface MetaData {
  sensors: Sensor[];
  actuators: Actuator[];
  kitTypes: KitType[];
}

export interface Configuration {
  imageURL: string;
  aboutUrl: string;
  aboutTitle: string;
  hasFooter: string;
  hasKitUser: string;
}

export interface Geo {
  type: string;
  time: string;
  lat: number;
  lng: number;
}

export enum FORM_TYPE {
  ADD = 0,
  EDIT = 1,
}

export enum PROPERTY_TYPE {
  T = 'Temperature',
  H = 'Humidity',
  M = 'Moisture',
  B = 'Battery',
}

export enum ACTUATORS {
  SNC = 'Solenoid'
}

export enum PROCESS_TYPE {
  R = 'REAL_TIME',
  B = 'BULK',
}

export enum ACCESS_TYPE {
  READ = 'WRITE',
  WRITE = 'WRITE',
  CLONE = 'CLONE',
  ADD = 'ADD',
  VIEW = 'VIEW',
  RUN = 'RUN',
  DELETE ='DELETE',
}

export enum SECTIONS {
  D_K_KM = 'DEVICE_KIT_KITMODEL',
  D = 'DEVICE',
  K = 'KIT',
  KM = 'KITMODEL',
}

export interface ModelDetail {
  model: string;
  modelUrl: string;
}

export interface RootCause {
  id: string;
  batchNumber: string;
  modelDetails: { [key: string]: ModelDetail };
  rootCauseNotificationDurationInMinutes: number;
  rootCauseResults: object;
  period: string;
}

export enum USER_GUIDE_INFO {
  SETUP_DEVICE_PULSE = 'Welcome to the Setup DevicePulse module! This is where you configure everything needed to receive failure notifications and gain insights into device performance. Follow these steps to onboard devices, define test cases, initiate testing, analyze failures, and enhance AI-driven troubleshooting.',
  CONNECT_DATA_STREAM = 'Start by adding your devices to the platform. Whether your devices are managed through SenzMatica, Azure IoT, or other platforms, this step ensures seamless onboarding and real-time data flow for monitoring and analysis.',
  DEFINE_TEST_CASE = 'Define test cases tailored to your devices and their expected behavior. These test cases help in identifying failures, anomalies, and performance issues based on real-world conditions. Setting clear success criteria ensures accurate evaluation of device performance and reliability.',
  MONITORING_TEST = 'Initiate testing and begin real-time monitoring of your devices. This step allows you to track performance, detect failures, and analyze data as it flows through the system. Continuous monitoring ensures timely identification of issues and helps maintain device reliability.',
  ROOT_CAUSE_ANALYTICS = 'Set up configurations to analyze device failures and identify root causes. This step enables the system to provide insights into why a failure occurred, helping you take corrective action faster.',
  KNOWLEDGE_BASED_CONFIGURATIONS = 'Enhance troubleshooting by uploading relevant documents and expert knowledge. This information enables the AI chatbot to assist users by answering questions about device failures and resolutions.',
}