import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Typography } from "@material-ui/core";
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import { dataRestructure } from './DataTable';
import useStyles from '../../assets/styles';
import { DropDown } from '../components/DropDown';
import { getSuccessCriteria } from '../components/apiHelper';
import { SubTestCases } from '../step2/SubTestCases';

export const TestSuccessCriteria = ({
  testData,
  selectedMainTestCaseTitle,
  selectedMainTestCaseId,
  subTestCaseTitles,
  setSelectedSubTestCase,
  selectedSubTestCase,
  selectedTestCaseType
}) => {
  const classes = useStyles();
  const [fetchedSuccessCriteria, setFetchedSuccessCriteria] = useState('');
  const [minMaxValues, setMinMaxValues] = useState('');

  const successCriteriaMap = {
    "NO_OF_DATA": "No of Data",
    "GREATER_THAN": "Greater than",
    "GREATER_THAN_OR_EQUAL_TO": "Greater than or equal to",
    "LESS_THAN": "Less than",
    "LESS_THAN_OR_EQUAL_TO": "Less than or equal to",
    "IS_EQUAL_TO": "Is equal to",
    "IS_NOT_EQUAL_TO": "Is not equal to",
    "IS_BETWEEN": "Is between",
    "IS_NOT_BETWEEN": "Is not between",
    "AVERAGE": "Average",
    "IS_INCREASING": "Increasing",
    "IS_DECREASING": "Decreasing"
  };

  const [data, setData] = useState({
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#00B1A5', '#FF4343'],
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    const fetchSuccessCriteria = async () => {
      if (selectedMainTestCaseTitle !== "" && selectedSubTestCase !== "") {
        let chartData = [0, 0];

        if (selectedTestCaseType === "Continuous Testing") {
          const { subTestCases, success, failed } = structureContinuousTestData(testData);

          const testResults = subTestCases.map((subTestCase, index) => ({
            subTestCase,
            success: success[index],
            failed: failed[index],
          }));
          const subTestCaseResult = testResults.find(result => result.subTestCase === selectedSubTestCase);

          if (subTestCaseResult) {
            chartData = [subTestCaseResult.success, subTestCaseResult.failed];
          } else {
            console.warn(`No results found for subTestCase: ${selectedSubTestCase}`);
          }

        } else {
          let structuredData = dataRestructure(testData, selectedMainTestCaseId);
          if (structuredData.length > 0 && structuredData[0] !== null && structuredData[0] !== undefined) {
            let data = structuredData[0];
            const result = [];
            for (const device in data) {
              for (const testCase in data[device]) {
                for (const testType in data[device][testCase]) {
                  if (testType === selectedSubTestCase) {
                    const tests = data[device][testCase][testType];
                    for (const test of tests) {
                      result.push(test);
                    }
                  }
                }
              }
            }
            const successCount = result.filter(test => test.testResult === "SUCCESS").length;
            const failureCount = result.filter(test => test.testResult === "FAILURE").length;
            chartData = [successCount, failureCount];
          }
        }

        setData({
          datasets: [
            {
              data: chartData,
              backgroundColor: ['#00B1A5', '#FF4343'],
              borderWidth: 0,
            },
          ],
        });
        try {
          const response = await getSuccessCriteria(selectedMainTestCaseId);

          if (response && response.subTestCases) {
            const subTestCase = response.subTestCases.find(
              (item) => item.subTestTitle === selectedSubTestCase
            );
            if (subTestCase) {
              setFetchedSuccessCriteria(subTestCase.successCriteria);
              if ([
                "NO_OF_DATA",
                "GREATER_THAN",
                "GREATER_THAN_OR_EQUAL_TO",
                "LESS_THAN",
                "LESS_THAN_OR_EQUAL_TO",
                "IS_EQUAL_TO",
                "IS_NOT_EQUAL_TO",
                "AVERAGE"
              ].includes(subTestCase.successCriteria)
              ) {
                setMinMaxValues(`${subTestCase.maxVal}`);
              } else {
                setMinMaxValues(`${subTestCase.minVal} - ${subTestCase.maxVal}`);
              }

            }
          }
        } catch (err) {
          console.error('Error fetching success criteria:', err);
        }
      }
    };

    fetchSuccessCriteria();
  }, [selectedMainTestCaseId, testData, selectedSubTestCase]);
  const handleSelectSubTestCase = (selectedOption) => {
    setSelectedSubTestCase(selectedOption);
  };

  return (
    <div className={classes.formContent} style={{padding: "15px" }}>
      <Grid container style={{height: '350px' }}>
        <Grid item xs={12} md={12} style={{ textAlign: "left" }}>
          <h4>SUB TEST SUCCESS CRITERIA</h4>
        </Grid>
        <Grid container alignItems="center" spacing={2} style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Grid item xs={12} md={6}>
            Sub Test Case Name:
            <span style={{ color: 'red' }}>*</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <DropDown
              options={subTestCaseTitles}
              type='subTestCase'
              emptyTag='-Select-'
              setSelectOption={handleSelectSubTestCase}
              value={selectedTestCaseType != '' ? selectedSubTestCase : ''}
              isSingle={true}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" style={{ marginTop: "10px" }}>
          <Grid item xs={12} md={12}>
            Success Criteria:
            {selectedTestCaseType != '' && selectedSubTestCase &&
              <Typography style={{ display: 'inline', color: '#0047AB', marginLeft: '8px' }}>
                {selectedSubTestCase && (successCriteriaMap[fetchedSuccessCriteria])}
                {minMaxValues && ` ${minMaxValues}`}
              </Typography>
            }

          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} md={3}
            style={{
              textAlign: "left",
              padding: "50px 0px 50px 20px",
              margin: "20px 0px"
            }}>
            <Grid container alignItems="center">
              <Grid item xs={4} md={4} style={{ marginTop: "5px", textAlign: "center" }}>
                <div style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#00B1A5',
                  display: 'inline-block'
                }} />
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography style={{ display: 'inline-block' }}>Success</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={4} md={4} style={{ marginTop: "5px", textAlign: "center" }}>
                <div style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#FF4343',
                  display: 'inline-block'
                }} />
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography style={{ display: 'inline-block' }}>Failure</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} md={9}
            style={{
              marginBottom: "20px",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start'
            }}>
            {selectedTestCaseType != '' && selectedSubTestCase && data.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? (
              <div style={{ width: "220px", height: "220px" }}>
                <Pie
                  data={data}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    tooltips: { enabled: false },
                    plugins: {
                      labels: {
                        render: "percentage",
                        fontColor: "white",
                        position: "border",
                        fontSize: 14,
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <Typography className={classes.contentText}>
                No Data Available
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};



// Structure the data for Continuous Testing
export const structureContinuousTestData = (testData) => {
  const subTestCases = [];
  const success = [];
  const failed = [];

  if (testData) {
    testData.forEach(deviceData => {
      Object.keys(deviceData).forEach((key) => {
        if (key !== "deviceId" && key !== "StartTime" && key !== "EndTime" && key !== "mostFrequentRootCauseInTimeRange") {
          if (!subTestCases.includes(key)) {
            subTestCases.push(key);
            success.push(0);
            failed.push(0);
          }

          const subTestIndex = subTestCases.indexOf(key);
          const result = deviceData[key].split("(")[0].trim();
          if (result === "SUCCESS") {
            success[subTestIndex] += 1;
          } else if (result === "FAILURE") {
            failed[subTestIndex] += 1;
          }
        }
      });
    });
  }

  return { subTestCases, success,failed};
};