import {
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  ListItemText,
  makeStyles,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import {useEffect, useState} from "react";
import AddNewDecoder from "src/app/setup-senzmatica/define-product-type-form/CreateCodec";
import {primaryBlue, primaryGray, primaryRed, searchBarBorderColor, white} from "src/components/colors";
import {TextBar} from "src/components/sub-components/TextBar";
import {environment} from "src/environments/environment";
import ReactSwitch from "react-switch";
import {AddIconLarge, ArrowIcon, CloseIcon, CloseIconColor, DownArrow, UpArrow} from "../../../components/Icons";
import axios from "axios";
import {RemoteConfigComponent} from "./RemoteConfigComponent";
import AlertDialog from "src/app/maintenance/otamanager/OTAManagerComponent";
import useStyles from "src/app/maintenance/assets/styles";
import { Alert } from "@material-ui/lab";
import { isStrictInteger,isCharValid,isNumeric, capitalizeWords } from "src/app/utils";
import { AuthService } from "src/app/authentication/auth.service";
import { addSensor, getAllSensors } from "src/app/maintenance/test-automation/components/apiHelper";
import { AddSensors } from "src/app/setup-senzmatica/define-product-type-form/AddSensorComponent";

export function EditProductType(props) {
  const classes = useLocalStyles();
  const style=useStyles();


  const [showSensorPopup, setShowSensorPopup] = useState(false);
  const [showActuatorPopup, setShowActuatorPopup] = useState(false);
  const [isTranscoding, setIsTranscoding] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState('');
  const [selectedActuator, setSelectedActuator] = useState('');


  const [isCodecDropDown, setIsCodecDropDown] = useState(false);





  //Error Messages
  const [productNameError, setProductNameError] = useState(false);
  const [sensorsError, setSensorsError] = useState(false);
  const [showCreateCodecDialog, setShowCreateCodecDialog] = useState(false);

  //rm edit 


  const [parametersAddedError, setParametersAddedError] = useState(false);
  const [addParameter, setAddParameter] = useState(false);
  const [mainType, setMainType] = useState(""); // network , topic or message
  const [type, setType] = useState("");
  const [index, setIndex] = useState(-1);

  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);



  //parameter inputs
  const [parameter, setParameter] = useState("");
  const [parameterId, setparameterId] = useState("");
  const [inputType, setInputType] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  const [format, setFormat] = useState("");
  const [join, setJoin] = useState([]);
  const [networkVisibility, setNetworkVisibility] = useState(false);
  const [topicVisibility, setTopicVisibility] = useState(false);
  const [messageVisibility, setMessageVisibility] = useState(false);

  const [formatError,setFormatError]=useState({error:false,errorMsg:""})
  const [inputTypeError,setInputTypeError]=useState({error:false,errorMsg:""});
  const [defaultValueError,setDefaultValueError]=useState({error:false,errorMsg:""});
  const [parameterError, setParameterError] = useState({
    error: false,
    errorMsg: "",
  });
  const [idError, setIdError] = useState({error: false, errorMsg: ""});
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [openDialog,setOpenDialog]=useState(false);
  const [openDialogSwitchTab,setOpenDialogSwitchTab]=useState(false);
  const [openDelete,setOpenDelete]=useState(false);
  const [openPrompt,setOpenPrompt]=useState(false);
  const [isAddSensorPopup,setIsAddSensorPopup] = useState(false)
  const [newSensorName,setNewSensorName] = useState("")
  const [newSensorCode,setNewSensorCode] = useState("")
  const [filteredSensors,setFilteredSensors] = useState([])
  const [showSensorOptions, setShowSensorOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sensorErrors,setSensorErrors] = useState({
    sensorNameError:null,
    sensorCodeError:null
  })
  
  const [errors, setErrors] = useState({
    productNameError: null,
    sensorsError: null,
    actuatorsError: null,
    protocolError: null,
    dataFormatError: null,
    connectivityError: null,
    persistenceError: null,
    parametersError:null,
    codecError:null
  })
  const[topicError,setTopicError]=useState("");

  useEffect(() =>{
    setFilteredSensors(props.sensors)
  },[props.sensors])

  // Event listener to handle clicks outside dropdowns
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isCodecDropDown) {
        if (!event.target.closest('.codec-dropdown')) {
          setIsCodecDropDown(false);
        }
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isCodecDropDown]);

  // Fetch MetaData from the API endpoint

  //RM EDIT
  const openAddParameter = (mainType, type, i) => {
    setAddParameter(true);
    setMainType(mainType);
    setType(type);
    setIndex(i);
    if (type === "edit") {
      setInputType(props.parameters[i].inputType);
      setFormat(props.parameters[i].format);
      setJoin(props.parameters[i].joinParameter);
      setDefaultVal(props.parameters[i].defaultValue);
      setparameterId(props.parameters[i].parameterId);
      setParameter(props.parameters[i].parameter);
    }
  };

  const closeAddParameter = () => {
    setType("");
    setAddParameter(false);
    setParameter("");
    setparameterId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setParameterError({error: false, errorMsg: ""});
    setIdError({error: false, errorMsg: ""});
    setDefaultValueError({error:false,errorMsg:""});
    setInputTypeError({error:false,errorMsg:""});
    setFormatError({error:false,errorMsg:""});
   // setMajorVersionUpgrade(false);

  };

//VALIDATIONS

const validateProductTypeForm = () => {
  let error: any = {}
  if (props.updatedProductName.trim() === "") {
    error.productNameError = "Product Type is Required"
  }

  if (props.sensorsOfDevice.length == 0 && props.actuatorsOfDevice.length == 0) {
    if (props.sensorsOfDevice.length == 0) {
      error.sensorsError = "Sensors are Required"
    }
    if (props.actuatorsOfDevice.length == 0) {
      error.actuatorsError = "Actuators are Required"
    }
  }

  if (props.updatedProtocol.trim() === "") {
    error.protocolError = "Protocol is Required"
  }

  if ((props.updatedOtaUpgradable || props.updatedRemotelyConfigurable) && props.updatedDataFormat.trim() === "") {
    error.dataFormatError = "Data Format is Required"
  }

  if (props.updatedRemotelyConfigurable && (!props.parameters || props.parameters.length === 0)) {
    error.parametersError = "Remote Config Parameters are Required";
  }
  
 if (props.updatedConnectivity.trim() === "") {
    error.connectivityError = "Connection is Required"
  }

  if (props.updatedPersistence === '') {
    error.persistenceError = "Persistence is Required"
  }
  if(props.isToggled && props.codecToSend===""){
      error.codecError = "Codec is Required"
  }
  
  return error

}

//read all input fields

const readParameters = (values) => {
  let formatMatchErrorCheck;
  if (type === "add-new") {
    let error;

    if (values.parameter === "" || values.parameterId === ""||values.inputType === "" || values.defaultValue === ""||values.format==="") {
      error = true;
      if (values.parameter === "") {
        setParameterError({
          error: true,
          errorMsg: "Parameter Name is required",
        });
      }
      if (values.defaultValue === "") {
        setDefaultValueError({error: true, errorMsg: "Default value is required"});
      }
      if (values.inputType === "") {
        setInputTypeError({error: true, errorMsg: "Input type is required"});
      }
      if (values.format === "") {
        setFormatError({error: true, errorMsg: "Format is required"});
      }
      if (values.parameterId === "") {
        setIdError({error: true, errorMsg: "Parameter Id is required"});}
      if (error === true) {
        return;
      }
    }

    formatMatchErrorCheck= validateDefaultValueFormat(values.format,values.defaultValue);
    if (!formatMatchErrorCheck){
      return;
    };
  

    if (props.parameters.filter((obj) => obj.parameterId === values.parameterId).length > 0) {
      setIdError({error: true, errorMsg: "parameter Id Already exist"});
      return;
    }
    //save as decimal if it is entered as integer.
      if ((values.format.toLowerCase() === "float" || values.format.toLowerCase() === "double") && isStrictInteger(values.defaultValue)) {
        const floatValue = parseFloat(values.defaultValue);
        const formatedFloatValue = floatValue.toFixed(1); // Ensure one decimal place 
        values.defaultValue=formatedFloatValue;
      }
  
      setDefaultValueError({error:false,errorMsg:""});
    props.setParameters((current) => [...current, values]);
  }
  if (type === "edit" && index !== -1) {
    const keys = Object.keys(values);
    let error;
    if (values.parameter === "" || values.parameterId === ""||values.inputType === "" || values.defaultValue === ""||values.format==="") {
       error=true;
      if (values.parameter === "") {
        setParameterError({
          error: true,
          errorMsg: "Parameter Name is required",
        });
      }
      if (values.defaultValue === "") {
        setDefaultValueError({error: true, errorMsg: "Default value is required"});
      }
      if (values.inputType === "") {
        setInputTypeError({error: true, errorMsg: "Input type is required"});
      }
      if (values.format === "") {
        setFormatError({error: true, errorMsg: "Format is required"});
      }
      if (values.parameterId === "") {
        setIdError({error: true, errorMsg: "Parameter Id is required"});}
      if (error==true){
        return;}
      
    }
    formatMatchErrorCheck= validateDefaultValueFormat(values["format"],values["defaultValue"]);
    if (!formatMatchErrorCheck){return;}
    if ((values.format.toLowerCase() === "float" || values.format.toLowerCase() === "double") && isStrictInteger(values.defaultValue)) {
      const floatValue = parseFloat(values.defaultValue);
      const formatedFloatValue = floatValue.toFixed(1); // Ensure one decimal place 
      values.defaultValue=formatedFloatValue;
    }
    keys.map((k) => {
      if (values[k] !== "") {
        props.parameters[index][k] = values[k];
      }
    });

    setDefaultValueError({error:false,errorMsg:""});
    props.setParameters(props.parameters);
    setType("");
  }
  setParameter("");
  setparameterId("");
  setInputType("");
  setDefaultVal("");
  setFormat("");
  setJoin([]);
  setAddParameter(false);
  //setMajorVersionUpgrade(false);
};


  const  validateDefaultValueFormat=(format,defaultValue)=>{
    let valid = true;
    format = format.toLowerCase();
    switch (format) {
        case "string":
          if (isNumeric(defaultValue)) {
            valid = false; 
            setDefaultValueError({error:true,errorMsg:"The input cannot be 'integer' only."})
           }
           break
        case "char":
           if (!isCharValid(defaultValue)){
           valid= false;
           setDefaultValueError({error:true,errorMsg:"Cannot add more than 1 character"})
           }
            break;
        case "double":
        case "float":
            if (!isNumeric(defaultValue)) {
                valid = false; // Must be numeric
                setDefaultValueError({error:true,errorMsg:"Input must be a numeric value"}) 
            }
            break;
        case "integer":
            if (!isStrictInteger(defaultValue)) {

                valid = false; // Must be a valid integer
                setDefaultValueError({error:true,errorMsg:"Input must be an integer"})
            }
            break;
        default:
            break;
    }

    return valid;
}
const openDeleteParameter=(i)=>{
  setIndex(i)
  setOpenDelete(true);

 }
 const handleDeleteParameter=()=>{
     let parameterIdToDelete=props.parameters[index].parameterId;
     let updatedParams=props.parameters;

     props.parameters.forEach(parameter=>
       {if (parameter.parameterId!==parameterIdToDelete && parameter.joinParameter.includes(parameterIdToDelete)){
         const updatedJoinParameter=parameter.joinParameter.filter(i=>i!=parameterIdToDelete)
         updatedParams=updatedParams.map(p=>p.parameterId==parameter.parameterId?{...p,joinParameter:updatedJoinParameter}:p);
       }})
    
    const updatedParamList=updatedParams.filter(p=>p.parameterId!==props.parameters[index].parameterId);
    props.setParameters(updatedParamList);
    setIndex(-1);
    setOpenDelete(false);
 }
 const openNetworkVisibility = () => {
  setNetworkVisibility(!networkVisibility);
};
const openTopicVisibility = () => {
  setTopicVisibility(!topicVisibility);
};
const openMessageVisibility = () => {
  setMessageVisibility(!messageVisibility);
};



const selectJoin = (e) => {
  setJoin(e.target.value);
};
const removeJoin = (value) => {
  setJoin(join.filter((d) => d !== value));
};


  //API endpoint for the update product type
  const updateProductType = async () => {
    try{
      checkTranscoding();
      const accessToken = AuthService.getAccessToken();

      //move join as dependencyMap
      const dependencyMap = new Map();
      props.parameters.forEach(param => {
      const paramId = param.parameterId;
      const joinParameters = param.joinParameter;

      joinParameters.forEach(joinParamId => {
        if (!dependencyMap.has(joinParamId)) {
          dependencyMap.set(joinParamId, []);
        }
        if (!dependencyMap.get(joinParamId).includes(paramId)) {
          dependencyMap.get(joinParamId).push(paramId);
        }

        if (!dependencyMap.has(paramId)) {
          dependencyMap.set(paramId, []);
        }
        if (!dependencyMap.get(paramId).includes(joinParamId)) {
          dependencyMap.get(paramId).push(joinParamId);
        }
      });

      delete param.joinParameter;
    });

     
      let versionFound;
      let updateVersionList=props.updatedVersion.map(version => {
        if (version.versionNum === "0.0.0") {
            versionFound = true;
            return { ...version, remoteConfigurations: props.parameters ,joinParameters:dependencyMap};
        }
        return version;
    });
    if (!versionFound) {
      updateVersionList.push({ versionNum: "0.0.0", remoteConfigurations: props.parameters ,joinParameters:dependencyMap});
    }
      props.setUpdatedVersion(updateVersionList)
    
      const updatedData = 
       {id: props.id,
        productName: props.updatedProductName,
        connectivity: props.updatedConnectivity,
        protocol: props.updatedProtocol,
        persistence: props.updatedPersistence,
        sensorCodes: props.sensorCodesOfDevice,
        actuatorCodes: props.actuatorCodesOfDevice,
        codecName: props.codecToSend,
        transcoder: props.isToggled ? true : false,
        otaUpgradable: props.updatedOtaUpgradable? true : false,
        remotelyConfigurable: props.updatedRemotelyConfigurable? true : false,
        dataFormat: props.updatedDataFormat,
        ...(props.updatedRemotelyConfigurable && { versions: updateVersionList }),
      };

       setLoadingScreen(true);
      const response =await axios.put(`${environment.host}/core/product/productType/${props.id}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
   
        const updatedProductType = response.data;
        
        if (response.data.errorCode && response.data.errorCode.length > 0) {

          setReqSuccess(false);
          setOpen(true);
          setErrorMessage(response.data.message);
          setLoadingScreen(false); 
          setTimeout(() => {
            setOpen(false);
            setErrorMessage("");
          }, 6000);
        } else {
          setReqSuccess(true);
          setLoadingScreen(false); 
          setOpen(true);
          
          setTimeout(() => {
          props.onClose();
          setOpen(false);
          props.getAllProducts();
          }, 4000);

        }
        
    }
    catch(error) {
        console.error('Error updating product type:', error);
        setLoadingScreen(false);
        throw error;
      };
  };

  // Fetch product types from the API endpoint



  const handleConnectivityChange = (event) => {
    props.setUpdatedConnectivity(event.target.value);
  };
  const handleProtocolChange = (event) => {
    props.setUpdatedProtocol(event.target.value);
  };
  const handlePersistenceChange = (event) => {
    const newPersistenceValue = event.target.value === "true";
    props.setUpdatedPersistence(newPersistenceValue);
  };

  const handleSensorPopup = () => {
    setIsAddSensorPopup(!isAddSensorPopup);
    setSensorErrors((prev)=>({
      ...prev,
      sensorCodeError:null,
      sensorNameError:null
    }))
  };

  const handleSensorSelection = (sensor) => {
    const selectedSensorName = sensor;
    if (selectedSensorName === 'AddSensor') {
      handleSensorPopup();
    } else if(selectedSensorName !== "") {
      const selectedSensor = props.sensors.find(sensor => sensor.codeValue === selectedSensorName);
      if (selectedSensor && !props.sensorsOfDevice.some(sensor => sensor.codeValue === selectedSensor.codeValue)) {
        props.setSensorsOfDevice(prevSensors => [
          ...prevSensors,
          selectedSensor
        ]);
        props.setSensorCodesOfDevice(prevSensors => [...prevSensors, selectedSensor.code]);
      }
      setSensorsError(false);
    }
  };

  const isAddSensor = () =>{
    setShowSensorOptions(!showSensorOptions)
    setIsAddSensorPopup(false)
  }

  const removeSensorFromDevice = (indexToRemove) => {
    props.setSensorsOfDevice(prevSensors => {
      const updatedSensors = [...prevSensors];
      updatedSensors.splice(indexToRemove, 1); // Remove the sensor at the specified index
      return updatedSensors;
    });
    props.setSensorCodesOfDevice(prevSensorCodes => {
      const updatedSensorCodes = [...prevSensorCodes];
      updatedSensorCodes.splice(indexToRemove, 1); // Remove the sensor code at the specified index
      return updatedSensorCodes;
    });
  };

  const handleActuatorSelection = (event) => {
    const selectedActuatorName = event.target.value;
    if (selectedActuatorName === 'AddActuator') {
      setShowActuatorPopup(true);
    } else {
      const selectedActuator = props.actuators.find(actuator => actuator.name === selectedActuatorName);
      if (selectedActuator && !props.actuatorsOfDevice.includes(selectedActuatorName)) {
        props.setActuatorsOfDevice(prevActuators => [...prevActuators, selectedActuatorName]);
        props.setActuatorCodesOfDevice(prevActuatorCodes => {
          if (!Array.isArray(prevActuatorCodes)) {
            prevActuatorCodes = []; // Initialize as an empty array if not already defined
          }
          return [...prevActuatorCodes, selectedActuator.code];
        });
      }
      setSelectedActuator('');
    }
  };

  const removeActuatorFromDevice = (indexToRemove) => {
    props.setActuatorsOfDevice(prevActuators => {
      const updatedActuators = [...prevActuators];
      updatedActuators.splice(indexToRemove, 1);
      return updatedActuators;
    });
    props.setActuatorCodesOfDevice(prevActuatorCodes => {
      const updatedActuatorCodes = [...prevActuatorCodes];
      updatedActuatorCodes.splice(indexToRemove, 1);
      return updatedActuatorCodes;
    });
  };

  const handleDataFormatChange = (event) => {
    props.setUpdatedDataFormat(event.target.value);
  };

  const handleChangeOTAToggle = () => {
    props.setUpdatedOtaUpgradable(!props.updatedOtaUpgradable)
  }

  const handleChangeRemoteToggle = () => {
    props.setUpdatedRemotelyConfigurable(!props.updatedRemotelyConfigurable);
  };

  const handleCodecChange = (option) => {
    props.setCodecToSend(option);
  };
  const handleChangeToggle = () => {
    handleTranscoding();
    props.setIsToggled(!props.isToggled)
    setIsCodecDropDown(false);
    props.setSelectedCodec("");
    props.setCodecToSend("");
    props.setStep("select");
  }
  const handleTranscoding = () => {
    if (props.isToggled) {
      setIsTranscoding(true);
    }
  }
  const checkTranscoding = () => {
    if (isTranscoding) {
      props.setUpdatedCodecName(props.updatedCodecName);
      if (props.step === "create" && props.codecName.trim() !== "") {
        props.setUpdatedCodecName(props.codecName);
      }
    }
  }
  const handleCreateCodecClick = () => {
    props.setStep("create");
    setShowCreateCodecDialog(!showCreateCodecDialog);
  };
  const handleSelectCodecClick = () => {
    props.setStep("select");
    props.setSelectedCodec("");
    props.fetchCodecs();
  };
  const handleCodecCreation = (name) => {
    props.setCodecToSend(name);
  };
  const ActuatorPopup = () => {
    return (
      <Popup
        type="actuator"
        onClose={() =>
          setShowActuatorPopup(false)
        }/>
    );
  };
  const Popup = ({type, onClose}) => {
    return (
      <div style={{color: 'black'}}>
        <p>
          Need to add a {type === 'sensor' ? 'sensor' : 'actuator'}? <br/>
          Contact the admin for assistance!
        </p>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '0px',
            right: '5px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CloseIcon color='#2A7BEC'/>
        </button>
      </div>
    );
  };

  const sensorValidation = () =>{
    let errors :any = {}
    if(newSensorName == ""){
      errors.sensorNameError = 'Sensor name required'
    }else if(props.sensors.some(sensor => sensor.codeValue.toLowerCase() === newSensorName.toLowerCase())){
      errors.sensorNameError = 'Sensor name already exist'
    }
    if(newSensorCode == ""){
      errors.sensorCodeError = 'Sensor code required'
    }else if(props.sensors.some(sensor => sensor.code.toLowerCase() === newSensorName.toLowerCase())){
      errors.sensorCodeError = 'Sensor code already exist'
    }
    return errors
  }

  const handleSensorName = (event) =>{
    const value = event.target.value
    setNewSensorName(value)
    if(value != ""){
      setSensorErrors((prev) =>({
        ...prev,
        sensorNameError : null
      }))
    }
  }

  const handleSensorCode = (event) =>{
    const value = event.target.value
    setNewSensorCode(value)
    if(value != ""){
      setSensorErrors((prev) =>({
        ...prev,
        sensorCodeError : null
      }))
    }
  }


    const getAllSensores =async () =>{
        try{
          const response =await getAllSensors()
          if(response){
            const sortedSensors = response.sort((a, b) => (a.codeValue < b.codeValue ? -1 : 1));
            props.setSensors(sortedSensors);
            setFilteredSensors(sortedSensors);
          }
        }catch(error){
          setOpen(true)
          setReqSuccess(false)
          setErrorMessage(error.message)
        }
    }
  
    const handleAddSensor = async() =>{
      const isValid = await sensorValidation();
      if(Object.values(isValid).length != 0){
        setSensorErrors(isValid)
        return;
      }
        setLoadingScreen(true)
      const data =[{
        code:newSensorCode.toUpperCase(),
        codeValue : capitalizeWords(newSensorName)
      }]
      console.log(data)
      try{
        const response = await addSensor(data)
        if(response){
          getAllSensores()
          setIsAddSensorPopup(false)
        }
      }catch(error){
        setOpen(true)
        setReqSuccess(false)
        setErrorMessage(error.message)
      }finally{
        setLoadingScreen(false)
      }
    }

  const handleSave = async () => {
    const validationError = validateProductTypeForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return;
    }
    
    let rmConfigSatisfied=!props.updatedRemotelyConfigurable||props.devices.length==0;    //if no devices in product type allow without configuring any
    let otaConfigSatisfied=!props.updatedOtaUpgradable||props.devices.length==0;
    
    props.devices.forEach(device=>{
      if (!rmConfigSatisfied&&device.rmConfigured=="Configured"){
        rmConfigSatisfied=true;
      }
      if (!otaConfigSatisfied&&device.otaConfigured=="Configured"){
        otaConfigSatisfied=true;
      }
      if(rmConfigSatisfied&&otaConfigSatisfied){return;}
    })
   if(!rmConfigSatisfied||!otaConfigSatisfied){
    setTopicError("When enabling 'Remote Manager' or 'OTA' for the first time, please configure at least one device under the product type before updating.");
    setOpenPrompt(true);
    return
   }
    try {
      await updateProductType();
   
       

    } catch (error) {
      console.error('Error updating product type:', error);
      setReqSuccess(false);
      setOpen(true);
      if(error.response.data.message){
      setErrorMessage(error.response.data.message);
      }
      setTimeout(() => {
        setOpen(false);
        setErrorMessage("");
      }, 6000);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === '') {
      setFilteredSensors(props.sensors);
    } else {
      const filtered = props.sensors.filter(sensor => sensor.codeValue.toLowerCase().includes(query.toLowerCase()));
      setFilteredSensors(filtered);
    }
  };

  
  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return; // to avoid closing popup during  backdrop click 
    }
    props.onClose(); 
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.2",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "1",
            boxShadow: "0px 4px 30px #00000008",
          }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>Edit Product Type</DialogTitle>
          </Grid>
          <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
            <IconButton
              onClick={props.onClose} >
              <CloseIconColor />
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent>
             <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={6} md={6}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Product Name :
                </Typography>
                <FormControl className={style.input}>
                    <input
                      className={style.formInput}
                      onChange={(e) => {
                        props.setUpdatedProductName(e.target.value);
                      }}
                      value={
                        props.updatedProductName
                      }
                    />
                  </FormControl>
                  {errors.productNameError && (
                  <span className="form-error">
                    {errors.productNameError}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={6} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Protocol :
                </Typography>
             
                <FormControl className={style.input}>
                  <select
                    className={style.formInput}
                    value={props.updatedProtocol}
                    onChange={handleProtocolChange}
                  >
                    <option value={props.updatedProtocol}>{props.updatedProtocol}</option>
                    {props.updatedProtocol !== 'MQTT' && <option value="MQTT">MQTT</option>}
                    {props.updatedProtocol !== 'HTTP' && <option value="HTTP">HTTP</option>}
                  </select>
                </FormControl>
                {errors.protocolError && (
                  <span className="form-error">
                    {errors.protocolError}
                  </span>
                )}
              </Grid>


              <Grid item xs={6} md={6} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Persistence :
                </Typography>
     
                <FormControl className={style.input}>
                    <select
                      className={style.formInput}
                      value={props.updatedPersistence.toString()}
                      onChange={handlePersistenceChange}
                    >
                      <option value={props.updatedPersistence.toString()}>
                        {props.updatedPersistence === true ? "Yes" : "No"}
                      </option>
                      {props.updatedPersistence== false && <option value="true">Yes</option>}
                      {props.updatedPersistence == true && <option value="false">No</option>}
                </select>
                                    
                </FormControl>
                {errors.persistenceError && (
                  <span className="form-error">
                    {errors.persistenceError}
                  </span>
                )}
              </Grid>

              <Grid item xs={6} md={6} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Connectivity :
                </Typography>
   
                <FormControl className={style.input}>
                  <select
                    className={style.formInput}
                    value={props.updatedConnectivity}
                    onChange={handleConnectivityChange}
                  >
                    <option value={props.updatedConnectivity}>{props.updatedConnectivity}</option>
                    {props.updatedConnectivity !== 'WIFI' && <option value="WIFI">Wifi</option>}
                    {props.updatedConnectivity !== 'GSM' && <option value="GSM">GSM</option>}
                  </select>
                </FormControl>
                {errors.connectivityError && (
                  <span className="form-error">
                    {errors.connectivityError}
                  </span>
                )}

              </Grid>

              <Grid item xs={12} md={12}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Sensors :
                </Typography>
             <Grid item xs={12} md={12}
                    className={`${style.cloudContainer} ${showSensorPopup ? classes.popup : ''}`}
                    style={{marginBottom: "10px"}}>
                {/* {props.sensorsOfDevice.length === 0 && (
                  <label className="no-data-available"
                         style={{width: "100%"}}>
                    {showSensorPopup ? (
                      <SensorPopup/>
                    ) : (
                      <> </>
                    )}
                  </label>
                )} */}
                {props.sensorsOfDevice.map((sensor, index) => (
                  <Grid container key={index}  className={style.cloudItem}>
                  <Grid item className={style.cloudItem_number}>{index + 1}</Grid>
                  <Grid item className={style.cloudItem_line}></Grid>
                  <Grid item className={style.cloudItem_text}>{sensor.codeValue}</Grid>
                  <Grid item onClick={() => removeSensorFromDevice(index)} className={style.cloudItem_close}><CloseIcon color='#2A7BEC'/></Grid>
                </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={12} style={{marginBottom: "22px"}}>
                    <FormControl className={`${style.input} sensor-dropdown`}>
                                        <div className={style.formInput} onClick={isAddSensor}>
                                          <span style={{color: "grey"}}>
                                            {showSensorOptions ? (
                                              <InputBase
                                                placeholder="Search Sensors"
                                                style={{ fontFamily: "'Poppins',sans-serif", fontSize: '13px' }}
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                                fullWidth
                                                autoFocus
                                              />
                                            ) : (
                                              <>Add Sensors</>
                                            )}
                                          </span>
                                          {showSensorOptions ? <UpArrow /> : <DownArrow />}
                                        </div>
                                        {showSensorOptions && (
                                          <Grid container className={classes.dropDownGrid}>
                                            <Grid item xs={12} md={12}
                                                  className={style.option}
                                                  onClick={handleSensorPopup}
                                            >
                                              <div className={classes.addIcon}>
                                                <AddIconLarge/>
                                                <span style={{marginLeft: '5px'}}> Add Sensors </span>
                                              </div>
                                            </Grid>
                                            {isAddSensorPopup && 
                                              <AddSensors 
                                                sensorNameChange={handleSensorName} 
                                                sensorCodeChange={handleSensorCode} 
                                                handleSave={handleAddSensor}
                                                error = {sensorErrors}
                                              />
                                            }
                                            {filteredSensors.map((sensor, index) => (
                                              <Grid item xs={12} md={12}
                                                    className={style.option}
                                                    key={index}
                                                    onClick={() => handleSensorSelection(sensor.codeValue)}
                                              >
                                                <span style={{ marginLeft: '10px' }}> {sensor.codeValue} </span>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        )}
                                        {errors.sensorsError && (
                                          <span className="form-error">
                                            {errors.sensorsError}
                                          </span>
                                        )}
                                      </FormControl>
              </Grid>
          </Grid>

              <Grid item xs={12} md={12} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Actuators :
                </Typography>
              <Grid item xs={12} md={12}
                    className={`cloud-container ${showActuatorPopup ? classes.popup : ''}`}
                    style={{marginBottom: "10px"}}>
                {props.actuatorsOfDevice.length === 0 && (
                  <label className="no-data-available" style={{width: "100%"}}>
                    {showActuatorPopup ? (
                      <ActuatorPopup/>
                    ) : (
                      <>  </>
                    )}
                  </label>
                )}
                {props.actuatorsOfDevice.map((actuator, index) => (
                <Grid container key={index}  className={style.cloudItem}>
                  <Grid item className={style.cloudItem_number}>{index + 1}</Grid>
                  <Grid item className={style.cloudItem_line}></Grid>
                  <Grid item className={style.cloudItem_text}>{actuator}</Grid>
                  <Grid item onClick={() => removeActuatorFromDevice(index)} className={style.cloudItem_close}><CloseIcon color='#2A7BEC'/></Grid>
                </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={12} style={{marginBottom: "22px"}}>
                {!showActuatorPopup && (
                  <>
                    <FormControl className={style.input}>
                      <select
                        value={selectedActuator}
                        onChange={handleActuatorSelection}
                        className={style.formInput}
                      >
                        <option value="" disabled hidden>Add Actuators</option>
                        <option value="AddActuator">Add Actuator</option>
                        {props.actuators.map((actuator, index) => (
                          <option key={index} value={actuator.name}>
                            {actuator.name}
                          </option>
                        ))}
                      </select>

                      {errors.actuatorsError && (
                  <span className="form-error">
                    {errors.actuatorsError}
                  </span>
                )}
                    </FormControl>
                  </>
                )}
              </Grid>
              </Grid>

              {(props.updatedOtaUpgradable || props.updatedRemotelyConfigurable) && (
                <>
                <Grid container style={{justifyContent:"space-between"}}>
                  <Grid item xs={6} md={6} className={classes.gridStyle} >
                    <Typography className={classes.labelStyle}>
                      Data Format:
                    </Typography>
                    <FormControl className={style.input}>
                      <select
                        className={style.formInput}
                        value={props.updatedDataFormat}
                        onChange={handleDataFormatChange}
                      >
                        <option value={props.updatedDataFormat}>{props.updatedDataFormat}</option>
                        {props.updatedDataFormat !== "String" && <option value="String">String</option>}
                        {props.updatedDataFormat !== "Json" && <option value="Json">Json</option>}
                      </select>
                    </FormControl> 
                    {errors.dataFormatError && (
                  <span className="form-error">
                    {errors.dataFormatError}
                  </span>
                )}
                  </Grid>
                  <Grid item xs={5} md={5} className={classes.gridStyle} style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
                    <Button
                      variant="contained"
                      onClick={props.toggleDeviceView}
                      className={classes.configDevicesButton}
                      style={{ textTransform: "none" }}
                    >
                      Configure Devices

                      <ArrowIcon width="20" height="15" />

                    </Button>
                  </Grid>
                  </Grid>
         </>
              )}
              <Grid item xs={12} md={12} className={classes.gridStyle} 
              style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                
                <Typography className={classes.labelStyle}>
                  Transcoding :
                </Typography>
              
                <Grid item xs={3} md={3} style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <FormControl>
                    <ReactSwitch
                      checked={props.isToggled}
                      onChange={handleChangeToggle}
                      offColor='#D9D9D9'
                      onColor="#5F9DF2"
                      offHandleColor='#D9D9D9'
                      onHandleColor="#2A7BEC"
                      height={15}
                      width={40}
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      
                    />
                  </FormControl>
                </Grid>
               
              </Grid>
              {errors.codecError && (
                  <span className="form-error">
                    {errors.codecError}
                  </span>
                )}
              {props.isToggled && (
                <Grid container>
                  <Grid container style={{marginBottom: "22px"}}>
                    <Grid item xs={6} md={6}
                          onClick={handleSelectCodecClick}
                          className={`${style.formInput} ${style.codecButton}`}
                          style={{
                            borderBottom: props.step === "select" ? "3px solid #2A7CED" : "1px solid #ccc",
                          }}
                    >
                      <div> Select Codec Method</div>
                    </Grid>
                    <Grid item xs={6} md={6}
                          onClick={handleCreateCodecClick}
                          className={`${style.formInput} ${style.codecButton}`}
                          style={{
                            borderBottom: props.step === "create" ? "3px solid #2A7CED" : "1px solid #ccc",
                          }}
                    >
                      <div> Create Codec</div>
                    </Grid>
                  </Grid>

                  {/* create codec form elements */}
                  {props.step === 'create' && (
                    <AddNewDecoder
                      onClose={handleSelectCodecClick}
                      codecs={props.codecs}
                      onCodecToPlaceholder={handleCodecCreation}
                    />
                  )}

                  {/* select codec form elements */}
                  {props.step !== 'create' && (
                    <Grid container style={{marginBottom: "22px"}}>
                      <FormControl className={style.input}>
                        <Grid container style={{height: '40px'}}>
                          <Grid container className={style.formInput}
                                style={{cursor: 'pointer'}}
                                alignContent='center'
                                onClick={() => setIsCodecDropDown(true)}
                          >
                            <Grid item style={{flex: 1}}>
                              <input
                                placeholder={props.codecToSend === '' ? "Select Codec" : props.codecToSend}
                                className={classes.optionInput}
                                defaultValue={props.selectedCodec}
                                readOnly
                              />
                            </Grid>
                            <Grid item> {isCodecDropDown ? <UpArrow/> : <DownArrow/>} </Grid>
                          </Grid>
                          {isCodecDropDown && (
                            <div style={{width: '100%', zIndex: 1}}>
                              <div className={classes.dropdown}>
                                {props.codecs.map((codec, index) => {
                                  return (
                                    <Grid item xs={12} md={12}
                                          className={classes.option}
                                          key={index}
                                          onClick={() => {
                                            handleCodecChange(codec.codecName)
                                          }}
                                    >
                                      <span style={{marginLeft: '10px'}}> {codec.codecName} </span>
                                    </Grid>
                                  )
                                })
                                }
                              </div>
                              <div style={{marginTop: '20px'}}/>
                            </div>
                          )}
                        </Grid>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                className={classes.gridStyle}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
        
                <Typography className={classes.labelStyle}>
                  Remotely Configurable:
                </Typography>
                <FormControl>
                  <ReactSwitch
                    checked={props.updatedRemotelyConfigurable}
                    onChange={handleChangeRemoteToggle}
                    offColor='#D9D9D9'
                    onColor="#5F9DF2"
                    offHandleColor='#D9D9D9'
                    onHandleColor="#2A7BEC"
                    height={15}
                    width={40}
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    
                  />
                </FormControl>
                {errors.parametersError && (
                  <span className="form-error">
                    {errors.parametersError}
                  </span>
                )}
              </Grid>

              {props.updatedRemotelyConfigurable?
              <Grid item xs={12} md={12}>
              <RemoteConfigComponent
              openNetworkVisibility={() => openNetworkVisibility()}
              openTopicVisibility={() => openTopicVisibility()}
              openMessageVisibility={() => openMessageVisibility()}
              networkVisibility={networkVisibility}
              topicVisibility={topicVisibility}
              messageVisibility={messageVisibility}
              open={(mainType, type, i) =>
                {if(type=="delete"){
                  openDeleteParameter(i);
                }
                else{
                openAddParameter(mainType, type, i)
              }}
              }
              parameters={props.parameters}
              intialParameters={props.intialParameters}
              setParameters={props.setParameters}
              productType={props.productName}
              
              readParameters={(parameters) => readParameters(parameters)}
              parametersAddedError={parametersAddedError}
             
              remotelyConfigurable={props.updatedRemotelyConfigurable}
              editparameterfields={true}
              />
             </Grid>:""}

              <Grid item xs={12} md={12} className={classes.gridStyle}
              style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Typography className={classes.labelStyle}>
                  OTA Upgradable:
                </Typography>
                <Grid item xs={3} md={3} style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <FormControl>
                    <ReactSwitch
                      checked={props.updatedOtaUpgradable}
                      onChange={handleChangeOTAToggle}
                      offColor='#D9D9D9'
                      onColor="#5F9DF2"
                      offHandleColor='#D9D9D9'
                      onHandleColor="#2A7BEC"
                      height={15}
                      width={40}
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      
                    />
                  </FormControl>
                </Grid>
              </Grid>
              </Grid>

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: 'white',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  backgroundColor: primaryBlue,
                }}
                onClick={handleSave}
              >
                Update
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>


      {addParameter ? (
        // ***** This is add new parameter input filed set ***** //
        <>
         <Dialog open={addParameter} maxWidth="md" fullWidth>
           <Grid container>
              <Grid item xs={10} md={10}>
                
                <DialogTitle>
                  {type === "view" ? "View" : type === "edit" ? "Edit" : "Add"}{" "}
                  {addParameter ? "Parameter" : "Firmware"}
                </DialogTitle>
                
              </Grid>
              <Grid item xs={2} md={2} style={{textAlign: "right"}}>
                <IconButton
                  onClick={() => {
                    addParameter ? closeAddParameter() : "";
                  }}
                >
                  <CloseIconColor/>
                </IconButton>
              </Grid>
            </Grid>
         
     
          <DialogContent>
              <Grid container style={{ textAlign: "center", marginTop: "20px" }}>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{ textAlign: "left", paddingBottom: "20px" }}
                >
                  <Typography >Parameter name<span className={style.required}>*</span> </Typography>
                  <FormControl className={style.input}>
                    <input
                      className={style.formInput}
                      disabled={type === "view" ? true : false}
                      onChange={(e) => {
                        setParameter(e.target.value);
                        setParameterError({ error: false, errorMsg: "" });
                      }}
                      value={
                        type === "edit" || type === "view"
                          ? props.parameters[index].parameter
                          : parameter
                      }
                    />

                  </FormControl>
                  {parameterError.error && (
                    <Typography className={style.errorText}>{parameterError.errorMsg}</Typography>)}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    textAlign: "left",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography >Parameter ID<span className={style.required}>*</span></Typography>
                  <FormControl className={style.input}>
                    <input
                      disabled={type === "add-new" ? false : true}
                      className={style.formInput}
                      type="number"
                      value={type === "edit" || type === "view"
                        ? props.parameters[index].parameterId
                        : parameterId}
                      onChange={(e) => {
                        setparameterId(e.target.value);
                        setIdError({ error: false, errorMsg: "" });
                      }}
                    />
                  </FormControl>
                  {idError.error && (
                    <Typography className={style.errorText}>{idError.errorMsg}</Typography>)}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{ textAlign: "left", paddingBottom: "20px" }}
                >
                  <Typography className={style.label}>
                    Input Type <span className={style.required}>*</span>
                  </Typography>
                  <FormControl style={{ padding: "0px", width: "100%" }}>
                    <NativeSelect
                      disabled={type === "view" ? true : false}
                      id="demo-customized-select-native"
                      value={
                        type === "view" ? props.parameters[index].inputType : inputType
                      }
                      className={style.input}
                      onChange={(e) => { setInputType(e.target.value); setInputTypeError({ error: false, errorMsg: "" }) }}
                      input={<BootstrapInput />}
                    >
                      <option value="" disabled hidden>
                        Select input type
                      </option>
                      <option value="text">Text</option>
                      <option value="json">Json</option>
                      <option value="Html">Html</option>
                    </NativeSelect>
                    <Typography className={style.errorText}>{inputTypeError.errorMsg}</Typography>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    textAlign: "left",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography className={style.label}>Data Format <span className={style.required}>*</span></Typography>
                  <FormControl style={{ padding: "0px", width: "100%" }}>
                    <NativeSelect
                      disabled={type === "view" ? true : false}
                      id="demo-customized-select-native"
                      value={type === "view" ? props.parameters[index].format : format}
                      className={style.input}
                      onChange={(e) => { setFormat(e.target.value); setFormatError({ error: false, errorMsg: "" });; }}
                      input={<BootstrapInput />}
                    >
                      <option value="" disabled hidden>
                        Select format type
                      </option>
                      <option value="String">String</option>
                      <option value="Integer">Integer</option>
                      <option value="Double">Double</option>
                      <option value="Float">Float</option>
                      <option value="Char">Char</option>
                    </NativeSelect>
                    <Typography className={style.errorText}>{formatError.errorMsg}</Typography>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{ textAlign: "left", paddingBottom: "20px" }}
                >
                  <Typography className={style.label}>Default Value<span className={style.required}>*</span></Typography>
                  <FormControl className={style.input}>
                    <input
                      className={style.formInput}
                      disabled={type === "view" || format === ""}
                      onChange={(e) => {
                        setDefaultVal(e.target.value);
                        if (format == "Char" && e.target.value.length>1) {
                          setDefaultValueError({ error: true, errorMsg: "Cannot add more than 1 character" })
                        }
                        else {
                          setDefaultValueError({ error: false, errorMsg: "" })
                        }
                      }}
                      value={
                        type === "view"
                          ? props.parameters[index].defaultValue
                          : defaultVal
                      }
                    />
                  </FormControl>
                  {defaultValueError.error && (
                    <Typography className={style.errorText}>
                      {defaultValueError.errorMsg}</Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    textAlign: "left",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography className={style.label}>
                    Join parameter
                  </Typography>
                  <FormControl className={style.input}>
                    <Select
                      disabled={type === "view" ? true : false}
                      id="demo-customized-select-native"
                      value={
                        type === "view" ? props.parameters[index].joinParameter : join
                      }
                      className={style.input}
                      onChange={selectJoin}
                      multiple
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      renderValue={(selected: string[]) => (
                        <div>
                          {selected.map((value, i) => (
                            <Chip
                              key={i}
                              label={value}
                              className={style.chip}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              onDelete={() => removeJoin(value)}
                            />
                          ))}
                        </div>
                      )}
                      input={<BootstrapInput />}
                    >
                      {type === "add-new" ? (
                        props.parameters
                          .filter((obj) => obj.parameterCategory === mainType)
                          .map((p, j) => {
                            return (
                              <MenuItem value={p.parameterId} key={j}>
                                <ListItemText
                                  primary={p.parameterId}
                                  style={{ color: primaryGray }}
                                />
                              </MenuItem>
                            );
                          })
                      ) : type === "edit" ? (
                        props.parameters
                          .filter((obj) => obj.parameterCategory === mainType)
                          .filter((ob) => ob.parameterId !== props.parameters[index].parameterId)
                          .map((p, j) => {
                            return (
                              <MenuItem value={p.parameterId} key={j}>
                                <ListItemText
                                  primary={p.parameterId}
                                  style={{ color: primaryGray }}
                                />
                              </MenuItem>
                            );
                          })
                      ) : (
                        <div></div>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            {type !== "view" && (
              <DialogActions style={{ width: "100%" }} hidden>
                <Button
                  variant="contained"
                  className={style.cancel}
                  onClick={closeAddParameter}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={style.add}
                  disabled={type === "view" ? true : false}
                  onClick={() => {

                    readParameters({
                      parameterCategory: mainType,
                      parameter: parameter,
                      parameterId: parameterId,
                      inputType: inputType,
                      format: format,
                      joinParameter: join,
                      defaultValue: defaultVal,
                    });
                  }}
                >
                  {type === "edit" ? "Update" : "Save"}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </>
      ) : ""}

      {loadingScreen &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80} />
        </div>}
        <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{ textAlign: "left" }}>
            {reqSuccess === true ? "Success" : "Error"}
          </h3>
          {reqSuccess
            ? "Product Type Updated Successfully"
            : errorMessage.length > 0
              ? errorMessage
              : "Something Went Wrong"}
        </Alert>
      </Snackbar>
      <AlertDialog openDialog={openDelete} setOpenDialog={setOpenDelete} handleSkip={handleDeleteParameter} OKText={"Delete"} severity={"danger"} title={"Confirm Delete?"} description={"Are you sure you want to remove this parameter ?"} />
      <PromptDialog openDialog={openPrompt} setOpenDialog={setOpenPrompt}  OKText={"Ok"}  title={"Device Configuration Needed"} description={topicError} />
      
    </div>
  );
};
export default function PromptDialog(props) {
  const buttonColor=props.severity&&props.severity=="danger"?primaryRed:primaryBlue;
  
  const handleClose=()=>{
  props.setOpenDialog(false)}
  return(
  <Dialog
  open={props.openDialog}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {props.title}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
    {props.description}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}  style={{backgroundColor:buttonColor,color:"white"}}>{props.OKText?props.OKText:"OK"}</Button>
  </DialogActions>
</Dialog>)
}
const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  input: {
    width: "100%",
    textAlign: "left",
  },
  textBarStyle: {
    height: "38px",
    borderRadius: "5px",
    border: "1px solid black",
  },
  popup: {
    backgroundColor: "#CEEBCE",
  },
  formInput: {
    height: "38px",
    borderRadius: "5px",
    padding: "10px 15px",
    fontFamily: "'Poppins', sans-serif",
    border: '0.5px solid',
    backgroundColor: white,
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  configDevicesButton: {
    backgroundColor: white,
    color: primaryBlue,
    borderColor:primaryBlue,
    borderStyle:"solid",
    borderWidth:"1px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
  },
  gridStyle: {
    textAlign: "left",
    marginBottom: "25px",
    paddingRight: "2%",
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "25px",
  },
  optionInput: {
    cursor: 'pointer',
    border: 'none',
    fontFamily: "'Poppins', sans-serif"
  },
  dropdown: {
    textAlign: 'left',
    border: '0.5px solid #324054',
    margin: 'px',
    borderRadius: '5px',
    backgroundColor: white,
    width: '100%',
    zIndex: 1,
    maxHeight: '160px',
    overflowY: 'auto',
  },
  option: {
    paddingLeft: '10px',
    height: '30px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: "13px"
  },
  dropDownGrid: {
      backgroundColor: white,
      border: '1px solid #C7C4C4',
      borderRadius: '5px',
      maxHeight: '200px',
      overflowY: 'auto',
      marginTop: '40px',
      zIndex:10,
      position: 'absolute'
    },
    addIcon:{
      margin: "5px 0px 0px 10px",
      display: 'flex', 
      alignItems: 'center', 
      cursor: 'pointer'
    }
}));


const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },

    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
