import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, createTheme, makeStyles, Theme, ThemeProvider, withStyles, } from "@material-ui/core/styles";
import { lightGray, primaryBlue, primaryGray, primaryRed, searchBarBorderColor, white } from "src/components/colors";
import { TextBar } from "src/components/sub-components/TextBar";
import { environment } from "src/environments/environment";
import useStyles from "../assets/styles";
import { Alert } from "@material-ui/lab";
import { submit } from "src/app/product-types/api-helper/apiHelper";
import { Step01 } from "./Steps/Step01";
import { Step02 } from "./Steps/Step02";
import { Step03 } from "./Steps/Step03";
import { CloseIconColor } from "src/components/Icons";
import axios from "axios";
import Cookies from "js-cookie";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function AddnewProductVersion(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(true);
  const [message, setMessage] = useState("");

  const [step, setStep] = useState(0);
  const [products, setProducts] = useState([]);
  const [productTypeError, setProductTypeError] = useState(false);
  const [versionError, setVersionError] = useState({
    isError: false,
    message: "",
  });
  const [binError, setBinError] = useState({ error: false, errorMsg: "" });
  const [parameterError, setParameterError] = useState({
    error: false,
    errorMsg: "",
  });
  const [formatError, setFormatError] = useState({ error: false, errorMsg: "" })
  const [inputTypeError, setInputTypeError] = useState({ error: false, errorMsg: "" });
  const [defaultValueError, setDefaultValueError] = useState({ error: false, errorMsg: "" })

  const [idError, setIdError] = useState({ error: false, errorMsg: "" });
  const [steps, setSteps] = useState([
    {
      text: "Basic Details",
      activate: true,
    },
    {
      text: "Remote Configuration",
      activate: false,
    },
    {
      text: "Add Technology Flow Chart",
      activate: false,
    }
  ]);
  const [addParameter, setAddParameter] = useState(false);
  const [mainType, setMainType] = useState(""); // network , topic or message
  const [type, setType] = useState("");
  const [index, setIndex] = useState(-1);

  //parameter inputs
  const [parameter, setParameter] = useState("");
  const [id, setId] = useState("");
  const [inputType, setInputType] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  const [format, setFormat] = useState("");
  const [join, setJoin] = useState([]);

  //final states that should send to BE ***************
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [selectedVersionClone, setSelectedVersionClone] = useState("");
  const [previousVersions, setPreviousVersions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedBin, setSelectedBin] = useState();
  const [binFileName, setBinFileName] = useState("");
  const [parameters, setParameters] = useState([]);
  const [intialParameters, setInitialParameters] = useState([]);
  const [parametersAddedError, setParametersAddedError] = useState(false);
  const [existingFlowChart, setExistingFlowChart] = useState("");
  const [flowChart, setFlowChart] = useState<File | null>(null);
  const [flowChartError, setFlowChartError] = useState(false);
  const [flowChartName, setFlowChartName] = useState("");
  const [flowChartFileName, setFlowChartFileName] = useState("");
  const [flowChartFileNameError, setFlowChartFileNameError] = useState({ isError: false, message: "" });
  const [deviceIds, setDeviceIds] = useState([]);
  const [majorVersionUpgrade, setMajorVersionUpgrade] = useState(false);
  const [networkVisibility, setNetworkVisibility] = useState(false);
  const [topicVisibility, setTopicVisibility] = useState(false);
  const [messageVisibility, setMessageVisibility] = useState(false);
  const [remotelyConfigurable, setRemotelyConfigurable] = useState(false);
  const [addFlowChart, setAddFlowChart] = useState(false);
  const [existingFlowChartList, setExistingFlowChartList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSwitchTab, setOpenDialogSwitchTab] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [availableBinfiles, setAvailableBinFiles] = useState([]);

  const [tab, setTab] = useState("select");


  useEffect(() => {
    setLoadingScreen(true);
    axios.get(`${environment.host}/core/products`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        setProducts(response.data.content.filter(product => product.otaUpgradable === true));
        setLoadingScreen(false)
      })
      .catch((error) => {
        setLoadingScreen(false)
        setProducts([]);
      });
  }, []);

  useEffect(() => {
    updateStepActivation(step);
  }, [step]);

  function updateStepActivation(currentStep) {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      activate: index === currentStep,
    }));
    setSteps(updatedSteps);
  }
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const openNetworkVisibility = () => {
    setNetworkVisibility(!networkVisibility);
  };
  const openTopicVisibility = () => {
    setTopicVisibility(!topicVisibility);
  };
  const openMessageVisibility = () => {
    setMessageVisibility(!messageVisibility);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleSkip = () => {

    setStep(step + 2);
    steps.map((s, i) => {
      if (i <= step + 2) {
        s.activate = true;
      } else {
        s.activate = false;
      }
    });
    setSteps(steps);
    setOpenDialog(false);
  }
  const handleDiscardTab = () => {
    resetAddFlowChart();
    if (tab === 'select') {
      setTab('create')
    }
    else if (tab === 'create') {
      setTab('select')
    }
    setOpenDialogSwitchTab(false);
  }
  const isVersionValid = (targetVersion) => {
    const isValidNumericVersion = /^[0-9]+(\.[0-9]+)*$/.test(targetVersion);
    return isValidNumericVersion;
  };
  const handleSwitchTab = (tabSelected) => {
    if (tabSelected === "select" && (flowChartFileName || flowChart)) {
      setOpenDialogSwitchTab(true)
    }
    else if (tabSelected === "create" && (existingFlowChart)) {
      setOpenDialogSwitchTab(true)
    }
    else {
      setTab(tabSelected);
    }
  }
  //read all input fields
  const readProductType = (productType) => {
    setSelectedProductType(productType);
    setProductTypeError(false);
  };

  const readMajorVersionUpgrade = (upgrade) => {
    setMajorVersionUpgrade(upgrade);
  };

  const readVersion = (version) => {
    setSelectedVersion(version);
    setVersionError({ isError: false, message: "" });
  };

  const readVersionClone = (version) => {
    setSelectedVersionClone(version);
    setVersionError({ isError: false, message: "" });
  };
  const readBin = (file) => {
    const acceptedFile = file[0];

    if (acceptedFile) {
      setBinFileName(acceptedFile.name);
      setSelectedBin(acceptedFile);

      if (!acceptedFile.name.endsWith(".bin")) {
        setBinError({ error: true, errorMsg: "Invalid file type" });
      } else {
        setBinError({ error: false, errorMsg: "" });
      }
      if (availableBinfiles.some(name => name === acceptedFile.name)) {
        setBinError({ error: true, errorMsg: "Filename already exists" });
      }
    }
  };
  const readFileName = (fileName) => {
    setFlowChartFileName(fileName)
    setFlowChartFileNameError({ isError: false, message: "" })
  }

  const processJoinParameters = (dependencyMap, parameters) => {
    parameters.forEach(param => {
      const paramId = param.parameterId;

      if (dependencyMap[paramId]) {
        param.joinParameter = dependencyMap[paramId];

        // Remove reverse dependency
        param.joinParameter.forEach(joinParamId => {
          if (dependencyMap[joinParamId]) {
            const index = dependencyMap[joinParamId].indexOf(paramId);
            if (index > -1) {
              dependencyMap[joinParamId].splice(index, 1);
            }
            if (dependencyMap[joinParamId].length === 0) {
              delete dependencyMap[joinParamId]; // Clean up empty entries
            }
          }
        });

      } else {
        param.joinParameter = [];
      }
    });
  };

  const readParametersCloned = (parameters) => {
    const matchingProduct = products.find(
      (product) => product.productName === selectedProductType
    );
    const versionToBeCloned = matchingProduct.versions.find(
      (version) => version.versionNum === selectedVersionClone
    );

    if (versionToBeCloned) {
      processJoinParameters(versionToBeCloned.joinParameters, parameters);
      setParameters(parameters);
    }
    setParameters(parameters);
    setParametersAddedError(false);
  };
  function isCharValid(value) {
    return /^.$/.test(value); // any single character
    // return /^[a-zA-Z]$/.test(value);  //any single alphabet
  }
  function isNumeric(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  function isStrictInteger(value) {
    if (isNaN(value)) {
      return false; // Return false for invalid numbers
    }
    const num = parseFloat(value);
    return Number.isInteger(num) && value.indexOf('.') === -1;
  }

  const validateDefaultValueFormat = (format, defaultValue) => {
    let valid = true;
    format = format.toLowerCase();
    switch (format) {
      case "string":
        if (isNumeric(defaultValue)) {
          valid = false;
          setDefaultValueError({ error: true, errorMsg: "The input cannot be 'integer' only." })
        }
        break
      case "char":
        if (!isCharValid(defaultValue)) {
          valid = false;
          setDefaultValueError({ error: true, errorMsg: "Cannot add more than 1 character" })
        }
        break;
      case "double":
      case "float":
        if (!isNumeric(defaultValue)) {
          valid = false; // Must be numeric
          setDefaultValueError({ error: true, errorMsg: "Input must be a numeric value" })
        }
        break;
      case "integer":
        if (!isStrictInteger(defaultValue)) {

          valid = false; // Must be a valid integer
          setDefaultValueError({ error: true, errorMsg: "Input must be an integer" })
        }
        break;
      default:
        break;
    }

    return valid;
  }

  const readParameters = (values) => {
    let formatMatchErrorCheck;
    if (type === "add-new") {
      let error;

      if (values.parameter === "" || values.parameterId === "" || values.inputType === "" || values.defaultValue === "" || values.format === "") {
        error = true;
        if (values.parameter === "") {
          setParameterError({
            error: true,
            errorMsg: "Parameter Name is required",
          });
        }
        if (values.defaultValue === "") {
          setDefaultValueError({ error: true, errorMsg: "Default value is required" });
        }
        if (values.inputType === "") {
          setInputTypeError({ error: true, errorMsg: "Input type is required" });
        }
        if (values.format === "") {
          setFormatError({ error: true, errorMsg: "Format is required" });
        }
        if (values.parameterId === "") {
          setIdError({ error: true, errorMsg: "Parameter Id is required" });
        }
        if (error === true) {
          return;
        }
      }

      formatMatchErrorCheck = validateDefaultValueFormat(values.format, values.defaultValue);
      if (!formatMatchErrorCheck) {
        return;
      };


      if (parameters.filter((obj) => obj.parameterId === values.parameterId).length > 0) {
        setIdError({ error: true, errorMsg: "parameter Id Already exist" });
        return;
      }
      //save as decimal if it is entered as integer.
      if ((values.format.toLowerCase() === "float" || values.format.toLowerCase() === "double") && isStrictInteger(values.defaultValue)) {
        const floatValue = parseFloat(values.defaultValue);
        const formatedFloatValue = floatValue.toFixed(1); // Ensure one decimal place 
        values.defaultValue = formatedFloatValue;
      }

      setDefaultValueError({ error: false, errorMsg: "" });
      setParameters((current) => [...current, values]);
    }
    if (type === "edit" && index !== -1) {
      const keys = Object.keys(values);
      let error;
      if (values.parameter === "" || values.parameterId === "" || values.inputType === "" || values.defaultValue === "" || values.format === "") {
        error = true;
        if (values.parameter === "") {
          setParameterError({
            error: true,
            errorMsg: "Parameter Name is required",
          });
        }
        if (values.defaultValue === "") {
          setDefaultValueError({ error: true, errorMsg: "Default value is required" });
        }
        if (values.inputType === "") {
          setInputTypeError({ error: true, errorMsg: "Input type is required" });
        }
        if (values.format === "") {
          setFormatError({ error: true, errorMsg: "Format is required" });
        }
        if (values.parameterId === "") {
          setIdError({ error: true, errorMsg: "Parameter Id is required" });
        }
        if (error == true) {
          return;
        }

      }
      formatMatchErrorCheck = validateDefaultValueFormat(values["format"], values["defaultValue"]);
      if (!formatMatchErrorCheck) { return; }
      if ((values.format.toLowerCase() === "float" || values.format.toLowerCase() === "double") && isStrictInteger(values.defaultValue)) {
        const floatValue = parseFloat(values.defaultValue);
        const formatedFloatValue = floatValue.toFixed(1); // Ensure one decimal place 
        values.defaultValue = formatedFloatValue;
      }
      keys.map((k) => {
        if (values[k] !== "") {
          parameters[index][k] = values[k];
        }
      });

      setDefaultValueError({ error: false, errorMsg: "" });
      setParameters(parameters);
      setType("");
    }
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setAddParameter(false);
    //setMajorVersionUpgrade(false);
  };
  const readExistingFlowChart = (flowChart) => {
    setExistingFlowChart(flowChart);
    setFlowChartError(false);
  };
  const readFlowChart = (file) => {
    const modifiedFile = new File([file[0]], flowChartFileName, {
      type: file[0].type,
      lastModified: file[0].lastModified,
    });
    setFlowChartName(file[0].name);
    setFlowChart(modifiedFile);
    setFlowChartError(false);
  };
  const resetAddFlowChart = () => {
    setFlowChartFileName("")
    setFlowChartName("")
    setFlowChartError(false)
    setFlowChart(null)
    setFlowChartFileNameError({ isError: false, message: "" })
    setExistingFlowChart("")
  }
  const readDevices = (devices) => {
    setDeviceIds(devices);
  };

  const readMessage = (message) => {
    if (message === "Data Cloned Successfully") {
      setStatus(true);
      setMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    } else {
      setStatus(false);
      setMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    }
  };
  const openDeleteParameter = (i) => {
    setIndex(i)
    setOpenDelete(true);

  }
  const handleDeleteParameter = () => {
    let parameterIdToDelete = parameters[index].parameterId;
    let updatedParams = parameters;

    parameters.forEach(parameter => {
      if (parameter.parameterId !== parameterIdToDelete && parameter.joinParameter.includes(parameterIdToDelete)) {
        const updatedJoinParameter = parameter.joinParameter.filter(i => i != parameterIdToDelete)
        updatedParams = updatedParams.map(p => p.parameterId == parameter.parameterId ? { ...p, joinParameter: updatedJoinParameter } : p);
      }
    })

    const updatedParamList = updatedParams.filter(p => p.parameterId !== parameters[index].parameterId);
    setParameters(updatedParamList);
    setIndex(-1);
    setOpenDelete(false);
  }
  const openAddParameter = (mainType, type, i) => {
    setAddParameter(true);
    setMainType(mainType);
    setType(type);
    setIndex(i);
    if (type === "edit") {
      setInputType(parameters[i].inputType);
      setFormat(parameters[i].format);
      setJoin(parameters[i].joinParameter);
      setDefaultVal(parameters[i].defaultValue);
      setId(parameters[i].parameterId);
      setParameter(parameters[i].parameter);
    }
  };
  const closeAddParameter = () => {
    setType("");
    setAddParameter(false);
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setParameterError({ error: false, errorMsg: "" });
    setIdError({ error: false, errorMsg: "" });
    setDefaultValueError({ error: false, errorMsg: "" });
    setInputTypeError({ error: false, errorMsg: "" });
    setFormatError({ error: false, errorMsg: "" });
    // setMajorVersionUpgrade(false);

  };

  const increaseStep = async () => {
    let error;
    let isRemotelyConfigurable;
    if (binError.error) {
      return;
    }
    if (
      step === 0 &&
      (selectedProductType === "" ||
        selectedVersion === "" ||
        binFileName === "")
    ) {
      if (selectedProductType === "") {
        setProductTypeError(true);
      }
      if (selectedVersion === "") {
        setVersionError({ isError: true, message: `Version Required` });
      }
      if (binFileName === "") {
        setBinError({ error: true, errorMsg: "Bin file is required" });
      }
      return;
    }
    if (step === 0 && id === "") {
      try {
        const matchingProduct = products.find(
          (product) =>
            product.productName === selectedProductType
        );
        if (matchingProduct) {
          setSelectedId(matchingProduct.id);
          isRemotelyConfigurable = matchingProduct.remotelyConfigurable;
          setRemotelyConfigurable(isRemotelyConfigurable);
          const selectedVersionExists = matchingProduct.versions.some(
            (version) => version.versionNum === selectedVersion
          );

          if (selectedVersionExists) {
            setVersionError({
              isError: true,
              message: `Version Already exists`,
            });
            return;
          }

          const versionNums = matchingProduct.versions
            .filter((version) => version.status === "APPROVED" && version.versionNum !== "0.0.0")
            .map((version) => version.versionNum);


          const zeroVersion = matchingProduct.versions.find(
            (version) => version.versionNum === "0.0.0"
          );

          if (zeroVersion) {
            processJoinParameters(zeroVersion.joinParameters, zeroVersion.remoteConfigurations);
            setParameters(zeroVersion.remoteConfigurations);
            setInitialParameters(zeroVersion.remoteConfigurations);

          }

          setPreviousVersions(versionNums);

          if (!isVersionValid(selectedVersion)) {
            setVersionError({
              isError: true,
              message: `Version is not valid`,
            });
            return;
          }
        } else {
          setVersionError({
            isError: true,
            message: `No matching product found for the selected type and category`,
          });
          return;
        }
      } catch (error) {
        setVersionError({
          isError: true,
          message: `No matching product found for the selected type and category`,
        });
        return;
      }
    }

    if (step === 2 && addFlowChart) {
      let flowChartError = tab === 'select' && existingFlowChart === "" || tab === 'create' && flowChart === null;
      let fileNameEmpty = tab === "create" && flowChartFileName === "";
      let isFileNameUnique = true;

      if (fileNameEmpty) {
        setFlowChartFileNameError({ isError: fileNameEmpty, message: "Filename required" })
      }

      else {
        if (tab === 'create') {
          existingFlowChartList.forEach(flowChart => {
            if (flowChart.fileName === flowChartFileName) {
              isFileNameUnique = false;
            }
          })
          if (!isFileNameUnique) {
            setFlowChartFileNameError({ isError: true, message: "Filename already exist" })
          }
        }
      }
      if (flowChartError) {
        setFlowChartError(flowChartError);
      }
      if (flowChartError || !isFileNameUnique || fileNameEmpty) {
        return;
      }
    }
    if (step === 1 && parameters.length === 0 && remotelyConfigurable) {
      error = true;
      setParametersAddedError(true);
      return;
    }

    if (step === 2) {
      setLoading(true);
      const result = await submit(
        selectedBin,//catch
        binFileName, //catch
        flowChart,
        selectedVersion, //catch
        parameters,
        existingFlowChart,
        selectedId,
        majorVersionUpgrade,
        addFlowChart,
        flowChartFileName,
      );
      setLoading(false);
      if (result.message && result.message === "Success") {
        setStatus(true);
        setMessage("New version submitted successfully");
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          clearStates();
           props.goBack()
        }, 1000);

      } else {
        setStatus(false);
        let errMsg = result.response.data.message ? result.response.data.message : "Error updating Version";
        setMessage(errMsg);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 6000);
      }
    }
    else if (step == 0 && !isRemotelyConfigurable) {
      handleClickOpen();
    }

    else {
      setStep(step + 1);
      steps.map((s, i) => {
        if (i <= step + 1) {
          s.activate = true;
        } else {
          s.activate = false;
        }
      });
      setSteps(steps);
    }
  };

  const decreaseStep = () => {
    if (step === 0) {
      clearStates();
      props.goBack();
      return;

    }
    else if (step == 2 && !remotelyConfigurable) {
      setStep(step - 2);
      steps.map((s, i) => {
        if (i <= step - 2) {
          s.activate = true;
        } else {
          s.activate = false;
        }
      });
      setSteps(steps);
    }
    else {
      setStep(step - 1);
      steps.map((s, i) => {
        if (i <= step - 1) {
          s.activate = true;
        } else {
          s.activate = false;
        }
      });
      setSteps(steps);
    }
  };

  const selectJoin = (e) => {
    setJoin(e.target.value);
  };
  const removeJoin = (value) => {
    setJoin(join.filter((d) => d !== value));
  };
  const removeDevice = (value) => {
    setDeviceIds(deviceIds.filter((d) => d !== value));
  };

  const clearStates = () => {
    setSelectedProductType("");
    setSelectedVersion("");
    setSelectedCategory("");
    setSelectedBin(undefined);
    setBinFileName("");
    setParameters([]);
    setExistingFlowChart("");
    setFlowChart(undefined);
    setFlowChartName("");
    setDeviceIds([]);
    setProductTypeError(false);
    setVersionError({ isError: false, message: "" });
    setBinError({ error: false, errorMsg: "" });
    setFlowChartError(false);
    setParametersAddedError(false);
    setSelectedId("");
    setSelectedVersionClone("");
    setPreviousVersions([]);
    setMajorVersionUpgrade(false);
  };

  useEffect(() => {
    setStep(0);
    steps.map((s, i) => {
      if (i === 0) {
        s.activate = true;
      } else {
        s.activate = false;
        setFlowChartError(false);
        setParametersAddedError(false);
      }
    });
    setSteps(steps);
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setParameters([]);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`} >



        {addParameter ? (
          // ***** This is add new parameter input filed set ***** //
          <>
            <Dialog open={addParameter} maxWidth="md" fullWidth>
              <Grid container>
                <Grid item xs={10} md={10}>

                  <DialogTitle>
                    {type === "view" ? "View" : type === "edit" ? "Edit" : "Add"}{" "}
                    {addParameter ? "Parameter" : "Firmware"}
                  </DialogTitle>

                </Grid>
                <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
                  <IconButton
                    onClick={() => {
                      addParameter ? closeAddParameter() : clearStates();
                    }}
                  >
                    <CloseIconColor />
                  </IconButton>
                </Grid>
              </Grid>

              <DialogContent>
                <Grid container style={{ textAlign: "center", marginTop: "20px" }}>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{ textAlign: "left", paddingBottom: "20px" }}
                  >
                    <Typography >Parameter name<span className={classes.required}>*</span> </Typography>
                    <FormControl className={classes.input}>
                      <input
                        className={classes.formInput}
                        disabled={type === "view" ? true : false}
                        onChange={(e) => {
                          setParameter(e.target.value);
                          setParameterError({ error: false, errorMsg: "" });
                        }}
                        value={
                          type === "edit" || type === "view"
                            ? parameters[index].parameter
                            : parameter
                        }
                      />

                    </FormControl>
                    {parameterError.error && (
                      <Typography className={classes.errorText}>{parameterError.errorMsg}</Typography>)}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography >Parameter ID<span className={classes.required}>*</span></Typography>
                    <FormControl className={classes.input}>
                      <input
                        disabled={type === "add-new" ? false : true}
                        className={classes.formInput}
                        type="number"
                        value={type === "edit" || type === "view"
                          ? parameters[index].parameterId
                          : id}
                        onChange={(e) => {
                          setId(e.target.value);
                          setIdError({ error: false, errorMsg: "" });
                        }}
                      />
                    </FormControl>
                    {idError.error && (
                      <Typography className={classes.errorText}>{idError.errorMsg}</Typography>)}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{ textAlign: "left", paddingBottom: "20px" }}
                  >
                    <Typography className={classes.label}>
                      Input Type <span className={classes.required}>*</span>
                    </Typography>
                    <FormControl style={{ padding: "0px", width: "100%" }}>
                      <NativeSelect
                        disabled={type === "view" ? true : false}
                        id="demo-customized-select-native"
                        value={
                          type === "view" ? parameters[index].inputType : inputType
                        }
                        className={classes.input}
                        onChange={(e) => { setInputType(e.target.value); setInputTypeError({ error: false, errorMsg: "" }) }}
                        input={<BootstrapInput />}
                      >
                        <option value="" disabled hidden>
                          Select input type
                        </option>
                        <option value="text">Text</option>
                        <option value="json">Json</option>
                        <option value="Html">Html</option>
                      </NativeSelect>
                      <Typography className={classes.errorText}>{inputTypeError.errorMsg}</Typography>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography className={classes.label}>Data Format <span className={classes.required}>*</span></Typography>
                    <FormControl style={{ padding: "0px", width: "100%" }}>
                      <NativeSelect
                        disabled={type === "view" ? true : false}
                        id="demo-customized-select-native"
                        value={type === "view" ? parameters[index].format : format}
                        className={classes.input}
                        onChange={(e) => { setFormat(e.target.value); setFormatError({ error: false, errorMsg: "" });; }}
                        input={<BootstrapInput />}
                      >
                        <option value="" disabled hidden>
                          Select format type
                        </option>
                        <option value="String">String</option>
                        <option value="Integer">Integer</option>
                        <option value="Double">Double</option>
                        <option value="Float">Float</option>
                        <option value="Char">Char</option>
                      </NativeSelect>
                      <Typography className={classes.errorText}>{formatError.errorMsg}</Typography>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{ textAlign: "left", paddingBottom: "20px" }}
                  >
                    <Typography className={classes.label}>Default Value<span className={classes.required}>*</span></Typography>
                    <FormControl className={classes.input}>
                      <input
                        className={classes.formInput}
                        disabled={type === "view" || format === ""}
                        onChange={(e) => {
                          setDefaultVal(e.target.value);
                          if (format == "Char" && e.target.value.length > 1) {
                            setDefaultValueError({ error: true, errorMsg: "Cannot add more than 1 character" })
                          }
                          else {
                            setDefaultValueError({ error: false, errorMsg: "" })
                          }
                        }}
                        value={
                          type === "view"
                            ? parameters[index].defaultValue
                            : defaultVal
                        }
                      />
                    </FormControl>
                    {defaultValueError.error && (
                      <Typography className={classes.errorText}>
                        {defaultValueError.errorMsg}</Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography className={classes.label}>
                      Join parameter
                    </Typography>
                    <FormControl className={classes.input}>
                      <Select
                        disabled={type === "view" ? true : false}
                        id="demo-customized-select-native"
                        value={
                          type === "view" ? parameters[index].joinParameter : join
                        }
                        className={classes.input}
                        onChange={selectJoin}
                        multiple
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        renderValue={(selected: string[]) => (
                          <div>
                            {selected.map((value, i) => (
                              <Chip
                                key={i}
                                label={value}
                                className={classes.chip}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={() => removeJoin(value)}
                              />
                            ))}
                          </div>
                        )}
                        input={<BootstrapInput />}
                      >
                        {type === "add-new" ? (
                          parameters
                            .filter((obj) => obj.parameterCategory === mainType)
                            .map((p, j) => {
                              return (
                                <MenuItem value={p.parameterId} key={j}>
                                  <ListItemText
                                    primary={p.parameterId}
                                    style={{ color: primaryGray }}
                                  />
                                </MenuItem>
                              );
                            })
                        ) : type === "edit" ? (
                          parameters
                            .filter((obj) => obj.parameterCategory === mainType)
                            .filter((ob) => ob.parameterId !== parameters[index].parameterId)
                            .map((p, j) => {
                              return (
                                <MenuItem value={p.parameterId} key={j}>
                                  <ListItemText
                                    primary={p.parameterId}
                                    style={{ color: primaryGray }}
                                  />
                                </MenuItem>
                              );
                            })
                        ) : (
                          <div></div>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              {type !== "view" && (
                <DialogActions style={{ width: "100%" }} hidden>
                  <Button
                    variant="contained"
                    className={classes.cancel}
                    onClick={closeAddParameter}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.add}
                    disabled={type === "view" ? true : false}
                    onClick={() => {

                      readParameters({
                        parameterCategory: mainType,
                        parameter: parameter,
                        parameterId: id,
                        inputType: inputType,
                        format: format,
                        joinParameter: join,
                        defaultValue: defaultVal,
                      });
                    }}
                  >
                    {type === "edit" ? "Update" : "Save"}
                  </Button>
                </DialogActions>
              )}
            </Dialog>
          </>
        ) : (
          // ***** This is add normal pop-up steps ***** //
          <>
            <Grid item xs={12} container alignItems="center" style={{ padding: "10px 5px 5px" }}>
              {steps.map((step, index) => (

                <Hidden key={index}>
                  {index === 0 && <Grid item xs={1} md={1}></Grid>}

                  <Grid item xs={2} md={2}>
                    <Circle
                      number={index + 1}
                      active={step.activate}
                      completed={steps.findIndex(s => s.activate) > index}
                    />
                  </Grid>

                  {index !== 2 && (
                    <Grid item xs={2} md={2} style={{ width: "100px" }}>
                      <hr
                        style={{
                          opacity: steps[index + 1].activate ? "1" : "0.12",
                        }}
                        color={
                          steps[index + 1].activate
                            ? primaryBlue
                            : "rgba(112, 112, 112, .12)"
                        }
                      />
                    </Grid>
                  )}
                </Hidden>
              ))}
              {/* <Grid item xs={1} md={1}>
                  </Grid> */}
              {steps.map((step, index) => (
                <Grid item xs={4} md={4} key={index}>
                  <Typography className={classes.circleText}
                    style={{
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              ))}

            </Grid>
            <Grid container className={classes.componentContainer}>
              <Grid item className={classes.contentContainer}>
                {step === 0 && (
                  <Step01
                    productTypeError={productTypeError}
                    versionError={versionError}
                    binError={binError}
                    readVersion={(version) => readVersion(version)}
                    defaultVersion={selectedVersion}
                    readProductType={(productType) =>
                      readProductType(productType)
                    }
                    defaultProductType={selectedProductType}
                    readBin={(file) => readBin(file)}
                    binFileName={binFileName}
                    setAvailableBinFiles={setAvailableBinFiles}
                  />
                )}
                {step === 1 && (
                  <Step02
                    openNetworkVisibility={() => openNetworkVisibility()}
                    openTopicVisibility={() => openTopicVisibility()}
                    openMessageVisibility={() => openMessageVisibility()}
                    networkVisibility={networkVisibility}
                    topicVisibility={topicVisibility}
                    messageVisibility={messageVisibility}
                    open={(mainType, type, i) => {
                      if (type == "delete") {
                        openDeleteParameter(i);
                      }
                      else {
                        openAddParameter(mainType, type, i)
                      }
                    }
                    }
                    parameters={parameters}
                    intialParameters={intialParameters}
                    setParameters={setParameters}
                    productType={selectedProductType}
                    deviceCategory={selectedCategory}
                    version={selectedVersion}
                    readVersionClone={(version) => readVersionClone(version)}
                    previousVersions={previousVersions}
                    selectedVersionClone={selectedVersionClone}
                    readParameters={(parameters) => readParameters(parameters)}
                    readParametersCloned={(parameters) =>
                      readParametersCloned(parameters)
                    }
                    readMessage={(message) => readMessage(message)}
                    parametersAddedError={parametersAddedError}
                    readMajorVersionUpgrade={(upgrade) =>
                      readMajorVersionUpgrade(upgrade)
                    }
                    majorVersionUpgrade={majorVersionUpgrade}
                    remotelyConfigurable={remotelyConfigurable}

                  />
                )}
                {step === 2 && (
                  <Step03
                    readExistingFlowChart={(fileName) =>
                      readExistingFlowChart(fileName)
                    }
                    defaultFlowChart={existingFlowChart}
                    flowChartError={flowChartError}
                    readFlowChart={(file) => readFlowChart(file)}
                    chartFilename={flowChartName}
                    selectedProductType={selectedProductType}
                    addFlowChart={addFlowChart}
                    setAddFlowChart={setAddFlowChart}
                    flowChartFileName={flowChartFileName}
                    readFileName={readFileName}
                    tab={tab}
                    setTab={setTab}
                    flowChartFileNameError={flowChartFileNameError}
                    resetAddFlowChart={resetAddFlowChart}
                    handleSwitchTab={handleSwitchTab}
                    existingFlowChartList={existingFlowChartList}
                    setExistingFlowChartList={setExistingFlowChartList}

                  />
                )}
              </Grid>

              <Grid item xs={12} md={12} className={classes.root} style={{ width: '100%' }}>
                <Grid container justifyContent="flex-end" className="testing">
                  <Button
                    onClick={decreaseStep}
                    variant="contained"
                    className={classes.backButton}
                    style={{ marginRight: '12px' }}
                  >
                    {step === 1 || step === 2 ? "Back" : "Cancel"}
                  </Button>
                  <Button
                    onClick={increaseStep}
                    variant="contained"
                    disabled={loading}
                    className={`${classes.getStarted} ${classes.button}`}
                  >
                    {step === 0 || step === 1 ? (
                      "Next"
                    ) : loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {loadingScreen &&
          <div style={{
            position: 'fixed',
            left: 0,
            top: 0,
            backgroundColor: '#000000',
            opacity: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            width: '100vw',
            height: '100vh'
          }}>
            <CircularProgress size={80} />
          </div>}
        <AlertDialog openDialog={openDialog} setOpenDialog={setOpenDialog} handleSkip={handleSkip} OKText={"Skip"} severity={"default"} title={"No remote configurations support"} description={"Step 2 will be skipped because the selected product type does not support remote configuration."} />
        <AlertDialog openDialog={openDialogSwitchTab} setOpenDialog={setOpenDialogSwitchTab} handleSkip={handleDiscardTab} OKText={"Switch"} severity={"default"} title={"Discard Changes?"} description={"Are you sure want to discard changes made here?"} />
        <AlertDialog openDialog={openDelete} setOpenDialog={setOpenDelete} handleSkip={handleDeleteParameter} OKText={"Delete"} severity={"danger"} title={"Confirm Delete?"} description={"Are you sure you want to remove this parameter ?"} />

        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity={status === true ? "success" : "warning"}>
            <h3 style={{ textAlign: "left" }}>
              {status === true ? "Success" : "Error"}
            </h3>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: props.completed ? primaryBlue : white,
          border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};


export default function AlertDialog(props) {
  const buttonColor = props.severity && props.severity == "danger" ? primaryRed : primaryBlue;

  const handleClose = () => {
    props.setOpenDialog(false)
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleSkip} style={{ backgroundColor: buttonColor, color: "white" }}>{props.OKText ? props.OKText : "OK"}</Button>
          <Button onClick={handleClose} >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
