import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import {primaryBlue, white} from "src/components/colors";
import {DownArrowIcon, HorizontalLine, QuestionIcon, UpArrow} from "src/components/Icons";
import {environment} from "src/environments/environment";
import {AlertBar} from "src/components/sub-components/AlertBar";
import {useHistory} from "react-router-dom";
import {ErrorSharp, Visibility, VisibilityOff} from "@material-ui/icons";
import {Loader} from "../step-3/components/Loader";
import useStyles from "src/app/maintenance/assets/styles";
import { updateUser } from "src/app/maintenance/test-automation/components/apiHelper";
import {userActionType,status} from '../../../app/utils'
import { countryCodes } from "./CountryCodes";
import Cookies from "js-cookie";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function AddUserComponent(props) {
  const style  = useStyles();
  const classes = localStyle();
  const [isAdvanceOption, setIsAdvanceOption] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [userRoleId, setUserRoleId] = useState(null)
  const [selectedReferences, setSelectedReferences] = useState('');
  const [addAnotherUser, setAddAnotherUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [references, setReferences] = useState([]);
  const [hostUrl, setHostUrl] = useState('');

  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState({password: false, confirmPassword: false})
  const [whatsapp,setWhatsapp] = useState('')
  const [countryCode, setCountryCode] = useState("");
  const [selectedUser,setSelectedUser] = useState({})
  const userStatus = [status.ACTIVE,status.INACTIVE]
  const [selectedStatus,setSelectedStatus] = useState('')
  //error states
  const [loading, setLoading] = useState(false);
  const [errors,setErrors] = useState({
    name:null,
    email:null,
    mobile:null,
    whatsapp:null,
    countryCode:null,
    role:null,
    reference:null,
    username:null,
    password:null,
    confirmPassword:null
  })

  //alert states
  const [alertOpen, setAlertOpen] = useState(false)
  const [severity, setSeverity] = useState("success")
  const [topic, setTopic] = useState("")
  const [message, setMessage] = useState("")
  const userId = Cookies.get('USER_ID');
  const history = useHistory();

  type User = {
    lastName: string;
    email: string;
    mobile: string;
    userRoleId: any;
    references?: any[];
    username?: string;
    password?: string;
    confirmPassword?: string;
    host: any;
    whatsApp : string,
    status?:string
  };


  const handleAdvanceOption = () => {
    setIsAdvanceOption(!isAdvanceOption);
  };

  const handleCheckboxChange = (event) => {
    setAddAnotherUser(event.target.checked);
  };

  const showErrorMessage = () => {
    setAlertOpen(true);
    setSeverity("error");
    setTopic("Error");
    setMessage("Email already exists");
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }

  const showMobileErrorMessage = () => {
    setAlertOpen(true);
    setSeverity("error");
    setTopic("Error");
    setMessage("Mobile Number already exists");
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }

  useEffect(() => {
    const userId = Cookies.get('USER_ID');
    const fetchMetaData = async () => {
      try {
        const response = await axios.get(`${environment.host}/user/${userId}/metadata`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },

        });
        const metaData = response.data;
        const hostUrl = metaData.content.configuration.configHost;
        setHostUrl(hostUrl);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };
    if(props.type == userActionType.USER_ADD){
      fetchMetaData();
    }
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${environment.host}/ums/super/user-role`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
        if (response.data.content) {
          setRoles(response.data.content);
          const lastRole = response.data.content[response.data.content.length - 1];
          setSelectedRole(lastRole.name);
          setUserRoleId(lastRole.id)
          setLoading(false);
        } else {
          console.error('Error fetching roles:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching the roles:', error);
      }
    };
    if(props.type == userActionType.USER_ADD){
      fetchRoles();
    }
  }, []);

  const referenceId = selectedReferences ? references.find(ref => ref.name === selectedReferences).id : '';

  useEffect(() => {
    const userRoleId = (props.type === userActionType.USER_ADD ? roles : props.roles).find(role => role.name === selectedRole) ? (props.type === userActionType.USER_ADD ? roles : props.roles).find(role => role.name === selectedRole)!.id : undefined;
    const fetchReferences = async () => {
      if (!userRoleId && props.type !== userActionType.ALERT_ADD) {
        return;
      }
      try {
        setLoading(true)
        const response = await axios.get(`${environment.host}/ums/user/${userId}/system-user/${props.type == userActionType.ALERT_ADD ? userId : props.selectedUserId}/references`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
        if (response.data.content) {
          setReferences(response.data.content);
          setSelectedReferences(response.data.content[0].name)
        } else {
          console.error('Error fetching references:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching the references:', error);
        setAlertOpen(true)
        setSeverity("warning");
        setTopic("Warning");
        setMessage(error.message);
      }finally{
        setLoading(false)
        setTimeout(() =>{
          setAlertOpen(false)
        },3000)
      }
    }
    fetchReferences();
  }, [selectedRole, roles, props.roles]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${environment.host}/ums/user/${userId}/system-user?filter=none`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
        if (response.data.content) {
          setUsers(response.data.content);
          console.log('Users:', response.data.content);
        } else {
          console.error('Error fetching users:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    if(props.type == userActionType.USER_ADD){
      fetchUsers();
    }
    
  }, []);

  const saveUser = async () => {
    let user: User = {
      lastName: lastName,
      email: email,
      mobile: mobile,
      ...(props.type !== userActionType.ALERT_EDIT && { userRoleId: userRoleId }), // Conditionally add userRoleId
      references: referenceId ? [referenceId] : [],
      host: props.type == userActionType.USER_ADD ? hostUrl : props.host,
      whatsApp : countryCode+whatsapp
    };

    let selectUser = {
      ...selectedUser,
      ...user,
      status : selectedStatus
    }

    // If advance option is enabled, include username, password, and confirmPassword in the user object
    if (isAdvanceOption) {
      user = {
        ...user,
        username: username,
        password: password,
        confirmPassword: confirmPassword
      };
    }
    try {
      setLoading(true);
      let response
      if (props.type === userActionType.ALERT_EDIT) {
        response = await updateUser(selectUser, props.selectedUserId);
        if(response.status == 200){
          resetUserForm();
          props.handleFormClose()
          props.setAlertOpen(true);
          props.setSeverity("success");
          props.setTopic("Success");
          props.setMessage("User updated successfully.");

        }else{
          setAlertOpen(true);
          setSeverity("warning");
          setTopic("Warning");
          setMessage(response.response.data.message);
        }
        setTimeout(() => setAlertOpen(false), 3000);
      } else {
        // Add new user logic
        response = await axios.post(`${environment.host}/ums/super/user`,user, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
  
        if (response.status === 200) {
          resetUserForm();
          if(props.type == userActionType.USER_ADD){
            setAlertOpen(true);
            setSeverity("success");
            setTopic("Success");
            setMessage("User added successfully. Please go to the Dashboard to activate the user");
            if (!addAnotherUser) {
              redirectToHomePage();
            }
            window.dispatchEvent(new Event('userAdded'));
          }else{
            props.handleFormClose()
            props.setAlertOpen(true);
            props.setSeverity("success");
            props.setTopic("Success");
            props.setMessage("User added successfully.");
            window.dispatchEvent(new Event('userAdded'));
          }
        }
      }
      setTimeout(() => {
        setAlertOpen(false)
        props.setAlertOpen(false)
      }, 3000);
      props.getAllUser()
    } catch (error) {
      setAlertOpen(true);
      setSeverity("error");
      setTopic("Error");
      setMessage(error.response.data.message || "An unexpected error occurred.");
      if(error.response.data.errorCode == "EU0516"){
        props.setIsUpgrade(true)
      }
    } finally {
      setLoading(false);
      setTimeout(() => setAlertOpen(false), 3000);
    }
  };

  const resetUserForm = () => {
    setLastName('');
    setEmail('');
    setMobile('');
    setSelectedRole(selectedRole);
    setSelectedReferences('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setAddAnotherUser(false);
    setWhatsapp('')
  };

  const redirectToHomePage = () => {
    resetUserForm();
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };

  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const handleAddUser = async () => {
    let isError = false;
    let error : any = {}

    if (!(hostUrl || props.host) && !isAdvanceOption) {
      setAlertOpen(true);
      setSeverity("error");
      setTopic("Error");
      setMessage("UMS host url is not set, please contact admin or use with Advance option");
      setTimeout(() => {
        setAlertOpen(false);
      }, 5000);
    }

    if (lastName.trim() === '') {
      // setLastNameError(true);
      error.name = 'Name is required'
    }
    if (email.trim() === '') {
      // setEmailError(true);
      error.email = 'Email is required'
    }else if (email.trim() && !isEmailValid(email)) {
      // setEmailFormatError(true);
      error.email = 'Email is not valid'
    } 

    const isMobileValid = /^\d+$/.test(mobile.trim());
    if (mobile.trim() === '') {
      error.mobile = props.type == userActionType.USER_ADD ? 'Mobile Number is required' : 'Phone Number is required'
    }else if (mobile.trim() && !isMobileValid) {
      error.mobile = props.type == userActionType.USER_ADD ? 'Mobile Number is not valid' : 'Phone Number is not valid'
    }

    const isWhatsappValid = /^\d+$/.test(whatsapp.trim());
    if(props.type !== userActionType.USER_ADD ){
      if (whatsapp.trim() === '') {
        error.whatsapp = 'Whatsapp Number is required'
      }else if (whatsapp.trim() && !isWhatsappValid) {
        error.whatsapp =  'Whatsapp Number is not valid'
      } 
    }
    if(countryCode===""){
      error.countryCode="Country code is required"
    }
    if (selectedRole === '' && props.type !== userActionType.ALERT_EDIT) {
      error.role = 'User Role is required'
    }
    if (selectedReferences === '' && userRoleId >= 10) {
      error.reference = 'User Reference is Required'
    }

    if (isAdvanceOption) {
      if (username.trim() === '') {
        error.username = 'Username is required '
      }
      if (password.trim() === '') {
        error.password = 'Password is required'
      }else if (password.trim() && password.trim().length < 8) {
        error.password = 'Password must be 8 or more characters'
      } 
      if (confirmPassword.trim() === '') {
        error.confirmPassword = 'Confirm Password is required'
      }
      
      if (confirmPassword.trim() && password.trim() !== confirmPassword.trim()) {
        error.confirmPassword = 'Password Should match'
      } 
    }
    setErrors(error)
    if (Object.keys(error).length > 0) {
      setLoading(false)
      return true;
    }

    const mobileExists = users.some((user) => user.mobile === mobile);

    if (mobileExists) {
      showMobileErrorMessage();
    }

    const emailExists = users.some((user) => user.email === email);
    console.log('Email exists:', emailExists)

    if (emailExists) {
      showErrorMessage();
    }
    try {
      setLoading(true)
      await saveUser();
      if(props.type == userActionType.USER_ADD){
        localStorage.setItem('userCreated', 'true');
      }
      
      // redirectToMainPage(true);
    } catch (error) {
      console.error('Error add user:', error);
    }finally{
      setLoading(false)
    }
  };

  const handleNameChange = (event) => {
    setLastName(event.target.value);
    setErrors((prevError) =>({
      ...prevError,
      name:null
    }))
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors((prevError) =>({
      ...prevError,
      email:null
    }))
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
    setErrors((prevError) =>({
      ...prevError,
      mobile:null
    }))
  };

  const handleWhatsappChange = (event) =>{
    setWhatsapp(event.target.value);
    setErrors((prevError) =>({
      ...prevError,
      whatsapp:null
    }))
  }

  const handleReferencesChange = (event) => {
    setSelectedReferences(event.target.value);
    setErrors((prevError) =>({
      ...prevError,
      reference:null
    }))
  }

  const handleUserStatus = (event) =>{
    setSelectedStatus(event.target.value)
  }

  const handleRoleChange = (event) => {
    const roleName = (props.type === userActionType.USER_ADD ? roles : props.roles).find(role => role.id === parseInt(event.target.value)).name
    setSelectedRole(roleName);
    setUserRoleId(event.target.value)
    setErrors((prevError) =>({
      ...prevError,
      role:null
    }))
  };

  const handlePhoneCode=(e)=>{
    setCountryCode(e.target.value)
    setErrors((prevError) =>({
      ...prevError,
      countryCode:null
    }))
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setErrors((prevError) =>({
      ...prevError,
      username:null
    }))
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value.trim());
    setErrors((prevError) =>({
      ...prevError,
      password:null
    }))
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value.trim());
    setErrors((prevError) =>({
      ...prevError,
      confirmPassword:null
    }))
  };

  const handleClickShowPassword = (id) => {
    if (id == 'password') {
      setShowPassword((prevState) => ({
        ...prevState,
        password: !prevState.password
      }))
    } else {
      setShowPassword((prevState) => ({
        ...prevState,
        confirmPassword: !prevState.confirmPassword
      }))
    }

  }

  function filterDialCode(input) {
    let countries = countryCodes;
    for (const country of countries) {
        if (input.startsWith(country.dial_code)) {
            return input.replace(country.dial_code, ''); 
        }
    }
    return input; 
  }

  function getCountryCode(input) {
    let countries = countryCodes;
    const matchedCountry = countries.find(country => input.startsWith(country.dial_code));
    return matchedCountry ? matchedCountry.dial_code : ""; 
  }

  useEffect(() =>{
    const fetchSelectedUser = async () => {
      if(props.type == userActionType.ALERT_EDIT){
        try {
          const response = await axios.get(`${environment.host}/ums/user/${userId}/system-user/${props.selectedUserId}`, {
            headers: {
              Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
            },
          });
          if (response.data.content) {
            const selectUser = response.data.content
            const { userRoleId, ...filteredUser } = response.data.content;
            setSelectedUser(filteredUser);
            setEmail(selectUser.email)
            setLastName(selectUser.lastName)
            setMobile(selectUser.mobile)
            setUserRoleId(selectUser.userRoleId)
            setUsername(selectUser.username)
            setPassword(selectUser.password)
            setConfirmPassword(selectUser.confirmPassword)
            const roleName = props.roles.find(role => role.id === selectUser.userRoleId).name
            setSelectedRole(roleName);
            selectUser.whatsApp && selectUser.whatsApp !== "" ? setWhatsapp(filterDialCode(selectUser.whatsApp)) : setWhatsapp(selectUser.mobile)
            selectUser.whatsApp && selectUser.whatsApp !== "" && setCountryCode(getCountryCode(selectUser.whatsApp))
            setSelectedStatus(selectUser.status)
          } else {
            console.error('Error fetching users:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      }
    };
    fetchSelectedUser()
  },[props.selectedUserId])

  console.log(props.type)
  

  return (
    <div className={`testing`} style={{padding : props.type === 'alert' && '10px 0px'}}>
      {props.type == userActionType.USER_ADD && <div style={{fontSize: '16px', lineHeight: '24px', marginTop: '15px'}}>
        <h4>Add Users</h4>
      </div>}
      <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1} style={{paddingTop: props.type == userActionType.USER_ADD ? "50px" : "0px" , marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
        <Grid item xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
          <Grid container alignItems={props.type == userActionType.USER_ADD ? "center" : "flex-start"}>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Name
                <span className={classes.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder={props.type == userActionType.USER_ADD ? "Enter Your Name" : "Add your name here"}
                  value={lastName}
                  onChange={(e) => {
                    handleNameChange(e);
                  }}
                />
                {errors.name && (
                  <span className="form-error">
                    {errors.name}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
      </Grid>

      <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1} style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
      {props.type == userActionType.USER_ADD &&<Grid item xs={1} md={3}></Grid>}
        <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Email
                <span className={classes.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="email"
                  placeholder={props.type == userActionType.USER_ADD ? "Enter Your Email" : "example@gmail.com" }
                  value={email}
                  onChange={(e) => {
                    handleEmailChange(e);
                  }}
                />
                {errors.email && (
                  <span className="form-error">
                    {errors.email}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
      </Grid>

      <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1} style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
      {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
        <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
              {props.type == userActionType.USER_ADD  ? 'Mobile' : 'Phone No'}
                <span className={classes.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder="Enter Your Mobile Number"
                  value={mobile}
                  onChange={(e) => {
                    handleMobileChange(e);
                  }}
                />
                {errors.mobile && (
                  <span className="form-error">
                   {errors.mobile}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
      </Grid>
      <Grid container spacing={1} style={{marginBottom:"10px"}}>
        <Grid item  xs={12} md={ 12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
              {'Whatsapp No'}
                <span className={classes.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
            <FormControl className={classes.dropInput}>
                <select
                  className={style.formInput}
                  value={countryCode}
                  onChange={handlePhoneCode}
                >
                  {props.type == 'alert-add' &&
                    <option value="" disabled>
                      Please Select Your Country Code
                    </option>
                  }
                  {countryCodes.map((code,i) => (
                    <option key={i} value={code.dial_code}>
                      {code.dial_code +" ("+code.name+")"}
                    </option>
                  ))}
                </select>
                {errors.countryCode  && (
                  <span className="form-error">
                    {errors.countryCode}
                  </span>
                )}
              </FormControl>
              <FormControl className={classes.whatsApp}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder='Enter Your Whatsapp Number'
                  value={whatsapp}
                  onChange={(e) => {
                    handleWhatsappChange(e);
                  }}
                />
                {errors.whatsapp && (
                  <span className="form-error">
                   {errors.whatsapp}
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
      </Grid>

      {props.type !== userActionType.ALERT_EDIT && <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1} style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
          <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
            <Grid container alignItems="center">
              <Grid item xs={4} md={3}>
                <Typography className={classes.text}>
                  {props.type == userActionType.USER_ADD ? 'Role' : 'User Role'}
                  <span className={classes.required}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
                <FormControl className={classes.input}>
                  <select
                    className={style.formInput}
                    value={userRoleId || ''}
                    onChange={handleRoleChange}
                  >
                    {props.type !== userActionType.USER_ADD && <option value="" disabled hidden>Select Role</option>}
                    {(props.type === userActionType.USER_ADD ? roles : props.roles).map((role) => (
                      <option key={role.id} value={role.id}>
                        {`${role.name} (${role.id})`}
                      </option>
                    ))}
                  </select>
                  {errors.role && (
                    <span className="form-error">
                      {errors.role}
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
        </Grid>
      }
      {selectedRole && userRoleId >= 10 && references && Array.isArray(references) &&
        <Grid container spacing={props.type === userActionType.USER_ADD ? 5 : 1} style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
          {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
          <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
            <Grid container alignItems="center">
              <Grid item xs={props.type == userActionType.USER_ADD ? 4 : 12} md={props.type == userActionType.USER_ADD ? 3 : 12}>
                <Typography className={classes.text}>
                  User References
                  <span className={classes.required}>*</span> 
                </Typography>
              </Grid>
              <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
                <FormControl className={classes.input}>
                  <select
                    className={style.formInput}
                    value={selectedReferences}
                    onChange={handleReferencesChange}
                  >
                    <option value="" disabled hidden>Select References</option>
                    {references.map((ref) => (
                      <option key={ref.id} value={ref.name}>{ref.name}</option>
                    ))}
                  </select>
                  {
                    errors.reference && (
                      <span className="form-error">
                        {errors.reference}
                      </span>
                    )
                  }
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
        </Grid>
      }
      {props.type == userActionType.ALERT_EDIT &&
        <Grid container spacing={1} style={{marginBottom:"10px"}}>
          <Grid item  xs={12} md={ 12}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={ 12}>
                <Typography className={classes.text}>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.input}>
                  <select
                    className={style.formInput}
                    value={selectedStatus}
                    onChange={handleUserStatus}
                  >
                    {userStatus.map((status,index) => (
                      <option key={index} value={status}>{status}</option>
                    ))}
                  </select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {props.type !== userActionType.ALERT_EDIT && <>
      <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1} style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
      {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
        <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
          <Grid container alignItems="center">
            <Grid item xs={5} md={4}>
              <Typography className={classes.text}>
                Advance Option
              </Typography>
            </Grid>
            <Grid item xs={6} md={7} style={{marginTop: '5px', marginLeft: '0px'}}>
              <hr/>
            </Grid>
            <Grid onClick={() => handleAdvanceOption()} style={{cursor: 'pointer'}}>
              {isAdvanceOption ?
                <UpArrow/>
                : <DownArrowIcon/>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isAdvanceOption ?
        <div>
          <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1}  justifyContent="center" style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
          {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
            <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
              <Grid container alignItems="center" style={{marginTop: '20px'}}>
                <Grid item xs={4} md={3}>
                  <Typography className={classes.text}>
                    Username
                    <span className={classes.required}>*</span> 
                  </Typography>
                </Grid>
                <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
                  <FormControl className={classes.input}>
                    <input
                      className={style.formInput}
                      type="text"
                      placeholder="Enter Your Username"
                      autoComplete="off"
                      value={username}
                      onChange={(e) => {
                        handleUsernameChange(e);
                      }}
                    />
                    {errors.username && (
                      <span className="form-error">
                        {errors.username}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
          </Grid>

          <Grid container spacing={props.type == userActionType.USER_ADD ? 5 : 1} justifyContent="center" style={{marginBottom: props.type !== userActionType.USER_ADD && "10px"}}>
          {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
            <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <Typography className={classes.text}>
                    Password
                    <span className={classes.required}>*</span> 
                    <Tooltip
                      arrow
                      title="Password must include at least one uppercase letter, one lowercase letter, one number, and one special character (#, $, %, &)"
                      interactive
                      placement="right"
                    >
                      <IconButton className={style.tooltipIcon}>
                        <QuestionIcon/>
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
                  <FormControl className={classes.input}>
                    <Input
                      className={style.formInput}
                      type={showPassword.password ? "text" : "password"}
                      placeholder="Enter Your Password"
                      onChange={(e) => handlePasswordChange(e)}
                      value={password}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className={classes.icon}
                            onClick={
                              () => handleClickShowPassword('password')
                            }
                          >
                            {showPassword.password ? (
                              <VisibilityOff/>
                            ) : (
                              <Visibility/>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.password && (
                      <span className="form-error">
                        {errors.password}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
          </Grid>

          <Grid container spacing={5} justifyContent="center">
          {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
            <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
              <Grid container alignItems="center">
                <Grid item xs={props.type == userActionType.USER_ADD ? 4 : 12} md={props.type == userActionType.USER_ADD ? 3 : 12}>
                  <Typography className={classes.text}>
                    Confirm Password
                    <span className={classes.required}>*</span> 
                  </Typography>
                </Grid>
                <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
                  <FormControl className={classes.input}>
                    <Input
                      className={style.formInput}
                      type={showPassword.confirmPassword ? "text" : "password"}
                      placeholder={props.type == userActionType.USER_ADD ? "Please Confirm Your Password" : "Re-enter your password"}
                      onChange={(e) => handleConfirmPasswordChange(e)}
                      value={confirmPassword}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className={classes.icon}
                            onClick={
                              () => handleClickShowPassword('confirmPassword')
                            }
                          >
                            {showPassword.confirmPassword ? (
                              <VisibilityOff/>
                            ) : (
                              <Visibility/>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.confirmPassword && (
                      <span className="form-error">
                        {errors.confirmPassword}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
          </Grid>
        </div>
        : <></>
      }
      </>}

      {props.type == userActionType.USER_ADD && <Grid container spacing={5}>
      <Grid item xs={1} md={3}></Grid>
        <Grid item  xs={props.type == userActionType.USER_ADD ? 10 : 12} md={props.type == userActionType.USER_ADD ? 6 : 12}>
          <Grid container alignItems="center">
            {/* <Grid item xs={4} md={3}></Grid> */}
            <Grid item xs={props.type == userActionType.USER_ADD ? 8 : 12} md={props.type == userActionType.USER_ADD ? 9 : 12}>
              <FormGroup>
                <FormControlLabel
                  className={classes.text}
                  control={
                    <Checkbox 
                      className={style.radio}
                      checked={addAnotherUser}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Add Another User"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>}

      <Grid container spacing={5}>
      {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
        <Grid item  xs={props.type === userActionType.USER_ADD ? 10 : 12} md={props.type === userActionType.USER_ADD ? 6 : 12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button className={style.backButton}
                      style={{marginRight:"10px"}}
                      onClick={() =>{props.type === userActionType.USER_ADD ? props.setIsUserClick(false) : props.handleFormClose()}}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button className={style.saveButton}
                      onClick={handleAddUser}
                      style={{marginRight:'0px'}}
              >
                {props.type === userActionType.USER_ADD ? 'Create User' :props.type === userActionType.ALERT_ADD ? 'Add' : 'Update'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {props.type == userActionType.USER_ADD && <Grid item xs={1} md={3}></Grid>}
      </Grid>
      <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
      <Loader loading={loading}/>
    </div>
  );
};

const localStyle = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  dropInput:{
    width:"35%",
    paddingRight:"5px"
  },
  whatsApp:{
    width:"65%"
  },
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif"
  },
  required: {
    color: "red",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  icon:{
   padding:"0px" ,
   opacity:"0.5",
  }
}));

