import { DialogContent, DialogTitle, Dialog, Grid, IconButton, Typography, makeStyles, Theme, Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'
import { SlideIconOffLarge, SlideIconOnLarge } from 'src/components/Icons'

export const AlertPopup = ({alerts,setAlerts}) =>{
    const classes = useLocalStyles()

    const handleAlerts = (type) => {
        // Create a new array to avoid mutating the state directly
        let updatedAlerts;

        if (alerts.includes(type)) {
            // Remove the type if it exists
            updatedAlerts = alerts.filter(alert => alert !== type);
        } else {
            // Add the type if it doesn't exist
            updatedAlerts = [...alerts, type];
        }
        // Update the state
        setAlerts(updatedAlerts);
    };
    
    return(
        <Grid container justifyContent='space-between'>
            <Grid item className={classes.alertCard}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography>Email</Typography>
                    <IconButton onClick={() =>handleAlerts('EMAIL')} size='small'>
                        {alerts.includes('EMAIL') ? <SlideIconOnLarge /> : <SlideIconOffLarge />}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item className={classes.alertCard}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography>Whatsapp</Typography>
                    <IconButton onClick={() => handleAlerts('WHATSAPP')} size='small'>
                        {alerts.includes('WHATSAPP') ? <SlideIconOnLarge /> : <SlideIconOffLarge />}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item className={classes.alertCard}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography>System</Typography>
                    <IconButton onClick={() =>handleAlerts('SYSTEM')} size='small'>
                        {alerts.includes('SYSTEM')? <SlideIconOnLarge /> : <SlideIconOffLarge />}
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

const useLocalStyles = makeStyles((theme: Theme) => ({
    alertCard: {
      border:'1px solid #E5E8EA',
      borderRadius : '8px',
      padding:'5px 10px',
      width:'180px'
    }
  }));