import { Button, createTheme, Grid, IconButton, InputBase, Popover, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import { CancelBatchIcon, ChatBotIcon, CorrectiveIcon, DeviceMonitoringIcon, FailureIncidentIcon, FilterIcon, NotificationIcon, RootCauseInsightIcon, } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { primaryBlue, white } from 'src/components/colors';
import { DataTable } from '../step4/DataTable';
import { Loader } from '../components/Loader';
import { Chatbot } from './Chatbot';
import { NotificationComponent } from './Notification';
import { ToasterComponent } from '../components/Toaster';
import {
    checkDbStatus,
    fetchBatchNumbers,
    fetchChatSessions,
    fetchEventSummary,
    fetchMainTestCases,
    fetchRootCauseAnalyticsSummary,
    getAllNotification,
    getContinuousTestCase,
    getContinuousTestSummery,
    getNotifications,
    getOnGoingContinuousTests,
    getRecentRecord,
    getSuccessCriteria,
    saveJson
} from '../components/apiHelper';
import { formatDateString } from 'src/app/testing-automation/api-helper/apiHelper';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { fetchDashboardCounts } from '../components/apiHelper';
import DeploymentStageComponent from './components/DeploymentStageComponent';
import { DropDown } from '../components/DropDown';
import { DialogPopup  } from '../components/DialogPopup';
import { LineChart } from '../components/LineChart';
import { SelectBatch } from './components/SelectBatch';
import { app } from 'firebase';
import Cookies from "js-cookie";

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

interface CountData {
    continuousFailureDiagnosisDevicesCount: number;
    initialFailureDiagnosisDevicesCount: number;
    devicesCount: number;
    issueIdentifiedDevicesCount: number;
    continuousTestCasesCount: number;
    initialTestCasesCount: number;
    continuousSubTestCasesCount: number;
    initialSubTestCasesCount: number;
    rootCauseIdentifiedDevicesCount: number;
    uniqueModelsCount: number;
}
interface EventSummaryData {
    failedMainTestCases: any[];
    totalFailedDeviceCount: number;
}
interface RootCauseAnalyticsData {
    deviceCountsForRootCausePredictions: any[];
    totalRootCauseIdentifiedDevicesCount: number;
}


export const ResultDashboardComponent = () => {
    const classes = useStyles()
    const history = useHistory();
    const [selectedTestCaseType, setSelectedTestCaseType] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
    const [selectedMainTestCaseTitle, setSelectedMainTestCaseTitle] = useState('');
    const config = {
        botAvatar: "img.png",
        floating: true,
    };
    const [selectedMainTestCaseId, setSelectedMainTestCaseId] = useState('');
    const [filterVisible,setFilterVisible] = useState(false)
    const [testData, setTestData] = useState([]);
    const [originalTestData, setOriginalTestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFormattedTime, setSelectedFormattedTime] = useState('');
    const [testCaseType, setTestCaseType] = useState('')
    const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
    const [devices, setDevices] = useState([]);
    const [continuousMainTestId, setContinuousMainTestId] = useState("");
    const [batchNumbers, setBatchNumbers] = useState([]);
    const [openChatbot, setOpenChatbot] = useState(false)
    const [chatbotData, setChatbotData] = useState([])
    const [testCaseInfo, setTestCaseInfo] = useState({})
    const [isOpenNotification, setIsOpenNotification] = useState(false)
    const [unReadNotifications, setUnreadNotifications] = useState([])
    const [messages, setMessages] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorEl_1, setAnchorEl_1] = useState(null)
    const [tableRootCause, setTableRootCause] = useState([])
    const [messageIdCounter, setMessageIdCounter] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchTerm, setSearchTerm] = useState('')
    const [activeTab, setActiveTab] = useState('post');
    const [selectedBatchNumbers, setSelectedBatchNumbers] = useState([]);
    const [appliedBatchNumbers, setAppliedBatchNumbers] = useState([]);
    const [countData, setCountData] = useState<CountData>({
        continuousFailureDiagnosisDevicesCount: 0,
        devicesCount: 0,
        issueIdentifiedDevicesCount: 0,
        continuousTestCasesCount: 0,
        rootCauseIdentifiedDevicesCount: 0,
        uniqueModelsCount: 0,
        initialFailureDiagnosisDevicesCount: 0,
        initialTestCasesCount: 0,
        continuousSubTestCasesCount: 0,
        initialSubTestCasesCount: 0,
    });
    const [eventSummaryData, setEventSummaryData] = useState<EventSummaryData>({
        failedMainTestCases: [],
        totalFailedDeviceCount: 0,
    });
    const [rootCauseAnalyticsData, setRootCauseAnalyticsData] = useState<RootCauseAnalyticsData>({
        deviceCountsForRootCausePredictions: [],
        totalRootCauseIdentifiedDevicesCount: 0,
    });
    const [totalChatCount, setTotalChatCount] = useState(0)

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)
    const [isOpenPopup,setIsOpenPopup] = useState(false)
    const [graphDetails,setGraphDetails] : any = useState({})
    const [chatIndex,setChatIndex] = useState(0)
    const [responseCount,setResponseCount] = useState(0)

    const expandGraph = () =>{
        // setLoading(true)
        setIsOpenPopup(true)
        setTimeout(() =>{
            setLoading(false)
        },3000)
    }

    const closePopup = () =>{
        setIsOpenPopup(false)
    }

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handleApplyFilter = async (status, updatedBatchNumbers = []) => {
        setLoading(true);
        try{
            const [dashboardResponse, eventSummaryResponse, rootCauseResponse] = await Promise.all([
                fetchDashboardCounts(status === "FILTER" ? updatedBatchNumbers : []),
                fetchEventSummary(status === "FILTER" ? updatedBatchNumbers : []),
                fetchRootCauseAnalyticsSummary(status === "FILTER" ? updatedBatchNumbers : [])
            ]);
            setCountData(dashboardResponse);
            setEventSummaryData(eventSummaryResponse);
            setRootCauseAnalyticsData(rootCauseResponse);
            if (status === "FILTER") {
                setAppliedBatchNumbers((prev) => updatedBatchNumbers);
            } else if (status === "CLOSE") {
                setSelectedBatchNumbers([]);
                setAppliedBatchNumbers([]);
            }
        } catch (err) {
            console.error('Error fetching dashboard data:', err);
        } finally {
            setLoading(false);
            setFilterVisible(false)
        }
    };

    const handleReportHistoryClick = () => {
        const event = new CustomEvent('reportHistoryClicked');
        window.dispatchEvent(event);
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}_${month}_${day}_${hours}_${minutes}`;
    }

    const failedTestData = (eventSummaryData) => {
        const totalFailedDeviceCount = getCountValue(eventSummaryData.totalFailedDeviceCount);

        let processedData = eventSummaryData.failedMainTestCases.map(test => ({
            name: test.mainTestTitle,
            value: ((getCountValue(test.deviceIds.length) / totalFailedDeviceCount) * 100).toFixed(2),
            devices: test.deviceIds.length,
            deviceIds: test.deviceIds,
        }));

        processedData.sort((a, b) => b.value - a.value);

        const mainItems = processedData.slice(0, 4);

        const smallItems = processedData.slice(4);
        if (smallItems.length > 0) {
            const othersValue = smallItems.reduce((sum, item) => sum + parseFloat(item.value), 0);
            const othersDevices = smallItems.reduce((sum, item) => sum + item.devices, 0);
            mainItems.push({
                name: 'Others',
                value: othersValue.toFixed(2),
                devices: othersDevices,
                deviceIds: smallItems.map(item => item.deviceIds).flat()
            });
        }

        const predefinedColors = ['#FF4343', '#EF9D9F', '#B40010', '#6D0000'];
        const colors = mainItems.map((item, index) => {
            return index < 4 ? predefinedColors[index] : '#8E8D8D';
        });

        return {
            labels: mainItems.map(item => item.name),
            datasets: [{
                data: mainItems.map(item => item.value),
                backgroundColor: colors,
                borderWidth: 0,
            }],
            devices: mainItems.map(item => item.devices),
            deviceIds: mainItems.map(item => item.deviceIds),
        };
    };

    const rootCauseData = (rootCauseAnalyticsData) => {
        const totalRootcauseCount = getCountValue(rootCauseAnalyticsData.totalRootCauseIdentifiedDevicesCount);

        let processedData = rootCauseAnalyticsData.deviceCountsForRootCausePredictions.map(rootCause => ({
            name: rootCause.predictions,
            value: ((getCountValue(rootCause.deviceIds.length) / totalRootcauseCount) * 100).toFixed(2),
            devices: rootCause.deviceIds.length,
            deviceIds: rootCause.deviceIds,
        }));

        processedData.sort((a, b) => b.value - a.value);

        const mainItems = processedData.slice(0, 4);

        const smallItems = processedData.slice(4);
        if (smallItems.length > 0) {
            const othersValue = smallItems.reduce((sum, item) => sum + parseFloat(item.value), 0);
            const othersDevices = smallItems.reduce((sum, item) => sum + item.devices, 0);
            mainItems.push({
                name: 'Others',
                value: othersValue.toFixed(2),
                devices: othersDevices,
                deviceIds: smallItems.map(item => item.deviceIds).flat(),
            });
        }

        const predefinedColors = ['#F9BD98', '#72340E', '#AE6E47', '#E5E8EA'];
        const colors = mainItems.map((item, index) => {
            return index < 4 ? predefinedColors[index] : '#8E8D8D';
        });

        return {
            labels: mainItems.map(item => item.name),
            datasets: [{
                data: mainItems.map(item => item.value),
                backgroundColor: colors,
                borderWidth: 0,
            }],
            devices: mainItems.map(item => item.devices),
            deviceIds: mainItems.map(item => item.deviceIds),
        };
    };


    useEffect(() => {
        const getMostRecentRecord = async () => {
            const today = new Date();
            const oneDayAgo = new Date(today);
            oneDayAgo.setDate(today.getDate() - 1);
            setStartDate(formatDateString(oneDayAgo));
            setEndDate(formatDateString(today));

            try {
                const data = await getRecentRecord();
                setSelectedBatch(data.batchNumber)
                setSelectedMainTestCaseTitle(data.mainTestTitle)
                setSelectedTestCaseType("Continuous Testing")
                setTestCaseType(data.testCaseType)
                setSelectedMainTestCaseId(data.id)
                let d = {
                    "mainTestTitle": data.mainTestTitle,
                    "subTestCases": data.subTestCases
                }
                setTestCaseInfo(d)
                try {
                    if(data.batchNumber){
                        const { mainTestTitles, content } = await fetchMainTestCases(data.batchNumber);
                        const uniqueMainTestTitles = [...new Set(mainTestTitles)];
                        setMainTestCasesTitles(uniqueMainTestTitles);
                        setMainTestCasesContent(content);
                    }
                } catch (error) {
                    console.error('Error fetching main test cases:', error);
                }

            } catch (err) {
                console.error('Error fetching batch numbers:', err);
            }
        };
        getMostRecentRecord();
    }, [])

    // Get continuous test summary data
    useEffect(() => {
        const fetchContinuousTestSummaryData = async () => {
            try {
                if (continuousMainTestId && continuousMainTestId !== 'null' && startDate && endDate) {
                    const continuousTestSummaryData = await getContinuousTestSummery(
                        startDate,
                        endDate,
                        continuousMainTestId
                    );
                    setTestData(continuousTestSummaryData);
                    setOriginalTestData(continuousTestSummaryData);
                }
            } catch (err) {
                console.error('Error fetching continuous test summary data:', err);
            }
        };
        if (continuousMainTestId && continuousMainTestId !== 'null') {
            fetchContinuousTestSummaryData();
        }
    }, [
        startDate,
        endDate,
        continuousMainTestId
    ]);

    // Get Continuous Test
    useEffect(() => {
        const fetchContinuousTestId = async () => {
            try {
                if(selectedBatch != '' && selectedMainTestCaseTitle != ''){
                    const continuousTestId = await getContinuousTestCase(selectedBatch, selectedMainTestCaseTitle);
                    setContinuousMainTestId(continuousTestId);
                }
            } catch (err) {
                console.error('Error fetching continuous test case ID:', err);
            }
        };

        fetchContinuousTestId();
    }, [selectedBatch, selectedMainTestCaseTitle]);


    const handleSelectMainTestCase = async (value) => {
        const trimmedValue = value.trim();
        setSelectedMainTestCaseTitle(trimmedValue);
        setSelectedTestCaseType("Continuous Testing")

        const selectedMainTestCase = mainTestCasesContent.find(testCase =>
            testCase.mainTestTitle === value
        );
        if (selectedMainTestCase) {
            try {
                const response = await getSuccessCriteria(selectedMainTestCase.id)
                if (response) {
                    setSelectedBatch(response.batchNumber)
                }
                setSelectedMainTestCaseId(selectedMainTestCase.id);
            } catch (error) {

            }

        }
    };

    const handleChatbot = async (event) => {
        try {
            setAnchorEl(event.currentTarget);
            setOpenChatbot(true);
        } catch (err) {
            console.log(err)
        }
    }
    const handleCloseChatbot = () => {
        setOpenChatbot(false)
        setAnchorEl(null)
        setMessages([]);
        localStorage.removeItem('COMMON_CHAT_SESSION_ID')
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const parseResponse = (text) => {
        // Split response into paragraphs by double newlines
        const paragraphs = text.split(/\n\n/);
        return paragraphs.map((para, paraIndex) => {
            if (para.includes('*')) {
                const listItems = para.split('\n');
                if (listItems) {
                    return (
                        <div key={`list-${paraIndex}`} style={{ paddingBottom: '20px' }}>
                            {listItems.map((item, index) => {
                                if (item.startsWith('*')) {
                                    let htmlContent = convertToBold(item.replace('* ', ''));
                                    return (
                                        <ul key={`list-${paraIndex}-${index}`} style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                            <li dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                        </ul>
                                    );
                                } else {
                                    // Handle as a paragraph with bold formatting
                                    let htmlContent = convertToBold(item);
                                    return (
                                        <p key={`para-${paraIndex}-${index}`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                    );
                                }
                            })}
                        </div>
                    );
                }
            } else if (para.includes('##')) {
                const formattedPara = para.replace('##', '').trim();
                return (
                    <h3 key={`header-${paraIndex}`} style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: convertToBold(formattedPara) }} />
                );
            } else {
                const lines = para.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={`line-${paraIndex}-${lineIndex}`}>
                        <span dangerouslySetInnerHTML={{ __html: convertToBold(line) }} />
                        <br />
                    </React.Fragment>
                ));
                return <p key={`paragraph-${paraIndex}`}>{lines}</p>;
            }
        });
    };

    useEffect(() => {
        const fetchDashboardData = async () => {
            setLoading(true);
            try{
                const [dashboardResponse, eventSummaryResponse, rootCauseResponse] = await Promise.all([
                    fetchDashboardCounts([]),
                    fetchEventSummary([]),
                    fetchRootCauseAnalyticsSummary([])
                ]);
                setCountData(dashboardResponse);
                setEventSummaryData(eventSummaryResponse);
                setRootCauseAnalyticsData(rootCauseResponse);
            } catch (err) {
                console.error('Error fetching dashboard data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    useEffect(() => {
        const referenceId = Cookies.get('ACCOUNT');
        const userId = Cookies.get('USER_ID');
        const getChatSessions = async () => {
            try {
                const response = await fetchChatSessions(referenceId, userId);
                const chatCount = Object.keys(response.data).length;
                setTotalChatCount(chatCount);
            } catch (err) {
                console.error('Error fetching notifications:', err);
            }
        };
        getChatSessions();
    }, []);

    useEffect(() => {
        const getBatchNumbers = async () => {
            try {
                const batchNumbers = await fetchBatchNumbers();
                setBatchNumbers(batchNumbers);
            } catch (err) {
                console.error('Error fetching batch numbers:', err);
            }
        };
        getBatchNumbers();
    }, []);


    const convertToBold = (text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    };

    const openNotification = (event) => {
        setIsOpenNotification(true)
        setAnchorEl_1(event.currentTarget);
    };

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)

        if (value) {
            const filteredData = originalTestData.filter(record =>
                record.deviceId.toString().includes(value)
            );
            setTestData(filteredData);
        } else {
            setTestData(originalTestData);
        }
    };

    const handleBatchSelection = (event, batch) => {
        const newSelected = event.target.checked 
          ? [...selectedBatchNumbers, batch]
          : selectedBatchNumbers.filter(b => b !== batch);
        setSelectedBatchNumbers(newSelected);
    };

    const handleBatchRemoval = (batchToRemove) => {
        setAppliedBatchNumbers((prevAppliedBatchNumbers) => {
            const updatedBatchNumbers = prevAppliedBatchNumbers.filter(b => b !== batchToRemove);
            return updatedBatchNumbers;
        });
    
        setSelectedBatchNumbers((prevSelectedBatchNumbers) => {
            const updatedSelected = prevSelectedBatchNumbers.filter(b => b !== batchToRemove);
            return updatedSelected;
        });
    
        handleApplyFilter('FILTER', appliedBatchNumbers.filter(b => b !== batchToRemove));
    };
    

    const handleSelectBatchNumber = async (value) => {
        setSelectedBatch(value);
        try {
            const { mainTestTitles, content, uniqueDevices } = await fetchMainTestCases(value);
            const uniqueMainTestTitles = [...new Set(mainTestTitles)];

            setMainTestCasesTitles(uniqueMainTestTitles);
            setMainTestCasesContent(content);
            setDevices(uniqueDevices);
        } catch (error) {
            console.error('Error fetching main test cases:', error);
        } finally {
            // Clear dependent dropdown states
            setTestData([]);
            setSelectedMainTestCaseTitle('');
            setSelectedTestCaseType('');
            setSelectedFormattedTime('')
            setContinuousMainTestId('')
        }
    };

    const combinedChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false,
        },
        hover: {
            mode: null,
        },
        rotation: - Math.PI,
        plugins: {
            labels: false,
        },
        tooltips: {
            enabled: false,
        },
        cutoutPercentage: 30,
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: { display: false },
        rotation: -Math.PI,
        tooltips: {
          enabled: false,
          custom: function (tooltipModel) {
            let tooltipEl = document.getElementById('chartjs-tooltip');
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.fontSize = '10px';
              tooltipEl.style.padding = '3px';
              tooltipEl.style.background = 'rgba(0, 0, 0, 0.9)';
              tooltipEl.style.color = 'white';
              tooltipEl.style.borderRadius = '4px';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.transition = 'all .1s ease';
              tooltipEl.style.zIndex = '1000';
              document.body.appendChild(tooltipEl);
            }
      
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }
      
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map(item => item.lines);
              let innerHtml = '';
      
              for (let i = 0; i < bodyLines.length; i++) {
                const colors = tooltipModel.labelColors && tooltipModel.labelColors[i];
                const colorBar = `<span style="
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background: ${colors ? colors.backgroundColor : '#000'};
                    margin-right: 5px;
                    margin-top: 3px;
                    border-radius: 3px;
                  "></span>`;
                innerHtml += `${colorBar}${bodyLines[i]}<br>`;
              }
              tooltipEl.innerHTML = innerHtml;
            }
      
            const canvas = this._chart.canvas;
            const rect = canvas.getBoundingClientRect();
            const tooltipX = rect.left + window.pageXOffset + tooltipModel.caretX;
            const tooltipY = rect.top + window.pageYOffset + tooltipModel.caretY;
      
            tooltipEl.style.left = tooltipX + 'px';
            tooltipEl.style.top = tooltipY + 'px';
            tooltipEl.style.opacity = '1';
          }
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              weight: 'bold',
              size: 13,
            },
          },
        },
        cutoutPercentage: 72,
    };    

    const getCountValue = (value) => (value !== undefined && value !== 0 ? value : 0);

    const PostDeploymentData = [
        {
            count: getCountValue(countData.devicesCount),
            data: {
                datasets: [
                    {
                        label: 'Under Diagnosis',
                        data: [
                            countData.devicesCount > 0 ? countData.continuousFailureDiagnosisDevicesCount : 0,
                            countData.devicesCount > 0 ? (countData.devicesCount - countData.continuousFailureDiagnosisDevicesCount) : 0
                        ],
                        backgroundColor: ['#2B2B2B', '#AAABB0'],
                        borderWidth: 0,
                    },
                    {
                        label: 'Issue Identified',
                        data: [
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 ? countData.issueIdentifiedDevicesCount : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 ? (countData.continuousFailureDiagnosisDevicesCount - countData.issueIdentifiedDevicesCount) : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 ? (countData.devicesCount - countData.continuousFailureDiagnosisDevicesCount) : 0
                        ],
                        backgroundColor: ['#FF4D4F', '#E0E0E0', '#FFFFFF'],
                        borderWidth: 0,

                    },
                    {
                        label: 'Root Cause Identified',
                        data: [
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 ? countData.rootCauseIdentifiedDevicesCount : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 ? (countData.issueIdentifiedDevicesCount - countData.rootCauseIdentifiedDevicesCount) : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 ? (countData.continuousFailureDiagnosisDevicesCount - countData.issueIdentifiedDevicesCount) : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 ? (countData.devicesCount - countData.continuousFailureDiagnosisDevicesCount) : 0
                        ],
                        backgroundColor: ['#FF8C42', '#E0E0E0', '#FFFFFF', '#FFFFFF'],
                        borderWidth: 0,
                    },
                    {
                        label: 'Solution Provided Via Chat',
                        data: [
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? 1 : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? (countData.rootCauseIdentifiedDevicesCount - 1) : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? (countData.issueIdentifiedDevicesCount - countData.rootCauseIdentifiedDevicesCount) : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? (countData.continuousFailureDiagnosisDevicesCount - countData.issueIdentifiedDevicesCount) : 0,
                            countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.issueIdentifiedDevicesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? (countData.devicesCount - countData.continuousFailureDiagnosisDevicesCount) : 0
                        ],
                        backgroundColor: ['#4CAF50', '#E0E0E0', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
                        borderWidth: 0,
                    }
                ]
            },
            options: combinedChartOptions,
            indicatorData:[
                { label: 'Devices with Test Failures', count: countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 ? getCountValue(countData.issueIdentifiedDevicesCount) : 0, percentage: countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 ? getCountValue(countData.continuousFailureDiagnosisDevicesCount) : 0, color: '#FF4D4F', description: 'Devices that have failed at least one sub-test case. ', opacity: 1 },
                { label: 'Devices with Root Cause Identified', count: countData.devicesCount > 0 ? getCountValue(countData.rootCauseIdentifiedDevicesCount) : 0, percentage: countData.devicesCount > 0 ? getCountValue(countData.issueIdentifiedDevicesCount) : 0, color: '#FF8C42', description: 'Devices for which root causes have been identified. ', opacity: 1 },
                { label: 'Under Troubleshooting', count: countData.devicesCount > 0 && countData.continuousTestCasesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? 1 : 0, percentage: countData.devicesCount > 0 && countData.rootCauseIdentifiedDevicesCount > 0 ? getCountValue(countData.rootCauseIdentifiedDevicesCount) : 0, color: '#4CAF50', description: 'Devices that received failure solutions through the chat. ', opacity: 0.3 },
            ],
            deviceData: [
                { label: 'Under Diagnosis', count: countData.devicesCount > 0 ? getCountValue(countData.continuousFailureDiagnosisDevicesCount) : 0, percentage: countData.devicesCount > 0 ? getCountValue(countData.devicesCount) : 0, color: '#2B2B2B', description: 'Devices that are being monitored as part of specific test cases.', opacity: 1 },
                { label: 'Not Under Diagnosis', count: countData.devicesCount > 0 ? getCountValue(countData.devicesCount - countData.continuousFailureDiagnosisDevicesCount) : 0, percentage: countData.devicesCount > 0 ?  getCountValue(countData.devicesCount) : 0, color: '#AAABB0', description: 'Devices that are not being monitored under any test case. ', opacity: 1 },
            ]
        },
        {
            mainTestCount: countData.devicesCount > 0 ? getCountValue(countData.continuousTestCasesCount) : 0,
            subTestCount: countData.devicesCount > 0 ? getCountValue(countData.continuousSubTestCasesCount) : 0,
            data: eventSummaryData ? failedTestData(eventSummaryData) : null,
            options: chartOptions,
            title: 'Issue Identified',
            totalCount: countData.devicesCount > 0 ? countData.continuousTestCasesCount > 0 ? getCountValue(eventSummaryData.totalFailedDeviceCount) : 0 : 0,
        },
        {
            modelCount: getCountValue(countData.uniqueModelsCount),
            data: rootCauseAnalyticsData ? rootCauseData(rootCauseAnalyticsData) : null,
            options: chartOptions,
            title: 'Root Cause Identified',
            totalCount: countData.devicesCount > 0 ? countData.continuousTestCasesCount > 0 ? countData.uniqueModelsCount > 0 ? getCountValue(rootCauseAnalyticsData.totalRootCauseIdentifiedDevicesCount) : 0 : 'N/A' : 'N/A',
        },
        {
            data: {
                labels: ['Common Chatbot Related Chats', 'Notification Based Chats'],
                datasets: [
                    {
                        data: [countData.devicesCount > 0 ? 10 : 0, countData.devicesCount > 0 ? (totalChatCount - 10) : 0],
                        backgroundColor: ['#0D5C3B', '#02C170'],
                        borderWidth: 0,
                    },
                ],
            },
            options: chartOptions,
            title: 'All Chats',
            totalCount: countData.devicesCount > 0 ? totalChatCount : 0,
        },
    ];

    const PreDeploymentData = [
        {
            count: getCountValue(countData.devicesCount),
            data: {
                datasets: [
                    {
                        label: 'Under Diagnosis',
                        data: [
                            countData.devicesCount > 0 ? countData.initialFailureDiagnosisDevicesCount : 0,
                            countData.devicesCount > 0 ? (countData.devicesCount - countData.initialFailureDiagnosisDevicesCount) : 0
                        ],
                        backgroundColor: ['#2B2B2B', '#AAABB0'],
                        borderWidth: 0,
                    },
                    {
                        label: 'Issue Identified',
                        data: [
                            countData.devicesCount > 0 && countData.initialFailureDiagnosisDevicesCount > 0 ? countData.issueIdentifiedDevicesCount : 0,
                            countData.devicesCount > 0 && countData.initialFailureDiagnosisDevicesCount > 0 ? (countData.initialFailureDiagnosisDevicesCount - countData.issueIdentifiedDevicesCount) : 0,
                            countData.devicesCount > 0 && countData.initialFailureDiagnosisDevicesCount > 0 ? (countData.devicesCount - countData.initialFailureDiagnosisDevicesCount) : 0
                        ],
                        backgroundColor: ['#FF4D4F', '#E0E0E0', '#FFFFFF'],
                        borderWidth: 0,

                    },
                ]
            },
            options: combinedChartOptions,
            indicatorData:[
                { label: 'Devices with Test Failures', count: countData.devicesCount > 0 && countData.initialTestCasesCount > 0 ? getCountValue(countData.issueIdentifiedDevicesCount) : 0, percentage: countData.devicesCount > 0 && countData.initialTestCasesCount > 0 ? getCountValue(countData.initialFailureDiagnosisDevicesCount) : 0, color: '#FF4D4F', description: 'Devices that have failed at least one sub-test case. ' },
            ],
            deviceData: [
                { label: 'Under Diagnosis', count: countData.devicesCount > 0 && countData.initialTestCasesCount > 0 ? getCountValue(countData.initialFailureDiagnosisDevicesCount) : 0, percentage: countData.devicesCount > 0 ? getCountValue(countData.devicesCount) : 0, color: '#2B2B2B', description: 'Devices that are being monitored as part of specific test cases. ' },
                { label: 'Not Under Diagnosis', count: countData.devicesCount > 0 && countData.initialTestCasesCount > 0 ? getCountValue(countData.devicesCount - countData.initialFailureDiagnosisDevicesCount) : 0, percentage: countData.devicesCount > 0 ?  getCountValue(countData.devicesCount) : 0, color: '#AAABB0', description: 'Devices that are not being monitored under any test case. ' },
            ]
        },
        {
            mainTestCount: getCountValue(countData.initialTestCasesCount),
            subTestCount: getCountValue(countData.initialSubTestCasesCount),
            data: eventSummaryData ? failedTestData(eventSummaryData) : null,
            options: chartOptions,
            title: 'Issue Identified',
            totalCount: getCountValue(eventSummaryData.totalFailedDeviceCount),
        },
        {
            modelCount: 0,
            data: null,
            options: chartOptions,
            title: 'Root Cause Identified',
            totalCount: 'N/A',
        },
        {
            data: {
                labels: ['Common Chatbot Related Chats', 'Notification Based Chats'],
                datasets: [
                    {
                        data: [countData.devicesCount > 0 ? 10 : 0, countData.devicesCount > 0 ? (totalChatCount - 10) : 0],
                        backgroundColor: ['#0D5C3B', '#02C170'],
                        borderWidth: 0,
                    },
                ],
            },
            options: chartOptions,
            title: 'All Chats',
            totalCount: countData.devicesCount > 0 ? totalChatCount : 0,
        },
    ];

    const toolTipData = [
        { title: 'This section provides a 24-hour summary of device diagnostics. It starts with the total onboarded devices, showing how many are under diagnosis and how many are not. it then breaks down diagnosed devices into dovices with test failures, highlights diagnosed devices with identified root causes, and finally shows how many devices are under troubleshooting'},
        { title: 'The section provides a 24-hour overview of detected issues, showing the total number of identified issues (each failed test cases is counted as a separate issue. even if the same device failed multiple test cases) and the count Of applied main and sub—test cases. It highlights the top fout failed main test cases by percentage, while all others are grouped under "Others" for clarity. Clicking on a failed test case reveals a list of affected devices.' },
        { title: 'The section provides a 24-hour overview of detected root causes for failures, showing the total number of identified root causes (each detected root cause is counted as a seperate root cause, even if the same device detect multiple root cooses) and the count of root coose models applied. It highlights the top four detected root causes by percentage. while all others are grouped under "Others" for clarity. Clicking on a detected root cause reveals a list of affected devices.' },
    ];

    return (
        <ThemeProvider theme={theme}>
            <div className='testing'>
                <Grid className={classes.pageTitle}>Dashboard</Grid>
                <Grid container style={{ display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginTop: '5px' }}>
                    <Grid item xs={12} md={4} className={classes.dashboardContainer}>
                        <Grid item onClick={() => handleTabClick("post")} className={`${classes.tabItem} ${activeTab === "post" ? classes.active : ""}`}>
                            <h5>Post Deployment Stage</h5>
                        </Grid>

                        <Grid item 
                            // onClick={() => handleTabClick("pre")} 
                            className={`${classes.tabItem}`}
                            //  ${activeTab === "pre" ? classes.active : ""}                     
                        >
                            <h5>Pre Deployment Stage</h5>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.endContainer}>
                        <Grid item md={1} xs={12} style={{alignContent:'center', display:'flex', minWidth:'110px'}}>
                            <Button
                                fullWidth
                                className={classes.hourButtonContainer}
                            >
                                Last 24hrs
                            </Button>
                        </Grid>
                        {appliedBatchNumbers.length > 0 && (
                            <Grid item className={classes.batchContainer} >
                                {appliedBatchNumbers.map((batch, index) => (
                                    <Grid item key={index}>
                                        <Grid className={classes.batchButtonContainer} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button style={{ color: '#FFFFFF', padding:'4px' }}>{batch}</Button>
                                            <IconButton
                                                onClick={() => {
                                                    handleBatchRemoval(batch)
                                                }}
                                                style={{ padding: '4px' }}
                                            >
                                                <CancelBatchIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>                           
                        )}
                        <SelectBatch 
                            filterVisible={filterVisible}
                            toggleFilter={toggleFilter}
                            setFilterVisible={setFilterVisible}
                            batchNumbers={batchNumbers}
                            selectedBatchNumbers={selectedBatchNumbers}
                            appliedBatchNumbers={appliedBatchNumbers}
                            setAppliedBatchNumbers={setAppliedBatchNumbers}
                            handleBatchSelection={handleBatchSelection}
                            handleApplyFilter={(status) => handleApplyFilter(status, selectedBatchNumbers)}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={12} >
                        {activeTab === 'post' ? DeploymentStageComponent({ stageData: PostDeploymentData, loading: loading, activeTab: activeTab, mainTitle:'Dashboard', path:'/ResultDashboard', toolTipData: toolTipData }) : DeploymentStageComponent({ stageData: PreDeploymentData, loading: loading, activeTab: activeTab, mainTitle:'Devices', path:'/ResultDashboard', toolTipData: toolTipData })}
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={12} >
                        <Grid container className={classes.formContent} style={{ backgroundColor: white }}>

                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12} md={2}>
                                    <SearchBar onChange={handleSearch} />
                                </Grid>

                                <Grid item xs={12} md={10}>
                                    <Grid container justifyContent="flex-end" spacing={2}>
                                        <Grid item md={4} xs={12}>
                                            <InputBase
                                                className={classes.formInput}
                                                type="text"
                                                name="DateRange"
                                                value={`${startDate}  to  ${endDate}`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <Button
                                                fullWidth
                                                onClick={handleReportHistoryClick}
                                                className={classes.reportButton}
                                            >
                                                Report History
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} alignItems="flex-start" style={{ marginTop: '16px' }}>
                                <Grid item md={4} xs={12}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item md={2} xs={12}>
                                            <Typography
                                                className={classes.typo}
                                                style={{ whiteSpace: 'nowrap', }}
                                            >
                                                Batch: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} xs={12}>
                                            <DropDown
                                                options={batchNumbers.map(batch => batch)}
                                                type="batchNumber"
                                                emptyTag="-Select-"
                                                setSelectOption={handleSelectBatchNumber}
                                                value={selectedBatch}
                                                isSingle={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Main Test Case Dropdown */}
                                <Grid item md={5} xs={12}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item md={3} xs={12}>
                                            <Typography
                                                className={classes.typo}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                Main Test Case: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={7} xs={12} style={{ marginLeft:"10px" }}>
                                            <DropDown
                                                options={mainTestCasesTitles}
                                                type="mainTestCase"
                                                emptyTag="-Select-"
                                                setSelectOption={handleSelectMainTestCase}
                                                value={selectedMainTestCaseTitle}
                                                isSingle={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>



                            {/* Data Table */}
                            <Grid container alignItems="center" spacing={2} style={{ margin: "20px 0px" }}>
                                <Grid item xs={12} md={12} style={{ padding: "8px 0px" }}>
                                    <DataTable
                                        selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                        testData={testData}
                                        selectedTestCaseType={selectedTestCaseType}
                                        testCaseType={testCaseType}
                                        loadingClose={() => {
                                            setLoading(false);
                                        }}
                                        selectedMainTestCaseId={selectedMainTestCaseId}
                                        step="step7"
                                        setChatbotData={setChatbotData}
                                        tableRootCause={tableRootCause[0] !== undefined && tableRootCause[0]["rootCauseResults"][formatDate(selectedFormattedTime)]}
                                        selectedFormattedTime={formatDate(selectedFormattedTime)}
                                        continuousMainTestId={continuousMainTestId}
                                        selectedBatch={selectedBatch}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Loader loading={loading} />
                </Grid>
                <IconButton onClick={handleChatbot} style={{ background: 'linear-gradient(180deg, #2A7BEC 0%, #184686 100%)', borderRadius: '50%', position: 'fixed', right: '20px', bottom: '20px', opacity: testCaseType != '' ? 1 : 0.4, zIndex: 1 }} disabled={testCaseType != '' ? false : true}>
                    <ChatBotIcon color={'white'} size="32" />
                </IconButton>
                <Popover
                    open={openChatbot}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Chatbot
                        handleClose={handleCloseChatbot}
                        messages={messages}
                        setMessages={setMessages}
                        parseResponse={parseResponse}
                        selectedBatch={selectedBatch}
                        type={'chatbot'}
                        rootCauseId={null}
                        setGraphDetails = {setGraphDetails}
                        expandGraph = {expandGraph}
                        setChatIndex = {setChatIndex}
                        acknowledgementStatus = {null}
                        setResponseCount={setResponseCount}
                        responseCount={0}
                    />
                </Popover>
                <ToasterComponent
                    toaster={isToasterOpen}
                    message={toasterMessage}
                    reqSuccess={reqSuccess}
                />
                <DialogPopup
                    open={isOpenPopup}
                    onClose = {closePopup}
                    title = {'Expand Graph'}
                    dialogContent = {<LineChart graph_details={graphDetails} isExpand={true} index={chatIndex}/>}
                    cancel='cancel'
                    submit= 'save'
                    submitButtonColor = '#2A7BEC'
                    dialogWidth = '1000px'
                    type = 'graph'
                    onSubmit={null}
                />

            </div>
        </ThemeProvider>
    )
}
