import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { GuideIcon } from 'src/components/Icons';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#868DAA',
    borderRadius: '8px',
    padding: '10px',
    maxWidth: '450px',
    fontSize: theme.typography.pxToRem(14),
  },
  arrow: {
    color: 'white',
    '&::before': {
      borderWidth: '0 100px 100px 100px',
    },
  },
  popper: {
    '&[x-placement*="bottom"] $arrow': {
      marginTop: '-0.6em',
    },
    '&[x-placement*="top"] $arrow': {
      marginBottom: '-0.6em',
    },
  },
}))(Tooltip);

export default function dashboardTooltip({ title }) {
  return (
    <CustomTooltip
      arrow
      placement='right'
      title={
        <Typography variant="body2">
          {title}
        </Typography>
      }
    >
      <IconButton>
        <GuideIcon />
      </IconButton>
    </CustomTooltip>
  );
}