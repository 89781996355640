import { red } from '@material-ui/core/colors';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { BorderColor } from '@material-ui/icons';
import { lightGray, primaryBlue, primaryGray, secondaryTextColor, textBarColor, white } from "src/components/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "50px",
    textAlign: "left",
    borderRadius: "3px",
  },
  boxContainer: {
    position: "relative",
    padding: "0px 20px 20px 0px",
    marginTop: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 8px #00000012",
    borderRadius: "16px",
    opacity: 1,
  },
  subBoxContainer: {
    marginTop: "20px",
    borderRadius: "5px",
    border: "1px solid #D9D9D9",
    padding: "35px"
  },
  dataStreamBoxContainer: {
    position: "relative",
    padding: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 8px #00000012",
    borderRadius: "4px",
    opacity: 1,
  },
  statusContainer: {
    display: "flex"
  },
  doughnutContainer:{
    width: '50%',
    position: 'relative',
    margin: '20px 0px 20px 0px',
    alignItems:'center'
  },
  flexContainer:{
    width: '50%',
    flexDirection:'column',
    display: 'flex',
    marginLeft:'45px',
    justifyContent: 'center',
    gap: '8px'
  },
  chipContainer: {
    marginRight: "0px",
    marginTop:"10px"
  },
  blur: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  step: {
    marginLeft: "0px"
  },
  titleContainer: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 600,
    marginLeft: "30px",
    marginRight: "80px",
    fontFamily:"poppins"
  },
  dataStreamTitleContainer: {
    fontSize: "25px",
    color: "#5E5C5C",
    fontWeight: 600,
    display: "flex",
    paddingLeft:"10px",
  },
  description: {
    fontWeight: 200,
    fontSize: "15px",
    margin: "10px 5px 30px 30px",
    height: "30px",
    fontFamily:"poppins"
  },
  dataStreamDescription: {
    fontWeight: 200,
    height:"70px",
    fontSize: "15px",
    padding: "10px 30px 20px 10px",
    margin: "0px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    textAlign: "start",
  },
  getStarted: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  reportButton:{
    backgroundColor: 'transparent',
    color: '#005B96',
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '5px',
    borderColor: '#005B96',
    borderStyle: 'solid',
    borderWidth: '1px',
    textTransform: 'none'
  },
  testAutomationStarted: {
    backgroundColor: '#005B96',
    gap: '10px',
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: '#005B96',
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  testContainer:{
    display:"flex", 
    flexDirection:"row", 
    gap:"20px"
  },
  testSubContainer:{
    borderRadius:"4px", 
    border:"1px solid #E2E5ED"
  },
  subTestMainContainer:{
    marginTop: "20px", 
    borderRadius: "5px", 
    backgroundColor: lightGray
  },
  subTestMainFlexContainer:{
    display: "flex", 
    flexDirection:'row', 
    gap:"10px"
  },
  subTestSecondFlexContainer:{
    display: "flex", 
    flexDirection: "column", 
    gap:"10px"
  },
  subTestThirdFlexContainer:{
    display: "flex", 
    justifyContent: "space-between", 
    flexDirection: "row", 
    gap:"10px"
  },
  subSuccessCriteriaContainer:{
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    gap:'15px'
  },
  option: {
    padding: '0px 10px',
    height: '30px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '13px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    paddingRight: '10px',
  },
  dropdown: {
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    border: '1px solid #C7C4C4',
    borderRadius: '5px',
    backgroundColor: white,
    zIndex: 1,
    maxHeight: '160px',
    overflowY: 'auto',
    cursor: 'pointer',
  },
  filterRadioContainer:{
    display: 'flex', 
    alignItems: 'center', 
    paddingLeft: '10px'
  },
  filterContainer:{
    position: 'absolute', 
    top: '100%', 
    left: '0px', 
    width: 'auto', 
    zIndex: 1, 
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px'
  },
  filterButtonContainer:{
    display: 'flex', 
    justifyContent: 'space-between', 
    marginTop: '8px', 
    padding: '10px'
  },
  subFilterContainer:{
    backgroundColor: '#FFFFFF', 
    padding: '5px 5px',
    maxHeight: '300px', 
    overflowY: 'auto'
  },
  popUpTitle:{
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: '#324054',
  },
  pageTitle:{
    marginTop:'50px', 
    fontSize:'32px', 
    fontFamily:'Poppins',
    fontWeight: 500, 
    display:'flex', 
    justifyItems:'start'
  },
  pageTitleContainer:{
    display: "flex", 
    alignItems: "center", 
    flexDirection:'row', 
    marginTop:'30px', 
    paddingLeft:'20px'
  },
  backgroundContainer:{
    marginBottom:'15px', 
    backgroundColor:"white", 
    padding:'20px'
  },
  tableContainer:{
    border: '1px solid rgb(224, 224, 224)', 
    marginTop: '20px'
  },
  circleContainer:{
    textAlign: "center", 
    marginTop: "25px", 
    display:"flex", 
    justifyContent:"center"
  },
  redirectContainer:{
    display: "flex", 
    margin: '30px 0px', 
    alignItems: 'center', 
    backgroundColor: 'white', 
    padding: '15px', 
    width: 'fit-content'
  },
  redirectTitle:{
    marginLeft: '10px', 
    fontSize:'12px', 
    color:'#868DAA'
  },
  filterSubHeading:{
    fontWeight: 'bold', 
    padding: '10px 10px 10px 20px'
  },
  filterHeadingContainer:{
    fontWeight: 'bold', 
    padding: '10px', 
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid rgb(237, 237, 237);'
  },
  inputBaseStyle: {
    width: '100%',
    minHeight: "38px",
    borderRadius: "5px",
    borderColor: lightGray,
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    paddingTop:'5px'
  },
  formContent: {
    padding: "10px",
    textAlign: "left",
  },
  formInput: {
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    border: ".75px solid rgba(63, 68, 81, .12156862745098039)",
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "10px 15px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color:primaryGray,
    backgroundColor:white,
    fontFamily: 'Poppins',
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: '#808080',
      opacity: 1,
    },
  },
  addNew: {
    backgroundColor: lightGray,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: lightGray,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    minWidth: '120px',
    borderRadius: '4px',
    textTransform: 'none',
    width:'160px',
  },
  backButton: {
    color: primaryGray,
    width: '160px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor:"transparent",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:'120px'
  },
  addLabelButton: {
    color: '#005B96',
    width: '160px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: "none",
    border: "1px solid #005B96",
    "&:hover": {
      backgroundColor:"transparent",
      color: '#2A7BEC',
      boxShadow: "none",
      border: "1px solid #005B96",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:'120px'
  },
  refreshButton: {
    border: '1px solid #02C170',
    borderRadius: '4px',
    padding: '8px 20px 8px 20px',
    cursor: 'pointer',
    color: '#02C170',
    backgroundColor: '#FFFFFF'
  },
  submitButton: {
    borderRadius: '4px',
    padding: '8px 50px 8px 50px',
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#2A7BEC'
  },
  radio: {
    '&.Mui-checked': {
      color: primaryBlue,
    },
    '&:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
  },
  check: {
    '&.Mui-checked': {
      color: '#005B96',
    },
    '&:hover': {
      backgroundColor: alpha('#005B96', 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha('#005B96', 0.1),
    },
  },
  filterRadio:{
    '&.Mui-checked': {
      color: '#005B96',
    },
    '&:hover': {
      backgroundColor: alpha('#005B96', 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha('#005B96', 0.1),
    },
  },
  filterBack:{
    color: primaryGray,
    width: '110px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor:"transparent",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:'110px'
  },
  filterButton:{
    minWidth: '110px',
    borderRadius: '4px',
    textTransform: 'none',
    width:'110px',
  },
  label: {
    alignSelf: 'flex-start',
    marginTop: '10px',
    fontSize: '14px !important', 
    fontFamily: 'Poppins, sans-serif',
  },
  typo: {
    fontSize: "14px",
    fontFamily:"poppins",
    color:"#212529"
  },
  deleteButton: {
    color: 'red',
  },
  descriptionContainer: {
    fontWeight: 200,
    fontSize: "15px",
    margin: "20px 50px 30px 30px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  selectModel: {
    padding: "10px 20px",
    gap: "10px",
    height: "42px",
    border: "0.5px solid rgba(85, 85, 85, 0.5)",
    borderRadius: "5px",
    cursor: 'pointer'
  },
  chip: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    fontSize: '12px',
    borderRadius: '16px',
    padding: '4px 8px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  table: {
    minWidth: 650,
    border: '1px solid #ddd',
    borderCollapse: 'collapse',
  },
  contentText: {
    fontSize: '14px',
  },
  topicText: {
    fontSize: '14px',
    color: "#30335b",
    fontWeight: "bold",
  },
  deviceId: {
    fontSize: '14px',
    color: "#30335b",
  },
  input: {
    width: "100%",
    textAlign: "left",
    backgroundColor: white,
    fontSize:"14px"
  },
  cloneButtonHover: {
    backgroundColor: 'transparent',
    color: primaryBlue,
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      boxShadow: 'none',
    },
  },
  subIconButtonDiv: {
    backgroundColor: "#F5F5F5",
    width: "25px",
    height: "25px",
    borderRadius: "100%",
    textAlign: "center",
  },
  iconButtonStyle: {
    backgroundColor: "ButtonShadow",
    borderRadius: "80%",
    boxShadow: "0px 3px 6px #00000029",
    padding: "3px",
  },
  addButton: {
    backgroundColor: white,
    color: primaryBlue,
    borderColor: primaryBlue,
  },
  inputDefaultValue: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  native: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '100%',
    fontSize: '16px',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 2,
    width: "100%",
  },
  graph:{
    display:'flex',
    flexDirection:'column',
    overflowX:'auto'
  },
  checkboxDropdownOption: {
    padding: '0px 10px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '13px',
  },
  annotation:{
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  annotationButton: {
    fontSize:'12px',
    height:'30px',
    color:'#005B96',
    backgroundColor: 'rgba(0, 91, 150, 0.05)',
    width:'160px',
    marginTop:'15px',
    marginBottom:'5px',
    textTransform:"none"
  },
  add: {
    backgroundColor: primaryBlue,
    color: "white",
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      color: white,
      boxShadow: "none",
    },
  },
  cancel: {
    backgroundColor: 'transparent',
    color: primaryGray,
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor: "transparent",
      bord: "black",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
  },
  delete: {
    backgroundColor: '#FF4343',
    color: white,
    marginBottom: "10px",
    marginRight: "10px",
    width: "40%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha('#FF4343', 0.8),
      color: white,
      boxShadow: "none",
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    color: primaryGray,
    marginBottom: "10px",
    marginRight: "10px",
    width: "40%",
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor: "transparent",
      bord: "black",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
  },
  devicePulseSaveButton:{
    width: '160px',
    borderRadius: '4px',
    backgroundColor: '#005B96',
    border: '1px solid #005B96',
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha('#005B96', 0.8),
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:"120px"
  },
  saveButton: {
    width: '160px',
    borderRadius: '4px',
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:"120px"
  },
  options: {
    // width: 'calc((100% - 40px ) / 3)',
    height: '300px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: '#5E5C5C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#2A7BEC',
      color: white
    },
    margin: 0,
    border: '0.6px solid #DDDDDD',
    boxShadow: '0px 4px 40px 0px #00000014'

  },
  dropdownOptions: {
    padding: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    color:'#8d979f',
    '&:hover': {
      backgroundColor: '#2A7BEC',
      color: white
    }
  },
  accordion: {
    marginTop: '10px',
    padding: '0px',
    border: 'none', // Remove border
    boxShadow: 'none', // Remove box-shadow
    '& .MuiAccordionSummary-root': {
      border: 'none', // Remove border
      boxShadow: 'none', // Remove box-shadow
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
    },
  },
  chatBotInput: {
    '&.MuiInputBase-input': {
      padding: '15px',
    },
    '&.MuiFormControl-root': {
      backgroundColor: '#f5f5f5'
    },
    '& .MuiFilledInput-root': {
      padding: '10px', // Adjust padding as needed
      borderRadius: '25px',
      height:'50px' // Optional: Change border-radius
    },
    borderRadius: '25px',
    color:'#444444',
    fontStyle:'Poppins'
  },
  chatBotPageInput: {
    '&.MuiInputBase-input': {
      padding: '15px',
    },
    '&.MuiFormControl-root': {
      backgroundColor: '#F2F4F5'
    },
    '& .MuiFilledInput-root': {
      padding: '10px',
      borderRadius: '10px',
      height:'50px'
    },
    color:'#444444',
    fontStyle:'Poppins'
  },
  phoneIcon: {
    marginRight: '10px',
    boxShadow: '0px 4px 40px 0px #0000001A',
    padding: '8px',
    height: '41px',
    borderRadius: '20.5px',
    width: ' 41px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  helperText:{
    color:"red",
    marginBottom:"2px"
  },
  required: {
    color: "red",
  },
  tableCell : {
    backgroundColor : '#FFFFFF',
    border:'none'
  },
  checked : {
    "&$checked": {
      color: "#2A7BEC", // Custom color when checked
    },
  },
  deviceCard : {
    color:'#2A7BEC',
    backgroundColor:'#2A7BEC1A',
    paddingLeft:'8px',
    width:'auto',
    height:'34px',
    margin:'5px 0px 0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  deviceCardContainer : {
    height:'100%',
    margin:'0px',
    width:'95%',
    overflowY:'auto'
  },
  iconStyle:{
    padding:"0px",
    marginRight: "10px",
    '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  },
  customButton:{
    backgroundColor: primaryBlue,
    color: white,
    textTransform: "none",
    fontFamily:"poppins",
    padding:"10px 24px",
    height:"40px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  filter:{
    color: secondaryTextColor,
    textTransform: "none",
    border: "  1px solid #dadadaaa",
    fontSize: "14px",
    padding: "10px 15px 10px 10px",
    height: "40px",
    lineHeight: "20px",
    background:"white",
    fontFamily:"poppins",
    '&:hover': {
    backgroundColor: 'transparent',
  },
  },
  container:{
    padding:"20px"
  },
  customLabel:{
    textAlign: "left",
    marginBottom: "22px",
    paddingRight: "2%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  typographyStyle:{
    color: primaryGray,
    fontSize: "14px",
    lineHeight: "21px",
    opacity: "1",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  customCloseIcon:{
    display: "flex",
    justifyContent: "end",
  },
  comingSoon:{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    zIndex: 0,
  },
  tileContainer:{
    position: 'relative',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  dashboardContainer: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  tabItem: {
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    color: '#7F7C7C',
    fontWeight: 'normal',
    padding: '2px',
  },
  active: {
    borderBottom: '2px solid rgba(0, 91, 150, 1)',
    color: 'rgba(0, 91, 150, 1)',
    fontWeight: 'bold',
  },
  hourButtonContainer:{
    backgroundColor: 'white',
    color: '#005B96',
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '5px',
    borderColor: '#005B96',
    borderStyle: 'solid',
    borderWidth: '1px',
    textTransform: 'none',
    padding:'6px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  batchContainer:{
    border: '1px solid rgba(226, 229, 237, 1)',
    borderRadius: '8px',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: 'auto',
    maxWidth: '300px',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none'
  },
  card:{
    padding: '20px',
    borderRadius: "12px",
    background: 'rgba(255, 255, 255, 1)',
    margin: '0px',
    fontFamily:"poppins",
    maxHeight: "800px",
    overflowY: "auto",
    '&.disabled': {
      filter: 'blur(2px)', // Apply blur
      opacity: 0.5, // Reduce visibility
      pointerEvents: 'none', // Disable interactions
    },
  },
  deviceBox:{
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: '#F5F3F9',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    height: '100px'
  },
  testBox:{
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: '#F5F3F9',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    height: '100px'
  },
  subTestBox:{
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: white,
    color: '#005B96',
    margin: '0px',
    border: '1px dashed #005B96',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  batchButtonContainer:{
    backgroundColor: 'rgba(0, 91, 150, 1)',
    color: white,
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '5px',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '1px',
    textTransform: 'none',
    display: 'flex',
    flexDirection:'row',

    '&:hover': {
      backgroundColor: 'rgba(0, 91, 150, 1)',
      color: 'white',
      boxShadow: 'none',
      borderColor: 'transparent',
    },
  },
  indicatorBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: '8px',
    border: '1px solid #E2E5ED',
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: '#005B96'
      ,
    },
  },
  iconButton: {
    backgroundColor: 'rgba(42, 123, 236, 0.04)',
    borderRadius: '50%',
    padding: '15px',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#005B96',
    },
  },
  cardLabel:{
    fontSize: '14px',
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '75px',
    alignSelf:'start'
  },
  notification : {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.6px solid #DDDDDD' ,
    top:0,
    position:'sticky',
    backgroundColor:'#ffffff',
    zIndex:1000
  },
  notificationPopup :{
    overflow:'hidden',
    zIndex:100,
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  userName:{
    width:'20%'
  },
  testBatchContainer:{
    marginBottom: "20px",
    marginLeft:'20px'
  },
  tableHeader:{
    fontWeight:600,
    color:"#324054"
  },
  tooltipCustom:{
    display:"flex",
    alignItems:"center",
    fontSize:"14px"
  },
  DragDropText: {
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif"
  },
  dragText:{
    fontSize:"16px",
    color:"#707070",
    fontWeight:500
  },
  dragTextSmall:{
    fontSize: "15px",
    color: primaryGray
  },
  dragDrop: {
    top: "316px",
    left: "361px",
    height: "180px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "2px dashed #427AE5",
    borderRadius: "5px",
    opacity: 1,
    padding:"32px"
  },
  codecButton:{
    cursor: 'pointer',
    padding: '0px',
    textAlign: 'center',
    color: primaryGray,
    backgroundColor: white,
    borderRadius: '0 4px 4px 0',
  },
  circleText:{
    fontSize: "12px",
    paddingTop: "15px",
    fontFamily: "Poppins, sans-serif",
  },
  protocolButton:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop:'10px',
    gap: '10px',
  },
  customContainer:{
    paddingTop:'20px',
    width:'60%',
    alignItems:'center',
    justifyContent:'center',
    display:'flex',
    flexDirection:'column'
  },
  componentContainer:{
    paddingTop:'20px',
    alignItems:'center',
    justifyContent:'center',
    display:'flex',
    flexDirection:'column'
  },
  customBoxContainer:{
    width:'60%',
    alignItems:'center',
    justifyContent:'center',
    display:'flex',
    flexDirection:'column',
    backgroundColor: "white", 
    marginTop:'30px', 
    marginBottom: "10px", 
    padding:'20px'
  },
  contentContainer:{
    width:'100%',
    marginBottom:'15px'
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
  },
  yellowChip: {
    backgroundColor: "#FFB40A1F",
    color: "#FFB40A",
    height: "20px",
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
  },
  blueChip: {
    backgroundColor: "#2a7ced",
    color: "#fff",
    height: "20px",
  },
  blueChipOpaque: {
    backgroundColor: "#2a7bec1a",
    color: "#2a7bec",
    height: "20px",

  },
  tablehead:{
  fontWeight: 600,
  color:"#324054",
  fontSize: "14px",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontFamily:"poppins"
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '0px',
    },
    '& .Mui-selected': {
      backgroundColor: '#2199e8 !important',
      color: 'white', 
    },
  },
  form:{
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    border: "none",
    borderWidth: "1px",
    padding: "0px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color:primaryGray,
    backgroundColor:"transparent",
    fontFamily: 'Poppins',
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: '#808080', 
      opacity: 1,
    },
  },
  cloudContainer: {
    borderRadius: "4px",
    border: ".75px solid rgba(63, 68, 81, .12156862745098039) !important",
    position: "relative",
    display: "flex",
    minHeight: "57px",
    width: "100%",
    backgroundColor: "white",
    marginBottom: "4px",
    flexWrap:"wrap"
  },
  cloudItem:{
    border: '.75px solid rgba(63, 68, 81, .12)',
    width: 'auto',
    borderRadius: '5px',
    padding: '2px 6px',
    alignItems: 'center',
    margin: '0 5px 2px 0',
    height: '30px',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  cloudItem_number:{
    padding: '2px 5px',
    fontSize: '12px',
    textAlign: 'center',
    color: '#707070',
  },
  cloudItem_line:{
    border: '.75px solid rgba(63, 68, 81, .12)',
    height: '20px',
    margin: '0 5px',
  },
  cloudItem_text:{
    padding: '2px 5px',
    fontSize: '12px',
    textAlign: 'center',
    color: '#707070',
  },
  cloudItem_close:{
   cursor:'pointer',
   margin:'2px',
   display:'flex',
   alignItems:'center',
   justifyContent:'center'
  },
  popupHeading:{
    fontFamily:'poppins',
    fontSize:'16px',
    fontWeight:'bold'
  },
  customTextField: {
    width: '200px',
    marginTop: 0,
  },
  dateTime:{
     border:'solid 1px #e0e0e0',
     borderRadius:'4px',
     marginRight:'10px',
     justifyContent:'center',
     display:'flex'
  },
  editConfigBatchName : {
    color: '#2A7BEC',
    backgroundColor: '#2A7BEC0D',
    fontWeight: 'bold',
    padding: '4px 10px',
    whiteSpace:'normal',
    wordWrap:'break-word'
  },
  needHelpButton: {
    color: '#2A7BEC',
    borderColor: '#2A7BEC1A',
    borderRadius: '22px',
    textTransform: 'none',
    opacity: 1, // Default opacity
    '&.disabled': {
      opacity: 0.5, // Lower opacity when disabled
      pointerEvents: 'none', // Disable interactions
    },
  },
  tooltipIcon:{
    padding: "1px 2px 2px 0px"
  },
  acknowledgement:{
    borderRadius:'5px',
    height:'50px',
    width:'auto',
    margin:"0px 0px 0px 10px"
  },
  acknowledgementContent:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  acknowledgementClose:{
    alignContent:'center'
  },
  trashIcon:{
    '&.Mui-disabled':{
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  viewMainTestCase:{
    display: 'flex',
    justifyContent: 'space-between',
    width:'500px'
  },
  mainTestCaseSub : {
    display: 'flex',
    flexDirection: 'column'
  },
  viewMainTestCaseText : {
    fontWeight: 'bold',
    marginTop: '5px'
  },
  editTestCaseInput:{
    marginTop: '5px',
    backgroundColor:'white',
    border:'1px solid #E2E5ED' ,
    borderRadius:'4px',
    width:'100%',
    padding:'5px'
  },
  alertText:{
    fontWeight:600,
    paddingBottom:'10px'
  },
  testCaseIcon:{
    height:'15px'
  },
  multiSelectDropDownTable:{
    maxHeight: '400px',
    overflowY: 'auto',
    border: '1px solid rgb(224, 224, 224)',
    margin:'10px 0px 30px 0px'
  },
  deviceAddButton:{
    color: '#FFFFFF',
    backgroundColor: '#2A7BEC',
    marginLeft: '10px',
    width: '100px'
  },
  deviceSummeryTile:{
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    padding: '4px',
    marginTop: '6px',
    '&.disabled': {
      filter: 'blur(2px)',
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  endContainer:{
    display: 'flex', 
    flexDirection:'row', 
    gap:'10px', 
    justifyContent: 'flex-end', 
    alignItems: 'center'
  },
  mainContainer:{
    display: 'flex', 
    flexDirection: 'column', 
    alignContent: 'center', 
    gap: '10px'
  },
  deviceContainer:{
    display: 'flex', 
    flexDirection: 'row', 
    alignContent: 'center', 
    justifyContent: 'space-between', 
    gap:'20px'
  },
  deviceDataContainer:{
    display: 'flex', 
    flexDirection: 'column', 
    gap: '10px'
  },
  subDeviceDataContainer:{
    display: 'flex', 
    alignItems: 'center', 
    gap: '10px',
    padding: '10px'
  },
  cardTitleContainer:{
    display: 'flex', 
    flexDirection: 'row', 
    alignContent: 'center', 
    justifyContent: 'flex-start', 
    gap: '10px'
  },
  cardTitle:{
    marginTop:'18px',
    fontSize:'16px',
    fontWeight: 500
  },
  cardCount:{
    fontWeight: 'bold', 
    alignSelf: 'start', 
    fontSize: '14px'
  },
  toolTipLabel:{
    color:'#000000', 
    fontWeight: 'bold', 
    fontSize: '14px', 
    textAlign:'start'
  },
  toolTipDescription:{
    color:'#000000',
    fontSize:'12px'
  },
  subDeviceBoxContainer:{
    width: '12px',
    height: '20px',
    borderRadius: '3px',
    margin:'0px',
    cursor: 'pointer',
    alignSelf: 'flex-start'
  },
  doughnutIndicatorBox:{
    display: 'flex', 
    flexDirection: 'column', 
    margin: '0px', 
    alignSelf: 'flex-start'
  },
  indicator:{
    width: '9px',
    height: '22px',
    borderRadius: '3px',
    margin:'6px 0px 0px 0px',
    cursor: 'pointer',
    alignSelf: 'flex-start'
  },
  indicatorLabel:{
    fontSize: '14px', 
    color: '#333',
    alignSelf: 'start', 
    textAlign: 'start'
  },
  indicatorCount:{
    fontWeight: 'bold', 
    alignSelf: 'start'
  },
  subDougnutContainer:{
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'space-between', 
    gap:'10px'
  },
  commonCardContainer:{
    display: 'flex', 
    flexDirection: 'row', 
    gap:'15px', 
    marginTop: '10px'
  },
  middleCountBox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    zIndex: 0,
    pointerEvents: 'none', 
    
  },
  middlelabel:{
    fontSize: '12px',
    width:"80px", 
    color:'#878787'
  },
  middleTitle:{
    fontSize: '14px',
    width:"80px", 
    color:'#878787'
  },
  middleCount:{
    fontWeight:'bold', 
    fontSize:'24px'
  },
  middleIcon:{
    borderRadius: '50%', 
    padding: '15px', 
    backgroundColor: '#F5F5F5'
  },
  mainTestBox:{
    display: 'flex', 
    flexDirection: 'row', 
    gap: '10px', 
    justifyContent:'center', 
    marginTop: '10px'
  },
  testSubBox:{
    display: 'flex', 
    justifyContent:'space-between', 
    gap: '10px'
  },
  sampleBox:{
    display: 'flex', 
    flexDirection: 'row', 
    gap: '14px'
  },
  testBoxIcon:{
    borderRadius: '50%', 
    padding: '11px', 
    alignSelf:'flex-start', 
    marginTop:'16px'
  },
  rootCauseBoxIcon:{
    backgroundColor: white, 
    borderRadius: '50%', 
    padding: '10px', 
    alignSelf:'flex-start', 
    marginTop:'10px'
  },
  testInnerBox:{
    display: 'flex', 
    flexDirection: 'column', 
    alignSelf:'flex-start'
  },
  testLabel:{
    fontSize:'14px', 
    alignSelf:'start', 
    width: 'auto', 
    textAlign:'start'
  },
  rootCauseLabel:{
    fontSize:'15px', 
    alignSelf:'start'
  },
  mainIndicatorBox:{
    display: 'flex', 
    alignItems: 'center', 
    gap: '10px', 
    padding:'6px'
  },
  rootCauseBox:{
    display: 'flex', 
    justifyContent:'center', 
    gap: '10px'
  },
  subRootCauseBox:{
    display: 'flex', 
    flexDirection: 'row', 
    gap: '15px'
  },
  emptyRootCauselabel:{
    display: 'flex', 
    justifyContent: 'center', 
    alignContent: 'center'
  },
  chatBotBox:{
    display: 'flex', 
    flexDirection:'row', 
    justifyContent:'space-between'
  },
  chatBotIcon:{
    backgroundColor: 'rgba(42, 123, 236, 0.04)', 
    borderRadius: '50%', 
    padding: '20px', 
    height:'65%'
  },
  chatLabel:{
    color:'#02C170', 
    marginTop:'15px', 
    fontSize: '15px'
  },
  chatCount:{
    color:'#02C170', 
    marginTop:'5px'
  },
  cardTitleIcon:{
    borderRadius: '50%', 
    padding: '15px',
    backgroundColor: 'rgba(42, 123, 236, 0.04)'
  },
  topicFont: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#4E4E4E",
  },
  textFieldFont: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "24px",
    color: "#4E4E4E",
  },
  azureStatus: {
    borderRadius:'16px',
    backgroundColor:'#F7F1FD',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#005B96',
    width: 'fit-content',
    margin: '0px',
    padding:'0px 12px',
    display: 'flex',
    alignItems: 'center',
  },
  usageDoughnutContainer: {
    display:'flex', 
    alignItems: 'center', 
    justifyContent:'center'
  },
  mainFlexContainer: {
    padding:'10px',
    display:'flex', 
    justifyContent:'space-between', 
    gap:'10px'
  },
  subFlexContainer: {
    padding:'25px', 
    backgroundColor:'#FFFFFF', 
    borderRadius:'4px',
    cursor: 'pointer',
  },
  flexRowContainer: {
    display:'flex', 
    flexDirection:'row', 
    gap:'20px'
  },
  sensorCodeChip:{
    width:'105px',
    height:'38px',
    display:'flex',
    color:'#005B96',
    backgroundColor:'#32405414',
    borderRadius:'6px',
    justifyContent:'center',
    alignItems:'center'
  },
  sensorNameInputField:{
    paddingLeft:'25px'
  },
  apiUploadContainer:{
    width:'100%',
    maxHeight:'280px',
    overflowY:'auto'
  },
  apiConfigUploadButton:{ 
    display: "flex", 
    alignItems: "end", 
    justifyContent: "flex-end", 
    marginBottom: "20px",
    marginLeft:'10px' 
  },
  uploadText:{
    fontWeight:400,
    fontSize:'12px',
    textAlign:'left',
    color:'#324054',
    paddingLeft:'10px'
  },
  uploadTextContainer:{
    backgroundColor:'#FAFAFA',
    display:'flex',
    width:'100%',
    padding:'15px'
  },
  chatBotPropmt:{ 
    display: 'flex',
    alignItems: 'center', 
    backgroundColor: 'white', 
    justifyContent: 'center', 
    width: 'auto' ,
    borderRadius:'8px',
    padding:'0px 10px'
  },
  subTestCaseContainer:{
    display:"flex", 
    flexDirection:"row", 
    justifyContent:"space-between", 
    gap:"10px",
    alignItems:'flex-start'
  },
  apiTableCell:{
    color:"#324054",
    align:"left"
  },
  expandedCell: {
    position: "relative",
    paddingLeft: "40px", // Adjust based on table structure
  },
  expandedCellBefore: {
    content: '""',
    position: "absolute",
    left: "10px", // Adjust left position
    top: "0",
    bottom: "0",
    width: "2px",
    backgroundColor: "#005187", // Side vertical line
  },
  expandedRow: {
    position: "relative",
  },
  expandedCellAfter: {
    content: '""',
    position: "absolute",
    left: "50%",
    width: "2px",
    height: "100%",
    top: "0",
    backgroundColor: "#005187", // Center vertical line
    transform: "translateX(-50%)",
  },
  methodText: {
    fontWeight: "bold",
  },  
  jiraStatusContainer:{ 
    alignItems:"center", 
    border: '1px solid #005B96', 
    backgroundColor:"#FFFFFF", 
    borderRadius: '6px', 
    padding: '5px', 
    cursor: 'pointer', 
    width:'260px', 
    margin: '0px',
    '&:hover': {
      backgroundColor: 'rgba(0, 91, 150, 0.1)',
    },
  },
  statusCount:{
    display:"flex", 
    backgroundColor:"#868DAA", 
    borderRadius:"50%", 
    padding:"6px", 
    color:"#000000", 
    fontSize:"10px", 
    height:"18px", 
    width:"18px", 
    alignItems:"center"
  },
  customtableCell:{
    border:"none"
  },
  skipButton:{
    borderRadius: '4px',
    border: '1px solid var(--Primary-Color, #005B96)',
    background: '#FFF',
    margin:'10px',
    minWidth:'120px',
    color:'#005B96'
  },
  jiraStatus:{
    padding:"6px 10px", 
    borderRadius:"16px"
  },
  deviceJiraStatus: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "center",
    marginBottom: "5px"
  },
  viewStatus:{
    display:"flex",
    alignItems:"center",
    width:"fit-content", 
    padding:"6px 14px", 
    borderRadius:"16px"
  },
  numberStyle:{
    fontSize:"16px",
    fontWeight:600
  },
  csvBtn:{
    border:"1px solid #C4C2C2",
    padding:"10px 0px",
    borderRadius:"4px",
    cursor:"pointer",
    color:"#C4C2C2",
    width:"153px",
    height:"40px",
    fontSize:"14px",
    boxShadow:"none",
    backgroundColor:"var(--white) 0 0 no-repeat padding-box",
    textTransform: "none",
    fontFamily:"poppins",
    "&:hover": {
    backgroundColor: "var(--white) !important",
    color: "#C4C2C2 !important",
    border: "1px solid #C4C2C2 !important",
    boxShadow: "none !important",
  },
  },
  title: {
    fontSize: "18px",
    paddingRight: "0px"
  },
  deletePopup: {
    margin: '0px',
  },
  deleteHeader: {
    height: "80px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 4px 80px rgba(0,0,0,0.1)",
    opacity: 1
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#005B96',
    },
    '& .PrivateTabIndicator-colorSecondary': {
      backgroundColor: '#005B96 !important',
    }
  },
  tabsIndicator:{
    color: 'C4C2C2',
    '&.Mui-selected': {
      color: '#005B96 !important',
      fontWeight:600,
    },
  }
}));

export default useStyles;
