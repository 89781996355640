import * as React from "react";
import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, Input, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles";
import { DropDown } from "../components/DropDown";
import { AddIconTwo, CloseIcon, EyeIcon, ViewIcon } from "src/components/Icons";
import { useEffect, useState } from 'react';
import { SearchBar } from "src/components/sub-components/SearchBar";
import { Close } from "@material-ui/icons";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step1({ errors, batchNumbers, setFormValue, setErrors, formValue }) {
  const classes = useStyles();
  const [selectedBatch, setSelectedBatch] = useState("")
  const [analysisName, setAnalysisName] = useState("")
  const [showBatchDetails, setShowBatchDetails] = useState(false)
  const [failedDevices, setFailedDevices] = useState(["M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458"]);
  const [searchQuery,setSearchQuery] = useState('')
  const [filteredDevices, setFilteredDevices] = useState(failedDevices);


  const batches = batchNumbers;
  const handleSelectBatch = (value) => {
    console.log(value)
    setSelectedBatch(value)
    setErrors(prevError => ({
      ...prevError,
      "batchNumber": null
    }))
    setFormValue(prevValue => ({
      ...prevValue,
      batchNumber: value
    }))
  }

  useEffect(() => {
    const results = failedDevices.filter(device => 
        device.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDevices(results);
  }, [searchQuery, failedDevices]);

  const handleInputChange = (event) => {
    let { value } = event.target
    let trimValue = value
    setAnalysisName(trimValue)
    setFormValue(prevValue => ({
      ...prevValue,
      analysisName: trimValue
    }))
    setErrors(prevError => ({
      ...prevError,
      'analysisName': null
    }))
  }

  const clickEyeIcon = () => {
    setShowBatchDetails(true)
  }


  const handleWindowClose = () => {
    setShowBatchDetails(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`} style={{ display: 'flex', justifyContent: 'center' }}>

        <Grid container className={classes.root}>

          <Grid container className={classes.formContent}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Test Batch
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <DropDown
                  options={batches}
                  type='batch'
                  emptyTag='Select a Test Batch'
                  setSelectOption={setSelectedBatch}
                  value={selectedBatch}
                  onClick={handleSelectBatch}
                  // handleError={handleError}
                  isSingle={true}
                  icon={<ViewIcon />}
                  iconClick={clickEyeIcon}
                />
                {errors.batchNumber &&
                  <Grid item xs={12} style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.batchNumber}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Analysis Name
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter Analysis Name Here"
                    name="analysis"
                    value={analysisName}
                    onChange={(event) => handleInputChange(event)}
                  />
                </Grid>
                {errors.analysisName &&
                  <Grid item xs={12} style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.analysisName}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>


        </Grid>
        <Dialog open={showBatchDetails} >
          <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>{`Batch ${selectedBatch}`}</span>
              <IconButton
                onClick={handleWindowClose}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <SearchBar placeholder="-Search-" onChange={(e) => setSearchQuery(e.target.value)}/>
            <Grid container style={{ margin: "25px 0" }}>
              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                Failed Devices
              </Typography>
            </Grid>

            <Grid container spacing={3} style={{ width: "500px" }}>
              {failedDevices.map((device, index) => (
                <Grid item xs={6} md={4}
                  key={index}
                  style={{ padding: "10px 10px 10px 20px" }}
                >
                  {device}
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}
