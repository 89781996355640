import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Radio } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { FilterIcon } from "src/components/Icons"
import { Typography } from "antd"
import useStyles from "src/app/maintenance/assets/styles"
import { SearchBar } from "src/components/sub-components/SearchBar"

export const SelectBatch = ({ filterVisible, toggleFilter, setFilterVisible, batchNumbers, selectedBatchNumbers, appliedBatchNumbers, setAppliedBatchNumbers, handleBatchSelection, handleApplyFilter}) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const [filterStatus, setFilterStatus] = useState("");

    const handleCloseFilter = async () =>{
        handleApplyFilter("CLOSE", []);
        setFilterVisible(false);
        setFilterStatus("");
    }

    const handleFilterApply = async () => {
        setAppliedBatchNumbers(selectedBatchNumbers);
        await new Promise(resolve => setTimeout(resolve, 0));
        await handleApplyFilter("FILTER", selectedBatchNumbers);
        setFilterStatus("FILTER");
        setSearchTerm("");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBatches = batchNumbers.filter((batch) =>
        batch.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Grid item className="filter-validity" style={{ position: 'relative', display: 'inline-block',zIndex:1 }}>
            <div className="filerButton" onClick={toggleFilter} style={{ display: 'flex', alignItems: 'center', cursor: "pointer", padding:'4px' }}>
                <FilterIcon />
                <span style={{ marginLeft: '4px' }}>Batch Filter{appliedBatchNumbers.length > 0 && ` (${appliedBatchNumbers.length} selected)`}</span>
            </div>
            {filterVisible && (
                <div className="filter-options" style={{ position: 'absolute', top: '100%', right: 0, backgroundColor: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',width:'340px' ,padding:'15px 0px'}}>
                    <Grid item style={{borderBottom:'1px solid #EDEDED', display:'flex'}}>
                        <Typography style={{color:'#1D283D',paddingLeft:'15px',fontFamily:'Poppins',fontWeight:'bold', marginBottom:"10px"}}>Batch Filter</Typography>
                    </Grid>
                    <Grid item style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <div style={{ padding: '10px' }}>
                            <SearchBar
                                onChange={handleSearchChange}
                                InputProps={{
                                  style: {
                                    height: "30px",
                                  },
                                }}
                                placeholder="Search for batches"
                                value={searchTerm}
                            />
                        </div>
                        {filteredBatches.map((batch, index) => (
                            <div key={index} style={{ marginTop: '10px' }}>
                                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                    <FormGroup style={{ margin: '0px' }}>
                                        <FormControlLabel
                                            label={batch}
                                            control={
                                                <Checkbox
                                                    className={classes.check}
                                                    checked={selectedBatchNumbers.includes(batch)}
                                                    onChange={(event) => handleBatchSelection(event, batch)}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        ))}
                    </Grid>
                    <Grid container justifyContent="flex-end" style={{paddingRight:'20px', marginTop:'10px'}}>
                        <Button variant= 'outlined' style={{marginRight:'10px'}} onClick={handleCloseFilter}>Cancel</Button>
                        <Button variant='contained' style={{backgroundColor:'rgba(0, 91, 150, 1)',color:'white'}} onClick={() => handleFilterApply()}>Apply Filter</Button>
                    </Grid>
                </div>
            )}
        </Grid>
    )
}
