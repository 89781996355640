import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, Input, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Tooltip, Typography, useScrollTrigger } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, Arrow, EditIcon, ExpandMoreIcon, NonSuccessCriteriaIcon, TestCaseIcon, TrashIcon, ViewIcon, CancelIcon, ExpandCollapseIcon, IncreaseIcon, DecreaseIcon, EditIconLarge } from 'src/components/Icons';
import { primaryBlue, white } from 'src/components/colors';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { getAllBatches, getAllMainTestCases } from '../testing-automation/api-helper/apiHelper';
import { Close } from '@material-ui/icons';
import { fetchMetaData, getUserRole, matchSensorNames, technicianRole, updateMainTestCase } from '../maintenance/test-automation/components/apiHelper';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Loader } from '../maintenance/test-automation/components/Loader';
import useStyles from '../maintenance/assets/styles';
import { ParameterDropDown } from '../maintenance/test-automation/step2/ParameterDropDown';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { convertToMinutes, convertToPeriod, formatSuccessCriteria, handleDevicePulseClicked, periodMap } from '../utils';
import { DialogPopup } from '../maintenance/test-automation/components/DialogPopup';
import { hasUserPermission } from '../permission';
import Cookies from "js-cookie";

export const TestCasesComponent = () => {
    const theme = createTheme({
        typography: {
            fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles()
    const [batches, setBatches] = useState([]);
    const [hover, setHover] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [mainTestCases, setMainTestCases] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [loading, setLoading] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [selectedSubTestCase, setSelectedSubTestCase] = useState(null);
    const [selectedTestCase, setSelectedTestCase] = useState(null);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [isViewSubTest, setIsViewSubTest] = useState(false);
    const [sensors, setSensors] = useState([]);
    const [isViewMainTestCase,setIsViewMainTestCase] = useState(false)
    const [index,setIndex] = useState({viewIndex:0,editIndex:0})
    const [isEditMainTestCase,setIsEditMainTestCase] = useState(false)
    const [responseTime,setResponseTime] = useState("")
    const [selectedPeriod, setSelectedPeriod] = useState("");
    const [shedulePeriod, setShedulePeriod] = useState([
        "Minutes", "Hours", "Days"
    ]);
    const [errors, setErrors] = useState({
        mainTestNameError: null,
        period:null,
        rootCauseNotificationDurationInMinutes:null
    })
    const [isResponseTimeFixed, setIsResponseTimeFixed] = useState(false);
    const [mainTestCaseTitle,setMainTestCaseTitle] = useState("")
    const [mainTestCaseId,setMainTestCaseId] = useState("")
    const inputRef = useRef(null)

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    const onHover = (id) => {
        setHover(id)
    }
    const removeHover = () => {
        setHover(null)
    }

    const handleExpandClick = (id) => {
        setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
    };

    useEffect(() => {
        fetchMetaData();
    }, []);

    useEffect(() => {
        if (selectedTestCase) {
            console.log('Test case selected:', selectedTestCase);
        }
    }, [selectedTestCase]);

    useEffect(() => {
        const fetchAllBatches = async () => {
            try {
                const fetchedBatches = await getAllBatches();
                setBatches(fetchedBatches);
                console.log(fetchedBatches);
                if (fetchedBatches.length > 0) {
                    setSelectedBatch(fetchedBatches[0]);
                    handleSelectBatchNumber(fetchedBatches[0]);
                }
            } catch (error) {
                console.log('Error fetching batch numbers:', error);
            }
        }
        fetchAllBatches();
    }, []);

    const fetchMainTestCases = async () => {
        setLoading(true);
        try {
            if (selectedBatch) {
                const fetchedMainTestCases = await getAllMainTestCases(selectedBatch);
                if (fetchedMainTestCases) {
                    const filteredTestCases = fetchedMainTestCases.data.filter(
                        (testCase) => testCase.default === false
                    );
                    setMainTestCases(filteredTestCases);
                    console.log('Main Test Cases:', filteredTestCases);
                }
            }
        } catch (error) {
            console.log('Error fetching main test cases:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedBatch) {
            console.log('Selected Batch:', selectedBatch);
            fetchMainTestCases();
        }
    }, [selectedBatch]);

    const createSubTestCase = async (subTestCase) => {
        console.log('Creating sub test case:', subTestCase);
        setLoading(true);
        try {
            const response = await axios.post(`${environment.host}/core/deviceTest/testCase/${selectedTestCase.id}/subTestCase`, subTestCase, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")
                }
            });
            console.log('Sub test case created:', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Sub Test Case Created Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            setIsCreatePopupOpen(false);
            await fetchMainTestCases();
        } catch (error) {
            console.error('Error creating sub test case:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteTestCase = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${environment.host}/core/deviceTest/testCase/${selectedTestCase.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")
                }
            });
            console.log('Test case deleted:', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Test Case Deleted Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchMainTestCases();
        } catch (error) {
            console.error('Error deleting test case:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteSubTestCase = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${environment.host}/core/deviceTest/testCase/${selectedTestCase.id}/subTestCase/${selectedSubTestCase.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")
                }
            });
            console.log('Sub test case deleted:', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Sub Test Case Deleted Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchMainTestCases();
        } catch (error) {
            console.error('Error deleting sub test case:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const getMetaData = async () => {
            const data = await fetchMetaData();
            setSensors(data || []);
        };

        getMetaData();
    }, []);

    const getSensorNameByCode = (code) => {
        const sensor = sensors.find((sensor) => sensor.code === code);
        return sensor ? sensor.name : 'Unknown Sensor';
    };


    const handleSelectBatchNumber = async (value) => {
        setSelectedBatch(value);
    };

    const handleDeleteIcon = (testCase) => {
        setSelectedTestCase(testCase);
        setSelectedSubTestCase(null);
        setIsDeletePopupOpen(true);
    };

    const handleSubTestDeleteIcon = (testCase, subTest) => {
        setSelectedTestCase(testCase);
        setSelectedSubTestCase(subTest);
        setIsDeletePopupOpen(true);
    };

    const cancelDeletePopup = () => {
        setIsDeletePopupOpen(false);
        setSelectedSubTestCase(null);
    };

    const handleViewPopup = (subTestCase) => {
        console.log(subTestCase)
        setSelectedSubTestCase(subTestCase);
        setIsViewSubTest(true)
    }

    const handleCreatePopUp = (testCase) => {
        setSelectedTestCase(testCase);
        setIsCreatePopupOpen(true)
    }

    const cancelCreatePopup = () => {
        setIsCreatePopupOpen(false)
    }


    const cancelViewPopup = () => {
        setIsViewSubTest(false)
    }

    const handleViewTestCase = (index) => {
        setIsViewMainTestCase(true)
        console.log(index)
        setIndex(prevIndex =>({
            ...prevIndex,
            viewIndex:index
        }))
    }

    const handleCloseMainTestCaseView = () =>{
        setIsViewMainTestCase(false)
    }

    const handleEditMainTestCase = (index,testCaseId) =>{
        setIsEditMainTestCase(true)
        setMainTestCaseId(testCaseId)
        const testCaseTitle = mainTestCases[index]
        setMainTestCaseTitle(testCaseTitle.mainTestTitle)
        setResponseTime(convertToPeriod(testCaseTitle.rootCauseNotificationDurationInMinutes.toString(),testCaseTitle.period))
        setSelectedPeriod(testCaseTitle.period)
        setIndex(prevIndex =>({
            ...prevIndex,
            editIndex:index
        }))
    }

    const handleUpdateMainTestCase = async () =>{
        const validationError = validationForm()
        console.log(validationError)
        setErrors(validationError)
        if (Object.keys(validationError).length > 0) {
            return
        }
        setLoading(true)
        const data = {
            mainTestTitle : mainTestCaseTitle,
            rootCauseNotificationDurationInMinutes: convertToMinutes(parseInt(responseTime), selectedPeriod),
            period : selectedPeriod
        }
        console.log(data,mainTestCaseId)
        try{
            const response =await updateMainTestCase(data,mainTestCaseId)
            if(response){
                closeEditMainTest()
                await fetchMainTestCases();
                setIsToasterOpen(true)
                setToasterMessage('Main test case updated successfully.')
                setReqSuccess(true)
            }
        }catch(error){
            setIsToasterOpen(true)
            setToasterMessage(error.message)
            setReqSuccess(false)
        }finally{
            setLoading(false)
            setTimeout(() =>{
                setIsToasterOpen(false)
            },3000)
        }
    }

    const handleMainTestCaseTitle = (event) =>{
        const {value} = event.target
        console.log(value)
        setMainTestCaseTitle(event.target.value);
        setErrors(prevError =>({
            ...prevError,
            mainTestNameError: null
        }))

        inputRef.current.focus();
    }

    const closeEditMainTest = () =>{
        setIsEditMainTestCase(false)
        setErrors(prevError =>({
            ...prevError,
            mainTestNameError: null,
            period:null,
            rootCauseNotificationDurationInMinutes:null
        }))
    }

    const validationForm = () => {
        let error: any = {}
        if (mainTestCaseTitle == "") {
          error.mainTestNameError = "Main Test Case Name is required"
        }
        if (responseTime == '') {
            error.rootCauseNotificationDurationInMinutes = 'Response Time Value is required';
        }
        if(parseInt(responseTime) == 0 && selectedPeriod != "MINUTES"){
            error.rootCauseNotificationDurationInMinutes = 'Response Time must be greater than 0';
        }
        if (selectedPeriod == '') {
            error.period = 'Period is required';
        }
        return error
      }

    const ViewMainTestCase = () => {
    
        const testCase = mainTestCases[index.viewIndex];
    
        if (!testCase) {
            return <Typography>Test case not found.</Typography>; // Fallback for invalid index
        }
    
        return (
            <Grid container className={classes.viewMainTestCase} direction="row" spacing={2}>
                <Grid item className={classes.mainTestCaseSub}>
                    <Typography>Main Test Case</Typography>
                    <Typography className={classes.viewMainTestCaseText}>
                        {testCase.mainTestTitle}
                    </Typography>
                </Grid>
                <Grid item className={classes.mainTestCaseSub}>
                    <Typography>Repeat Every (Alert Schedule)</Typography>
                    <Typography className={classes.viewMainTestCaseText}>
                        {`${convertToPeriod(testCase.rootCauseNotificationDurationInMinutes,testCase.period)} ${testCase.period}`}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const handleIncrease = () => {
        const currentValue = responseTime === '' ? 0 : parseInt(responseTime, 10);
        setResponseTime(String(currentValue + 1));
        setErrors((prevError) => ({
          ...prevError,
          rootCauseNotificationDurationInMinutes: null
        }));
    };
      
    const handleDecrease = () => {
        const currentValue = responseTime === '' ? 0 : parseInt(responseTime, 10);
        setResponseTime(currentValue > 0 ? String(currentValue - 1) : '0');
        setErrors((prevError) => ({
          ...prevError,
          rootCauseNotificationDurationInMinutes: null
        }));
    };
    
    const handleResponseTime = (value: number | string) => {
        const stringValue = String(value);
        const numericValue = stringValue.replace(/[^0-9]/g, '');
        
        setResponseTime(numericValue);
        
        setErrors((prevError) => ({
          ...prevError,
          rootCauseNotificationDurationInMinutes: null
        }));
    };

    const handleSchedulePeriod = (period) => {
          const periodInMinutes = convertToMinutes(parseInt(responseTime), period);
          setSelectedPeriod(periodMap[period]);
      
          setErrors((prevError) => ({
            ...prevError,
            period: null
          }));
    };

    const EditMainTestCase = () => {
        const testCase = mainTestCases[index.editIndex];
        if (!testCase) {
            return <Typography>Test case not found.</Typography>; // Fallback for invalid index
        }
    
        return (
            <Grid container className={classes.viewMainTestCase} direction="row" spacing={2}>
                <Grid item xs={12} md={12} className={classes.mainTestCaseSub}>
                    <Typography>Main Test Case: <span style={{color:'red'}}>*</span></Typography>
                    <InputBase 
                        value={mainTestCaseTitle}
                        onChange={handleMainTestCaseTitle}
                        className={`${classes.formInput} ${classes.editTestCaseInput}`}
                        placeholder="Enter main test case title"
                        name="maintTestCaseTitle"
                        inputRef={inputRef}
                    />
                    {errors.mainTestNameError && (
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                            <Typography className={classes.errorText}>
                                {errors.mainTestNameError}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} md={12} className={classes.mainTestCaseSub}>
                    <Typography className={classes.alertText}>Alert Schedule</Typography>
                    <Typography>Repeat Every <span style={{ color: 'red' }}>*</span></Typography>
                    <Grid container style={{display:"flex",marginTop:'10px'}}>
                        <Grid item xs={12} md={6}>
                        <InputBase
                            className={classes.formInput}
                            placeholder="Enter Duration"
                            name="responseTime"
                            value={responseTime}
                            onChange={(e) => {
                            const inputValue = e.target.value;
                            handleResponseTime(inputValue === '' ? '' : parseInt(inputValue, 10))
                            }}                        style={{paddingRight:'0px'}}
                            endAdornment={
                            <InputAdornment position="end">
                                <Box display="flex" flexDirection="column" style={{marginTop:'-14px'}}>
                                    <IconButton size="small" onClick={handleIncrease} className={classes.testCaseIcon}>
                                        <IncreaseIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={handleDecrease} className={classes.testCaseIcon}>
                                        <DecreaseIcon />
                                    </IconButton>
                                </Box>
                            </InputAdornment>
                            }
                        />
                        {errors.rootCauseNotificationDurationInMinutes && (
                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                            <Typography className={classes.errorText}>
                                {errors.rootCauseNotificationDurationInMinutes}
                            </Typography>
                            </Grid>
                        )}
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingLeft:'15px',display:'flex',alignItems:'flex-end'}}>
                            <DropDown
                                options={shedulePeriod}
                                type="shedulePeriod"
                                emptyTag="-Select-"
                                setSelectOption={handleSchedulePeriod}
                                value={selectedPeriod}
                                isSingle={true}
                                isDisabled={isResponseTimeFixed}
                            />
                            {errors.period && (
                                <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                <Typography className={classes.errorText}>
                                    {errors.period}
                                </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };


    
    


    return (
        <ThemeProvider theme={theme} >
            <Grid item md={12} xs={12} style={{ padding: '25px' }}>
                <Grid className={classes.pageTitle} style={{margin:'0px'}}>Failure Diagnosis</Grid>
                <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Grid container className={classes.redirectContainer}>
                        <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>                          
                            <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                                <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                                    <NonSuccessCriteriaIcon />
                                </IconButton>
                            </Link>
                        </Grid>
                        <Arrow />
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <TestCaseIcon />
                            <Typography className={classes.redirectTitle}>Test Cases</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin:'0px', width:'fit-content' }}>
                        <Grid item >
                            <Typography style={{ fontSize: "15px", paddingRight: '15px' }}>
                                Batch
                                <span style={{ color: 'red' }}>*</span>
                                :
                            </Typography>
                        </Grid>
                        <Grid item style={{width:"300px"}}>
                            <DropDown
                                options={batches}
                                type="batchNumber"
                                emptyTag="Select"
                                setSelectOption={handleSelectBatchNumber}
                                isSingle={true}
                                value={selectedBatch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.backgroundContainer}>
                    <Table style={{border:"1px solid #ddd"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader} align="left" style={{width:'30%'}}>Test Case</TableCell>
                                <TableCell className={classes.tableHeader} align="left" style={{width:'50%'}}>Description</TableCell>
                                <TableCell className={classes.tableHeader}style={{width:'20%'}} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mainTestCases.length > 0 ? (
                                mainTestCases.map((testcase, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 1 ? '#FFFFFF':'#F5F5F5'}}>
                                            <TableCell style={{ width: '30%' }}>{testcase.mainTestTitle}</TableCell>
                                            <TableCell style={{ width: '50%' }}>{testcase.description}</TableCell>
                                            <TableCell style={{ display: 'flex', justifyContent: hasUserPermission("TESTCASE") ?'space-between':'center' }}>
                                                { hasUserPermission("TESTCASE") && 
                                                
                                                <Grid item style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                    {hover === testcase.id && (
                                                        <Typography
                                                            style={{
                                                                fontSize: '12px',
                                                                fontFamily: 'Poppins',
                                                                textAlign: 'left',
                                                                borderRadius: '3px',
                                                                backgroundColor: 'white',
                                                                color: '#5E5C5C',
                                                                minWidth: '200px',
                                                                position: 'absolute',
                                                                marginRight: '10px'
                                                            }}
                                                        >
                                                            Add New Sub Test Case
                                                        </Typography>
                                                    )}

                                                    <IconButton
                                                        size={'small'}
                                                        onMouseEnter={() => onHover(testcase.id)}
                                                        onMouseLeave={removeHover}
                                                        onClick={() => handleCreatePopUp(testcase)}
                                                    >
                                                        <AddIcon fill="none" opacity={1} />
                                                    </IconButton>
                                                </Grid>
                                                }
                                                
                                                <IconButton size={'small'} onClick={() => handleViewTestCase(index)}>
                                                    <ViewIcon color='#707070' />
                                                </IconButton>
                                                {
                                                    hasUserPermission("TESTCASE") && 
                                                    <>
                                                    <IconButton size={'small'} onClick={() => handleEditMainTestCase(index,testcase.id)}>
                                                        <EditIcon stroke={'#707070'} />
                                                    </IconButton>
                                                    <IconButton size={'small'} onClick={() => handleDeleteIcon(testcase)}>
                                                        <TrashIcon />
                                                    </IconButton>
                                                    </>
                                                }
                                               
                                                <IconButton size={'small'} onClick={() => handleExpandClick(testcase.id)}>
                                                    {expandedRows[testcase.id] ? <ExpandCollapseIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        {expandedRows[testcase.id] && (
                                            <React.Fragment>
                                                {testcase.subTestCases.map((subTest, subIndex) => (
                                                    <TableRow key={`subTest-${subTest.id}-${subIndex}`}>
                                                        <TableCell style={{ width: '30%', paddingLeft: '50px', backgroundColor:'#E5E8EA' }}>
                                                            {subTest.subTestTitle}
                                                        </TableCell>
                                                        <TableCell style={{ width: '50%', backgroundColor:'#E5E8EA' }}></TableCell>
                                                        <TableCell style={{ display: 'flex', justifyContent: hasUserPermission("TESTCASE") ?'flex-end':'center', backgroundColor:'#E5E8EA', paddingRight:'55px'  }}>
                                                            <Grid item style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                                                                <IconButton size={'small'} onClick={() => handleViewPopup(subTest)}>
                                                                    <ViewIcon color = '#707070'/>
                                                                </IconButton>
                                                                {
                                                                    hasUserPermission("TESTCASE") && 
                                                                    <>
                                                                     <IconButton size={'small'}>
                                                                        <EditIcon stroke={'#707070'} />
                                                                    </IconButton>
                                                                    <IconButton size={'small'} onClick={() => handleSubTestDeleteIcon(testcase, subTest)}>
                                                                        <TrashIcon />
                                                                    </IconButton>
                                                                    </>
                                                                }
                                                               
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        No Test Cases Available for The Selected Batch
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </Grid>
            </Grid>
            <DeletePopup
                open={isDeletePopupOpen}
                closePopup={cancelDeletePopup}
                testCase={selectedTestCase}
                subTestCase={selectedSubTestCase}
                deleteTestCase={deleteTestCase}
                deleteSubTestCase={deleteSubTestCase}
            />
            <ViewPopup
                open={isViewSubTest}
                handleWindowClose={cancelViewPopup}
                subTestCase={selectedSubTestCase}
                getSensorNameByCode={getSensorNameByCode}
            />
            <CreatePopup
                open={isCreatePopupOpen}
                handleWindowClose={() => {cancelCreatePopup()}} 
                selectedBatch={selectedBatch}
                createSubTestCase={createSubTestCase}
            />
            <DialogPopup 
                title='View Main test case'
                open={isViewMainTestCase}
                onClose = {handleCloseMainTestCaseView}
                buttonRequired = {false}
                dialogContent={<ViewMainTestCase />}
            />
            <DialogPopup 
                title='Edit Main test case'
                open={isEditMainTestCase}
                onClose = {closeEditMainTest}
                buttonRequired = {true}
                dialogContent={<EditMainTestCase />}
                cancel = 'cancel'
                submit='update'
                onSubmit = {handleUpdateMainTestCase}
                submitButtonColor = {primaryBlue}
            />
            <Loader loading={loading} />
            <ToasterComponent
                toaster={isToasterOpen}
                message={toasterMessage}
                reqSuccess={reqSuccess}
            />
        </ThemeProvider>
    );
};

export const DeletePopup = ({open,closePopup,testCase, subTestCase, deleteTestCase, deleteSubTestCase}) =>{
    const classes = useStyles()
    if (!testCase) {
        return null;
    }
    // console.log('Test Case:', testCase);

    const handleDelete = () => {
        if (subTestCase) {
            deleteSubTestCase();
        } else {
            deleteTestCase();
        }
        closePopup();
    };
    return (
        <Dialog open={open}>
            <Grid item style={{ display: 'flex', flexDirection: 'row', boxShadow: '0 0 15px 0 #00000014'  }}>
                <DialogTitle style={{ width: '90%'}}>Delete {subTestCase ? "Sub Test Case" : "Test Case"}</DialogTitle>
                <IconButton className={classes.iconStyle} style={{ justifyContent: "flex-end", cursor: "pointer" }} onClick={closePopup}>
                    <CancelIcon />
                </IconButton>
            </Grid>
            <DialogContent>
                <Box>
                    <Grid item style={{ marginBottom: '15px', padding: '10px' }}>
                        <Typography>
                            Are you sure you want to delete {subTestCase ? subTestCase.subTestTitle : testCase.mainTestTitle}?
                        </Typography>
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-between', width: '40%', marginBottom:'10px', gap:'10px' }}>
                            <Button variant='outlined' style={{ flexGrow: 1 }} onClick={closePopup}>Cancel</Button>
                            <Button variant='contained' style={{ backgroundColor: '#FF4343', color: 'white' }} onClick={handleDelete}>
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

        </Dialog>
    );
};

export const ViewPopup = ({open,handleWindowClose, subTestCase, getSensorNameByCode }) => { 
    return(
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>View Sub Test Case</span>
                    <IconButton
                        onClick={handleWindowClose}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent >
                <Box sx={{minHeight:'135px'}}>
                    {subTestCase && (
                    <Grid container spacing={3} style={{display: 'flex', flexDirection:'row', overflowY:'hidden', padding:'15px'}} key={`subTest-${subTestCase.id}`}>
                        <Grid item style={{flexDirection:'column'}}>
                                <Typography>
                                    Sub Test Case
                                </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'5px'}}>
                                    {subTestCase.subTestTitle}
                                </Typography>
                            </Grid>
                        <Grid item style={{flexDirection:'column'}}>
                                <Typography>
                                    Parameter
                                </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'5px'}}>
                                    {getSensorNameByCode(subTestCase.sensorCode)}
                                </Typography>
                            </Grid>
                        <Grid item style={{flexDirection:'column'}}>
                                <Typography>
                                    Success Criteria
                                </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'5px'}}>
                                    {formatSuccessCriteria(subTestCase.successCriteria)}
                                </Typography>
                            <Typography style={{fontWeight:'bold', marginTop:'2px'}}>
                                    {subTestCase.successCriteria.includes('between')
                                        ? `${subTestCase.minVal} - ${subTestCase.maxVal}`
                                        : subTestCase.minVal
                                    }
                                </Typography>

                            </Grid>
                        </Grid>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export const CreatePopup = ({open,handleWindowClose, selectedBatch, createSubTestCase}) => {
    const classes = useStyles();
    const [parameters, setParameters] = useState([]);
    const [subTestCase, setSubTestCase] = useState({
        subTestTitle: '',
        sensorCode: '',
        successCriteria: '',
        criteriaValue: '',
        minValue: '',
        maxValue: ''
    });
    const [errors, setErrors] = useState({
        subTestTitleError: null,
        sensorCodeError: null,
        successCriteriaError: null,
        criteriaValueError: null,
        minValueError: null,
        maxValueError: null
    });
    const [successCriterias, setSuccessCriterias] = useState([
        "No of Data",
        "Greater than",
        "Greater than or equal to",
        "Less than",
        "Less than or equal to",
        "Is equal to",
        "Is not equal to",
        "Is between",
        "Is not between",
        "Average",
        "Increasing",
        "Decreasing"
    ]);

    useEffect(() => {
        const getParameters = async () => {
            try {
                const parameters = await matchSensorNames(selectedBatch);
                setParameters(parameters);
            } catch (err) {
                console.error('Error fetching parameters:', err);
            }
        };
        if (selectedBatch) {
            getParameters();
        }
    }, [selectedBatch]);

    const parameterOptions = parameters.map(param => ({
        label: param.name,
        value: param.code
    }));

    const successCriteriaMap = {
        "No of Data": "NO_OF_DATA",
        "Greater than": "GREATER_THAN",
        "Greater than or equal to": "GREATER_THAN_OR_EQUAL_TO",
        "Less than": "LESS_THAN",
        "Less than or equal to": "LESS_THAN_OR_EQUAL_TO",
        "Is equal to": "IS_EQUAL_TO",
        "Is not equal to": "IS_NOT_EQUAL_TO",
        "Is between": "IS_BETWEEN",
        "Is not between": "IS_NOT_BETWEEN",
        "Average": "AVERAGE",
        "Increasing": "IS_INCREASING",
        "Decreasing": "IS_DECREASING",
    };

    const validationForm = () => {
        let error: any = {};

        if (subTestCase.subTestTitle === '') {
            error.subTestTitleError = 'Sub Test Case Title is required';
        }
        if (subTestCase.sensorCode === '') {
            error.sensorCodeError = 'Parameter is required';
        }
        if (subTestCase.successCriteria === '') {
            error.successCriteriaError = 'Success scenario is required';
        }
        if (["No of Data", "Greater than", "Greater than or equal to",
            "Less than", "Less than or equal to", "Is equal to", "Is not equal to",
            "Average"].includes(subTestCase.successCriteria) && subTestCase.criteriaValue === '') {
            error.criteriaValueError = 'Value is required';
        }
        else if (["No of Data", "Greater than", "Greater than or equal to",
            "Less than", "Less than or equal to", "Is equal to", "Is not equal to",
            "Average"].includes(subTestCase.successCriteria) && isNaN(Number(subTestCase.criteriaValue))) {
            error.criteriaValueError = 'Only Numeric Characters are Allowed';
        }
        if (["Is between", "Is not between", "Increasing", "Decreasing"]
            .includes(subTestCase.successCriteria)) {
            if (subTestCase.minValue === '') {
                error.minValueError = 'Min Value is required';
            } else if (isNaN(Number(subTestCase.minValue))) {
                error.minValueError = 'Only Numeric Characters are Allowed';
            }
            if (subTestCase.maxValue === '') {
                error.maxValueError = 'Max Value is required';
            } else if (isNaN(Number(subTestCase.maxValue))) {
                error.maxValueError = 'Only Numeric Characters are Allowed';
            }
        }

        setErrors(error);
        return error;
    };


    const resetFormFields = () =>  {
        setSubTestCase({
            subTestTitle: '',
            successCriteria: '',
            sensorCode: '',
            criteriaValue: '',
            minValue: '',
            maxValue: ''
        });
        setErrors({
            subTestTitleError: null,
            sensorCodeError: null,
            successCriteriaError: null,
            criteriaValueError: null,
            minValueError: null,
            maxValueError: null
        });
    }

    const handleSubTestCaseChange = ({ target: { name, value } }) => {
        setSubTestCase(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({
            ...prev,
            [`${name}Error`]: null
        }));
    };

    const handleParameterChange = (selectedSensor) => {
        setSubTestCase(prev => ({
            ...prev,
            sensorCode: selectedSensor
        }));
        setErrors(prev => ({
            ...prev,
            sensorCodeError: null
        }));
    };

    const handleSubmit = async () => {
        const validationError = validationForm();
        if (Object.keys(validationError).length > 0) {
            return;
        }

        let minVal = subTestCase.minValue;
        let maxVal = subTestCase.maxValue;

        if (["No of Data", "Greater than", "Greater than or equal to",
            "Is equal to", "Is not equal to", "Average"].includes(subTestCase.successCriteria)) {
            minVal = subTestCase.criteriaValue;
            maxVal = subTestCase.criteriaValue;
        } else if (["Less than", "Less than or equal to"].includes(subTestCase.successCriteria)) {
            maxVal = subTestCase.criteriaValue;
        }

        const newSubTestCase = {
            subTestTitle: subTestCase.subTestTitle,
            sensorCode: subTestCase.sensorCode,
            successCriteria: successCriteriaMap[subTestCase.successCriteria],
            minVal: minVal,
            maxVal: maxVal
        };

        try {
            await createSubTestCase(newSubTestCase);
            resetFormFields();
        } catch (error) {
            console.error('Error creating sub test case:', error);
        }
    };




    return(
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>Add Sub Test Case</span>
                    <IconButton
                        onClick={() => {
                            handleWindowClose();
                            resetFormFields();
                        }}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
          <Box sx={{minHeight:'370px'}}>
                    <Grid container spacing={3} style={{ padding: '10px' }}>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                Sub Test Case
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                placeholder="Enter Sub Test Case Name Here"
                                name="subTestTitle"
                                value={subTestCase.subTestTitle}
                                onChange={handleSubTestCaseChange}
                            />
                            {errors.subTestTitleError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.subTestTitleError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column' }}>
                            <Typography>
                                Parameter
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <ParameterDropDown
                                options={parameterOptions}
                                type='parameter'
                                emptyTag='Select Parameter'
                                setSelectOption={handleParameterChange}
                                value={subTestCase.sensorCode}
                                isSingle={true}
                            />
                            {errors.sensorCodeError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.sensorCodeError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column' }}>
                            <Typography>
                                Success Criteria
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <DropDown
                                options={successCriterias}
                                type='successCriteria'
                                emptyTag='Select Success Criteria'
                                setSelectOption={(value) =>
                                    handleSubTestCaseChange({ target: { name: 'successCriteria', value } })
                                }
                                value={subTestCase.successCriteria}
                                isSingle={true}
                            />
                            {errors.successCriteriaError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.successCriteriaError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                        {["No of Data", "Greater than", "Greater than or equal to", "Less than", "Less than or equal to", "Is equal to", "Is not equal to", "Average"].includes(subTestCase.successCriteria) && (
                            <Grid item xs={12}>
                                <Typography>
                                    Criteria Value
                                    <span style={{ color: 'red' }}> * </span>
                                    :
                                </Typography>
                                <InputBase
                                    className={classes.formInput}
                                    type="text"
                                    placeholder="Enter Value Here"
                                    name="criteriaValue"
                                    value={subTestCase.criteriaValue}
                                    onChange={handleSubTestCaseChange}
                                />
                                {errors.criteriaValueError && (
                                    <Grid container justifyContent="flex-end" alignItems="center">
                                        <Typography className={classes.errorText}>
                                            {errors.criteriaValueError}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        {["Is between", "Is not between", "Increasing", "Decreasing"].includes(subTestCase.successCriteria) && (
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <Typography>
                                        Min Value
                                        <span style={{ color: 'red' }}> * </span>
                                        :
                                    </Typography>
                                    <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        placeholder="Min Value"
                                        name="minValue"
                                        value={subTestCase.minValue}
                                        onChange={handleSubTestCaseChange}
                                    />
                                    {errors.minValueError && (
                                        <Grid container justifyContent="flex-end" alignItems="center">
                                            <Typography className={classes.errorText}>
                                                {errors.minValueError}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Max Value
                                        <span style={{ color: 'red' }}> * </span>
                                        :
                                    </Typography>
                                    <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        placeholder="Max Value"
                                        name="maxValue"
                                        value={subTestCase.maxValue}
                                        onChange={handleSubTestCaseChange}
                                    />
                                    {errors.maxValueError && (
                                        <Grid container justifyContent="flex-end" alignItems="center">
                                            <Typography className={classes.errorText}>
                                                {errors.maxValueError}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={`${classes.backButton} ${classes.button}`}
                                    onClick={() => {
                                        handleWindowClose();
                                        resetFormFields();
                                    }}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={`${classes.getStarted} ${classes.button}`}
                                    onClick={handleSubmit}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
