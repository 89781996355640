import * as React from "react";
import {useEffect, useState} from 'react';
import { Box, Button, createTheme, Grid, IconButton, ThemeProvider, Tooltip, Typography } from "@material-ui/core";
import { Link, useLocation } from 'react-router-dom';
import useStyles from "../assets/styles";
import { AzureIcon, StartMonitoringIcon, SuccessCriteriaIcon, ReportIcon, ReportReadyIcon, NonSuccessCriteriaIcon, NotStartMonitoringIcon, StepIcon } from "src/components/Icons";
import { environment } from "src/environments/environment";
import { Loader } from "../test-automation/components/Loader";
import { numberToWords } from "src/app/utils";
import axios from "axios";
import { checkDbStatusByProcess } from "./components/apiHelper";
import {  useHistory } from 'react-router-dom';
import { ToasterComponent } from "./components/Toaster";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});


const StepCard = ({ step, title, description, pathname, isSenzmaticaConnected, isAzureConnected, scenariosCount, monitoringSessionsCount, reportReadyCount }) => {
  const classes = useStyles();
  const stepNumber = step.split(" ")[1];

  const history = useHistory();

   //Toaster
   const [isToasterOpen, setIsToasterOpen] = useState(false)
   const [toasterMessage, setToasterMessage] = useState("")
   const [reqSuccess, setReqSuccess] = useState(false)

  const vectorDbStatusChcek=async()=>{
    if(step==="Step 05"){
      const result = await checkDbStatusByProcess();
      if(result && result[0]==="Vector DB is created"){
        history.push("/HumanoidNotificationSystem")
      }
      else{
        history.push("/TestAutomation")
        setIsToasterOpen(true);
        setReqSuccess(false);
        setToasterMessage("Vector db is creating");
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 2500);
      }
    }else{
      history.push(pathname)
    }
  }

  return (
    <Grid item xs={12} md={6}>
      <Box className={classes.boxContainer}>
        <div className={classes.statusContainer}>
          <Box className={classes.step}><StepIcon stepNumber={stepNumber} /></Box>
          <div className={classes.chipContainer}>
            {step === "Step 01" && (
              <>
                {isAzureConnected && !isSenzmaticaConnected && (
                  <Tooltip title="Azure Connected" placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <AzureIcon color="#02C170" />
                    </IconButton>
                  </Tooltip>
                )}
                {isSenzmaticaConnected && !isAzureConnected && (
                    <Tooltip title="Senzmatica Connected" placement="left">
                      <IconButton style={{ padding: "0px" }}>
                        <AzureIcon color="#02C170" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isSenzmaticaConnected && isAzureConnected && (
                    <Tooltip title="Senzmatica and Azure Connected" placement="left">
                      <IconButton style={{ padding: "0px" }}>
                        <AzureIcon color="#02C170" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isSenzmaticaConnected && !isAzureConnected && (
                    <IconButton style={{ padding: "0px" }}>
                      <AzureIcon color="#FF0000" />
                    </IconButton>
                  )}
              </>
            )}
            {step === "Step 02" && (
              <>
                {scenariosCount > 0 ? (
                  <Tooltip title={`${numberToWords(scenariosCount)} scenarios are defined`} placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <SuccessCriteriaIcon scenariosCount={scenariosCount} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton style={{ padding: "0px" }}>
                    <NonSuccessCriteriaIcon color={"#324054"}/>
                  </IconButton>
                )}
              </>
            )}
            {step === "Step 03" && (
              <>
                {monitoringSessionsCount > 0 ? (
                  <Tooltip title={`${numberToWords(monitoringSessionsCount)} monitoring sessions are in progress`} placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <StartMonitoringIcon monitoringCount={monitoringSessionsCount} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton style={{ padding: "0px" }}>
                    <NotStartMonitoringIcon />
                  </IconButton>
                )}
              </>
            )}
            {step === "Step 06" && (
              <>
                {reportReadyCount > 0 ? (
                  <Tooltip title={`${numberToWords(reportReadyCount)} Report are ready`} placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <ReportReadyIcon reportCount={reportReadyCount} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton style={{ padding: "0px" }}>
                    <ReportIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </div>
        <Typography className={classes.titleContainer}style={{justifyContent: "left"}}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        <div className={classes.buttonContainer} style={{justifyContent: "flex-end"}}>
          
            <Button
              variant="contained"
              className={classes.getStarted}
              style={{ textTransform: "none" }}
              onClick={vectorDbStatusChcek}
            >
              Get Started
            </Button>
        </div>
      </Box>
      <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
    </Grid>
  );
};

export function TestAutomationComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [isSenzmaticaConnected, setIsSenzmaticaConnected] = useState(false);
  const [isAzureConnected, setIsAzureConnected] = useState(false);
  const [scenariosCount, setScenariosCount] = useState(null);
  const [monitoringSessionsCount, setMonitoringSessionsCount] = useState(null);
  const [reportReadyCount, setReportReadyCount] = useState(null);

  const location = useLocation();
  const connectDeviceId = location.state ? location.state.connectDeviceId : undefined;
  const fromStepReport = location.state ? location.state.fromTestReport : undefined;

  const isTestAutomationUser=(): boolean =>{
    const role = parseInt(localStorage.getItem('ROLE'), 10);
    return role >= 10 && role < 100;
  }
  
  const [steps,setSteps] =useState ([
    { number: "Step 01", title: "Connect Data Stream", description: "Start by connecting to the IoT Platform and initiating the data stream", pathname:"/DataStream" },
    { number: "Step 02", title: "Define Success Criteria", description: "Define the test cases to validate the device data", pathname:"/DefineTestCases" },
    { number: "Step 03", title: "Start Monitoring", description: "Initiating the process of testing devices", pathname:"/StartTesting" },
    { number: "Step 04", title: "Root Cause Analysis", description: "Analysing the root cause of device failures", pathname:"/RootCauseAnalysis" },
    { number: "Step 05", title: "Knowledge based Configuration", description: "Explaining failure reasons in a human-understandable way", pathname:"/HumanoidNotificationSystem" },
    !isTestAutomationUser() && { number: "Step 06", title: "Result Dashboard", description: "Accessing a comprehensive maintenance dashboard", pathname:"/ResultDashboard" },
  ]);

const fetchConnectDevice = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${environment.host}/core/connectDevice`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    });

    const data = response.data.content;
    if (Array.isArray(data)) {
      const isSenzmatica = data.some((device) => device.type === "SENZMATE");
      setIsSenzmaticaConnected(isSenzmatica);
    } else {
      console.error("Unexpected data format:", data);
      throw new Error("Unexpected data format received from the API");
    }

  } catch (error) {
    console.error('Error fetching test data:', error.message);
  } finally {
    setLoading(false);
  }
};

const fetchCosmosDbRequests = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${environment.host}/core/cosmosRequests`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    });

    const data = response.data.content;
    if (Array.isArray(data) && data.length > 0) {
      setIsAzureConnected(true);
    } else {
      setIsAzureConnected(false);
    }
  } catch (error) {
    console.error('Error fetching Cosmos DB requests:', error.message);
  } finally {
    setLoading(false);
  }
};

const fetchTestData = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${environment.host}/core/testcase`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    });

    console.log("Fetched data:", response.data);

    const data = response.data.content;

    const scenariosCount = data.length;
    const monitoringSessionsCount = data.filter((testCase) => testCase.default === false && testCase.checked === false).length;
    
    let reportReadyCount = data.filter((testCase) => testCase.default === false && testCase.checked === true).length;

    if(fromStepReport) {
      reportReadyCount = Math.max(0, reportReadyCount - 1);
    }

    setScenariosCount(scenariosCount);
    setMonitoringSessionsCount(monitoringSessionsCount);
    setReportReadyCount(reportReadyCount);
  } catch (error) {
    console.error('Error fetching test data:', error.message);
  } finally {
    setLoading(false);
  }
};


  useEffect(() => {
    fetchTestData();
    fetchConnectDevice();
    fetchCosmosDbRequests();
  }, [connectDeviceId]);

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Device Pulse
        </div>

        <Grid container className={classes.root} 
          style={{
            padding: "0px 150px 50px",
            borderRadius: "0px",
          }}
          spacing={3}
        >
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              {index % 2 === 0 && (
                <Grid container spacing={3}>
                  <StepCard 
                    step={steps[index].number} 
                    title={steps[index].title} 
                    description={steps[index].description} 
                    pathname={steps[index].pathname}
                    isSenzmaticaConnected={isSenzmaticaConnected}
                    isAzureConnected={isAzureConnected}
                    scenariosCount={scenariosCount}
                    monitoringSessionsCount={monitoringSessionsCount}
                    reportReadyCount={reportReadyCount}
                  />

                  {steps[index + 1] && (
                    <StepCard 
                      step={steps[index + 1].number} 
                      title={steps[index + 1].title} 
                      description={steps[index + 1].description} 
                      pathname={steps[index + 1].pathname}
                      isSenzmaticaConnected={isSenzmaticaConnected}
                      isAzureConnected={isAzureConnected}
                      scenariosCount={scenariosCount}
                      monitoringSessionsCount={monitoringSessionsCount}
                      reportReadyCount={reportReadyCount}
                    />
                  )}

                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Loader loading={loading} />
      </div>
    </ThemeProvider>
  );
}
