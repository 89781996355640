import * as React from "react";
import {forwardRef, useImperativeHandle, useState} from "react";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  NativeSelect,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import { environment } from "src/environments/environment";
import { AddIconTwo, DeleteIcon, EditIconTwo, ExpandIconTwo, ViewIconTwo } from "src/components/Icons";
import { lightGray, primaryBlue, primaryRed, textBarColor, white } from "src/components/colors";
import { BootstrapInput } from "./Step01";
import AlertPopup from "../../AlertPopup";
import useStyles from "../../assets/styles";
import Cookies from "js-cookie";

export function Step02(props) {
  const classes = localStyles();
  const styles =useStyles();
  const [cloneClicked, setCloneClicked] = useState(false);
  const [versionCloneError, setVersionCloneError] = useState(false);
  const [openPopup,setOpenPopup]=useState(false);
  const readVersionClone = (e) => {
    props.readVersionClone(e.target.value);
    setVersionCloneError(false);
  };

  const formatOptions = ["String", "Integer", "Double", "Float", "Char"];
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const readMajorVersionUpgrade = (checked) => {
     if (!checked){
      handleOpenPopup();
    } 
    else{
    props.readMajorVersionUpgrade(checked);
   }

  };
  const handleDiscardChanges=()=>{
    props.setParameters(props.intialParameters);
    props.readMajorVersionUpgrade(false);
    setOpenPopup(false);

  }

  const toggleClone = (cloneClicked) => {
    setCloneClicked(!cloneClicked);
  };

  const onClickClone = () => {
    if (props.selectedVersionClone !== "") {
      setCloneClicked(true);
      fetch(
        `${environment.host}/core/product/${props.productType}/${props.selectedVersionClone}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Success" && data.content !== undefined) {
            props.readParametersCloned(data.content);
            props.readMessage("Data Cloned Successfully");
          } else if (data.message && data.message !== "Success") {
            props.readMessage(data.message);
          }
        })
        .catch((err) => {
          console.error("An error occurred:", err);
        });
    } else {
      setVersionCloneError(true);
    }
  };

  return (
    <div className="testing">
      <>
      {props.remotelyConfigurable?(

        <Grid container className={styles.formContent}>

            <Grid
        item
        xs={12}
        md={12}
      ></Grid>
    <Grid
        item
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          
        }}
      >
        <Typography className={classes.label}>Major Version Upgrade</Typography>
        <FormControlLabel
          label={""}
          control={
            <Checkbox
              className={classes.input}
              checked={props.majorVersionUpgrade}
              style={{padding: "0px"}}
              onChange={(e) => {
                readMajorVersionUpgrade(e.target.checked);
              }}
              color="primary"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          }
          labelPlacement="start"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          
        }}
      >
      {props.majorVersionUpgrade&&props.parametersAddedError&&props.parameters.length==0?
        <Typography className={classes.helperText}> Remote Config Parameters are Required *</Typography>:""}
      </Grid>
      {props.majorVersionUpgrade?
      <Grid item xs={12} md={12} style={{textAlign: "left"}}>
        <Typography className={classes.clone}>
          <span style={{fontWeight: "bold"}}>
           click here to clone parameters from old versions ?{" "}
          </span>
          <Button
            onClick={() => {
              toggleClone(cloneClicked);
            }}
            variant="contained"
            className={classes.cloneButtonHover}
          >
            {" "}
            Click Here
          </Button>
        </Typography>
      </Grid>:  <Grid item xs={12} md={12} style={{textAlign: "left"}}>
        <Typography className={classes.clone}>
          <span style={{fontWeight: "bold"}}>
            Remote configuration can only be updated in major version update
            </span></Typography>
            </Grid>}
      {cloneClicked && (
        <Grid
          item
          xs={12}
          md={12}
          style={{
            textAlign: "left",
            marginBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Typography className={classes.label}>
            Version to be cloned
          </Typography>
          <FormControl className={classes.input}>
            <NativeSelect
              id="demo-customized-select-native"
              value={props.selectedVersionClone}
              className={classes.input}
              onChange={readVersionClone}
              input={<BootstrapInput/>}
            >
              {Array.isArray(props.previousVersions) &&
              props.previousVersions.length === 0 ? (
                <option value="" disabled hidden>
                  No versions available
                </option>
              ) : (
                <option value="" disabled hidden>
                  Select version to be cloned
                </option>
              )}
              {Array.isArray(props.previousVersions) &&
                props.previousVersions.map((version, i) => (
                  <option value={version.toString()} key={i}>
                    {version}
                  </option>
                ))}
            </NativeSelect>

            {versionCloneError === true && (
              <FormHelperText className={classes.helperText}>
                Version to be Cloned Required *
              </FormHelperText>
            )}
          </FormControl>
          <Grid item xs={12} md={12} style={{textAlign: "right"}}>
            <Button
              onClick={onClickClone}
              variant="contained"
              style={{marginTop: "20px"}}
              className={classes.cloneButtonHover}
            >
              {" "}
              Clone
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={5}
        md={5}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
        <Typography style={{color: "#324054A6"}}>
          Network & Communication
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
        <Divider style={{marginTop: "12px", width: "100%"}}/>
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openNetworkVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.networkVisibility}/>
          </div>
        </IconButton>
      </Grid>

      {props.networkVisibility ? (
        <Grid container>
          {props.majorVersionUpgrade?
          <Grid item xs={12} md={12} style={{textAlign: "right" ,paddingRight:"40px"}}>

            <Button
              className={classes.addButton}
              variant="outlined"
              onClick={() =>
                props.open("Network & Communication", "add-new", -1)
              }
              startIcon={<AddIconTwo color='#2A7CED'/>}
            >
              ADD
            </Button>
          </Grid>:""}
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Network & Communication"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <Typography className={classes.topic}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Network & Communication"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{textAlign: "center", paddingTop: "25px"}}
          >
            <Typography className={classes.topic}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Network & Communication" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{paddingLeft: "10px"}}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                    <IconButton
                      style={{padding: "0px"}}
                      onClick={() =>
                        props.open("Network & Communication", "view", i)
                      }
                    >
                      <ViewIconTwo/>
                    </IconButton>
                    {props.majorVersionUpgrade?
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Network & Communication", "edit", i)
                      }
                    >
                      <EditIconTwo/>
                    </IconButton>:""}
                    {props.majorVersionUpgrade?
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Network & Communication", "delete", i)
                      }
                    >
                      <DeleteIcon/>
                    </IconButton>:""}
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        xs={5}
        md={5}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
        <Typography style={{color: "#324054A6"}}>
          Topic Format & Interval
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
        <Divider style={{marginTop: "12px", width: "100%"}}/>
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openTopicVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.topicVisibility}/>
          </div>
        </IconButton>
      </Grid>

      {props.topicVisibility ? (
        <Grid container>
          {props.majorVersionUpgrade?
          <Grid item xs={12} md={12} style={{textAlign: "right",paddingRight:"40px"}}>
            <Button
              className={classes.addButton}
              variant="outlined"
              onClick={() =>
                props.open("Topic Format & Interval", "add-new", -1)
              }
              startIcon={<AddIconTwo color='#2A7CED'/>}
            >
              ADD
            </Button>
          </Grid>:""}
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Topic Format & Interval"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <Typography className={classes.topic}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Topic Format & Interval"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{textAlign: "center", paddingTop: "25px"}}
          >
            <Typography className={classes.topic}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Topic Format & Interval" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{paddingLeft: "10px"}}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                    <IconButton
                      style={{padding: "0px"}}
                      onClick={() =>
                        props.open("Topic Format & Interval", "view", i)
                      }
                    >
                      <ViewIconTwo/>
                    </IconButton>
                    {props.majorVersionUpgrade?
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Topic Format & Interval", "edit", i)
                      }
                    >
                      <EditIconTwo/>
                    </IconButton>:""}
                    {props.majorVersionUpgrade?
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Topic Format & Interval", "delete", i)
                      }
                    >
                      <DeleteIcon/>
                    </IconButton>:""}
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        xs={5}
        md={5}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
        <Typography style={{color: "#324054A6"}}>Message Format</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
        <Divider style={{marginTop: "12px", width: "100%"}}/>
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openMessageVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.messageVisibility}/>
          </div>
        </IconButton>
      </Grid>

      {props.messageVisibility ? (
        <Grid container>
          {props.majorVersionUpgrade?
          <Grid item xs={12} md={12} style={{textAlign: "right",paddingRight:"40px"}}>
            <Button
              className={classes.addButton}
              variant="outlined"
              onClick={() => props.open("Message Format", "add-new", -1)}
              startIcon={<AddIconTwo color='#2A7CED'/>}
            >
              ADD
            </Button>
          </Grid>:""}
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Message Format"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <Typography className={classes.topic}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "MessageFormat"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{textAlign: "center", paddingTop: "25px"}}
          >
            <Typography className={classes.topic}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Message Format" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{paddingLeft: "10px"}}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                    <IconButton
                      style={{padding: "0px"}}
                      onClick={() => props.open("Message Format", "view", i)}
                    >
                      <ViewIconTwo/>
                    </IconButton>
                    {props.majorVersionUpgrade?
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() => props.open("Message Format", "edit", i)}
                    >
                      <EditIconTwo/>
                    </IconButton>:""}
                    {props.majorVersionUpgrade?
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Message Format", "delete", i)
                      }
                    >
                      <DeleteIcon/>
                    </IconButton>:""}
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    
    </Grid>):(<Grid container style={{ padding: '30px 200px' }}>
    <Grid item xs={12} md={12} style={{textAlign: "left"}}>
        <Typography className={classes.clone}>
          <span style={{fontWeight: "bold",fontSize:14}}>
          This product type is not remotely configurable
          </span>
        </Typography>
      </Grid>
    </Grid>)} 
    <AlertPopup openDialog={openPopup} setOpenDialog={setOpenPopup} handleSkip={handleDiscardChanges} textYes={"Yes"}
                textNo={"No"} description={"Are you sure you want to discard all the changes?"} title={"Discard Changes?"} />
    </>
  
    </div>
    
  );
}

const localStyles = makeStyles((themes: Theme) =>
  createStyles({
    container: {
      paddingTop: "20px",
    },
    clone: {
      fontSize: "14px",
    },
    subIconButtonDiv: {
      backgroundColor: "#F5F5F5",
      width: "25px",
      height: "25px",
      borderRadius: "100%",
      textAlign: "center",
    },
    topic: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    addButton: {
      backgroundColor: white,
      color: primaryBlue,
      borderColor: primaryBlue,
    },
    clonedAlready: {
      color: "blue",
      fontSize: "14px",
      marginTop: "10px",
      fontWeight: "bold",
      border: "2px solid blue",
      padding: "8px",
      backgroundColor: "lightblue",
    },
    clonedIndicator: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      fontWeight: "bold",
      backgroundColor: "lightblue",
      padding: "8px",
      borderRadius: "5px",
    },
    clonedIcon: {
      color: "green",
      marginRight: "5px",
    },
    errorText: {
      color: "red",
      fontSize: "12px",
    },
    clonedText: {
      fontSize: "14px",
      color: "blue",
    },
    cloneButtonHover: {
      backgroundColor: primaryBlue,
      color: "white",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    helperText: {
      color: "red",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: textBarColor,
    },
    label: {
      color: "#324054A6",
      paddingBottom: "5px",
    },
  })
);




