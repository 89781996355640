import * as React from 'react';
import {useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core"
import {Bar} from 'react-chartjs-2';
import { dataRestructure } from './DataTable';
import useStyles from '../../assets/styles';

export const TestSummaryReport = ({ 
  testData,
  selectedMainTestCaseTitle,
  selectedMainTestCaseId
}) => {
  const classes = useStyles()

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'SUCCESS',
        data: [0, 0],
        backgroundColor: '#2A7CED',
      },
      {
        label: 'OFFLINE',
        data: [0, 0],
        backgroundColor: '#D1D7DA',
      },
    ],
  })

  useEffect(() => {
    if (selectedMainTestCaseTitle !== "") {
      let structuredData = dataRestructure(testData, selectedMainTestCaseTitle);

      let subTestCases = []
      let success = []
      let failed = []

      if (structuredData.length > 0 && structuredData[0] !== null && structuredData[0] !== undefined) {
        let data = structuredData[0]
        const result = [];
        for (const device in data) {
          for (const testCase in data[device]) {
            for (const testType in data[device][testCase]) {
              const tests = data[device][testCase][testType];
              for (const test of tests) {
                result.push(test);
              }
            }
          }
        }

        result.map((obj) => {
          if (!subTestCases.includes(obj.subTestTitle)) {
            subTestCases.push(obj.subTestTitle)
          }
        })
        subTestCases.map((s) => {
          success.push(0)
          failed.push(0)
        })

        result.map((r) => {
          if (r.testResult === "SUCCESS") {
            let subTestIndex = subTestCases.indexOf(r.subTestTitle)
            success[subTestIndex] = success[subTestIndex] + 1
          }
          if (r.testResult === "FAILURE") {
            let subTestIndex = subTestCases.indexOf(r.subTestTitle)
            failed[subTestIndex] = failed[subTestIndex] + 1
          }
        })
      }

      setData(
        {
          labels: subTestCases,
          datasets: [
            {
              label: 'SUCCESS',
              data: success,
              backgroundColor: '#0096FF',
            },
            {
              label: 'OFFLINE',
              data: failed,
              backgroundColor: '#0047AB',
            },
          ],
        }
      )
    }

  }, [selectedMainTestCaseId, testData])


  const isEmptyData =
    data.labels.length === 0 &&
    data.datasets.every((dataset) =>
      dataset.data.every((value) => value === 0)
    );
  return (
    <div className={classes.formContent} 
      style={{
        padding: "15px" 
      }}>
        < Grid container style={{height: '350px' }}>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <h4>SUB TEST SUMMARY REPORT</h4>
          </Grid>
          <Grid item xs={12} md={12} style={{marginTop: "20px", marginBottom: "20px"}}>
            {isEmptyData ? (
              <div style={{ 
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '230px' 
              }}>
                <Typography className={classes.contentText} style={{ textAlign: 'center' }}>
                  No Data Available
                </Typography>
              </div>
            ) : (
              <Bar data={data} options={{
                maintainAspectRatio: true,
                responsive: true,
                legend: {
                  display: false
                },
                scales: {
                  xAxes: [{
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Test categories '
                    },
                    gridLines: {
                      display: false,
                    },
                  }],
                  yAxes: [{
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Number of Device'
                    },
                    ticks: {
                      beginAtZero: true,
                      precision: 0
                    },
                  }]
                },
                plugins: {
                  labels: {
                    render: () => {
                    }
                  },
                },
              }}/>
            )}
          </Grid>
        </Grid>
    </div>
  )
}
