import { Box, Card, CardContent, Grid, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { PhoneIcon } from 'src/components/Icons'
import useStyles from '../../assets/styles'

export const NotificationComponent = ({ notification, closeNotification }) => {
    const [notificationData, setNotificationData] = useState(notification.flat())
    const classes = useStyles()
    return (
        <Grid container style={{ maxWidth: '400px'}}>
            <Grid item xs={12} md={12} style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', borderBottom: '0.6px solid #DDDDDD' }}>
                <Grid item>
                    <Typography style={{ padding: '20px', color: '#555555', fontSize: '20px' }}>Notification</Typography>
                </Grid>
                <Grid item >
                    <IconButton onClick={closeNotification}>
                        <Close />
                    </IconButton>

                </Grid>

            </Grid>
            <Grid item xs={12} md={12}>
                {notificationData.map((note, index) =>
                    <Grid item key={index}>
                        <CardNotification note={note} />
                    </Grid>
                )}
            </Grid>

        </Grid>
    )
}

export const CardNotification = ({ note }) => {
    const classes = useStyles()
    return (
        <Box>
            <Card>
                <CardContent style={{display:'flex', overflowY: 'auto' ,flexGrow:1}}>
                    <Grid item className={classes.phoneIcon}>
                        <PhoneIcon />
                    </Grid>
                    <Grid item xs={10} md={10}>
                        <Typography>
                            The {note.deviceId} is failed due to  {note.predictions}
                        </Typography>
                        <Typography style={{ color: '#2A7BEC', fontSize: '10px', paddingTop: '10px' }}>{note.date}</Typography>
                    </Grid>
                    
                </CardContent>
            </Card>
        </Box>
    )
}