import { Grid, TextField, IconButton ,Button, Typography} from "@material-ui/core"
import { message, Upload } from "antd"
import Dragger from "antd/lib/upload/Dragger"
import React, { useEffect, useState } from "react"
import useStyles from "src/app/maintenance/assets/styles"
import { DeleteFileDialog } from "src/app/maintenance/test-automation/step6/KnowledgeBasedUploads"
import { UploadIcon, DocumentIcon, PenIcon, TrashIcon } from "src/components/Icons"
import { DragDrop } from "src/components/sub-components/DragAndDrop"

export const EditFiles = ({ files,setFiles,fileList,setFileList,removeAFile,setIsDeleteConformation,isDeleteConformation, fileIndex,setFileIndex,setDeleteFileType,deleteFileType,errors ,setErrors,setDeleteFileList,setUploadedFile}) => {
    const classes = useStyles()
    const [selectedFile,setSelectedFile] = useState({})
    const [selectedFileName,setSelectedFileName] = useState('')
    const [isEdit,setIsEdit] = useState(false)
    const [editedFileName,setEditedFileName] = useState('')

    const handleFileChange = (info) => {
        // Filter out any files that already exist in the file list
        const newFiles = info.fileList.filter(file => {
            const existingFile = fileList.find(f => f.name === file.name);
            return !existingFile; // Only include files that are not already in the state
        }).map(file => ({
            name: file.name,
            size: (file.size / 1024 / 1024).toFixed(2),
            progress: 0,
            file: file.originFileObj,
        }));
        if (newFiles.length > 0) {
            setUploadedFile(prev => [...prev, ...newFiles])
            setFileList(prev => [...prev, ...newFiles]);
            setErrors((prevError) => ({
                ...prevError,
                KnowledgedBase : null
            }))
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setFileList(prevFiles =>
                prevFiles.map(file => {
                    if (file.progress >= 100) return file; 
                    return { ...file, progress: file.progress + 10 }; 
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [fileList]);

    const props = {
        name: 'file',
        multiple: false,
        accept: '.pdf',
        onChange: handleFileChange,
        beforeUpload: (file) => {
            const isPdf = file.type === 'application/pdf';
            const isLt5MB = file.size / 1024 / 1024 < 5;
            const { status, name, size } = file;

            if (!isPdf) {
                message.error('You can only upload PDF files!');
                return false;
            }
            return false;
        },
        showUploadList: false
    };

    const handleCancelDelete = () =>{
        setIsDeleteConformation(false)
    }

    const handleDeleteButton = (file,index) =>{
        setIsDeleteConformation(true)
        setSelectedFile(file)
        setSelectedFileName(file.name)
        setDeleteFileList((prevFile) =>([
            ...prevFile,
            file.name
        ]))
        setFileIndex(index)
        setDeleteFileType('removeAFile')
    }

    const handleEditIcon = (index,name) =>{
        setIsEdit(true)
        setFileIndex(index)
        setEditedFileName(name)
        setErrors(prevError =>({
            ...prevError,
            fileError:null
        }))
    }

    const handleUpdateFileName = (newName, index) => {
        let updatedFiles = [...files];
        let updatedFileNames = updatedFiles.map(file => file.name);
        
        // Check if the new name already exists (excluding the current file)
        let findNameAlreadyExist = updatedFileNames.find((name, idx) => 
            name.toLowerCase() === newName.toLowerCase() && idx !== index
        );
        
        if (findNameAlreadyExist) {
            setErrors(prevError => ({
                ...prevError,
                fileError: "There is already a file with the same name. Please use another name."
            }));
        } else {
            setFiles(updatedFiles);
            setErrors(prevError => ({
                ...prevError,
                fileError: null
            })); // Clear the error if the name is unique
        }
        updatedFiles[index].name = newName;
    };
    
    
    const handleFileNameChange = (e) => {
        setEditedFileName(e.target.value); // Update local state with the new file name
    };
    
    const handleBlurOrEnter = () => {
        setIsEdit(false); // Disable edit mode
        handleUpdateFileName(editedFileName, fileIndex); // Save the updated filename
    };

    return (
        <Grid container style={{margin:'15px 0px'}}>
            {fileList.length > 0 && (
                <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Upload {...props} accept='.pdf'>
                        <Button
                            variant="outlined"
                            startIcon={<UploadIcon color='#2A7BEC' />}
                            className={classes.button}
                            style={{ borderColor: '#2A7BEC', color: '#2A7BEC' }}
                        >
                            Attach Files
                        </Button>
                    </Upload>
                </Grid>
            )}
            <Grid item xs={12} md={12}>
                <Grid container style={{ marginTop: '15px', maxHeight: '450px', overflowY: 'auto' }}>
                    {fileList && fileList.length > 0 ? (
                        fileList.map((file, index) => (
                            <Grid container key={index}>
                                <Grid container  direction='column' style={{ border: '1px solid #CACACA', borderRadius: '4px', padding: '10px', marginTop: '15px' }}>
                                    <Grid item xs={12} md={12}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                                                <DocumentIcon />
                                                {isEdit && fileIndex == index ? (
                                                    <TextField
                                                        value={editedFileName}
                                                        onChange={handleFileNameChange}
                                                        onBlur={handleBlurOrEnter} // Save on blur (click outside)
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') handleBlurOrEnter(); // Save on Enter key press
                                                        }}
                                                        autoFocus // Automatically focus the input when editing
                                                        style={{ margin: '0 10px', width: '150px' }}
                                                    />
                                                )
                                                    :
                                                    (
                                                        <span style={{ color: '#353535', textAlign: 'center', fontFamily: 'Poppins', margin: '0 10px' }}>
                                                            {file.name}
                                                        </span>
                                                    )

                                                }
                                                <IconButton size="small" onClick={() => handleEditIcon(index, file.name)}>
                                                    <PenIcon />
                                                </IconButton>

                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handleDeleteButton(file, index)}>
                                                    <TrashIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ color: '#B4B2B2', textAlign: 'center', fontFamily: 'Poppins', display: 'flex' }}>
                                        {`${file.size} MB`}
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                                        <progress value={file.progress} max={100} style={{ width: '100%', marginRight: '10px' }} />
                                        <span>{`${file.progress}%`}</span>
                                    </Grid>
                                </Grid>
                                {errors.fileError && fileIndex == index &&
                                    <Grid item xs={12} style={{ padding: "0px 8px" ,textAlign:'left'}}>
                                        <Typography className={classes.errorText} >
                                            {errors.fileError}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>

                        ))
                    )
                :
                (
                    <Grid container>
                        <Grid item className={classes.dragAndDrop} style={{borderColor:errors.KnowledgedBase ? 'red':'#CBD0DC',width:'100%'}}>
                            <Dragger {...props} accept='.pdf' onChange={handleFileChange}>
                                <DragDrop />
                                <p className="ant-upload-text" style={{textAlign:'center'}}>Click or drag file to this area to upload</p>
                                {/* <p className="ant-upload-text" style={{ color: '#B4B2B2', textAlign: 'center' }}>maximum upload file size : 5MB</p> */}
                            </Dragger>
                        </Grid>
                        {errors.KnowledgedBase &&
                            <Grid item xs={12} style={{ padding: "0px 8px" ,textAlign:'left'}}>
                                <Typography className={classes.errorText} >
                                    {errors.KnowledgedBase}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                )}
                </Grid>
            </Grid>
            <DeleteFileDialog 
                open={isDeleteConformation} 
                fileName={selectedFileName} 
                onClose={handleCancelDelete} 
                onDelete={removeAFile}
                type={deleteFileType}
            />
        </Grid>
    )
}