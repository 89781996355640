import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    withStyles,
  } from "@material-ui/core";
  import { AddIconTwo, DeleteIcon, EditIconTwo, CopyIcon, CloseIcon } from '../../components/Icons';
  import {ToasterComponent} from "../maintenance/test-automation/components/Toaster";
  import { makeStyles } from "@material-ui/core/styles";
  import { environment } from "../../environments/environment";
  import { SearchBar } from "../../components/sub-components/SearchBar";
  import React, { useState, useEffect } from "react";
  import { CreateKitModels } from "../setup-senzmatica/step-3/steps/CreateKitModels";
  import { primaryBlue, secondaryTextColor, white } from "../../components/colors";
  import useStyles from "../../app/maintenance/assets/styles";
  import { Alert, Pagination } from "@material-ui/lab";
  import { AccountService } from '../account/account.service';
  import {CloseIconColor} from "../../components/Icons";
  import { EditKitModel } from "../setup-senzmatica/step-3/EditKitModel";
  import Cookies from "js-cookie";
  
  export function KitModelComponent() {
    const classes = localStyles();
    const style = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedKitModel, setSelectedKitModel] = useState<any>(null);
    const [selectedKitModelDelete, setSelectedKitModelDelete] = useState<any>(null);
    const [isCloning, setIsCloning] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [allKitModels, setAllKitModels] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [error, setError] = useState(null);
    const [allSensors, setAllSensors] = useState([]);
    const [allActuators, setAllActuators] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const [isToasterOpen, setIsToasterOpen] = useState(false);
    const [toasterMessage, setToasterMessage] = useState("");
    const [reqSuccess, setReqSuccess] = useState(false);
    const [lastCreatedKitModel, setLastCreatedKitModel] = useState("");
    const [kitModelFormType, setKitModelFormType] = useState("Add");
  
    useEffect(() => {
      fetchKitModels();
      fetchMetadata();
      fetchAllKitTypes();
    }, []);
  
    const increaseStep = () => {
      console.log("this is increase step");
      setShowForm(false);
    }
  
    const setIsEyeIconClick = () => {
      console.log("this is edit popup cancel");
      setShowForm(false);
    }
  
    const fetchKitModels = async () => {
      setLoadingScreen(true);
      try {
        const response = await fetch(`${environment.host}/core/kit-model`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
  
        if (!response.ok) throw new Error('Failed to fetch kit models');
  
        const data = await response.json();
        setAllKitModels(data.content);
      } catch (error) {
        console.error('Error fetching kit models:', error);
      } finally {
        setLoadingScreen(false);
      }
    };
  
    const fetchMetadata = async () => {
      try {
        const response = await fetch(`${environment.host}/core/meta-data`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
    
        const sensors = data.content.sensors.sort((a, b) => (a.name < b.name ? -1 : 1));
        const actuators = data.content.actuators.sort((a, b) => (a.name < b.name ? -1 : 1));
        const types = data.content.kitTypes;
    
        setAllSensors(sensors);
        setAllActuators(actuators);
    
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };
    
    const fetchAllKitTypes = async () => {
      try {
        const response = await fetch(`${environment.host}/core/kitType`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        const types = data.content;
    
        setAllTypes(types);
    
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };
  
    // Filtering Kit Models based on the search term
    const getFilteredKitModels = () => {
      return allKitModels.filter((kitModel) =>
        kitModel.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    };
  
    const assignKitModel = (selectKitModel) => {
      let kitModel
      if (selectKitModel == "") {
        console.log(lastCreatedKitModel)
        kitModel = allKitModels.filter(model => model.name.toLowerCase() == lastCreatedKitModel.toLowerCase())
  
      } else {
        console.log(selectKitModel)
        kitModel = allKitModels.filter(model => model.name.toLowerCase() == selectKitModel.name.toLowerCase())
      }
  
      return kitModel;
    }
  
    const editKitModel = (kitModel: any) => {
      setKitModelFormType("Update");
      setSelectedKitModel(assignKitModel(kitModel));
      setShowForm(true);
    };
  
    const cloneKitModel = (kitModel: any) => {
      setIsCloning(true);
      setKitModelFormType("Clone");
      setSelectedKitModel(assignKitModel(kitModel));
      setShowForm(true);
    };
  
    const addKitModel = () => {
      setKitModelFormType("Add")
      setShowForm(true);
      setSelectedKitModel(null);
    };
  
    const deleteKitModel = async () => {
      if (!selectedKitModelDelete) return;
    
      try {
        const response = await fetch(`${environment.host}/core/kit-model/${selectedKitModelDelete.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
    
        if (!response.ok) {
          throw new Error(`Failed to delete kit model: ${response.statusText}`);
        }
    
        setAllKitModels((prevModels) => prevModels.filter((model) => model !== selectedKitModelDelete));
        setSelectedKitModelDelete(null);
      } catch (error) {
        console.error('Error deleting kit model:', error);
      }
    };
    
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      setCurrentPage(newPage); // Ensure both variables are updated
    };
  
    return (
      <div className={classes.root}>
        <div className="page-heading">
          Kit Model Board
        </div>
  
        <Grid container className={style.container}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={8}
              md={4}
              style={{ textAlign: "left" }}
            >
              <SearchBar placeholder="Search Kit Models" onChange={(e) => setSearchTerm(e.target.value)} />
            </Grid>
          </Grid>
        </Grid>
  
        <TableContainer className={style.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>ID</TableCell>
                <TableCell className={classes.tableCell}>Model Name</TableCell>
                <TableCell className={classes.tableCell}>No of Sensors</TableCell>
                <TableCell className={classes.tableCell}>LPS Enabled</TableCell>
                <TableCell className={classes.tableCell}>Type</TableCell>
                <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getFilteredKitModels()
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((kitModel, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#F9F9F9" : "white",
                    }}
                  >
                    <TableCell className={classes.tableCellBody}>{kitModel.id}</TableCell>
                    <TableCell className={classes.tableCellBody}>{kitModel.name}</TableCell>
                    <TableCell className={classes.tableCellBody}>{kitModel.noOfSensors}</TableCell>
                    <TableCell className={classes.tableCellBody}>{kitModel.lbsEnabled ? "True" : "False"}</TableCell>
                    <TableCell className={classes.tableCellBody}>{kitModel.type}</TableCell>
                    <TableCell
                      className={classes.tableCellBody}
                      style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "8px", whiteSpace: "nowrap" }}
                    >
                      <Tooltip title="Edit">
                        <IconButton size="small" style={{ padding: "4px" }} onClick={() => editKitModel(kitModel)}>
                          <EditIconTwo />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Clone">
                        <IconButton size="small" style={{ padding: "4px" }} onClick={() => cloneKitModel(kitModel)}>
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton size="small" style={{ padding: "4px" }} onClick={() => setSelectedKitModelDelete(kitModel)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
  
        <Grid container justifyContent="center">
          {getFilteredKitModels().length > rowsPerPage && (
            <Pagination
              className={style.pagination}
              page={page}
              onChange={handleChangePage}
              count={Math.ceil(getFilteredKitModels().length / rowsPerPage)}
              shape="rounded"
              defaultPage={1}
            />
          )}
        </Grid>
  
        <Grid container justifyContent="flex-end" style={{ position: "fixed", bottom: 20, right: 20 }}>
          <Button
            variant="contained"
            onClick={addKitModel}
            style={{
              minWidth: "50px",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              backgroundColor: "#2A7CED",
              color: "#fff",
              boxShadow: "0 3px 6px rgba(0, 0, 0, 0.275)",
            }}
          >
            <AddIconTwo color={"#FFF"}/>
          </Button>
        </Grid>
  
        {loadingScreen && (
          <div style={{
            position: 'fixed',
            left: 0,
            top: 0,
            backgroundColor: '#000000',
            opacity: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            width: '100vw',
            height: '100vh',
          }}>
            <CircularProgress size={80} />
          </div>
        )}
  
        {showForm && (
          <Dialog open={true}>
            <Grid container>
              <Grid item xs={10} md={10}>
                <DialogTitle>
                  {kitModelFormType == 'Add' ? 'Add New' : kitModelFormType} Kit Model
                </DialogTitle>
              </Grid>
              <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
                <IconButton
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  <CloseIconColor/>
                </IconButton>
              </Grid>
            </Grid>
  
            <Divider />
            <DialogContent>
                <CreateKitModels 
                  increaseStep={increaseStep}
                  allSensors={allSensors}
                  allActuators={allActuators}
                  allTypes={allTypes}
                  allKitModelsName={allKitModels}
                  setLoading={setLoadingScreen}
                  setIsToasterOpen={setIsToasterOpen}
                  setToasterMessage={setToasterMessage}
                  setReqSuccess={setReqSuccess}
                  getKitModels={fetchKitModels}
                  setLastCreatedKitModel={setLastCreatedKitModel}
                  kitModelFormType={kitModelFormType}
                  selectModel={selectedKitModel}
                  getKitModelTrigger={() => {}}
                  hideFormTrigger={() => setShowForm(false)}
                  isCloning={isCloning}
                />
            </DialogContent>
          </Dialog>
        )}
  
        
        <ToasterComponent toaster={isToasterOpen} message={toasterMessage} reqSuccess={reqSuccess}/>
  
        {selectedKitModelDelete && (
          <Dialog open={true} onClose={() => setSelectedKitModelDelete(null)}>
            <div className={classes.modal}>
              <div className={classes.modalContent}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <DialogTitle>CONFIRMATION</DialogTitle>
                  </Grid>
                  <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
                    <IconButton onClick={() => setSelectedKitModelDelete(null)}>
                      <CloseIconColor />
                    </IconButton>
                  </Grid>
                </Grid>
                <DialogContent>
                  <Typography variant="body1" style={{ color: "#FF4343" }}>
                    Are you sure you want to delete the kit model {selectedKitModelDelete.name}?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={() => setSelectedKitModelDelete(null)}>
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.deleteButton}
                    onClick={deleteKitModel}
                  >
                    DELETE
                  </Button>
                </DialogActions>
              </div>
            </div>
          </Dialog>
        )}
  
      </div>
    );
  };
  
  const localStyles = makeStyles((theme: Theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    tableText: {
      fontSize: "14px",
      color: "#3C3C3C",
    },
    versionChip: {
      backgroundColor: "#e6edf7",
      color: "#2a7ced",
      height: "20px",
      border: `1px solid #2A7CED`,
    },
    deleteButton: {
      backgroundColor: "#F06A6A",
      color: "white",
      "&:hover": {
        backgroundColor: "#D9534F",
      },
    },
    tableCell: {
      textAlign: "left",
      borderBottomColor: "white",
      borderTopColor: "white",
      backgroundColor: "white",
    },
    tableCellBody: {
      textAlign: "left",
      borderBottomColor: "white",
      borderTopColor: "white",
    },
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0px 3px 6px #00000029",
    },
  }));
  