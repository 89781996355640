import { Chip, CircularProgress, Dialog, Grid, IconButton, makeStyles, Theme, Typography, } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import { useState } from "react";
import { textBarColor } from "src/components/colors";
import { TextBar } from "src/components/sub-components/TextBar";
import { environment } from "src/environments/environment";
import { CloseIcon, CloseIconColor } from "../../../components/Icons";
import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";
import Cookies from "js-cookie";

export function ViewProductType(props) {
  const classes = useLocalStyles();
  const style = useStyles();
  const [sensorsOfDevice, setSensorsOfDevice] = useState([]);
  const [actuatorsOfDevice, setActuatorsOfDevice] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);


  const productTypeData = props.productType.productType || props.productType;
  const {
    productName,
    connectivity,
    protocol,
    persistence,
    sensorCodes,
    actuatorCodes,
    codecName,
    transcoder,
    dataFormat,
    otaUpgradable,
    remotelyConfigurable,
    versions,
  } = productTypeData;

  console.log("props.productType.productType:", productTypeData)

  // Fetch MetaData from the API endpoint
  React.useEffect(() => {
    const accessToken = Cookies.get("ACCESS_TOKEN");
    setLoadingScreen(true);
    axios.get(`${environment.host}/core/meta-data`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (sensorCodes) {
          const names = sensorCodes.map(code => {
            const sensor = response.data.content.sensors.find(s => s.code === code);
            return sensor ? sensor.name : code;
          });
          setSensorsOfDevice(names);
        }
        if (actuatorCodes) {
          const actuatorNames = actuatorCodes.map(code => {
            const actuator = response.data.content.actuators.find(s => s.code === code);
            return actuator ? actuator.name : code;
          });
          setActuatorsOfDevice(actuatorNames);
        }
        setLoadingScreen(false);
      })
      .catch((error) => {
        console.error('Error fetching metadata:', error);
        setLoadingScreen(false);
        throw new Error('Failed to fetch metadata');
      });
  }, []);
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{ width: "750px" }}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: props.opacity,
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
   <Grid container className={classes.headerContainer}>
      <Grid item xs={10} >
        <Typography className={classes.dialogTitle}>View Product Type</Typography>
      </Grid>

      <Grid item xs={2}  className={classes.closeButtonContainer}>
        <IconButton onClick={props.onClose} >
          <CloseIconColor />
        </IconButton>
      </Grid>
    </Grid>

        <Grid container
          className={classes.container}>
          <Grid item xs={4}  className={classes.gridItemContainer}>
            <Typography className={classes.labelStyle} style={{ marginTop: "2px" }}>
              Product Name :
            </Typography>
            <Typography
              className={classes.valueStyle}
            >{productName}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItemContainer}>
            <Typography className={classes.labelStyle} style={{ marginTop: "2px" }}>
              Sensors :
            </Typography>
          <Grid container className={classes.gridItem}
            >
              {sensorsOfDevice.length === 0 && (
                <Typography className="no-data-available">
                  No Sensors
                </Typography>
              )}

              {sensorsOfDevice.map((sensor, index) => (
                <Grid item >
                  <Chip
                  key={index}
                  className={style.blueChipOpaque}
                  label={sensor}
                />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.gridItemContainer}>
            <Typography className={classes.labelStyle} style={{ marginTop: "2px" }}>
              Actuators :
            </Typography>
            <Grid  container className={classes.gridItem}>
            {actuatorsOfDevice.length === 0 && (
              <Typography className="no-data-available" style={{ width: "100%" }}>
                No Actuators
              </Typography>
            )}
            {actuatorsOfDevice.map((actuator, index) => (
              <Grid item>
              <Chip
                key={index}
                className={`${style.blueChipOpaque} ${classes.chipContainer}`}
                label={actuator}
              />
              </Grid>))}
              </Grid>
          </Grid>
          <Grid item xs={4} md={4} className={classes.gridItemContainer}>
            <Typography className={classes.labelStyle}>
              Connectivity :
            </Typography>
            <Typography
              className={classes.valueStyle}
            >{connectivity}</Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.gridItemContainer}>
            <Typography className={classes.labelStyle}>
              Protocol :
            </Typography>
            <Typography
              className={classes.valueStyle}>{protocol}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.gridItemContainer}>
            <Typography className={classes.labelStyle}>
              Persistence :
            </Typography>
            <Typography className={classes.valueStyle}>
              {persistence === true ? "Yes" : "No"}
              </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}
/*
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "750px"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: props.opacity,
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid container
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: "1",
                boxShadow: "0px 4px 30px #00000008",
              }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>View Product Type</DialogTitle>
          </Grid>
          <Grid item xs={2} md={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
          >
            <IconButton onClick={props.onClose}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Product Name :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={productName}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Sensors :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} className={`cloud-container`}
                    style={{backgroundColor: textBarColor, marginBottom: "20px"}}>
                {sensorsOfDevice.length === 0 && (
                  <label className="no-data-available"
                         style={{width: "100%"}}>
                    No Sensors
                  </label>
                )}
                {sensorsOfDevice.map((sensor, index) => (
                  <div key={index} className="cloud-item">
                    <span className="sensor-number">{index + 1}</span>
                    <span className="cloud-item-text">{sensor}</span>
                    <div className="cloud-item-close">
                      <i
                        className="fa fa-times-circle"
                        style={{color: "#d94737"}}>
                      </i>
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Actuators :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} className={`cloud-container`}
                    style={{backgroundColor: textBarColor, marginBottom: "20px"}}>
                {actuatorsOfDevice.length === 0 && (
                  <label className="no-data-available" style={{width: "100%"}}>
                    No Actuators
                  </label>
                )}
                {actuatorsOfDevice.map((actuator, index) => (
                  <div key={index} className="cloud-item">
                    <span className="sensor-number">{index + 1}</span>
                    <span className="cloud-item-text">{actuator}</span>
                    <div className="cloud-item-close">
                      <i
                        className="fa fa-times-circle"
                        style={{color: "#d94737"}}>
                      </i>
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Connectivity :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={connectivity}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Protocol :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={protocol}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                  Persistence :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={persistence}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  OTA Upgradable:
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                    disabled={true}
                    variant="outlined"
                    defaultValue={
                      otaUpgradable ?  
                      "OTA Upgrade is enabled" : "OTA Upgrade is  not enabled" 
                    }
                    error={{error: false}}
                    style={classes.textBarStyle}
                />
              </Grid>

              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Remotely Configurable:
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                    disabled={true}
                    variant="outlined"
                    defaultValue={
                      remotelyConfigurable ?  
                      "Remote Configuration is enabled" : "Remote Configuration is not enabled" 
                    }
                    error={{error: false}}
                    style={classes.textBarStyle}
                />
              </Grid>

              {(otaUpgradable || remotelyConfigurable) && (
                <>
                  <Grid item xs={3} md={3} className={classes.gridStyle}>
                    <Typography className={classes.labelStyle}>
                      Data Format:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <TextBar
                      disabled={true}
                      variant="outlined"
                      defaultValue={dataFormat}
                      error={{error: false}}
                      style={classes.textBarStyle}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={3} md={3}
                    className={classes.gridStyle}
                >
                <Typography className={classes.labelStyle}>
                  Transcoding :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  disabled={true}
                  variant="outlined"
                  defaultValue={
                    transcoder ? 
                      codecName !== ""  ? codecName : "No transcoder added" 
                    : "Transcoding not enabled"
                  }
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
              </Grid>
            </Grid>
          </DialogContent>

        </>
      </Dialog>
      {loadingScreen &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80} />
        </div>}
    </div>
  );
};

*/
const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px"
  },
  gridContainer:{
    width: "100%",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
    padding:"10px",
    opacity: "1",
    boxShadow: "0px 4px 30px #00000008",
  },
  gridStyle: {
    textAlign: "left",
    marginBottom: "25px",
    paddingRight: "2%",
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "25px",
    marginBottom: "4px", 
    color:"#707070"
  },

  textBarStyle: {
    backgroundColor: textBarColor,
    height: "38px",
    borderRadius: "5px",
    border: "1px solid black",
  },
  gridItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    justifyContent: "left",
    height: "100%",
    padding: "10px",
    flexWrap: "wrap",
    paddingLeft:"20px"
  },
  gridItem:{
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",  
    justifyContent: "flex-start",
    alignItems: "center"  
  },
  valueStyle: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    color: "#212529",
  },
  
    chipContainer: {
      display: "flex !important",
      flexWrap: "wrap",
      gap: theme.spacing(1), // Adds spacing between chips
      justifyContent: "flex-start !important", // Aligns chips to the left
    },
    headerContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      background: "#FFFFFF",
      boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.08)",
      margin: "0 auto",
    },
    dialogTitle: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
      textTransform: "uppercase",
      color: "#1D283D",
      padding: "20px",
    },
    closeButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
}));
