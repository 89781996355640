import { Button, Checkbox, Grid, IconButton, InputBase, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { CheckBox, Close } from '@material-ui/icons';
import { Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon, CloseIconColor, DownArrow, UpArrow } from 'src/components/Icons';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import useStyles from '../../assets/styles';
import { FilterBatchNumber } from './FilterBatchNumber';

export const MultiSelectDropDown = (props) => {
    const classes = useStyles()
    const [filterDevices, setFilterDevices] = useState(props.options)
    const [searchTerm, setSearchTerm] = useState('')
    const dropdownRef = useRef(null);

    const handleSelectDevice = (device) => {
        console.log(device)
        props.setSelectedDevices((prevDevices) => {
            const devices = [...prevDevices];
            const deviceIndex = devices.findIndex((d) => d === device);

            if (deviceIndex === -1) {
                // Add device if not already selected
                devices.push(device);
            } else {
                // Remove device if already selected
                devices.splice(deviceIndex, 1);
            }

            return devices;
        });
    };

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)

        if (value) {
            const filteredData = props.options.filter(device =>
                device.toString().includes(value)
            );
            setFilterDevices(filteredData);
        } else {
            setFilterDevices(props.options);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            props.setIsDropdownVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Grid container ref={dropdownRef}>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <SearchBar onChange={handleSearch} />
                </Grid>
            </Grid>
            <Grid container className={classes.multiSelectDropDownTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell} align="left" ></TableCell>
                            <TableCell className={classes.tableCell} align="left">Device ID</TableCell>
                            <TableCell className={classes.tableCell} align="left">Laset Seen</TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {filterDevices.map((device, index) => {
                            return (
                                <TableRow style={{ backgroundColor: index % 2 == 1 ? '#FFFFFF' : '#F5F5F5' }} key={index}>
                                    <TableCell align="left">
                                        <Checkbox
                                            classes={{
                                                checked: classes.checked
                                            }}
                                            checked={props.selectedDevices.includes(device.id)}
                                            onClick={() => handleSelectDevice(device.id)}
                                            className="check-box"
                                        />
                                    </TableCell>
                                    <TableCell align="left">{device.id}</TableCell>
                                    <TableCell align="left">{device.lastSeen && device.lastSeen != '' ? device.lastSeen : 'N/A'}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>

            </Grid>
            <Grid container justifyContent='flex-end'>
                <Button variant='outlined' style={{ width: '100px' }} onClick={props.handleCancel}>Cancel</Button>
                <Button variant='outlined' className={classes.deviceAddButton} onClick={props.handleSave}>Add</Button>
            </Grid>
        </Grid>
    );
}

export const DeviceCard = ({ device, onClose }) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.deviceCard}>
            <label style={{ marginRight: '10px' }}>{device}</label>
            <IconButton onClick={() => onClose(device)}>
                <CloseIcon color='#2A7BEC' />
            </IconButton>

        </Grid>
    )
}
