import * as React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Provider} from 'react-redux';
import store from '../../components/store.js'
import { ProductTypComponent } from './ProductTypComponent';
import { ConfigurationProduct } from '././configure-product/ConfigurationProduct';

export function SetupProductTypeLayout() {
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <Switch>
            <Route path={"/product-types/configurations"} component={ConfigurationProduct}/> 
            <Route path={"/product-types"} component={ProductTypComponent}/>
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>

  )
}
