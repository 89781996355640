import { Button, Grid, Radio } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { FilterIcon } from "src/components/Icons"
import useStyles from "../../assets/styles"
import { Typography } from "antd"

export const FilterBatchNumber = ({ filterVisible, toggleFilter, handlePrefixSelect, setFilterVisible,prefixes , selectedPrefix, filterBatch,setSelectedPrefix }) => {
    const classes = useStyles()
    const [batchPrefixes, setBatchPrefixes] = useState([])
    const href = useRef(null)

    const handleCloseFilter = () =>{
        setFilterVisible(false)
        setSelectedPrefix('All')
    }

    useEffect(() =>{
        if(prefixes){
            setBatchPrefixes([
                'All',
                ...prefixes
            ])
        }
    },[prefixes])

    const handleClickOutside = (event) => {
        if (href.current && !href.current.contains(event.target)) {
            setFilterVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Grid item  ref={href} className="filter-validity" style={{ position: 'relative', display: 'inline-block' }}>
            <div className="filerButton" onClick={toggleFilter} style={{ display: 'flex', alignItems: 'center' }}>
                <FilterIcon />
                <span style={{ marginLeft: '4px' }}>Filter</span>
            </div>
            {filterVisible && (
                <div className="filter-options" style={{ position: 'absolute', top: '100%', left: 0, backgroundColor: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',width:'320px' ,padding:'15px 0px'}}>
                    <Grid item style={{borderBottom:'1px solid #EDEDED',marginBottom:'10px'}}>
                        <Typography style={{color:'#1D283D',paddingLeft:'15px',fontFamily:'Poppins',fontWeight:'bold'}}>Filter</Typography>
                    </Grid>
                    <Grid item style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {batchPrefixes.map((prefix, index) => (
                            <div key={index} style={{cursor:'pointer',display:'flex',alignItems:'center'}} onClick={() =>handlePrefixSelect(prefix)}>
                                <Radio
                                    className={classes.filterRadio}
                                    value={`prefix-${index}`}
                                    checked={selectedPrefix === prefix}
                                    onChange={() => handlePrefixSelect(prefix)}
                                    color="default"
                                />
                                <label htmlFor={`prefix-${index}`} style={{ marginLeft: '8px' }}>
                                    {prefix}
                                </label>
                            </div>
                        ))}
                    </Grid>
                    <Grid container justifyContent="flex-end" style={{paddingRight:'10px'}}>
                        <Button variant= 'outlined' style={{marginRight:'10px'}} onClick={handleCloseFilter}>Cancel</Button>
                        <Button variant='contained' style={{backgroundColor:'#2A7BEC',color:'white'}} onClick={filterBatch}>Apply Filter</Button>
                    </Grid>
                </div>
            )}
        </Grid>
    )
}