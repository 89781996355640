import * as React from "react";
import {useEffect, useState} from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import HelpInfo from '@material-ui/icons/InfoOutlined';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {primaryBlue, primaryGray, primaryRed} from 'src/components/colors';
import Dropzone from 'react-dropzone';
import {DragDrop} from 'src/components/sub-components/DragAndDrop';
import 'react-toastify/dist/ReactToastify.css';
import {environment} from "src/environments/environment";
import {Alert} from "@material-ui/lab";
import axios from 'axios';
import useStyles from "src/app/maintenance/assets/styles";

export function AddNewDecoder(props) {
  const style = useStyles();
  const classes = localStyles();
  const [name, setName] = useState('');
  const [decoderScript, setDecoderScript] = useState('');
  const [encoderScript, setEncoderScript] = useState('');
  const [nameError, setNameError] = useState(false);
  const [encoderFileError, setEncoderFileError] = useState(false);
  const [encoderFileAvailable, setEncoderFileAvailable] = useState(true);
  const [decoderFileError, setDecoderFileError] = useState(false);
  const [decoderFileAvailable, setDecoderFilAvailable] = useState(true);
  const [selectedDecoderScript, setSelectedDecoderScript] = useState();
  const [selectedEncoderScript, setSelectedEncoderScript] = useState();
  const [selectedDataFormat, setSelectedDataFormat] = useState('');
  const [formatError, setFormatError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [toaster, setToaster] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(false);

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const closePopup = () => {
    setNameError(false);
    setFormatError(false);
    setEncoderFileError(false)
    setDecoderFileError(false)
    setName('');
    setDecoderScript('');
    setEncoderScript('');
    setSelectedDataFormat('');
    setSelectedDecoderScript(null)
    setSelectedEncoderScript(null)
    props.onClose();
  }

  const readDecoderScript = (decoderFile) => {
    setDecoderScript(decoderFile[0].name)
    setSelectedDecoderScript(decoderFile[0])
    setDecoderFileError(false)
  }

  const createDecoder = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('decoderFile', selectedDecoderScript ? selectedDecoderScript : new File([''], 'empty.txt', { type: 'text/plain' }));
    formData.append('encoderFile', selectedEncoderScript ? selectedEncoderScript : new File([''], 'empty.txt', { type: 'text/plain' }));
    formData.append('codecName', name);
    formData.append('scriptFormat', "JSON");

    axios({
      method: 'POST',
      url: `${environment.host}/core/codec`,
      data: formData,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        const responseData = response.data;
        if (responseData.message === 'Success') {
          props.onCodecToPlaceholder(name);
          props.onClose();
          props.successCallback("Success");
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setToaster(true);
        setErrorMessage(error.response ? error.response.data.message : error.message);
        setReqSuccess(false);
        setTimeout(() => {
          setToaster(false);
        }, 4000);
        console.error(error);
      });
  };


  const readParameters = async () => {
    let valid = true;

    if (decoderScript === '' || encoderScript === '' || name === '') {
      if (name === '') {
        setNameError(true);
      }
      if (selectedDataFormat === '') {
        setFormatError(true);
      }
      if (encoderScript === '') {
        setEncoderFileError(true);
      }
      if (decoderScript === '') {
        setDecoderFileError(true);
      }
    }

    if (selectedEncoderScript && !await fileExists(selectedEncoderScript)) {
      setEncoderFileAvailable(false);
      valid = false;
    }

    if (selectedDecoderScript && !await fileExists(selectedDecoderScript)) {
      setDecoderFilAvailable(false);
      valid = false;
    }

    if (valid) {
      createDecoder();
    }
  };

  const fileExists = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(true);
      reader.onerror = () => resolve(false);
      reader.readAsArrayBuffer(file);
    });
  };


  useEffect(() => {
    setSelectedDataFormat('');
  }, []);


  const NewTooltip = withStyles({
    tooltip: {
      color: "black",
      backgroundColor: "white",
      boxShadow: "0px 3px 6px #00000029",
      padding: "10px",
      fontSize: "12px",
    },
  })(Tooltip);

  return (
    <Grid item xs={12} md={12}
          className={`testing`}
          style={{margin: "0px"}}
    >
      <div
        style={{
          textAlign: "left",
          fontSize: "15px",
          marginBottom: '10px'
        }}>
        <FormControl className={classes.input}>
          Codec Name
          <input
            type={'text'}
            className={classes.formInput}
            style={{
              borderColor: nameError === false ? "#C7C4C4" : primaryRed,
              textIndent: "10px",
              borderStyle: 'solid',
              borderWidth: '0.5px'
            }}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(false);
            }}
            value={name}
            placeholder="Enter codec name"
            readOnly={false}
          />
          {nameError === true &&
            <FormHelperText className={classes.helperText}>
              Name Required *
            </FormHelperText>}
        </FormControl>

        <div className={classes.input}
             style={{marginTop: '10px'}}>
          <NewTooltip title="Convert Device Data into Magma Data Format." placement="right" arrow>
                        <span className={style.tooltipCustom}>
                            Decoder
                            <HelpInfo style={{fontSize: 16, marginLeft: 5}}/>
                        </span>
          </NewTooltip>
          <Dropzone
            onDrop={file => {
              readDecoderScript(file);
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}
                     style={{
                       borderColor: (decoderFileError === true && encoderFileError === true) ? primaryRed : "#427AE5",
                     }}
                     className={style.dragDrop}>
                  <input {...getInputProps()} accept=".java"/>
                  <DragDrop/>
                  <Typography className={style.DragDropText}>
                                    <span className={style.dragText}>
                                    {decoderScript === "" ? "Drag & Drop the Script" : decoderScript}
                                    </span><br/>
                    <span className={style.dragTextSmall}>
                                    {decoderScript != "" ? "" : "or Select the file from device"}
                                        </span>
                  </Typography>
                </div>
              </section>
            )}
          </Dropzone>
          {decoderFileError === true && encoderFileError === true &&
            <FormHelperText className={classes.helperText}>File Required *</FormHelperText>}
          {!decoderFileAvailable &&
            <FormHelperText className={classes.helperText}>Decoder file is not in the given location *</FormHelperText>}
        </div>
      </div>
      {
        <DialogActions style={{width: "100%"}} hidden>
          <Button variant='contained'
                  className={style.backButton}
                  onClick={() => closePopup()}
          >
            Cancel
          </Button>
          <Button variant='contained' className={style.saveButton}
                  onClick={() => readParameters()}
          >
            Add
          </Button>
        </DialogActions>
      }
      <Snackbar
        open={toaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          onClose={handleCloseToaster}
          severity={reqSuccess === true ? "success" : "error"}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
    </Grid>
  )
}

export default AddNewDecoder

const localStyles = makeStyles((themes: Theme) =>
  createStyles({
    helperText: {
      color: "red"
    },
    input: {
      width: "100%",
      textAlign: "left",
      marginBottom: '20px'
    },
    formInput: {
      height: "38px",
      borderRadius: "5px",
      fontFamily: "'Poppins', sans-serif"
    },
  }),
);
