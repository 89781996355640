import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import * as React from 'react';
import {useState} from 'react';

export const ToasterComponent = (props) => {

  return (
    <Snackbar
      open={props.toaster}
      autoHideDuration={6000}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
    >
      <Alert
        severity={props.reqSuccess === true ? "success" : "warning"}
      >
        {
          props.message.length > 0
            ? props.message
            : "Something went wrong"
        }
      </Alert>
    </Snackbar>
  )

}
