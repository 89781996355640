import * as React from 'react';
import {useState} from 'react';
import {CreateKitModels} from './steps/CreateKitModels';
import {Grid, Typography} from '@material-ui/core';


export function EditKitModel(props) {

  const onClose = () => {
    props.setKitModelFormType('Add')
    props.setIsEyeIconClick(false)
  }

  return (
    <div>
      <aside>
        <div className="modal_HighZindex" id="addKitModel">
          <div className="custom-modal-content" style={{width: '900px'}}>
            <Grid container className="modal-header" style={{textAlign: 'left'}}>
              <Grid item xs={7} md={7}>
                <Typography>
                  View Kit Model
                </Typography>
              </Grid>
              <Grid item xs={5} md={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="modal-close" style={{color: "#666D73"}} onClick={() => onClose()}>&times;</button>
              </Grid>
            </Grid>
            <CreateKitModels
              setIsApiClick={props.setIsApiClick}
              increaseStep={props.increaseStep}
              decreaseStep={props.decreaseStep}
              allSensors={props.allSensors}
              allActuators={props.allActuators}
              allTypes={props.allTypes}
              allKitModelsName={props.allKitModels}
              setIsToasterOpen={props.setIsToasterOpen}
              setToasterMessage={props.setToasterMessage}
              setReqSuccess={props.setReqSuccess}
              getKitModels={props.getKitModels}
              setLastCreatedKitModel={props.setLastCreatedKitModel}
              setLoading={props.setLoading}
              kitModelFormType={props.kitModelFormType}
              setIsEyeIconClick={props.setIsEyeIconClick}
              selectModel={props.selectModel}
              setKitModelFormType={props.setKitModelFormType}
            />

          </div>
        </div>
      </aside>
    </div>
  )
}
