import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  Grid,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Checkbox,
  createTheme,
  TablePagination,
  FormGroup,
  FormControlLabel, FormControl
} from '@material-ui/core';
import useStyles from '../../../assets/styles';
import AddNewDecoder from '../CreateCodec';
import {DownArrow, UpArrow} from '../../../../../components/Icons';
import {environment} from '../../../../../environments/environment';
import {Loader} from '../../../test-automation/components/Loader';
import {ToasterComponent} from '../../../test-automation/components/Toaster';
import axios from 'axios';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export default function Step2SelectTestCases({ formData,
                                               setFormData,
                                               connectedDevices,
                                               selectedDeviceFinal,
                                               setSelectedDeviceFinal,
                                               batchNumber,
                                               batchNumberError,
                                               message,
                                               setMessage,
                                               isExistingBatchNumber,
                                               SetBatchNumber,
                                               loading,
                                               setInputValue,
                                               transcoding,
                                               setTranscoding,
                                               transcodingError,
                                               setTranscodingError,
                                               selectedDeviceError
                                             }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [step, setStep] = useState("select");
  const [codecs, setCodecs] = useState([]);
  const [showCreateCodecDialog, setShowCreateCodecDialog] = useState(false);
  const [loadings, setLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reqSuccess, setReqSuccess] = useState(false);
  const [isCodecDropDown, setIsCodecDropDown] = useState(false);


  useEffect(() => {
    setInputValue(batchNumber);
  }, [batchNumber, setInputValue]);


  const handleCreateCodecClick = () => {
    setStep("create");
    setShowCreateCodecDialog(!showCreateCodecDialog);
  };
  const handleSelectCodecClick = () => {
    setStep("select");
    fetchCodecs();
  };

  useEffect(() => {
    if (step === 'select') {
      fetchCodecs();
    }
  }, [step]);

  const handleCodecChange = (option) => {
    setTranscoding(option);
    setIsCodecDropDown(false);
    setTranscodingError(false); // Reset error when a codec is selected
    setFormData({
      ...formData,
      transcoding: option // Pass the selected value
    });
  };
  const successCallback = (message) => {
    if (message === "Success"){
      setLoading(false);
      setToaster(true);
      setErrorMessage('Codec Added Successfully');
      setReqSuccess(true);
    }
  }
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isCodecDropDown) {
        if (
          !event.target.closest('.codec-dropdown')
        ) {

          setIsCodecDropDown(false);
        }
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isCodecDropDown]);

  const fetchCodecs = () => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    axios({
      method: 'GET',
      url: `${environment.host}/core/codec`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          setCodecs(data.content);
        } else {
          console.error('Failed to fetch codecs:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Error fetching codecs:', error);
      });
  };




  const handleCheckBoxChange = (deviceId) => (event) => {
    console.log('Checkbox clicked for device:', deviceId);
    setSelectedDeviceFinal((prevSelected) => {
      if (event.target.checked) {
        console.log('Adding device:', deviceId);
        return [...prevSelected, deviceId];
      } else {
        console.log('Removing device:', deviceId);
        return prevSelected.filter((id) => id !== deviceId);
      }
    });
  };

  useEffect(() => {
    console.log('Selected devices:', selectedDeviceFinal);
  }, [selectedDeviceFinal]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    const trimmedValue = value.trim();
    if (name === 'batch') {
      SetBatchNumber(trimmedValue);
      setInputValue(trimmedValue);
      setMessage('');
    }setFormData({
      ...formData,
      [name]: trimmedValue
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center" justifyContent="center" style={{marginBottom: "30px"}}>
        <Grid item xs={12} md={8}>
          <Grid container className={classes.subBoxContainer}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={3} className={classes.label}>
                  Batch: <span style={{color: 'red'}}>*</span>
                </Grid>
                <Grid item xs={9}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    name="batch"
                    value={batchNumber}
                    onChange={handleInputChange}
                  />
                  {
                    isExistingBatchNumber && (
                      <Typography className={classes.errorText}>
                        {message}
                      </Typography>
                    )
                  }
                  {batchNumberError && (
                    <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px"}}>
                      <Typography className={classes.errorText}>
                        Batch Number is Required
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>


              <Grid container style={{marginTop: "30px"}}>
                <Grid item xs={3} md={3} className={classes.label}>
                  Device Transcoding:
                  <span style={{color: 'red'}}>*</span>
                </Grid>
                <Grid item xs={9} md={9}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <Grid container
                            alignItems="center"
                            justifyContent="center"
                            onClick={handleSelectCodecClick}
                            className={`${classes.formInput} ${classes.codecButton}`}
                            style={{
                              borderBottom: step === "select" ? "3px solid #2A7CED" : "1px solid #ccc",}}
                      >
                        <div> Select Codec Method</div>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container
                            alignItems="center"
                            justifyContent="center"
                            onClick={handleCreateCodecClick}
                            className={`${classes.formInput} ${classes.codecButton}`}
                            style={{
                              borderBottom: step === "create" ? "3px solid #2A7CED" : "1px solid #ccc",}}
                      >
                        <div> Create Codec</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* create codec form elements */}
                  {step === 'create' && (
                    <Grid container spacing={3} style={{marginTop: "20px"}}>
                      <Grid item xs={12} md={12}>
                        <AddNewDecoder
                          onClose={handleSelectCodecClick}
                          codecs={codecs}
                          onCodecToPlaceholder={handleCodecChange}
                          successCallback={successCallback}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* select codec form elements */}
                  {step !== 'create' && (
                    <Grid container spacing={3} style={{marginTop: "20px"}}>
                      <Grid item xs={12} md={12}>
                        <FormControl className={classes.input}>
                          <Grid container style={{height: '40px'}}>
                            <Grid container
                                  className={classes.formInput}
                                  style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderRadius: '4px', // Optional: add border-radius for rounded corners
                                  }}
                                  onClick={() => setIsCodecDropDown(true)}
                            >
                              <Grid item style={{flex: 1}}>
                                <div
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: '#000', // Optional: Set the text color
                                  }}
                                >
                                  {transcoding === '' ? "Select Codec" : transcoding}
                                </div>
                              </Grid>
                              <Grid item>
                                {isCodecDropDown ? <UpArrow/> : <DownArrow/>}
                              </Grid>
                            </Grid>

                            {isCodecDropDown && (
                              <div style={{width: '100%', zIndex: 1}}>
                                <div className={classes.dropdown}>
                                  {codecs.map((codec, index) => {
                                    return (
                                      <Grid item xs={12} md={12}
                                            className={classes.option}
                                            key={index}
                                            onClick={() => {
                                              handleCodecChange(codec.codecName)
                                            }}
                                      >
                                        <span style={{marginLeft: '10px'}}> {codec.codecName} </span>
                                      </Grid>
                                    )
                                  })
                                  }
                                </div>
                                <div style={{marginTop: '20px'}}/>
                              </div>
                            )}
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {transcodingError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                    <Typography className={classes.errorText}>
                      Transcoding is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid container alignItems="center" justifyContent="flex-end" style={{marginTop: "30px", marginBottom: "15px"}}>
                <Grid item xs={9} md={11}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="left">Device ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <Typography>Loading devices...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : connectedDevices && connectedDevices.length > 0 ? (
                          connectedDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device) => (
                            <TableRow key={device}>
                              <TableCell align="center" onClick={() => console.log('TableCell clicked')}>
                                <FormGroup>
                                  <FormControlLabel
                                    label=""
                                    control={
                                      <Checkbox
                                        className={classes.radio}
                                        checked={selectedDeviceFinal.includes(device)}
                                        onChange={handleCheckBoxChange(device)}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="left">
                                <Typography>{device}</Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <Typography>No Devices Available</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {
                    selectedDeviceError && (
                      <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "5px"}}>
                        <Typography className={classes.errorText}>
                          Selecting At least One Device is Required.
                        </Typography>
                      </Grid>
                    )
                  }
                  <Grid container justifyContent='center' style={{marginRight: "20px"}}>
                    <Grid item xs={6} md={6} style={{marginTop: "10px"}}>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={connectedDevices !== undefined && connectedDevices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Loader loading={loadings}/>
      <ToasterComponent
        toaster={toaster}
        message={errorMessage}
        reqSuccess={reqSuccess}
      />
    </ThemeProvider>
  );
}
