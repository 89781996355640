import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Grid, InputBase, makeStyles, Theme, Typography} from '@material-ui/core'
import {primaryBlue, primaryGray, white} from 'src/components/colors';
import {DownArrowIcon, PlusIcon, UpArrow} from 'src/components/Icons';
import useStyles from "src/app/maintenance/assets/styles";


export function DropDown(props) {
  const classes = local();
  const style = useStyles();
  const [isDropDown, setIsDropDown] = useState(false)
  const dropdownRef = useRef(null);
  const [selectOptions,setSelectOptions] = useState([])
  const [searchQuery,setSearchQuery] = useState("")

  const handleValue = (e) => {
    setSearchQuery('')
    if (props.type == 'kitModel') {
      handleKitModels(e)
      setSearchQuery(e)
    } else if (props.type == 'batchNumber') {
      handleBatchNumber(e)
    }else{
      props.onClick(e)
    }
    
  }

  const handleBatchNumber = (e) => {
    props.setSelectOption(e.toString())
    props.setBatchNumber(e.toString());
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDown(false)
      }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropDown = () => {
    if (props.kitModelFormType !== 'Edit') {
      setIsDropDown(!isDropDown)
      setSelectOptions(props.options)
    }
  };


  const handleAddClick = () => {
    props.setIsAddClick(true)
    const isDropDownClick = isDropDown;
    if (isDropDownClick == true) {
      setIsDropDown(false)
    }
  }

  const handleKitModels = (e) => {
    props.setSelectKitModel(e)
    console.log(e)
    props.setModelError({error: false, errorMessage: ''})

  }

  

  const handleSearchOption = (event) =>{
    console.log(event.target.value)
    if(props.type != 'option' && props.type != 'batchNumber'){

      const value = event.target.value
      console.log(props.type)
      setSearchQuery(value);
      if (props.kitModelFormType !== 'Edit') {
        if(value == ''){
          setSelectOptions(props.options)
        }else{
          const filterOption = props.options.filter((option) => option.toLowerCase().includes(value.toLowerCase()))
          console.log(filterOption)
          setSelectOptions(filterOption)
        }
      }
    }
    
  }


  return (
    <Grid ref={dropdownRef} container style={{height: '40px'}}>
      <Grid container className={style.formInput}
            style={{border: props.borderStyle||'1px solid #e0e0e0', backgroundColor: white, paddingRight: '0', cursor: 'pointer',margin:'0px'}}
            alignContent='center' onClick={() => handleDropDown()}>
        <Grid item xs={11} md={11}>
          <InputBase
            type="text"
            placeholder={props.type == 'option' ? props.placeholder : props.emptyTag}
            classes={{ input: props.applyCustomPlaceholder ? classes.input : classes.inputBaseCustom }}
            value={props.type == 'option' || props.type == 'batchNumber'? props.value : searchQuery}
            onChange={handleSearchOption}
            fullWidth
            autoFocus
          />
        </Grid>
        {props.kitModelFormType !== 'Edit' ?
          <Grid item xs={1} md={1} style={{textAlign: 'center'}}>
            {isDropDown ? <UpArrow/> : <DownArrowIcon/>}
          </Grid>
          :
          <></>

        }

      </Grid>
      {isDropDown ?
        <div style={{
          textAlign: 'left',
          border: '.75px solid rgba(63, 68, 81, 0.12)',
          margin: 'px',
          borderRadius: '5px',
          backgroundColor: white,
          width: '100%',
          zIndex: '1',
          maxHeight: '160px',
          overflowY: 'auto'
        }}>
          {props.isAddRequired ?
            <Grid item className={style.option} onClick={() => handleAddClick()}>
              <Grid container style={{marginTop: '5px'}}>
                <Grid item style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: white,
                  borderRadius: '50%',
                  boxShadow: "0px 4px 8px #0000001F",
                  width: '20px',
                  height: '20px'
                }}>
                  <PlusIcon/>
                </Grid>
                <Typography
                  style={{paddingLeft: '10px'}}>{props.type == 'batchNumber' ? props.addTag : props.emptyTag}</Typography>
              </Grid>
            </Grid>
            :
            <></>

          }
          {selectOptions.map((option, index) => {
            return (
              <Grid item className={style.option} onClick={() => {
                handleValue(option), props.isSingle ? handleDropDown() : null
              }} key={index}>
                {option}
              </Grid>
            )

          })

          }

        </div>
        :
        <></>

      }
    </Grid>
  )

}

const local= makeStyles((theme: Theme) => ({
  input: {
    "&::placeholder": {
      color: primaryGray,  
      opacity: 1, 
      fontFamily: "'Poppins', sans-serif",
      fontSize:'14px'
    }
  },
  inputBaseCustom: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'white',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '13px',
  },
}))


