import {Card, Grid, Paper, Typography} from '@material-ui/core';
import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ActiveSensors, SensorFailure, WrongValued} from 'src/components/Icons';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "10px",
  },
  image: {
    width: "50px",
    height: "50px",
    margin: "10px",
    backgroundColor: "#DADADA",
    padding: "10px"
  }
}));

export default function SensorCard(props) {

  const classes = useStyles();

  return (
    <Card elevation={3} className={classes.card}>
      <Grid container>
        <Grid item xs={4} md={4}>
          <Paper elevation={0} className={classes.image}>
            {
              props.name === "Sensor Failure" ? <SensorFailure/>
                :
                props.name === "Active Sensors" ? <ActiveSensors/>
                  :
                  props.name === "Wrong Valued" && <WrongValued/>
            }
          </Paper>
        </Grid>
        <Grid item xs={8} md={8}>
          <Grid container>
            <Grid item xs={12} md={12} style={{textAlign: "left"}}>
              <Typography style={{
                fontWeight: 500,
                fontSize: "12px",
                marginTop: "10px",
                marginLeft: "5px"
              }}>{props.name}</Typography>
            </Grid>
            <Grid item xs={12} md={12} style={{textAlign: "left"}}>
              <Typography style={{
                fontWeight: 700,
                fontSize: "18px",
                marginTop: "5px",
                marginLeft: "5px"
              }}>{props.value}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}


