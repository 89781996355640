import { Box, createTheme, Grid, Icon, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from '../maintenance/assets/styles';
import { Doughnut } from 'react-chartjs-2';
import { Opacity } from '@material-ui/icons';
import { azureService } from '../utils';
import {useLocation} from 'react-router-dom'
import { deleteTestDevice, fetchUserRole, fetchUsers, getAllDevices, getArchivedDevices, getUserRole, removeUser } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { UserTable } from './components/UserTable';
import { DeviceTable } from './components/DeviceTable';
import { SubscriptionDeviceIcon, SubscriptionUserIcon } from 'src/components/Icons';
import { DeletePopup } from '../maintenance/test-automation/components/DeletePopup';
import Cookies from "js-cookie";

export const subscriptionUsageComponent = () => {
    const theme = createTheme({
        typography: {
            fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();
    const location = useLocation();
    const {isAzureFreePackage, isAzureBasicPackage, isAzurePremiumPackage} = azureService();

    //Filter and pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search,setSearch] = useState('');
    const [searchTerm,setSearchTerm] = useState('')
    const [filterVisible,setFilterVisible] = useState(false)
    const [selectedPrefix,setSelectedPrefix] = useState('')
    const [prefixes,setPrefixes] = useState([])

    //Users
    const [users,setUsers] = useState([])
    const [filterUsers,setFilterUsers] = useState([])
    const [isDeleteUser,setIsDeleteUser] = useState(false)
    const [selectedUserName,setSelectedUserName] = useState('')
    const [userCount,setUserCount] = useState(0)
    const [userRoles,setUserRoles] = useState([])
    const [selectedCard, setSelectedCard] = useState("user");

    //Devices
    const [isDeleteDevice,setIsDeleteDevice] = useState(false)
    const [selectedUserId,setSelectedUserId] = useState('')
    const [selectedDeviceId,setSelectedDeviceId] = useState('')
    const [devicesCount,setDevicesCount] = useState(0)
    const [archivedDevicesCount, setArchivedDevicesCount] = useState(0)
    const [totalDevicesCount, setTotalDevicesCount] = useState(0)
    const [filterDevices,setFilterDevices] = useState([])
    const [testDevices,setTestDevices] = useState([])

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false) 
    const [loading, setLoading] = useState(false)

    const roleId = Cookies.get('ROLE');
    const existUserId = Cookies.get('USER_ID');
    const planId = Cookies.get('PLANID');

    const getCountValue = (value) => (value !== undefined && value !== 0 ? value : 0);

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false,
        },
        hover: {
            mode: null,
        },
        rotation: - Math.PI,
        plugins: {
            labels: false,
        },
        tooltips: {
            enabled: false,
        },
        cutoutPercentage: 80,
    };

    const totalDeviceCount = isAzureFreePackage() ? 10 : totalDevicesCount;
    const totalUserCount = isAzureFreePackage() ? 2 : isAzureBasicPackage() ? 5 : isAzurePremiumPackage() ? 10 : 0;
    
    const deviceData = [
        {
            indicatorData: [
                {label: isAzureFreePackage() ? 'Onboarded Devices(tried Devices)' : 'Registered Devices', count: isAzureFreePackage() ? totalDevicesCount : devicesCount, percentage: totalDeviceCount, color: '#005B96'},
                {label: isAzureFreePackage() ? 'Remaining Devices(available tries)' : 'Deleted Devices', count: isAzureFreePackage() ? (totalDeviceCount - totalDevicesCount) : archivedDevicesCount, percentage: totalDeviceCount, color: isAzureFreePackage() ? '#E2E5ED' : '#FF4343'},
            ],
            boxCount: totalDeviceCount,
            boxLabel: isAzureFreePackage() ? 'Adding Device Try Limit' : 'Billed Devices',
            middleLabel: 'Devices',
            middleIcon: <SubscriptionDeviceIcon />,
            options: chartOptions,
            data: {
                labels: isAzureFreePackage() ? ['Registered Devices', 'Remaining Devices'] : ['Registered Devices', 'Deleted Devices'],
                datasets: [
                    {
                        data: isAzureFreePackage() ? [totalDevicesCount, totalDeviceCount - totalDevicesCount] : [devicesCount, archivedDevicesCount],
                        backgroundColor: isAzureFreePackage() ? ['#005B96', '#E2E5ED'] : ['#005B96', '#FF4343'],
                        borderWidth: 0,
                    },
                ],
            },
        }
    ];

    const userData = [
        {
            indicatorData: [
                {label: 'Registered Users', count: userCount, percentage: totalUserCount, color: '#005B96'},
                {label: 'Remaining Users', count: (totalUserCount - userCount), percentage: totalUserCount, color: '#E2E5ED'},
            ],
            boxCount: totalUserCount,
            boxLabel: 'User Limit',
            middleLabel: 'Users',
            middleIcon: <SubscriptionUserIcon />,
            options: chartOptions,
            data: {
                labels: ['Registered Users', 'Remaining Users'],
                datasets: [
                    {
                        data: [userCount, (totalUserCount - userCount)],
                        backgroundColor: ['#005B96', '#E2E5ED'],
                        borderWidth: 0,
                    },
                ],
            },
        },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearchUser = (event) => {
        const { value } = event.target;
        const lowerCaseSearchTerm = value.toLowerCase();
        setSearchTerm(lowerCaseSearchTerm)

        let updatedUser = users.filter(user => {
            const matchSearch = user.username && user.username.includes(lowerCaseSearchTerm);
            return matchSearch;
        })
        setFilterUsers(updatedUser)
        setPage(0)
    };

    const handleSearchDevice = (event) =>{
        const {value} = event.target
        const lowerCaseSearchTerm = value.toLowerCase();
        let filterDevice = testDevices.filter(device => {
            const matchesPrefix = device.batchNumber && (selectedPrefix === 'All' || device.batchNumber.startsWith(selectedPrefix));
            const matchesSearch = device.id.toLowerCase().includes(lowerCaseSearchTerm) || device.batchNumber && device.batchNumber.toLowerCase().includes(lowerCaseSearchTerm);
            return matchesPrefix && matchesSearch;
        })
        setFilterDevices(filterDevice)
        setPage(0)
    }

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const filterBatch = () =>{  
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = testDevices.filter(device => {
            if(device.batchNumber){
                const matchesPrefix = selectedPrefix === 'All' || device.batchNumber.startsWith(selectedPrefix);
                const matchesSearch = device.batchNumber.toLowerCase().includes(lowerCaseSearchTerm)
                return matchesPrefix && matchesSearch;
            }
        })
        setFilterDevices(filtered);
        setFilterVisible(false)
        setPage(0)
    }

    const handleDeleteClose = () =>{
        setIsDeleteUser(false)
    }

    const handleDeleteOpen = (id,name,roleId) =>{
        setSelectedUserId(id)
        setSelectedUserName(name)
        setIsDeleteUser(true)
    }

    const handleDelete =async () =>{
        try{
            setLoading(true)
            const response = await removeUser(selectedUserId)
            if(response.status == 200){
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("User removed successfully.");
                getAllUser()
                window.dispatchEvent(
                    new CustomEvent('userDeleted', { detail: { selectedUserId } })
                );
            }else{
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(response.response.data.message);
            }
            setPage(0)
        }catch(error){

        }finally{
            setIsDeleteUser(false)
            setLoading(false)
            setTimeout(() => setIsToasterOpen(false), 2000);
        }
    }

    const getAllUser = async () =>{
        const users = await fetchUsers()
        const userRoles = await fetchUserRole()

        setUserRoles(userRoles);
        const currentUserRole = parseInt(getUserRole()); 
        if(users){
            //hide users that have greater user role
            const filteredUsers = users.filter((u)=>u.userRoleId > currentUserRole)
            setUsers(filteredUsers);
            setUserCount(filteredUsers.length);
            setFilterUsers(filteredUsers);
        }else{
            setUsers([])
            setFilterUsers([])
        }
        
    }

    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);
    
                // Fetch both users and roles simultaneously
                const [users] = await Promise.all([
                    getAllUser()
                ]);
    
            } catch (error) {
            } finally {
                setLoading(false); 
            }
        };
    
        initializeData();
    }, []);

    const getDevices = async () => {
        setLoading(true);
    
        try {
            let devices = [];
            let selectedDeviceIds = null;
    
            if (location && location.state && location.state.deviceIds) {
                selectedDeviceIds = location.state.deviceIds;
            }
            if (selectedDeviceIds && selectedDeviceIds.length > 0) {
                devices = await getAllDevices(selectedDeviceIds);
            } else {
                devices = await getAllDevices();
            }

            const archivedDeviceResponse = await getArchivedDevices(planId && planId !== "undefined" && planId !== "null" ? planId : undefined);
            let archivedDevices = archivedDeviceResponse.map(archived => ({
                ...JSON.parse(archived.object),
                isArchived: true,
                userInfo: archived.userInfo,
                deletedAt: archived.deletedAt
            }))

            const allDevices = [...devices, ...archivedDevices];
    
            setTestDevices(allDevices);
            setFilterDevices(allDevices);
            setDevicesCount(devices.length);
            setArchivedDevicesCount(archivedDevices.length)
            setTotalDevicesCount(allDevices.length);
    
            const batchNumbers = [];
            devices.forEach(device => {
                if (device.batchNumber && !batchNumbers.includes(device.batchNumber)) {
                    batchNumbers.push(device.batchNumber);
                }
            })
            setPrefixes(batchNumbers)
        } catch (error) {
            console.error('Error fetching devices:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() =>{
        getDevices()
    },[])

    const handleDeleteIcon = (id) =>{
        console.log("Delete icon clicked for ID:", id);
        setSelectedDeviceId(id)
        setIsDeleteDevice(true)
    }

    const onClose = () =>{
        setIsDeleteDevice(false)
    }

    const onDelete = async() =>{
        try{
            setLoading(true)
            const response = await deleteTestDevice(selectedDeviceId, planId && planId !== "undefined" && planId !== "null" ? planId : undefined)
            if(response){
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Device Removed Successfully")
                window.dispatchEvent(
                    new CustomEvent('deviceDeleted', { detail: { selectedDeviceId } })
                );
            }

        }catch(error){
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        }finally{
            setIsDeleteDevice(false)
            setLoading(false)
            await  getDevices()
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
            setPage(0)
        }   
    }

    useEffect(() => {
        setPage(0);
    }, [selectedCard]);
    
    return (
        <ThemeProvider theme={theme} >
            <Grid item md={12} xs={12} style={{ padding: '25px' }}>
                <Grid item style={{display:'flex',flexDirection:'row', gap:'20px', padding:'10px'}}>
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>Subscription Usage</Grid>
                    <Grid className={classes.azureStatus}>{isAzureFreePackage() ? 'Free' : isAzureBasicPackage() ? 'Basic' : isAzurePremiumPackage() ? 'Premium' : 'None'}</Grid>
                </Grid>
                <Grid container style={{marginTop:'20px'}}>
                    <Grid item md={12} xs={12} className={classes.mainFlexContainer} >
                        <Grid item xs={12} md={6} className={classes.subFlexContainer} onClick={() => setSelectedCard("user")} style={{border: selectedCard === "user" ? '1px solid #005B96' : '1px solid #E2E5ED'}}>
                            <Grid item className={classes.flexRowContainer}>
                                <Grid item xs={12} md={4} className={classes.usageDoughnutContainer} >
                                    <Box style={{ position: 'relative', margin: '0px' }}>
                                        <Doughnut data={userData[0].data} options={userData[0].options} />
                                            <Box
                                                className={classes.middleCountBox}
                                            >
                                                <IconButton className={classes.middleIcon}>
                                                    {userData[0].middleIcon}
                                                </IconButton>
                                                <Typography className={classes.middleTitle}>
                                                    {userData[0].middleLabel}
                                                </Typography>
                                            </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8} style={{display:'flex', flexDirection:'column'}}>
                                    <Box className={classes.deviceBox} style={{borderRadius: '16px', textAlign:'center', height:'80px'}}>
                                        <Grid item xs={12}>
                                            <Typography>{userData[0].boxLabel}</Typography>
                                            <Typography variant="h4">{userData[0].boxCount}</Typography>                                                         
                                        </Grid>
                                    </Box>
                                    <Grid item xs={12} style={{marginTop: '15px'}}>
                                        <Grid container spacing={2}>
                                            {userData[0].indicatorData.map((item, index) => (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    key={index}
                                                    className={classes.subDeviceDataContainer}
                                                    style={{justifyContent: index === 1 ? 'flex-end' : 'flex-start'}}
                                                >
                                                    <Box
                                                        className={classes.subDeviceBoxContainer}
                                                        style={{backgroundColor: item.color}}
                                                    />
                                                    <Box className={classes.doughnutIndicatorBox}>
                                                        <Typography className={classes.indicatorLabel}>{item.label}</Typography>
                                                        <Typography className={classes.indicatorCount}>{item.count} / {item.percentage}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.subFlexContainer} onClick={() => setSelectedCard("device")} style={{border: selectedCard === "device" ? '1px solid #005B96' : '1px solid #E2E5ED'}}>
                            <Grid item className={classes.flexRowContainer}>
                                <Grid item xs={12} md={4} className={classes.usageDoughnutContainer}>
                                    <Box style={{ position: 'relative', margin: '0px' }}>
                                        <Doughnut data={deviceData[0].data} options={userData[0].options} />
                                            <Box
                                                className={classes.middleCountBox}
                                            >
                                                <IconButton className={classes.middleIcon}>
                                                    {deviceData[0].middleIcon}
                                                </IconButton>
                                                <Typography className={classes.middleTitle}>
                                                    {deviceData[0].middleLabel}
                                                </Typography>
                                            </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8} style={{display:'flex', flexDirection:'column'}}>
                                    <Box className={classes.deviceBox} style={{borderRadius: '16px', textAlign:'center', height:'80px'}}>
                                        <Grid item xs={12}>
                                            <Typography>{deviceData[0].boxLabel}</Typography>
                                            <Typography variant="h4">{deviceData[0].boxCount}</Typography>                                                         
                                        </Grid>
                                    </Box>
                                    <Grid item xs={12} style={{marginTop: '15px'}}>
                                        <Grid container spacing={2}>
                                            {deviceData[0].indicatorData.map((item, index) => (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    key={index}
                                                    className={classes.subDeviceDataContainer}
                                                    style={{justifyContent: index === 1 ? 'flex-end' : 'flex-start'}}
                                                >
                                                    <Box
                                                        className={classes.subDeviceBoxContainer}
                                                        style={{backgroundColor: item.color}}
                                                    />
                                                    <Box className={classes.doughnutIndicatorBox}>
                                                        <Typography className={classes.indicatorLabel}>{item.label}</Typography>
                                                        <Typography className={classes.indicatorCount}>{item.count} / {item.percentage}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    {selectedCard === "user" ? 
                        <UserTable
                            filterUsers={filterUsers}
                            userRoles={userRoles}
                            search={search}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleDeleteOpen={handleDeleteOpen}
                            roleId={roleId}
                            existUserId={existUserId}
                            handleSearch={handleSearchUser}
                        /> 
                        : 
                        <DeviceTable 
                            filterDevices={filterDevices}
                            toggleFilter={toggleFilter}
                            handlePrefixSelect={handlePrefixSelect}
                            filterVisible={filterVisible}
                            setFilterVisible={setFilterVisible}
                            prefixes={prefixes}
                            selectedPrefix={selectedPrefix}
                            filterBatch={filterBatch}
                            setSelectedPrefix={setSelectedPrefix}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            searchTerm={searchTerm}
                            handleSearch={handleSearchDevice}
                            handleDeleteIcon={handleDeleteIcon}
                            isDelete={isDeleteDevice}
                        />
                    }
                </Grid>
            </Grid>
            <DeletePopup 
                open={selectedCard === "device" ? isDeleteDevice : isDeleteUser}
                title = {'CONFIRMATION'}
                content = {selectedCard === "device" ? `Are you sure you want delete ${selectedDeviceId} ?` : `Are you sure you want delete ${selectedUserName} ?`}
                onClose = {selectedCard === "device" ? onClose : handleDeleteClose}
                onDelete = {selectedCard === "device" ? onDelete : handleDelete}
            />
            <ToasterComponent 
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
            <Loader loading={loading} /> 
        </ThemeProvider>
    )
}