import {Component, OnInit} from '@angular/core';
import {AccountService} from '../account/account.service';
import {SideNavService} from '../layouts/side-menu/sidenav/sidenav.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ACCESS_TYPE, Device, FORM_TYPE, Kit, KitModel, SECTIONS} from '../models';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';
import {FormBuilder} from '@angular/forms';
import * as _ from 'underscore';

declare function toggleClass(id, classA);

@Component({
  selector: 'app-kitboard',
  templateUrl: './kitboard.component.html',
  styleUrls: ['./kitboard.component.css']
})
export class KitBoardComponent implements OnInit {
  error: null;
  kits: Kit[];
  devices: Device[];
  devicesAll: Device[];
  kitModels: KitModel[];
  devicesOfKit: Device[];
  p = 1;
  selectedKit: Kit;
  formType = FORM_TYPE.EDIT;
  showForm = false;
  searchTerm = '';
  isDataFetched = false;
  isCloning = false;
  validity = false;
  ACCESS_TYPE = ACCESS_TYPE;
  SECTION = SECTIONS;
  selectedValidity: string | null = null;
  filterBtnEnable: boolean = false;
  
  toggleFilter() {
    this.filterBtnEnable = !this.filterBtnEnable;
  }
  updateValidity(validity: string) {
    this.selectedValidity = validity === 'All' ? null : validity;
    this.filterBtnEnable = true;
  }

  constructor(private accountService: AccountService, private sideNavService: SideNavService,
              private spinner: NgxSpinnerService, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.getKits();
    this.getKitModels();
    this.getDevices();
  }

  public pageChange(event: number): void {
    this.p = event;
  }

  getKits() {
    this.accountService.getKits().subscribe(response => {
      this.kits = response.content;
      this.isDataFetched = true;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  addKit() {
    this.formType = FORM_TYPE.ADD;
    this.showForm = true;
  }

  editKit(kit: Kit) {
    this.formType = FORM_TYPE.EDIT;
    this.devices = Object.assign([], this.devicesAll);
    this.devicesOfKit = kit.devices.map(device => {
      return _.findWhere(this.devices, {id: device});
    });
    kit['kitModel'] = kit.model.id;
    kit['soilType'] = kit.metaData ? kit.metaData.soilType : '';
    this.selectedKit = kit;
    this.showForm = true;
  }

  hideForm() {
    this.isCloning = false;
    this.showForm = false;
  }


  getDevices() {
    this.accountService.getDevices().subscribe(response => {
      this.devices = response.content;
      this.devicesAll = Object.assign([], this.devices);
      this.devices.sort((a, b) => {
        if (!a.name || !b.name) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
    }, error => {
      this.spinner.hide();
    })
  }

  getKitModels() {
    this.spinner.show();
    this.accountService.getKitModels().subscribe(response => {
      this.kitModels = response.content;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  deleteKit() {
    this.spinner.show();
    this.accountService.deleteKit(this.selectedKit.id).subscribe(response => {
      this.toggleHideById('deleteModal');
      this.kits.splice(this.kits.indexOf(this.selectedKit), 1);
      this.spinner.hide();
      this.toaster.success('Kit Deleted Successfully', 'Success');
    }, error => {
      this.toggleHideById('deleteModal');
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  addDeviceToKit(index: any) {
    this.devicesOfKit.push(this.devices.splice(index, 1)[0]);
  }

  removeDeviceFromKit(index: number) {
    this.devices.push(this.devicesOfKit.splice(index, 1)[0]);
    this.devices.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  getDevicesNames(kit) {
    return (kit.devices);
  }

  getKitsFiltered() {
    if (!this.kits) {
      return [];
    }
    return this.kits.filter(kits => {
      const isValid = this.selectedValidity === 'Valid' ? this.kitValidity(kits.id) : this.selectedValidity === 'Invalid' ? !this.kitValidity(kits.id) : true;

      const matchSearch = JSON.stringify(Object.values(kits)).toLowerCase().includes(this.searchTerm.trim().toLowerCase());

      return isValid && matchSearch;
    });
  }

  toggleHideById(id) {
    document.getElementById(id).classList.toggle('hide');
  }

  kitValidity(id) {
    let currentDevices = []
    let deviceSensor = []
    let deviceActuator = []
    let sensorCount = 0
    let actuatorCount = 0
    let kitMatches = false
    let actuatorsMatch
    let sensorsMatch

    if (!this.kitModels) {
      return;
    }
    const kit = this.kits.find((res) => id === res.id);
    if (!kit) {
      console.log('Kit not found');
      return;
    }
    const kitModel = this.kitModels.find((kitModel) => kit.model.id === kitModel.id);
    if (!kitModel) {
      // Kit model not found.
      console.log('Kit model not found');
      return;
    }
    const kitModelActuators = kitModel.actuators;
    const kitModelSensors = kitModel.sensors;
    const kitModelActuatorLength = kitModelActuators.length
    const kitMOdelSensorLength = kitModelSensors.length
    if (this.devices) {
      kit.devices.forEach((device) => {
        const foundDevice = this.devices.find((dev) => device === dev.id);
        if (foundDevice) {
          currentDevices.push(foundDevice);
        }
      });
    }

    currentDevices.forEach((device) => {
      const deviceActuatorCodes = device.actuatorCodes;
      deviceActuatorCodes.forEach((actuator) => deviceActuator.push(actuator))
      const deviceSensorCodes = device.sensorCodes;
      deviceSensorCodes.forEach((device) => deviceSensor.push(device))
      sensorCount = sensorCount + deviceSensorCodes.length
      actuatorCount = actuatorCount + deviceActuatorCodes.length
    })

    actuatorsMatch = deviceActuator.every((actuator) => kitModelActuators.includes(actuator));
    sensorsMatch = deviceSensor.every((sensor) => kitModelSensors.includes(sensor));
    if (kitMOdelSensorLength == sensorCount && kitModelActuatorLength == actuatorCount && actuatorsMatch && sensorsMatch) {
      kitMatches = true

    }
    return kitMatches;
  }
}
