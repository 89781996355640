import { createTheme, ThemeProvider, Grid, Table, TableHead, TableRow, IconButton, TableBody, TableCell, DialogTitle, Dialog, DialogContent, Button, Box, Card } from '@material-ui/core';
import * as React from 'react';
import { BackToHomeComponent } from '../maintenance/test-automation/components/BackToHome';
import { ChatbotConfig, EditIcon, EditIconLarge, ExpandCollapseIcon, ExpandMoreIcon, PdfIcon, TrashIcon, ViewIcon } from 'src/components/Icons';
import { Typography } from 'antd';
import useStyles from '../maintenance/assets/styles';
import { Close } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { EditChatbotConfigComponent } from './edit-chatbot-config/EditChatbotConfig';
import { deleteChatbotConfig, fetchChatbotConfig, viewChatBotConfig } from '../maintenance/test-automation/components/apiHelper'
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});
const STEPS = [
    {
        text: "Project / Client Configurations",
        activate: true,
    },
    {
        text: "Knowledge Based Uploads",
        activate: false,
    },
    // {
    //     text: "API Configurations",
    //     activate: false,
    // },
];

interface ChatbotConfig {
    batchNumber: string;
    projectName: any;
}

interface ConfigDetails {
    batchNumber?: any;
    projectName?: any;
    fileNames?: any;
    files?: any
}

export const ChatbotConfigComponent = () => {

    const [chatbotConfigDetails, setChatbotConfigDetails] = useState<ChatbotConfig[]>([]);
    const [selectedConfigDetails, setSelectedConfigDetails] = useState<ConfigDetails>({})
    const [isViewPopup, setIsViewPopup] = useState(false)
    const [isDeletePopup, setIsDeletePopup] = useState(false)
    const [isEditPopup, setIsEditPopup] = useState(false)
    const [isDeleteConformation, setIsDeleteConformation] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isExpand, setIsExpand] = useState({ project: false, fileNames: false, apiData: false })
    const [steps, setSteps] = useState(STEPS)
    const [step, setStep] = useState(0)
    const [selectedBatchNumber,setSelectedBatchNumber] = useState('')
    const [selectedProject,setSelectedProject] = useState('')
    const [projectNames,setProjectNames] = useState([])
    const [errors, setErrors] = useState({
        projectName: null,
        fileError: null,
        KnowledgedBase: null
    });
    const [searchTerm,setSearchTerm] = useState('')
    const [filterVisible,setFilterVisible] = useState(false)
    const [selectedPrefix,setSelectedPrefix] = useState('')
    const [prefixes,setPrefixes] = useState([])
    const [filterConfigDetails,setFilterConfigDetails] = useState<ChatbotConfig[]>([])
    const [downloadLinks,setDownloadLink] = useState<any[]>([])

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false) 

    const handleViewPopup = (batch,projectName) => {
        setIsViewPopup(true)
        getChatConfigDetails(batch)
        setSelectedBatchNumber(batch)
        setSelectedProject(projectName)
    }

    const getChatConfigDetails = async (batch) =>{
        try {
            setLoading(true)
            const data = { batchNumber: batch }
            const response = await viewChatBotConfig(data)
            if (response) {
                if(response.files !== 'EMPTY'){
                    const configDetails = {
                        ...response,
                        files:transformPDFData(response.files)
                    }
                    const link = downloadFilesFromBase64(response.files)
                    setDownloadLink(link)
                    setSelectedConfigDetails(configDetails)
                }else{
                    setSelectedConfigDetails(response)
                }
                
            }else{
                setSelectedConfigDetails({})
            }

        } catch (error) {

        } finally {
            setLoading(false)
        }
    } 

    const handleEditPopup = (batch,projectName) => {
        setIsEditPopup(true)
        getChatConfigDetails(batch)
        setSelectedBatchNumber(batch)
        setSelectedProject(projectName)
    }

    const handleDownloadFile = (name) => {
        const link = downloadLinks.find(link => 
            link.fileName.toLowerCase() === name.toLowerCase()
        );
    
        if (link) {
            // Create an anchor element and set its href to the download link
            const a = document.createElement('a');
            a.href = link.url;
            a.download = link.fileName; // Set the download attribute to the file name
            document.body.appendChild(a);
            a.click(); // Trigger the download
            document.body.removeChild(a); // Clean up by removing the anchor tag
        } else {
            console.log(`No link found for file: ${name}`);
        }
    };
    

    

    const transformPDFData = (input) => {
        const output = [];
        Object.entries(input).forEach(file => {
            const name = file[0].split('/').pop(); // Extract the file name
            const size = (file[1].toString().length / 1024 / 1024).toFixed(2); // Calculate size in KB, rounded to 2 decimal places
            const base64Data = file[1].toString();
            
            // Decode base64 and create binary data
            const binaryData = atob(base64Data);
            const arrayBuffer = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                arrayBuffer[i] = binaryData.charCodeAt(i);
            }
            
            // Create a Blob and then a File object
            const blob = new Blob([arrayBuffer], { type: "application/pdf" });
            const fileObj = new File([blob], name, { type: "application/pdf", lastModified: Date.now() });
            
            // Push the transformed data into the output array
            output.push({
                "name": name,
                "size": size,
                "progress": 100,
                "file": fileObj
            });
        });
        return output;
    };

    const downloadFilesFromBase64 = (files) => {
        const downloadLinks = [];
        Object.entries(files).forEach((file) => {
            const fileName = file[0].split('/').pop();    
                const base64Data = file[1].toString(); // Extract the file name from the path
      
                // Decode the base64 string into binary data
                const binaryData = atob(base64Data);
                
                // Convert binary string to an ArrayBuffer
                const arrayBuffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    arrayBuffer[i] = binaryData.charCodeAt(i);
                }
                
                // Create a Blob from the ArrayBuffer
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                
                // Clean up by removing the link and revoking the object URL
                const url = URL.createObjectURL(blob);
                downloadLinks.push({ fileName, url });
            
        });
        return downloadLinks;
    };
    

    const handleDeletePopup = (batch) => {
        setIsDeletePopup(true)
        setSelectedBatchNumber(batch)
    }

    const cancelPopup = () => {
        fetchChatConfig()
        setIsViewPopup(false)
        setIsDeletePopup(false)
        setIsEditPopup(false)
        setIsDeleteConformation(false)
        setIsExpand({
            project: false,
            fileNames: false,
            apiData: false
        })
        setStep(0)
        setErrors(prevError => ({
            ...prevError,
            projectName:null,
            fileError:null,
            KnowledgedBase:null
        }))
    }

    useEffect(() => {
        fetchChatConfig()
    }, [])

    const fetchChatConfig = async () => {
        try {
            setLoading(true)
            const response = await fetchChatbotConfig();

            // Update the state with the fetched data
            if (response) {
                setChatbotConfigDetails(response);
                setFilterConfigDetails(response)
                const projectName = response.map(detail =>
                    detail.projectName
                )
                setProjectNames(projectName)
                const batchNumber = response.map(detail => detail.batchNumber)
                getPrefixes(batchNumber)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const getPrefixes = (batchNumbers) =>{
        let prefixes = batchNumbers.map(res =>{
            if(res.includes('_') || res.includes('-')){
                return res.split(/[_-]/)[0]
            }
        }).filter(Boolean)
        setPrefixes(prefixes)
    }

    const deleteChatbot = async () =>{
        try{
            setLoading(true)
            const response = await deleteChatbotConfig(selectedBatchNumber)
            if(response){
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Chatbot Configuration Removed Successfully")
            }
            
        }catch{

        }finally{
            setLoading(false)
            setIsDeletePopup(false)
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
            await fetchChatConfig()
        }
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)
    };

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const filterBatch = () =>{
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = chatbotConfigDetails.filter(config => {
            const matchesPrefix = selectedPrefix === 'All' || config.batchNumber.startsWith(selectedPrefix);
            const matchesSearch = config.batchNumber.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        });
        setFilterConfigDetails(filtered);
        setFilterVisible(false)
    }

    useEffect(() => {
        const filterBatchNumber = () => {
            const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : '';

            const filtered = chatbotConfigDetails.filter(config => {
                const matchesSearch = config.batchNumber.toLowerCase().includes(lowerCaseSearchTerm);
                const matchesPrefix = selectedPrefix === 'All' || selectedPrefix === '' || config.batchNumber.startsWith(selectedPrefix);
        
                // If no search term is provided and prefix is 'All', show all batches
                if (selectedPrefix === 'All' && !searchTerm) {
                    return true; // Keep all batches
                }
                return matchesPrefix && matchesSearch;
            });
            setFilterConfigDetails(filtered);
        };
        filterBatchNumber();
    }, [searchTerm, selectedPrefix]);

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ padding: '25px' }}>
                <BackToHomeComponent icon={<ChatbotConfig />} title={'Knowledge Based Uploads'} />
                <Grid container style={{marginBottom:'15px'}}>
                    <Grid item xs={6} md={3} style={{marginRight:'10px'}}>
                        <SearchBar onChange={handleSearch}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FilterBatchNumber 
                            toggleFilter={toggleFilter} 
                            handlePrefixSelect={handlePrefixSelect} 
                            filterVisible={filterVisible} 
                            setFilterVisible ={setFilterVisible}
                            prefixes = {prefixes}
                            selectedPrefix={selectedPrefix}
                            filterBatch={filterBatch}
                            setSelectedPrefix = {setSelectedPrefix}
                        />
                    </Grid>                
                </Grid>
                <Grid container >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ width: '30%' }}>Batch Name</TableCell>
                                <TableCell align="left" style={{ width: '50%' }}>Project Name</TableCell>
                                <TableCell style={{ width: '20%' }} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterConfigDetails.length > 0 ? (
                                filterConfigDetails.map((chat, index) => (
                                    <TableRow key={index} style={{ backgroundColor: index % 2 == 1 ? '#ECEFF1' : '#FFFFFF' }}>
                                        <TableCell align="left">{chat.batchNumber}</TableCell>
                                        <TableCell align="left">{chat.projectName}</TableCell>
                                        <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                            <IconButton size={'small'} onClick={() => handleViewPopup(chat.batchNumber,chat.projectName)}>
                                                <ViewIcon />
                                            </IconButton>
                                            <IconButton size={'small'} onClick={() => handleEditPopup(chat.batchNumber,chat.projectName)}>
                                                <EditIconLarge />
                                            </IconButton>
                                            <IconButton size={'small'} onClick={() => handleDeletePopup(chat.batchNumber)}>
                                                <TrashIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        No Chatbot Configuration Available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
            <ViewChatbotConfig 
                open={isViewPopup} 
                onClose={cancelPopup} 
                chatConfig={selectedConfigDetails} 
                isExpand={isExpand} 
                setIsExpand={setIsExpand}
                selectedBatchNumber = {selectedBatchNumber}
                selectedProject = {selectedProject}
                downloadFile = {handleDownloadFile}
            />
            <DeletePopup 
                open={isDeletePopup} 
                onClose={cancelPopup} 
                name={selectedBatchNumber}
                onDelete={deleteChatbot} 
            />
            <EditChatbotConfigComponent 
                open={isEditPopup} 
                onClose={cancelPopup} 
                chatConfig={selectedConfigDetails} 
                step = {step}
                setStep = {setStep}
                steps={steps}
                STEPS={STEPS}
                selectedBatchNumber = {selectedBatchNumber}
                selectedProject = {selectedProject}
                projectNames = {projectNames}
                setErrors={setErrors}
                errors={errors}
                setSteps={setSteps}
                setLoading = {setLoading}
                setIsToasterOpen = {setIsToasterOpen}
                setToasterMessage = {setToasterMessage}
                setReqSuccess = {setReqSuccess}
            />
            <Loader loading={loading} />
            <ToasterComponent 
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
        </ThemeProvider>
    )
}

export const ViewChatbotConfig = ({ open, onClose, chatConfig, isExpand, setIsExpand ,selectedBatchNumber,selectedProject,downloadFile}) => {
    const classes = useStyles()
    const dialogContentRef = useRef(null);
    const dialogTitleRef = useRef(null)

    const handleExpand = (key) => {
        setIsExpand((prevState) => ({
            project: key === 'project' ? !prevState.project : false,
            fileNames: key === 'files' && key !== 'download' ? !prevState.fileNames : false,
            apiData: key === 'apiData' ? !prevState.apiData : false,
        }));
    }

    const handleClickOutside = (event) => {
        if (dialogContentRef.current && !dialogContentRef.current.contains(event.target) && dialogTitleRef.current && !dialogTitleRef.current.contains(event.target) ) {
          setIsExpand({
            project: false,
            files: false,
            apiData: false,
          });
        }
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Dialog open={open} PaperProps={{ style: { minWidth: '750px' } }}>
            <DialogTitle ref={dialogTitleRef}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.typo} style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        {'View ChatBot config'.toUpperCase()}
                    </Typography>
                    <IconButton onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent ref={dialogContentRef} style={{ backgroundColor: '#F9F9F9', margin: '0px' }}>
                {chatConfig && (
                    <Grid container justifyContent='space-around'>
                        <Grid container style={{ backgroundColor: 'white', width: '100%', marginBottom: '15px' }} onClick={() =>handleExpand('project')}>
                            <Grid item style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '16px'
                            }}>
                                <Typography className={classes.typo}>
                                    Project / Client Configurations
                                </Typography>
                                <IconButton size={'small'}>
                                    {isExpand.project ?
                                        <ExpandCollapseIcon />
                                        :
                                        <ExpandMoreIcon />
                                    }
                                </IconButton>
                            </Grid>
                            {isExpand.project && (
                                <Grid container style={{ backgroundColor: "white", padding: '16px' }}>
                                    <Grid item xs={6} md={6}>
                                        <Typography style={{ color: '#707070', marginBottom: '10px' }}>Batch Number</Typography>
                                        <Typography>{selectedBatchNumber}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography style={{ color: '#707070', marginBottom: '10px' }}>Project Name</Typography>
                                        <Typography>{selectedProject}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container style={{ backgroundColor: 'white', width: '100%', marginBottom: '15px' }} onClick={() => handleExpand('files')}>
                            <Grid item style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '16px',
                                marginBottom: '15px'
                            }}>
                                <Typography className={classes.typo}>
                                    Knowledge Based Uploads
                                </Typography>
                                <IconButton size={'small'}>
                                    {isExpand.files ?
                                        <ExpandCollapseIcon />
                                        :
                                        <ExpandMoreIcon />
                                    }
                                </IconButton>
                            </Grid>
                            {isExpand.fileNames && chatConfig.files != 'EMPTY' && chatConfig.fileNames.length > 0  ?
                                <Grid container style={{ backgroundColor: "white", display: 'flex', marginBottom: '15px', padding: '16px' }} >
                                    {chatConfig.fileNames.map((file, index) => (
                                        <Grid item key={index} style={{ display: 'flex', marginRight: '10px' }} >
                                            <FileCard name={file} download={downloadFile} handleExpand={handleExpand}/>
                                        </Grid>
                                    ))}
                                </Grid>
                                : isExpand.files && (chatConfig.files || chatConfig.files == 'EMPTY') &&
                                <Typography style={{display:'flex',justifyContent:'center' , padding:'10px',width:'100%'}}>
                                    There is no files available.
                                </Typography>
                            }
                        </Grid>
                        {/* <Grid container style={{ backgroundColor: 'white', width: '100%', marginBottom: '15px' }} onClick={() => handleExpand('apiData')}>
                            <Grid item style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '16px',
                                marginBottom: '15px'
                            }}>
                                <Typography className={classes.typo}>
                                    API Configurations
                                </Typography>
                                <IconButton size={'small'}>
                                    {isExpand.apiData ?
                                        <ExpandCollapseIcon />
                                        :
                                        <ExpandMoreIcon />
                                    }
                                </IconButton>
                            </Grid>
                            {isExpand.apiData && (
                                <Grid container style={{ backgroundColor: "white", padding: '16px' }}>
                                    <Grid item xs={6} md={6}>
                                        <Typography style={{ color: '#707070', marginBottom: '10px' }}>Sensor Data API</Typography>
                                        <Typography>https://url.com/sksdlsd/dsjd/sdsd</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography style={{ color: '#707070', marginBottom: '10px' }}>Meta Data API</Typography>
                                        <Typography>https://url.com/sksdlsd/dsjd/sdsd</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid> */}
                    </Grid>
                )}
                
            </DialogContent>
        </Dialog>
    )
}

export const FileCard = ({ name,download,handleExpand }) => {
    return (
        <Card style={{ boxShadow: 'none',marginBottom : '10px' }} onClick={() => {handleExpand('download') ,download(name)}}>
            <Grid container style={{ display: 'flex', alignItems: 'center', padding: '4px 16px 4px 12px', border: '1px solid #EAEAEA', borderRadius: '9px' }}>
                <Grid item style={{ marginRight: '10px' }}>
                    <PdfIcon />
                </Grid>
                <Grid item>
                    <Typography>{name}</Typography>
                    {/* <Typography>{`${size} MB`}</Typography> */}
                </Grid>

            </Grid>
        </Card>
    )
}

export const DeletePopup = ({ open, onClose, name,onDelete }) => {
    return (
        <Dialog open={open} >
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014' }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography>CONFIRMATION</Typography>
                    <IconButton onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Typography>{`Are you sure you want to permanently remove this chatbot configuration from the batch ${name}?`}</Typography>
                    <Grid container justifyContent='flex-end' style={{ margin: '15px 0px' }}>
                        <Button variant='outlined' style={{ marginRight: '15px', color: '#7F7C7C' }} onClick={onClose}>No</Button>
                        <Button variant='contained' style={{ backgroundColor: 'red', color: 'white' }} onClick={onDelete}> Yes</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}