import { Box, createTheme, Grid, IconButton, InputAdornment, ListItem, Paper, TextField, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import {Link, useHistory} from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import { Arrow, AttachIcon, BackIcon, BatchesIcon, ChatBotIcon, ChatCardIcon, ChatHistoryIcon, NonSuccessCriteriaIcon, SentIcon } from 'src/components/Icons';
import useStyles from '../maintenance/assets/styles';
import { answerChat } from '../maintenance/test-automation/components/apiHelper';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

export const ChatBotComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();
    const history = useHistory();
    const [messages, setMessages] = useState([]);

    const handleBack = () => {
        history.push('/ChatHistory');
    }
    
    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ padding: '25px' }}>
                <Grid container style={{ display: 'flex', margin: '50px 0px', alignItems: 'center', backgroundColor: 'white', padding: '15px', width: 'fit-content' }}>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <NonSuccessCriteriaIcon color={"#324054"}/>
                        <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                            <Typography style={{ color: '#858DAA' }}>Setup Device Pulse</Typography>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <ChatHistoryIcon color="#324054"/>
                        <Link to={'/ChatHistory'} style={{ color: '#858DAA', textDecoration: 'none' }}>
                            <Typography style={{ marginLeft: '10px' }}>Chat History</Typography>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ color: '#2A7BEC', display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ marginLeft: '10px' }}>Device Pulse ChatBot</Typography>
                    </Grid>
                </Grid>

                <Grid container direction="column" style={{ padding: '20px', flexGrow: 1, gap:'5px' }}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '5px', padding:'5px', backgroundColor: '#FFFFFF' }}>
                        <Grid item style={{ display: 'flex' }}>
                            <Grid item onClick={handleBack} style={{ padding: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <BackIcon />
                            </Grid>
                            <Grid item style={{ backgroundColor: '#F2F8FF', borderRadius: '50%', padding: '10px', display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                                <ChatBotIcon color='#2A7BEC' size="32" />
                            </Grid>
                            <Typography variant="h6" className={classes.typo} style={{ alignContent: 'center', fontWeight: 'bold' }}>Device Pulse ChatBot</Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" style={{ flexGrow: 1, overflowY: "auto", padding: '10px 0', maxHeight: '60vh', backgroundColor: '#FFFFFF' }}>
                        {messages.map((msg, index) => (
                            <ListItem key={index} style={{ justifyContent: msg.sender === "user" ? "flex-end" : "flex-start" }}>
                                {msg.sender !== "user" && (
                                    <Grid item style={{ backgroundColor: '#F2F8FF', borderRadius: '50%', padding: '10px', display: 'flex', marginRight: '10px', alignItems: 'flex-start' }}>
                                        <ChatBotIcon color='#2A7BEC' size="20" />
                                    </Grid>
                                )}
                                <Grid container direction="column" style={{ justifyContent: msg.sender === "user" ? "flex-end" : "flex-start" }}>
                                    <Paper
                                        style={{
                                            padding: "10px",
                                            backgroundColor: msg.sender === "user" ? "#1976d2" : "#f5f5f5",
                                            color: msg.sender === "user" ? "#fff" : "#000",
                                            wordWrap: "break-word",
                                            maxWidth: '80%',
                                            borderRadius: msg.sender !== "user" ? '0px 15px 15px 15px' : '15px 0px 15px 15px',
                                            fontSize: '12px'
                                        }}
                                        className={classes.typo}
                                    >
                                        {msg.loading ? <Skeleton variant="text" width={100} height={20} /> : msg.text}
                                    </Paper>
                                </Grid>
                            </ListItem>
                        ))}
                    </Grid>

                    <Grid container alignItems="center" style={{ boxShadow: "0px -4px 16px 0px #00000014", padding: "10px", background: "#FFFFFF" }}>
                        <TextField
                            fullWidth
                            placeholder="Type your message here..."
                            variant="filled"
                            className={classes.chatBotPageInput}
                            InputProps={{
                                classes: {
                                    root: classes.chatBotPageInput,
                                    input: classes.chatBotPageInput
                                },
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <AttachIcon />
                                        </IconButton>
                                        <IconButton>
                                            <SentIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    // handle send message logic
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};


export const EncodedImage = ({ encodedString }) => {
    return (
        <div>
            <img src={`data:image/jpeg;base64,${encodedString}`}
                alt="Encoded"
                style={{ width: '400px', height: '400px' }}
            />
        </div>
    );
};