import {AbstractControl} from '@angular/forms';

export function timeDurationToReadableForm(time) {
  // let startDate = new Date(startTime);
  // let endDate = new Date(endTime);
  let diffMs = (time * 60 * 1000); // milliseconds between now & Christmas
  let diffDays = Math.floor(diffMs / 86400000); // days
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  if (diffDays === 0) {
    if (diffHrs === 0) {
      return (diffMins + ' minutes');
    }
    return (diffHrs + ' hours, ' + diffMins + ' minutes');
  }
  return (diffDays + ' days, ' + diffHrs + ' hours, ' + diffMins + ' minutes');
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function newDateFromGiven(date) {
  const dashSplit = date.toString().split('-')
  const spaceSplit = dashSplit[2].toString().split(' ')
  const colonSplit = spaceSplit[1].toString().split(':')
  return new Date(dashSplit[0], dashSplit[1] - 1, spaceSplit[0], colonSplit[0], colonSplit[1], colonSplit[2])
}

export function timeSince(date) {
  if (!date) {
    return;
  }
  let sinceDate = newDateFromGiven(date).getTime()
  let seconds = Math.floor((new Date().getTime() - sinceDate) / 1000);
  let sinceTime = null
  let min, hours, days
  if (seconds < 60) {
    return sinceTime = "few seconds ago"
  } else if (seconds < 3600) {
    min = Math.floor(seconds / 60)
    return sinceTime = min + " minutes ago"
  } else if (seconds < 86400) {
    hours = Math.floor(seconds / 3600)
    return sinceTime = hours + " hours ago"
  } else if (seconds > 86400) {
    days = Math.floor(seconds / 86400)
    return sinceTime = days + " days ago"
  } else {
    return date
  }
}

export function convert24Hr(time) {
  if (time.includes('AM') || time.includes('PM')) {
    const convertTime = time.split(/:|\s/)

    var hours = parseInt(convertTime[0], 10)
    var min = parseInt(convertTime[1], 10)
    var sec = parseInt(convertTime[2], 10)
    var period = convertTime[3].toUpperCase()

    if (period === 'PM' && hours < 12) {
      hours += 12;
      console.log(hours)
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    var time24hr = (hours < 10 ? '0' : '') + hours + ':' + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
    return time24hr;
  }
}

export function numericValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const isValid = /^\d+$/.test(control.value);
  return isValid ? null : {'notNumeric': true};
}

export function isValidBatchNumber(control: AbstractControl): { [key: string]: boolean } | null{
  const hasLetter = /[a-zA-Z]/.test(control.value); // Check for at least one letter
  const hasNumber = /\d/.test(control.value);       // Check for at least one digit
  const isAlphanumeric = /^[a-zA-Z0-9_-]+$/.test(control.value); // Only alphanumeric characters
  const hasUnderscoreOrHyphen = /[_-]/.test(control.value);
  const isValid = hasLetter && hasNumber && isAlphanumeric && hasUnderscoreOrHyphen;
  return isValid ?  null : {'notAlphaNumeric': true};
};

export function numberToWords(num) {
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  if (num < 10) return ones[num];
  if (num < 20) return teens[num - 10];
  if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 === 0 ? '' : ' ' + ones[num % 10]);
}

export const formatSuccessCriteria = (criteria: string): string => {
  return criteria
      .replace(/_/g, ' ')
      .split(' ') 
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '); 
};
