import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { CloseIconLargeTwo } from 'src/components/Icons';

export const RootCauseDetailsPopup = ({
  open,
  close,
  rootCauseData
}) => {

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={"md"}
      style={{ width: "650px", height: "600px" }}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: 'inherit'
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
          opacity: '1',
        },
      }}
    >
      <Grid container
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: "1",
          boxShadow: "0px 4px 30px #00000008",
        }}
      >
        <Grid item xs={10}>
          <DialogTitle>
            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Root Cause Details
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <IconButton onClick={close}>
            <CloseIconLargeTwo />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <DialogContent
        style={{
          margin: "0px",
          padding: "24px 12px 24px 30px",
          fontSize: '13px'
        }}>
        <Grid container spacing={3} style={{ width: "500px" }}>
          {rootCauseData && rootCauseData.length > 0 ? (
            rootCauseData.map((item, index) => (
              <Grid container key={index} spacing={2} alignItems="center" style={{ borderBottom: '1px solid #e0e0e0', padding: '8px 0' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: '13px' }}>
                    {item.time}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: '13px' }}>
                    {item.predictions}
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography style={{ fontSize: '13px' }}>
              No data available
            </Typography>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
