import React, { useEffect } from "react";
import { AuthService } from "src/app/authentication/auth.service";
import { environment } from "src/environments/environment";
import axios from "src/app/authentication/authInterceptor";
import local from "src/app/setup-senzmatica/define-product-type-form/DefineProductTypeComponent";
import Cookies from "js-cookie";

export const technicianRole = "30";
export const engineerRole = "20";

export const getUserId = () => {
  const userId = Cookies.get("USER_ID");
  if (!userId) {
    return;
  }
  return userId;
};

export const getPlanId = () => {
  const planId = Cookies.get("PLANID");
  if (!planId) {
    return;
  }
  return planId;
};

export const getUserRole = () => {
  const role = Cookies.get("ROLE");
  if (!role) {
    return;
  }
  return role;
};

export const isEngineer = () => {
  return getUserRole() === engineerRole;
};

export const isTechnician = () => {
  return getUserRole() === technicianRole;
};

const formatAndEncodeDate = (dateString) => {
  const formattedDate = new Date(dateString);

  const padZero = (num) => num.toString().padStart(2, '0');

  const year = formattedDate.getFullYear();
  const month = padZero(formattedDate.getMonth() + 1); 
  const day = padZero(formattedDate.getDate());
  const hours = padZero(formattedDate.getHours());
  const minutes = padZero(formattedDate.getMinutes());
  const seconds = padZero(formattedDate.getSeconds());

  const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return encodeURIComponent(dateTimeString);
};

// Fetch batch numbers from the API endpoint
export const fetchBatchNumbers = async () => {
  return axios.get(`${environment.host}/core/user/${Cookies.get("USER_ID")}/deviceTest/batchNumbers`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
    },
  })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching batch numbers:', err);
      return;
    });
};

// Get Parameters from batch number API endpoint
export const fetchParameter = async (batchNo) => {
  return axios.get(`${environment.host}/core/user/${getUserId()}/sensorsCodesOfBatch/${batchNo}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
    },
  })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching metadata:', err);
      return;
    });
}

// Fetch MetaData from the API endpoint
export const fetchMetaData = async () => {
  return axios.get(`${environment.host}/core/meta-data`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
    },
  })
    .then((response) => {
      // return response.data.content.sensors.sort((a, b) => (a.name < b.name ? -1 : 1));
      return response.data.content.sensors;
    })
    .catch((err) => {
      console.error('Error fetching metadata:', err);
      return;
    });
}

export const matchSensorNames = async (batchNo) => {
  try {
    const sensorCodes = await fetchParameter(batchNo);
    const sensorsMetaData = await fetchMetaData();

    const sensorMap = sensorsMetaData.reduce((acc, sensor) => {
      acc[sensor.code] = sensor.name;
      return acc;
    }, {});

    // Mapping sensor codes to their corresponding names
    const sensorNames = sensorCodes.map((code) => ({
      code,
      name: sensorMap[code],
    }));

    return sensorNames;

  } catch (err) {
    console.error("Error matching sensor names:", err);
    return [];
  }
};

// Submit test case data to the API endpoint
export const defineTestCase = async (
  testCase,
  setLoading,
  setIsToasterOpen,
  setToasterMessage,
  setReqSuccess,
  history,
  addAnotherTestCase
) => {
  setLoading(true);
  await axios.post(`${environment.host}/core/user/${getUserId()}/deviceTest/defineTestCases`, testCase, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
    },
  })
    .then((response) => {
      setIsToasterOpen(true);
      setToasterMessage("Test Case Defined Successfully");
      setReqSuccess(true);
      setTimeout(() => {
        setIsToasterOpen(false);
        if (addAnotherTestCase) {
          window.location.reload();
        } else {
          history.push(`/TestAutomation`);
        }
      }, 1000);
    })
    .catch((error) => {
      console.error('Error submitting test case:', error);
      setLoading(false);
      setIsToasterOpen(true);
      setToasterMessage(error.response.data.message);
      setReqSuccess(false);
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 4000);
      throw new Error(error.response.data.message);
    })
    .finally(() => {
      setLoading(false);
    });
};

// Get Main Test cases from the API endpoint
export const fetchMainTestCases = async (batchNo) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
        },
      }
    );
    const allData = response.data;
    const data = allData.content.filter((d) => d.default === true);

    const mainTestTitles = data.map(testCase => testCase.mainTestTitle);
    const uniqueDevices = [...new Set(data.flatMap(testCase => testCase.devices))];

    return {
      mainTestTitles,
      content: data,
      uniqueDevices
    };
  }
  catch (err) {
    console.error('Error fetching main test cases:', err);
  }
};

// Submit data to start testing API endpoint
export const startTesting = async (
  payload,
  setLoading,
  setIsToasterOpen,
  setToasterMessage,
  setReqSuccess,
  selectedTestingMethod,
  history
) => {
  setLoading(true);

  const endpoint = selectedTestingMethod === "Continuous Testing"
    ? `${environment.host}/core/user/${getUserId()}/deviceTest/startContinuousTesting?hours=1`
    : `${environment.host}/core/user/${getUserId()}/deviceTest/startTesting`;

  const response = await axios.post(endpoint, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
    },
  })
    .then((response) => {
      setIsToasterOpen(true);
      setToasterMessage("Test scheduled successfully");
      setReqSuccess(true);
      setTimeout(() => {
        setIsToasterOpen(false);
        history.push(`/TestAutomation`);
      }, 2000);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error submitting test case:', error);
      setLoading(false);
      setIsToasterOpen(true);
      setToasterMessage(error.response.data.message);
      setReqSuccess(false);
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 4000);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    });
};

// Get Test Summary Data from the API endpoint
export const getDeviceTestSummery = async (
  batchNo,
  mainTitle,
  testCaseType,
  continuousMainTestId,
  timeShedule
) => {
  try {
    const endpoint = testCaseType === "Continuous Testing"
      ? `${environment.host}/core/user/${getUserId()}/continuousTestSummary/${continuousMainTestId}/${timeShedule}`
      : `${environment.host}/core/user/${getUserId()}/deviceTest/testReportTable/${batchNo}?testCaseTitle=${mainTitle}`;

    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      }
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.data.content;
  } catch (err) {
    console.error('Error fetching device test summary:', err);
  }
};

// Get Continuous Test Summary Data from the API endpoint
export const getContinuousTestSummery = async (
  startDate, 
  endDate, 
  continuousMainTestId
) => {
  const encodedStartDate = formatAndEncodeDate(startDate);
  const encodedEndDate = formatAndEncodeDate(endDate);

  if (!encodedStartDate || !encodedEndDate) {
    throw new Error("Invalid date range");
  }

  try {
    const response = await axios.get(
      `${environment.host}/core/user/${getUserId()}/continuousTestSummary/${continuousMainTestId}/?from=${encodedStartDate}&to=${encodedEndDate}`, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        }
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.data.content;
  } catch (err) {
    console.error('Error fetching continuous test summary:', err);
  }
};

// Get Root Cause Details API endpoint
export const getRootCauseDetails = async (id, startTime, endTime, mainTestId) => {
  const encodedStartDate = formatAndEncodeDate(startTime);
  const encodedEndDate = formatAndEncodeDate(endTime);

  if (!encodedStartDate || !encodedEndDate) {
    throw new Error("Invalid date range");
  }

  try {
    const response = await axios.get(
      `${environment.host}/core/user/${getUserId()}/rootCauseDetailsByDeviceId?mainTestId=${mainTestId}&deviceId=${id}&from=${encodedStartDate}&to=${encodedEndDate}`,
      // `${environment.host}/core/rootCauseDetailsByDeviceId?mainTestId=6703c9de31c92c71344cbf70&deviceId=NUC_G6JY117002SU_0&from=2024-10-01%2000%3A00%3A00&to=2024-10-31%2023%3A59%3A00`,  
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        }
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const formattedData = response.data.content.map(item => ({
      time: item.time,
      predictions: item.rootCauseResults.map(result => result.predictions).join(", ")
    }));
    return formattedData;
  } catch (err) {
    console.error('Error fetching root cause details:', err);
    throw err;
  }
};

// Get root cause configurations API endpoint
export const getRootCauseConfiguration = async (batch) => {
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/rootCauseConfig/${batch}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      },
    })
    .then((response) => {
      const data = response.data.content;
      const responseTime = data.rootCauseNotificationDurationInMinutes;
      const responsePeriod = data.period;
      return {
        content: data,
        responseTime,
        responsePeriod
      };
    })
    .catch((err) => {
      console.error("Error fetching schedule times:", err);
      return; 
    });
};




// Get Ongoing Continuous Tests API endpoint
export const getOnGoingContinuousTests = async () => {
  try {
    const response = await axios.get(
      `${environment.host}/core/user/${getUserId()}/ongoingContinuousTests`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
        },
      }
    );
    const allData = response.data;
    const data = allData.content || []; 
    const mainTestTitles = data.map(testCase => testCase.mainTestTitle);

    return {
      mainTestTitles,
      content: data
    };
  } catch (err) {
    console.error('Error fetching main test cases:', err);
    return { mainTestTitles: [], content: [] }; 
  }
};


// Get Devices from Batch API endpoint
export const fetchDevices = async (batchNo) => {
  try {
    const response = await axios.get(`${environment.host}/core/device`, {
      params: { batchNumber: batchNo },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`Error fetching devices: ${response.statusText}`);
    }
    const data = response.data;
    const deviceIds = data.content.map(device => device.id);
    return deviceIds;

  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while fetching devices");
  }
};


// Get schedule times for the main test case API endpoint
export const getScheduleTimes = async (mainTestCaseId) => {
  return axios
    .get(`${environment.host}/core/getScheduleTimes/${mainTestCaseId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching schedule times:', err);
      return;
    });
};

// Get Continuous Test from the API endpoint
export const getContinuousTestCase = async (batchNo, mainName) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
        },
      }
    );

    const allData = response.data;
    const continuousTest = allData.content.find(
      (d) => d.default === false &&
        d.testCaseType &&
        d.mainTestTitle === mainName &&
        d.testCaseType.toUpperCase() === "CONTINUOUS"
    );
    return continuousTest ? continuousTest.id : null;
  } catch (err) {
    console.error('Error fetching continuous test cases:', err);
  }
};

// Get schedule times for the main test case API endpoint
export const getSuccessCriteria = async (mainTestId) => {
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/mainTestCase/${mainTestId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching schedule times:', err);
      return;
    });
};

// Get test automation sensors reading
export const getSensorReading = (data) => {
  return axios
    .post(`${environment.host}/core/user/${getUserId()}/testAutomation/sensorReadings`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      console.error('Error fetching schedule times:', err);
      return;
    });
}

export const rootCauseConfiguration = (data) => {
  let finalPayload = {
    "batchNumber": data.batchNumber,
    "modelDetails": data.modelDetails,
    "mainTestId": data.mainTestId,
    "inferenceCodes": data.inferenceCodes,
    "inferenceNoOfData": data.inferenceNoOfData
}
  return axios
    .post(`${environment.host}/core/user/${getUserId()}/rootCauseConfigure`, finalPayload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        return Promise.reject(new Error(err.response.data.message || 'An error occurred'));
      } else {
        return Promise.reject(new Error(err.message || 'Network error occurred'));
      }
    }
  );
}


export const saveJson = (data) => {
  return axios
    .post(`${environment.chatbotHost}/save_json/`, data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.Notification;
    })
    .catch((err) => {
      console.error('Error fetching save json:', err);
      return;
    }
  );
}

export const checkDbStatus = (selectedBatch) => {
  return axios
    .post(`${environment.chatbotHost}/check_batch_status/`, {
      "batchNumber": selectedBatch,
      referenceId : Cookies.get('ACCOUNT')
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error({'message':err.response.data.detail});
      return {'message':err.response.data.detail};
    }
  );
}

export const checkDbStatusByProcess = () => {
  return axios
    .post(`${environment.chatbotHost}/check_vdb_status/`, {
      referenceId : Cookies.get('ACCOUNT')
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error('Error fetching save json:', err);
      return;
    }
  );
}

export const answerChat = (questions) =>{
  return axios 
    .post(`${environment.chatbotHost}/answer_query/`,questions,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      const result = response.data
      return result;
    }).catch((err) => {
      console.error('Error fetching save json:', err);
      return;
    }
  )
} 

export const getAllNotification = (batch) =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/rootCause/${batch}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    }).catch((err) =>{
      console.log('Error fetching notification :',err)
      return;
    })
}

export const analyticsConfiguration = (data) => {
  return axios
    .post(`${environment.chatbotHost}/get_analysis_configs/`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data
    }).catch((err) =>{
      throw err.response.data;
    })
}

export const apiConfiguration = (api) =>{
  return axios
    .post(`${environment.chatbotHost}/get_api_configs/`,api,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data
    }).catch((err) =>{
      console.log('Error fetching api:',err)
    })
}

export const uploadFile = (files) =>{
  return axios
    .post(`${environment.chatbotHost}/upload_file`,files,{
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + Cookies.get("ACCESS_TOKEN"),
      },
    })
    .then((response)=>{
      return response.data.info
    }).catch((error) =>{
      console.log("Error uploading files",error)
    })
}
export const getAvailableModels = (body) =>{
  const userId=Cookies.get("USER_ID");
  const referenceId = Cookies.get("USER_ID")
  return axios 
    .get(`${environment.rootCauseHost}/model_info_dashboard?userid=${userId}`,{
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      const result = response.data

      let models = []
      let descriptions =[]
      let sensorCodes = {}
      const allUsers = Object.keys(result.model_info_dashboard);
      allUsers.map((u,i)=>{
        const projects = Object.keys( result.model_info_dashboard[u])
        projects.map((p,j)=>{
          models.push(result.model_info_dashboard[u][p].model_name)
          descriptions.push(result.model_info_dashboard[u][p].model_info_for_inference.project_description)
          if(result.model_info_dashboard[u][p].model_info_for_inference.all_mapping!==null
          ){
            sensorCodes[result.model_info_dashboard[u][p].model_name]=Object.keys(result.model_info_dashboard[u][p].model_info_for_inference.all_mapping)
          }
        })
      })

      let newObj = {"modelDetails":models, "descripstions":descriptions,'codes':sensorCodes}
      

      return newObj;
    }).catch((err) => {
      console.error('Error fetching save json:', err);
      return;
    }
  )
} 
export const getRecentRecord = () =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/getMostRecentReport`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    }).catch((err) =>{
      console.log('Error fetching notification :',err)
      return;
    })
}

export const removeDeviceFromBatch = (batchNo,deviceId) =>{
  return axios
    .delete(`${environment.host}/core/user/${getUserId()}/batch/${batchNo}/${deviceId}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      console.log("Error remove device from batch")
    })
}

export const removeBatch = (batchNo) =>{
  return axios
    .delete(`${environment.host}/core/user/${getUserId()}/batch/${batchNo}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      console.log("Error remove batch")
    })
}

export const fetchChatbotConfig = async (data) =>{
  return axios
    .post(`${environment.chatbotHost}/get_full_analysis_configs/`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      const details = response.data.details;
      // Transforming the response into an array of objects
      const batchArray = Object.keys(details).map(batchNumber => ({
        batchNumber, // Use the key as batchNumber
        projectName: details[batchNumber].analysis_configs // Extract the analysis_configs value
      }));
      return batchArray;
    }).catch((err) =>{
      // console.log('Error fetching api:',err)
    })
}

export const viewChatBotConfig = (data) =>{
  return axios
    .post(`${environment.chatbotHost}/get_batch_details/`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      const details = response.data
      let fileNames = []
      if(details.pdf_files != "EMPTY"){
        fileNames = Object.keys(details.pdf_files).map(name => {
          const fileName = name.split('/').pop();
          return fileName;
        });
      }else{
        fileNames = []
      }
      
      const chatConfigDetails = {
        batchNumber : details.batchNumber,
        projectName : details.details.analysis_configs,
        fileNames : fileNames,
        files:details.pdf_files
      }
      return chatConfigDetails
    }).catch((err) =>{
      // console.log('Error fetching api:',err)
    })
}


export const deleteChatbotConfig = async (batchNumber) =>{
  return axios
    .delete(`${environment.chatbotHost}/delete_configs/`,{
      headers: {
        'Content-Type': 'application/json', // Set the Content-Type header
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      },
      data: {
        batchNumber: batchNumber, // Pass the batchNumber as part of the data object
        referenceId : Cookies.get('ACCOUNT')
      }
    })
    .then((response) =>{
      console.log(response)
      return response 
    }).catch((err) =>{
      // console.log('Error fetching api:',err)
    })
}

export const updateProjectClientConfigurations = (data) =>{
  return axios
    .put(`${environment.chatbotHost}/edit-analysis-configs`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.data
    })
}

export const fetchDashboardCounts = async (payload) =>{
  return axios
    .post(`${environment.host}/core/user/${getUserId()}/dashboardCount`, payload,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    }).catch((err) =>{
      console.log('Error fetching counts :',err)
      return;
    })
}

export const fetchEventSummary = async (payload) => {
  return axios
    .post(`${environment.host}/core/user/${getUserId()}/testFailedDevicesCount`, payload,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    }).catch((error) =>{
      return error.message;
    })
}

export const fetchRootCauseAnalyticsSummary = async (payload) => {
  return axios
    .post(`${environment.host}/core/user/${getUserId()}/rootCauseIdentifiedDevicesCount`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    }).catch((error) =>{
      return error.message;
    })
}

export const fetchChatSessions = async (referenceId: string, userId: string) => {
    try {
        const response = await axios.post(
            `${environment.chatbotHost}/list-chat-sessions`, 
            { referenceId, userId },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
                }
            }
        );
        return response;
    } catch (error) {
        throw error; 
    }
};

export const CopilotInitialApi = (data) =>{
  return axios 
    .post(`${environment.chatbotHost}/save_json/`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data
    })
}

export const getNotifications = (start,end) =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/allRootCauseResults?startingIndex=${start}&endIndex=${end}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      const unreadNotifications = response.data.content.filter(notification => !notification.read);
      return {
        content: response.data.content,
        unreadNotifications: unreadNotifications
      };
    });
};

export const markAsRead = async (ids) => {
  try {
    const response = await axios.post(`${environment.host}/core/user/${getUserId()}/rootCause/markAsRead`, ids, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error marking notifications as read:', error);
    throw error;
  }
};

export const getDevicesNotInTestCase = () =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/testCase/device`,{
      headers: {
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      return error.message
    })
}

export const getDevicesNotHaveBatchNumber = () =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/batch/device`,{
      headers: {
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      return error.message
    })
}

export const addDevicesToTestCase = (batchNumber,devices) =>{
  return axios
    .put(`${environment.host}/core/user/${getUserId()}/batch/${batchNumber}`,devices,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.content
    })
    .catch((error) => {
      return error.message
    }
  )
}

export const addBatchNumberToDevices = (batchNumber,devices) =>{
  return axios
    .put(`${environment.host}/core/user/${getUserId()}/device?batchNumber=${batchNumber}`,devices,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) => {
      return response.data.content
    })
    .catch((error) => {
      return error.message
    }
  )
}

export const DeleteFile  = (data) =>{
  return axios 
    .post(`${environment.chatbotHost}/delete_file`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      console.log(response.data)
      // return 
    })
    .catch((error) =>{
      return error.message
    }
  )
}

export const getTestDevices = async () =>{
  return axios 
    .get(`${environment.host}/core/user/${getUserId()}/deviceTest/device`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      const devices = response.data.content
      return devices
    })
}


export const getAllDevices = async (deviceIds = null) => {
  let url = `${environment.host}/core/user/${getUserId()}/device`;
  
  if (deviceIds && deviceIds.length > 0) {
      const deviceIdsParam = deviceIds.join(',');
      url += `?deviceIds=${deviceIdsParam}`;
  }

  return axios
      .get(url, {
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
          }
      })
      .then((response) => {
          const devices = response.data.content;
          return devices;
      });
};

export const getArchivedDevices = async (planId) => {
  return axios
      .get(`${environment.host}/core/user/${getUserId()}/deviceArchives`, {
          params: { planId },
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
          }
      })
      .then((response) => response.data.content);
};

export const deleteTestDevice = async (deviceId: string, planId?: string) => {
  const config: any = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
    },
  };

  if (planId !== "undefined" && planId !== null) {
    config.params = { planId };
  }

  try {
    const response = await axios.delete(
      `${environment.host}/core/user/${getUserId()}/device/${deviceId}`,
      config
    );
    return response.data.content;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete the device.");
  }
};

// Get IsDefaultFasleMain Test cases from the API endpoint
export const getIsDefaultFalseMainTestCases = async (batchNo) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
        },
      }
    );
    const allData = response.data;
    const data = allData.content.filter((d) => d.default === false);
    const mainTestTitles = data.map(testCase => testCase.mainTestTitle);
    return {
      mainTestTitles,
      content: data
    };
  }
  catch (err) {
    console.error('Error fetching main test cases:', err);
  }
};

// Get Feasibility Test Summary Data from the API endpoint
export const getFeasibilityTestSummery = async (
  batchNo, 
  mainTitle
) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/user/${getUserId()}/deviceTest/testReportTable/${batchNo}?testCaseTitle=${mainTitle}`, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        }
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.data.content;
  } catch (err) {
    console.error('Error fetching device test summary:', err);
  }
};

export const fetchUsers = async () => {
  return axios
    .get(`${environment.host}/ums/user/${getUserId()}/system-user?filter=device-pulse`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      return error.message
    })
}

export const fetchUserRole = async () =>{
  return axios
    .get(`${environment.host}/ums/super/user-role`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      return error.message
    })
}

export const updateUser = (user,selecteduserId) =>{
  return axios
    .put(`${environment.host}/ums/user/${getUserId()}/system-user/${selecteduserId}`,user,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
    .then((response) =>{
      return response
    })
    .catch((error) =>{
      return error
    })
}

export const setupNotification = async (data) =>{
  return axios 
    .post(`${environment.host}/core/user/${getUserId()}/notificationSetup`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      return error.message
    })
}

export const removeUser = async (id) =>{
  return axios  
    .delete(`${environment.host}/ums/user/${getUserId()}/system-user/${id}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
    .then((response) =>{
      return response
    })
    .catch((error) =>{
      return error
    })
}

export const getNotificationSetup = async (id) =>{
  return axios  
    .get(`${environment.host}/core/user/${getUserId()}/getNotificationSetup/${id}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
    .then((response) =>{
      return response
    })
    .catch((error) =>{
      return error
    }
  )
}

export const fetchUserMetaData = () =>{
  return axios
  .get(`${environment.host}/user/${getUserId()}/metadata`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
    },
  })
  .then((response) =>{
    return response
  })
  .catch((error) =>{
    return error
  })
}

export const changeSensorLabels = async (deviceId, sensorChangeRequestDTO) => {
  try {
    const response = await axios.put(`${environment.host}/core/device/${deviceId}/sensor/changeLabels`, sensorChangeRequestDTO);
    return response.data;
  } catch (error) {
    console.error("Error changing sensor labels:", error);
    throw error;
  }
}; 

export const getDevices = (batchNumber) =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/batch/${batchNumber}`,{
      headers: {
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data.content
    })
    .catch((error) =>{
      return error.message
    })
}

export const getKnowledgeBaseDetails = (data) =>{
  return axios
    .post(`${environment.chatbotHost}/check-files/`,data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    })
    .then((response) =>{
      return response.data
    })
    .catch((error) =>{
      return error.message
    })
}

export const commonChat = (data) =>{
  return axios
    .post(`${environment.chatbotHost}/common_chat/`,
      data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
        }
      }
    ).then((response) =>{
      return response.data
    })
    .catch((error) =>{
      return error.message
    })
  
}

export const updateMainTestCase = async (data,id) =>{
  return axios
    .put(`${environment.host}/core/user/${getUserId()}/testCase/${id}`,
    data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const updateAcknowledgement = async(data) =>{
  return axios  
    .post(`${environment.chatbotHost}/update-acknowledgement/`,
      data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
        }
      }
    ).then((response) =>{
      return response.data
      })
  .catch((error) =>{
    throw error.response.data
  })
}

export const getAllSensors =async () =>{
  return axios  
    .get(`${environment.host}/core/user/${getUserId()}/sensorCodes`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const addSensor = async (data) =>{
  return axios  
    .post(`${environment.host}/core/user/${getUserId()}/sensorCodes`,
    data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const saveToken = (data) =>{
  return axios  
    .post(`${environment.host}/core/user/${getUserId()}/knowledgeBaseApi/token`,
    data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const getGuideUser = async () =>{
  return axios
    .get(`${environment.host}/ums/${getUserId()}/get-guide`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const getAuthenticationToken = () =>{
  return axios  
    .get(`${environment.host}/core/user/${getUserId()}/knowledgeBaseApi/token`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
} 

export const updateGuideUser = async (user) =>{
  return axios
    .put(`${environment.host}/ums/${getUserId()}/update-guide`,user,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
} 

export const addApiConfiguration = (data) =>{
  return axios  
    .post(`${environment.host}/core/user/${getUserId()}/knowledgeBaseApi`,
    data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const getApiConfig = (batchNumber) =>{
  return axios  
    .get(`${environment.host}/core/user/${getUserId()}/knowledgeBaseApi?batchNumber=${batchNumber}`,
    {headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
    }
  }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}
export const createJiraProject = async (data) => {
  return axios  
    .post(`${environment.host}/core/user/${getUserId()}/jira/project`,
    data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response
  })
  .catch((error) =>{
    throw error.response
  })
}

export const deleteApiConfig = (id) =>{
  return axios  
    .delete(`${environment.host}/core/user/${getUserId()}/knowledgeBaseApi/${id}`,
    {
      headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }).then((response) =>{
      return response.data.content
    }).catch((error) => {
      throw error.response;
    }
  )
}

export const getJiraProject = async () =>{
  return axios  
    .get(`${environment.host}/core/user/${getUserId()}/jira/project`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const updateApiConfig = (id,data) =>{
  return axios  
    .put(`${environment.host}/core/user/${getUserId()}/knowledgeBaseApi/${id}`,
    data,
    {headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
    }
  }
  ).then((response) =>{
    return response.data.content
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const getAllJiraIssues = async () =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/jira-api/issues`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const getAllDBJiraIssues = async () =>{
  return axios
    .get(`${environment.host}/core/user/${getUserId()}/jira/issues`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response
  })
  .catch((error) =>{
    throw error.response.data
  })
}


export const updateProjectStatus = async (status: boolean) => {
  return axios
    .put(`${environment.host}/core/user/${getUserId()}/jira/project/status`,{},
    {
      params: { status },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    return response.data
  })
  .catch((error) =>{
    throw error.response.data
  })
}

export const SyncJiraTickets = async () => {
  return axios
    .put(`${environment.host}/core/user/${getUserId()}/jira/syncTickets`,{},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      }
    }
  ).then((response) =>{
    console.log(response)
    return response 
  })
  .catch((error) =>{
    throw error.response.data
  })
}

