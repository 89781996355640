import {FormControl, FormControlLabel, Grid, RadioGroup, Typography} from '@material-ui/core';
import * as React from 'react';


export function ViewDeviceComponent(props) {

  const onClose = () => {
    props.isEyeIconClick(prevState => ({
      ...prevState,
      device: false
    }))
  }
  return (
    <div>
      <aside>
        <div className="modal_HighZindex" id="addKitModel">
          <div className="modal-content2">
            <Grid container className="modal-header" style={{textAlign: 'left'}}>
              <Grid item xs={7} md={7}>
                <Typography>
                  View Device
                </Typography>
              </Grid>
              <Grid item xs={5} md={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="modal-close" style={{color: "#666D73"}} onClick={() => onClose()}>&times;</button>
              </Grid>
            </Grid>
            <div className={`testing`} style={{margin: '0', paddingTop: "20px"}}>
              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Device Id(IMEI)
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.id}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Device Name
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.name}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Interval
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.interval}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Persistence
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}>
                      <FormControl>
                        <RadioGroup row defaultValue={props.persistance}>
                          <FormControlLabel
                            value="true"
                            control={props.radio}
                            label="True"
                          />
                          <FormControlLabel
                            value="false"
                            control={props.radio}
                            label="False"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container>
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Sensors
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <Grid container>
                          {props.selectSensors.length == 0 ?
                            <Grid container>
                              <input
                                className={props.classes.formInput}
                                type="text"
                                placeholder={props.selectSensors.length != 0 ? "" : "No Sensor Added"}
                                style={{height: '80px', marginBottom: '10px', width: '100%', textAlign: 'center'}}
                                // value={sensorValue}
                                disabled={true}
                              />
                            </Grid>
                            :
                            <Grid container style={{
                              border: '0.5px solid #55555580',
                              borderRadius: '5px',
                              height: '80px',
                              padding: '10px',
                              overflowY: 'auto',
                              marginBottom: '10px'
                            }}>
                              {props.selectSensors.map((sensor, index) => {
                                return (
                                  <Grid container key={index} style={{
                                    border: '0.5px solid #55555580',
                                    width: 'auto',
                                    borderRadius: '5px',
                                    padding: '2px 6px',
                                    alignItems: 'center',
                                    margin: '0 5px 2px 0',
                                    height: '30px'
                                  }}>
                                    <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                                    <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                                    <Grid item style={{padding: '2px 5px'}}>{sensor.name}</Grid>
                                  </Grid>
                                )
                              })
                              }
                            </Grid>
                          }
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container>
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Actuators
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <Grid container>
                          {props.selectActuator.length == 0 ?
                            <Grid container>
                              <input
                                className={props.classes.formInput}
                                type="text"
                                placeholder={props.selectActuator.length != 0 ? "" : "No Actuator Added"}
                                style={{height: '80px', marginBottom: '10px', width: '100%', textAlign: 'center'}}
                                // value={sensorValue}
                                disabled={true}
                              />
                            </Grid>
                            :
                            <Grid container style={{
                              border: '0.5px solid #55555580',
                              borderRadius: '5px',
                              height: '80px',
                              padding: '10px',
                              overflowY: 'auto',
                              marginBottom: '10px'
                            }}>
                              {props.selectActuator.map((actuator, index) => {
                                return (
                                  <Grid container key={index} style={{
                                    border: '0.5px solid #55555580',
                                    width: 'auto',
                                    borderRadius: '5px',
                                    padding: '2px 6px',
                                    alignItems: 'center',
                                    margin: '0 5px 2px 0',
                                    height: '30px'
                                  }}>
                                    <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                                    <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                                    <Grid item style={{padding: '2px 5px'}}>{actuator.name}</Grid>
                                  </Grid>
                                )
                              })
                              }
                            </Grid>
                          }
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Protocol
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.protocol}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Connectivity
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.connectivity}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Batch
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.batchNumber}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={5} md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} md={3}>
                      <Typography className={props.classes.text}>
                        Sim Number
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={4} md={8}>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.classes.formInput}
                          type="text"
                          value={props.selectDevice.simNumber}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={5} style={{marginBottom: '10px'}}>
                <Grid item xs={5} md={12}>
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={4} md={9}>
                      <Grid container justifyContent='flex-end'>
                        <Grid item style={{
                          border: '0.5px solid #555555',
                          borderRadius: '5px',
                          padding: '5px 30px',
                          marginRight: '10px',
                          cursor: 'pointer'
                        }} onClick={onClose}>
                          Cancel
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}
