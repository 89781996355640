import * as React from "react";
import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, Input, InputAdornment, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles";
import { DropDown } from "../components/DropDown";
import { ViewIcon } from "src/components/Icons";
import { useEffect, useState } from 'react';
import { SearchBar } from "src/components/sub-components/SearchBar";
import { Close } from "@material-ui/icons";
import { getIsDefaultFalseMainTestCases, getRootCauseConfiguration } from "../components/apiHelper";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step1({ errors,batchNumbers,setFormValue,setErrors,formValue,setLoading,setIsToasterOpen,setReqSuccess,setToasterMessage }) {
  const classes = useStyles();
  const [selectedBatch, setSelectedBatch] = useState("")
  const [responseTime, setResponseTime] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
  const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
  const [mainTestCase, setMainTestCase] = useState('');
  const [showBatchDetails, setShowBatchDetails] = useState(false)
  const [isResponseTimeFixed, setIsResponseTimeFixed] = useState(false);
  const [failedDevices, setFailedDevices] = useState(["M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458", "M03458"]);
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredDevices, setFilteredDevices] = useState(failedDevices);
  const [shedulePeriod, setShedulePeriod] = useState([
    "Minutes", "Hours", "Days", "Weeks", "Months"
  ]);

  useEffect(() => {
    async function fetchBatchDetails() {
      if (!selectedBatch) return;

      setSelectedPeriod("");
      setResponseTime("");
      setMainTestCase("");
      setIsResponseTimeFixed(false);

      try {
        setLoading(true)
        const { mainTestTitles, content } = await getIsDefaultFalseMainTestCases(selectedBatch);
        const rootCauseData = await getRootCauseConfiguration(selectedBatch);
  
        if (rootCauseData && rootCauseData.content && Object.keys(rootCauseData.content).length > 0) {
          handleRootCauseData(rootCauseData);
  
          const assignedMainTestIds = Object.keys(rootCauseData.content.modelDetails || {});
          const unassignedTests = content.filter(testCase => !assignedMainTestIds.includes(testCase.id));
          const unassignedMainTestTitles = unassignedTests.map(testCase => testCase.mainTestTitle);
  
          setMainTestCasesTitles([...new Set(unassignedMainTestTitles)]);
          setMainTestCasesContent(unassignedTests);
        } else {
          setMainTestCasesTitles([...new Set(mainTestTitles)]);
          setMainTestCasesContent(content);
          setIsResponseTimeFixed(false);
          setResponseTime("");
          setSelectedPeriod(""); 
        }
      }catch (error) {
        console.error("Error fetching batch details:", error);
        setIsToasterOpen(true)
        setReqSuccess(false)
        setToasterMessage(error.message)
      }finally{
        setLoading(false)
      }
    }
    fetchBatchDetails();
  }, [selectedBatch]);

  useEffect(() => {
    const results = failedDevices.filter(device =>
      device.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDevices(results);
  }, [searchQuery, failedDevices]);


  const handleRootCauseData = (data) => {
    if (data && data.content && Object.keys(data.content).length > 0) {
      const { content, responseTime, responsePeriod } = data;
      setSelectedPeriod(responsePeriod);
      setIsResponseTimeFixed(true);
    } else {
      setSelectedPeriod("");
      setResponseTime("");
      setIsResponseTimeFixed(false);
      console.log("Root cause configuration data is unavailable.");
    }
  };

  const batches = batchNumbers;
  const handleSelectBatch = async (value) => {
    setSelectedBatch(value);
    setErrors((prevError) => ({
      ...prevError,
      batchNumber: null,
    }));
    setFormValue((prevValue) => ({
      ...prevValue,
      batchNumber: value,
    }));
  };

  const handleSelectMainTestCase = (value) => {
    setMainTestCase(value);
    const selectedMainTestCase = mainTestCasesContent.find(
      (testCase) => testCase.mainTestTitle === value
    );
    if (selectedMainTestCase) {
      setFormValue((prevValue) => ({
        ...prevValue,
        mainTestCaseId: selectedMainTestCase.id,
        modelDetails: {
          ...prevValue.modelDetails,
          [selectedMainTestCase.id]: prevValue.modelDetails[selectedMainTestCase.id] || {
            model: null,
            modelUrl: null,
          },
        },
      }));
      setErrors((prevError) => ({
        ...prevError,
        mainTestCaseId: null,
      }));
    }
  };

  const clickEyeIcon = () => {
    setShowBatchDetails(true)
  }

  const handleWindowClose = () => {
    setShowBatchDetails(false)
  }

  const onMainDropClick = () => {
    if (selectedBatch === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        batchNoError: "Batch is required"
      }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`defineRootCause`}>

      <Grid container className={`${classes.root} ${classes.formContent}`}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.typo}>
                  Test Batch
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  options={batches}
                  type='batch'
                  emptyTag='Select a Test Batch'
                  setSelectOption={setSelectedBatch}
                  value={selectedBatch}
                  onClick={handleSelectBatch}
                  // handleError={handleError}
                  isSingle={true}
                  icon={<ViewIcon color = '#707070'/>}
                  iconClick={clickEyeIcon}
                />
              </Grid>
                {errors.batchNumber &&
                  <Grid item xs={12} style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.batchNumber}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} style={{ marginTop: "20px",padding:'8px' }}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Main Test Case
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
                <Grid item xs={12} md={12}>
                  <DropDown
                    options={mainTestCasesTitles}
                    type='mainTestCase'
                    emptyTag='Select Main Test Case'
                    setSelectOption={handleSelectMainTestCase}
                    value={mainTestCase}
                    isSingle
                    onMainDropClick={onMainDropClick}
                  />
                  {errors.mainTestCaseId &&
                    <Grid item xs={12} style={{ padding: "0px 8px" }}>
                      <Typography className={classes.errorText}>
                        {errors.mainTestCaseId}
                      </Typography>
                    </Grid>
                  }
                </Grid>
              </Grid>
        </Grid>
        </Grid>
        <Dialog open={showBatchDetails} >
          <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>{`Batch ${selectedBatch}`}</span>
              <IconButton
                onClick={handleWindowClose}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <SearchBar placeholder="-Search-" onChange={(e) => setSearchQuery(e.target.value)} />
            <Grid container style={{ margin: "25px 0" }}>
              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                Failed Devices
              </Typography>
            </Grid>

            <Grid container spacing={3} style={{ width: "500px" }}>
              {failedDevices.map((device, index) => (
                <Grid item xs={6} md={4}
                  key={index}
                  style={{ padding: "10px 10px 10px 20px" }}
                >
                  {device}
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}
