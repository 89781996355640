import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Tab,
  Tabs
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {TabContext, TabPanel} from "@material-ui/lab";
import {Chart} from 'chart.js';
import {Typography} from 'antd';
import {AddIconTwo, ExportIcon} from 'src/components/Icons';
import useStyles from '../../assets/styles';
import {DropDown} from '../components/DropDown';
import {CheckBoxDropDown} from './CheckBoxDropDown';
import { changeSensorLabels, fetchMetaData } from '../components/apiHelper';
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';


export const DateAndSummeryReport = ({
                                       isClick,
                                       handleWindowClose,
                                       headers,
                                       data
                                     }) => {

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isAnnotate, setIsAnnotate] = useState(false);
  const [loading, setLoading] = useState(false);

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)

  const handleAnnotationButton = () => {
    setIsAnnotate((prevMode) => !prevMode);
  };
  const chartRef = useRef<Chart | null>(null);
  const isMountedRef = useRef(true);

  const SelectSubTask = (tabHeaders: { tabHeaders: string[] }) => {

    const [newLabelName, setNewLabelName] = useState("");
    const [errors, setErrors] = useState({label : ''});
    const [isAddLabelPopupOpen, setIsAddLabelPopupOpen] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);
    const [labelInput, setLabelInput] = useState('');
    const [selectedPoints, setSelectedPoints] = useState([]);
    const [confirmedPoints, setConfirmedPoints] = useState([]);
    const [pointLabels, setPointLabels] = useState({});
    
    const uniqueSensors = Array.from(
      new Set(
        headers.map((header) => {
          const match = header.match(/\((.*?)\)/); // Extract sensor name in brackets
          return match ? match[1].trim() : null;
        })
      )
    ).filter(Boolean);
    
    const sensorOptions = uniqueSensors.map((sensor) => ({
      label: sensor,
      value: sensor,
      checked: headers[value] && headers[value].includes(`(${sensor})`),
      disabled: headers[value] && headers[value].includes(`(${sensor})`),
    }));
    
    // Set selected sensors based on the current tab
    const [selectedSensors, setSelectedSensors] = useState(
      sensorOptions.filter((sensor) => sensor.checked).map((sensor) => sensor.value)
    );

    const handleAddLabelPopupOpen = () => setIsAddLabelPopupOpen(true);

    const handleAddLabelPopupClose = () => {
      setNewLabelName("");
      setIsAddLabelPopupOpen(false);
    };

    const handleSelectInput = (e) => {
      setLabelInput(e);
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    // console.log('data', data);
    const filterSensorValue = data.filter(sensor => {
      return sensor.subTestCase.toLowerCase().includes(headers[value].split('(')[0].trim().toLowerCase());
    });

    const existingLabels = filterSensorValue.length > 0 && filterSensorValue[0].label ? filterSensorValue[0].label.filter((label) => label && label.trim() !== "") : []; 

    // Merge existing labels with dropdownOptions
    const combinedOptions = Array.from(new Set([...existingLabels, ...dropdownOptions]));

    const validationForm = () => {
      let hasError = false;
      const newErrors = { label: '' };
    
      if (!newLabelName.trim()) {
        newErrors.label = 'Label name is required';
        hasError = true;
      } else if (combinedOptions.includes(newLabelName.trim())) {
        newErrors.label = 'Label already exists';
        hasError = true;
      }
    
      setErrors(newErrors);
      return hasError;
    };

    const handleSaveNewLabel = async () => {
      const hasError = validationForm();
      if (hasError) {
        return;
      }
      setDropdownOptions((prevOptions) => [...prevOptions, newLabelName]);
      setLabelInput(newLabelName);
      setNewLabelName("");
      handleAddLabelPopupClose();
    };

    const handleConfirmLabels = () => {
      const newPointLabels = { ...pointLabels };
      selectedPoints.forEach(point => {
        newPointLabels[point] = labelInput;
      });
  
      setPointLabels(newPointLabels);
      setConfirmedPoints(prev => [...new Set([...prev, ...selectedPoints])]);
      setSelectedPoints([]);
    };    

    const deviceId = filterSensorValue.length > 0 && filterSensorValue[0].deviceId && filterSensorValue[0].deviceId.length > 0 ? filterSensorValue[0].deviceId[0] : null;

    useEffect(() => {
      isMountedRef.current = true;
      
      return () => {
        isMountedRef.current = false;
      };
    }, []);  

    const payload = async (confirmedPoints, selectedSensors) => {
      const metadataSensors = await fetchMetaData();

      const sensorDTOS = confirmedPoints.map(point => ({
        id: filterSensorValue[0].id[point],
        label: pointLabels[point] || labelInput  
      }));

      const sensorCodeDTOS = selectedSensors.map((sensor) => {
        const metadataSensor = metadataSensors.find(meta => meta.name === sensor);

        return {
          code: metadataSensor ? metadataSensor.code : '',
          codeValue: sensor
        };
      });

      return {
        sensorDTOS,
        sensorCodeDTOS,
      };
    };

    const handleChangeLabels = async () => {
      try {
        setLoading(true);
    
        const payloadData = await payload(confirmedPoints, selectedSensors);
        const response = await changeSensorLabels(deviceId, payloadData);
    
        console.log('response', response);
        handleWindowClose();
      } catch (error) {
        if (isMountedRef.current) {
          let errorMessage = 'An unexpected error occurred';
          if (error.response && error.response.status) {
            const statusCode = error.response.status;
            if (statusCode === 500) {
              errorMessage = 'Something went wrong.';
            } else if (statusCode === 422) {
              errorMessage = error.response.data.message;
            }
          }
          setIsToasterOpen(true);
          setReqSuccess(false);
          setToasterMessage(errorMessage);
          setTimeout(() => {
            if (isMountedRef.current) {
              setIsToasterOpen(false);
            }
          }, 5000);
        }
      } finally {
        if (isMountedRef.current) {
          setLoading(false);
        }
      }
    };

    const exportToCSV = () => {
      if (!data[value]) return;
    
      const filterSensorValue = data.filter(sensor =>
        sensor.subTestCase.toLowerCase().includes(headers[value].split('(')[0].trim().toLowerCase())
      );
    
      if (filterSensorValue.length === 0) return;
    
      const sensorData = filterSensorValue[0];
      
      const header = [
        'Device ID',
        'DateTime', 
        'sensor ID',
        'Sensor Reading', 
        'Label',
        'Sub TestCase',
        'MaxVal',
        'MinVal',
        'Success Criteria'
      ];
    
      const rows = sensorData.dateTime.map((_, index) => [
        sensorData.deviceId[0],
        sensorData.dateTime[index],
        sensorData.id[index],
        sensorData.sensorReadings[index],
        sensorData.label[index] || '',
        sensorData.subTestCase,
        sensorData.maxVal,
        sensorData.minVal,
        sensorData.successCriteria
      ]);
    
      const csvContent = [
        header.join(','),
        ...rows.map(row => row.map(cell => 
          `"${String(cell).replace(/"/g, '""')}"`
        ).join(','))
      ].join('\n');
    
      // Create and download CSV file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${headers[value].split('(')[0].trim()}_data.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <TabContext value={value.toString()}>
        <Box sx={{bgcolor: 'background.paper'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="off"
            className={classes.tabs}
          >
            {headers.map((head, index) => (
              <Tab
                key={index}
                label={head}
                style={{border: '1px solid #F4F4F4'}}
                className={classes.tabsIndicator}
              />
            ))}
          </Tabs>

          <TabPanel
            value={value.toString()}
            style={{border: 'none', boxShadow: 'none', padding: '0px'}}
          > 
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={3} className={classes.annotation}>
                <Button
                  onClick={handleAnnotationButton}
                  className={classes.annotationButton}
                >
                  {isAnnotate ? 'Normal Graph' : 'Annotation Graph'}
                </Button>
              </Grid>
              <Grid item xs={3} style={{display:'flex', justifyContent:'flex-end'}}>
                <Button variant="contained"
                    className={classes.csvBtn}
                    onClick={exportToCSV}
                    style={{minWidth: '120px'}}>
                      <span style={{marginRight:'5px'}}>
                        <ExportIcon stroke="#C4C2C2"/>
                      </span>
                      Export CSV</Button>
              </Grid>
            </Grid>
            <Grid container style={{display:'flex', flexDirection:'column'}}>
              {isAnnotate &&  (
                <Grid item xs={combinedOptions.length > 0 ? 10 : selectedSensors.length > 1 ? 5 : 4} style={{display: 'flex', gap: '5px', marginTop: '10px', justifyContent:'flex-start'}}>
                  <CheckBoxDropDown
                    emptyTag="Select Sensors"
                    options={sensorOptions}
                    value={selectedSensors}
                    setSelectOption={setSelectedSensors}
                  />
                  {combinedOptions.length > 0 && (
                    <DropDown
                      type="label"
                      emptyTag="Select a label"
                      value={labelInput}
                      setSelectOption={(e) => {
                        handleSelectInput(e);
                      }}
                      isSingle={true}
                      options={combinedOptions}
                  />
                  )}
                  <Button className={`${classes.addLabelButton} ${classes.button}`} style={{minWidth: '60px'}}
                    onClick={handleAddLabelPopupOpen}
                    disabled={selectedPoints.length === 0} 
                  >
                    <AddIconTwo color='#005B96'/>
                    <span style={{color:"#005B96"}}>Add</span>
                  </Button>
                  {labelInput && (
                    <Button
                      variant="contained"
                      disabled={!labelInput || selectedPoints.length === 0}
                      className={`${classes.getStarted} ${classes.button}`}
                      style={{minWidth: '150px'}}
                      onClick={handleConfirmLabels}
                    >
                      Confirm Labels
                    </Button>
                  )}
                </Grid>
              )}
              <Grid container className={classes.graph}>
                {
                  filterSensorValue[0] && filterSensorValue[0].sensorReadings.length !== 0 ? (
                    isAnnotate ? (
                      <AnnotationChart
                        data={data}
                        headers={headers}
                        value={value}
                        selectedPoints={selectedPoints}
                        setSelectedPoints={setSelectedPoints}
                        confirmedPoints={confirmedPoints}
                        setConfirmedPoints={setConfirmedPoints}
                        pointLabels={pointLabels}
                        setPointLabels={setPointLabels}
                      />
                    ) : (
                      <LineChart
                        data={data}
                        headers={headers}
                        value={value}
                      />
                    )
                  ) : (
                    <Typography
                      style={{
                        height: '200px',
                        textAlign: 'center',
                        border: '1px solid #F4F4F4',
                        alignContent: 'center',
                      }}
                    >
                      No Sensor Readings
                    </Typography>
                  )
                }
              </Grid>
              {isAnnotate &&
                <Grid container spacing={2} style={{marginTop: "20px", position: 'sticky', top: 0, zIndex: 1}} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      className={`${classes.backButton} ${classes.button}`}
                      onClick={() => {
                        handleWindowClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={`${classes.getStarted} ${classes.button}`}
                      onClick={handleChangeLabels}
                      disabled={confirmedPoints.length === 0 || !labelInput || selectedSensors.length === 0}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </TabPanel>
        </Box>
        {isAddLabelPopupOpen && (
          <AddLabelPopup
            isOpen={isAddLabelPopupOpen}
            handleClose={handleAddLabelPopupClose}
            errors={errors}
            setErrors={setErrors}
            labelName={newLabelName}
            setLabelName={setNewLabelName}
            handleSave={handleSaveNewLabel}
          />
        )}
      </TabContext>
    );
  };

  const LineChart = ({data, headers, value}) => {
    let isTooLarge = false
    useEffect(() => {
      const canvas = document.getElementById('lineChart') as HTMLCanvasElement;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const filterSensorValue = data.filter(sensor =>
        sensor.subTestCase.toLowerCase().includes(headers[value].split('(')[0].trim().toLowerCase())
      );

      if (filterSensorValue.length > 0 && filterSensorValue[0]) {
        const sensorReadings = filterSensorValue[0].sensorReadings.map(read => Number(read));
        const maxValue = Math.max(...sensorReadings) < Number(filterSensorValue[0].maxVal) ? Number(filterSensorValue[0].maxVal) : Math.max(...sensorReadings);
        const minValue = Math.min(...sensorReadings) > Number(filterSensorValue[0].minVal) ? Number(filterSensorValue[0].minVal) : Math.min(...sensorReadings);
        const dateTime = filterSensorValue[0].dateTime;
        if (minValue > 1000000 || maxValue > 1000000) {
          isTooLarge = true
        }

        if (ctx) {
          new Chart(ctx, {
            type: 'line',
            data: {
              labels: dateTime,
              datasets: [
                {
                  label: headers[value].split('(')[0].trim(),
                  data: sensorReadings,
                  borderColor: '#5E5C5C',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  fill: false,
                  tension: 0.1,
                  pointBackgroundColor: '#2A7BEC',
                  pointBorderColor: '#2A7BEC',
                  pointBorderWidth: 1,
                  pointRadius: 5,
                },
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  display: true,
                  title: {
                    display: true,
                    text: 'Time',
                  },
                }],
                yAxes: [{
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: headers[value].split('(')[0].trim(),  // Axis label based on your headers
                  },
                  ticks: {
                    beginAtZero: true,  // Start the y-axis from 0
                    min: isTooLarge ? Math.ceil(Number(Math.min(...sensorReadings))) : Math.ceil(minValue / 10) * 10 - 10,  // Minimum value for the y-axis
                    max: isTooLarge ? Number(Math.max(...sensorReadings)) == 0 ? 10 : Math.ceil(Number(Math.max(...sensorReadings))) : Math.ceil(maxValue / 10) * 10 + 10,  // Maximum value for the y-axis
                    stepSize: 10,  // Distance between ticks
                  },
                }],
              },
              plugins: {
                tooltip: {
                  mode: 'index',
                },
                legend: {
                  display: true,
                },
              },
            },
            plugins: [
              {
                afterDraw: (chartInstance) => {
                  const {left, right} = chartInstance.chartArea;
                  if (!isTooLarge && chartInstance.scales['y-axis-0'] && filterSensorValue[0].minVal) {
                    const yValue1 = chartInstance.scales['y-axis-0'].getPixelForValue(Number(filterSensorValue[0].minVal))
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(left, yValue1);
                    ctx.lineTo(right, yValue1);
                    ctx.strokeStyle = '#949494';
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.font = '12px Poppins';
                    ctx.fillStyle = '#C5C3C3';
                    ctx.fillText('Minimum Threshold Level 1', left + 50, yValue1 - 10);
                    ctx.restore();
                  }
                  if (!isTooLarge && chartInstance.scales['y-axis-0'] && filterSensorValue[0].maxVal && filterSensorValue[0].minVal !== filterSensorValue[0].maxVal) {
                    const yValue2 = chartInstance.scales['y-axis-0'].getPixelForValue(Number(filterSensorValue[0].maxVal));
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(left, yValue2);
                    ctx.lineTo(right, yValue2);
                    ctx.strokeStyle = '#949494';
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.font = '12px Poppins';
                    ctx.fillStyle = '#C5C3C3';
                    ctx.fillText('Maximum Threshold Level 1', left + 50, yValue2 - 10);
                    ctx.restore();
                  }
                },
              },
            ],
          });
        }
      }
    }, [data, headers, value]);

    return (
      <div style={{height: '500px', width: data[value].dateTime.length > 100 ? '3500px' : 'auto', overflowX: 'auto', overflowY:'auto'}}>
        <canvas id="lineChart" style={{display: "block", height: "500px", width: "100%"}}
                className="chartjs-render-monitor"></canvas>
      </div>
    )

  };

  const colors = ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099',
    '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E',
    '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC',
    '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
  ];

  let labels = [];
  let colorCodes = [];

  const getRandomColor = () => {
    let color = '#';
    const letters = '0123456789ABCDEF';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    if (colors.includes(color)) {
      color = getRandomColor();
    }
    return (color);
  }

  const pickColorCode = (data) => {
    console.log('label data', data)
    const colors1 = Array(data.length).fill('#000000');
    data.map((label, i) => {
      if (!labels.includes(label)) {
        label ? labels.push(label) : null;
      }
      if (labels.length < colors.length) {
        const labelIndex = labels.indexOf(label);
        const colorCode = colors[labelIndex];
        colorCode ? colors1[i] = colorCode : null;
      } else {
        const randomColor = getRandomColor();
        colors1[i] = randomColor;
      }
    });
    colorCodes = colors1;
    // console.log('color code', colorCodes)
    return colorCodes;

  }

  const AnnotationChart = ({ data, headers, value, selectedPoints, setSelectedPoints, confirmedPoints, setConfirmedPoints, pointLabels, setPointLabels }) => {  
    let isTooLarge = false;
    const isPointConfirmed = (pointIndex) => confirmedPoints.includes(pointIndex);
    const getPointLabel = (pointIndex) => pointLabels[pointIndex];
  
    useEffect(() => {
      const canvas = document.getElementById('annotationChart') as HTMLCanvasElement;
      if (!canvas) return;
  
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
  
      const filterSensorValue = data.filter(sensor =>
        sensor.subTestCase.toLowerCase().includes(headers[value].split('(')[0].trim().toLowerCase())
      );
      console.log('filter value', filterSensorValue)
      
      if (filterSensorValue.length > 0 && filterSensorValue[0]) {
        const sensorReadings = filterSensorValue[0].sensorReadings.map(read => Number(read));
        const maxValue = Math.max(...sensorReadings) < Number(filterSensorValue[0].maxVal) ? Number(filterSensorValue[0].maxVal) : Math.max(...sensorReadings);
        const minValue = Math.min(...sensorReadings) > Number(filterSensorValue[0].minVal) ? Number(filterSensorValue[0].minVal) : Math.min(...sensorReadings);
        const dateTime = filterSensorValue[0].dateTime;

        const allLabels = [...new Set([
          ...filterSensorValue[0].label.map(read => String(read)),
          ...Object.values(pointLabels).filter(label => label)
        ])];
        const labelColorMap = {};
        const labelColors = pickColorCode(allLabels);
        
        allLabels.forEach((label, index) => {
          labelColorMap[label] = labelColors[index] || '#000000';
        });
  
        if (minValue > 1000000 || maxValue > 1000000) {
          isTooLarge = true;
        }
  
        const chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: dateTime,
            datasets: [
              {
                label: headers[value].split('(')[0].trim(),
                data: sensorReadings,
                borderColor: '#5E5C5C',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                fill: false,
                tension: 0.1,
                pointBackgroundColor: sensorReadings.map((_, index) => {
                  const confirmedLabel = pointLabels[index];
                  const originalLabel = filterSensorValue[0].label[index];
                  const label = confirmedLabel || originalLabel;
                  return label ? labelColorMap[label] : '#000000';
                }),
                pointBorderColor: '#000000',
                pointBorderWidth: 1,
                pointRadius: sensorReadings.map((_, index) => 
                  isPointConfirmed(index) ? 8 : 5
                ),
              },
            ],
          },
          options: {
            animation: {
              duration: 0,
            },
            responsiveAnimationDuration: 0,
            scales: {
              xAxes: [{
                display: true,
                title: {
                  display: true,
                  text: 'Time',
                },
              }],
              yAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: headers[value].split('(')[0].trim(),
                },
                ticks: {
                  beginAtZero: true,
                  min: isTooLarge ? Math.ceil(Number(Math.min(...sensorReadings))) : Math.ceil(minValue / 10) * 10 - 10,
                  max: isTooLarge ? Number(Math.max(...sensorReadings)) === 0 ? 10 : Math.ceil(Number(Math.max(...sensorReadings))) : Math.ceil(maxValue / 10) * 10 + 10,
                  stepSize: 10,
                },
              }],
            },
            tooltips: {
              mode: 'index',
              callbacks: {
                footer: (tooltipItems, data) => {
                  const index = tooltipItems[0].index;
                  let label = '';
        
                  if (filterSensorValue.length > 0 && filterSensorValue[0].label) {
                    label = filterSensorValue[0].label[index];
                  }
        
                  return label && label.trim() ? `Label: ${label}` : '';
                },
              },
            },
            legend: {
              display: true,
            },
            maintainAspectRatio: false,
          },
          plugins: [
            {
              id: 'selectedPointIndicator',
              afterDatasetsDraw: (chart) => {
                const ctx = chart.ctx;
                const meta = chart.getDatasetMeta(0);
                
                // Draw threshold lines
                const { left, right } = chart.chartArea;
                if (!isTooLarge && chart.scales['y-axis-0'] && filterSensorValue[0].minVal) {
                  const yValue1 = chart.scales['y-axis-0'].getPixelForValue(Number(filterSensorValue[0].minVal));
                  ctx.save();
                  ctx.beginPath();
                  ctx.moveTo(left, yValue1);
                  ctx.lineTo(right, yValue1);
                  ctx.strokeStyle = '#949494';
                  ctx.lineWidth = 2;
                  ctx.stroke();
                  ctx.font = '12px Poppins';
                  ctx.fillStyle = '#C5C3C3';
                  ctx.fillText('Minimum Threshold Level 1', left + 50, yValue1 - 10);
                  ctx.restore();
                }
                
                if (!isTooLarge && chart.scales['y-axis-0'] && filterSensorValue[0].maxVal && filterSensorValue[0].minVal !== filterSensorValue[0].maxVal) {
                  const yValue2 = chart.scales['y-axis-0'].getPixelForValue(Number(filterSensorValue[0].maxVal));
                  ctx.save();
                  ctx.beginPath();
                  ctx.moveTo(left, yValue2);
                  ctx.lineTo(right, yValue2);
                  ctx.strokeStyle = '#949494';
                  ctx.lineWidth = 2;
                  ctx.stroke();
                  ctx.font = '12px Poppins';
                  ctx.fillStyle = '#C5C3C3';
                  ctx.fillText('Maximum Threshold Level 1', left + 50, yValue2 - 10);
                  ctx.restore();
                }

                selectedPoints.forEach(pointIndex => {
                  const element = meta.data[pointIndex];
                  if (element && element._model) {
                    ctx.save();
                    ctx.beginPath();
                    
                    // confimed points with filled circle
                    if (isPointConfirmed(pointIndex)) {
                      const pointLabel = getPointLabel(pointIndex);
                      ctx.arc(element._model.x, element._model.y, 10, 0, Math.PI * 2);
                      ctx.fillStyle = pointLabel ? labelColorMap[pointLabel] : '#000000';
                      ctx.fill();
                    } else {
                      // Selected point with Outlined circle
                      ctx.arc(element._model.x, element._model.y, 8, 0, Math.PI * 2);
                      ctx.strokeStyle = '#000000';
                      ctx.lineWidth = 2;
                      ctx.stroke();
                    }
                    
                    ctx.restore();
                  }
                });
              },
            },
          ],
        });
        chartRef.current = chart;
  
        return () => {
          chart.destroy();
        };
      }
    }, [data, headers, value, selectedPoints, confirmedPoints, pointLabels]);

    const handlePointClick = (event) => {
      const chart = chartRef.current;
      if (!chart) return;
    
      const activePoints = chart.getElementsAtEventForMode(
        event, 
        'nearest', 
        { intersect: true }, 
        false
      );
      
      if (activePoints.length > 0) {
        const clickedIndex = activePoints[0]._index;
        
        if (!isPointConfirmed(clickedIndex)) {
          setSelectedPoints((prevPoints) => 
            prevPoints.includes(clickedIndex)
              ? prevPoints.filter((point) => point !== clickedIndex)
              : [...prevPoints, clickedIndex]
          );
        }
      }
    };    

    return (
      <div style={{ height: '500px', width: data[value].dateTime.length > 100 ? '3500px' : 'auto', overflow: 'auto' }}>
        <canvas
          id="annotationChart"
          onClick={handlePointClick}
          style={{ display: "block", height: "500px", width: "100%" }}
          className="chartjs-render-monitor"
        ></canvas>
      </div>
    );
  };

  const AddLabelPopup = ({isOpen, handleClose, errors, setErrors, labelName, setLabelName, handleSave}) => {
    const classes = useStyles();

    return (
      <Dialog open={isOpen} maxWidth="sm" fullWidth>
        <DialogTitle style={{boxShadow: "0 0 15px 0 #00000014"}}>
          <Box
            sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
          >
            <span>{"Add New Label".toLocaleUpperCase()}</span>
            <IconButton
              style={{paddingRight: "0px"}}
              size="small"
              onClick={handleClose}
            >
              <Close/>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{margin: '0px'}}>
          <Box style={{display: "flex", padding: "10px"}}>
            <Grid item xs={12} style={{flexDirection: 'column'}}>
              <Grid>
                <Typography>
                  Label Name
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                <InputBase
                  className={classes.formInput}
                  type="text"
                  placeholder="Enter New Label Name Here"
                  value={labelName}
                  onChange={(e) => {
                    setLabelName(e.target.value);
                    if (errors.label) {
                      setErrors({ label: '' });
                    }
                  }}
                />
                {errors.label && (
                  <Grid item xs={12} style={{ padding: "0px 8px" }}>
                    <Typography className={classes.errorText}>
                      {errors.label}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                style={{marginTop: "20px", marginLeft: '3px'}}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.backButton} ${classes.button}`}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.getStarted} ${classes.button}`}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };


  return (
    <div>
      <Dialog open={isClick} onClose={handleWindowClose} maxWidth="lg" fullWidth>
        <DialogTitle style={{boxShadow: '0 0 15px 0 #00000014'}}>
          <Box
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
          >
            <span> Date & Time Summary Report</span>
            <IconButton
              onClick={handleWindowClose}
              style={{paddingRight: '0px'}}
              size="small"
            >
              <Close/>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{width:'100%', overflowX:'hidden'}}>
          <SelectSubTask tabHeaders={headers}/>
        </DialogContent>
      </Dialog>
      <Loader loading={loading} />
      <ToasterComponent
        toaster={isToasterOpen}
        message={toasterMessage}
        reqSuccess={reqSuccess}
      />
    </div>
  );
};