import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@material-ui/core'
import * as React from 'react'
import { Arrow, BatchesIcon, CollapseIcon, EditIconLarge, ExpandCollapseIcon, ExpandIcon, NonSuccessCriteriaIcon, TrashIcon, ViewIcon } from 'src/components/Icons';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { Close } from '@material-ui/icons';
import { addBatchNumberToDevices, addDevicesToTestCase, fetchBatchNumbers, fetchMainTestCases, getDevices, getDevicesNotHaveBatchNumber, getDevicesNotInTestCase, getUserRole, removeBatch, removeDeviceFromBatch, technicianRole } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../setup-senzmatica/step-3/components/Loader';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';
import useStyles from '../maintenance/assets/styles';
import { Select } from 'antd';
import { MultiSelectDropDown } from '../maintenance/test-automation/components/MultiSelectDropDown';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { primaryBlue, white } from 'src/components/colors';
import { DialogPopup } from '../maintenance/test-automation/components/DialogPopup';
import { hasUserPermission } from '../permission';
import { set } from 'date-fns';
import { getPrefixes, handleDevicePulseClicked } from '../utils';

const { Option } = Select;

export const BatchesComponent = () => {

    const classes = useStyles()
    const theme = createTheme({
        typography: {
            fontFamily: "'Poppins', sans-serif",
        },
    });
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
    const [devices, setDevices] = useState([])
    const [filterDevices, setFilterDevices] = useState([])
    const [isViewBatch, setIsViewBatch] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [batchNumbers, setBatchNumbers] = useState([])
    const [loading, setLoading] = useState(false)
    const [actionType, setActionType] = useState('')
    const [deleteType, setDeleteType] = useState('')
    const [selectedBatchNumber, setSelectedBatchNumber] = useState('')
    const [selectedDeviceId, setSelectedDeviceId] = useState('')
    const [filterBatchNumbers, setFilterBatchNumbers] = useState([])
    const [searchBatchNumber, setSearchBatchNumber] = useState([])
    const [filterVisible, setFilterVisible] = useState(false)
    const [selectedPrefix, setSelectedPrefix] = useState('')
    const [prefixes, setPrefixes] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [expandedRow, setExpandedRow] = useState(null)
    const dropdownRef = useRef(null);
    const [devicesNotInTestCase, setDevicesNotInTestCase] = useState([])
    const [isAddDeviceVisible, setIsAddDeviceVisible] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState([])
    const location = useLocation();

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    const handleDeleteIcon = (batch, type) => {
        if (type == 'batch') {
            setSelectedBatchNumber(batch)
        } else if (type == 'device') {
            setSelectedDeviceId(batch)
        }

        setIsDeletePopupOpen(true)
        setDeleteType(type)
    }

    const cancelDeletePopup = () => {
        setIsDeletePopupOpen(false)
        setTimeout(() => {
            setIsToasterOpen(false)
        }, 2000)
    }

    useEffect(() => {
        const fetchBatchNumber = async () => {
            try {
                setLoading(true)
                const response = await fetchBatchNumbers();
                if (response) {
                    setBatchNumbers(response)
                    setFilterBatchNumbers(response)
                    const prefixes = getPrefixes(response)
                    setPrefixes(prefixes)
                }
            } catch (err) {

            } finally {
                setLoading(false)
            }
        }
        fetchBatchNumber()
        getNotExistDevices()
    }, [])

    const handleViewPopup = async (batchNumber, type) => {
        setLoading(true)
        try {
            const response = await getDevices(batchNumber)
            response ? setDevices(response) : setDevices([])
            setActionType(type)
            setSelectedBatchNumber(batchNumber)

        } catch (err) {

        } finally {
            setLoading(false)
        }
    }

    const getNotExistDevices = async () => {
        try {
            setLoading(true)
            const devices = await getDevicesNotHaveBatchNumber()
            devices && devices.length > 0 ? setDevicesNotInTestCase(devices) : setDevicesNotInTestCase([])
        } catch (error) {

        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }

    const cancelViewPopup = () => {
        setIsViewBatch(false)
        setSearchQuery('')
    }

    useEffect(() => {
        if (searchQuery === '') {
            // If the search query is empty, show all devices
            setFilterDevices(devices);
        } else {
            // Filter the devices based on the search query
            const results = devices.filter(device =>
                device.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilterDevices(results);
        }
    }, [searchQuery, devices]);

    const handleRemoveBatch = async (type) => {
        setLoading(true)
        try {
            if (type == 'device') {
                await removeDeviceFromBatch(selectedBatchNumber, selectedDeviceId)
                const response = await getDevices(selectedBatchNumber)
                getNotExistDevices()
                setDevices(response)
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Devices removed successfully")
            } else if (type == 'batch') {
                await removeBatch(selectedBatchNumber)
                const updatedBatchNumbers = await fetchBatchNumbers();
                setBatchNumbers(updatedBatchNumbers);
                setFilterBatchNumbers(updatedBatchNumbers)
                const prefixes = getPrefixes(updatedBatchNumbers)
                setPrefixes(prefixes)
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Batch removed successfully")
            }
        } catch (err) {
            console.error('Error removing batch/device:', err);
        } finally {
            setLoading(false)
            cancelDeletePopup()
        }
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)
    };

    const addDevicesToBatch = async () => {
        try {
            setLoading(true)
            if (devices.length > 0) {
                const response = await addBatchNumberToDevices(selectedBatchNumber, selectedDevices)
                getNotExistDevices()
                handleViewPopup(selectedBatchNumber, 'view')
                setIsAddDeviceVisible(false)
                setSelectedDevices([])
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Devices added successfully.")

            } else {
                setIsToasterOpen(true)
                setReqSuccess(false)
                setToasterMessage("There is no test case.")
            }

        } catch (error) {

        } finally {
            setLoading(false);
            setTimeout(() => {
                setIsToasterOpen(false); // Show toaster after 2 seconds
            }, 2000);
        }
    }

    useEffect(() => {
        const filterRootCauses = () => {
            const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : '';

            const filtered = batchNumbers.filter(batch => {
                const matchesSearch = batch.toLowerCase().includes(lowerCaseSearchTerm);
                const matchesPrefix = selectedPrefix === 'All' || selectedPrefix === '' || batch.startsWith(selectedPrefix);

                // If no search term is provided and prefix is 'All', show all batches
                if (selectedPrefix === 'All' && !searchTerm) {
                    return true; // Keep all batches
                }
                return matchesPrefix && matchesSearch;
            });
            setFilterBatchNumbers(filtered);
        };
        filterRootCauses();
    }, [searchTerm, batchNumbers]);


    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const filterBatch = () => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = batchNumbers.filter(batch => {
            const matchesPrefix = selectedPrefix === 'All' || batch.startsWith(selectedPrefix);
            const matchesSearch = batch.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        });
        setFilterBatchNumbers(filtered);
        setFilterVisible(false)
    }

    const handleExpand = (index, batch) => {
        setExpandedRow((prevExpandedRow) => {
            if (prevExpandedRow === index) {
                return null;
            } else {
                handleViewPopup(batch, 'view');
                return index;
            }
        });
        setSelectedBatchNumber(batch);
    };

    const handleCancel = () => {
        setSelectedDevices([])
        setIsAddDeviceVisible(false)
    }

    const handleAddDevice = () => {
        setIsAddDeviceVisible(true)
    }

    const closeAddDevice = () => {
        setIsAddDeviceVisible(false)
    }

    const AddDevicesPopup = () => {
        return (
            <Grid item xs={12} md={12}>
                <MultiSelectDropDown
                    options={devicesNotInTestCase}
                    isDropdownVisible={isAddDeviceVisible}
                    setIsDropdownVisible={setIsAddDeviceVisible}
                    setSelectedDevices={setSelectedDevices}
                    selectedDevices={selectedDevices}
                    handleSave={addDevicesToBatch}
                    handleCancel={handleCancel}
                />
            </Grid>
        )
    }

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{ padding: '25px' }}>
                <Grid item xs={12} md={12}>
                <Grid className={classes.pageTitle} style={{margin:'0px'}}>Batches</Grid>
                <Grid container className={classes.redirectContainer} style={{display:"flex"}}>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}> 
                        <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                            <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                                <NonSuccessCriteriaIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <BatchesIcon />
                        <Typography className={classes.redirectTitle}>Batches</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.backgroundContainer}>
                    <Grid item xs={6} md={3} style={{ marginRight: '10px' }}>
                        <SearchBar onChange={handleSearch} />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FilterBatchNumber
                            toggleFilter={toggleFilter}
                            handlePrefixSelect={handlePrefixSelect}
                            filterVisible={filterVisible}
                            setFilterVisible={setFilterVisible}
                            prefixes={prefixes}
                            selectedPrefix={selectedPrefix}
                            filterBatch={filterBatch}
                            setSelectedPrefix={setSelectedPrefix}
                            filterType="batchNumber"
                        />
                    </Grid>
                    <Table className={classes.tableContainer}>
                        <TableHead>
                            <TableRow >
                                <TableCell className={classes.tablehead} align="left" style={{ width: '85%' }}>Batch ID</TableCell>
                                {
                                    hasUserPermission("BATCHES") &&  
                                    <TableCell className={classes.tablehead} style={{ width: '10%' }} align="center">Action</TableCell>
                                }
                                <TableCell className={classes.tablehead} style={{ width: '5%' }} align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterBatchNumbers && filterBatchNumbers.length > 0 ?
                                (filterBatchNumbers.map((batch, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow style={{ backgroundColor: index % 2 == 1 ? '#FFFFFF' : '#F5F5F5' }}>
                                            <TableCell style={{ width: '85%' }} onClick={() => handleExpand(index, batch)}>{batch}</TableCell>
                                            {
                                                  hasUserPermission("BATCHES") &&  
                                                  <TableCell align="center">
                                                        <IconButton size={'small'} onClick={() => handleDeleteIcon(batch, 'batch')}>
                                                            <TrashIcon />
                                                        </IconButton>
                                                    </TableCell>
                                            }
                                           
                                            <TableCell style={{ width: '5%', justifyContent: 'end' }}>

                                                <IconButton size='small' onClick={() => handleExpand(index, batch)}>
                                                    {expandedRow == index ? <ExpandCollapseIcon /> : <ExpandIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        {expandedRow == index &&
                                            <React.Fragment key={`${index}-expanded`}>
                                                <TableRow style={{ width: '100%', backgroundColor: 'white' }}>
                                                    <TableCell colSpan={3}>
                                                        <Grid container >
                                                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {
                                                                    hasUserPermission("BATCHES") && <Button style={{ backgroundColor: primaryBlue, color: white }} variant='contained' onClick={handleAddDevice}>Add Devices</Button>
                                                                }
                                                            
                                                            </Grid>


                                                            <Grid item xs={12} md={12} style={{ marginTop: '10px', border: '1px solid rgb(224, 224, 224)' }}>
                                                                <TableContainer style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell className={classes.tablehead} style={{ width: '85%' }}>Available Devices</TableCell>
                                                                                {hasUserPermission("BATCHES") &&  
                                                                                    <TableCell className={classes.tablehead}>Action</TableCell>
                                                                                }                                                                                   
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody >
                                                                            {filterDevices && filterDevices.length > 0 ? (
                                                                                filterDevices.map((device, index) => (
                                                                                    <TableRow key={index} style={{ backgroundColor: index % 2 == 1 ? '#FFFFFF' : '#F5F5F5' }}>
                                                                                        <TableCell style={{ width: '85%' }}>{device}</TableCell>
                                                                                        {
                                                                                            hasUserPermission("BATCHES") && <TableCell>
                                                                                            <IconButton size="small" onClick={() => handleDeleteIcon(device, 'device')}>
                                                                                                <TrashIcon />
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                        }
                                                                                        
                                                                                    </TableRow>
                                                                                ))
                                                                            ) : (
                                                                                <TableRow style={{ backgroundColor: '#F5F5F5' }}>
                                                                                    <TableCell colSpan={2}>
                                                                                        <Grid container justifyContent="center">
                                                                                            <Grid item>No devices found</Grid>
                                                                                        </Grid>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )))
                                : (
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                            No Batch Available
                                        </TableCell>
                                    </TableRow>
                                )
                            }


                        </TableBody>
                    </Table>
                </Grid>
                <DeletePopup
                    open={isDeletePopupOpen}
                    closePopup={cancelDeletePopup}
                    batchNumber={selectedBatchNumber}
                    type={deleteType}
                    deviceId={selectedDeviceId}
                    handleDelete={handleRemoveBatch}
                />
                <ViewPopup
                    open={isViewBatch}
                    devices={filterDevices}
                    setSearchQuery={setSearchQuery}
                    handleWindowClose={cancelViewPopup}
                    type={actionType}
                    openDelete={handleDeleteIcon}
                    selectedBatch={selectedBatchNumber}
                />
                <Loader loading={loading} />
                <ToasterComponent
                    reqSuccess={reqSuccess}
                    message={toasterMessage}
                    toaster={isToasterOpen}
                />
                <DialogPopup
                    open={isAddDeviceVisible}
                    onClose={closeAddDevice}
                    title='add devices'
                    buttonRequired={false}
                    dialogContent={<AddDevicesPopup />}
                />
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export const DeletePopup = ({ open, closePopup, batchNumber, type, deviceId, handleDelete }) => {
    return (
        <Dialog open={open}>
            <Box>
                <DialogTitle>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <span>{type == 'batch' ? 'REMOVE BATCH' : 'REMOVE DEVICE'}</span>
                        <IconButton
                            onClick={closePopup}
                            style={{ paddingRight: '0px' }}
                            size="small"
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ margin: '0px' }}>
                    <Grid item style={{ marginBottom: '15px', marginRight: '15px' }}>
                        <Typography>
                            {type == 'batch' ?
                                `Are you sure you want to remove batch ID: ${batchNumber}`
                                : `Are you sure you want to remove device ID: ${deviceId} from batch ID: ${batchNumber} `
                            }
                        </Typography>
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                            <Button variant='outlined' onClick={closePopup} style={{ marginRight: '10px' }}>Cancel</Button>
                            <Button variant='contained' style={{ backgroundColor: '#FF4343', color: 'white' }} onClick={() => handleDelete(type)}>Delete</Button>
                        </Grid>
                    </Grid>


                </DialogContent>
            </Box>
        </Dialog>
    )
}

export const ViewPopup = ({ open, devices, setSearchQuery, handleWindowClose, type, openDelete, selectedBatch }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    return (
        <Dialog open={open}>
            <DialogTitle>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0 0 15px 0 #00000014' }}
                >
                    <span>{type == 'view' ? 'View Batch' : 'Edit Batch'}</span>
                    <IconButton
                        onClick={handleWindowClose}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ fontSize: "15px", color: '#324054', marginBottom: '10px', fontWeight: 'bold' }}>{selectedBatch}</Typography>
                <SearchBar placeholder="Search" onChange={(e) => setSearchQuery(e.target.value.trim())} />
                <Grid container style={{ margin: "25px 0" }}>
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                        Devices
                    </Typography>
                </Grid>

                <Grid container spacing={3} style={{ width: "500px" }}>
                    {devices.map((device, index) => (
                        <Grid
                            item
                            xs={12}   // Full width on small screens
                            md={6}
                            key={index}
                            onMouseEnter={() => setHoveredIndex(index)}  // Set hovered index on mouse enter
                            onMouseLeave={() => setHoveredIndex(null)}   // Reset hovered index on mouse leave
                        >
                            <Grid
                                container
                                alignItems="center"
                                style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                }}
                            >
                                <Typography style={{ fontSize: '13px' }}>
                                    {device}
                                </Typography>

                                {type === 'edit' && hoveredIndex === index && (
                                    <IconButton size='small' style={{ marginLeft: '10px' }} onClick={() => openDelete(device, 'device')}>
                                        <TrashIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>

                    ))}

                </Grid>
            </DialogContent>
        </Dialog>
    )
}