import { ThemeProvider, createTheme, Grid, Button, Table, TableBody, TableCell, TableHead,TableContainer, TableRow, IconButton, TablePagination, Tooltip, Typography} from '@material-ui/core';
import * as React from 'react'
import { CircleIcon, DeviceIcon, NonSuccessCriteriaIcon, TrashIcon} from 'src/components/Icons';
import { BackToHomeComponent } from '../maintenance/test-automation/components/BackToHome';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';
import { useEffect, useState } from 'react'
import { deleteTestDevice, fetchBatchNumbers, getAllDBJiraIssues, getAllDevices, getAllJiraIssues, getTestDevices, getUserRole, technicianRole } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { DeletePopup } from '../maintenance/test-automation/components/DeletePopup';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import useStyles from '../maintenance/assets/styles';
import {useLocation} from 'react-router-dom'
import { CreateBatchPopup } from './components/CreateBatchPopup';
import { hasUserPermission } from '../permission';
import { formatSriLankanDateTime, getBackgroundColor, getColor, getPrefixes, isTestAutomationUser } from '../utils';
import { ViewStatusPopUp } from './components/ViewStatusPopUp';
import { azureService } from '../utils';
import Cookies from "js-cookie";

export const DevicePulseDevicesComponent = ({}) =>{
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles()
    const location = useLocation();
    const stageTitle = location.state ? location.state.stageTitle : 'Devices';
    const mainTitle = location.state ? 'Post Deployment Stage' : 'Devices';
    const path = location.state ? location.state.path : '/TestAutomation';
    const hideCreateBatch = location.state ? location.state.hideCreateBatch : false;
    // const [toggleFilter,setToggleFilter] = useState(false)
    const [filterVisible,setFilterVisible] = useState(false)
    const [prefixes,setPrefixes] = useState([])
    const [selectedPrefix,setSelectedPrefix] = useState('')
    const [testDevices,setTestDevices] = useState([])
    const [searchTerm,setSearchTerm] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterDevices,setFilterDevices] = useState([])
    const [notBatchAvailableDevices,setNotBatchAvailableDevices] = useState([])
    const [isDelete,setIsDelete] = useState(false)
    const [selectedDeviceId,setSelectedDeviceId] = useState('')
    const [isCreateBatchpopupOpen,setIsCreateBatchpopupOpen] = useState(false)
    const [selectedDeviceStatus, setSelectedDeviceStatus] = useState([]);
    const [isViewStatusOpen, setIsViewStatusOpen] = useState(false);
    const {isAzureFreePackage, isAzureBasicPackage} = azureService()
    const planId = Cookies.get('PLANID')

    const isVisible = isTestAutomationUser() && !(isAzureFreePackage() || isAzureBasicPackage());

    //Loader
    const [loading,setLoading] = useState(false)

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false) 

    const handleSearch = (event) =>{
        const {value} = event.target
        const lowerCaseSearchTerm = value.toLowerCase();
        let filterDevice = testDevices.filter(device => {
            const matchesPrefix = device.batchNumber && (selectedPrefix === 'All' || device.batchNumber.startsWith(selectedPrefix));
            const matchesSearch = device.id.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        })
        setFilterDevices(filterDevice)
        setPage(0)
    }

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const filterBatch = () =>{  
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = testDevices.filter(device => {
            if(device.batchNumber){
                const matchesPrefix = selectedPrefix === 'All' || device.batchNumber.startsWith(selectedPrefix);
                const matchesSearch = device.batchNumber.toLowerCase().includes(lowerCaseSearchTerm)
                return matchesPrefix && matchesSearch;
            }
        })
        setFilterDevices(filtered);
        setFilterVisible(false)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const openBatchPopUp = () =>{
        setIsCreateBatchpopupOpen(true)
    }

    const cancelBatchPopup = () =>{
        setIsCreateBatchpopupOpen(false)
    }

    const handleBatchCreated = async () => {
        await getDevices();
    };

    useEffect(() =>{
        getDevices()
    },[])

    const formatDate = (dateStr) => {
        if(dateStr != ""){
            const date = new Date(dateStr);
            return date.toISOString().replace("T", " ").substring(0, 19);
        }   
    };

    const getDevices = async () => {
        setLoading(true);
    
        try {
            let devices = [];
            let selectedDeviceIds = null;
    
            if (location && location.state && location.state.deviceIds) {
                selectedDeviceIds = location.state.deviceIds;
            }
            if (selectedDeviceIds && selectedDeviceIds.length > 0) {
                devices = await getAllDevices(selectedDeviceIds);
            } else {
                devices = await getAllDevices();
            }
    
            setTestDevices(devices);
            setFilterDevices(devices);
    
            const batchNumbers = [];
            devices.forEach(device => {
                if (device.batchNumber && !batchNumbers.includes(device.batchNumber)) {
                    batchNumbers.push(device.batchNumber);
                }
            })
            const uniquePrefixes = getPrefixes(batchNumbers);
            setPrefixes(uniquePrefixes);
            const notBatchAvailableDevices = devices.filter((device) => !device.batchNumber);
            setNotBatchAvailableDevices(notBatchAvailableDevices);
        } catch (error) {
            console.error('Error fetching devices:', error);
        } finally {
            setLoading(false);
        }
    };

    
    const fetchData = async () => {
        try {
            setLoading(true);
            
            const dbResponse = await getAllDBJiraIssues();
            if (dbResponse.status !== 200) {
                console.log("Error fetching DB data");
                return;
            }

            const devices = location.state ? location.state.deviceIds : [];
            
            const dbIssues = dbResponse.data.filter(issue => 
                issue.type === "SUB" && 
                (devices.includes(issue.deviceId) || 
                    (issue.labels && issue.labels.some(label => devices.includes(label))))
            );

            const jiraResponse = await getAllJiraIssues();
            if (jiraResponse.status !== 200) {
                console.log("Error fetching Jira data");
                return;
            }

            const jiraIssuesMap = {};
            jiraResponse.data.issues.forEach(issue => {
                jiraIssuesMap[issue.key] = issue;
            });

            const deviceStatusMap = {};
            dbIssues.forEach(dbIssue => {
                const deviceId = devices.find(id => 
                    id === dbIssue.deviceId || 
                    (dbIssue.labels && dbIssue.labels.includes(id))
                );
                
                if (!deviceId) return;

                const jiraIssue = jiraIssuesMap[dbIssue.issueKey];
                if (!jiraIssue) return;

                if (!deviceStatusMap[deviceId]) {
                    deviceStatusMap[deviceId] = [];
                }

                deviceStatusMap[deviceId].push({
                    subTestcase: dbIssue.subTestcase,
                    jiraKey: jiraIssue.key,
                    status: jiraIssue.fields.status ? jiraIssue.fields.status.name : 'N/A'
                });
            });

            setFilterDevices(prevDevices => {
                return prevDevices.map(device => {
                    const statusInfo = deviceStatusMap[device.id] || [];
                    return {
                        ...device,
                        statusInfo: statusInfo,
                        status: statusInfo.length > 0 ? statusInfo[0].status : 'N/A'
                    };
                });
            });

        } catch (error) {
            console.log("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (location.state && location.state.deviceIds) {
            fetchData();
        }
    }, [location.state && location.state.deviceIds]);

    const openViewStatus = (deviceId) => {
        const device = filterDevices.find(d => d.id === deviceId);
        setSelectedDeviceStatus(device.statusInfo ? device.statusInfo : []);
        setIsViewStatusOpen(true);
    };

    const closeViewStatus = () => {
        setIsViewStatusOpen(false);
    };

    const handleDeleteIcon = (id) =>{
        setSelectedDeviceId(id)
        setIsDelete(true)
    }

    const onClose = () =>{
        setIsDelete(false)
    }

    const onDelete = async() =>{
        try{
            setLoading(true)
            const response = await deleteTestDevice(selectedDeviceId, planId && planId !== "undefined" && planId !== "null" ? planId : undefined);
            if(response){
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Device Removed Successfully")
                window.dispatchEvent(
                    new CustomEvent('deviceDeleted', { detail: { selectedDeviceId } })
                );
            }

        }catch(error){
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        }finally{
            setIsDelete(false)
            setLoading(false)
            await  getDevices()
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
            setPage(0)
        }   
    }

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px'}}>
                <Grid item xs={12} md={12}>
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>{mainTitle}</Grid>
                    <Grid container style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <BackToHomeComponent icon={<DeviceIcon />} title={stageTitle} path={path} />
                        {(!hideCreateBatch && hasUserPermission("DEVICES")) && (
                            <Button 
                                variant="contained"
                                className={`${classes.getStarted} ${classes.button}`}
                                style={{ height: '40px', backgroundColor: '#005B96'}}
                                onClick={openBatchPopUp}
                            >
                                Create Batch
                            </Button>
                        )}                
                    </Grid>
                    <Grid container className={classes.backgroundContainer}>
                        <Grid item xs={6} md={3} style={{marginRight:'10px'}}>
                            <SearchBar onChange={handleSearch}/>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FilterBatchNumber 
                                toggleFilter={toggleFilter} 
                                handlePrefixSelect={handlePrefixSelect} 
                                filterVisible={filterVisible} 
                                setFilterVisible ={setFilterVisible}
                                prefixes = {prefixes}
                                selectedPrefix={selectedPrefix}
                                filterBatch={filterBatch}
                                setSelectedPrefix = {setSelectedPrefix}
                                filterType="batchNumber"
                            />
                        </Grid>  
                        <TablePagination style={{marginRight: '8px', marginTop: '10px'}}
                            rowsPerPageOptions={[]}
                            component="div"
                            count={filterDevices !== undefined && filterDevices.filter((device) => device.id.toLowerCase().includes(searchTerm.trim().toLowerCase())).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> 
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tableHeader}>Device</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>Batch Name</TableCell>
                                { isVisible && location.state && location.state.deviceIds && (
                                    <TableCell align="left" className={classes.tableHeader}>Status</TableCell>
                                )}
                                <TableCell align="left" className={classes.tableHeader}>Last Onboarded Time</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>Last Seen</TableCell>
                                {
                                    hasUserPermission("DEVICES") && <TableCell  align="center" className={classes.tableHeader}>Action</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterDevices.length > 0 ? 
                                (filterDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device,index) =>(
                                    <TableRow key={index} style={{backgroundColor: index % 2 == 0 ? '#F5F5F5':'#FFFFFF'}}>
                                        <TableCell >{device.id}</TableCell>
                                        <TableCell>{device.batchNumber && device.batchNumber != '' ? device.batchNumber : 'N/A'}</TableCell>
                                        { isVisible && location.state && location.state.deviceIds && (
                                            <TableCell>
                                                {device.statusInfo && device.statusInfo.length > 0 ? (
                                                    <Grid
                                                        container
                                                        onClick={() => openViewStatus(device.id)}
                                                        className={classes.jiraStatusContainer}
                                                        style={{
                                                            justifyContent: (() => {
                                                                const uniqueStatusesCount = Object.keys(
                                                                    device.statusInfo.reduce((acc, { status }) => {
                                                                        acc[status] = (acc[status] || 0) + 1;
                                                                        return acc;
                                                                    }, {})
                                                                ).length;
                                                        
                                                                return uniqueStatusesCount === 1 ? "center" : uniqueStatusesCount === 2 ? "space-evenly" : "space-between";
                                                            })(),
                                                        }}
                                                    >
                                                        {Object.entries(
                                                            device.statusInfo.reduce((acc, { status }) => {
                                                                acc[status] = (acc[status] || 0) + 1;
                                                                return acc;
                                                            }, {})
                                                        )
                                                            .slice(0, 2)
                                                            .map(([status, count], idx) => (
                                                                <Grid
                                                                    item
                                                                    key={idx}
                                                                    className={classes.deviceJiraStatus}
                                                                >
                                                                    <CircleIcon color={getColor(status)} />
                                                                    <Typography style={{ fontSize: "11px", color: getColor(status) }}>
                                                                        {status}
                                                                    </Typography>
                                                                    <Typography
                                                                        className={classes.statusCount}
                                                                        style={{
                                                                            color: getColor(status),
                                                                            backgroundColor: getBackgroundColor(status),
                                                                        }}
                                                                    >
                                                                        {count}
                                                                    </Typography>
                                                                </Grid>
                                                            ))}

                                                        {Object.keys(
                                                            device.statusInfo.reduce((acc, { status }) => {
                                                                acc[status] = (acc[status] || 0) + 1;
                                                                return acc;
                                                            }, {})
                                                        ).length > 2 && (
                                                            <Typography style={{ fontSize: "11px" }}>... More</Typography>
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <Grid
                                                        container
                                                        className={classes.jiraStatusContainer}
                                                        style={{ display: "flex", justifyContent: "center", cursor: "none" }}
                                                    >
                                                        <Typography style={{ fontSize: "11px" }}>No issues found</Typography>
                                                    </Grid>
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell>{device.creationDate && device.creationDate != '' ? device.creationDate : 'N/A'}</TableCell>
                                        <TableCell>{device.lastSeen && device.lastSeen != '' ? device.lastSeen : 'N/A'}</TableCell>
                                        {
                                         hasUserPermission("DEVICES") &&   <TableCell style={{display:'flex',justifyContent:'center', padding: isVisible && location.state && location.state.deviceIds ? '26px' : undefined}}>
                                         <IconButton size={'small'} onClick={() =>handleDeleteIcon(device.id)}>
                                             <TrashIcon />
                                         </IconButton>
                                        </TableCell>
                                        }
                                    </TableRow>
                                )))
                                : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign:'center' }}>
                                        No Devices Available
                                    </TableCell>
                                </TableRow>
                                )
                            } 

                            
                        </TableBody>
                    </Table>
                    </Grid>
                </Grid>
            </Grid>
            <DeletePopup 
                open={isDelete}
                title = {'CONFORMATION'}
                content = {`Are you sure you want delete ${selectedDeviceId} ?`}
                onClose = {onClose}
                onDelete = {onDelete}
            />
            <Loader loading={loading} />
            <ToasterComponent 
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
            <CreateBatchPopup
                open={isCreateBatchpopupOpen}
                handleClose={cancelBatchPopup}
                devices={notBatchAvailableDevices}
                setLoading={setLoading}
                setIsToasterOpen={setIsToasterOpen}
                setReqSuccess={setReqSuccess}
                setToasterMessage={setToasterMessage}
                onBatchCreated={handleBatchCreated}
            />
            <ViewStatusPopUp open={isViewStatusOpen} handleWindowClose={closeViewStatus} statusData ={selectedDeviceStatus}/>
        </ThemeProvider>
    )
}