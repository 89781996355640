import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import useStyles from "src/app/maintenance/assets/styles";

const ComingSoonComponent = ({ children, show }) => { 
    //need to add this to whole component pass the show prop as true
    const classes = useStyles();

    return (
        <Box className={classes.tileContainer}>
            {children}

            {show && (
                <Box className={classes.comingSoon}>
                    <Typography style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: '#434656',
                        textAlign: 'center',
                    }}>
                        Coming Soon...
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ComingSoonComponent;