import { Button, createTheme, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, ThemeProvider, Tooltip } from '@material-ui/core'
import * as React from 'react'
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';
import { useEffect, useState } from 'react';
import { AddIconTwo, AlertIcon, EditIconLarge, SlideIconOff, SlideIconOn, TrashIcon } from 'src/components/Icons';
import { AlertPopup } from './Alert';
import { DialogPopup } from '../maintenance/test-automation/components/DialogPopup';
import { AddUserComponent } from '../setup-senzmatica/step-4/AddUser';
import { fetchUserMetaData, fetchUserRole, fetchUsers, getNotificationSetup, getUserRole, removeUser, setupNotification } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { Conditional } from '@angular/compiler';
import { AlertBar } from 'src/components/sub-components/AlertBar';
import useStyles from '../maintenance/assets/styles';
import { environment } from 'src/environments/environment';
import { azureService, getUserRoleName } from '../utils';
import { hasUserPermission } from '../permission';
import Cookies from "js-cookie";

export const AlertSettingComponent = () =>{
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles()
    const [searchTerm,setSearchTerm] = useState('')
    const [filterVisible,setFilterVisible] = useState(false)
    const [selectedPrefix,setSelectedPrefix] = useState('')
    const [prefixes,setPrefixes] = useState([])
    const [users,setUsers] = useState([])
    const [filterUsers,setFilterUsers] = useState([])
    const [isFormOpen,setIsFormOpen] = useState(false)
    const [formType,setFormType] = useState('')
    const [alerts,setAlerts] = useState([])
    const [isEditAlert,setIsEditAlert] = useState(false)
    const [isDelete,setIsDelete] = useState(false)
    const [loading,setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search,setSearch] = useState('')
    const [userRoles,setUserRoles] = useState([])
    const [selectedUserId,setSelectedUserId] = useState('')
    const [selectedUserName,setSelectedUserName] = useState('')
    const [hostUrl,setHostUrl] = useState('')
    const [isUpgrade,setIsUpgrade] = useState(false)
    const [userCount, setUserCount] = useState(0)
    const {isAzureFreePackage, isAzureBasicPackage, isAzurePremiumPackage} = azureService()

    //alert states
    const [alertOpen, setAlertOpen] = useState(false)
    const [severity, setSeverity] = useState("success")
    const [topic, setTopic] = useState("")
    const [message, setMessage] = useState("")

    
    const roleId = Cookies.get('ROLE')
    const existUserId = Cookies.get('USER_ID')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)
        let updatedUser = users.filter(user => user.mobile.includes(value))
        setFilterUsers(updatedUser)
        setPage(0)
    };

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const handleFormClose = () =>{
        setIsFormOpen(false)
    }

    const handleFormOpen = (type,id) =>{
        setIsFormOpen(true)
        setFormType(type)
        if(type == 'edit'){
            setSelectedUserId(id)
        }
    }

    const filterBatch = () =>{  
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = users.filter(user => {
            const matchesPrefix = selectedPrefix === 'All' || user.startsWith(selectedPrefix);
            const matchesSearch = user.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        });
        setFilterUsers(filtered);
        setFilterVisible(false)
    }

    const handleCloseEditAlert = () =>{
        setIsEditAlert(false)
    }

    const handleEditAlerts = async (id) =>{
        try{
            setLoading(true)
            const response = await getNotificationSetup(id)
            if(response.status == 200){
                const alertSetting = response.data.content
                if(alertSetting){
                    setAlerts(alertSetting.notificationTypes)
                }else{
                    setAlerts([])
                }
                
            }
        }catch(error){

        }finally{
            setLoading(false)
        }
        setSelectedUserId(id)
        setIsEditAlert(true)
    }

    const handleDeleteClose = () =>{
        setIsDelete(false)
    }

    const handleDeleteOpen = (id,name,roleId) =>{
        setSelectedUserId(id)
        setSelectedUserName(name)
        setIsDelete(true)
    }

    const handleSave = async () =>{
        const data = {
            userId : selectedUserId,
            referenceId : '123',
            notificationTypes : alerts
        }

        try{
            setLoading(true)
            const response = await setupNotification(data)
            if(response){
                setIsEditAlert(false)
                setAlertOpen(true);
                setSeverity("success");
                setTopic("Success");
                setMessage("Alert added successfully.");
            }
        }catch(error){

        }finally{
            setLoading(false)
            setTimeout(() => setAlertOpen(false), 2000);
        } 
    }

    

    const handleDelete =async () =>{
        try{
            setLoading(true)
            const response = await removeUser(selectedUserId)
            if(response.status == 200){
                setAlertOpen(true);
                setSeverity("success");
                setTopic("Success");
                setMessage("User removed successfully.");
                getAllUser()
                window.dispatchEvent(
                    new CustomEvent('userDeleted', { detail: { selectedUserId } })
                );
            }else{
                setAlertOpen(true);
                setSeverity("warning");
                setTopic("Warning");
                setMessage(response.response.data.message);
            }
            setPage(0)
        }catch(error){

        }finally{
            setIsDelete(false)
            setLoading(false)
            setTimeout(() => setAlertOpen(false), 2000);
        }
    }
    
    const closeUpgrade = () =>{
        setIsUpgrade(false)
    }

    const handleUpgrade = () =>{
        window.open(
            environment.azurePackageUpgradeLink,
            "_blank" // Specifies to open the link in a new tab
        );
        setIsUpgrade(false)
    }

    const dialogConfigs = [
        {
            type: 'alert',
            open: isEditAlert,
            title: 'alerts',
            cancel: 'cancel',
            submit: 'save',
            onClose: handleCloseEditAlert,
            dialogContent: <AlertPopup alerts={alerts} setAlerts={setAlerts}/>,
            dialogWidth: '600px',
            submitButtonColor: '#2A7BEC',
            onSubmit: handleSave,
            buttonRequired : true
        },
        {
            type: 'delete',
            open: isDelete,
            title: 'confirmation',
            cancel: 'cancel',
            submit: 'delete',
            onClose: handleDeleteClose,
            dialogContent: `Are you sure you want to delete ${selectedUserName}?`,
            dialogWidth: '520px',
            submitButtonColor: '#FF4343',
            onSubmit : handleDelete,
            buttonRequired : true
        },
        {
            type: 'addUser',
            open: isFormOpen,
            title: formType === 'add' ? 'Add User' : 'Edit User',
            cancel: 'cancel',
            submit: formType === 'add' ? 'add' : 'edit',
            onClose: handleFormClose,
            dialogContent: (
                <AddUserComponent
                    type={formType === 'add' ? 'alert-add' : 'alert-edit'}
                    onClose={handleFormClose}
                    selectedUserId = {selectedUserId}
                    roles = {userRoles}
                    handleFormClose = {handleFormClose}
                    host = {hostUrl}
                    setFilterUsers = {setFilterUsers}
                    user = {filterUsers}
                    setUsers = {setUsers}
                    getAllUser = {() =>getAllUser()}
                    setIsUpgrade = {setIsUpgrade}
                    setAlertOpen = {setAlertOpen}
                    setSeverity ={setSeverity}
                    setTopic = {setTopic}
                    setMessage = {setMessage}
                />
            ),
            dialogWidth: '900px',
            submitButtonColor: '#FF4343',
            onSubmit : null,
            buttonRequired : false
        },
        {
            type: 'upgrade',
            open: isUpgrade,
            title: 'Upgrade Azure Package',
            cancel: 'cancel',
            submit: 'upgrade',
            onClose: closeUpgrade,
            dialogContent: 'User limit exceeded. Please upgrade your package.',
            dialogWidth: '600px',
            submitButtonColor: '#2A7BEC',
            onSubmit : handleUpgrade,
            buttonRequired : true
        },
    ];

    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);
    
                // Fetch both users and roles simultaneously
                const [meta,users, userRoles] = await Promise.all([
                    fetchUserMetaData(),
                    getAllUser(),
                    fetchUserRole()
                ]);
                const host = meta.data.content.configuration.configHost;
                setHostUrl(host)
                const roles = userRoles.filter(role => (role.id == 20 || role.id == 30))
                setUserRoles(roles);
    
            } catch (error) {
            } finally {
                setLoading(false); 
            }
        };
    
        initializeData();
    }, []);

    const getAllUser = async () =>{
        const users = await fetchUsers()
        const currentUserRole = parseInt(getUserRole()); 
        if(users){
            //hide users that have greater user role
            const filteredUsers = users.filter((u)=>u.userRoleId>currentUserRole)
            setUsers(filteredUsers);
            setUserCount(filteredUsers.length);
            setFilterUsers(filteredUsers);
        }else{
            setUsers([])
            setFilterUsers([])
        }
        
    }
    
    const disableButton = (isAzureFreePackage() && userCount >= 2) || (isAzureBasicPackage() && userCount >= 5) || (isAzurePremiumPackage() && userCount >= 10);


    return(
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px',margin:'0px'}}>
                <Grid className={classes.pageTitle} style={{margin:'0px'}}>User Settings</Grid>
                <Grid container className={classes.backgroundContainer} style={{marginTop:'20px'}}>
                    <Grid item xs={4} md={4} style={{paddingRight:'10px'}}>
                        <SearchBar onChange={handleSearch} placeholder='Search by Phone Number'/>
                    </Grid>
                    { hasUserPermission("ALERT") &&
                        <Grid item xs={8} md={8} style={{display:'flex',justifyContent:'flex-end'}}>
                        
                            <Button variant="contained" 
                                    startIcon={<AddIconTwo color='#FFFFFF'/>} 
                                    style={{color:'#FFFFFF', backgroundColor:'#005B96'}} 
                                    onClick={() => {
                                        if (disableButton) {
                                            setIsUpgrade(true);
                                        } else {
                                            handleFormOpen('add', '');
                                        }
                                    }} 
                                >
                                Add
                            </Button> 
                        </Grid>
                    }
                <Grid style={{
              textAlign: 'left',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '0px',
              justifyContent: 'flex-end'
            }} item xs={12} md={12}>

              <TablePagination style={{margin: '0px'}}
                               rowsPerPageOptions={[]}
                               component="div"
                               count={
                                Array.isArray(filterUsers) && filterUsers.length > 0 
                                    ? filterUsers.filter((dec) => dec.mobile.includes(search.trim())).length 
                                    : 0
                                }
                               rowsPerPage={rowsPerPage}
                               page={page}
                               onPageChange={handleChangePage}
                               onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
                <Grid container className={classes.tableContainer} style={{marginTop:"0px"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={classes.tableHeader}>Name</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Email</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Phone No.</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>User Role</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(filterUsers) && filterUsers.length > 0  ? 
                            (filterUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user,index) =>(
                                <TableRow key={index} style={{backgroundColor: index % 2 == 1 ? '#FFFFFF':'#F5F5F5'}}>
                                    <TableCell className={classes.userName}>
                                        <Tooltip title={user.lastName} arrow>
                                            <span>
                                                {user.lastName.length > 20 ? `${user.lastName.slice(0, 20)}...` : user.lastName}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={user.email}>
                                            <span>
                                                {user.email.length > 20 ? `${user.email.slice(0, 20)}...` : user.email}
                                            </span>                                               
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{user.mobile}</TableCell>
                                    <TableCell>{getUserRoleName(user.userRoleId, userRoles)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton size={'small'} onClick={() => handleFormOpen('edit',user.id)} disabled={roleId > user.userRoleId}>
                                            <EditIconLarge />
                                        </IconButton>
                                        <IconButton size={'small'} onClick={() => handleEditAlerts(user.id)} style={{margin:'0px 10px'}}>
                                            <AlertIcon />
                                        </IconButton>
                                        { hasUserPermission("ALERT") &&
                                            <IconButton size={'small'} className={classes.trashIcon} onClick={() =>handleDeleteOpen(user.id,user.lastName,user.userRoleId)} disabled={roleId > user.userRoleId || user.id === existUserId}>
                                                <TrashIcon />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            )))
                            : (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign:'center' }}>
                                    No User Available
                                </TableCell>
                            </TableRow>
                            )
                        }   
                    </TableBody>
                </Table>
                </Grid>
                </Grid>
            </Grid>
            {dialogConfigs.map((config, index) => (
                <DialogPopup
                    key={index}
                    open={config.open}
                    title={config.title}
                    cancel={config.cancel}
                    submit={config.submit}
                    onClose={config.onClose}
                    dialogContent={config.dialogContent}
                    dialogWidth={config.dialogWidth}
                    submitButtonColor={config.submitButtonColor}
                    type={config.type}
                    onSubmit={config.onSubmit}
                    buttonRequired = {config.buttonRequired} 
                />
            ))}
            <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
            <Loader loading={loading} />
        </ThemeProvider>
    )
}