import {Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, Tooltip, Typography} from '@material-ui/core';
import * as React from 'react';
import {useState, useEffect} from 'react';
import {createStyles, makeStyles, Theme,alpha} from '@material-ui/core/styles';
import {white,primaryBlue} from 'src/components/colors';
import {AddIcon, DeleteIcon, EditIcon, ExpandMoreIcon} from 'src/components/Icons';


export function AddNewRoleAccordingList(props) {
  const classes = useStyles();
  const [show, setShow] = useState(-1)
  const openEdit = (i) => {
    if (i === show) {
      setShow(-1)
    } else {
      setShow(i)
    }
  }

  const handleDeleteIconClick = (id, privilege) => {
    props.onDelete(id, privilege)
  };

  return (
    <div style={{width: "100%"}}>
      {
        props.data.map((child, index) => {
          if (child && child.child) {
            return (
              <div key={index}>
                <Accordion expanded={show === index} elevation={0} key={index}
                          style={{backgroundColor: `rgba(124, 142, 150, ${props.opacity})`, paddingLeft: '20px', paddingRight:'20px'}}
                           TransitionProps={{unmountOnExit: true}} className={classes.subPrivilegeAccording}>
                  <AccordionSummary style={{height: "70px", paddingRight: child.child.length == 0 ? '30px' : '10px'}}
                                    expandIcon={child.child && child.child.length > 0 ? <ExpandMoreIcon/> : <></>}
                                    onClick={() => openEdit(index)}>
                    <Grid container>
                      <Grid item xs={3} md={3}>
                        <Grid container>
                          <Grid item xs={2} md={2} style={{display: "flex", alignItems: "center" }}>
                            <Checkbox className={classes.checkbox}
                                      onChange={() => props.selectPrivileges(child)}
                                      checked={child.selected == true}
                                      onClick={(e) => {
                                        e.stopPropagation(), props.onChange(child)
                                      }}
                                      indeterminate={(child.selected == 'partial')}/>
                          </Grid>
                          <Grid item xs={10} md={10} style={{display: "flex", alignItems: "center"}}>
                            <Tooltip title={child.name} placement="bottom-start" arrow>
                              <Typography
                                style={{textTransform: 'capitalize'}}>{child.name.length > 20 ? (child.name.substring(0, 10) + "...").replace("_", " ") : child.name.replace("_", " ")}</Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={7} xs={7} style={{display: 'flex', overflowX: 'auto'}}>
                        {child.actions ?
                          child.actions.map((action, actionIndex) => {
                            return (
                              <Grid key={actionIndex} item md={4} xs={4} style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'poppins',
                                marginRight: '10px',
                                maxWidth: '150px'
                              }}>
                                <Grid container>
                                  <Grid item>
                                    <Checkbox className={classes.checkbox}
                                              checked={action.selected}
                                              onChange={() => {
                                                props.selectActionsFrom(child, action)
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation(), props.selectActionsFrom(child, action)
                                              }}/>
                                  </Grid>
                                  <Grid item xs={8} md={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography style={{fontFamily: 'poppins'}}>{action.action_name}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          }) : <></>

                        }
                      </Grid>
                      <Grid item xs={2} md={2} style={{display: 'flex', alignItems: 'start', marginTop: "20px"}}>
                        <Grid container justifyContent='flex-end' spacing={1}>
                          <Grid item data-toggle='tooltip' title='Edit'
                                onClick={(e) => props.openAddNew(e, child.id, "edits", {
                                  name: child.name,
                                  id: child.id,
                                  description: child.description,
                                  actions: child.actions
                                })}
                          >
                            <EditIcon stroke={"#324054"}/>
                          </Grid>
                          <Grid item data-toggle='tooltip' title='Delete'
                                onClick={() => {
                                  handleDeleteIconClick(child.id, child)
                                }}
                          >
                            <DeleteIcon/>
                          </Grid>
                          <Grid item style={{display: 'flex', justifyContent: 'end', marginTop: '1px'}}>
                            {props.hover === child.id ?
                              <Typography style={{
                                fontSize: '12px',
                                fontFamily: 'poppins',
                                textAlign: 'center',
                                borderRadius: '5px',
                                backgroundColor: white,
                                color: '#5E5C5C',
                                minWidth: '100px'
                              }}>Add Sub privilege</Typography>
                              : <></>}
                          </Grid>
                          {child.child.length < 5 ?
                            <Grid item style={{display: 'flex', justifyContent: 'end'}}
                                  onClick={(e) => props.openAddNew(e, child.id, "add-new", undefined)}
                                  onMouseEnter={() => props.onHover(child.id)}
                                  onMouseLeave={() => props.removeHover()}
                            >
                               <AddIcon fill={'none'} opacity={1}/>


                            </Grid>
                            :
                            <Grid item style={{display: 'flex', justifyContent: 'end', cursor: 'default' }}data-toggle='tooltip' title='Disabled'>
                              <AddIcon aria-disabled="true" fill="none" opacity="0.5"/>
                            </Grid>
                          }
                        </Grid>

                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails style={{backgroundColor: "white", padding: "0px"}}>
                    <Grid container style={{width: "100%"}}>
                      <AddNewRoleAccordingList allActions={props.allActions} onChange={(id) => props.onChange(id)}
                                               openAddNew={(e, i, type, defaultVal) => props.openAddNew(e, i, type, defaultVal)}
                                               data={child.child} opacity={props.opacity + 0.2} hover={props.hover}
                                               onHover={props.onHover} removeHover={props.removeHover}
                                               padding={props.padding > 11 ? props.padding : props.padding + 5}
                                               currentPrivileges={props.currentPrivileges}
                                               selectActionsFrom={(privilege, action) => props.selectActionsFrom(privilege, action)}
                                               onDelete={(id, privilege) => props.onDelete(id, privilege)} search={props.search}/>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          } else {
            return null;
          }
        })
      }
    </div>
  )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addHighPrivilegesAccording: {
      backgroundColor: "white",
      color: "black",
      marginLeft: "10px",
      marginRight: "10px",
      textTransform: "none",
      marginTop: "5px"
    },
    subIconButtonDiv: {
      backgroundColor: "white",
      width: "25px",
      height: "25px",
      borderRadius: "100%"
    },
    subPrivilegeAccording: {
      width: "100%",
      marginBottom: "1px"
    },
    iconBackground: {
      width: "24px",
      height: "24px",
      borderRadius: "100%",
      backgroundColor: "white"
    },
    checkbox:{
      '&.Mui-checked': {
      color: primaryBlue,
    },
   '&:hover': {
    backgroundColor: alpha(primaryBlue, 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
    },
  }),
);
