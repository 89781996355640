import { Button, createTheme, Grid, IconButton, ThemeProvider, LinearProgress, Box, DialogTitle, Dialog, DialogContent, TextField, Tabs, Tab, FormControlLabel, Checkbox, InputBase } from '@material-ui/core'
import * as React from 'react'
import { ApiUploadIcon, DocumentIcon, PenIcon, TrashIcon, UploadIcon } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { DragDrop } from 'src/components/sub-components/DragAndDrop';
import { Upload, Icon, message, Progress, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { environment } from 'src/environments/environment';
import { Close, CloudUpload, Height } from '@material-ui/icons';
import { TabContext, TabPanel } from "@material-ui/lab";
import { style } from '@angular/animations';
import { ApiConfigurationComponent } from './ApiConfiguration';
import { DialogPopup } from '../components/DialogPopup';
import { InputField } from 'src/app/setup-senzmatica/step-3/components/InputField';
import { getAuthenticationToken, saveToken } from '../components/apiHelper';
import { DropDown } from '../components/DropDown';
import { ToasterComponent } from '../components/Toaster';
import { AddAuthenticationToken } from './Component/AddAuthenticationToken';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});

export const KnowledgeBasedUploadsComponents = ({
    setFiles,
    files,
    errors,
    setErrors,
    setDeleteFileType,
    deleteFileType,
    setIsDeleteConformation,
    isDeleteConformation,
    setLoading,
    setHeaderValue,
    headerValue,
    allTableData,
    setAllTableData,
    selectedBatchNumber,
    jsonFiles,
    setJsonFiles,
    setExistToken,
    existToken,
    getToken
}) => {
    const classes = useStyles();
    const Dragger = Upload.Dragger;
    const [fileList, setFileList] = useState([])
    const [selectedFile, setSelectedFile] = useState({})
    const [selectedFileName, setSelectedFileName] = useState('')
    const [fileIndex, setFileIndex] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [editedFileName, setEditedFileName] = useState('')
    const [inputWidth, setInputWidth] = useState(0); // Default minimum width
    const spanRefs = useRef([]);
    const headers = ['API Upload', 'File Upload']
    const [selectedJsonFile, setselectedJsonFile] = useState(null)
    const [fileData, setFileData] = useState<any>([])
    const [isAddJson, setIsAddJson] = useState(false)
    const [isTokenDialog, setIsTokenDialog] = useState(false)
    const [token, setToken] = useState({ name: "", token: "" })
    const [selectedToken, setSelectedToken] = useState([])
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)
    const [tokenError, setTokenError] = useState({
        tokenNameError: null,
        tokenError: null
    })
    const [isDeleteFile, setIsDeleteFile] = useState(false)
    const [selectedFileIndex, setSelectedFileIndex] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            setFiles(prevFiles =>
                prevFiles.map(file => {
                    if (file.progress >= 100) return file;
                    return { ...file, progress: file.progress + 10 };
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [files]);

    const handleFileChange = (info) => {
        // Filter out any files that already exist in the file list
        const newFiles = info.fileList.filter(file => {
            const existingFile = files.find(f => f.name === file.name);
            return !existingFile; // Only include files that are not already in the state
        }).map(file => ({
            name: file.name,
            size: (file.size / 1024 / 1024).toFixed(2),
            progress: 0,
            file: file.originFileObj,
        }));

        if (newFiles.length > 0) {
            setFileList(prev => [...prev, ...newFiles]);
            setFiles(prev => [...prev, ...newFiles]);
        }
        setErrors(prevError => ({
            ...prevError,
            KnowledgedBase: null
        }))
    };


    const props = {
        name: 'file',
        multiple: false,
        accept: '.pdf',
        onChange: handleFileChange,
        beforeUpload: (file) => {
            const isPdf = file.type === 'application/pdf';
            const isLt5MB = file.size / 1024 / 1024 < 5;
            const { status, name, size } = file;

            if (!isPdf) {
                message.error('You can only upload PDF files!');
                return false;
            }
            // if (!isLt5MB) {
            //   message.error('File must be smaller than 5MB!');
            //   return false; 
            // }
            return false;
        },
        showUploadList: false
    };

    const removeAFile = () => {
        console.log(fileIndex)
        if (deleteFileType == "removeAllFile") {
            setFiles([])
            setIsDeleteConformation(false)
        } else {
            let selectedFiles = [...files]
            selectedFiles.splice(fileIndex, 1)
            setFiles(selectedFiles)
            setIsDeleteConformation(false)
        }
    }

    const handleCancelDelete = () => {
        setIsDeleteConformation(false)
    }

    const handleDeleteButton = (file, index) => {
        setIsDeleteConformation(true)
        setSelectedFile(file)
        setSelectedFileName(file.name)
        setFileIndex(index)
        setDeleteFileType('removeAFile')
    }

    const handleEditIcon = (index, name) => {
        setIsEdit(true)
        setFileIndex(index)
        setEditedFileName(name)
        if (spanRefs.current[index]) {
            setInputWidth(spanRefs.current[index].offsetWidth); // Add padding
        }
    }

    const handleFileNameChange = (e, index) => {
        const fileName = e.target.value
        setEditedFileName(fileName); // Update local state with the new file name
        let updatedFileErrors = { ...errors.fileError }
        if (fileName == '') {
            updatedFileErrors[index] = "File name can't be empty"
        } else {
            updatedFileErrors[index] = null
        }
        const allErrorsNull = Object.values(updatedFileErrors).every(error => error === null);

        setErrors(prevError => ({
            ...prevError,
            fileError: allErrorsNull ? null : updatedFileErrors // Clear fileError if all errors are null
        }));
    };

    const handleBlurOrEnter = () => {
        setIsEdit(false); // Disable edit mode
        handleUpdateFileName(editedFileName, fileIndex); // Save the updated filename
    };

    const handleUpdateFileName = (newName, index) => {
        let updatedFiles = [...files];
        if (newName == '') {
            updatedFiles[index].name = files[index].name;
        } else {
            updatedFiles[index].name = newName;
        }
        setFiles(updatedFiles);
        setErrors(prevError => ({
            ...prevError,
            fileError: null
        }))
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setHeaderValue(newValue);
    };

    const handleAddToken = () => {
        setIsTokenDialog(prev => !prev);
        setTokenError((prev) => ({
            ...prev,
            tokenNameError: null,
            tokenError: null
        }))
    }

    const handleToken = (type, value) => {
        setToken(prev => ({
            ...prev,
            [type]: value
        }))
        if (type == 'name') {
            setTokenError((prev) => ({
                ...prev,
                tokenNameError: null
            }))
        } else if (type == 'token') {
            setTokenError((prev) => ({
                ...prev,
                tokenError: null
            }))
        }
    }

    const handleDeletePopup = (index) => {
        setIsDeleteFile(true)
        setSelectedFileIndex(index)
    }

    const cancelDeletePopup = () => {
        setIsDeleteFile(false)
    }

    const deleteFile = (index) => {
        const updatedFiles = [...jsonFiles];
        const selectedFileName = updatedFiles[selectedFileIndex].name
        const selectedApi = allTableData.filter((data) => data.fileName !== selectedFileName)
        console.log(selectedApi)
        setAllTableData(selectedApi)
        updatedFiles.splice(index, 1);
        setJsonFiles(updatedFiles);
        const token = [...selectedToken]
        token.splice(index,1)
        setSelectedToken(token)
        cancelDeletePopup()
    };

    const handleSelectApi = (fileIndex, pathIndex) => {
        const fileName = jsonFiles[fileIndex].name;
        const apiData = fileData[fileIndex][pathIndex];
        const apiName = apiData.apiName;
        const dataWithAuth = {
            ...apiData,
            authenticationToken: selectedToken[fileIndex] && selectedToken[fileIndex][pathIndex] ? selectedToken[fileIndex][pathIndex] : ''
        }
        setAllTableData((prev) => {
            let newTableData = [...prev];
            const fileEntryIndex = newTableData.findIndex((entry) => entry.fileName === fileName);

            if (fileEntryIndex !== -1) {
                const existingApis = newTableData[fileEntryIndex].apis;
                const apiIndex = existingApis.findIndex((api) => api.apiName === apiName);

                if (apiIndex !== -1) {
                    existingApis.splice(apiIndex, 1);
                    if (existingApis.length === 0) {
                        newTableData.splice(fileEntryIndex, 1);
                    }
                } else {
                    existingApis.push(dataWithAuth);
                }
            } else {
                newTableData.push({
                    fileName: fileName,
                    apis: [dataWithAuth],
                });
            }
            return newTableData;
        });
    };

    // Select all checkboxes
    const handleSelectAll = (fileIndex) => {
        const fileName = jsonFiles[fileIndex].name;
        const apiData = fileData[fileIndex]; // All APIs in the selected file
        const updatedApiData = apiData.map((api, pathIndex) => ({
            ...api,
            authenticationToken: selectedToken[fileIndex] && selectedToken[fileIndex][pathIndex] ? selectedToken[fileIndex][pathIndex] : '', // Assign token if exists
        }));
        setAllTableData((prev) => {
            let tableData = [...prev];
            const fileEntryIndex = tableData.findIndex((entry) => entry.fileName === fileName);

            if (fileEntryIndex !== -1) {
                // File exists, check if all APIs are already selected
                const isAllSelected = updatedApiData.every(api =>
                    tableData[fileEntryIndex].apis.some(existingApi => existingApi.apiName === api.apiName)
                );

                if (isAllSelected) {
                    // Remove the file if all its APIs are already selected (toggle off)
                    tableData.splice(fileEntryIndex, 1);
                } else {
                    // Add only missing APIs
                    const updatedApis = [...tableData[fileEntryIndex].apis];

                    updatedApiData.forEach(api => {
                        if (!updatedApis.some(existingApi => existingApi.apiName === api.apiName)) {
                            updatedApis.push(api);
                        }
                    });

                    tableData[fileEntryIndex].apis = updatedApis;
                }
            } else {
                // File is not in the table, add all its APIs
                tableData.push({
                    fileName: fileName,
                    apis: updatedApiData,
                });
            }
            return tableData;
        });
    };


    useEffect(() => {
        // Create an array to store the table data
        let tableDataArray = [];

        jsonFiles.forEach((file, fileIndex) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const result = (event.target as FileReader).result;
                    const jsonData = JSON.parse(result as string);

                    let dataArray = [];

                    Object.entries(jsonData.paths || {}).forEach(([path, methods], pathIndex) => {
                        for (const [method, details] of Object.entries(methods)) {
                            if (details.operationId) {
                                const tableData =
                                {
                                    apiName: details.operationId ? details.operationId : '',
                                    description: details.description ? details.description : '',
                                    batchNumber: selectedBatchNumber,
                                    serverUrl: jsonData.servers ? jsonData.servers[0].url : "",
                                    authenticationToken: (
                                        <DropDown
                                            options={existToken ? existToken.map((token) => token.name) : []}
                                            type="token"
                                            emptyTag="Select a token"
                                            onClick={(e) => handleSelectToken(fileIndex, pathIndex, e, details.operationId)}
                                            value={selectedToken[fileIndex] && selectedToken[fileIndex][pathIndex] ? selectedToken[fileIndex][pathIndex] : ''}
                                            isSingle={true}
                                        />
                                    ),
                                    source: file.name,
                                    payload: JSON.stringify({ [path]: methods }),  // Keep the path and methods in the table data
                                }
                                dataArray.push(tableData);
                            }
                        }
                    })
                    tableDataArray.push(dataArray);
                    if (fileIndex === jsonFiles.length - 1) {
                        console.log(tableDataArray)
                        setFileData(tableDataArray);
                    }
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            };

            reader.readAsText(file); // Start reading the file
        });
    }, [jsonFiles, selectedToken, allTableData]);

    const handleSelectToken = (fileIndex, pathIndex, selectedTokenValue, apiName) => {
        setSelectedToken((prev) => {
            const updated = [...prev];
            if (updated[fileIndex]) {
                updated[fileIndex] = {
                    ...updated[fileIndex],
                    [pathIndex]: selectedTokenValue,
                };
            } else {
                // If the fileIndex doesn't exist, add a new entry
                updated[fileIndex] = { [pathIndex]: selectedTokenValue };
            }
            return updated;
        });

        setAllTableData((prev) => {
            let newTableData = [...prev];
            const fileEntryIndex = newTableData.findIndex((entry) => entry.fileName === jsonFiles[fileIndex].name);
            if (fileEntryIndex !== -1) {
                const existingApis = newTableData[fileEntryIndex].apis;
                const apiIndex = existingApis.findIndex((api) => api.apiName === apiName);

                if (apiIndex !== -1) {
                    // Update the authentication token for the selected API
                    existingApis[apiIndex] = {
                        ...existingApis[apiIndex],
                        authenticationToken: selectedTokenValue,
                    };
                }
            }
            return newTableData;
        });
    };

    const handleJsonUpload = (event) => {
        setIsAddJson(false);
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        const file = event.target.files[0];
        const isDuplicate = jsonFiles.some(
            (json) => json.name.trim().toLowerCase() === file.name.trim().toLowerCase()
        );
        if (isDuplicate) {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage("This file has already been attached.");
            event.target.value = "";
        } else {
            setselectedJsonFile(file);
        }
        setTimeout(() =>{
            setIsToasterOpen(false)
        },2500)
    };

    useEffect(() => {
        if (isToasterOpen) {
            const timer = setTimeout(() => {
                setIsToasterOpen(false);
            }, 3000);

            return () => clearTimeout(timer); // Cleanup function
        }
    }, [isToasterOpen]);

    const handleAddJson = () => {
        setIsAddJson(true)
        setJsonFiles((prev) => ([
            ...prev,
            selectedJsonFile
        ]))
        setselectedJsonFile(null)
    }

    const tokenValidate = () => {
        let error: any = {}
        if (token.name == "") {
            error.tokenNameError = "Token name required."
        }

        if (token.token == "") {
            error.tokenError = "Token required"
        }

        return error
    }

    return (
        <ThemeProvider theme={theme} >
            <div className='testing'>
                <Grid container direction='column'>
                    <TabContext value={headerValue.toString()}>
                        <Box sx={{ bgcolor: "background.paper", margin: "0px" }}>
                            <Tabs value={headerValue.toString()} onChange={handleChange}>
                                <Tab label="API UPLOAD" value={"0"} />
                                <Tab label="FILE UPLOAD" value={"1"} />
                            </Tabs>
                            <TabPanel value="0" style={{ padding: "24px 0px", minHeight: '340px', overflowY: 'auto' }}>
                                <ApiConfigurationComponent
                                    handleJsonUpload={handleJsonUpload}
                                    jsonData={fileData}
                                    isAddJson={isAddJson}
                                    handleAddJson={handleAddJson}
                                    handleAddToken={handleAddToken}
                                    selectedJsonFile={selectedJsonFile}
                                    jsonFiles={jsonFiles}
                                    handleDeletFile={handleDeletePopup}
                                    handleSelectAll={handleSelectAll}
                                    handleSelectApi={handleSelectApi}
                                    allTableData={allTableData}
                                    isAddToken = {true}
                                    describe = {true}
                                />
                            </TabPanel>

                            <TabPanel value="1" key={headerValue} style={{ padding: "24px 0px" }}>
                                <Grid container>
                                    {files.length != 0 &&
                                        <Grid container>
                                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                <Upload {...props} accept='.pdf'>
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<UploadIcon color='#2A7BEC' />}
                                                        className={classes.button}
                                                        style={{ borderColor: '#2A7BEC', color: '#2A7BEC' }}
                                                    >
                                                        Attach Files
                                                    </Button>
                                                </Upload>
                                            </Grid>

                                            <Grid container style={{ marginTop: '15px', maxHeight: '450px', overflowY: 'auto' }}>
                                                {files && (
                                                    files.map((file, index) => (
                                                        <Grid container key={index}>
                                                            <Grid container key={index} direction='column' style={{ border: '1px solid #CACACA', borderRadius: '4px', padding: '10px', marginTop: '15px' }}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Grid container justifyContent='space-between'>
                                                                        <Grid item style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                                                                            <DocumentIcon />
                                                                            {isEdit && fileIndex == index ? (
                                                                                <TextField
                                                                                    value={editedFileName}
                                                                                    onChange={(e) => handleFileNameChange(e, index)}
                                                                                    onBlur={handleBlurOrEnter} // Save on blur (click outside)
                                                                                    onKeyPress={(e) => {
                                                                                        if (e.key === 'Enter') handleBlurOrEnter(); // Save on Enter key press
                                                                                    }}
                                                                                    autoFocus // Automatically focus the input when editing
                                                                                    InputProps={{
                                                                                        style: { width: `${inputWidth}px`, margin: '0px 10px', }, // Set width for input element
                                                                                    }}
                                                                                />
                                                                            )
                                                                                :
                                                                                (
                                                                                    <span ref={(el) => (spanRefs.current[index] = el)} style={{ color: '#353535', textAlign: 'center', fontFamily: 'Poppins', margin: '0 10px' }}>
                                                                                        {file.name}
                                                                                    </span>
                                                                                )

                                                                            }
                                                                            <IconButton size="small" onClick={() => handleEditIcon(index, file.name)}>
                                                                                <PenIcon />
                                                                            </IconButton>

                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton size="small" onClick={() => handleDeleteButton(file, index)}>
                                                                                <TrashIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item style={{ color: '#B4B2B2', textAlign: 'center', fontFamily: 'Poppins', display: 'flex' }}>
                                                                    {`${file.size} MB`}
                                                                </Grid>
                                                            </Grid>
                                                            {errors.fileError &&
                                                                <Grid item xs={12} style={{ padding: "0px 8px", textAlign: 'left' }}>
                                                                    <Typography className={classes.errorText} >
                                                                        {errors.fileError[index]}
                                                                    </Typography>
                                                                </Grid>
                                                            }

                                                        </Grid>
                                                    ))
                                                )}
                                            </Grid>


                                        </Grid>
                                    }

                                    {files.length == 0 &&
                                        <Grid container>
                                            <Grid item className={classes.dragDrop} style={{ borderColor: errors.KnowledgedBase ? 'red' : '', width: '100%' }}>
                                                <Dragger {...props} accept='.pdf' onChange={handleFileChange}>
                                                    <DragDrop />
                                                    <p className={classes.dragText}>Click or drag file to this area to upload</p>
                                                    <p className="ant-upload-text" style={{ color: '#B4B2B2', textAlign: 'center' }}>maximum upload file size : 5MB</p>
                                                </Dragger>
                                            </Grid>
                                            {errors.KnowledgedBase &&
                                                <Grid item xs={12} style={{ padding: "0px 8px", textAlign: 'left' }}>
                                                    <Typography className={classes.errorText} >
                                                        {errors.KnowledgedBase}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Grid>
                <DeleteFileDialog
                    open={isDeleteConformation}
                    fileName={selectedFileName}
                    onClose={handleCancelDelete}
                    onDelete={removeAFile}
                    type={deleteFileType}
                />
                <AddAuthenticationToken
                    setLoading={setLoading}
                    setIsTokenDialog={setIsTokenDialog}
                    getToken={getToken}
                    isTokenDialog={isTokenDialog}
                    setToasterMessage = {setToasterMessage}
                    setIsToasterOpen = {setIsToasterOpen}
                    setReqSuccess = {setReqSuccess}
                    existTokens = {existToken}
                />

                <DialogPopup
                    open={isDeleteFile}
                    title="Delete File"
                    onClose={cancelDeletePopup}
                    buttonRequired={true}
                    cancel='cancel'
                    submit='delete'
                    submitButtonColor='red'
                    dialogContent="Do you want to delete this file?"
                    onSubmit={deleteFile}
                />

                <ToasterComponent
                    reqSuccess={reqSuccess}
                    message={toasterMessage}
                    toaster={isToasterOpen}
                />

            </div>
        </ThemeProvider>
    )
}


export const DeleteFileDialog = ({ open, fileName, onClose, onDelete, type }) => {
    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014' }}>
                <Grid container justifyContent='space-between'>
                    <Typography>DELETE CONFORMATION</Typography>
                    <IconButton size='small' onClick={onClose}>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container style={{ marginTop: '10px' }}>
                    <Grid item>
                        {type == "removeAllFile" ? "Are you sure you want to remove all files from this project ?" : `Are you sure you want to remove this file from this project ?`}
                    </Grid>
                    <Grid item xs={12} md={12} style={{ margin: '10px 0' }}>
                        <Grid container justifyContent='flex-end'>
                            <Button variant='outlined' style={{ marginRight: '10px' }} onClick={onClose}>No</Button>
                            <Button variant='contained' style={{ backgroundColor: 'red', color: 'white' }} onClick={onDelete}> Yes</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}