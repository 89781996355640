import * as React from "react";
import { Box, Button, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { DropDown } from "../../components/DropDown";
import useStyles from "src/app/maintenance/assets/styles";
import {useState, useEffect} from 'react';
import { DocumentIcon } from "src/components/Icons";
import { DescriptionComponent } from "./Description";
import { getAvailableModels } from "../../components/apiHelper";


const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function SelectAModelComponent({setFormValue,errors,setErrors}) {
  const classes = useStyles();
  const [selectedMOdel,setSelectedModel] = useState("")
  const [showDescription,setShowDescription] = useState(false)

  const [models, setModels] = useState([])
 
  const handleSelectModel = (value) =>{
    setSelectedModel(value)
    setFormValue(prevValue =>({
      ...prevValue,
      "model":value,
      "modelUrl":models.filter((m)=>m.model_name===value)[0]["model_path"]
    }))
    setErrors(prevError => ({
      ...prevError,
      "model": null
    }))

  }
  const showDescriptionDialog = () =>{
    setShowDescription(true); 
  }
  const handleCloseWindow = () =>{
    setShowDescription(false)
  }

  useEffect(()=>{
    const getAvailableModel = async () => {
      try {
        const models = await getAvailableModels({
          "projectname": "RootCause"
        });
        if(models && models.status==="success"){
          let modelData = []
          let keys = Object.keys(models).filter((k)=>k!=="status")
          keys.map((k)=>{
            modelData.push(models[k])
          })
          setModels(modelData);
        }
      } catch (err) {
        console.error('Error fetching batch numbers:', err);
      }
  };
  getAvailableModel()
  },[])

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>

      <Grid container className={classes.root}>
        <Grid container className={classes.formContent}>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Model
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
                <DropDown
                    options={models.map((m)=>m.model_name)}
                    type='model'
                    emptyTag='Select Model'
                    setSelectOption={setSelectedModel}
                    value={selectedMOdel}
                    // handleError={handleError}
                    onClick= {handleSelectModel}
                    isSingle={true}
                    icon ={<DocumentIcon />}
                    iconClick = {showDescriptionDialog}
                  />
                  {errors.model &&
                    <Grid item xs={12} style={{ padding: "0px 8px" }}>
                      <Typography className={classes.errorText}>
                        {errors.model}
                      </Typography>
                    </Grid>
                  }
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      {showDescription && 
        <DescriptionComponent 
          open={showDescription} 
          handleCloseWindow={handleCloseWindow}
        />

      }   
        
      </div>
    </ThemeProvider>
  );
}
