import * as React from 'react';
import { Dialog, DialogTitle, Box, IconButton, DialogContent, Tab, Tabs } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Chart} from 'chart.js';
import { Typography } from 'antd';

export const DateAndSummeryReport = ({
  isClick,
  handleWindowClose,
  headers,
  data
}) => {

  const [value, setValue] = useState(0);

  const SelectSubTask = (tabHeaders: { tabHeaders: string[] }) => {
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    const filterSensorValue = data.filter(sensor => {
      return sensor.subTestCase.toLowerCase().includes(headers[value].toLowerCase());
    });

    return (
      <TabContext value={value.toString()}>
        <Box sx={{ bgcolor: 'background.paper' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="off"
          >
            {headers.map((head, index) => (
              <Tab
                key={index}
                label={head}
                style={{ border: '1px solid #F4F4F4' }}
              />
            ))}
          </Tabs>
          <TabPanel
            value={value.toString()}
            style={{border: 'none', boxShadow: 'none' }}
          >
          {filterSensorValue[0] && filterSensorValue[0].sensorReadings.length != 0 ?
            <LineChart data={data} headers={headers} value={value}/>
            :
            <Typography style={{height:'200px',textAlign:'center',border:'1px solid #F4F4F4',alignContent:'center'}}>No Sensor Readings</Typography>
          } 
          </TabPanel>
        </Box>
      </TabContext>
    );
  };

  const LineChart = ({ data, headers, value }) => {
    let isTooLarge = false
    useEffect(() => {
      const canvas = document.getElementById('myChart') as HTMLCanvasElement;
      if (!canvas) return;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
  
      const filterSensorValue = data.filter(sensor => {
        return sensor.subTestCase.toLowerCase().includes(headers[value].toLowerCase());
      });
  
      if (filterSensorValue.length > 0 && filterSensorValue[0]) {
        const sensorReadings = filterSensorValue[0].sensorReadings.map(read => Number(read));
        const maxValue = Math.max(...sensorReadings) < Number(filterSensorValue[0].maxVal) ? Number(filterSensorValue[0].maxVal) : Math.max(...sensorReadings);
        const minValue = Math.min(...sensorReadings) > Number(filterSensorValue[0].minVal) ? Number(filterSensorValue[0].minVal) : Math.min(...sensorReadings);
        const dateTime = filterSensorValue[0].dateTime;
        if(minValue > 1000000 || maxValue > 1000000){
          isTooLarge = true
        }
  
        if (ctx) {
          new Chart(ctx, {
            type: 'line',
            data: {
              labels: dateTime,
              datasets: [
                {
                  label: headers[value],
                  data: sensorReadings,
                  borderColor: '#5E5C5C',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  fill: false,
                  tension: 0.1,
                  pointBackgroundColor: '#2A7BEC',
                  pointBorderColor: '#2A7BEC',
                  pointBorderWidth: 1,
                  pointRadius: 3,
                },
              ],
            },
            options: {
              scales: {
                xAxes: [{
                  display: true,
                  title: {
                    display: true,
                    text: 'Time',
                  },
                }],
                yAxes: [{
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: headers[value],  // Axis label based on your headers
                  },
                  ticks: {
                    beginAtZero: true,  // Start the y-axis from 0
                    min:  isTooLarge ? Math.ceil(Number(Math.min(...sensorReadings))) : Math.ceil(minValue /10) *10 - 10,  // Minimum value for the y-axis
                    max:  isTooLarge ? Number(Math.max(...sensorReadings)) == 0 ? 10 :  Math.ceil(Number(Math.max(...sensorReadings))) : Math.ceil(maxValue /10) *10  + 10,  // Maximum value for the y-axis
                    stepSize: 10,  // Distance between ticks
                  },
                }],
              },
              plugins: {
                tooltip: {
                  mode: 'index',
                },
                legend: {
                  display: true,
                },
              },
            },
            plugins: [
              {
                afterDraw: (chartInstance) => {
                  const { left, right } = chartInstance.chartArea;
                  if (!isTooLarge && chartInstance.scales['y-axis-0'] && filterSensorValue[0].minVal) {
                    const yValue1 = chartInstance.scales['y-axis-0'].getPixelForValue(Number(filterSensorValue[0].minVal))
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(left, yValue1);
                    ctx.lineTo(right, yValue1);
                    ctx.strokeStyle = '#949494';
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.font = '12px Poppins';
                    ctx.fillStyle = '#C5C3C3';
                    ctx.fillText('Minimum Threshold Level 1', left + 50, yValue1 - 10);
                    ctx.restore();
                  }
                  if (!isTooLarge && chartInstance.scales['y-axis-0'] && filterSensorValue[0].maxVal && filterSensorValue[0].minVal !== filterSensorValue[0].maxVal) {
                    const yValue2 = chartInstance.scales['y-axis-0'].getPixelForValue(Number(filterSensorValue[0].maxVal));
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(left, yValue2);
                    ctx.lineTo(right, yValue2);
                    ctx.strokeStyle = '#949494';
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.font = '12px Poppins';
                    ctx.fillStyle = '#C5C3C3';
                    ctx.fillText('Maximum Threshold Level 1', left + 50, yValue2 - 10);
                    ctx.restore();
                  }
                },
              },
            ],
          });
        }
      }
    }, [data, headers, value]);
  
    return <canvas id="myChart"></canvas>;
  };

  return (
    <div>
      <Dialog open={isClick} onClose={handleWindowClose} maxWidth="lg" fullWidth>
        <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <span>{'Date & Time Summery Report'.toLocaleUpperCase()}</span>
            <IconButton
              onClick={handleWindowClose}
              style={{ paddingRight: '0px' }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ width: '100%' }}>
          <SelectSubTask tabHeaders={headers} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
