import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {LoginResponse} from '../../models';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginResponse: LoginResponse;
  loginError: string;
  form: FormGroup;
  isSubmitted;
  public showPassword: boolean;
  isDialog = false;
  rememberMe = false;

  constructor(private authService: AuthService, protected router: Router, private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService, private http: HttpClient) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      console.log('Token:', token);
      this.signInWithAzure(token);
    }
  }

  signIn(auth) {
    this.isSubmitted = true;

    if (this.form.valid) {
      this.loginWithUserName(auth, this.rememberMe);
    }
  }

  signInWithAzure(token?: String) {
    console.log("Login with azure");

    const { clientId, tenantId } = environment.azureAd;
    const redirectUri = `${window.location.origin}/callback`;

    const state = token ? `12345|${token}` : '12345';

    const authUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize` +
    `?client_id=${clientId}` +
    `&response_type=code` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}` +
    `&response_mode=query` +
    `&scope=openid profile email User.Read` +
    `&state=${state}`;
    window.location.href = authUrl;
  }

  async loginWithUserName(auth, rememberMe) {
    const skipOauth = 'skipOauth' in environment ? environment.skipOauth : false;
    if (skipOauth) {
      if (auth.username === "super" && auth.password === "bruntha1234") {
        AuthService.loadCredential(JSON.parse("{\n" +
          "    \"access_token\": \"7abd8210-690c-4557-b61c-fc6ab3fa8321\",\n" +
          "    \"token_type\": \"bearer\",\n" +
          "    \"refresh_token\": \"78f71a4f-a850-4df4-94c4-945d54bb08b4\",\n" +
          "    \"expires_in\": 25583521,\n" +
          "    \"scope\": \"trust read write\",\n" +
          "    \"role\": 1,\n" +
          "    \"references\": [\n" +
          "        \"5d9585bd2d3b874485c2e75a\"\n" +
          "    ],\n" +
          "    \"name\": \"SUPER ADMIN\",\n" +
          "    \"id\": \"5db07c302d3b8739d56b149d\",\n" +
          "    \"type\": null,\n" +
          "    \"authorities\": []\n" +
          "}"));
        await this.router.navigate(['']);
      } else {
        this.loginError = 'Invalid credentials';
      }
      return;
    }

    auth.grant_type = 'password';
    auth.client_id = environment.client_id;
    auth.client_secret = environment.client_secret;
    this.spinner.show();
    await this.authService.loginWithUserName(auth, rememberMe).subscribe(
      (response) => {
        this.loginResponse = response;
        console.log(response);
        AuthService.loadCredential(this.loginResponse);
        if (response.role < 10) {
          this.router.navigate(['']);
        } else if ( response.role >= 10 && response.role < 100) {
          this.router.navigate(this.authService.isTestAutomationUser() ?['/ResultDashboard'] :['/TestAutomation']);
        } else {
          this.loginError = 'You don\'t have permission';
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.loginError = error.error.error_description;
      });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  toggleRememberMe() {
    this.rememberMe = !this.rememberMe;
  }

}
