import * as React from "react";
import {useState} from "react";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  NativeSelect,
  Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, styled, Theme} from "@material-ui/core/styles";
import {primaryBlue, primaryGray, textBarColor, white,} from "src/components/colors";
import {BootstrapInput} from "../../product-types/add-new-firmware/steps/Step1";
import {environment} from "src/environments/environment";
import {AddIconTwo, DeleteIcon, EditIconTwo, ExpandIconTwo, ViewIconTwo} from "src/components/Icons";

export function AddDeviceParameterConfiguration(props) {
  const classes = useStyles();
  const [viewParameter, setViewParameter] = useState({
    Network_and_Communication: false,
    Topic_Format_and_Interval: false,
    Message_Format: false,
  });
  const [selectedParameter, setSelectedParameter] = useState({
    networkParameter: {
      parameter: "",
      parameterId: "",
      inputType: "",
      format: "",
      defaultValue: "",
      joinParameter: "",
      parameterCategory: "",
    },
    topicParameter: {
      parameter: "",
      parameterId: "",
      inputType: "",
      format: "",
      defaultValue: "",
      joinParameter: "",
      parameterCategory: "",
    },
    messageParameter: {
      parameter: "",
      parameterId: "",
      inputType: "",
      format: "",
      defaultValue: "",
      joinParameter: "",
      parameterCategory: "",
    },
  });
  const openView = (parameter) => {
    if (parameter.parameterCategory === "Network & Communication") {
      setViewParameter({
        ...viewParameter,
        Network_and_Communication: true,
      })
      setSelectedParameter({
        ...selectedParameter,
        networkParameter: parameter,
      })
    } else if (parameter.parameterCategory === "Topic Format & Interval") {
      setViewParameter({
        ...viewParameter,
        Topic_Format_and_Interval: true,
      })
      setSelectedParameter({
        ...selectedParameter,
        topicParameter: parameter,
      })
    } else {
      setViewParameter({
        ...viewParameter,
        Message_Format: true,
      })
      setSelectedParameter({
        ...selectedParameter,
        messageParameter: parameter,
      })
    }
  };

  const closeView = (type) => {
    if (type === "Network & Communication") {
      setViewParameter({
        ...viewParameter,
        Network_and_Communication: false,
      })
    } else if (type === "Topic Format & Interval") {
      setViewParameter({
        ...viewParameter,
        Topic_Format_and_Interval: false,
      })
    }
    else {
      setViewParameter({
        ...viewParameter,
        Message_Format: false,
      })
    }
  }
  

  return (
    <Grid container className={classes.container}>
      <Grid container style={{ backgroundColor: 'white', alignItems: 'center' }}>
      <Grid
        item
        xs={5}
        md={5}
        style={{textAlign: "left"}}
      >
        <Typography style={{ paddingLeft: '20px' }}>
          Network & Communication
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openNetworkVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.networkVisibility}/>
          </div>
        </IconButton>
      </Grid>

        {props.networkVisibility && (
          <Grid container style={{ padding: '20px' }}>
            {!viewParameter.Network_and_Communication && (
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "right"}}>
            <Button
              className={classes.addButton}
              variant="outlined"
              onClick={() =>
                props.open("Network & Communication", "add-new", -1)
              }
              startIcon={<AddIconTwo/>}
            >
              ADD
            </Button>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Network & Communication"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
                  <Typography className={classes.topic} style={{ paddingLeft: '40px' }}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Network & Communication"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{textAlign: "center", paddingTop: "25px"}}
          >
                  <Typography className={classes.topic} style={{ textAlign: 'end', paddingRight: '60px' }}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Network & Communication" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                          <Typography style={{ paddingLeft: "50px" }}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "end", paddingRight: '60px'}}>
                    <IconButton
                      style={{padding: "0px"}}
                      onClick={() =>
                              openView(parameter)
                      }
                    >
                      <ViewIconTwo/>
                    </IconButton>
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Network & Communication", "edit", i)
                      }
                    >
                            <EditIconTwo />
                          </IconButton>
                          <IconButton
                            style={{ marginLeft: "10px", padding: "0px" }}
                            onClick={() => props.deleteParameter(i)}
                          >
                            <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
            )}
            {viewParameter.Network_and_Communication === true && (
              <Grid container>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Parameter Name</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Parameter ID</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Input Type</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Data Format</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Default Value</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Join Parameter</Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.networkParameter.parameter != "" ? selectedParameter.networkParameter.parameter : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.networkParameter.parameterId != "" ? selectedParameter.networkParameter.parameterId : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.networkParameter.inputType != "" ? selectedParameter.networkParameter.inputType : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.networkParameter.format != "" ? selectedParameter.networkParameter.format : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.networkParameter.defaultValue != "" ? selectedParameter.networkParameter.defaultValue : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.networkParameter.joinParameter != "" ? selectedParameter.networkParameter.joinParameter : "N/A"}
                  </Grid>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
                    <Button className={classes.backButton} variant="contained" onClick={() => closeView("Network & Communication")}>Back</Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
      )}
      </Grid>


      <Grid container style={{ backgroundColor: 'white', alignItems: 'center', marginTop: '20px'}}>
      <Grid
        item
        xs={5}
        md={5}
          style={{textAlign: "left"}}
      >
        <Typography style={{paddingLeft: '20px'}}>
          Topic Format & Interval
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openTopicVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.topicVisibility}/>
          </div>
        </IconButton>
      </Grid>

        {props.topicVisibility && (
          <Grid container style={{ padding: '20px' }}>
            {!viewParameter.Topic_Format_and_Interval && (
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "right"}}>
            <Button
              className={classes.addButton}
              variant="outlined"
              onClick={() =>
                props.open("Topic Format & Interval", "add-new", -1)
              }
              startIcon={<AddIconTwo/>}
            >
              ADD
            </Button>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Topic Format & Interval"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
                  <Typography className={classes.topic} style={{ paddingLeft: '40px' }}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Topic Format & Interval"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{textAlign: "center", paddingTop: "25px"}}
          >
                  <Typography className={classes.topic} style={{ textAlign: 'end', paddingRight: '60px' }}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Topic Format & Interval" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{paddingLeft: "50px"}}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "end", paddingRight: '60px' }}>
                    <IconButton
                      style={{padding: "0px"}}
                            onClick={() => openView(parameter)
                      }
                    >
                      <ViewIconTwo/>
                    </IconButton>
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() =>
                        props.open("Topic Format & Interval", "edit", i)
                      }
                    >
                            <EditIconTwo />
                          </IconButton>
                          <IconButton
                            style={{ marginLeft: "10px", padding: "0px" }}
                            onClick={() => props.deleteParameter(i)}
                          >
                            <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
            )}
            {viewParameter.Topic_Format_and_Interval === true && (
              <Grid container>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Parameter Name</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Parameter ID</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Input Type</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Data Format</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Default Value</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Join Parameter</Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.topicParameter.parameter != "" ? selectedParameter.topicParameter.parameter : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.topicParameter.parameterId != "" ? selectedParameter.topicParameter.parameterId : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.topicParameter.inputType != "" ? selectedParameter.topicParameter.inputType : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.topicParameter.format != "" ? selectedParameter.topicParameter.format : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.topicParameter.defaultValue != "" ? selectedParameter.topicParameter.defaultValue : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.topicParameter.joinParameter != "" ? selectedParameter.topicParameter.joinParameter : "N/A"}
                  </Grid>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
                    <Button className={classes.backButton} variant="contained" onClick={() => closeView("Topic Format & Interval")}>Back</Button>
                  </Grid>
                </Grid>
              </Grid>
            )
            }
          </Grid>
        )}
      </Grid>


      <Grid container style={{ backgroundColor: 'white', alignItems: 'center', marginTop: '20px' }}>
      <Grid
        item
        xs={5}
        md={5}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
          <Typography style={{ paddingLeft: '20px' }}>Message Format</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{textAlign: "left", paddingTop: "20px"}}
      >
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openMessageVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.messageVisibility}/>
          </div>
        </IconButton>
      </Grid>

        {props.messageVisibility && (
          <Grid container style={{ padding: '20px' }}>
            {!viewParameter.Message_Format && (
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "right"}}>
            <Button
              className={classes.addButton}
              variant="outlined"
              onClick={() => props.open("Message Format", "add-new", -1)}
              startIcon={<AddIconTwo/>}
            >
              ADD
            </Button>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Message Format"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
                  <Typography className={classes.topic} style={{ paddingLeft: '40px' }}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "MessageFormat"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{textAlign: "center", paddingTop: "25px"}}
          >
                  <Typography className={classes.topic} style={{ textAlign: 'end', paddingRight: '60px' }}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Message Format" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{paddingLeft: "50px"}}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "end", paddingRight: '60px' }}>
                    <IconButton
                      style={{padding: "0px"}}
                            onClick={() => openView(parameter)}
                    >
                      <ViewIconTwo/>
                    </IconButton>
                    <IconButton
                      style={{marginLeft: "10px", padding: "0px"}}
                      onClick={() => props.open("Message Format", "edit", i)}
                    >
                            <EditIconTwo />
                          </IconButton>
                          <IconButton
                            style={{ marginLeft: "10px", padding: "0px" }}
                            onClick={() => props.deleteParameter(i)}
                          >
                            <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
            )}
            {viewParameter.Message_Format === true && (
              <Grid container>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Parameter Name</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Parameter ID</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Input Type</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Data Format</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Default Value</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className={classes.topic} style={{ color: primaryGray, fontSize: "14px", }} >Join Parameter</Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.messageParameter.parameter != "" ? selectedParameter.messageParameter.parameter : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.messageParameter.parameterId != "" ? selectedParameter.messageParameter.parameterId : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.messageParameter.inputType != "" ? selectedParameter.messageParameter.inputType : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.messageParameter.format != "" ? selectedParameter.messageParameter.format : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.messageParameter.defaultValue != "" ? selectedParameter.messageParameter.defaultValue : "N/A"}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {selectedParameter.messageParameter.joinParameter != "" ? selectedParameter.messageParameter.joinParameter : "N/A"}
                  </Grid>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
                    <Button className={classes.backButton} variant="contained" onClick={() => closeView("Message Format")}>Back</Button>
                  </Grid>
                </Grid>
              </Grid>
            )
            }
          </Grid>
        )}
      </Grid>

      {props.parametersAddedError === true && props.parameters.length===0 &&(
        <FormHelperText className={classes.helperText}>
          Remote Configurations not added *
        </FormHelperText>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    container: {
      paddingTop: "20px",
    },
    clone: {
      fontSize: "14px",
    },
    subIconButtonDiv: {
      backgroundColor: "#F5F5F5",
      width: "25px",
      height: "25px",
      borderRadius: "100%",
      textAlign: "center",
    },
    topic: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    addButton: {
      backgroundColor: white,
      color: primaryBlue,
      borderColor: primaryBlue,
    },
    backButton: {
      color: primaryGray,
      width: '100px',
      borderRadius: '4px',
      backgroundColor: 'transparent',
      boxShadow: "none",
      border: "1px solid #707070",
      "&:hover": {
        backgroundColor: "transparent",
        color: primaryGray,
        boxShadow: "none",
      },
    },
    clonedAlready: {
      color: "blue",
      fontSize: "14px",
      marginTop: "10px",
      fontWeight: "bold",
      border: "2px solid blue",
      padding: "8px",
      backgroundColor: "lightblue",
    },
    clonedIndicator: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      fontWeight: "bold",
      backgroundColor: "lightblue",
      padding: "8px",
      borderRadius: "5px",
    },
    clonedIcon: {
      color: "green",
      marginRight: "5px",
    },
    clonedText: {
      fontSize: "14px",
      color: "blue",
    },
    cloneButtonHover: {
      backgroundColor: primaryBlue,
      color: "white",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    helperText: {
      color: "red",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: textBarColor,
    },
    label: {
      color: "#324054A6",
      paddingBottom: "5px",
    },
  })
);




