import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {useState} from "react";
import {CloseIcon} from "../Icons";

const OTAHistory = (props) => {
  const product =
    props.device.device.product && props.device.device.product.otaHistory
      ? props.device.device.product
      : {otaHistory: []};

  const history = product.otaHistory;

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "60%", height: "70%"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.4",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          style={{position: "absolute", top: 0, right: "10px"}}
        >
          <CloseIcon/>
        </IconButton>

        <DialogContent style={{width: "100%"}}>
          <Grid container>
            <Grid item xs={12} md={12} style={{paddingTop: "10px"}}>
              <TableContainer>
                <Table
                  size="medium"
                  style={{
                    boxShadow: "initial",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{display: "inline-flex"}}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              lineHeight: "16px",
                            }}
                          >
                            DATE
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{display: "inline-flex"}}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              lineHeight: "16px",
                            }}
                          >
                            ACTION
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{display: "inline-flex"}}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              lineHeight: "16px",
                            }}
                          >
                            CURRENT VERSION
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{display: "inline-flex"}}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              lineHeight: "16px",
                            }}
                          >
                            PREVIOUS VERSION
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: "white",
                        }}
                      >
                        <div style={{display: "inline-flex"}}>
                          <Typography
                            display="inline"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              lineHeight: "16px",
                            }}
                          >
                            ACTION BY
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history &&
                      history.length > 0 &&
                      history
                        .slice()
                        .reverse()
                        .map((item, i) => (
                          <TableRow
                            style={{
                              textAlign: "center",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                            key={i}
                          >
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.updatedDate.split(" ")[0]}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Updated - Version
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.currentVersion}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.previousVersion}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.actionBy}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OTAHistory;
