import * as React from "react";
import {useEffect, useRef, useState} from 'react';
import { Box, Button, createTheme, Grid, IconButton, ThemeProvider, Tooltip, Typography } from "@material-ui/core";
import { Link, useLocation } from 'react-router-dom';
import useStyles from "../assets/styles";
import { AzureIcon, StartMonitoringIcon, SuccessCriteriaIcon, ReportIcon, ReportReadyIcon, NonSuccessCriteriaIcon, NotStartMonitoringIcon, StepIcon, RightArrowIcon } from "src/components/Icons";
import { environment } from "src/environments/environment";
import { Loader } from "../test-automation/components/Loader";
import { hasPermision, isTestAutomationUser, numberToWords } from "src/app/utils";
import axios from "axios";
import { checkDbStatusByProcess, getAllDevices, getJiraProject, getUserRole, technicianRole } from "./components/apiHelper";
import {  useHistory } from 'react-router-dom';
import { ToasterComponent } from "./components/Toaster";
import { DialogPopup } from "./components/DialogPopup";
import { azureService } from "src/app/utils";
import { updateGuideUser } from "./components/apiHelper";
import { WelcomePopUp } from "src/app/start-guide/WelcomePopUp";
import local from "src/app/setup-senzmatica/define-product-type-form/DefineProductTypeComponent";
import { UserGuide } from "src/app/start-guide/UserGuide";
import Cookies from "js-cookie";


const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});


const StepCard = ({ step, title, loading, setLoading, description, pathname, isSenzmaticaConnected, isAzureConnected, scenariosCount, monitoringSessionsCount, reportReadyCount, devicesCount, id ,setDevicesCount}) => {
  const classes = useStyles();
  const stepNumber = step.split(" ")[1];

  const history = useHistory();
  const [isUpgrade,setIsUpgrade] = useState(false)
  const {isAzureFreePackage, isAzureBasicPackage, isAzurePremiumPackage} = azureService()
   //Toaster
   const [isToasterOpen, setIsToasterOpen] = useState(false)
   const [toasterMessage, setToasterMessage] = useState("")
   const [reqSuccess, setReqSuccess] = useState(false)
   const [isVectorDbCreated,setIsVectorDbCreated] = useState(false)

   const isMounted = useRef(true);

  const vectorDbStatusChcek=async()=>{
    if(!hasPermision(pathname.slice(1))){
      setIsUpgrade(true)
    }else if(step==="Step 05"){
      setLoading(true)
      const result = await checkDbStatusByProcess();
      if(result && (result[0]==="Vector DB is created" || result[0] == "Vector DB is absent")){
        setLoading(false)
        history.push("/HumanoidNotificationSystem")
      }
      else{
        setIsToasterOpen(true);
        setReqSuccess(false);
        setToasterMessage("Vector db is creating");
        setIsVectorDbCreated(true)
        setLoading(false)
        setTimeout(() => {
          setIsToasterOpen(false);
          history.push("/TestAutomation")
        }, 2500);
      }
    }else{
      history.push(pathname)
    }
  }

  const fetchDevicesCount = async () => {
    setLoading(true);
    try {
      const response = await getAllDevices();

      const devicesCount = response.length;
      if(isMounted.current){
        setDevicesCount(devicesCount);
      }
    } catch (error) {
      console.error('Error fetching device data:', error.message);
    } finally {
      if(isMounted.current){
        setLoading(false);
      }
    } 
  };

  useEffect(() => {
    isMounted.current = true; // Mark component as mounted
    // fetchDevicesCount();
    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, []);

  const isButtonDisabled = () => {
    if (step === "Step 01") {
      if (isAzureFreePackage() && devicesCount >= 10) {
        return true;
      }
      if (isAzureBasicPackage() && devicesCount >= 100000) {
        return true;
      }
      if (isAzurePremiumPackage() && devicesCount >= 100000) {
        return true;
      }
    }
    return false;
  };

  const onCloseUpgrade = () =>{
    setIsUpgrade(false)
  }

  const handleUpgrade = () =>{
    window.open(
        environment.azurePackageUpgradeLink,
        "_blank" // Specifies to open the link in a new tab
    );
    setIsUpgrade(false)
  }

  return (
    <Grid item xs={12} md={6}>
      <Box className={classes.boxContainer} id ={`nav-${id}`} style={{borderRadius: "8px"}}>
        <div className={classes.statusContainer}>
          <Box className={classes.step}><StepIcon stepNumber={stepNumber} /></Box>
          <div className={classes.chipContainer}>
            {step === "Step 01" && (
              <>
                {isAzureConnected && !isSenzmaticaConnected && (
                  <Tooltip title="Azure Connected" placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <AzureIcon color="#02C170" />
                    </IconButton>
                  </Tooltip>
                )}
                {isSenzmaticaConnected && !isAzureConnected && (
                    <Tooltip title="SenzMatica Connected" placement="left">
                      <IconButton style={{ padding: "0px" }}>
                        <AzureIcon color="#02C170" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isSenzmaticaConnected && isAzureConnected && (
                    <Tooltip title="SenzMatica and Azure Connected" placement="left">
                      <IconButton style={{ padding: "0px" }}>
                        <AzureIcon color="#02C170" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isSenzmaticaConnected && !isAzureConnected && (
                    <IconButton style={{ padding: "0px" }}>
                      <AzureIcon color="#FF0000" />
                    </IconButton>
                  )}
              </>
            )}
            {step === "Step 02" && (
              <>
                {scenariosCount > 0 ? (
                  <Tooltip title={`${numberToWords(scenariosCount)} scenarios are defined`} placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <SuccessCriteriaIcon scenariosCount={scenariosCount} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton style={{ padding: "0px" }}>
                    <NonSuccessCriteriaIcon />
                  </IconButton>
                )}
              </>
            )}
            {step === "Step 03" && (
              <>
                {monitoringSessionsCount > 0 ? (
                  <Tooltip title={`${numberToWords(monitoringSessionsCount)} monitoring sessions are in progress`} placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <StartMonitoringIcon monitoringCount={monitoringSessionsCount} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton style={{ padding: "0px" }}>
                    <NotStartMonitoringIcon />
                  </IconButton>
                )}
              </>
            )}
            {step === "Step 06" && !isTestAutomationUser() && (
              <>
                {reportReadyCount > 0 ? (
                  <Tooltip title={`${numberToWords(reportReadyCount)} Report are ready`} placement="left">
                    <IconButton style={{ padding: "0px" }}>
                      <ReportReadyIcon reportCount={reportReadyCount} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton style={{ padding: "0px" }}>
                    <ReportIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </div>
        <Typography className={classes.titleContainer}style={{justifyContent: "left"}}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        <div className={classes.buttonContainer} style={{justifyContent: "flex-end"}}>
          
            <Button
              variant="contained"
              className={classes.testAutomationStarted}
              style={{ textTransform: "none" }}
              onClick={vectorDbStatusChcek}
              disabled={isButtonDisabled() || getUserRole()===technicianRole}
            >
              Get Started
              <RightArrowIcon />
            </Button>
        </div>
      </Box>
      <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
      <DialogPopup 
        open = {isUpgrade}
        title = 'Upgrade Azure Package'
        dialogContent = "This feature isn't available. Please upgrade your Device Pulse Plan."
        buttonRequired = {true}
        cancel = 'cancel'
        submit = 'upgrade'
        onClose = {onCloseUpgrade}
        onSubmit = {handleUpgrade}
        submitButtonColor = '#2A7BEC'
      />
      <DialogPopup 
        open = {isVectorDbCreated}
        title = 'Upgrade Azure Package'
        dialogContent = "Chatbot is currently being configured. Please try again in a few minutes."
        buttonRequired = {true}
        cancel = 'cancel'
        submit = 'ok'
        onClose = {() => setIsVectorDbCreated(false)}
        onSubmit = {() => setIsVectorDbCreated(false)}
        submitButtonColor = '#2A7BEC'
      />
    </Grid>
  );
};

export function TestAutomationComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [isSenzmaticaConnected, setIsSenzmaticaConnected] = useState(false);
  const [isAzureConnected, setIsAzureConnected] = useState(false);
  const [scenariosCount, setScenariosCount] = useState(null);
  const [monitoringSessionsCount, setMonitoringSessionsCount] = useState(null);
  const [reportReadyCount, setReportReadyCount] = useState(null);
  const [devicesCount, setDevicesCount] = useState(null);
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(false);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const {isAzurePremiumPackage, isAzureBasicPackage, isAzureFreePackage} = azureService()

  const userId = Cookies.get('USER_ID');
  const isMounted = useRef(true)
  const location = useLocation();
  const connectDeviceId = location.state ? location.state.connectDeviceId : undefined;
  const fromStepReport = location.state ? location.state.fromTestReport : undefined;

  const isTestAutomationUser=(): boolean =>{
    const role = parseInt(Cookies.get('ROLE'), 10);
    return role >= 10 && role < 100;
  }
  
  const [steps, setSteps] = useState(() => {
    const baseSteps = [
      { number: "Step 01", id: "CONNECT_DATA_STREAM", title: "Connect Data Stream", description: "Start by connecting to the IoT Platform and initiating the data stream", pathname: "/DataStream" },
      { number: "Step 02", id: "DEFINE_TEST_CASE", title: "Define Success Criteria", description: "Define the test cases to validate the device data", pathname: "/DefineTestCases" },
      { number: "Step 03", id: "MONITORING_TEST", title: "Start Monitoring", description: "Initiating the process of testing devices", pathname: "/StartTesting" },
      { number: "Step 04", id: "ROOT_CAUSE_ANALYTICS", title: "Root Cause Analysis", description: "Analysing the root cause of device failures", pathname: "/RootCauseAnalysis" },
      { number: "Step 05", id: "KNOWLEDGE_BASED_CONFIGURATIONS", title: "Knowledge based Configuration", description: "Explaining failure reasons in a human-understandable way", pathname: "/HumanoidNotificationSystem" }
    ];
  
    const additionalStep = isTestAutomationUser() ? (!isAzureFreePackage() && !isAzureBasicPackage()) ? { number: "Step 06", id: "Step_06", title: "ITSM Configuration", description: "Setting up Jira integration for task creation based on device failures", pathname: "/Configuration" } : null : { number: "Step 06", id: "Step_06", title: "Result Dashboard", description: "Accessing a comprehensive maintenance dashboard", pathname: "/ResultDashboard" };
    return [...baseSteps, additionalStep];
  });

  const handleCloseWelcomePopup = () => {
    setIsWelcomePopupOpen(false);
    const visitedPageMap = localStorage.getItem('userGuidePages') ? JSON.parse(localStorage.getItem('userGuidePages')) : {};
    visitedPageMap['WELCOME'] = true;
    localStorage.setItem('userGuidePages', JSON.stringify(visitedPageMap));
    updateGuideUser({ visitedPageMap });
  };

  const handleNextWelcomePopup = () => {
    setIsWelcomePopupOpen(false);

    const event = new CustomEvent('startUserGuide', { detail: { id: 'SETUP_DEVICE_PULSE' } });
    window.dispatchEvent(event);
    setTimeout(() => {
      setIsTourRunning(true);
    }, 500); 
  };

  const fetchDevicesCount = async () => {
    setLoading(true);
    try {
      const response = await getAllDevices();

      const devicesCount = response.length;
      if(isMounted.current){
        setDevicesCount(devicesCount);
      }
    } catch (error) {
      console.error('Error fetching device data:', error.message);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchDevicesCount();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
  const visitedPageMap = localStorage.getItem('userGuidePages')
    ? JSON.parse(localStorage.getItem('userGuidePages'))
    : {};

  console.log('Visited Page Map:', visitedPageMap); 
  if (Object.keys(visitedPageMap).length === 0) {
    setIsWelcomePopupOpen(true);
  }
}, []);


const fetchConnectDevice = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${environment.host}/core/user/${userId}/connectDevice`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    });

    const data = response.data.content;
    if (Array.isArray(data)) {
      const isSenzmatica = data.some((device) => device.type === "SENZMATE");
      if(isMounted.current){
        setIsSenzmaticaConnected(isSenzmatica);
      }
    } else {
      console.error("Unexpected data format:", data);
      throw new Error("Unexpected data format received from the API");
    }

  } catch (error) {
    console.error('Error fetching test data:', error.message);
  }
};

const fetchCosmosDbRequests = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${environment.host}/core/user/${userId}/cosmosRequests`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    });

    const data = response.data.content;
    if (Array.isArray(data) && data.length > 0) {
      setIsAzureConnected(true);
    } else {
      setIsAzureConnected(false);
    }
  } catch (error) {
    console.error('Error fetching Cosmos DB requests:', error.message);
  }
}

const fetchTestData = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${environment.host}/core/user/${userId}/testcase`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    });

    console.log("Fetched data:", response.data);

    const data = response.data.content;

    const scenariosCount = data.length;
    const monitoringSessionsCount = data.filter((testCase) => testCase.default === false && testCase.checked === false).length;
    
    let reportReadyCount = data.filter((testCase) => testCase.default === false && testCase.checked === true).length;

    if(fromStepReport) {
      reportReadyCount = Math.max(0, reportReadyCount - 1);
    }

    setScenariosCount(scenariosCount);
    setMonitoringSessionsCount(monitoringSessionsCount);
    setReportReadyCount(reportReadyCount);
  } catch (error) {
    console.error('Error fetching test data:', error.message);
  } finally {
    setLoading(false);
  }
};

  useEffect(() => {
    isMounted.current = true;
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchTestData(),
          fetchConnectDevice(),
          fetchCosmosDbRequests(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted.current) {
          setLoading(false); // Update state only if mounted
        }
      }
    };
    fetchData()
    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, [connectDeviceId]);

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid className={classes.pageTitle} style={{paddingLeft:'50px'}}>Setup Device Pulse</Grid>
        <Grid container className={classes.root} 
          style={{
            padding: "0px 50px 50px",
            borderRadius: "0px",
          }}
          spacing={3}
        >
          <WelcomePopUp
            open={isWelcomePopupOpen}
            onClose={handleCloseWelcomePopup}
            onNext={handleNextWelcomePopup}
            title="Learn about the  DevicePulse.AI"
            content="Welcome to the DevicePulse!  DevicePulse.AI helps you monitor, analyze, and optimize device performance with AI-driven insights. Follow these steps to set up and manage your devices efficiently"
          />
          <UserGuide isTourRunning={isTourRunning} steps={steps} />
          {steps.map((step, index) => (
              index % 2 === 0 && (
                <Grid container spacing={3} key={index}>
                  <StepCard 
                    step={steps[index].number} 
                    title={steps[index].title}
                    loading={loading}
                    setLoading={setLoading}
                    description={steps[index].description} 
                    pathname={steps[index].pathname}
                    isSenzmaticaConnected={isSenzmaticaConnected}
                    isAzureConnected={isAzureConnected}
                    scenariosCount={scenariosCount}
                    monitoringSessionsCount={monitoringSessionsCount}
                    reportReadyCount={reportReadyCount}
                    devicesCount={devicesCount}
                    id={steps[index].id}
                    setDevicesCount={setDevicesCount}
                  />

                  {steps[index + 1] && (
                    <StepCard 
                      step={steps[index + 1].number} 
                      title={steps[index + 1].title} 
                      loading={loading}
                      setLoading={setLoading}
                      description={steps[index + 1].description} 
                      pathname={steps[index + 1].pathname}
                      isSenzmaticaConnected={isSenzmaticaConnected}
                      isAzureConnected={isAzureConnected}
                      scenariosCount={scenariosCount}
                      monitoringSessionsCount={monitoringSessionsCount}
                      reportReadyCount={reportReadyCount}
                      devicesCount={devicesCount}
                      id={steps[index + 1].id}
                      setDevicesCount={setDevicesCount}
                    />
                  )}

                </Grid>
              )
          ))}
        </Grid>
        <Loader loading={loading} />
      </div>
    </ThemeProvider>
  );
}
