import * as React from 'react'

export const parseResponse = (text) => {
    // Split response into paragraphs by double newlines
    const paragraphs = text.split(/\n\n/);

    
    const convertToBold = (text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    };
    
    return paragraphs.map((para, paraIndex) => {
        if (para.includes('*')) {
            const listItems = para.split('\n');
            if (listItems) {
                return (
                    <div key={`list-${paraIndex}`} style={{ paddingBottom: '20px' }}>
                        {listItems.map((item, index) => {
                            // Check if the item is a list item (starts with '*')
                            if (item.startsWith('*')) {
                                let htmlContent = convertToBold(item);
                                return (
                                    <ul key={`list-${paraIndex}-${index}`} style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                        <li dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                    </ul>
                                );
                            } else {
                                // Handle as a paragraph with bold formatting
                                let htmlContent = convertToBold(item);
                                return (
                                    <p key={`para-${paraIndex}-${index}`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                );
                            }
                        })}
                    </div>
                );
            }
        } else if (para.includes('##')) {
            const formattedPara = para.replace('##', '').trim(); // Remove '##' and trim whitespace
            return (
                <h3 key={`header-${paraIndex}`} style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: convertToBold(formattedPara) }} />
            );
        } else {
            return (
                <p key={`paragraph-${paraIndex}`} dangerouslySetInnerHTML={{ __html: convertToBold(para) }} />
  
            );
        }
    });
  };