import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { AccountService } from '../../account/account.service';
import { Kit } from 'src/app/models';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view-component.html',
  styleUrls: ['./image-view-component.css']
})
export class ImageViewComponent implements OnChanges {
  @Input() kit: Kit;
  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<void>();

  selectedImage: string | null = null;
  isImagePopupOpen: boolean = false;
  images: string[] = [];
  status: string = '';

  constructor(private accountService: AccountService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['kit'] && this.kit) {
      this.fetchImages();
    }
  }

  fetchImages() {
    if (this.kit && this.kit.id) {
      this.accountService.getReferenceImages(this.kit.id, this.kit).subscribe(
        (response: { content: { referenceImages: string[] } }) => {
          this.images = response.content.referenceImages || []; 
        },
        (error) => {
          console.error('Error fetching images:', error);
          this.images = []; 
        }
      );
    } else {
      this.images = []; 
    }
  }

  updateStatus() {
    if (!this.kit || !this.kit.id) return;

    this.accountService.updateReferenceImageStatus(this.kit.id, this.status).subscribe(
      (response) => {
        console.log('Status updated successfully:', response);
        alert('Status updated successfully!');
      },
      (error) => {
        console.error('Error updating status:', error);
        alert('Failed to update status.');
      }
    );
  }

  closePopup() {
    this.isOpen = false;
    this.close.emit();
  }

  openImagePopup(imageUrl: string) {
    this.selectedImage = imageUrl;
    this.isImagePopupOpen = true;
  }

  closeImagePopup() {
    this.isImagePopupOpen = false;
    this.selectedImage = null;
  }

  downloadImage() {
    if (this.selectedImage) {
      const link = document.createElement('a');
      link.href = this.selectedImage;
      link.download = 'downloaded_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
