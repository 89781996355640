import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Grid, InputBase} from '@material-ui/core';
import {white} from 'src/components/colors';
import {DownArrowIcon, UpArrow} from 'src/components/Icons';
import useStyles from '../../assets/styles';


export function ParameterDropDown(props) {
  const classes = useStyles();
  const [isDropDown, setIsDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(''); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectOptions(props.options); 
  }, [props.options]);

  useEffect(() => {
    if (!props.value) {
      setSelectedLabel(''); // Clear selectedLabel when value is reset
    } else {
      const selectedOption = props.options.find(option => option.value === props.value);
      setSelectedLabel(selectedOption ? selectedOption.label : '');
    }
  }, [props.value, props.options]);

  const handleValue = (label, value) => {
    if(props.mainTestCaseName != undefined){
      if(props.mainTestCaseName != ''){
        props.setSelectOption(value); 
        setSelectedLabel(label);
      }else{
        props.setErrors(prevErrors => ({
          ...prevErrors,
          mainTestNameError: "Main test case is required"
        }));
        return;
      }
    }else{
      props.setSelectOption(value);
      setSelectedLabel(label);
    }
    // setParameterValue(label)
  };

  const handleDropDown = () => {
    setIsDropDown(!isDropDown);
  };

  const handleInputChange = (event) =>{
    const {value} = event.target;
    setSelectedLabel(value)
    if(value != ''){
      let filterParameter = selectOptions.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
      setSelectOptions(filterParameter)
    }else{
      setSelectOptions(props.options)
    }
  }

  return (
    <Grid ref={dropdownRef} container style={{ position: 'relative' }}>
      <Grid container 
        className={classes.inputBaseStyle}
        style={{backgroundColor: white}} 
        onClick={handleDropDown}
      >
        <Grid item xs={10} className={classes.inputContainer}>
          <InputBase
            placeholder={props.emptyTag}
            style={{
              border: 'none',
              cursor: 'pointer',
              marginLeft: '10px',
              fontSize: '13px'
            }}
            value={selectedLabel}
            fullWidth
            autoFocus
            readOnly ={props.type !== 'parameter'}
            onChange={props.type == 'parameter' ? handleInputChange : undefined}
          />
        </Grid>
        <Grid item xs={2} className={classes.iconContainer}>
          {isDropDown ? <UpArrow /> : <DownArrowIcon />}
        </Grid>
      </Grid>

      {isDropDown && (
        <div className={classes.dropdown}>
          {selectOptions.map((option) => (
            <Grid
              item
              className={classes.option}
              key={option.value}
              onClick={() => {
                handleValue(option.label, option.value); 
                if (props.isSingle) {
                  setIsDropDown(false);
                }
              }}
            >
              {option.label}
            </Grid>
          ))}
        </div>
      )}
    </Grid>
  );
}
