import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  InputBase,
  Chip,
  Checkbox,
  Typography,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import useStyles from "../../assets/styles";
import { DownArrowIcon, UpArrow } from "src/components/Icons";

export function CheckBoxDropDown(props) {
  const classes = useStyles();
  const [isDropDown, setIsDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectOptions, setSelectOptions] = useState(props.options || []);
  const [selectedValues, setSelectedValues] = useState(props.value || []); 

  useEffect(() => {
    setSelectedValues(props.value || []);
    setSelectOptions(props.options || []);
  }, [props.value, props.options]);

  const handleValueChange = (option) => {
    if (option.disabled) return;

    // Update selected values based on whether the option is already selected
    const updatedValues = selectedValues.includes(option.value)
      ? selectedValues.filter((selected) => selected !== option.value)
      : [...selectedValues, option.value];

    setSelectedValues(updatedValues);
    props.setSelectOption(updatedValues);
  };

  const handleRemove = (option) => {
    const updatedValues = selectedValues.filter((selected) => selected !== option.value);
    setSelectedValues(updatedValues);
    props.setSelectOption(updatedValues);
  };

  const visibleChips = selectedValues.slice(0, 2);
  const hiddenCount = selectedValues.length - visibleChips.length;

  return (
    <Grid ref={dropdownRef} container style={{ position: "relative" }}>
      <Grid container className={classes.inputBaseStyle} onClick={() => setIsDropDown((prev) => !prev)}>
        <Grid item xs={10} className={classes.inputContainer}>
          {selectedValues.length === 0 ? (
            <InputBase
              placeholder={props.emptyTag || 'Select options'}
              style={{
                border: "none",
                cursor: "pointer",
                marginLeft: "10px",
                fontSize: "13px",
              }}
              value=""
              fullWidth
              readOnly
            />
          ) : (
            <>
              {visibleChips.map((value, index) => (
                <Chip
                  key={`${value}-${index}`}
                  label={value}
                  className={classes.chip}
                  onDelete={() => handleRemove({ value })}
                  style={{ margin: '2px' }}
                />
              ))}
              {hiddenCount > 0 && (
                <Chip
                  label={`+${hiddenCount}`}
                  className={classes.chip}
                  style={{ margin: '2px', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDropDown(true);
                  }}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={2} className={classes.iconContainer} style={{zIndex:1}}>
          {isDropDown ? <UpArrow /> : <DownArrowIcon />}
        </Grid>
      </Grid>   

      {isDropDown && (
        <div className={classes.dropdown}>
          {selectOptions.map((option, index) => (
            <Grid
              container
              alignItems="center"
              className={classes.checkboxDropdownOption}
              key={`${option.value}-${index}`}
            >
              <FormGroup style={{ margin: "0px" }}>
                <FormControlLabel
                  className={classes.typo}
                  control={
                    <Checkbox
                      checked={selectedValues.includes(option.value)}
                      onChange={() => handleValueChange(option)}
                      disabled={option.disabled}
                      color="primary"
                    />
                  }
                  label={option.label}
                />
              </FormGroup>
            </Grid>
          ))}
        </div>
      )}
    </Grid>
  );
}