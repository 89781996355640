import { createTheme, ThemeProvider, Grid, Table, TableHead, TableRow, IconButton, TableBody, TableCell, DialogTitle, Dialog, DialogContent, Button, Box, Card, Accordion, AccordionSummary, AccordionDetails, TableContainer, Paper, Typography, InputBase } from '@material-ui/core';
import * as React from 'react';
import { BackToHomeComponent } from '../maintenance/test-automation/components/BackToHome';
import { AddIconTwo, ChatbotConfig, EditIcon, EditIconLarge, ExpandCollapseIcon, ExpandIcon, ExpandMoreIcon, PdfIcon, PenIcon, TrashIcon, UploadIcon, ViewIcon } from 'src/components/Icons';
import useStyles from '../maintenance/assets/styles';
import { Close } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { EditChatbotConfigComponent } from './edit-chatbot-config/EditChatbotConfig';
import { addApiConfiguration, deleteApiConfig, deleteChatbotConfig, DeleteFile, fetchChatbotConfig, getApiConfig, getAuthenticationToken, getUserRole, saveToken, technicianRole, updateApiConfig, updateProjectClientConfigurations, viewChatBotConfig } from '../maintenance/test-automation/components/apiHelper'
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';
import { hasUserPermission } from '../permission';
import Cookies from "js-cookie";
import { ApiTable } from '../maintenance/test-automation/step6/Component/ApiTable';
import { AddAuthenticationToken } from '../maintenance/test-automation/step6/Component/AddAuthenticationToken';
import { DialogPopup } from '../maintenance/test-automation/components/DialogPopup';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { ApiConfigurationComponent } from '../maintenance/test-automation/step6/ApiConfiguration';
import { Buffer } from 'buffer';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});
const STEPS = [
    {
        text: "Project Configurations",
        activate: true,
    },
    {
        text: "Knowledge Based Uploads",
        activate: false,
    },
    // {
    //     text: "API Configurations",
    //     activate: false,
    // },
];

interface ChatbotConfig {
    batchNumber: string;
    projectName: any;
}

interface ConfigDetails {
    batchNumber?: any;
    projectName?: any;
    fileNames?: any;
    files?: any
}

export const ChatbotConfigComponent = () => {

    const classes = useStyles();
    const [chatbotConfigDetails, setChatbotConfigDetails] = useState<ChatbotConfig[]>([]);
    const [selectedConfigDetails, setSelectedConfigDetails] = useState<ConfigDetails>({})
    const [isViewPopup, setIsViewPopup] = useState(false)
    const [isDeletePopup, setIsDeletePopup] = useState(false)
    const [isEditPopup, setIsEditPopup] = useState(false)
    const [isDeleteConformation, setIsDeleteConformation] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isExpand, setIsExpand] = useState({ project: false, fileNames: false, apiData: false })
    const [steps, setSteps] = useState(STEPS)
    const [step, setStep] = useState(0)
    const [selectedBatchNumber, setSelectedBatchNumber] = useState('')
    const [selectedProject, setSelectedProject] = useState<any>({})
    const [projectNames, setProjectNames] = useState([])
    const [errors, setErrors] = useState({
        projectName: null,
        fileError: null,
        KnowledgedBase: null
    });
    const [searchTerm, setSearchTerm] = useState('')
    const [filterVisible, setFilterVisible] = useState(false)
    const [selectedPrefix, setSelectedPrefix] = useState('')
    const [prefixes, setPrefixes] = useState([])
    const [filterConfigDetails, setFilterConfigDetails] = useState<ChatbotConfig[]>([])
    const [downloadLinks, setDownloadLink] = useState<any[]>([])
    const KnowledgedBaseTypes = ['API Input', 'File Upload']
    const [methodExpandedRow, setMethodExpandedRow] = useState(null)
    const [authenticationTokens, setAuthenticationTokens] = useState([])
    const [apiConfigDetails, setApiConfigDetails] = useState({})
    const [isTokenDialog, setIsTokenDialog] = useState(false)
    const [token, setToken] = useState({ name: "", token: "" })
    const [tokenError, setTokenError] = useState({
        tokenNameError: null,
        tokenError: null
    })
    const [expandRow, setExpandRow] = useState({ method: null, batch: null, file: null })
    const [isApiInputPopup, setIsApiInputPopup] = useState({ delete: false, edit: false, json: false })
    const [selectedApi, setSelectedApi] = useState<any>({})
    const [authenticationToken, setAuthenticationToken] = useState('')
    const [isAddJson, setIsAddJson] = useState(false)

    const [fileData, setFileData] = useState<any>([])
    const [selectedJsonFile, setselectedJsonFile] = useState(null)
    const [jsonFiles, setJsonFiles] = useState([])
    const [allTableData, setAllTableData] = useState([])
    const [selectedToken, setSelectedToken] = useState([])
    const [hoveredRow, setHoveredRow] = useState(null)
    const [isEditProjectName, setIsEditProjectName] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const [isFileUploadPopup,setIsFileUploadPopup] = useState({delete:false})
    const [selectedUploadFile,setSelectedUploadFile] = useState<any>(null)
    const [isDeleteFile,setIsDeleteFile] = useState(false)
    const [selectedFileIndex,setSelectedFileIndex] = useState(null)
    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    const isMounted = useRef(true)

    const handleViewPopup = (batch, projectName) => {
        setIsViewPopup(true)
        getChatConfigDetails(batch)
        setSelectedBatchNumber(batch)
        // setSelectedProject(projectName)
    }

    const getChatConfigDetails = async (batch) => {
        try {
            const data = {
                batchNumber: batch,
                referenceId: Cookies.get('ACCOUNT')
            }
            const response = await viewChatBotConfig(data)
            if (response) {
                if (response.files !== 'EMPTY') {
                    const configDetails = {
                        ...response,
                        files: transformPDFData(response.files)
                    }
                    const link = downloadFilesFromBase64(response.files)
                    setDownloadLink(link)
                    setSelectedConfigDetails(configDetails)
                } else {
                    setSelectedConfigDetails(response)
                }

            } else {
                setSelectedConfigDetails({})
            }

        } catch (error) {

        }
    }

    const handleEditPopup = (batch, projectName) => {
        setIsEditPopup(true)
        getChatConfigDetails(batch)
        setSelectedBatchNumber(batch)
        // setSelectedProject(projectName)
    }

    const handleDownloadFile = (name) => {
        const link = downloadLinks.find(link =>
            link.fileName.toLowerCase() === name.toLowerCase()
        );

        if (link) {
            // Create an anchor element and set its href to the download link
            const a = document.createElement('a');
            a.href = link.url;
            a.download = link.fileName; // Set the download attribute to the file name
            document.body.appendChild(a);
            a.click(); // Trigger the download
            document.body.removeChild(a); // Clean up by removing the anchor tag
        } else {
            console.log(`No link found for file: ${name}`);
        }
    };




    const transformPDFData = (input) => {
        const output = [];
        Object.entries(input).forEach(file => {
            const name = file[0].split('/').pop(); // Extract the file name
            const size = (file[1].toString().length / 1024 / 1024).toFixed(2); // Calculate size in KB, rounded to 2 decimal places
            const base64Data = file[1].toString();

            // Decode base64 and create binary data
            const binaryData = atob(base64Data);
            const arrayBuffer = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                arrayBuffer[i] = binaryData.charCodeAt(i);
            }

            // Create a Blob and then a File object
            const blob = new Blob([arrayBuffer], { type: "application/pdf" });
            const fileObj = new File([blob], name, { type: "application/pdf", lastModified: Date.now() });

            // Push the transformed data into the output array
            output.push({
                "name": name,
                "size": size,
                "progress": 100,
                "file": fileObj
            });
        });
        return output;
    };

    const downloadFilesFromBase64 = (files) => {
        const downloadLinks = [];
        Object.entries(files).forEach((file) => {
            const fileName = file[0].split('/').pop();
            const base64Data = file[1].toString(); // Extract the file name from the path

            // Decode the base64 string into binary data
            const binaryData = atob(base64Data);

            // Convert binary string to an ArrayBuffer
            const arrayBuffer = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                arrayBuffer[i] = binaryData.charCodeAt(i);
            }

            // Create a Blob from the ArrayBuffer
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

            // Clean up by removing the link and revoking the object URL
            const url = URL.createObjectURL(blob);
            downloadLinks.push({ fileName, url });

        });
        return downloadLinks;
    };


    const handleDeletePopup = (batch) => {
        setIsDeletePopup(true)
        setSelectedBatchNumber(batch)
    }

    const cancelPopup = () => {
        fetchChatConfig()
        setIsViewPopup(false)
        setIsDeletePopup(false)
        setIsEditPopup(false)
        setIsDeleteConformation(false)
        setSearchTerm('')
        setIsExpand({
            project: false,
            fileNames: false,
            apiData: false
        })
        setStep(0)
        setErrors(prevError => ({
            ...prevError,
            projectName: null,
            fileError: null,
            KnowledgedBase: null
        }))
        setExpandRow(prev => ({
            ...prev,
            file: null
        }))
    }

    useEffect(() => {
        fetchChatConfig()
    }, [])

    const fetchChatConfig = async () => {
        try {
            setLoading(true)
            const data = {
                referenceId: Cookies.get('ACCOUNT')
            }
            const response = await fetchChatbotConfig(data);

            // Update the state with the fetched data
            if (response) {
                setChatbotConfigDetails(response);
                setFilterConfigDetails(response)
                const projectName = response.map(detail =>
                    detail.projectName
                )
                setProjectNames(projectName)
                const batchNumber = response.map(detail => detail.batchNumber)
                getPrefixes(batchNumber)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const getPrefixes = (batchNumbers) => {
        let prefixes = batchNumbers.map(res => {
            if (res.includes('_') || res.includes('-')) {
                return res.split(/[_-]/)[0]
            }
        }).filter(Boolean)
        setPrefixes(prefixes)
    }

    const deleteChatbot = async () => {
        try {
            setLoading(true)
            const response = await deleteChatbotConfig(selectedBatchNumber)
            if (response) {
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Chatbot Configuration Removed Successfully")
            }

        } catch {

        } finally {
            setLoading(false)
            setIsDeletePopup(false)
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
            await fetchChatConfig()
        }
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)
    };

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const handleExpand = async (index, type, batchNumber) => {
        setExpandRow(prev => ({
            method: type === 'batch' ? null : type === 'method' && index == prev.method ? null :type === 'file' ? prev.method : index,
            batch: type === 'batch' ? (prev.batch === index ? null : index) : prev.batch,
            file: type !== 'file' ? null : prev.file === index ? null : index
        }));
        setSelectedBatchNumber(batchNumber)
        if (type == 'batch') {
            setLoading(true);
            Promise.all([
                getApiDetails(batchNumber),
                getChatConfigDetails(batchNumber),
            ])
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const getApiDetails = async (batchNumber) => {
        try {
            const response = await getApiConfig(batchNumber)
            const responseData = response.map(api => {
                const authMatch = authenticationTokens.find(auth => auth.token === api.authenticationToken);
                const tokenName = authMatch ? authMatch.name : "";
                return {
                    ...api,
                    authenticationToken : tokenName
                }
            })
            const data = responseData.reduce((acc, item) => {
                acc[item.source] = acc[item.source] || [];
                acc[item.source].push(item);
                return acc;
            }, {} as Record<string, typeof response>);
            console.log(data)
            setApiConfigDetails(data)
        } catch (error) {

        }
    }

    const filterBatch = () => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = chatbotConfigDetails.filter(config => {
            const matchesPrefix = selectedPrefix === 'All' || config.batchNumber.startsWith(selectedPrefix);
            const matchesSearch = config.batchNumber.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        });
        setFilterConfigDetails(filtered);
        setFilterVisible(false)
    }

    const getAuthentication = async () => {
        try {
            const reponse = await getAuthenticationToken()
            console.log(reponse)
            setAuthenticationTokens(reponse)
        } catch (error) {
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        }
    }

    useEffect(() => {
        getAuthentication()
    }, [])

    useEffect(() => {
        const filterBatchNumber = () => {
            const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : '';

            const filtered = chatbotConfigDetails.filter(config => {
                const matchesSearch = config.batchNumber.toLowerCase().includes(lowerCaseSearchTerm);
                const matchesPrefix = selectedPrefix === 'All' || selectedPrefix === '' || config.batchNumber.startsWith(selectedPrefix);

                // If no search term is provided and prefix is 'All', show all batches
                if (selectedPrefix === 'All' && !searchTerm) {
                    return true; // Keep all batches
                }
                return matchesPrefix && matchesSearch;
            });
            setFilterConfigDetails(filtered);
        };
        filterBatchNumber();
    }, [searchTerm, selectedPrefix]);

    const handleAddToken = () => {
        setIsTokenDialog(prev => !prev);
        setTokenError((prev) => ({
            ...prev,
            tokenNameError: null,
            tokenError: null
        }))
    }

    const handleAddJsonFile = () => {
        setIsAddJson(true)
    }

    const handlePopup = (type, data) => {
        setIsApiInputPopup(prev => ({
            ...prev,
            [type]: !prev[type]
        }))
        setSelectedApi(data)
        setAuthenticationToken(data.authenticationToken)
        if(type == 'json'){
            setAllTableData([])
            setJsonFiles([])
            setSelectedToken([])
            setIsAddJson(false)
        }
    }

    const handleDeleteJsonFile = (index) => {
        setIsDeleteFile(true)
        setSelectedFileIndex(index)
    }

    const cancelDeletePopup = () => {
        setIsDeleteFile(false)
    }

    const deleteJsonFile = (index) => {
        const updatedFiles = [...jsonFiles];
        const selectedFileName = updatedFiles[selectedFileIndex].name
        const selectedApi = allTableData.filter((data) => data.fileName !== selectedFileName)
        setAllTableData(selectedApi)
        updatedFiles.splice(index, 1);
        setJsonFiles(updatedFiles);
        const token = [...selectedToken]
        token.splice(index,1)
        setSelectedToken(token)
        cancelDeletePopup()
    };

    const deleteApiDetails = async () => {
        setLoading(true)
        try {
            const response = await deleteApiConfig(selectedApi.id)
            if (response) {
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage('API Deleted Successfully.')
                getApiDetails(selectedBatchNumber)
                setIsApiInputPopup(prev => ({
                    ...prev,
                    delete: false
                }))
            }
        } catch (error) {
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        } finally {
            setLoading(false)
            setTimeout(() => {
                setIsToasterOpen(false)
            }, 2500)
        }
    }

    const editApiDetails = async () => {
        setLoading(true)
        try {
            const tokenValue = authenticationTokens.find(token => token.name === authenticationToken).token
            const response = await updateApiConfig(selectedApi.id, Buffer.from(tokenValue))
            if (response) {
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage('API Updated Successfully.')
                getApiDetails(selectedBatchNumber)
                setIsApiInputPopup(prev => ({
                    ...prev,
                    edit: false
                }))
                cancelPopup()
            }
        } catch (error) {
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        } finally {
            setLoading(false)
            setTimeout(() => {
                setIsToasterOpen(false)
            }, 2500)
        }
    }

    const handleSelectToken = (data) => {
        setAuthenticationToken(data)
    }

    const selectTokenFromAddJson = (fileIndex, pathIndex, selectedTokenValue, apiName) => {
        setSelectedToken((prev) => {
            const updated = [...prev];
            if (updated[fileIndex]) {
                updated[fileIndex] = {
                    ...updated[fileIndex],
                    [pathIndex]: selectedTokenValue,
                };
            } else {
                // If the fileIndex doesn't exist, add a new entry
                updated[fileIndex] = { [pathIndex]: selectedTokenValue };
            }
            return updated;
        });

        setAllTableData((prev) => {
            let newTableData = [...prev];
            const fileEntryIndex = newTableData.findIndex((entry) => entry.fileName === jsonFiles[fileIndex].name);
            if (fileEntryIndex !== -1) {
                const existingApis = newTableData[fileEntryIndex].apis;
                const apiIndex = existingApis.findIndex((api) => api.apiName === apiName);

                if (apiIndex !== -1) {
                    // Update the authentication token for the selected API
                    existingApis[apiIndex] = {
                        ...existingApis[apiIndex],
                        authenticationToken: selectedTokenValue,
                    };
                }
            }
            return newTableData;
        });
    };

    const handleJsonUpload = (event) => {
        setIsAddJson(false);
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        const file = event.target.files[0];
        const isDuplicate = jsonFiles.some(
            (json) => json.name.trim().toLowerCase() === file.name.trim().toLowerCase()
        );
        const isExist = filteredApiConfigDetails.some(([filename,record]) => filename.toLowerCase() === file.name.trim().toLowerCase())
        console.log(isExist)
        if (isDuplicate || isExist) {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage("This file has already been attached.");
            event.target.value = "";
        } else {
            setselectedJsonFile(file);
        }
        setTimeout(() => {
            setIsToasterOpen(false)
        }, 2500)
    };

    const handleAddJson = () => {
        setIsAddJson(true)
        setJsonFiles((prev) => ([
            ...prev,
            selectedJsonFile
        ]))
        setselectedJsonFile(null)
    }

    const handleSelectApi = (fileIndex, pathIndex) => {
        const fileName = jsonFiles[fileIndex].name;
        const apiData = fileData[fileIndex][pathIndex];
        const apiName = apiData.apiName;
        const dataWithAuth = {
            ...apiData,
            authenticationToken: selectedToken[fileIndex] && selectedToken[fileIndex][pathIndex] ? selectedToken[fileIndex][pathIndex] : ''
        }
        setAllTableData((prev) => {
            let newTableData = [...prev];
            const fileEntryIndex = newTableData.findIndex((entry) => entry.fileName === fileName);

            if (fileEntryIndex !== -1) {
                const existingApis = newTableData[fileEntryIndex].apis;
                const apiIndex = existingApis.findIndex((api) => api.apiName === apiName);

                if (apiIndex !== -1) {
                    existingApis.splice(apiIndex, 1);
                    if (existingApis.length === 0) {
                        newTableData.splice(fileEntryIndex, 1);
                    }
                } else {
                    existingApis.push(dataWithAuth);
                }
            } else {
                newTableData.push({
                    fileName: fileName,
                    apis: [dataWithAuth],
                });
            }
            return newTableData;
        });
    };

    // Select all checkboxes
    const handleSelectAll = (fileIndex) => {
        const fileName = jsonFiles[fileIndex].name;
        const apiData = fileData[fileIndex]; // All APIs in the selected file
        const updatedApiData = apiData.map((api, pathIndex) => ({
            ...api,
            authenticationToken: selectedToken[fileIndex] && selectedToken[fileIndex][pathIndex] ? selectedToken[fileIndex][pathIndex] : '', // Assign token if exists
        }));
        setAllTableData((prev) => {
            let tableData = [...prev];
            const fileEntryIndex = tableData.findIndex((entry) => entry.fileName === fileName);

            if (fileEntryIndex !== -1) {
                // File exists, check if all APIs are already selected
                const isAllSelected = updatedApiData.every(api =>
                    tableData[fileEntryIndex].apis.some(existingApi => existingApi.apiName === api.apiName)
                );

                if (isAllSelected) {
                    // Remove the file if all its APIs are already selected (toggle off)
                    tableData.splice(fileEntryIndex, 1);
                } else {
                    // Add only missing APIs
                    const updatedApis = [...tableData[fileEntryIndex].apis];

                    updatedApiData.forEach(api => {
                        if (!updatedApis.some(existingApi => existingApi.apiName === api.apiName)) {
                            updatedApis.push(api);
                        }
                    });

                    tableData[fileEntryIndex].apis = updatedApis;
                }
            } else {
                // File is not in the table, add all its APIs
                tableData.push({
                    fileName: fileName,
                    apis: updatedApiData,
                });
            }
            return tableData;
        });
    };




    useEffect(() => {
        // Create an array to store the table data
        let tableDataArray = [];

        jsonFiles.forEach((file, fileIndex) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const result = (event.target as FileReader).result;
                    const jsonData = JSON.parse(result as string);

                    let dataArray = [];

                    Object.entries(jsonData.paths || {}).forEach(([path, methods], pathIndex) => {
                        for (const [method, details] of Object.entries(methods)) {
                            if (details.operationId) {
                                const tableData =
                                {
                                    apiName: details.operationId ? details.operationId : '',
                                    description: details.description ? details.description : '',
                                    batchNumber: selectedBatchNumber,
                                    serverUrl: jsonData.servers ? jsonData.servers[0].url : "",
                                    authenticationToken: (
                                        <DropDown
                                            options={authenticationTokens ? authenticationTokens.map((token) => token.name) : []}
                                            type="token"
                                            emptyTag="Select a token"
                                            onClick={(e) => selectTokenFromAddJson(fileIndex, pathIndex, e, details.operationId)}
                                            value={selectedToken[fileIndex] && selectedToken[fileIndex][pathIndex] ? selectedToken[fileIndex][pathIndex] : ''}
                                            isSingle={true}
                                        />
                                    ),
                                    source: file.name,
                                    payload: JSON.stringify({ [path]: methods }),  // Keep the path and methods in the table data
                                }
                                dataArray.push(tableData);
                            }
                        }
                    })
                    tableDataArray.push(dataArray);
                    if (fileIndex === jsonFiles.length - 1) {
                        console.log(tableDataArray)
                        setFileData(tableDataArray);
                    }
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            };

            reader.readAsText(file); // Start reading the file
        });
    }, [jsonFiles, selectedToken, allTableData]);

    const uploadApiConfigure = async () => {
        if (!isMounted.current) return; // Prevent updates if unmounted
        if (allTableData.length == 0) {
            return
        }
        setLoading(true);
        try {
            const apis = allTableData.reduce((acc, data) => acc.concat(data.apis), []);
            const apisWithAuth = apis.map(api => {
                const tokenMatch = authenticationTokens.find(auth => auth.name === api.authenticationToken);
                return {
                    ...api,
                    authenticationToken: tokenMatch ? tokenMatch.token : ""
                };
            });
            const response = await addApiConfiguration(apisWithAuth);
            if (isMounted.current) {
                if (response) {
                    setIsToasterOpen(true);
                    setReqSuccess(true);
                    setToasterMessage("APIs Uploaded Successfully.");
                    setIsApiInputPopup(prev => ({
                        ...prev,
                        json: false
                    }))
                    cancelPopup()
                }
            }
        } catch (error) {
            if (isMounted.current) {
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(error.message);
            }
        } finally {
            if (isMounted.current) {
                setLoading(false);
                setTimeout(() => {
                    if (isMounted.current) {
                        setIsToasterOpen(false);
                    }
                }, 3000);
            }
        }
    };

    const handleEditProjectName = (index, projectName) => {
        setIsEditProjectName(true)
        const project = { index: index, name: projectName }
        setSelectedProject(project)
    }

    // const changePro
    const handleProjectName = (event, index, batch) => {
        let value = event.target.value.trim();

        setSelectedBatchNumber(batch); // Always update batch number

        setSelectedProject(prev => {
            if (prev.index === index) {
                return { index: index, name: value };
            }
            return prev; // Ensure state remains unchanged for other projects
        });

        setErrors(prevError => ({
            ...prevError,
            projectName: value ? null : prevError.projectName, // Only reset error if there's a value
        }));
    };


    const updateProjectName = async (index) => {
        const selectedConfig = filterConfigDetails[index]
        if (selectedProject.name == '') {
            setErrors(prevError => ({
                ...prevError,
                projectName: 'Project Name is Required.',
            }));
            return
        } else if (selectedConfig.projectName !== selectedProject.name && filterConfigDetails.some(config => config.projectName.toLowerCase() === selectedProject.name.toLowerCase())) {
            setErrors(prevError => ({
                ...prevError,
                projectName: 'Project Name Already Exists.',
            }));
            return
        } else if (selectedConfig.projectName == selectedProject.name) {
            setIsEditProjectName(false)
            return
        }
        const data = {
            batchNumber: selectedBatchNumber,
            analysis_configs: selectedProject.name,
            referenceId: Cookies.get('ACCOUNT')
        }
        try {
            setLoading(true)
            const response = await updateProjectClientConfigurations(data)
            console.log(response)
            if (response) {
                setIsEditProjectName(false)
                fetchChatConfig()
            }
        } catch {

        } finally {
            setLoading(false)
        }
    }

    const searchFileName = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    // Filter files based on search query
    const filteredApiConfigDetails = Object.entries(apiConfigDetails).filter(([fileName]) =>
        fileName.toLowerCase().includes(searchQuery)
    );

    // const handleDeleteFile = () =>{
    //     setIsFileUploadPopup(prev =>({
    //         ...prev,
    //         delete:true
    //     }))
    // }

    const deleteFile = async (index) => {

        console.log(index)
        const selectedFile = selectedConfigDetails.files[index]
        console.log(selectedFile)
        setSelectedUploadFile(selectedFile)
        setIsFileUploadPopup(prev =>({
            ...prev,
            delete:true
        }))
       
    }

    const handleDeleteFile = async () => {
        const data = {
            batchNumber: selectedBatchNumber,
            deleteFiles: [selectedUploadFile.name],
            referenceId : Cookies.get('ACCOUNT')
        };
        try {
            setLoading(true);
            await DeleteFile(data);
            setIsToasterOpen(true);
            setToasterMessage(`Successfully removed the file ${selectedUploadFile.name}`);
            setReqSuccess(true);
        } catch (error) {
            setIsToasterOpen(true);
            setToasterMessage(error.message);
            setReqSuccess(false);
        } finally {
            setLoading(false);
            setIsFileUploadPopup(prev => ({
                ...prev,
                delete:false
            }))
            // setIsExpand(()=)
            getChatConfigDetails(selectedBatchNumber)
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ padding: '25px' }}>
                <Grid item xs={12} md={12}>
                    <Grid className={classes.pageTitle} style={{ margin: '0px' }}>Knowledge Based Uploads</Grid>
                    <BackToHomeComponent icon={<ChatbotConfig />} path={'/TestAutomation'} title={'Knowledge Based Uploads'} />
                    <Grid container className={classes.backgroundContainer}>
                        <Grid item xs={6} md={3} style={{ marginRight: '10px' }}>
                            <SearchBar onChange={handleSearch} value={searchTerm} />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FilterBatchNumber
                                toggleFilter={toggleFilter}
                                handlePrefixSelect={handlePrefixSelect}
                                filterVisible={filterVisible}
                                setFilterVisible={setFilterVisible}
                                prefixes={prefixes}
                                selectedPrefix={selectedPrefix}
                                filterBatch={filterBatch}
                                setSelectedPrefix={setSelectedPrefix}
                                filterType="batchNumber"
                            />
                        </Grid>
                        <Grid container className={classes.tableContainer} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tablehead} align="left" style={{ width: '30%' }}>Batch Name</TableCell>
                                        <TableCell className={classes.tablehead} align="left" style={{ width: '60%' }}>Project Name</TableCell>
                                        <TableCell className={classes.tablehead} style={{ width: '10%' }} align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filterConfigDetails.length > 0 ? (
                                        filterConfigDetails.map((chat, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow style={{ backgroundColor: expandRow.batch == index ? '#005B96' : index % 2 == 0 ? '#F5F5F5' : '#FFFFFF', }}>
                                                    <TableCell align="left" style={{ color: expandRow.batch == index ? '#FFFFFF' : '#324054' }}>{chat.batchNumber}</TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{ color: expandRow.batch == index ? '#FFFFFF' : '#324054' }}
                                                        onMouseEnter={() => setHoveredRow(index)}
                                                        onMouseLeave={() => setHoveredRow(null)}
                                                    >
                                                        {isEditProjectName && selectedProject.index == index ?

                                                            <Grid container>
                                                                <InputBase
                                                                    value={selectedProject.name}
                                                                    className={classes.formInput}
                                                                    type="text"
                                                                    onChange={(e) => handleProjectName(e, index, chat.batchNumber)}
                                                                    style={{ flex: 1, marginRight: '20px' }}
                                                                />
                                                                <Button
                                                                    onClick={() => updateProjectName(index)}
                                                                    style={{ backgroundColor: '#2A7BEC', color: 'white' }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Grid>
                                                            :
                                                            <Grid container>
                                                                <Typography>{chat.projectName}</Typography>
                                                                {hoveredRow === index && (
                                                                    <IconButton size="small" onClick={() => handleEditProjectName(index, chat.projectName)}>
                                                                        <PenIcon />
                                                                    </IconButton>
                                                                )}
                                                            </Grid>
                                                        }
                                                        {selectedProject.index == index && errors.projectName &&
                                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                                <Typography className={classes.errorText}>
                                                                    {errors.projectName}
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                    </TableCell>
                                                    <TableCell style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', gap: '10px', border: 'none' }}>
                                                        {
                                                            hasUserPermission("CHATBOTCONFIG") &&
                                                            <IconButton size={'small'} onClick={() => handleDeletePopup(chat.batchNumber)}>
                                                                <TrashIcon />
                                                            </IconButton>
                                                        }
                                                        <IconButton size='small' onClick={() => handleExpand(index, 'batch', chat.batchNumber)}>
                                                            {expandRow.batch == index ? <ExpandCollapseIcon /> : <ExpandIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                {expandRow.batch === index && (
                                                    KnowledgedBaseTypes.map((method, methodIndex) => (
                                                        <React.Fragment key={methodIndex}>
                                                            <TableRow style={{ backgroundColor: '#E5E8EA' }}>
                                                                <TableCell colSpan={2}>
                                                                    <span >{method}</span>
                                                                </TableCell>
                                                                <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <IconButton size='small' onClick={() => handleExpand(methodIndex, 'method', chat.batchNumber)}>
                                                                        {expandRow.method == methodIndex ? <ExpandCollapseIcon /> : <ExpandIcon />}
                                                                    </IconButton>
                                                                </TableCell>

                                                            </TableRow>
                                                            {expandRow.method == methodIndex && expandRow.batch === index && method == 'API Input' &&
                                                                <TableRow style={{ backgroundColor: '#E5E8EA' }}>
                                                                    <TableCell colSpan={3}>
                                                                        <Grid container style={{ marginBottom: '10px' }}>
                                                                            <Grid item xs={4} md={4}>
                                                                                <SearchBar onChange={searchFileName} />
                                                                            </Grid>
                                                                            <Grid item xs={8} md={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    startIcon={<AddIconTwo color='#FFFFFF' />}
                                                                                    onClick={handleAddToken}
                                                                                    className={classes.devicePulseSaveButton}
                                                                                    style={{ marginRight: '10px' }}
                                                                                >
                                                                                    Add Token
                                                                                </Button>
                                                                                <Button
                                                                                    startIcon={<UploadIcon color="white" />}
                                                                                    variant="contained"
                                                                                    className={classes.devicePulseSaveButton}
                                                                                    onClick={() => handlePopup('json', selectedApi)}
                                                                                >
                                                                                    Upload JSON
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow >
                                                                                    <TableCell style={{ backgroundColor: 'white' }} colSpan={2}>Source</TableCell>
                                                                                    <TableCell style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>Action</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {filteredApiConfigDetails.map(([fileName, records], fileIndex) => (
                                                                                    <React.Fragment key={fileIndex}>
                                                                                        {/* File Name Header */}
                                                                                        <TableRow style={{ backgroundColor: fileIndex % 2 === 1 ? '#FFFFFF' : '#ECEFF1', width: '100%' }}>
                                                                                            <TableCell colSpan={2}>{fileName}</TableCell>
                                                                                            <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                                <Grid container justifyContent="flex-end">
                                                                                                    <IconButton size="small" onClick={() => handleExpand(fileIndex, 'file', chat.batchNumber)}>
                                                                                                        {expandRow.file === fileIndex ? <ExpandCollapseIcon /> : <ExpandIcon />}
                                                                                                    </IconButton>
                                                                                                </Grid>
                                                                                            </TableCell>
                                                                                        </TableRow>

                                                                                        {/* Table Content */}
                                                                                        {expandRow.file === fileIndex && (
                                                                                            <TableRow>
                                                                                                <TableCell colSpan={3} style={{ backgroundColor: 'white' }}>
                                                                                                    <TableContainer component={Paper}>
                                                                                                        <ApiTable
                                                                                                            isAction={true}
                                                                                                            data={records}
                                                                                                            border={true}
                                                                                                            handlePopup={handlePopup}
                                                                                                        />
                                                                                                    </TableContainer>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {expandRow.method == methodIndex && expandRow.batch === index && method == 'File Upload' &&
                                                                <TableRow style={{ backgroundColor: '#E5E8EA' }}>
                                                                    <TableCell colSpan={3}>
                                                                        <EditChatbotConfigComponent
                                                                            open={isEditPopup}
                                                                            onClose={cancelPopup}
                                                                            chatConfig={selectedConfigDetails}
                                                                            step={1}
                                                                            setStep={setStep}
                                                                            steps={steps}
                                                                            STEPS={STEPS}
                                                                            selectedBatchNumber={selectedBatchNumber}
                                                                            projectNames={projectNames}
                                                                            setErrors={setErrors}
                                                                            errors={errors}
                                                                            setSteps={setSteps}
                                                                            setLoading={setLoading}
                                                                            setIsToasterOpen={setIsToasterOpen}
                                                                            setToasterMessage={setToasterMessage}
                                                                            setReqSuccess={setReqSuccess}
                                                                            handleExpand={() => handleExpand(index, 'batch', chat.batchNumber)}
                                                                        />

                                                                        {selectedConfigDetails && selectedConfigDetails.files != 'EMPTY' && selectedConfigDetails.fileNames.length > 0 &&
                                                                            <Grid container style={{ display: 'flex', marginBottom: '15px', padding: '16px' }} >
                                                                                {selectedConfigDetails && selectedConfigDetails.fileNames.map((file, index) => (
                                                                                    <Grid item key={index} style={{ display: 'flex', marginRight: '10px' }} >
                                                                                        <FileCard name={file} download={handleDownloadFile} deleteFile={() => deleteFile(index)}/>
                                                                                    </Grid>
                                                                                ))}
                                                                            </Grid>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>

                                                            }
                                                        </React.Fragment>
                                                    ))
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                No Chatbot Configuration Available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DeletePopup
                open={isDeletePopup}
                onClose={cancelPopup}
                name={selectedBatchNumber}
                onDelete={deleteChatbot}
            />

            <Loader loading={loading} />
            <ToasterComponent
                reqSuccess={reqSuccess}
                message={toasterMessage}
                toaster={isToasterOpen}
            />
            <AddAuthenticationToken
                setLoading={setLoading}
                setIsTokenDialog={setIsTokenDialog}
                getToken={getAuthentication}
                isTokenDialog={isTokenDialog}
                setToasterMessage={setToasterMessage}
                setIsToasterOpen={setIsToasterOpen}
                setReqSuccess={setReqSuccess}
                existTokens={authenticationTokens}
            />
            <DialogPopup
                open={isApiInputPopup.delete}
                buttonRequired={true}
                cancel='cancel'
                submit='delete'
                submitButtonColor='red'
                title='confirmation'
                dialogContent={`Are you sure you want to remove ${selectedApi.apiName} from this project?`}
                onClose={() => handlePopup('delete', selectedApi)}
                onSubmit={deleteApiDetails}
            />

            <DialogPopup
                open={isApiInputPopup.json}
                buttonRequired={jsonFiles && jsonFiles.length > 0 ? true : false}
                cancel='cancel'
                submit='upload'
                submitButtonColor='#005B96'
                title='upload json'
                dialogWidth='1200px'
                dialogContent={<ApiConfigurationComponent
                    handleJsonUpload={handleJsonUpload}
                    jsonData={fileData}
                    isAddJson={isAddJson}
                    handleAddJson={handleAddJson}
                    handleAddToken={handleAddToken}
                    selectedJsonFile={selectedJsonFile}
                    jsonFiles={jsonFiles}
                    handleDeletFile={handleDeleteJsonFile}
                    handleSelectAll={handleSelectAll}
                    handleSelectApi={handleSelectApi}
                    allTableData={allTableData}

                />}
                onClose={() => handlePopup('json', selectedApi)}
                onSubmit={uploadApiConfigure}
            />

            <DialogPopup
                open={isApiInputPopup.edit}
                title="edit API inputs"
                onClose={() => handlePopup('edit', selectedApi)}
                buttonRequired={true}
                cancel='cancel'
                submit='update'
                submitButtonColor='#005B96'
                dialogWidth='800px'
                dialogContent={
                    <Grid container>
                        <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                            <Grid item xs={3} md={3}>
                                <Typography style={{ color: '#868DAA' }}>API Name</Typography>
                                <Typography variant="body1" style={{ wordBreak: 'break-word' }}>{selectedApi.apiName}</Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Typography style={{ color: '#868DAA' }}>Description</Typography>
                                <Typography variant="body1" style={{ wordBreak: 'break-word' }}>{selectedApi.description}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body2" style={{ color: '#868DAA', fontWeight: 500 }}>
                                    Server URL
                                </Typography>
                                <Typography variant="body1" style={{ wordBreak: 'break-word' }}>
                                    {selectedApi.serverUrl}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} md={3}>
                                <Typography style={{ color: '#868DAA' }}>Source</Typography>
                                <Typography>{selectedApi.source}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography>Authentication Token</Typography>
                            <DropDown
                                options={authenticationTokens ? authenticationTokens.map((token) => token.name) : []}
                                type="token"
                                emptyTag="Select a token"
                                onClick={(e) => handleSelectToken(e)}
                                value={authenticationToken}
                                isSingle={true}
                            />
                        </Grid>
                    </Grid>
                }
                onSubmit={editApiDetails}
            />
            <DialogPopup
                open={isDeleteFile}
                title="Delete File"
                onClose={cancelDeletePopup}
                buttonRequired={true}
                dialogWidth='400px'
                cancel='cancel'
                submit='delete'
                submitButtonColor='red'
                dialogContent="Do you want to delete this file?"
                onSubmit={deleteJsonFile}
            />
            {selectedUploadFile && 
                <DialogPopup
                open={isFileUploadPopup.delete}
                buttonRequired={true}
                cancel='cancel'
                submit='delete'
                submitButtonColor='red'
                title='confirmation'
                dialogContent={`Are you sure you want to remove ${selectedUploadFile.name} from this project?`}
                onClose={() => setIsFileUploadPopup(prev => ({
                        ...prev,
                        delete:false
                    }))}
                onSubmit={handleDeleteFile}
                />
            }
            
        </ThemeProvider>
    )
}

export const FileCard = ({ name, download, deleteFile }) => {
    return (
        <Card style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '9px' }} >
            <Grid container style={{ display: 'flex', alignItems: 'center', padding: '4px 16px 4px 12px', border: '1px solid #EAEAEA', borderRadius: '9px' }}>
                <Grid item style={{ marginRight: '10px' }} onClick={() => { download(name) }}>
                    <PdfIcon />
                </Grid>
                <Grid item onClick={() => { download(name) }}>
                    <Typography>{name}</Typography>
                    {/* <Typography>{`${size} MB`}</Typography> */}
                </Grid>
                <Grid item>
                    <IconButton size='small' onClick={() => deleteFile()}>
                        <TrashIcon />
                    </IconButton>
                </Grid>

            </Grid>
        </Card>
    )
}

export const DeletePopup = ({ open, onClose, name, onDelete }) => {
    return (
        <Dialog open={open} >
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014' }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography>CONFIRMATION</Typography>
                    <IconButton onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Typography>{`Are you sure you want to permanently remove this chatbot configuration from the batch ${name}?`}</Typography>
                    <Grid container justifyContent='flex-end' style={{ margin: '15px 0px' }}>
                        <Button variant='outlined' style={{ marginRight: '15px', color: '#7F7C7C' }} onClick={onClose}>No</Button>
                        <Button variant='contained' style={{ backgroundColor: 'red', color: 'white' }} onClick={onDelete}> Yes</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
