import {Dialog, FormLabel, Grid, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import {useEffect, useState} from "react";
import {CloseIcon, CloseIconColor, ExpandIconTwo} from "../Icons";
import {TextBar} from "./TextBar";
import {primaryGray, textBarColor} from "../colors";
import useStyles from "src/app/maintenance/assets/styles";

const ViewConfiguration = (props) => {
  const device = props.device.device.deviceParameterConfiguration;

  const remoteDetails =
    props.device.device.deviceParameterConfiguration.remoteConfigurations;

  const [openIcons, setOpenIcons] = useState({});
  const [parameterValues, setParameterValues] = useState({});
  const [groupedParameters, setGroupedParameters] = useState({});
  const configurations = [
    "Network & Communication",
    "Topic Format & Interval",
    "Message Format",
  ];

  const localStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: "20px",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    helperText: {
      color: "red",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 1,
      width: "100%",
    },
    textBarStyle: {
      backgroundColor: textBarColor,
      height: "44px",
    },
    textBarMessageStyle: {
      width: "219px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    labelMessageStyle: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
      width: "121px",
    },
    backdrop: {
      opacity: 0,
      transition: "opacity 225ms cubic-bezier(0.4, 0, 0.1, 1) 0ms",
    },
    paper: {
      border: "solid 1px gray",
    },
  }));

  useEffect(() => {
    const updatedParameterValues = {};
    const updatedGroupedParameters = {};

    remoteDetails.forEach((item) => {
      const parameterName = item.parameter;
      const {defaultValue, id} = item;

      updatedParameterValues[parameterName] = item.defaultValue; //assuming same parameter name won't be there for configuration categories

      if (!updatedGroupedParameters[item.parameterCategory]) {
        updatedGroupedParameters[item.parameterCategory] = [];
      }

      updatedGroupedParameters[item.parameterCategory].push({
        name: parameterName,
        id: id,
        value: defaultValue,
      });
    });
    setParameterValues(updatedParameterValues);
    setGroupedParameters(updatedGroupedParameters);
  }, [remoteDetails]);

  const classes = localStyles();
  const style =  useStyles();
  const keyOrder = ["productType", "versionNum", "deviceCategory", "deviceIds"];
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "766px", height: "491px"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.4",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "1",
            boxShadow: "0px 4px 30px #00000008",
          }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>View Configuration</DialogTitle>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            className={style.customCloseIcon}
          >
            <IconButton onClick={props.onClose}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent style={{margin:"0px"}}>
            <Grid container className={classes.container}>
              {keyOrder.map((key, id) => {
                var label = "";
                if (key === "productType") {
                  label = "Product Type";
                } else if (key === "versionNum") {
                  label = "Version";
                } else if (key === "deviceCategory") {
                  label = "Device Category";
                } else if (key === "deviceIds") {
                  label = "Device IDs";
                } else {
                  label = key;
                }
                if (device.hasOwnProperty(key)) {
                  return (
                    <Grid
                      key={id}
                      item
                      xs={6}
                      md={12}
                      style={{
                        textAlign: "left",
                        marginBottom: "22px",
                        paddingRight: "2%",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: primaryGray,
                        }}
                      >
                        {label}
                      </Typography>
                      <TextBar
                        disabled={true}
                        style={classes.textBarStyle}
                        variant="outlined"
                        defaultValue={device[key]}
                        error={{error: false}}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>

            <div>
              {configurations.map((category, index) => (
                <div key={category}>
                  <Grid container className={classes.container}>
                    <Grid item xs={4} md={4}>
                      <FormLabel
                        style={{
                          alignItems: "center",
                          color: primaryGray,
                          opacity: "1",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "start",
                          fontSize: "16px",
                          lineHeight: "25px",
                        }}
                      >
                        {category}
                      </FormLabel>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      md={7}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Divider className={classes.divider}/>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        margin: "0px",
                      }}
                    >
                      <IconButton
                        style={{
                          backgroundColor: "ButtonShadow",
                          borderRadius: "80%",
                          boxShadow: "0px 3px 6px #00000029",
                          padding: "3px",
                        }}
                        onClick={() => {
                          setOpenIcons((prevState) => ({
                            ...prevState,
                            [category]: !prevState[category],
                          }));
                        }}
                      >
                        <ExpandIconTwo rotation={openIcons[category]}/>
                      </IconButton>
                    </Grid>
                  </Grid>

                  {openIcons[category] && (
                    <Grid container className={classes.container}>
                      {groupedParameters&&groupedParameters[category]&&groupedParameters[category].map((param) => (
                        <Grid
                          item
                          xs={6}
                          md={12}
                          key={param.name}
                          className={style.customLabel}
                        >
                          <Typography
                          className={style.typographyStyle}
                          >
                            {param.name}
                          </Typography>
                          <TextBar
                            id={param.name}
                            name={param.name}
                            disabled={true}
                            defaultValue={parameterValues[param.name]}
                            error={{error: false}}
                            style={classes.textBarStyle}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </div>
              ))}
            </div>
          </DialogContent>
        </>
      </Dialog>
    </div>
  );
};

export default ViewConfiguration;
