import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as React from 'react';
import {SideNavService} from 'src/app/layouts/side-menu/sidenav/sidenav.service';
import * as ReactDOM from 'react-dom';
import { NotificationComponent } from './Notification';

@Component({
  selector: 'app-notification-wrapper',
  template: `
    <div [id]="rootId"></div>`,
  styleUrls: ['../../../../components/react.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationWrapper implements OnChanges, OnDestroy, AfterViewInit {
    @Input() notification: any[] = [];
    @Input() closeNotification!: () => void;
    @Input() message: any[] = [];
    @Input() parseResponse!: (response: any) => void;
    @Input() isToaster: (isOpen: boolean) => void;
    @Input() success: (success: boolean) => void;
    @Input() toastMessage: (message: string) => void;
    @Input() rootId = 'notification';
    @Input() isLoading: (isLoading : boolean) => void
    @Input() isNotificationOpen: (isNotificationOpen : boolean) => void

    @Output() notificationCount = new EventEmitter<number>();
    chatCount = 0;

    constructor(private sideNavService: SideNavService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(this.notification)
        this.render();
    }

    ngAfterViewInit() {
        this.render();
    }

    ngOnDestroy() {
    }

    private render() {
        const container = document.getElementById(this.rootId);
        if(container){
            ReactDOM.render(
                React.createElement(NotificationComponent, {
                  notification: this.notification,
                  closeNotification: this.closeNotification,
                  message: this.message,
                  isToaster: this.isToaster,
                  success: this.success,
                  toastMessage: this.toastMessage,
                  chatCount : this.chatCount,
                  isLoading : this.isLoading,
                  notificationCount: this.handleNotificationCount.bind(this),
                  isNotificationOpen: this. isNotificationOpen
                }),
                document.getElementById(this.rootId)
            );
        }
        
    }
    private handleNotificationCount(count: number): void {
        this.notificationCount.emit(count);
    }
}

export {NotificationComponent};