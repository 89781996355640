import { Button, createTheme, Grid, IconButton, ThemeProvider, LinearProgress, Box, DialogTitle, Dialog, DialogContent, TextField } from '@material-ui/core'
import * as React from 'react'
import { DocumentIcon, PenIcon, TrashIcon, UploadIcon } from 'src/components/Icons';
import useStyles from '../../assets/styles';
import { DragDrop } from 'src/components/sub-components/DragAndDrop';
import { Upload, Icon, message, Progress, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { environment } from 'src/environments/environment';
import { Close } from '@material-ui/icons';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});

export const KnowledgeBasedUploadsComponents = ({
    setFiles,
    files,
    errors,
    setErrors,
    setDeleteFileType,
    deleteFileType,
    setIsDeleteConformation,
    isDeleteConformation
}) => {
    const classes = useStyles();
    const Dragger = Upload.Dragger;
    const [fileList,setFileList] = useState([])
    const [selectedFile,setSelectedFile] = useState({})
    const [selectedFileName,setSelectedFileName] = useState('')
    const [fileIndex,setFileIndex] = useState(0)
    const [isEdit,setIsEdit] = useState(false)
    const [editedFileName,setEditedFileName] = useState('')

    useEffect(() => {
        const interval = setInterval(() => {
            setFiles(prevFiles =>
                prevFiles.map(file => {
                    if (file.progress >= 100) return file; 
                    return { ...file, progress: file.progress + 10 }; 
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [files]);

    const handleFileChange = (info) => {
        // Filter out any files that already exist in the file list
        const newFiles = info.fileList.filter(file => {
            const existingFile = files.find(f => f.name === file.name);
            return !existingFile; // Only include files that are not already in the state
        }).map(file => ({
            name: file.name,
            size: (file.size / 1024 / 1024).toFixed(2),
            progress: 0,
            file: file.originFileObj,
        }));
    
        if (newFiles.length > 0) {
            setFileList(prev => [...prev, ...newFiles]);
            setFiles(prev => [...prev, ...newFiles.map(f => f.file)]);
        }
    };


    const props = {
        name: 'file',
        multiple: false, 
        accept: '.pdf', 
        onChange: handleFileChange,
        beforeUpload: (file) => {
            const isPdf = file.type === 'application/pdf';
            const isLt5MB = file.size / 1024 / 1024 < 5;
            const { status, name, size } = file;
        
            if (!isPdf) {
              message.error('You can only upload PDF files!');
              return false; 
            }
            // if (!isLt5MB) {
            //   message.error('File must be smaller than 5MB!');
            //   return false; 
            // }
            return false; 
        },
        showUploadList: false
    };

    const removeAFile = () =>{
        console.log(fileIndex)
        if(deleteFileType == "removeAllFile"){
            setFiles([])
            setIsDeleteConformation(false)
        }else{
            let selectedFiles = [...files]
            selectedFiles.splice(fileIndex,1)
            setFiles(selectedFiles)
            setIsDeleteConformation(false)
        }
    }

    const handleCancelDelete = () =>{
        setIsDeleteConformation(false)
    }

    const handleDeleteButton = (file,index) =>{
        setIsDeleteConformation(true)
        setSelectedFile(file)
        setSelectedFileName(file.name)
        setFileIndex(index)
        setDeleteFileType('removeAFile')
    }

    const handleEditIcon = (index,name) =>{
        setIsEdit(true)
        setFileIndex(index)
        setEditedFileName(name)
    }
    
      const handleFileNameChange = (e) => {
        setEditedFileName(e.target.value); // Update local state with the new file name
      };
    
      const handleBlurOrEnter = () => {
        setIsEdit(false); // Disable edit mode
        handleUpdateFileName(editedFileName, fileIndex); // Save the updated filename
      };

      const handleUpdateFileName = (newName, index) => {
        let updatedFiles = [...files];
        updatedFiles[index].name = newName;
        setFiles(updatedFiles);
      };

    return (
        <ThemeProvider theme={theme} >
            <div className='testing'>
                <Grid container direction='column'>
                    {files.length != 0 &&
                        <Grid container>
                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                <Upload {...props} accept='.pdf'>
                                    <Button
                                        variant="outlined"
                                        startIcon={<UploadIcon color='#2A7BEC' />}
                                        className={classes.button}
                                        style={{ borderColor: '#2A7BEC', color: '#2A7BEC' }}
                                    >
                                        Attach Files
                                    </Button>
                                </Upload>
                            </Grid>

                            <Grid container style={{ marginTop: '15px' ,maxHeight:'450px',overflowY:'auto'}}>
                                {files && (
                                    files.map((file, index) => (
                                        <Grid container key={index} direction='column' style={{border: '1px solid #CACACA',borderRadius:'4px',padding:'10px',marginTop:'15px'}}>
                                            <Grid item xs={12} md={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                                                        <DocumentIcon />
                                                        {isEdit && fileIndex == index ? (
                                                            <TextField
                                                                value={editedFileName}
                                                                onChange={handleFileNameChange}
                                                                onBlur={handleBlurOrEnter} // Save on blur (click outside)
                                                                onKeyPress={(e) => {
                                                                if (e.key === 'Enter') handleBlurOrEnter(); // Save on Enter key press
                                                                }}
                                                                autoFocus // Automatically focus the input when editing
                                                                style={{ margin: '0 10px', width: '150px' }}
                                                            />
                                                        )
                                                        :
                                                        (
                                                            <span style={{ color: '#353535', textAlign: 'center', fontFamily: 'Poppins', margin: '0 10px' }}>
                                                                {file.name}
                                                            </span>
                                                        )

                                                        }
                                                        <IconButton size="small" onClick={() => handleEditIcon(index,file.name)} disabled={file.progress != 100 ? true : false} style={{opacity:file.progress != 100 ? '0.5' : '1'}} >
                                                            <PenIcon />
                                                        </IconButton>
                                                        
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton size="small" onClick={() => handleDeleteButton(file,index)} disabled={file.progress != 100 ? true : false} style={{opacity:file.progress != 100 ? '0.5' : '1'}}>
                                                            <TrashIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ color: '#B4B2B2', textAlign: 'center', fontFamily: 'Poppins', display: 'flex' }}>
                                                {`${file.size} MB`}
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                                                <progress value={file.progress} max={100} style={{ width: '100%', marginRight: '10px' }} />
                                                <span>{`${file.progress}%`}</span>
                                            </Grid>
                                        </Grid>

                                    ))
                                )}
                            </Grid>


                        </Grid>
                    }

                    {files.length == 0 &&
                        <Grid container>
                            <Grid item className={classes.dragAndDrop} style={{borderColor:errors.KnowledgedBase ? 'red':'#CBD0DC',width:'100%'}}>
                                <Dragger {...props} accept='.pdf' onChange={handleFileChange}>
                                    <DragDrop />
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    {/* <p className="ant-upload-text" style={{ color: '#B4B2B2', textAlign: 'center' }}>maximum upload file size : 5MB</p> */}
                                </Dragger>
                            </Grid>
                            {errors.KnowledgedBase &&
                                <Grid item xs={12} style={{ padding: "0px 8px" ,textAlign:'left'}}>
                                    <Typography className={classes.errorText} >
                                        {errors.KnowledgedBase}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    }



                </Grid>
                <DeleteFileDialog 
                    open={isDeleteConformation} 
                    fileName={selectedFileName} 
                    onClose={handleCancelDelete} 
                    onDelete={removeAFile}
                    type={deleteFileType}
                />
            </div>
        </ThemeProvider>
    )
}


export const DeleteFileDialog = ({open,fileName,onClose,onDelete,type}) =>{
    return(
        <Dialog open ={open}>
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014' }}>
                <Grid container justifyContent='space-between'>
                    <Typography>DELETE CONFORMATION</Typography>
                    <IconButton size='small' onClick={onClose}>
                        <Close />
                    </IconButton>                        
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container style={{marginTop:'10px'}}>
                    <Grid item>
                        {type == "removeAllFile" ? "Are you sure you want to remove all files from this project ?" :`Are you sure you want to remove this file from this project ?`} 
                    </Grid>
                    <Grid item xs={12} md={12} style={{margin:'10px 0'}}>
                        <Grid container justifyContent='flex-end'>
                            <Button variant='outlined' style={{marginRight:'10px'}}  onClick={onClose}>No</Button>
                            <Button variant='contained' style={{backgroundColor:'red',color:'white'}} onClick={onDelete}> Yes</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}