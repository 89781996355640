import * as React from 'react'
import { DialogPopup } from '../../components/DialogPopup'
import { Grid, InputBase, Typography } from '@material-ui/core'
import useStyles from 'src/app/maintenance/assets/styles'
import { useState } from 'react'
import { saveToken } from '../../components/apiHelper'

export const AddAuthenticationToken = (props) => {
    const classes = useStyles()
    const [token, setToken] = useState({ name: "", token: "" })
    const [tokenError, setTokenError] = useState({
        tokenNameError: null,
        tokenError: null
    })

    const handleAddToken = () => {
        props.setIsTokenDialog(prev => !prev);
        setTokenError((prev) => ({
            ...prev,
            tokenNameError: null,
            tokenError: null
        }))
    }

    const handleSaveToken = async () => {
        props.setLoading(true)
        const error = tokenValidate();
        if (Object.keys(error).length > 0) {
            setTokenError(error);
            props.setLoading(false);
            return;
        }
        try {
            const response = await saveToken(token)
            if(response){
                props.setToasterMessage('Add Authentication Token Successfully. ')
                props.setIsToasterOpen(true)
                props.setReqSuccess(true)
            }
            props.getToken()
        } catch (error) {
            props.setToasterMessage(error.message)
            props.setIsToasterOpen(true)
            props.setReqSuccess(false)
        } finally {
            props.setLoading(false)
            props.setIsTokenDialog(false)
            setTimeout(() =>{
                props.setIsToasterOpen(false)
            },2500)
        }
    }

    const tokenValidate = () => {
        let error: any = {}
        if (token.name == "") {
            error.tokenNameError = "Token name required."
        }else if(props.existTokens.some(t => t.name.toLowerCase() === token.name.toLowerCase())){
            error.tokenNameError = "The Token Name Already Exists."
        }

        if (token.token == "") {
            error.tokenError = "Token required"
        }else if(props.existTokens.some(t => t.token.toLowerCase() === token.token.toLowerCase())){
            error.tokenError = "The Token Already Exists."
        }

        return error
    }

    const handleToken = (type, value) => {
        setToken(prev => ({
            ...prev,
            [type]: value
        }))
        if (type == 'name') {
            setTokenError((prev) => ({
                ...prev,
                tokenNameError: null
            }))
        } else if (type == 'token') {
            setTokenError((prev) => ({
                ...prev,
                tokenError: null
            }))
        }
    }
    return (
        <DialogPopup
            open={props.isTokenDialog}
            title="add token"
            onClose={handleAddToken}
            buttonRequired={true}
            cancel='cancel'
            submit='save'
            submitButtonColor='#005B96'
            dialogContent={
                <Grid container>
                    <Grid container>
                        <Typography>
                            Name
                        </Typography>
                        <InputBase
                            className={classes.formInput}
                            placeholder="Enter name here"
                            value={token.name}
                            onChange={(e) => handleToken('name', e.target.value.trim())}
                        />
                        {tokenError.tokenNameError &&
                            <Typography className={classes.errorText} style={{ textAlign: 'left' }}>
                                {tokenError.tokenNameError}
                            </Typography>
                        }
                    </Grid>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Typography>
                            Token
                        </Typography>
                        <InputBase
                            className={classes.formInput}
                            placeholder="Enter token"
                            value={token.token}
                            onChange={(e) => handleToken('token', e.target.value.trim())}
                        />
                        {tokenError.tokenError &&
                            <Typography className={classes.errorText} style={{ textAlign: 'left' }}>
                                {tokenError.tokenError}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            }
            onSubmit={handleSaveToken}
        />
    )
}