import { Box, Button, createTheme, Grid, IconButton, Paper, Popover, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import { ChatBotIcon, NotificationIcon } from 'src/components/Icons';
import { DropDown } from '../components/DropDown';
import useStyles from '../../assets/styles';
import { useEffect, useState } from 'react';
import { checkDbStatus, fetchBatchNumbers, fetchMainTestCases, getAllNotification, getContinuousTestCase, getDeviceTestSummery, getRecentRecord, getScheduleTimes, getSensorReading, getSuccessCriteria, saveJson } from '../components/apiHelper';
import { lightGray } from 'src/components/colors';
import { DataTable } from '../step4/DataTable';
import { Link } from 'react-router-dom';
import { Loader } from '../components/Loader';
import { Chatbot } from './Chatbot';
import { NotificationComponent } from './Notification';
import { TestSuccessCriteria } from '../step4/TestSuccessCriteria';
import { TestSummaryReport } from '../step4/TestSummaryReport';
import { ToasterComponent } from '../components/Toaster';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

export const ResultDashboardComponent = () => {
    const classes = useStyles()
    const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
    const [selectedTestCaseType, setSelectedTestCaseType] = useState('');
    const [streamingMethods, setStreamingMethods] = useState([
        "SenzMatica", "MQTT", "AWS", "Rest API", "Azure IoT"
    ]);
    const [testCaseTypes, setTestCaseTypes] = useState([
        "Production Feasibility Testing", "Continuous Testing"
    ]);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [batchNumbers, setBatchNumbers] = useState([]);
    const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
    const [selectedMainTestCaseTitle, setSelectedMainTestCaseTitle] = useState('');
    const config = {
        botAvatar: "img.png",
        floating: true,
    };
    const [selectedMainTestCaseId, setSelectedMainTestCaseId] = useState('');
    const [subTestCaseTitles, setSubTestCaseTitles] = useState([]);
    const [devices, setDevices] = useState([]);
    const [testData, setTestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [continuousMainTestId, setContinuousMainTestId] = useState("");
    const [scheduleTimes, setScheduleTimes] = useState([]);
    const [selectedScheduleTime, setSelectedScheduleTime] = useState('');
    const [selectedFormattedTime, setSelectedFormattedTime] = useState('');
    const [testCaseType, setTestCaseType] = useState('')
    const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
    const [openChatbot, setOpenChatbot] = useState(false)
    const [chatbotData, setChatbotData] = useState([])
    const [testCaseInfo, setTestCaseInfo] = useState({})
    const [isOpenNotification,setIsOpenNotification] = useState(false)
    const [notifications,setNotifications] = useState([])
    const [messages, setMessages] = useState([]);
    const [anchorEl,setAnchorEl] = useState(null)
    const [anchorEl_1,setAnchorEl_1] = useState(null)
    const [tableRootCause,setTableRootCause] = useState([])
    const [messageIdCounter,setMessageIdCounter] = useState(0);
    const [selectedSubTestCase, setSelectedSubTestCase] = useState('');

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)


    const  formatDate=(dateStr)=> {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); 
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}_${month}_${day}_${hours}_${minutes}`;
    }

    //handel errors
    const [errors, setErrors] = useState({
        streamNameError: null,
        batchNoError: null,
        mainTestNameError: null
    })

    const openNotification = (event) => {
        setIsOpenNotification(true)
        setAnchorEl_1(event.currentTarget);
    };

    const handleError = (name) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    }

    const onBatchDropClick = () => {
        if (selectedStreamingMethod === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                streamNameError: "Data Streaming Method is Required"
            }));
        }
    };

    const onMainDropClick = () => {
        if (selectedBatch === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                batchNoError: "Batch is required"
            }));
        }
    };

    const onTestTypeDropClick = () => {
        if (selectedMainTestCaseTitle === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                mainTestNameError: "Main Test Case is required"
            }));
        }
    };

    useEffect(()=>{
        const getMostRecentRecord = async () => {
            try {
                const data = await getRecentRecord();
                setSelectedStreamingMethod(data.dataStreamingMethod==="SENZMATE" ? "SenzMatica":data.dataStreamingMethod)
                setSelectedBatch(data.batchNumber)
                setSelectedMainTestCaseTitle(data.mainTestTitle)
                setSelectedTestCaseType("Continuous Testing")
                setTestCaseType(data.testCaseType)
                setSelectedMainTestCaseId(data.id)
                let d = {
                    "mainTestTitle":data.mainTestTitle,
                    "subTestCases":data.subTestCases
                }
                setTestCaseInfo(d)
                let subs=[]
                data.subTestCases.map((s)=>{
                    subs.push(s.subTestTitle);
                })
                setSubTestCaseTitles(subs)
                try {
                    const { mainTestTitles, content, uniqueDevices } = await fetchMainTestCases(data.batchNumber);
                    const uniqueMainTestTitles = [...new Set(mainTestTitles)];
        
                    setMainTestCasesTitles(uniqueMainTestTitles);
                    setMainTestCasesContent(content);
                    setDevices(uniqueDevices);
                } catch (error) {
                    console.error('Error fetching main test cases:', error);
                }
                
            } catch (err) {
                console.error('Error fetching batch numbers:', err);
            }        
        };
        getMostRecentRecord();
    },[])

    useEffect(() => {
        const getBatchNumbers = async () => {
            if (selectedStreamingMethod) {
                try {
                    const batchNumbers = await fetchBatchNumbers();
                    setBatchNumbers(batchNumbers);
                } catch (err) {
                    console.error('Error fetching batch numbers:', err);
                }
            }
        };
        getBatchNumbers();
    }, [selectedStreamingMethod]);


    // Get Continuous Test
    useEffect(() => {
        const fetchContinuousTestId = async () => {
            if (selectedTestCaseType === "Continuous Testing") {
                try {
                    const continuousTestId = await getContinuousTestCase(selectedBatch, selectedMainTestCaseTitle);
                    setContinuousMainTestId(continuousTestId);
                } catch (err) {
                    console.error('Error fetching continuous test case ID:', err);
                }
            } else {
                setContinuousMainTestId("");
            }
        };
        fetchContinuousTestId();
    }, [selectedTestCaseType, selectedBatch, selectedMainTestCaseTitle]);


    // Fetch test summary data
    useEffect(() => {
        const getTestSummaryData = async () => {
            
            try {
                if (selectedBatch == "" || selectedMainTestCaseTitle == "" || selectedTestCaseType == "") {
                    setTestData([])
                    return;
                }
                if (selectedTestCaseType === "Continuous Testing") {
                    if (!continuousMainTestId || !selectedScheduleTime) {
                        return;
                    }
                }
                setLoading(true)
                const testSummaryData = await getDeviceTestSummery(
                    selectedBatch,
                    selectedMainTestCaseTitle,
                    selectedTestCaseType,
                    continuousMainTestId,
                    selectedScheduleTime
                );
                setTestData(testSummaryData);
            } catch (err) {
                console.error('Error fetching test summary data:', err);
            }
        };
        getTestSummaryData();
    }, [
        selectedBatch,
        selectedMainTestCaseTitle,
        selectedTestCaseType,
        continuousMainTestId,
        selectedScheduleTime,
        selectedFormattedTime
    ]);

    // Fetch schedule times
    useEffect(() => {
        const fetchTimeZones = async () => {
            if (selectedTestCaseType === "Continuous Testing" && continuousMainTestId) {
                try {
                    let scheduleTimeAndDate = await getScheduleTimes(continuousMainTestId);
                    scheduleTimeAndDate = scheduleTimeAndDate.sort((a, b) => b.localeCompare(a));
                    const dateAndTime = []
                    scheduleTimeAndDate.map(schedule => {
                        var [year, month, day, hour, minute] = schedule.split("_");
                        const dateFormate = new Date(year, month - 1, day, hour, minute);

                        let date = dateFormate.toLocaleDateString('en-us')
                        let time = dateFormate.toLocaleTimeString('en-us')

                        let dateTime = date.concat('   ', time)
                        const dateTimeObject = {
                            [schedule]: dateTime
                        };
                        dateAndTime.push(dateTimeObject)

                    })
                    setScheduleTimes(dateAndTime);
                    if (scheduleTimeAndDate && scheduleTimeAndDate.length > 0) {
                        setSelectedFormattedTime(Object.values(dateAndTime[0]).toString())
                        setSelectedScheduleTime(Object.keys(dateAndTime[0]).toString());
                    }

                } catch (err) {
                    console.error('Error fetching schedule times:', err);
                }
            }
        };
        fetchTimeZones();
    }, [selectedTestCaseType, continuousMainTestId]);


    const handleSelectStreamingMethod = (value) => {
        setSelectedStreamingMethod(value);
        handleError('streamNameError')
    };

    const handleSelectBatchNumber = async (value) => {
        if (selectedStreamingMethod) {
            setErrors(prevErrors => ({
                ...prevErrors,
                streamNameError: null
            }));
        }
        setSelectedBatch(value);
        handleError('batchNoError')
        setLoading(true)
        try {
            const { mainTestTitles, content, uniqueDevices } = await fetchMainTestCases(value);
            const uniqueMainTestTitles = [...new Set(mainTestTitles)];

            setMainTestCasesTitles(uniqueMainTestTitles);
            setMainTestCasesContent(content);
            setDevices(uniqueDevices);
        } catch (error) {
            console.error('Error fetching main test cases:', error);
        }finally{
            // Clear dependent dropdown states
            setTestData([]);
            setSubTestCaseTitles([]);
            setSelectedMainTestCaseTitle('');
            setSelectedSubTestCase('')
            setSelectedTestCaseType('');
            setSelectedScheduleTime('');
            setSelectedFormattedTime('')
            setLoading(false)
        }
    };

    const handleSelectMainTestCase = async (value) => {
        if (selectedBatch) {
            setErrors(prevErrors => ({
                ...prevErrors,
                batchNoError: null
            }));
        }

        const trimmedValue = value.trim();
        setSelectedMainTestCaseTitle(trimmedValue);
        handleError('mainTestNameError')

        const selectedMainTestCase = mainTestCasesContent.find(testCase =>
            testCase.mainTestTitle === value
        );
        if (selectedMainTestCase) {
            setSelectedMainTestCaseId(selectedMainTestCase.id);
            const subTestTitles = selectedMainTestCase.subTestCases.map(subTestCase =>
                subTestCase.subTestTitle
            );
            setSubTestCaseTitles(subTestTitles);
        } else {
            setSubTestCaseTitles([]);
        }
        setSelectedSubTestCase('')
        setSelectedTestCaseType('');
        setSelectedScheduleTime('');
        setSelectedFormattedTime('')
    };

    const handleChatbot = async (event) => {
        try {
            setAnchorEl(event.currentTarget);
            const dbStatus = await checkDbStatus(selectedBatch);
            if(dbStatus.message==="No file is processing for this batch."){
                setOpenChatbot(true);
            }
            else{
                setOpenChatbot(false);
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(dbStatus.message);
                setTimeout(() => {
                  setIsToasterOpen(false);
                }, 5000);
                return
            }
          // Construct payload and send it to saveJson
          const payload = {
            results: chatbotData,
            testCaseInfo,
          };
          setMessages(prev => [
            ...prev,
            { sender: "bot", loading: true }, // This triggers the Skeleton
        ]);
      
          // Fetch the test summary data
          const testSummaryData = await saveJson(JSON.stringify(payload));
        if(testSummaryData){
            setMessages(prev =>
                prev.map((msg, index) =>
                    index === prev.length-1  && msg.loading ? { text: parseResponse(testSummaryData), sender: "bot"} : msg
                )
            );
        }
          
        }catch(err){
            console.log(err)
        }
    }
    const handleCloseChatbot = () => {
        setOpenChatbot(false)
        setAnchorEl(null)
        setMessages([]);
    }

    const handleSelectTestingMethod = (value) => {
        if (selectedMainTestCaseTitle) {
            setErrors(prevErrors => ({
                ...prevErrors,
                mainTestNameError: null
            }));
        }
        setSelectedTestCaseType(value);
        if (typeof value == "string" && value.includes('Continuous')) {
            setTestCaseType('CONTINUOUS')
        } else {
            setTestCaseType('INITIAL')
        }
    };

    const handleScheduleTime = (selectedValue) => {
        setSelectedFormattedTime(selectedValue.toString())
        const filterDate = scheduleTimes.filter(time => Object.values(time).includes(selectedValue.toString()));
        const dateAndTime = Object.keys(filterDate[0]).toString()
        setSelectedScheduleTime(dateAndTime);
    };

    const handleCloseNotification = () => {
        setIsOpenNotification(false)
        setAnchorEl_1(false)
    }

    useEffect(() => {
        const fetchSuccessCriteria = async () => {
            try {
                if (selectedTestCaseType != '') {
                    const response = await getSuccessCriteria(selectedMainTestCaseId);

                    // Check if the response is valid
                    if (response) {
                        let result = {
                            mainTestTitle: response.mainTestTitle,
                            subTestCases: response.subTestCases
                        };
                        setTestCaseInfo(result)
                    }
                }

            } catch (error) {
                console.error("Error fetching success criteria:", error);
            }
        };

        fetchSuccessCriteria();
    }, [selectedTestCaseType]); // Ensure dependencies include relevant IDs

    useEffect(() =>{
        const notification = async () =>{
            try{
                if(selectedBatch != ''){
                    const response = await getAllNotification(selectedBatch)
                    setTableRootCause(response)
                    if (response) {
                        const rootCauseResults = response[0]? response[0].rootCauseResults : {};
                        const content = Object.values(rootCauseResults);
                        const dates = Object.keys(rootCauseResults);

                        const formattedDates = dates.map(date =>{
                            let [year, month, day, hour, minute]  = date.split('_');
                            let formattedDate = `${year}-${month}-${day}`;
                            let formattedTime = `${hour}:${minute}`;
                            return `${formattedDate} ${formattedTime}`
                        })
                        if (content.length) {
                          const updatedData = content.map((subArray: any, index: number) =>
                            subArray.map((item: any) => ({
                              ...item,
                              date: formattedDates[index], // Using the date at the current index
                            }))
                          );
                          setNotifications(updatedData);
                        }
                    }
                        
                }   
            }catch(err){
                console.log('Error Fetching notifications :',err)
            }
        }
        notification()
    },[selectedBatch])

    const parseResponse = (text) => {
        // Split response into paragraphs by double newlines
        const paragraphs = text.split(/\n\n/);
        return paragraphs.map((para, paraIndex) => {
            if (para.includes('*')) {
                const listItems = para.split('\n');
                if(listItems){
                    return (
                        <div key={`list-${paraIndex}`} style={{ paddingBottom: '20px' }}>
                            {listItems.map((item, index) => {
                                // Check if the item is a list item (starts with '*')
                                if (item.startsWith('*')) {
                                    let htmlContent = convertToBold(item);
                                    return (
                                        <ul key={`list-${paraIndex}-${index}`} style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                            <li dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                        </ul>
                                    );
                                } else {
                                    // Handle as a paragraph with bold formatting
                                    let htmlContent = convertToBold(item);
                                    return (
                                        <p key={`para-${paraIndex}-${index}`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                    );
                                }
                            })}
                        </div>
                    );
                }
            }else if(para.includes('##')){
                const formattedPara = para.replace('##', '').trim(); // Remove '##' and trim whitespace
                return (
                    <h3 key={`header-${paraIndex}`}style={{marginBottom:'20px'}} dangerouslySetInnerHTML={{ __html: convertToBold(formattedPara) }} />
                );
            }else {
                return (
                    <p key={`paragraph-${paraIndex}`} dangerouslySetInnerHTML={{ __html: convertToBold(para) }} />
                
                );
            }
        });
      };

      const convertToBold = (text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      };

    return (
        <ThemeProvider theme={theme}>
            <div className='testing'>
                <div  style={{ marginBottom: "50px" }}>
                   
                </div>

                <Grid container alignItems="center" style={{ display: 'flex' }}>
                    <Grid item xs={3} md={3}></Grid>
                    <Grid item xs={6} md={6} style={{ textAlign: "center", paddingBottom: "40px" }}>
                        <h4>Result Dashboard</h4>
                    </Grid>
                    <Grid item xs={3} md={3} onClick={openNotification} style={{ display:'flex',justifyContent:'flex-end',opacity: selectedBatch != '' ? 1 : 0.4 }}>
                        <IconButton style={{cursor: selectedBatch != '' ? 'pointer' : 'not-allowed'}}  size="small">
                            <NotificationIcon />
                        </IconButton>   
                    </Grid>
                </Grid>
                <Grid container className={classes.root} justifyContent='center'>
                    <Grid item xs={12} md={10} >
                        <Grid container className={classes.formContent}>
                            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                                {/* Data Streaming Method */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Data Streaming Method: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={streamingMethods}
                                                type='streamingMethod'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectStreamingMethod}
                                                value={selectedStreamingMethod}
                                                handleError={handleError}
                                                isSingle={true}
                                            />
                                        </Grid>
                                        {errors.streamNameError && (
                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                <Typography className={classes.errorText}>
                                                    {errors.streamNameError}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Batch */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Test Batch: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={batchNumbers.map(batch => batch)}
                                                type='batchNumber'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectBatchNumber}
                                                value={selectedBatch}
                                                onBatchDropClick={onBatchDropClick}
                                                isSingle={true}
                                            />
                                        </Grid>
                                        {errors.batchNoError && (
                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                <Typography className={classes.errorText}>
                                                    {errors.batchNoError}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                                {/* Main Test Case Name */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Main Test Case Name: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={mainTestCasesTitles}
                                                type='mainTestCase'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectMainTestCase}
                                                value={selectedMainTestCaseTitle}
                                                isSingle={true}
                                                onMainDropClick={onMainDropClick}
                                            />
                                        </Grid>
                                        {errors.mainTestNameError && (
                                            <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                                <Typography className={classes.errorText}>
                                                    {errors.mainTestNameError}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Testing Methods */}
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.typo}>
                                                Testing Methods: <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropDown
                                                options={testCaseTypes}
                                                type='testingMethod'
                                                emptyTag='-Select-'
                                                setSelectOption={handleSelectTestingMethod}
                                                value={selectedTestCaseType}
                                                isSingle
                                                onTestTypeDropClick={onTestTypeDropClick}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Time Period */}
                            {selectedTestCaseType === "Continuous Testing" && continuousMainTestId && (
                                <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                                    <Grid item xs={12} md={3}>
                                        <DropDown
                                            options={scheduleTimes.map(time => Object.values(time))}
                                            type="scheduleTime"
                                            emptyTag="-Select-"
                                            setSelectOption={handleScheduleTime}
                                            value={selectedFormattedTime}
                                            isSingle={true}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {/* Test Success Criteria and Summary Report */}
                            <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                                <Grid item xs={12} md={6}>
                                <TestSuccessCriteria
                                    testData={testData}
                                    selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                    selectedMainTestCaseId={selectedMainTestCaseId}
                                    subTestCaseTitles={subTestCaseTitles}
                                    setSelectedSubTestCase={setSelectedSubTestCase}
                                    selectedSubTestCase={selectedSubTestCase}
                                    selectedTestCaseType={selectedTestCaseType}
                                />
                                </Grid>
                
                                <Grid item xs={12} md={6}>
                                <TestSummaryReport
                                    testData={testData}
                                    selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                    selectedMainTestCaseId={selectedMainTestCaseId}
                                />
                                </Grid>
                            </Grid>
                            {/* Data Table */}
                            <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                                <Grid item xs={12} md={12}>
                                    <DataTable
                                        selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                        testData={testData}
                                        selectedTestCaseType={selectedTestCaseType}
                                        testCaseType={testCaseType}
                                        loadingClose={() => {
                                            setLoading(false);
                                        }}
                                        selectedMainTestCaseId={selectedMainTestCaseId}
                                        step="step7"
                                        setChatbotData={setChatbotData}
                                        tableRootCause={tableRootCause[0]!==undefined && tableRootCause[0]["rootCauseResults"][formatDate(selectedFormattedTime)]}
                                        selectedFormattedTime={formatDate(selectedFormattedTime)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Back Button */}
                            <Grid container justifyContent="flex-end" style={{ marginBottom: "20px" }}>
                                <Grid item xs={12} container justifyContent="flex-end">
                                    <div
                                        className={classes.buttonContainer}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                        }}
                                    >
                                        <Link
                                            to={{
                                                pathname: `/TestAutomation`,
                                                state: selectedMainTestCaseTitle && selectedTestCaseType ? { fromTestReport: true } : null,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                className={classes.backButton}
                                            >
                                                Back
                                            </Button>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Loader loading={loading} />
                </Grid>
                <IconButton onClick={handleChatbot} style={{ background: 'linear-gradient(180deg, #2A7BEC 0%, #184686 100%)', borderRadius: '50%', position: 'fixed', right: '20px', bottom: '20px', opacity: testCaseType != '' ? 1 : 0.4 }} disabled={testCaseType != '' ? false : true}>
                    <ChatBotIcon color={'white'} size="32" />
                </IconButton>
                <Popover
                    open={openChatbot}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Chatbot 
                        handleClose={handleCloseChatbot} 
                        messages={messages} 
                        setMessages={setMessages}   
                        parseResponse={parseResponse}
                        messageCount={messageIdCounter}
                        selectedBatch={selectedBatch}
                    />
                </Popover>
                <Popover
                    open={isOpenNotification}
                    anchorEl={anchorEl_1}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <NotificationComponent notification={notifications} closeNotification={handleCloseNotification}/>
                </Popover>
                <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
            </div>
        </ThemeProvider>
    )
}