import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {environment} from "src/environments/environment";
import {secondaryTextColor, white} from "src/components/colors";
import {SearchBar} from "src/components/sub-components/SearchBar";
import {EditProductType} from "./EditProductType";
import {ViewProductType} from "./ViewProductType";
import {DeleteProductType} from "./DeleteProductType";
import { DeleteBlackIcon, EditIconTwo, ViewIcon } from "src/components/Icons";
import { primaryBlue } from "src/components/colors";
import axios from "axios";


export function ConfigurationProduct(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });
  const [userId, setUserId] = useState("");
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const [reviewOpen, setReviewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleConfigurationsClick = () => {
    history.push('/product-types');
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleReviewOpen = (props) => {
    setReviewOpen(true);
    setSelectedType(props);
  };
  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleEditOpen = (props) => {
    setEditOpen(true);
    setSelectedType(props);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteOpen = (props) => {
    setDeleteOpen(true);
    setSelectedType(props);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const getAllProducts = () => {
    axios.get(`${environment.host}/core/product/productTypes`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        const productsData = response.data.content || [];
        setProducts(productsData);

        // Flatten the products data to get unique product types
        const allProductTypes = productsData.reduce((acc, curr) => {
          acc[curr.productType] = true;
          return acc;
        }, {});
        setAllProducts(Object.keys(allProductTypes));
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setProducts([]);
        setAllProducts([]);
      });
  };

  useEffect(() => {
    const uId = localStorage.getItem("USER_ID");
    getAllProducts();
  }, []);

  return (
  <ThemeProvider theme={theme}>
    <div className={`testing`}>
      <div className="page-heading">
        Product Types
      </div>
    <Grid container className={classes.container}>
      <Grid container alignItems="center">
        <Grid item xs={10}></Grid>
          <Grid
            item
              xs={6}
              md={2}
              style={{textAlign: "left", paddingLeft: "10px"}}
            >
            <Button
              variant="contained"
              onClick={handleConfigurationsClick}
              className={classes.configuration}
              style={{textTransform: "none", whiteSpace:"nowrap" }}
            >
              Version Management
            </Button>
          </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} md={3}>
          <SearchBar placeholder="Search Product Name" onChange={(e) => setSearch(e.target.value)}/>
        </Grid>
        <Grid item xs={4} md={3}></Grid>
        <Grid item xs={4} md={6}>
          <TablePagination
            rowsPerPageOptions={[6, 10, 25]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{marginTop: "20px", marginLeft: "10px"}}
      >
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    display="inline"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "80px"
                    }}
                  >
                    PRODUCT NAME
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    display="inline"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    PROTOCOL
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    display="inline"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    TRANSCODING ENABLED
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    ACTIONS
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products !== undefined &&
                products
                  .filter((obj) => {
                    if (search === "") {
                      return obj;
                    } else if (
                      obj.productName
                        .toLowerCase()
                        .includes(search.trim().toLowerCase())
                    ) {
                      return obj;
                    }
                  })
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((product, i) => (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <Typography
                          display="inline"
                          className={classes.tableText}
                          style={{paddingLeft: "80px"}}
                        >
                          {product.productName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <Typography className={classes.tableText}>
                          {product.protocol}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <Typography
                          display="inline"
                          className={classes.tableText}
                          style={{
                            paddingLeft: "5px",
                            color: product.transcoder ? "black" : "grey"
                          }}
                        >
                          {product.transcoder ? "True" : "False"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <IconButton
                          style={{padding: "0px"}}
                          onClick={() =>
                            handleReviewOpen({
                              productType: product
                            })
                          }
                        >
                          <ViewIcon/>
                        </IconButton>
                        <IconButton
                          style={{padding: "0px", paddingLeft: "10px"}}
                          onClick={() => {
                            handleEditOpen({
                              productType: product
                            });
                          }}
                        >
                          <EditIconTwo/>
                        </IconButton>
                        <IconButton
                          style={{padding: "0px", paddingLeft: "10px"}}
                          onClick={() => {
                            handleDeleteOpen({
                              productType: product
                            });
                          }}
                        >
                          <DeleteBlackIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      
      {reviewOpen && (
        <ViewProductType
          productType={selectedType}
          open={reviewOpen}
          onClose={handleReviewClose}
        />
      )}
      {editOpen  && (
        <EditProductType
          productType={selectedType}
          open={editOpen}
          onClose={() => handleEditClose()}
        />
      )}
      {deleteOpen && (
        <DeleteProductType
          productType={selectedType}
          open={deleteOpen}
          onClose={handleDeleteClose}
        />
      )}
      
    </Grid>
  </div>
  </ThemeProvider>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  configuration: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
}));

