import * as React from "react";
import { Box, Button, createTheme, Divider, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import useStyles from "../assets/styles";
import { useState } from "react";
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { DropDown } from "./DropDown";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step3(props) {
  const classes = useStyles();

  const updateDetailsArray = (prevState, index, value) => {
    return prevState.map((detail, i) =>
      i === index
        ? {...detail, defaultValue: value, checkUpdate: true}
        : detail
    );
  };
  const handleInputChange = (event, index,setDetails) => {
    const {value} = event.target;
    const trimmedValue = value.trim();
    setDetails((prevState) => {
      const updatedDetailsArray = updateDetailsArray(
        prevState,
        index,
        trimmedValue
      );
      return updatedDetailsArray;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        
        
        <Grid container className={classes.root} style={{padding:"0px 80px"}}>
        <Grid container className={classes.formContent}>

            <Grid container  alignItems="center" style={{marginTop:"20px"}}>
                <Grid item xs={3} md={3} >
                  <Typography className={classes.typo}  
                    style={{textAlign:"left", fontWeight:"bold",fontSize:"18px" }}>
                    Topic Format & Interval 
                  </Typography>  
                </Grid>
                <Grid item xs={9} md={9}>
                  <Divider className={classes.divider}/> 
                </Grid>
            </Grid>

          
            {props.detailsArray.map((item, id) => {
                if(item.parameterCategory=="Topic Format & Interval"){
                  return(
             <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}} key={id}>
             <Grid item xs={6} md={6} style={{marginTop:"20px"}}>
                 <Typography className={classes.typo} style={{textAlign:"left"}}>
                   {item.parameter}
                   <span style={{ color: 'red' }}>*</span>
                 </Typography>  
                 <InputBase
                   className={classes.formInput}
                   type="text"
                   placeholder={`Enter ${item.parameter} Here`}
                   name={item.parameter}
                   value={item.defaultValue} 
                   onChange={(event) => handleInputChange(event, id,props.setDetailsArray)}
                 />
                 <Typography className={classes.errorText} >{item.errorMsg&&item.errorMsg}</Typography>
             </Grid>
           </Grid> )
                }}
          )}    
        
        <Grid container alignItems="center" style={{marginTop:"60px"}}>
            <Grid item xs={2} md={2}  >
              <Typography className={classes.typo} style={{textAlign:"left", fontWeight:"bold" , fontSize:"18px"}}>
                Message Format
              </Typography>  
            </Grid>
            <Grid item xs={10} md={10}  >
                <Divider className={classes.divider}/> 
            </Grid>
        </Grid>

        {props.detailsArray.map((item, id) => {
          if (item.parameterCategory=="Message Format"){
            return(
          <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}} key={id}>
          <Grid item xs={6} md={6}>
              <Typography className={classes.typo} style={{textAlign:"left"}}>
                {item.parameter}
                <span style={{ color: 'red' }}>*</span>
              </Typography>  
              <InputBase
                className={classes.formInput}
                type="text"
                placeholder={`Enter ${item.parameter} Here`}
                name={item.parameter}
                value={item.defaultValue?item.defaultValue:''} 
                onChange={(event) => handleInputChange(event, id,props.setDetailsArray)}
              />
              <Typography className={classes.errorText} >{item.errorMsg&&item.errorMsg}</Typography>
          </Grid>
        </Grid>) }}
      )}
          </Grid> 
          </Grid>

          
        {/* <Loader loading={loading}/>
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        /> */}
      </div>
    </ThemeProvider>
  );
}
