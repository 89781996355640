import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Grid, InputBase,Button,FormGroup, FormControlLabel, Checkbox, Typography, TextField, List, ListItem, DialogContent, DialogTitle, Dialog, IconButton, Box} from '@material-ui/core';
import {lightGray} from 'src/components/colors';
import {ThemeProvider, createTheme, makeStyles} from '@material-ui/core/styles';
import { DropDown} from '../components/DropDown';
import useStyles from '../../assets/styles';
import { SubTestCases } from './SubTestCases';
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';
import { AutoComplete } from 'antd';
import { 
  fetchBatchNumbers,
  defineTestCase, 
  fetchMainTestCases
} from '../components/apiHelper'; 
import { Close } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { convertToMinutes, clickBack } from 'src/app/utils';
import { RedirectIcon } from 'src/components/Icons';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function DefineTestCasesComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
  const [streamingMethods, setStreamingMethods] = useState([
    "SenzMatica", "MQTT", "AWS", "Rest API", "Azure IoT"
  ]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [mainTestCase, setMainTestCase] = useState('');
  const [description, setDescription] = useState('');
  const [subTestCases, setSubTestCases] = useState([{
    subTestCaseName: '',
    successCriteria: '',
    parameter: '',
    criteriaValue: '',
    subSuccessCriteria:'',
    shedulePeriod:'',
    durationValue:0,
    minValue: '',
    maxValue: ''
  }]);
  const [addAnotherTestCase, setAddAnotherTestCase] = useState(false);
  const [subTestCaseErrors, setSubTestCaseErrors] = useState([]);
  const [selectedPreviousStreamingMethod,setSelectedPreviousStreamingMethod] = useState('')
  const [conformationType,setConformationType] = useState('')
  const [previousBatch,setPreviousBatch] = useState('')

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mainTestCasesTitles,setMainTestCasesTitles] = useState([])
  const [suggestionsVisible,setSuggestionsVisible] = useState(false)
  const dropdownRef = useRef(null);
  const [isPromptOpen,setIsPromptOpen] = useState(false)

  //handel errors
  const [errors, setErrors] = useState({
    streamNameError: null,
    batchNoError: null,
    mainTestNameError: null,
    subTestCasesError:null
  })

  useEffect(() => {
    const getBatchNumbers = async () => {
      if (selectedStreamingMethod) {
        setLoading(true)
        try {
          const batchNumbers = await fetchBatchNumbers();
          setBatchNumbers(batchNumbers);

          if(batchNumbers.length > 0){
            setSelectedBatch(batchNumbers[0]);
            handleSelectBatchNumber(batchNumbers[0]);
          }

          const batchDetails = await fetchMainTestCases(batchNumbers[0])
          if(batchDetails){
            setMainTestCasesTitles(batchDetails.mainTestTitles)
          }
        } catch (err) {
          console.error('Error fetching batch numbers:', err);
        }finally{
          setLoading(false)
        }
      }
    };
    getBatchNumbers();
  }, [selectedStreamingMethod]); 

  const streamingMethodsMap = {
    "SenzMatica":"SENZMATE", 
    "MQTT":"MQTT",
    "AWS":"AWS", 
    "Rest API":"RESTAPI", 
    "Azure IoT":"AZUREIOT"
  };
  
  const successCriteriaMap = {
    "No of Data": "NO_OF_DATA",
    "Greater than": "GREATER_THAN",
    "Greater than or equal to": "GREATER_THAN_OR_EQUAL_TO",
    "Less than": "LESS_THAN",
    "Less than or equal to": "LESS_THAN_OR_EQUAL_TO",
    "Is equal to": "IS_EQUAL_TO",
    "Is not equal to": "IS_NOT_EQUAL_TO",
    "Is between": "IS_BETWEEN",
    "Is not between": "IS_NOT_BETWEEN",
    "Average": "AVERAGE",
    "Increasing": "IS_INCREASING",
    "Decreasing": "IS_DECREASING",
    "Is Value Present":"IS_VALUE_PRESENCE"
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSuggestionsVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validationForm = () => {
    let error: any = {};
    let subCaseErrors: any[] = [];
    if (selectedStreamingMethod == "") {
      error.streamNameError = "Data Streaming Method is required";
    }
    if (selectedStreamingMethod != "" && selectedBatch == "") {
      error.batchNoError = "Batch is required";
    }
    if (selectedBatch != "" && mainTestCase == "") {
      error.mainTestNameError = "Main Test Case Name is required";
    }
    const subTestCaseNames = subTestCases.map(testCase => testCase.subTestCaseName.trim());
    const duplicateIndices = subTestCaseNames
      .map((name, index) => subTestCaseNames.indexOf(name) !== index && name !== '' ? index : null)
      .filter(index => index !== null);
    const newSubTestCaseErrors = subTestCases.map((subTestCase, index) => {
      let subTestCaseError: any = {};
  
      // SubTestCaseName validation
      if (mainTestCase != "" && subTestCase.subTestCaseName === "") {
        subTestCaseError.subTestCaseNameError = "Sub Test Case Name is required";
      } else if (duplicateIndices.includes(index)) {
        subTestCaseError.subTestCaseNameError = "Sub-test case name is already in use. Please enter another name.";
      }
      if (subTestCase.subTestCaseName != "" && subTestCase.parameter === "") {
        subTestCaseError.parameterError = "Parameter is required";
      }
      if (subTestCase.parameter != "" && subTestCase.successCriteria === "") {
        subTestCaseError.successCriteriaError = "Success scenario is required";
      }
      if (
        ["No of Data", "Greater than", "Greater than or equal to", "Less than", "Less than or equal to", "Is equal to", "Is not equal to", "Average", "Is Value Present"]
          .includes(subTestCase.successCriteria) &&
        subTestCase.criteriaValue === ""
      ) {
        subTestCaseError.criteriaValueError = "Value is required";
      } else if (
        ["No of Data", "Greater than", "Greater than or equal to", "Less than", "Less than or equal to", "Is equal to", "Is not equal to", "Average", "Is Value Present"]
          .includes(subTestCase.successCriteria) &&
        isNaN(Number(subTestCase.criteriaValue))
      ) {
        subTestCaseError.criteriaValueError = "Only Numeric Characters are allowed";
      }

      if (["Is between", "Is not between", "Increasing", "Decreasing"].includes(subTestCase.successCriteria)) {
        // Min value validation
        if (subTestCase.minValue === "") {
          subTestCaseError.minValueError = "Min Value is required";
        } else if (isNaN(Number(subTestCase.minValue))) {
          subTestCaseError.minValueError = "Only Numeric Characters are allowed";
        } else if (Number(subTestCase.minValue) >= Number(subTestCase.maxValue)) {
          subTestCaseError.minValueError = "Min value must be less than Max value";
        }
        if (subTestCase.maxValue === "") {
          subTestCaseError.maxValueError = "Max Value is required";
        } else if (isNaN(Number(subTestCase.maxValue))) {
          subTestCaseError.maxValueError = "Only Numeric Characters are allowed";
        }
      }

      if (
        ["No of Data", "Average"]
          .includes(subTestCase.successCriteria) &&
        subTestCase.subSuccessCriteria === ""
      ) {
        subTestCaseError.subSuccessCriteriaError = "Criteria is required";
      }

      if (Object.keys(subTestCaseError).length > 0) {
        subCaseErrors[index] = subTestCaseError;
      }
    });
    if(subCaseErrors.length > 0){
      error.subTestCasesError = subCaseErrors;
    }
    setSubTestCaseErrors(subCaseErrors)
    return error;
  }

  const handleError = (name) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }

  const onBatchDropClick = () => {
    if (selectedStreamingMethod === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: "Data Streaming Method is Required"
      }));
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    
    if (name === 'mainTestCaseName') {
        if (selectedBatch === '') {
          setErrors(prevErrors => ({
            ...prevErrors,
            batchNoError: "Batch is required"
          }));
          return;
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          batchNoError: null
        }));
      setMainTestCase(value);
      handleError('mainTestNameError')
    }
    let filterTitle = mainTestCasesTitles.filter(title => title.toLowerCase().includes(value.toLowerCase()))
    if(value != '' && name === 'mainTestCaseName' && filterTitle.length > 0){
      setSuggestionsVisible(true)
      setMainTestCasesTitles(filterTitle)
    }else if(value == '' || filterTitle.length == 0){
      setSuggestionsVisible(false)
    }

    if (name === 'description') {
      setDescription(event.target.value);
    }     
  };
  
  const handleSelectStreamingMethod = (value) => {
    if(selectedStreamingMethod !== '' && value !== selectedStreamingMethod){
      
      if (selectedBatch !== '' || subTestCases.some(subTestCase => 
        Object.values(subTestCase).every(value => value !== '')
      )) {
        setIsPromptOpen(true);
        setConformationType('streamMethod')
        setSelectedPreviousStreamingMethod(value)
      }
    }else{
      setSelectedStreamingMethod(value)
    }
    handleError('streamNameError'); 
    setErrors(prevErrors => ({
      ...prevErrors, 
      streamNameError: null,
      batchNoError: null,
      mainTestNameError: null,
      subTestCasesError: null,
    }));
    setSubTestCaseErrors([])
  };

  const handleSelectBatchNumber = async (value) => {
    if(selectedBatch !== '' && value !== selectedBatch){
      
      if(mainTestCase !== "" || subTestCases.some(subTestCase => 
        Object.values(subTestCase).every(value => value !== '')
      )) {
        setIsPromptOpen(true);
        setPreviousBatch(value)
        setConformationType('batch')
      }else{
        setSelectedBatch(value)
        fetchMainTestTitle(value)
      }
    }
    handleError('batchNoError');
    setErrors(prevErrors => ({
      ...prevErrors, 
      streamNameError: null,
      batchNoError: null,
      mainTestNameError: null,
      subTestCasesError: null,
    }));
    setSubTestCaseErrors([])
  };

  const convertToMinutes = (value: number, period: string): number => {
    switch (period) {
      case "Days":
        return value * 24 * 60;
      case "Weeks":
        return value * 7 * 24 * 60;
      case "Months":
        return value * 30 * 24 * 60;
      case "Hours":
        return value * 60;
      case "Minutes":
      default:
        return value;
    }
  };
  
  const handleCheckboxChange = (event) => {
    setAddAnotherTestCase(event.target.checked);
  };

  const resetFormFields = () => {
    const resetSubTestCases = [{
      subTestCaseName: '',
      successCriteria: '',
      parameter: '',
      criteriaValue: '',
      subSuccessCriteria:'',
      shedulePeriod:'',
      durationValue:null,
      minValue: '',
      maxValue: ''
    }];
  
    if (selectedStreamingMethod !== '') {
      if (selectedBatch !== '') {
        setSelectedBatch(''); // Only reset the batch if it exists
      }
    } else {
      setSelectedStreamingMethod(''); // Reset streaming method if it's empty
      setSelectedBatch('');
    }
  
    setMainTestCase('');
    setDescription('');
    setSubTestCaseErrors([])
    setAddAnotherTestCase(false);
    setSubTestCases(resetSubTestCases);
    setIsPromptOpen(false)
  };

  const handleConformation = async () =>{
    resetFormFields()
    if(conformationType == 'batch'){
      setLoading(true)
      if (selectedStreamingMethod) {
        setErrors(prevErrors => ({
          ...prevErrors,
          streamNameError: null
        }));
      }
      setSelectedBatch(previousBatch)
      fetchMainTestTitle(previousBatch)
    }else if(conformationType == 'streamMethod'){
      setSelectedStreamingMethod(selectedPreviousStreamingMethod)
    }
    setLoading(false)
  }

  const fetchMainTestTitle = async (value) =>{
    const batchDetails = await fetchMainTestCases(value)
    if(batchDetails){
      setMainTestCasesTitles(batchDetails.mainTestTitles)
    }
  }

  const handleConformationCancel = () =>{
    setSelectedStreamingMethod(selectedStreamingMethod)
    setIsPromptOpen(false)
  }
  
  const handleBack = () => {
    resetFormFields();
    history.push(`/TestAutomation`);
  }

  const handleSubmit = async () => {
    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }
    const testCase = {
      batchNumber: selectedBatch,
      mainTestTitle: mainTestCase.trim(),
      subTestCases: subTestCases.map(subTestCase => {
        let minVal = subTestCase.minValue;
        let maxVal = subTestCase.maxValue;
        let convertedIntervalInHours = convertToMinutes(subTestCase.durationValue, subTestCase.shedulePeriod);
  
        if (["No of Data","Greater than", "Greater than or equal to", "Is equal to", "Is not equal to", "Average","Is Value Present"]
          .includes(subTestCase.successCriteria)) {
              minVal = subTestCase.criteriaValue;
              maxVal = subTestCase.criteriaValue;
        } 
        else if (["Less than", "Less than or equal to"]
          .includes(subTestCase.successCriteria)) {
              maxVal = subTestCase.criteriaValue;
        }
        return {
          subTestTitle: subTestCase.subTestCaseName.trim(),
          minVal,
          maxVal,
          successCriteria: successCriteriaMap[subTestCase.successCriteria],
          sensorCode: subTestCase.parameter,
          subSuccessCriteria: successCriteriaMap[subTestCase.subSuccessCriteria],
          sensorReadingDuration: convertedIntervalInHours
        };
      }),
      dataStreamingMethod: streamingMethodsMap[selectedStreamingMethod],
      description: description,
    };
    try {
      await defineTestCase(
          testCase, 
          setLoading, 
          setIsToasterOpen, 
          setToasterMessage, 
          setReqSuccess,
          history,
          addAnotherTestCase
      );
    } catch (error) {
      console.error('Error submitting test case:', error);

    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="testing">
        <Grid container className={classes.pageTitleContainer} style={{paddingLeft:'0px'}}>
          <IconButton onClick={() => clickBack(history, "/TestAutomation")}>
            <RedirectIcon />
          </IconButton>
          <Grid className={classes.pageTitle} style={{margin:'0px'}}>Define Test Case Criteria - Step 02</Grid>
        </Grid>
  
        <Grid container className={classes.root} style={{display: "flex", justifyContent: "center"}}>
          <Grid item xs={12} md={12}>
            <Grid container className={classes.formContent}>
              <Grid container className={classes.dataStreamBoxContainer}>
                <Grid item xs={12} md={12} style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"10px"}}>
                  {/* Data Streaming Method */}
                  <Grid item xs={12} md={4} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.typo}>
                        Data Streaming Method
                        <span style={{ color: 'red' }}>*</span>
                      </Typography> 
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <DropDown
                        options={streamingMethods}
                        type="streamingMethod"
                        emptyTag="-Select-"
                        setSelectOption={handleSelectStreamingMethod}
                        value={selectedStreamingMethod}
                        handleError={handleError}
                        isSingle={true}
                      />
                    </Grid>
                    {errors.streamNameError && (
                      <Grid item container xs={12} md={12} justifyContent="flex-end" alignItems="center" style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                          {errors.streamNameError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
      
                  {/* New Batch */}
                  <Grid item xs={12} md={4} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.typo}>
                        Test Batch 
                        <span style={{ color: 'red' }}>*</span>
                      </Typography> 
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <DropDown
                        options={batchNumbers.map(batch => batch)}
                        type="batchNumber"
                        emptyTag="-Select-"
                        setSelectOption={handleSelectBatchNumber}
                        value={selectedBatch}
                        onBatchDropClick={onBatchDropClick}
                        isSingle={true}
                      />
                    </Grid>
                    {errors.batchNoError && (
                      <Grid item container xs={12} md={12} justifyContent="flex-end" alignItems="center" style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                          {errors.batchNoError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
      
                  {/* Main Test Case Name */}
                  <Grid item xs={12} md={4} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.typo}>
                        Main Test Case Name
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>  
                    </Grid>
                    <Grid item xs={12} md={12} style={{ position: 'relative' }} ref={dropdownRef}>
                      <InputBase
                        className={classes.formInput}
                        type="text"
                        placeholder="Enter Main Test Case Name Here"
                        name="mainTestCaseName"
                        value={mainTestCase} 
                        onChange={handleInputChange}
                      />
                      {suggestionsVisible && (
                          <Grid item xs={12} md={12} className={classes.dropdown} style={{top:'85%',margin:"0px 8px",width:'-moz-available'}}>
                          {mainTestCasesTitles.map((title,index) =>(
                            <Grid 
                              container
                              alignItems="center"
                              justifyContent="space-between"
                              className={classes.option}
                              key={index}
                            >
                              <Grid item>{title}</Grid>
                            </Grid>
                            
                          ))}
                        </Grid>
                      )
                      }
                    </Grid>
                    {errors.mainTestNameError && (
                      <Grid item container justifyContent="flex-end" alignItems="center" style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                          {errors.mainTestNameError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
  
                {/* Sub Test Cases */}
                <SubTestCases
                  subTestCases={subTestCases}
                  setSubTestCases={setSubTestCases}
                  subTestCaseErrors={subTestCaseErrors}
                  selectedBatch={selectedBatch}
                  setSubTestCaseErrors={setSubTestCaseErrors}
                  setErrors={setErrors}
                  mainTestCaseName={mainTestCase}
                />
  
                {/* Description */}
                <Grid container alignItems="flex-start" style={{ marginTop: "20px" }}>
                  <Grid item xs={12} md={12} className={classes.label}>
                    <Typography className={classes.typo}>
                      Description
                    </Typography> 
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <InputBase
                      className={classes.formInput}
                      style={{ height: "100px" }}
                      type="text"
                      placeholder="Enter Description Here"
                      name="description"
                      value={description}
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid> 
  
                {/* Add Another Test Case */}
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{ marginTop: "20px" }}>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        label={
                          <Typography className={classes.typo}>
                            Add Another Test Case
                          </Typography> 
                        }
                        control={
                          <Checkbox
                            className={classes.radio}
                            checked={addAnotherTestCase}
                            onChange={handleCheckboxChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
  
                {/* Buttons */}
                <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={`${classes.devicePulseSaveButton} ${classes.button}`}
                      onClick={handleSubmit}
                    >
                      Submit 
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
  
        <Loader loading={loading} />
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        />
        <PromptPopup 
          open={isPromptOpen} 
          setIsOpen={setIsPromptOpen} 
          submitConformation={handleConformation}
          cancelConformation = {handleConformationCancel}
          type ={conformationType}
        />
      </div>
    </ThemeProvider>
  );
}


export const PromptPopup = ({open,setIsOpen,submitConformation,cancelConformation,type}) =>{

  const handleClose = () =>{
    setIsOpen(false)  
  }
  return(
    <Dialog open={open} >
      <Box sx={{boxShadow:'0px 0px 15px 0px #00000014',margin:'0px'}}>
        <DialogTitle>
          <Grid container  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
            <Typography>CONFORMATION</Typography>
            <IconButton onClick={handleClose} style={{ paddingRight: '0px' }} size="small">
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container style={{margin:'10px 0'}}>
          <Grid item>
            {type == 'streamMethod' ? 
              <Typography>All your progress will be lost if you change the data streaming method. Are you sure you want to proceed?</Typography>
              :
              <Typography>All your progress will be lost if you change the batch number. Are you sure you want to proceed?</Typography>
            }
             </Grid>
          <Grid item xs={12} md={12} style={{display:'flex',justifyContent:'flex-end',marginTop:'10px'}}>
            <Button variant="outlined" onClick={cancelConformation} style={{marginRight:'15px'}}>No</Button>
            <Button variant="contained" onClick={submitConformation} style={{backgroundColor:'red'}}>Yes</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
