import { Box, Button, Checkbox, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputBase, Radio, Tab, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Arrow, CancelIcon, DocumentIcon, EditIcon, EditIconLarge, FilterIcon, NonSuccessCriteriaIcon, RootCauseIcon, TrashIcon, ViewIcon } from 'src/components/Icons';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Close, Delete, Description, Edit } from '@material-ui/icons';
import useStyles from '../maintenance/assets/styles';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { formatSuccessCriteria, handleDevicePulseClicked } from '../utils';
import { DescriptionComponent } from '../maintenance/test-automation/step5/step2/Description';
import { batch } from 'react-redux';
import { SelectAModelComponent } from '../maintenance/test-automation/step5/step2/SelectAModelComponent';
import { getAvailableModels, fetchMainTestCases, getUserRole, technicianRole } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { RootCause } from '../models';
import { hasUserPermission } from '../permission';
import Cookies from "js-cookie";

export interface ViewPopupProps {
    openView: boolean;
    handleWindowClose: () => void;
    rootCause: RootCause;
  }

export const RootCauseConfigComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [rootCauses, setRootCauses] = useState([]);
    const [filteredRootCauses, setFilteredRootCauses] = useState([]);
    const [selectedRootCause, setSelectedRootCause] = useState(null);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [formValue, setFormValue] = useState({
        batchNumber: '',
        modelDetails: {},
        rootCauseNotificationDurationInMinutes: '',
        period: 'MINUTES',
        selectedMainTestCaseId: '',
    });
    const [selectedMainTestCaseId,setSelectedMainTestCaseId] = useState('')
    const [selectedModel,setSelectedModel] = useState('')

    const [errors, setErrors] = useState({
        testCase: '',
        model: '',
        rootCauseNotificationDurationInMinutes: '',
        period : ''
    });

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [reqSuccess, setReqSuccess] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterVisible, setFilterVisible] = useState(false);
    const [batchPrefixes, setBatchPrefixes] = useState([]);
    const [selectedPrefix, setSelectedPrefix] = useState(null);
    const [tempSelectedPrefix, setTempSelectedPrefix] = useState(null);
    const [modelNames, setModelNames] = useState([]);
    const [tempSelectedModel, setTempSelectedModel] = useState(null);
    const [fetchedTestCases, setFetchedTestCases] = useState([]);
    const [testCasesByBatch, setTestCasesByBatch] = useState({});

    const userId = Cookies.get('USER_ID')
    const handleEditPopup = (rootCause) => {
        const durationInMinutes = rootCause.rootCauseNotificationDurationInMinutes || 0;
        const period = rootCause.period;

        const durationValue = convertToPeriod(durationInMinutes, period);

        setSelectedRootCause(rootCause);
        let validMainTestCaseId = '';
        let selectedModel = '';

        const modelDetailsKeys = Object.keys(rootCause.modelDetails);
        if (modelDetailsKeys.length > 0) {
            validMainTestCaseId = modelDetailsKeys[0];

            const firstModelDetail = rootCause.modelDetails[validMainTestCaseId];
            if (firstModelDetail) {
                selectedModel = firstModelDetail.model;
            }
        }

        setFormValue({
            batchNumber: rootCause.batchNumber || '',
            modelDetails: rootCause.modelDetails || {},
            rootCauseNotificationDurationInMinutes: durationValue,
            period: period,
            selectedMainTestCaseId: validMainTestCaseId,
        });

        setSelectedMainTestCaseId(validMainTestCaseId);
        setSelectedModel(selectedModel);
        setIsEditPopupOpen(true);
    };

    const resetFormFields = () =>  {
        setFormValue({
            batchNumber: '',
            modelDetails: {},
            rootCauseNotificationDurationInMinutes: '',
            period : '',
            selectedMainTestCaseId: '',
        });
        setErrors({
            testCase: '',
            model: '',
            rootCauseNotificationDurationInMinutes: '',
            period : ''
        });
    }


    const convertToMinutes = (value, period) => ({
        days: value * 1440,
        weeks: value * 10080,
        months: value * 43200,
        hours: value * 60,
        minutes: value,
    }[period.toLowerCase()] || value);

    const convertToPeriod = (minutes, period) => ({
        DAYS: minutes / 1440,
        WEEKS: minutes / 10080,
        MONTHS: minutes / 43200,
        HOURS: minutes / 60,
        MINUTES: minutes,
    }[period] || minutes);

    const cancelEditPopup = () => {
        setIsEditPopupOpen(false);
    }

    const handleViewPopup = (rootCause) =>{
        setSelectedRootCause(rootCause);
        setIsViewPopupOpen(true)
    }

    const cancelViewPopup = () =>{
        setIsViewPopupOpen(false)
    }

    const handleDeletePopup = (rootCause) => {
        setSelectedRootCause(rootCause);
        setIsDeletePopupOpen(true);
    }

    const cancelDeletePopup = () => {
        setIsDeletePopupOpen(false);
    }

    const extractBatchPrefixesAndModels = (rootCauses) => {
        const prefixes = new Set();
        const models = new Set();
    
        rootCauses.forEach(rootCause => {
            if (rootCause.batchNumber && typeof rootCause.batchNumber === 'string' && /[_-]/.test(rootCause.batchNumber)) {
                const match = rootCause.batchNumber.match(/^[A-Za-z0-9]+(?=[_-])/);
                if (match) prefixes.add(match[0]);
            }
    
            if (rootCause.modelDetails && Object.values(rootCause.modelDetails).length > 0) {
                Object.values(rootCause.modelDetails).forEach(model => {
                    if ((model as { model: string }).model) {
                        models.add((model as { model: string }).model);
                    }
                });
            }
        });
    
        setBatchPrefixes([...prefixes]);
        setModelNames([...models]);
    };

    const fetchRootCauses = async () => {
        setLoading(true);
         try {
            const response = await axios.get(`${environment.host}/core/user/${userId}/rootCause`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")
                }
            });
            const data = response.data;
            setRootCauses(data.content);
            setFilteredRootCauses(data.content);
            extractBatchPrefixesAndModels(data.content);
        } catch(err) {
            console.log('Error fetching root causes', err);
        } finally {
            setLoading(false);
        }
    };

    const getOnGoingContinuousTests = async () => {
        try {
          const response = await axios.get(
            `${environment.host}/core/user/${userId}/ongoingContinuousTests`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
              },
            }
          );
          const allData = response.data;
          const data = allData.content || [];

          const testCasesByBatch = data.reduce((acc, testCase) => {
            acc[testCase.batchNumber] = testCase.mainTestTitle;
            return acc;
          }, {});

          return {
            testCasesByBatch,
            content: data,
          };
        } catch (err) {
          console.error('Error fetching main test cases:', err);
          return { testCasesByBatch: {}, content: [] };
        }
    };

    useEffect(() => {
        getOnGoingContinuousTests().then(data => {
            setFetchedTestCases(data.content);
            setTestCasesByBatch(data.testCasesByBatch);
        });
    }, []);


    const updateRootCauses = async (rootCause) => {
        setLoading(true);
        try {
            const payload = {
                ...rootCause
            };

            const response = await axios.put(`${environment.host}/core/user/${userId}/rootCause/${selectedRootCause.id}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN"),
                }
            });

            if (response.status === 200) {
                console.log('Root Cause Updated Successfully', response.data);
                setIsToasterOpen(true);
                setToasterMessage('Root Cause Model Updated Successfully');
                setReqSuccess(true);
                setTimeout(() => {
                    setIsToasterOpen(false);
                }, 5000);

                await fetchRootCauses();
                setIsEditPopupOpen(false);
                resetFormFields();
            }
        } catch (err) {
            const errorData = err.response.data;
            if (err.response.status === 422) {
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(errorData.message);
                setTimeout(() => {
                    setIsToasterOpen(false);
                }, 5000);
            }
            console.log('Error updating root cause', err);
        } finally {
            setLoading(false);
        }
    };

    const deleteRootCause = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${environment.host}/core/user/${userId}/rootCause/${selectedRootCause.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")
                }
            });
            console.log('Root Cause Deleted Successfully', response.data);
            setIsDeletePopupOpen(false);
            setIsToasterOpen(true);
            setToasterMessage('Root Cause Model Removed Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);

            setSelectedPrefix("ALL");
            setTempSelectedPrefix("ALL");

            await fetchRootCauses();
        } catch(err) {
            console.log('Error deleting root cause', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRootCauses();
    }, []);

    useEffect(() => {
        filterRootCauses();
    }, [selectedPrefix, selectedModel, searchTerm]);

    const filterRootCauses = () => {
        if (!selectedPrefix && !searchTerm && !selectedModel) {
            setFilteredRootCauses(rootCauses);
            return;
        }
    
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
        const filtered = rootCauses.filter(rootCause => {
            const matchesPrefix = !selectedPrefix || rootCause.batchNumber.startsWith(selectedPrefix);
    
            const matchesSearch = rootCause.batchNumber.toLowerCase().includes(lowerCaseSearchTerm);
    
            const matchesModel =
                !selectedModel ||
                (rootCause.modelDetails &&
                    Object.values(rootCause.modelDetails).some(model => (model as { model: string }).model === selectedModel));
    
            return matchesPrefix && matchesSearch && matchesModel;
        });
    
        setFilteredRootCauses(filtered);
    };
    
    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setTempSelectedPrefix(prefix);
        setTempSelectedModel(null);
    };

    const handleModelSelect = (model) => {
        setTempSelectedModel(model);
        setTempSelectedPrefix(null);
    };

    const applyFilter = async () => {
        setSelectedPrefix(tempSelectedPrefix);
        setSelectedModel(tempSelectedModel);
        setFilterVisible(false);
    };
    
    const cancelFilter = () => {
        setTempSelectedPrefix(null);
        setTempSelectedModel(null);
        setSelectedPrefix(null);
        setSelectedModel(null);
        setFilterVisible(false);
    };


    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px'}}>
                <Grid item xs={12} md={12}>
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>Root Cause Config</Grid>
                    <Grid container className={classes.redirectContainer}>
                        <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                            <Link to={'/TestAutomation'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                                <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                                    <NonSuccessCriteriaIcon />
                                </IconButton>
                            </Link>
                        </Grid>
                        <Arrow/>
                        <Grid item style={{display:'flex',alignItems:'center'}}>
                            <RootCauseIcon />
                            <Typography className={classes.redirectTitle}>Root Cause Config</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.backgroundContainer}>
                        <Grid container alignItems="center" style={{ flexWrap: 'nowrap', gap: '15px'}}>
                            <Grid item className="search-box">
                                <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search"
                                className="search-input"
                                style={{ marginRight: '8px' }}
                                />
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='search-icon'>
                                    <path d="M10.7579 9.99018L8.7483 7.99685C9.52835 7.02424 9.90611 5.78972 9.80391 4.54714C9.7017 3.30455 9.12729 2.14836 8.19879 1.31628C7.27029 0.484206 6.05828 0.0394997 4.81197 0.0736033C3.56565 0.107707 2.37977 0.618028 1.49817 1.49963C0.616563 2.38124 0.106242 3.56712 0.0721384 4.81343C0.0380349 6.05974 0.482741 7.27176 1.31482 8.20025C2.14689 9.12875 3.30309 9.70316 4.54567 9.80537C5.78825 9.90758 7.02277 9.52982 7.99538 8.74976L9.98872 10.7431C10.0391 10.7939 10.099 10.8342 10.165 10.8617C10.231 10.8892 10.3018 10.9033 10.3733 10.9033C10.4448 10.9033 10.5156 10.8892 10.5816 10.8617C10.6476 10.8342 10.7075 10.7939 10.7579 10.7431C10.8555 10.6421 10.9101 10.5071 10.9101 10.3666C10.9101 10.2262 10.8555 10.0912 10.7579 9.99018ZM4.95663 8.74976C4.20671 8.74976 3.47363 8.52739 2.8501 8.11075C2.22656 7.69412 1.74057 7.10194 1.45359 6.40911C1.16661 5.71627 1.09152 4.95389 1.23782 4.21838C1.38412 3.48287 1.74525 2.80726 2.27552 2.27699C2.80579 1.74671 3.48141 1.38559 4.21692 1.23929C4.95243 1.09299 5.71481 1.16807 6.40764 1.45506C7.10048 1.74204 7.69266 2.22802 8.10929 2.85156C8.52592 3.4751 8.7483 4.20818 8.7483 4.9581C8.7483 5.96371 8.34882 6.92814 7.63775 7.63921C6.92667 8.35029 5.96225 8.74976 4.95663 8.74976Z" fill="#7F7C7C"/>
                                </svg>

                            </Grid>
                            <Grid item className="filter-validity" style={{ position: 'relative', display: 'inline-block', zIndex: 1 }}>
                                <div className="filerButton" onClick={toggleFilter} style={{ display: 'flex', alignItems: 'center' }}>
                                    <FilterIcon />
                                    <span style={{ marginLeft: '4px' }}>Filter</span>
                                </div>
                                {filterVisible && (
                                    <div className={classes.filterContainer}>
                                        <div className={classes.filterHeadingContainer}>
                                            Filter
                                        </div>
                                        <div className={classes.subFilterContainer}>
                                            <div className={classes.filterRadioContainer}>
                                                <Radio
                                                    value="ALL"
                                                    checked={!tempSelectedPrefix && !tempSelectedModel}
                                                    className={classes.radio}
                                                    onChange={() => {
                                                        setTempSelectedPrefix(null);
                                                        setTempSelectedModel(null);
                                                    }}
                                                    color="default"
                                                />
                                                <label style={{ marginLeft: '8px' }}>ALL</label>
                                            </div>

                                            <div className={classes.filterSubHeading}>
                                                Test Batch
                                            </div>
                                            {batchPrefixes.map((prefix, index) => (
                                                <div key={index} className={classes.filterRadioContainer}>
                                                    <Radio
                                                        className={classes.radio}
                                                        value={prefix}
                                                        checked={tempSelectedPrefix === prefix}
                                                        onChange={() => handlePrefixSelect(prefix)}
                                                        color="default"
                                                    />
                                                    <label style={{ marginLeft: '8px' }}>{prefix}</label>
                                                </div>
                                            ))}

                                            <div className={classes.filterSubHeading}>
                                                Model Name
                                            </div>
                                            {modelNames.map((model, index) => (
                                                <div key={index} className={classes.filterRadioContainer}>
                                                    <Radio
                                                        className={classes.radio}
                                                        value={model}
                                                        checked={tempSelectedModel === model}
                                                        onChange={() => handleModelSelect(model)}
                                                        color="default"
                                                    />
                                                    <label style={{ marginLeft: '8px' }}>{model}</label>
                                                </div>
                                            ))}

                                            <div className={classes.filterButtonContainer}>
                                                <Button onClick={cancelFilter} className={classes.cancelButton} style={{ marginBottom: '0px', width:'45%' }}>Cancel</Button>
                                                <Button onClick={applyFilter} className={classes.addButton} style={{ backgroundColor: '#005B96', color: 'white' }}>
                                                    Apply Filter
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}                                         
                            </Grid>
                        </Grid>
                        <Grid container className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tablehead} align="left" style={{width:'20%'}}>Batch Name</TableCell>
                                        <TableCell className={classes.tablehead} align="left" style={{width:'30%'}}>Main Test Case</TableCell>
                                        <TableCell className={classes.tablehead} align="left" style={{width:'30%'}}>Model Name</TableCell>
                                        <TableCell className={classes.tablehead} style={{width:'20%'}} align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRootCauses.length > 0 ? (
                                        filteredRootCauses.map((rootcause, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow style={{
                                                    backgroundColor:
                                                     index % 2 === 0 ? "#F5F5F5" : "white"
                                                }}>
                                                    <TableCell align="left">{rootcause.batchNumber}</TableCell>
                                                    <TableCell align="left">{testCasesByBatch[rootcause.batchNumber] || "N/A"}</TableCell>
                                                    <TableCell align="left">
                                                        {rootcause.modelDetails && Object.values(rootcause.modelDetails).length > 0 && (
                                                            formatSuccessCriteria((Object.values(rootcause.modelDetails)[0] as any).model)
                                                        )}
                                                    </TableCell>
                                                    <TableCell style={{ display: 'flex', justifyContent:  hasUserPermission("ROOTCAUSECONFIG") ?'flex-end':'center', paddingRight:'55px', alignItems:'center', gap:'10px'  }}>
                                                        <IconButton size={'small'} onClick={() => handleViewPopup(rootcause)}>
                                                            <ViewIcon color = '#707070'/>
                                                        </IconButton>
                                                        {
                                                            hasUserPermission("ROOTCAUSECONFIG") && 
                                                        
                                                            <IconButton size={'small'} onClick={() => handleEditPopup(rootcause)}>
                                                                <EditIcon stroke={'#707070'} />
                                                            </IconButton>
                                                            }  
                                                        {
                                                           hasUserPermission("ROOTCAUSECONFIG") && 
                                                            
                                                            <IconButton size={'small'} onClick={() => handleDeletePopup(rootcause)}>
                                                                <TrashIcon />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} style={{ textAlign:'center' }}>
                                                No Root Causes Available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ViewPopup
                openView={isViewPopupOpen}
                handleWindowClose={cancelViewPopup}
                rootCause={selectedRootCause}
            />
            <EditPopup
                open={isEditPopupOpen}
                handleWindowClose={cancelEditPopup}
                rootCause={selectedRootCause}
                updateRootCauses={updateRootCauses}
                formValue={formValue}
                setFormValue={setFormValue}
                selectedMainTestCaseId={selectedMainTestCaseId}
                setSelectedMainTestCaseId={setSelectedMainTestCaseId}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                convertToMinutes={convertToMinutes}
                resetFormFields={resetFormFields}
                errors={errors}
                setErrors={setErrors}
            />
            <DeletePopup
                open={isDeletePopupOpen}
                closePopup={cancelDeletePopup}
                rootCause={selectedRootCause}
                deleteRootCause={deleteRootCause}
            />
            <Loader loading={loading} />
            <ToasterComponent
                toaster={isToasterOpen}
                message={toasterMessage}
                reqSuccess={reqSuccess}
            />
        </ThemeProvider>
    );
};

export const ViewPopup = ({ openView, handleWindowClose, rootCause }: ViewPopupProps) => {
    const theme = createTheme({
        typography: {
            fontFamily: "'Poppins', sans-serif",
            fontSize: 14,
            body1: {
                fontSize: '14px',

            },
        },
    });
    
    return (
        <ThemeProvider theme={theme}>
            <Dialog 
                open={openView}
                PaperProps={{
                    style: {
                        width: '580px',
                        maxWidth: '580px'
                    }
                }}
            >
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>View Root Cause Config</span>
                <IconButton onClick={handleWindowClose} style={{ paddingRight: '0px' }} size="small">
                    <Close />
                </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent style={{ margin: "0px" }}>
                <Box sx={{ minHeight: '140px' }}>
                    {rootCause && (
                        <Grid container spacing={3} style={{ padding: '15px' }} key={rootCause.id}>
                            <Grid item xs={6}>
                                <Typography>Batch Name</Typography>
                                <Tooltip title={rootCause.batchNumber}>
                                <Typography style={{ fontWeight: "bold", marginTop: "5px" }}>
                                    {rootCause.batchNumber.length > 10
                                    ? `${rootCause.batchNumber.substring(0, 10)}...`
                                    : rootCause.batchNumber}
                                </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Model</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px', justifyItems: 'space-between' }}>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        {rootCause.modelDetails &&
                                            Object.values(rootCause.modelDetails).length > 0 &&
                                            formatSuccessCriteria(Object.values(rootCause.modelDetails)[0].model)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

export const DeletePopup = ({open,closePopup,rootCause, deleteRootCause}) =>{
    const classes = useStyles();
    if (!rootCause) {
        return null;
    }
    return (
        <Dialog open={open}>
            <Grid item style={{ display: 'flex', flexDirection: 'row', boxShadow: '0 0 15px 0 #00000014'  }}>
                <DialogTitle style={{ width: '90%'}}>Delete RootCause Config</DialogTitle>
                <IconButton style={{ justifyContent: "flex-end", cursor: "pointer" }} onClick={closePopup}>
                    <CancelIcon />
                </IconButton>
            </Grid>
            <DialogContent>
                    <Box>
                        <Grid item style={{ marginBottom: '15px', padding: '10px' }}>
                            <Typography>
                                Are you sure you want to permanently remove this '{rootCause.analysisName}' root cause model from the batch '{rootCause.batchNumber}'?
                            </Typography>
                        </Grid>
                        <Grid container justifyContent='flex-end'>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-evenly', width: '40%', marginBottom:'10px' }}>
                                <Button className={classes.cancelButton} onClick={closePopup}>No</Button>
                                <Button className={classes.delete} style={{ backgroundColor: '#FF4343', color: 'white' }} onClick={deleteRootCause}>
                                    Yes
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
            </DialogContent>

        </Dialog>
    );
};

export const EditPopup = ({open, handleWindowClose, rootCause, updateRootCauses, formValue, setFormValue, selectedMainTestCaseId, setSelectedMainTestCaseId, selectedModel, setSelectedModel, convertToMinutes, resetFormFields, errors, setErrors }) => {
    const classes = useStyles();
    const [showDescription,setShowDescription] = useState(false)
    const [models, setModels] = useState([]);
    const [selectedTestTile, setSelectedTestTitle] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState("");
    const [responseTime, setResponseTime] = useState(null);
    const [mainTestTitles, setMainTestTitles] = useState([]);
    const currentModelDetails = selectedMainTestCaseId && formValue.modelDetails[selectedMainTestCaseId];
    const modelToShow = currentModelDetails ? currentModelDetails.model : '';
    const [shedulePeriod, setShedulePeriod] = useState([
        "Minutes", "Hours", "Days", "Weeks", "Months"
    ]);

    const [descriptions, setDescriptions] = useState({})
    const [selectedDescription,setSelectedDescription]=useState("")
    const [codes,setCodes] = useState({})
    const [selectedcodes,setSelectedcodes] = useState([])

    const showDescriptionDialog = (e) =>{
        setSelectedDescription(descriptions[e])
        setShowDescription(true);
    }

    const handleCloseWindow = () =>{
        setShowDescription(false)
    }


    const handleResponseTime = (value) => {
        if (isNaN(value) || value === "") {
            setResponseTime("");
            setFormValue((prevValue) => ({
                ...prevValue,
                rootCauseNotificationDurationInMinutes: "",
            }));
            return;
        }

        const durationInMinutes = convertToMinutes(value, selectedPeriod) || 0;

        setResponseTime(value);
        setFormValue((prevValue) => ({
            ...prevValue,
            rootCauseNotificationDurationInMinutes: durationInMinutes,
        }));

        setErrors((prevError) => ({
            ...prevError,
            rootCauseNotificationDurationInMinutes: null,
        }));
    };


    const handleSchedulePeriod = (period) => {
        setSelectedPeriod(period);

        setFormValue((prevValue) => ({
            ...prevValue,
            period: periodMap[period],
        }));

        setErrors((prevError) => ({
            ...prevError,
            period: null,
        }));
    };

    const periodMap = {
        "Hours": "HOURS",
        "Days": "DAYS",
        "Minutes": "MINUTES",
        "Weeks":"WEEKS",
        "MONTHS":"Months"
    };

    const handleSelectModel = (value: string) => {
        setSelectedModel(value);

        let modelUrl = '';
        const matchingModel = models.find((m) => m.model_name === value);
        setSelectedcodes(codes[matchingModel.model_path])
        if (matchingModel) {
            modelUrl = matchingModel.model_path;
        }

        setFormValue((prevValue) => {
            const updatedModelDetails = { ...prevValue.modelDetails };

            if (selectedMainTestCaseId) {
                updatedModelDetails[selectedMainTestCaseId] = {
                    ...updatedModelDetails[selectedMainTestCaseId],
                    model: value,
                    modelUrl: modelUrl
                };
            }

            return {
                ...prevValue,
                modelDetails: updatedModelDetails
            };
        });

        setErrors((prevError) => ({
            ...prevError,
            model: null
        }));
    };

    const fetchTestCases = async (batchNo) => {
        try {
            const response = await axios.get(
              `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
                },
              }
            );
            const allData = response.data;
            const data = allData.content.filter((d) => d.default === false);

            const mainTestTitles = data.map(testCase => ({
                id: testCase.id,
                title: testCase.mainTestTitle
            }));
            setMainTestTitles(mainTestTitles);

            const selectedTestCase = data.find(testCase => testCase.id === formValue.selectedMainTestCaseId);
            if (selectedTestCase) {
                setSelectedTestTitle(selectedTestCase.mainTestTitle);
            } else {
                setSelectedTestTitle('');
            }
        }
        catch (err) {
            console.error('Error fetching main test cases:', err);
        }
    };

    const handleSelectMainTestCase = (title: string) => {
        const selectedCase = mainTestTitles.find(test => test.title === title);

        if (selectedCase) {
            const selectedCaseId = selectedCase.id;

            setSelectedMainTestCaseId(selectedCaseId);
            setSelectedTestTitle(title);

            const modelObject = models.find(m => m.model_name === selectedModel);
            let modelUrl = '';

            if (modelObject) {
                modelUrl = modelObject.model_path;
            }

            setFormValue(prevValue => ({
                ...prevValue,
                selectedMainTestCaseId: selectedCaseId,
                modelDetails: {
                    ...prevValue.modelDetails,
                    [selectedCaseId]: {
                        model: selectedModel,
                        modelUrl: modelUrl
                    }
                }
            }));
        }
    };

    useEffect(()=>{
        const getAvailableModel = async () => {
          try {
            const models = await getAvailableModels({
              "userid": Cookies.get('USER_ID')
            });
            const mod = models["modelDetails"]

            if(mod!==null || mod!==undefined){
              let modelData = []
              mod.map((k,i)=>{
                modelData.push({"model_path":k,"model_name":k.match(/[^/]+\.joblib$/)[0].replace('.joblib', '')})
              })
              setModels(modelData);
              setDescriptions(models["descripstions"])
              setCodes(models["codes"])
            }
          } catch (err) {
            console.error('Error fetching batch numbers:', err);
          }
      };
      getAvailableModel()
    },[]);

    useEffect(() => {
        if(formValue.batchNumber){
            fetchTestCases(formValue.batchNumber);
        }
    }, [formValue.batchNumber]);


    const validateForm = () => {
        let error:any = {};

        if(!formValue.modelDetails[selectedMainTestCaseId]){
            error.testCase = 'Main Test Case is required';
        }
        if (!formValue.modelDetails || !formValue.modelDetails[selectedMainTestCaseId] || !formValue.modelDetails[selectedMainTestCaseId].model) {
            error.model = 'Model is required';
        }
        setErrors(error);
        return error
    }

    const handleSubmit = async () => {
        const validationError = validateForm();
        if(Object.keys(validationError).length > 0) {
            return;
        }
        try {
            formValue["inferenceCodes"]=selectedcodes
            formValue["inferenceNoOfData"]=null
            formValue["mainTestId"]=formValue.selectedMainTestCaseId
            delete formValue.selectedMainTestCaseId
            await updateRootCauses(formValue);
        } catch(err) {
            console.log('Error updating root cause', err);
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>Edit Root Cause Config</span>
              <IconButton
                onClick={() => {
                    handleWindowClose();
                    resetFormFields();
                }}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
          <Box>
            <Grid container spacing={3} style={{ padding: '10px' }}>
                <Grid item xs={6} style={{ border: '1px solid #2A7BEC', borderRadius: '5px', marginTop: '10px', marginLeft: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                        <Typography style={{width:'75%'}}>
                            Batch Name
                            <span style={{ color: 'red' }}>*</span>
                        </Typography>
                            <InputBase
                                style={{fontWeight: 'bold', color: '#2A7BEC', backgroundColor: '#F9F9FA', padding: '10px'}}
                                type="text"
                                name="batchNumber"
                                value={formValue.batchNumber}
                            />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column', marginBottom:'10px' }}>
                    <Typography className={classes.typo}>
                    Main Test Case
                    <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <DropDown
                        options={mainTestTitles.map(test => test.title)}
                        type='testCase'
                        emptyTag='Select Main Test Case'
                        setSelectOption={handleSelectMainTestCase}
                        onClick={handleSelectMainTestCase}
                        value={selectedTestTile}
                        isSingle={true}
                    />
                    {errors.testCase &&
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                            {errors.testCase}
                        </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography className={classes.typo}>
                    Model
                    <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <DropDown
                        options={models.map((m)=>m.model_name)}
                        type='model'
                        emptyTag='Select Model'
                        setSelectOption={setSelectedModel}
                        value={ selectedMainTestCaseId && formValue.modelDetails[selectedMainTestCaseId] ? formValue.modelDetails[selectedMainTestCaseId].model : ''}
                        // handleError={handleError}
                        onClick= {handleSelectModel}
                        isSingle={true}
                        icon ={<DocumentIcon />}
                        iconClick = {(e)=>showDescriptionDialog(e)}
                    />
                    {errors.model &&
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                            {errors.model}
                        </Typography>
                        </Grid>
                    }
                </Grid>
                {showDescription &&
                    <DescriptionComponent
                    open={showDescription}
                    handleCloseWindow={handleCloseWindow}
                    description={selectedDescription}
                    />
                }
                <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.backButton} ${classes.button}`}
                            onClick={() => {
                                handleWindowClose();
                                resetFormFields();
                            }}
                        >
                            Cancel
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.getStarted} ${classes.button}`}
                            onClick={handleSubmit}
                        >
                            Update
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
          </DialogContent>
        </Dialog>
    );
};
