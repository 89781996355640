import { getUserRole, isEngineer, isTechnician } from "./maintenance/test-automation/components/apiHelper";

export const hasUserPermission = (feature, access="VIEW")  => {
    if(isEngineer() && feature === "ALERT"){
        return false;
    }
    if(isTechnician() && ['DEVICES', 'TESTCASE', 'CHATBOTCONFIG', 'BATCHES','ROOTCAUSECONFIG'].includes(feature)){
        return false;
    }
    return true;
}

