import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../authentication/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  ACCESS_TYPE,
  Actuator,
  Device,
  FORM_TYPE,
  MetaData,
  PROCESS_TYPE,
  PROPERTY_TYPE,
  SECTIONS,
  Sensor
} from '../models';
import {AccountService} from '../account/account.service';
import {ToastrService} from 'ngx-toastr';
import {SideNavService} from '../layouts/side-menu/sidenav/sidenav.service';
import moment from 'moment-timezone';
import {ActivatedRoute} from '@angular/router';
import { isValidBatchNumber } from '../utils';

function toggleClass(id, className) {
  const element = document.getElementById(id);
  if (element) {
    element.classList.toggle(className);
  } else {
    console.error(`Element with ID ${id} not found`);
  }
}

declare function removeClass(id, className): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('addDevice', {static: false}) addDeviceElement: ElementRef;
  formDevice: FormGroup;
  formType;
  devicesOfKit: Device[] = [];
  isSubmitted: boolean;
  error;
  propertyType = PROPERTY_TYPE;
  processTypes = PROCESS_TYPE;
  propertyCalMethod = ['SUM', 'MIN', 'MAX', 'AVE', 'PREDICT', 'ANY'];
  dropdownSettings;
  sensorsOfDevice: Sensor[] = [];
  actuatorsOfDevice: Actuator[] = [];
  devices: Device [];
  batches = []
  devicesAll: Device [] = [];
  selectedDeviceId;
  p = 1;

  sensorsOfKitModel = [];
  showKitForm = false;
  showKitModelForm = false;
  isDataFetched = false;
  actuators;
  sensors;
  metaData: MetaData;
  devicesSummary;
  favouriteDevices = [];
  searchTerm = '';
  isCloning = false;
  selectedDevice: Device;
  // mqtt
  mqttClient;
  clicked = '';

  ACCESS_TYPE = ACCESS_TYPE;
  SECTION = SECTIONS;

  corporateId;
  filter;
  favourite;
  showDeviceForm;
  recievedLastRawData
  validity = false
  connectedSensor
  expectedFormat;
  sortOrder = 'none';
  selectedValidity: string | null = null;
  filterBtnEnable: boolean = false;
  toggleModeView:boolean=false;
  
  toggleFilter() {
    this.filterBtnEnable = !this.filterBtnEnable;
  }
  updateValidity(validity: string) {
    this.selectedValidity = validity === 'All' ? null : validity;
    this.filterBtnEnable = true;
  }

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private accountService: AccountService, private toaster: ToastrService,
              private sideNavService: SideNavService, private router: ActivatedRoute,
              private authService: AuthService, private cdr: ChangeDetectorRef) {
    // this.mqttClient = new Paho.MQTT.Client('mqtt.senzmate.com', 8000, 'manualIrr' + new Date().getTime());
    // this.mqttClient.connect({onSuccess: this.onConnected.bind(this)});

  }

  ngOnInit() {

    this.router.params.subscribe(params => {
      this.corporateId = params['coperateId'];
      this.filter = params['filter']
      this.favourite = params['favourite'];

    });

    this.formDevice = this.formBuilder.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      interval: [null, [Validators.required, Validators.pattern('^[1-9]{1}[0-9]*$'), Validators.min(0)]],
      persistence: [null, Validators.required],
      protocol: [null, Validators.required],
      connectivity: [null, Validators.required],
      batchNumber: [null,[Validators.required,isValidBatchNumber]]
    });
    this.getDevicesSummary();
    this.getDevices();
    this.getMetaData();
    this.getBatches();
    this.getFavouriteDevices();
    // this.onConnected();

  }

  ngAfterViewInit() {
    if (this.addDeviceElement) {
      toggleClass('addDevice', 'hide');
    }
  }

  public pageChange(event: number): void {
    this.p = event;
  }


  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  createDevice(device) {
    Object.keys(this.formDevice.controls).forEach((key) => {
        if (typeof device[key] === "string") {
          device[key] = device[key].trim();
        }
      }
    );

    this.isSubmitted = true;
    if (!this.formDevice.valid || (!this.isSuperUser() && !AuthService.hasNewUserAccess)) {
      return;
    }

    this.spinner.show();
    this.error = null;
    device.sensorCodes = this.sensorsOfDevice.map(s => s.code);
    device.actuatorCodes = this.actuatorsOfDevice.map(a => a.code);
    device.noOfSensors = this.sensorsOfDevice.length;
    device.noOfActuators = this.actuatorsOfDevice.length;
    if (this.formType === FORM_TYPE.ADD || this.isCloning) {
      this.accountService.createDevice(device).subscribe(response => {
        // this.spinner.hide();
        this.getDevices();
        toggleClass('addDevice', 'hide');
        this.toaster.success('Device Created Successfully', 'Success');
        this.formDevice.reset();
        this.selectedDevice = null;
        this.showDeviceForm = false;
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      }, () => {
        this.isCloning = this.isCloning ? !this.isCloning : this.isCloning;
      });
    } else {
      this.accountService.updateDevice(this.selectedDeviceId, device).subscribe(response => {
        this.getDevices();
        toggleClass('addDevice', 'hide');
        this.toaster.success('Device Updated Successfully', 'Success');
        this.formDevice.reset();
        this.selectedDevice = null;
        this.showDeviceForm = false;
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }
    this.isSubmitted = false;
  }

  deleteDevice() {
    this.spinner.show();
    this.accountService.deleteDevice(this.selectedDevice.id).subscribe(response => {
      this.toggleHideById('deleteModal');
      this.devicesAll.splice(this.devicesAll.indexOf(this.selectedDevice), 1);
      this.spinner.hide();
      this.toaster.success('Device Deleted Successfully', 'Success');
    }, error => {
      this.toggleHideById('deleteModal');
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  editDevice(device) {
    this.showDeviceForm = true
    this.selectedDevice = device
    console.log(device)
    this.formType = FORM_TYPE.EDIT;
    // this.selectedDeviceId = device.id;
    this.error = null;
    this.isSubmitted = false;
    //  toggleClass('addDevice', 'hide');
  }

  runMockDevice(device) {
    this.spinner.show();
    this.accountService.runMockDevice(device.id, device.clientId ? device.clientId : null).subscribe(response => {
      this.spinner.hide();
      this.toaster.success('Device is running', 'Success');
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Something went wrong', 'cant run the device!');
      }
    });
  }
  toggleDeviceMode() {
    this.spinner.show();
    const previousStatus = this.selectedDevice.status ;
    const statusToUpdate = this.selectedDevice.status =="INACTIVE"? "ACTIVE":"INACTIVE"
    const isActiveUpdate=!this.selectedDevice.isActive;
    this.accountService.updateDeviceMode(this.selectedDevice.id,statusToUpdate).subscribe(
        response => {
           if (this.selectedDevice) this.selectedDevice.status = statusToUpdate;
           this.selectedDevice.isActive=isActiveUpdate;
           this.toggleModeView=false;
            this.spinner.hide();
        },
        error => {
          if (this.selectedDevice) {
            this.selectedDevice.status = previousStatus;
          }
            this.spinner.hide();
            this.toggleModeView=false;
            this.toaster.error('Something went wrong', 'can\'t change device status!');

        }
    );
}
  
  getDevices(isSilent?) {
    if (!isSilent) {
      this.spinner.show();
    }
    this.accountService.getOneDevice(this.corporateId, this.filter, this.favourite).subscribe(response => {
      this.devices = response.content ? response.content : [];
      this.devices.map(dev=>{
        dev.isActive=dev.status!=null && dev.status!="INACTIVE";
      })
      this.devicesAll = Object.assign([], this.devices);

      if (this.devicesSummary) {
        this.devicesSummary.alive = {noOfDevice: 0, deviceList: []};
        this.devicesSummary.noDevicesOffline = {noOfDevice: 0, deviceList: []};

        this.devices.forEach(device => {
          if (this.isDeviceOnline(device.lastSeen, device.interval)) {
            this.devicesSummary.alive.noOfDevice++;
            this.devicesSummary.alive.deviceList.push(device.id);
          } else if (device.lastSeen) {
            this.devicesSummary.noDevicesOffline.noOfDevice++;
            this.devicesSummary.noDevicesOffline.deviceList.push(device.id);
          }
        });
      }

      this.isDataFetched = true;
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  isDeviceOnline(time, interval) {
    if (!time) {
      return false;
    }
    const ist = moment.tz(time, 'Asia/Colombo');
    const ut = ist.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return moment().diff(ut) / (1000 * 60) <= interval;
  }

  getMetaData() {
    this.accountService.getMetaData().subscribe((data) => {
      this.metaData = data.content;
      this.sensors = this.metaData.sensors.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });

      this.actuators = data.content.actuators.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
    }, error => {
      this.spinner.hide();
    });
  }

  getDevicesSummary() {
    this.accountService.getDevicesSummary().subscribe((data) => {
      this.devicesSummary = data.content;
    }, error => {
      this.spinner.hide();
    });
  }

  getBatches() {
    this.accountService.getBatches().subscribe((data) => {
      this.batches = data.content;
    }, error => {
      this.spinner.hide();
    });
  }

  isBatterySelected() {
    return this.sensorsOfDevice.includes(this.getSensorByCode('B'));
  }

  getPropertyCalMethod() {
    return this.propertyCalMethod;
  }

  getProcessTypes() {
    return Object.keys(this.processTypes).map(o => {
      return String(o);
    });
  }

  getWindowWidth() {
    return window.innerWidth;
  }

  getDeviceFormReady() {
    if (this.showDeviceForm) {
      this.showDeviceForm = false;
    }

    this.cdr.detectChanges();
    this.showDeviceForm = true;
    if (this.showDeviceForm) {
      this.formType = FORM_TYPE.ADD;
      this.isCloning = true;
      this.isSubmitted = false;
      this.formDevice.reset();
      this.formDevice.patchValue({
        persistence: true,
        protocol: 'HTTP',
        connectivity: 'GSM',
      });
      this.formDevice.controls['id'].enable();
      this.sensorsOfDevice = [];
    }
  }

  onFormClose() {
    this.showDeviceForm = false;
  }

  getKitFormReady() {
    this.formType = FORM_TYPE.ADD;
    this.showKitForm = true;
  }

  getKitModelFormReady() {
    this.formType = FORM_TYPE.ADD;
    this.showKitModelForm = true;
  }

  getSensorsSelected() {
    const temp = [];
    this.sensorsOfKitModel.forEach(function (s, index) {
      temp.push({item_id: index, item_text: s.name + ' ' + (index + 1)});
    });
    return temp;
    // return Array.from({length: this.sensorsOfKitModel.length}, (v, k) => String(k));
  }

  addSensorToDevice(index) {
    this.sensorsOfDevice.push(this.sensors[index]);
  }

  removeSensorFromDevice(index) {
    this.sensorsOfDevice.splice(index, 1);
  }

  addActuatorToDevice(index) {
    this.actuatorsOfDevice.push(this.actuators[index]);
  }

  removeActuatorFromDevice(index) {
    this.actuatorsOfDevice.splice(index, 1);
  }

  addDeviceToKit(index) {
    this.devicesOfKit.push(this.devices.splice(index, 1)[0]);
  }

  removeDeviceFromKit(index: number) {
    this.devices.push(this.devicesOfKit.splice(index, 1)[0]);
    this.devices.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  hasLeastSensorOfProperty(property: String): boolean {
    return this.sensorsOfKitModel.includes(property);
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  onClearError() {
    this.error = null;
  }

  getSensorByCode(sensor) {
    return this.sensors.filter(s => {
      return s.code === sensor;
    }, 1)[0];
  }

  getDevicesFiltered() {
    this.devicesAll.map((d) => {
      if (this.favouriteDevices.includes(d.id)) {
        d.favourite = true;
      } else {
        d.favourite = false;
      }
    });

    return this.devicesAll.filter(device => {
      const isValid = this.selectedValidity === 'Valid' ? this.createExpectedMessageFormat(device) :
        this.selectedValidity === 'Invalid' ? !this.createExpectedMessageFormat(device) :
          true;

      const isSelected = this.clicked !== '' && this.devicesSummary ?
        this.devicesSummary[this.clicked].deviceList.includes(device.id) :
        true;

      const matchesSearch = JSON.stringify(Object.values(device)).toLowerCase().includes(this.searchTerm.trim().toLowerCase());

      return isValid && isSelected && matchesSearch;
    });
  }

  getNotSorted() {
    this.accountService.getOneDevice(this.corporateId, this.filter, this.favourite).subscribe(response => {
      this.devices = response.content ? response.content : [];
    });
  }


  sort(colName: string) {

    this.sortOrder = (this.sortOrder === 'asc') ? 'desc' : (this.sortOrder === 'desc') ? 'none' : 'asc';
    if (this.sortOrder === 'none') { // No sorting required
      this.getNotSorted();
      this.devicesAll = this.devices;
    } else {
      this.devicesAll.sort((a, b) => {
        if (!a[colName] && !b[colName]) {
          return 0;
        }
        if (!a[colName]) {
          return 1;
        }
        if (!b[colName]) {
          return -1;
        }
        const multiplier = (this.sortOrder === 'desc') ? -1 : 1;
        // Sort based on column values
        return (a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0) * multiplier;
      });
    }
  }

  getIcon(): string {
    if (this.sortOrder === 'asc') {
      return '&#9650;'; // Upward arrow
    } else if (this.sortOrder === 'desc') {
      return '&#9660;'; // Downward arrow
    }
  }

  toggleHideById(id) {
    document.getElementById(id).classList.toggle('hide');
  }
  togglemodeView(event,device) {
    event.preventDefault();
    this.toggleModeView=true;
    this.selectedDevice=device;
  }


  isActive(value) {
    if (this.clicked === '') {
      this.clicked = value;
    } else if (this.clicked === value) {
      this.clicked = '';
    } else if (this.clicked !== '' && this.clicked !== value) {
      this.clicked = value;
    }
  }

  getFavouriteDevices() {
    this.accountService.getFavouriteDevices().subscribe((data) => {

      this.favouriteDevices = data.content
    }, error => {
      this.spinner.hide();
    });
  }

  addOrRemoveFavouriteDevice(id, status) {
    let action
    if (status === true) {
      action = "remove"
    } else {
      action = "add"
    }
    this.accountService.addOrRemoveFavourite(id, action).subscribe((data) => {
        if (data.message === "Success") {
          if (action === "remove") {
            this.favouriteDevices = this.favouriteDevices.filter((data) => data !== id)
          }
          if (action === "add") {
            this.favouriteDevices.push(id)
          }
        }
        console.log(this.favouriteDevices)
      },
      () => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  createExpectedMessageFormat(data) {
    if (data.lastRawData) {
      this.recievedLastRawData = data.lastRawData
      this.connectedSensor = data.sensorCodes
      if (data.sensors == '') {
        if (this.connectedSensor.length == 0) {
          this.expectedFormat = 'There is no sensor connected.'
        } else {
          this.expectedFormat = this.connectedSensor.map((sensor, index) => {
            return index + "-" + sensor + ":0"
          }).join(';')
        }
      } else {
        const sensorValue = {}
        data.sensors.map((device) => {
          sensorValue[device.code] = device.value
        })
        this.expectedFormat = this.connectedSensor.map((element, index) => `${index}-${element}:${sensorValue[element] ? sensorValue[element] : 0}`).join(';');
      }
      if (this.recievedLastRawData == this.expectedFormat) {
        this.validity = true;
      }
      return this.validity;
    }

  }

}
