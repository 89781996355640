import {
  Chip,
  colors,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {CloseIcon, CloseIconColor, ViewIcon} from "../../Icons";
import {filterTitle} from "../../colors";
import ReviewHistory from "./ReviewHistory";

const HistoryDevice = (props) => {
  const device = props.device.device;
  const history = props.device.device.deviceParameterConfiguration.updateHistory ? props.device.device.deviceParameterConfiguration.updateHistory : [];
  const [isOpen, setIsOpen] = useState(true);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  function handleReviewClick(data) {
    if (data.updateParamList != null && data.updateParamList.length !== 0) {
    setIsReviewOpen(true);}
    else{
      //throw empty params message TODO L
    }
    setReviewData(data);
  }

  const handleClose = () => {
    setIsOpen(false);
    props.close();
  };
  const statusMap = {
    PARTIALLY_UPDATED: "Partially updated",
    UPDATED: "Updated",
    FAILED: "Failed",
    PENDING: "Pending",
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.4",
          },
        }}
        PaperProps={{
          // className: classes.paper,
          style: {
            boxShadow: "inherit",
          },
        }}
      >
      {isReviewOpen === true ? (
        <ReviewHistory
          data={reviewData}

          open={isReviewOpen}
          close={() => {
            setIsReviewOpen(false);
          }}
          device={device}
        />
      ) : (
        <>
          <Grid
            container
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: "1",
              boxShadow: "0px 4px 30px #00000008"
            }}
          >
            <Grid
              item
              style={{ paddingLeft: "12px", fontSize: "18px" }}
              xs={10}
              md={10}
            >
              <h3
                style={{
                  fontWeight: "bold",
                  color: filterTitle,
                  fontSize: "16px",
                  lineHeight: "25px",
                }}
              >
                {device.name} -{" >"}
                {device.lastUpdateStatus &&
                  device.lastUpdateStatus ===
                  "UPDATED" ? (
                  <span
                    style={{ color: "green" }}
                  >Updated</span>
                ) :
                  (
                    device.lastUpdateStatus ===
                      "PENDING" ? (
                      <span
                        style={{ color: "#FFB40A" }}
                      >Pending</span>
                    ) :
                      (device.lastUpdateStatus ===
                        "FAILED" ? (
                        <span
                          style={{ color: "red" }}
                        >Failure</span>
                      ) :
                        device.lastUpdateStatus ===
                          "PARTIALLY_UPDATED" ? (
                          <span
                            style={{ color: "red" }}
                          >Partially Updated</span>
                        ) : ""))}   </h3>

            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <IconButton onClick={handleClose}>
                <CloseIconColor />
              </IconButton>
            </Grid>
            <Divider />


          </Grid>
          <DialogContent style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={12} md={12} style={{ paddingTop: "10px" }}>
                <TableContainer>
                  <Table
                    size="medium"
                    style={{
                      boxShadow: "initial",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              DATE
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              TIME
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              UpdateStatus
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              ACTION
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              ACTION BY
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              ACTION
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {history &&
                        history.length > 0 &&
                        history
                          .slice()
                          .reverse()
                          .map((item, i) => (
                            <TableRow
                              style={{
                                textAlign: "center",
                                borderBottomColor: "white",
                                borderTopColor: "white",
                                backgroundColor:
                                  i % 2 === 0 || i / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                              key={i}
                            >
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.updatedDate.split(" ")[0]}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.updatedDate.split(" ")[1]}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {statusMap[item.updateStatus] || ""}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.updateParamList
                                  ? Object.keys(
                                    item.updateParamList
                                  ).join(", ")
                                  : ""}{" "}
                                {/* <br/>
                                {item.topicFormat
                                  ? Object.keys(item.topicFormat).join(", ")
                                  : ""}{" "}
                                <br/>
                                {item.messageFormat
                                  ? Object.keys(item.messageFormat).join(", ")
                                  : ""} */}
                              </TableCell>

                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.actionBy}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleReviewClick(item)}
                                >
                                  <ViewIcon color = '#707070'/>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default HistoryDevice;
