import * as React from 'react';
import { useState } from 'react';
import { Box, Button, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import useStyles from "../../assets/styles";

const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
});

export default function Step1ConnectDevices({
                                             formData,
                                             setFormData,
                                             apiKey,
                                             batchNumber,
                                             baseUrl,
                                             setApiKey,
                                             setbatchNumber,
                                             setBaseUrl,
                                             baseUrlError,
                                             batchNumberError,
                                             apiKeyError,
                                             setBaseUrlError,
                                             setBatchNumberError,
                                             setApiKeyError
                                            }){
    const classes = useStyles();
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const trimmedValue = value.trim();
        if(name === "baseUrl"){
            setBaseUrl(trimmedValue);
            setBaseUrlError(false);
        } else if(name === "batchNumber"){
            setbatchNumber(trimmedValue);
            setBatchNumberError(false);
        } else if(name === "apiKey"){
            setApiKey(trimmedValue);
            setApiKeyError(false);
        }
        setFormData({
            ...formData,
            [name]: trimmedValue
        });
    }
    return (
        <ThemeProvider theme={theme}>
            <Grid container alignItems="center">
                <Grid container style={{marginBottom:"30px"}} justifyContent='center'>
                    <Grid item xs={12} md={12}>
                        <Grid container style={{textAlign: "left"}}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                <Typography className={classes.typo}>
                                    Base URL
                                    <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                </Grid>
                                <Grid item>
                                    <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        placeholder="Enter Base URL Here"
                                        name="baseUrl"
                                        value={baseUrl}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                {baseUrlError && (
                                    <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                                    <Typography className={classes.errorText}>
                                        Base Url is Required
                                    </Typography>
                                    </Grid>
                                )}
                        </Grid>
                        <Grid container direction="column" spacing={1} style={{marginTop:"30px"}}>
                            <Grid item>
                            <Typography className={classes.typo}>
                                Batch No
                                <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            </Grid>
                            <Grid item>
                                <InputBase
                                    className={classes.formInput}
                                    type="text"
                                    placeholder="Enter Batch No Here"
                                    name="batchNumber"
                                    value={batchNumber}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            {batchNumberError && (
                                    <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                                    <Typography className={classes.errorText}>
                                        Batch Number is Required
                                    </Typography>
                                    </Grid>
                                )}
                        </Grid>
                        <Grid container direction="column" spacing={1} style={{marginTop:"30px"}}>
                                <Grid item>
                                  <Typography className={classes.typo}>
                                    API Key/Token
                                    <span style={{ color: 'red' }}>*</span>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                    <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        placeholder="Enter API Key Here"
                                        name="apiKey"
                                        value={apiKey}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                {apiKeyError && (
                                    <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                                    <Typography className={classes.errorText}>
                                        ApiKey is Required
                                    </Typography>
                                    </Grid>
                                )}
                        </Grid> 
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>

    );
}