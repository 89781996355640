import {Button} from "@material-ui/core";
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {environment} from "src/environments/environment";

export function AutomlApp(props) {
  const [iframeHeight, setIframeHeight] = useState("500px");
  const [hidden, setHidden] = useState(true);

  const autoMl = 'autoMl' in environment ? environment.autoMl : "";

  const sendMessage = () => {
    const iframe = document.getElementById("myIframe") as HTMLIFrameElement;
    const authenticationData = {
      access_token: localStorage.getItem("ACCESS_TOKEN"),
      refresh_token: localStorage.getItem("REFRESH_TOKEN"),
      references: [],
      role: localStorage.getItem("ROLE"),
      id: localStorage.getItem("USER_ID"),
      name: localStorage.getItem("NAME"),
    };
    iframe.contentWindow.postMessage(JSON.stringify(authenticationData), "*");
  };

  useEffect(() => {
    setTimeout(() => {
      sendMessage();
      setHidden(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      const newHeight = `${window.innerHeight}px`;
      setIframeHeight(newHeight);
    };

    resizeHandler(); // Set initial height

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);
  return (
    <>
      <div style={{width: "100%", paddingTop: "40px", paddingLeft: "20px", paddingBottom: "10px"}}>
        <Link to={{pathname: `/`, state: {}}}>
          <Button style={{backgroundColor: "#007bff", color: "white", textTransform: "none"}} size="small"
                  variant="contained"> Go Back</Button>
        </Link>
      </div>
      <iframe
        hidden={hidden}
        id="myIframe"
        src={autoMl}
        title="Embedded Website"
        style={{width: "100%", height: iframeHeight, border: "none", paddingLeft: "10px", paddingRight: "10px"}}
      ></iframe>
    </>
  );
}
