import React, {useEffect, useRef, useState} from "react";
import {CircularProgress, Grid, IconButton, makeStyles, Theme, Tooltip} from "@material-ui/core";
import {environment} from "src/environments/environment";
import {primaryBlue} from "src/components/colors";
import {AlertBar} from "src/components/sub-components/AlertBar";
import {ClockIcon, RefreshIcon, WaitIcon, WarningIcon} from "src/components/Icons";
import axios from "axios";


interface DeviceDataProps {
  deviceId: string,
  deviceName: string,
  productType: string,
  interval: string
}

function Step2DeviceData(props: DeviceDataProps) {
  const classes = useStyles();
  const productType = props.productType;
  const deviceName = props.deviceName;
  const deviceId = props.deviceId;
  const [productTypeData, setProductTypeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [estimatedTime, setEstimatedTime] = useState("");
  const [sensors, setSensors] = useState([]);
  const [selectedSensorIndex, setSelectedSensorIndex] = useState(0);


  useEffect(() => {
    console.log("props: ", props);
  }, []);

  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const interval = parseInt(props.interval, 10); 
    const timeAfterInterval = new Date(currentTime.getTime() + interval * 60000); 
    setEstimatedTime(timeAfterInterval.toLocaleTimeString());
  }, [props.interval]);

  // Update time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchSensorData();
  }, [props.deviceId]);

  // Fetch MetaData from the API endpoint
  useEffect(() => {
    const fetchMetaData = () => {
      const accessToken = localStorage.getItem('ACCESS_TOKEN');
      axios.get(`${environment.host}/core/meta-data`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (isMounted.current) {
            const sensorsArray = response.data.content.sensors;
            const codes = productTypeData.reduce((acc, curr) => {
              if (!acc.includes(curr.code)) {
                acc.push(curr.code);
              }
              return acc;
            }, []);
            const matchedSensors = sensorsArray.filter(sensor => codes.includes(sensor.code));
            setSensors(matchedSensors);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            console.error('Error fetching metadata:', error);
          }
        });
    };
    fetchMetaData();
  }, [productTypeData]);

  const fetchSensorData = async () => {
    setLoading(true);
      await axios.get(`${environment.host}/core/device/${props.deviceId}/getAllDirectData`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        if (isMounted.current) {
          setProductTypeData(response.data.content);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setProductTypeData([]);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const reloadData = async () => {
    await fetchSensorData();
  };

  const selectTab = (index) => {
    setSelectedSensorIndex(index);
  };

  const sensorData = sensors.length > 0 ? productTypeData.filter(data => data.code === sensors[selectedSensorIndex].code) : [];

  // Sort sensorData by time in descending order
  const sortedSensorData = sensorData.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());

  return <div className={`testing`}>
    <div className={classes.formContainer}>
      <div className={classes.textInputContainer}>
        <label className={classes.textInputLabel}>
          Device Name:
        </label>
        <input
          className={classes.formInput}
          type="text"
          value={deviceName}
          placeholder="Device Name"
          readOnly={true}
        />
      </div>
      <div className={classes.textInputContainer}>
        <label className={classes.textInputLabel}>
          Device ID(IMEI):
        </label>
        <input
          className={classes.formInput}
          type="text"
          value={deviceId}
          placeholder="Device ID"
          readOnly={true}
        />
      </div>
      <div className={classes.textInputContainer}>
        <label className={classes.textInputLabel}>
          Product Types:
        </label>
        <input
          className={classes.formInput}
          type="text"
          value={productType}
          placeholder="Product Types"
          readOnly={true}
        />
      </div>
    </div>
    <div className={classes.textInputContainer}>
      <label className={classes.textInputLabel}/>
      {
        loading ? (
          // Loading indicator
          <div className={classes.loadingContainer}>
            <Grid className={classes.LoadingContainer}>
              <Grid container item xs={12} style={{height: "100px"}}>
                <div style={{marginLeft: "350px"}}>
                  <ClockIcon/>
                  <label className={classes.LoadingText}>
                    {currentTime.toLocaleTimeString()}
                  </label>
                </div>
              </Grid>
              <Grid container item xs={12} style={{height: "100px"}}>
                <div style={{textAlign: "center"}}>
                  <WaitIcon/>
                  <label className={classes.LoadingText} style={{display: "block"}}>
                    Stay Tuned!
                  </label>
                  <label className={classes.LoadingText}>
                    Your Estimated Time is at {estimatedTime}
                  </label>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : productTypeData && productTypeData.length > 0 ? (
          <>
            <div style={{width: "500px"}}>
              <div className={`threshold-setting ${classes.thresholdSetting}`}>
                <div className="row" style={{margin: "0px", cursor: "pointer"}}>
                  {sensors.map((sensor, index) => (
                    <a key={index}
                       className={`col nopadding ${selectedSensorIndex === index ? classes.selectedSensor : ''}`}
                       onClick={() => selectTab(index)}>
                      <div className={`threshold-data ${classes.thresholdData}`}>
                        <div>
                          <div className={`device-data-title ${classes.deviceDataTitle}`}>
                            {sensor.name}
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <div style={{height: "10px"}}></div>
              <div className={classes.dataContainer}>
                {sortedSensorData.length > 0 && (
                  <table className="table custab" id="device">
                    <thead>
                    <tr>
                      <th>Date Time</th>
                      <th>Data</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedSensorData.map((data, index) => (
                      <tr key={index}>
                        <td>{new Date(data.time).toLocaleString()}</td>
                        <td>{data.value}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </>
        ) : (
          // Display estimated time and loading message
          <div className={classes.dataLoadingContainer}>
            <div className={classes.errorContainer}>
              <WarningIcon/>
              <label className={classes.errorText}>
                Check the URLs, Data Formats and Encoding/Decoding script files
              </label>
            </div>

          </div>
        )
      }
    </div>
    <div className={classes.textInputContainer}>
      <label className={classes.textInputLabel}/>
      <div className={classes.iconContainer}>
        <Tooltip title="Refresh">
          <IconButton style={{padding: '0px'}} onClick={reloadData}>
            <RefreshIcon/>
          </IconButton>
        </Tooltip>
      </div>
    </div>
    {loading &&
      <div style={{
        position: 'fixed',
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        opacity: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        width: '100vw',
        height: '100vh'
      }}>
        <CircularProgress size={80}/>
      </div>
    }
  </div>
};

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginTop: "70px",
  },
  textInputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
  textInputLabel: {
    width: "150px",
    padding: "5px",
    textAlign: "left",
    marginTop: "3px"
  },
  formInput: {
    height: "38px",
    width: "500px",
    borderRadius: "5px",
    padding: "10px 5px",
    fontFamily: "'Poppins', sans-serif",
    border: "1px solid silver"
  },
  loadingContainer: {
    height: "300px",
    width: "500px",
    background: "#FFFFFF",
    borderRadius: "5px",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid",
    borderColor: "green"
  },
  dataContainer: {
    height: "300px",
    width: "500px",
    background: "#FFFFFF",
    borderRadius: "5px",
    overflowY: "scroll",
    border: "1px solid",
    borderColor: "silver"
  },
  selectedSensor: {
    backgroundColor: primaryBlue,
    border: "1px solid",
    color: "white",
    alignItems: "center",

  },
  thresholdSetting: {
    border: "1px solid white",
    backgroundColor: "#ECEFF1"
  },
  thresholdData: {
    cursor: "pointer",
    alignItems: "center"

  },
  deviceDataTitle: {
    alignItems: "center"
  },
  dataLoadingContainer: {
    height: "300px",
    width: "500px",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: "2px solid",
    borderColor: "red"
  },
  LoadingContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  dataErrorContainer: {
    height: "300px",
    width: "500px",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px"
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

  },
  errorText: {
    width: "280px",
    color: "red",
    fontSize: "14px"
  },
  errorLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  LoadingText: {
    width: "280px",
    color: "green",
    fontSize: "14px"
  },
  productDataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 15px 5px 15px",
    borderBottom: "1px solid #5E5C5C",
  },
  dataTypesTitle: {
    fontWeight: "bold",
    fontSize: "16px"
  },
  dataTypes: {
    fontSize: "12px",
    color: "#5E5C5C",
    paddingRight: "10px"
  },
  iconContainer: {
    width: "500px",
    margin: "0px",
    display: "flex",
    justifyContent: "flex-start"
  }
}));

export default Step2DeviceData;
