import { Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip } from "@material-ui/core";
import { T } from "antd/lib/upload/utils";
import React from "react";
import useStyles from "src/app/maintenance/assets/styles";
import { AddIconTwo, TrashIcon } from "src/components/Icons";
import { SearchBar } from "src/components/sub-components/SearchBar";
import { getUserRoleName } from "src/app/utils";


export const UserTable = ({filterUsers, userRoles, search, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleDeleteOpen, roleId, existUserId, handleSearch}) => {
    const userCount = Array.isArray(filterUsers) ? filterUsers.filter((dec) => dec.mobile.includes(search.trim())).length : 0;
    const totalUserPages = Math.ceil(userCount / rowsPerPage);
    const classes = useStyles();
    return (
        <div>
            <Grid container className={classes.backgroundContainer}>
                <Grid item xs={12} md={12} style={{display:'flex',justifyContent:'space-between',marginBottom:'10px'}}>
                    <Grid item xs={4} md={4} style={{paddingRight:'10px'}}>
                        <SearchBar placeholder='Search' onChange={handleSearch}/>
                    </Grid>
                    <TablePagination style={{margin: '0px'}}
                        rowsPerPageOptions={[]}
                        component="div"
                        count={userCount}
                        rowsPerPage={rowsPerPage}
                        page={Math.min(page, Math.max(totalUserPages - 1, 0))}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <Grid container className={classes.tableContainer} style={{marginTop:"0px"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">User Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">User Role</TableCell>
                            <TableCell align="left">Account Created</TableCell>
                            <TableCell align="left">Last Sign In</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(filterUsers) && filterUsers.length > 0  ? 
                            (filterUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user,index) =>(
                                <TableRow key={index} style={{backgroundColor: index % 2 == 1 ? '#F5F5F5':'#FFFFFF'}}>
                                    <TableCell className={classes.userName}>
                                        <Tooltip title={user.username ? user.username : ''} arrow>
                                            <span>
                                                {user.username ? user.username.length > 20 ? `${user.username.slice(0, 20)}...` : user.username : 'N/A'}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={user.email}>
                                            <span>
                                                {user.email.length > 20 ? `${user.email.slice(0, 20)}...` : user.email}
                                            </span>                                               
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {getUserRoleName(user.userRoleId, userRoles)}
                                    </TableCell>
                                    <TableCell>{user.creationDate ? user.creationDate : 'N/A'}</TableCell>
                                    <TableCell>{user.lastSuccessfulLoginDate ? user.lastSuccessfulLoginDate : 'N/A'}</TableCell>
                                    <TableCell>
                                        <span style={{
                                            backgroundColor: user.status === 'ACTIVE' ? 'rgba(0, 177, 165, 0.1)' : user.status === 'INACTIVE' ? 'rgba(255, 67, 67, 0.05)' : '',
                                            color: user.status === 'ACTIVE' ? '#00B1A5' : user.status === 'INACTIVE' ? '#FF4343' : '',
                                            padding:' 6px 14px',
                                            borderRadius: '25px',
                                        }}>
                                            {user.status}
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton size={'small'} className={classes.trashIcon} onClick={() =>handleDeleteOpen(user.id,user.username,user.userRoleId)} disabled={roleId > user.userRoleId || user.id === existUserId}>
                                            <TrashIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )))
                            : (
                            <TableRow>
                                <TableCell colSpan={7} style={{ textAlign:'center' }}>
                                    No User Available
                                </TableCell>
                            </TableRow>
                            )
                        }   
                    </TableBody>
                </Table>
                </Grid>
            </Grid>
        </div>
    );
}