import * as React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, Grid, ThemeProvider, createTheme } from '@material-ui/core';
import useStyles from '../../assets/styles';
import { useState } from 'react';

interface Product {
  productType: string;
  deviceCategory: string;
}

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export const Step3 = ({result}) => {
  const classes = useStyles();

  // Hardcoded products array
  const products = useState(result);

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid container className={classes.root} style={{ paddingBottom: '40px' }}>
          <Grid item xs={12} md={12}>
            <TableContainer>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Device Id
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Route Cause
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products ? products.map((product, i) => (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: i % 2 === 0 ? "#ECEDEF" : "white",
                        }}
                      >
                        <Typography
                          display="inline"
                          className={classes.contentText}
                          style={{ paddingLeft: "5px" }}
                        >
                          {product.productType}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: i % 2 === 0 ? "#ECEDEF" : "white",
                        }}
                      >
                        <Typography className={classes.contentText}>
                          {product.deviceCategory}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                    : <TableRow>
                      <TableCell align="center">
                        <Typography className={classes.contentText}>
                          No Data Available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>


          <Grid item md={3} />
        </Grid>
      </div>
    </ThemeProvider>
  );
};

