import { Box, Button, createTheme, Grid, IconButton,Table, TableBody, TableCell, TableHead, TablePagination, TableRow, ThemeProvider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../maintenance/assets/styles";
import { SearchBar } from "src/components/sub-components/SearchBar";
import { Arrow, DeviceFailureIcon, NonSuccessCriteriaIcon, RefreshApiIcon, TestCaseIcon, TrashIcon } from "src/components/Icons";
import { Link } from "react-router-dom";
import { FilterBatchNumber } from "../maintenance/test-automation/components/FilterBatchNumber";
import { EditConfigurationPopUp } from "./components/EditConfiguration";
import { getAllDBJiraIssues, getAllJiraIssues, getJiraProject, SyncJiraTickets } from "../maintenance/test-automation/components/apiHelper";
import { Loader } from "../maintenance/test-automation/components/Loader";
import { ToasterComponent } from "../maintenance/test-automation/components/Toaster";
import { useLocation } from "react-router-dom";
import { formatSriLankanDateTime, getBackgroundColor, getColor } from "../utils";

export const DeviceFailureComponent = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterVisible, setFilterVisible] = useState(false);
    const jiraStatus = ['To Do', 'In Progress', 'On Hold', 'Done'];
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isEditPopUpOpen, setIsEditPopUpOpen] = useState(false);
    const [projectDetail, setProjectDetail] = useState([]);
    const [isProjectExist, setIsProjectExist] = useState(false);
    const location = useLocation();

    const [combinedIssues, setCombinedIssues] = useState([]);
    const [filteredIssues, setFilteredIssues] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const theme = createTheme({
        typography: {
            fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();

    const handleSearch = (event) =>{
        const {value} = event.target
        const lowerCaseSearchTerm = value.toLowerCase();
        if (!lowerCaseSearchTerm) {
            setFilteredIssues(combinedIssues);
            return;
        }
        let filterIssue = combinedIssues.filter(issue => {
            const matchesPrefix = selectedStatus === 'ALL' || issue.status.includes(selectedStatus);
            const matchesSearch = issue.deviceId.toLowerCase().includes(lowerCaseSearchTerm) || issue.jiraTicketNumber.toLowerCase().includes(lowerCaseSearchTerm);
            return matchesSearch && matchesPrefix;
        });
        setFilteredIssues(filterIssue);
        setPage(0);
    }

    const filterStatus = () => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = combinedIssues.filter(issue => {
            const matchesPrefix = selectedStatus === 'All' || issue.status.includes(selectedStatus);
            const matchesSearch = issue.jiraTicketNumber.toLowerCase().includes(lowerCaseSearchTerm) || issue.deviceId.toLowerCase().includes(lowerCaseSearchTerm);
            return matchesPrefix && matchesSearch;
        });
        setFilteredIssues(filtered);
        setFilterVisible(false);
        setPage(0)
    }

    const handleEditPopUp = () => {
        setIsEditPopUpOpen(true)
    }

    const cancelEditPopup = () => {
        setIsEditPopUpOpen(false)
    }

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const getProjectDetail = async () => {
            try {
                const response = await getJiraProject();
                if (response.status === 200) {
                    const data = response.data;
                    if(data.jiraBoard !== null && data.jiraBoard !== "" && data.jiraBoard !== undefined) {    
                        setIsProjectExist(true);
                        setProjectDetail(data);
                    }
                } else{
                    console.log("Error fetching data");
                }
            } catch(error){
                console.log("Error fetching data:", error);
            }
        }
        getProjectDetail();
    }, [isProjectExist]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [dbResponse, jiraResponse] = await Promise.all([
                getAllDBJiraIssues(),
                getAllJiraIssues()
            ]);
    
            if (dbResponse.status !== 200 || jiraResponse.status !== 200) {
                setCombinedIssues([]);
                setFilteredIssues([]);
                return;
            }
    
            const dbIssues = dbResponse.data.filter(issue => issue.type === "SUB");

            const jiraSubTasks = jiraResponse.data.issues.filter(
                issue => issue.fields.issuetype.subtask === true
            );
    
            if (dbIssues.length > 0 && jiraSubTasks.length > 0) {
                const combined = jiraSubTasks.map(jiraIssue => {
                    const dbIssue = dbIssues.find(dbIssue => dbIssue.issueKey === jiraIssue.key);
                    return {
                        jiraTicketNumber: jiraIssue ? jiraIssue.key : 'N/A',
                        subTestcase: dbIssue ? dbIssue.subTestcase : 'N/A',
                        status: jiraIssue ? jiraIssue.fields.status.name : 'N/A',
                        reporter: jiraIssue ? jiraIssue.fields.reporter.displayName : 'N/A',
                        assignee: jiraIssue && jiraIssue.fields.assignee ? jiraIssue.fields.assignee.displayName : 'N/A',
                        created: jiraIssue ? formatSriLankanDateTime(jiraIssue.fields.created) : 'N/A',
                        deviceId: dbIssue ? dbIssue.deviceId : 'N/A'
                    };
                });
    
                setCombinedIssues(combined);
                setFilteredIssues(combined);
            } else {
                setCombinedIssues([]);
                setFilteredIssues([]);
            }
        } catch (error) {
            console.log("Error fetching data:", error);
            setCombinedIssues([]);
            setFilteredIssues([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const syncTickets = async () => {
        try {
            setLoading(true);
            const response = await SyncJiraTickets();
            console.log("Sync response:", response);
            if (response.status === 200) {
                await fetchData();
                setIsToasterOpen(true);
                setReqSuccess(true);
                setToasterMessage(response.data.status);
            }
        } catch (error) {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage(error.data.status);
        }
        finally {
            setLoading(false);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 3000);
        }
    }

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px'}}>
                <Grid item xs={12} md={12}> 
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>Failure Anlaytics</Grid>
                    <Grid container style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <Grid container className={classes.redirectContainer}>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>                          
                                <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                                    <NonSuccessCriteriaIcon />
                                </Link>
                            </Grid>
                            <Arrow />
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                <DeviceFailureIcon />
                                <Typography className={classes.redirectTitle}>Failure Anlaytics</Typography>
                            </Grid>
                        </Grid>
                        {isProjectExist && 
                            <Button 
                                variant="contained"
                                className={`${classes.getStarted} ${classes.button}`}
                                style={{ height: '40px', width:'180px', backgroundColor: '#005B96'}}
                                onClick={handleEditPopUp}
                            >
                                Edit Configurations
                            </Button>
                        }
                    </Grid>
                    <Grid container className={classes.backgroundContainer}>
                        <Grid item xs={12} md={12} style={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                            <Grid item xs={12} md={6} style={{display:'flex', alignItems:'center'}}>
                                <Grid item xs={6} md={3} style={{marginRight:'10px'}}>
                                    <SearchBar onChange={handleSearch}/>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <FilterBatchNumber 
                                        toggleFilter={toggleFilter} 
                                        handlePrefixSelect={setSelectedStatus} 
                                        filterVisible={filterVisible} 
                                        setFilterVisible ={setFilterVisible}
                                        prefixes = {jiraStatus}
                                        selectedPrefix={selectedStatus}
                                        filterBatch={filterStatus}
                                        setSelectedPrefix = {setSelectedStatus}
                                        filterType="jiraStatus"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className={classes.buttonContainer} style={{marginBottom:"10px", justifyContent:'flex-end'}}>
                                <Button onClick={syncTickets} className={classes.refreshButton}>
                                    <RefreshApiIcon />
                                    <Typography style={{marginLeft:"10px"}}>Sync Jira</Typography>
                                </Button>
                            </Grid>
                        </Grid>  
                        <TablePagination style={{marginRight: '2px'}}
                            rowsPerPageOptions={[]}
                            component="div"
                            count={filteredIssues !== undefined && filteredIssues.filter((issue) => issue.deviceId.toLowerCase().includes(searchTerm.trim().toLowerCase())).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> 
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tableHeader}>Jira Ticket Number</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>Device ID</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>Failure Timestamp</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>Failure</TableCell>
                                <TableCell align="center" className={classes.tableHeader}>Jira Status</TableCell>
                                <TableCell align="center" className={classes.tableHeader}>Reporter</TableCell>
                                <TableCell align="center" className={classes.tableHeader}>Assignee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredIssues.length > 0 ? 
                                (filteredIssues.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((issue,index) => (
                                    <TableRow key={index} style={{backgroundColor: index % 2 == 0 ? '#F5F5F5':'#FFFFFF'}}>
                                        <TableCell>
                                            <Typography align="left" style={{fontSize:"14px"}}>
                                                {issue.jiraTicketNumber && issue.jiraTicketNumber != '' ? issue.jiraTicketNumber : 'N/A'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >{issue.deviceId}</TableCell>
                                        <TableCell>{issue.created !== 'N/A' ? issue.created : 'N/A'}</TableCell>
                                        <TableCell>{issue.subTestcase ? issue.subTestcase : 'N/A'}</TableCell>
                                        <TableCell>
                                            <Box display="flex" className={classes.jiraStatus} alignItems="center" style={{color: getColor(issue.status), backgroundColor: getBackgroundColor(issue.status), width: issue.status === "In Progress" ? "110px" : "fit-content"}}>
                                                {issue.status}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Typography align="center" style={{fontSize:"14px"}}>
                                                {issue.reporter}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography align="center" style={{fontSize:"14px"}}>
                                                {issue.assignee}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )))
                                : (
                                <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign:'center' }}>
                                        No Issues Available
                                    </TableCell>
                                </TableRow>
                                )
                            }   
                        </TableBody>
                    </Table>
                    </Grid>
                </Grid>
            </Grid>
            <EditConfigurationPopUp open={isEditPopUpOpen} handleWindowClose={cancelEditPopup} setIsToasterOpen={setIsToasterOpen} setReqSuccess={setReqSuccess} setToasterMessage={setToasterMessage} setLoading={setLoading} projectDetail={projectDetail} fetchData={fetchData}/>
            <Loader loading={loading} />
            <ToasterComponent 
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
        </ThemeProvider>
    )
};