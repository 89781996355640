import * as React from "react";
import {useEffect, useState} from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {primaryGray, searchBarBorderColor,} from "src/components/colors";
import {environment} from "src/environments/environment";
import axios from "axios";

export function Step4(props) {
  const classes = useStyles();
  const [deviceIds, setDeviceIds] = useState([]);

  const readMajorVersionUpgrade = (checked) => {
    props.readMajorVersionUpgrade(checked);
  };

  useEffect(() => {
    axios.get(`${environment.host}/core/device`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
          let ids = [];
          response.data.content.forEach((d, i) => {
            if (d.product) {
              ids.push(d.product.productId);
              // if (d.product.productId === props.selectedId) {
              //   ids.push(d.id);
              // }
            }
          });
          setDeviceIds(ids);
        }
      })
      .catch((err) => {
        console.log(err);
        setDeviceIds([]);
      });
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography className={classes.label}>Major Version Upgrade</Typography>
        <FormControlLabel
          label={""}
          control={
            <Checkbox
              className={classes.input}
              checked={props.majorVersionUpgrade}
              style={{padding: "0px"}}
              onChange={(e) => {
                readMajorVersionUpgrade(e.target.checked);
              }}
              color="primary"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          }
          labelPlacement="start"
        />
      </Grid>

      <Grid
        item
        xs={6}
        md={props.defaultDevices.length > 2 ? 12 : 6}
        style={{textAlign: "left", marginBottom: "20px"}}
      >
        <Typography className={classes.label}>Select Device ID's</Typography>
        <FormControl className={classes.input}>
          <Select
            id="demo-customized-select-native"
            value={props.defaultDevices}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: {paper: classes.menuPaper},
            }}
            className={classes.input}
            onChange={(e) => props.readDevices(e.target.value)}
            multiple
            renderValue={(selected: string[]) => (
              <div className={classes.chipsContainer}>
                {selected.map((value, i) => (
                  <Chip
                    key={i}
                    label={value}
                    className={classes.chip}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => props.removeDevice(value)}
                  />
                ))}
              </div>
            )}
            input={<BootstrapInput/>}
          >
            {deviceIds.map((d, index) => (
              <MenuItem value={d} key={index} divider={true}>
                <Checkbox
                  checked={props.defaultDevices.includes(d)}
                  style={{color: "#8F8F8F", display: "inline"}}
                />
                <ListItemText
                  primary={d}
                  style={{color: primaryGray, display: "inline"}}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  label: {
    fontSize: "15px",
    color: primaryGray,
    paddingBottom: "10px",
  },
  chip: {
    borderRadius: "4px",
    backgroundColor: "#C7C4C436",
    marginLeft: "5px",
    marginBottom: "10px",
  },
  menuPaper: {
    maxHeight: "200px",
  },
  chipsContainer: (props: {}) => ({
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "100px",
    overflowY: "auto",
  }),
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
