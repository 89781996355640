import * as React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
// import {ViewDecoder} from './connect-devices/view-decoder-details/ViewDecoder';
import {UserApplicationComponent} from './step-3/UserApllication';
import {Provider} from 'react-redux';
import store from '../../components/store.js'
import {SetupSenzMaticaComponent} from './SetupSenzMaticaComponent';
import {UserCreationComponent} from './step-4/UserCreation';
import {AddUserComponent} from './step-4/AddUser';
import {AutomlApp} from './step-5/AutoMlApp';
import {DefineProductTypeComponent} from './define-product-type-form/DefineProductTypeComponent';
import {ConnectDeviceComponent} from './connect-devices/ConnectDevice';
import { SenzMaticaComponent } from '../senzmatica/SenzMaticaComponent';
import { TestAutomationComponent } from '../maintenance/test-automation/TestAutomationComponent';
import { DefineTestCasesComponent } from '../maintenance/test-automation/step2/DefineTestCasesComponent';
import { DataStreamComponent } from '../maintenance/data-stream/DataStreamComponent';
import { StartTestingComponent } from '../maintenance/test-automation/step3/StartTestingComponent';
import { TestReportComponent } from '../maintenance/test-automation/step4/TestReportComponent';
import { RootCauseAnalysisComponent } from '../maintenance/test-automation/step5/RootCauseAnalysisComponent';
import { ConnectDataStreamComponent } from '../maintenance/data-stream/ConnectDataStream';
import { RemoteManagerComponent } from '../maintenance/remote-manager/RemoteManagerComponent';

import { OTAManagerComponent } from '../maintenance/otamanager/OTAManagerComponent';
import {AzureConnectDeviceComponent} from '../maintenance/data-stream/Azure IoT/AzureConnectDevices';
import { HumanoidNotificationSystemComponent } from '../maintenance/test-automation/step6/HumanoidNotificationSystem';
import {ResultDashboardComponent} from '../maintenance/test-automation/step7/ResultDashboard'
import { BatchesComponent } from '../batches/BatchesComponent';
import { TestCasesComponent } from '../testCases/TestCasesComponent';
import { RootCauseConfigComponent } from '../root-cause-config/RootCauseConfigComponent';
import { ChatbotConfigComponent } from '../chatbot-config/ChatbotConfigComponent';
import { ChatHistoryComponent } from '../chat-history/ChatHistoryComponent';
import { ChatBotComponent } from '../chat-history/ChatBotComponent';
import { DevicePulseDevicesComponent } from '../devicePulse-device/Devices';



export function SetupLayout() {
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <Switch>
            <Route path={"/ResultDashboard"} component={ResultDashboardComponent} />
            <Route path={"/HumanoidNotificationSystem"} component={HumanoidNotificationSystemComponent} />
            <Route path={"/TestAutomation"} component={TestAutomationComponent}/>
            <Route path={"/Remote-Manager"} component={RemoteManagerComponent}/>
            <Route path={"/DataStream"} component={DataStreamComponent}/>
            <Route path={"/Azure-IoT"} component={AzureConnectDeviceComponent}/>
            <Route path={"/ConnectDataStream"} component={ConnectDataStreamComponent}/>
            <Route path={"/DefineTestCases"} component={DefineTestCasesComponent}/>
            <Route path={"/StartTesting"} component={StartTestingComponent}/>
            <Route path={"/TestReport"} component={TestReportComponent}/>
            <Route path={"/RootCauseAnalysis"} component={RootCauseAnalysisComponent}/>
            <Route path={"/User-Application"} component={UserApplicationComponent}/>
            <Route path={"/User-Creation"} component={UserCreationComponent}/>
            <Route path={"User-Creation/addUser"} component={AddUserComponent}/>
            <Route path={"/automl"} component={AutomlApp}/>
            <Route path={"/DefineProductType"} component={DefineProductTypeComponent}/>
            <Route path={"/ConnectDevice"} component={ConnectDeviceComponent}/>
            <Route path={"/OTA-Manager"} component={OTAManagerComponent}/>
            <Route path={"/DevicePulseDevices"} component={DevicePulseDevicesComponent} />
            <Route path={"/Batches"} component={BatchesComponent} />
            <Route path={"/Testcases"} component={TestCasesComponent} />
            <Route path={"/RootCauseConfig"} component={RootCauseConfigComponent} />
            <Route path={"/ChatbotConfig"} component={ChatbotConfigComponent} />
            <Route exact path={"/ChatHistory"} component={ChatHistoryComponent} />
            <Route path={"/ChatHistory/ChatBot"} component={ChatBotComponent}/>
            <Route path={""} component={SenzMaticaComponent}/>
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>

  )
}
