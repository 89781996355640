import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../../layouts/side-menu/sidenav/sidenav.service';
import {ActivatedRoute} from '@angular/router';
import {AccountService} from '../../account/account.service';
import {AuthService} from '../../authentication/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ACCESS_TYPE, Actuator, Device, FORM_TYPE, MetaData, SECTIONS, Sensor, SelectedSensor} from '../../models';
import {formatDate} from '@angular/common';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import * as _ from 'underscore';
import * as c3 from 'c3';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';


@Component({
  selector: 'app-device-data',
  templateUrl: './device-data-actuator.component.html',
  styleUrls: ['./device-data-actuator.component.css'],
})
export class DeviceDataActuatorComponent implements OnInit {

  modalRef: NgbModalRef;
  form: FormGroup;
  selectedSensor;
  selectedSensorCode;
  deviceId;
  device: Device;
  sensorData = [];
  newSensorData = [];
  p = 0;
  sensors: Sensor[];
  actuators: Actuator[];
  xPointsCount = 8;
  dateRangeType = 0;
  isCustomDateSet = false;
  fromDate;
  fromDateStr;
  toDate;
  toDateStr;
  count = 10;
  error;
  isGraph = false;
  c3Chart: any;
  isAnnotation = false;
  selectedLabel = null;
  selectedSensors: SelectedSensor [] = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'codeValue',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    disabledField: 'isDisabled',
  };
  deviceLookup: { code: string; codeValue: string; isDisabled?: boolean }[] = [];
  labels = [];
  pointsHighlighted = [];
  pointsForLabeling: { 'id': string; 'label': string }[] = [];
  colors = ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099',
    '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E',
    '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC',
    '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'];
  newLabel: string;
  newLabelColor: string;
  colorCodes = [];
  addLabelErr = null;
  ACCESS_TYPE = ACCESS_TYPE;
  SECTION = SECTIONS;
  formType;
  showForm;
  dateTime: string;
  sensorsOfDevice = [];
  actuatorsOfDevice = [];
  deviceForm: FormGroup;
  sensorName = [];
  actuatorName = [];
  todayDate

  isSubmitted: boolean;
  metaData: MetaData;
  selectedDevice: Device;
  showDeviceForm;


  constructor(private sideNavService: SideNavService, private router: ActivatedRoute,
              private accountService: AccountService, private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private db: AngularFireDatabase, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.deviceId = params['deviceId'];
    });
    this.spinner.show();
    const today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1; // Note: Months are zero-based, so we add 1
    var day = today.getDate();
    this.todayDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    this.toDate = today;
    this.fromDate = today;

    this.getMetaData();
    this.deviceForm = this.formBuilder.group({
      sensor: [null],
      actuator: [null]
    });
  }

  setDeviceLookup(device) {
    this.deviceLookup = device.sensorCodes.map(code => ({
      code: code,
      codeValue: this.getSensorCode(code).name,
      isDisabled: String(code) === String(this.selectedSensorCode)
    }));
  }

  getDevice() {
    this.accountService.getDevice(this.deviceId)
      .subscribe(response => {
        this.device = response.content;
        this.sensorsOfDevice = this.device.actuatorCodes ? this.device.actuatorCodes.map(s => this.getSensorByCode(s)) : [];
        this.sensorsOfDevice.forEach((sensor) => {
          this.sensorName.push(sensor.name)
        })
        this.actuatorsOfDevice = this.device.actuatorCodes ? this.device.actuatorCodes.map(s => this.getActuatorByCode(s)) : [];
        this.actuatorsOfDevice.forEach((actuator) => {
          this.actuatorName.push(actuator.name)
        })
        this.device.actuatorCodes.length == 0 ? this.spinner.hide() : '';
        this.accountService.deviceName.next(this.device.name);
        if (this.device.actuatorCodes.length > 0) {
          this.selectTab(0);
        }
      }, (e) => {
        if (e.error.errorCode === 'E1011') {
          this.error = 'Device Not Found';
        }
        this.spinner.hide();
      });
  }

  editDevice(selectedDevice) {
    this.spinner.show();

    this.accountService.getMetaData().subscribe(
      (data) => {
        this.metaData = data.content;

        this.accountService.getDevice(this.deviceId).subscribe(
          (response) => {
            this.selectedDevice = response.content;
            this.showDeviceForm = true;
            this.formType = FORM_TYPE.EDIT;
            this.error = null;
            this.isSubmitted = false;

            this.sensorsOfDevice = this.selectedDevice.actuatorCodes
              ? this.selectedDevice.actuatorCodes.map((code) => this.sensors.find((sensor) => sensor.code === code))
              : [];

            this.actuatorsOfDevice = this.selectedDevice.actuatorCodes
              ? this.selectedDevice.actuatorCodes.map((code) => this.actuators.find((actuator) => actuator.code === code))
              : [];
            this.spinner.hide();
          },
          (error) => {
            console.error('Error fetching Device:', error);
            this.spinner.hide();
          }
        );
      },
      (error) => {
        console.error('Error fetching metadata:', error);
        this.spinner.hide();
      }
    );
  }

  getMetaData() {
    this.accountService.getMetaData().subscribe((data) => {
      this.sensors = data.content.sensors.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      this.actuators = data.content.actuators.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      this.getDevice();
    }, (e) => {
      if (e.errorCode === 'E1011') {
        this.error = 'Device Not Found';
      }
      this.spinner.hide();
    }, () => {
    });
  }

  getSensorData() {
    this.spinner.show();
    this.pointsForLabeling = [];
    this.accountService.getActuatorData(this.deviceId, this.selectedSensor, this.fromDateStr, this.toDateStr)
      .subscribe(response => {
        this.sensorData = response.content || null;
        this.collectExistingLabels();
        this.pickColorCode();
        if (response.content.length > 0) {
          console.log('server data: ', response.content);
          this.drawNormalGraph();
        }
        this.getDataFromFirebase()
        // this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }


  getSensorCode(code) {
    return this.actuators.filter(s => s.code === code, 1)[0];
  }

  onItemSelect(item: any): void {
    console.log('Selected Item:', item);
  }
  
  onItemDeSelect(item: any): void {
    console.log('Deselected Item:', item);
  }
  
  onSelectAll(items: any[]): void {
    console.log('Selected All:', items);
  }
  
  onDeSelectAll(items: any[]): void {
    console.log('Deselected All:', items);
  }  

  collectExistingLabels() {
    this.sensorData.map((data) => {
      data.label ? this.labels.includes(data.label) ? null : this.labels.push(data.label) : null;
    });
  }

  getRandomColor() {
    let color = '#';
    const letters = '0123456789ABCDEF';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    if (this.colors.includes(color)) {
      color = this.getRandomColor();
    }
    return (color);
  }

  pickColorCode() {
    const data = this.sensorData;
    const colors1 = Array(data.length).fill('#000000');
    this.sensorData.map((data, i) => {
      const label1 = data.label;
      if (!this.labels.includes(label1)) {
        label1 ? this.labels.push(label1) : null;
      }
      if (this.labels.length < this.colors.length) {
        const labelIndex = this.labels.indexOf(label1);
        const colorCode = this.colors[labelIndex];
        colorCode ? colors1[i] = colorCode : null;
      } else {
        const randomColor = this.getRandomColor();
        colors1[i] = randomColor;
      }
    });
    this.colorCodes = colors1;

  }

  selectTab(index: number) {
    const today = new Date();
    switch (this.dateRangeType) {
      case 0:
        this.toDate = today;
        this.fromDate = today;
        break;
      case 1:
        this.toDate = today;
        this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        break;
      case 2:
        this.toDate = today;
        this.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        break;
    }
    this.toDateStr = formatDate(this.toDate, 'yyyy-MM-dd', 'lk');
    this.fromDateStr = formatDate(this.fromDate, 'yyyy-MM-dd', 'lk');
    this.selectedSensor = index;
    this.selectedSensorCode = this.device.sensorCodes[index];
    this.setDeviceLookup(this.device);
    this.getSensorData();
    this.isCustomDateSet = false;
  }

  changeDateRange(rangeType: number) {
    this.dateRangeType = rangeType;
    if (this.dateRangeType !== 3) {
      this.selectTab(this.selectedSensor);
    }
  }

  getSensorDataForCustomRange() {
    if (this.isCustomDateSet) {
      this.selectTab(this.selectedSensor);
    } else {
      this.isCustomDateSet = true;
    }
  }

  public pageChange(event: number): void {
    this.p = event;
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  drawNormalGraph() {   // for normal graph representation
    const data = this.sensorData;
    let value = [];
    let time = [];
    const xs = {};
    const columns = [];
    const myArray = [];

    value = _.pluck(data, 'value');
    time = _.pluck(data, 'time');

    if (value[0] && value[0].includes('/')) {
      value[0].split('/').map((element, index) => (
        xs['Reading' + (index + 1)] = 'x'
      ));

      value.map((element, i) => {
        myArray.push(element.split('/'));
      });

      for (let i = 0; i < myArray[0].length; i++) {
        const arr = [];
        myArray.map((e, ind) => (
          arr.push(e[i] ? e[i] : 0)
        ));
        columns.push(['Reading' + (i + 1), ...arr]);
      }
    } else {
      xs['Reading'] = 'x';
      columns.push(['Reading', ...value]);
    }
    this.spinner.show();
    this.c3Chart = c3.generate(
      {
        bindto: '#chart',
        data: {
          xs: {
            ...xs,
          },
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: [
            ...columns,
            ['x'].concat(time),
          ]
        },
        subchart: {
          show: true
        },
        axis: {
          x: {
            show: true,
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },

            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed
              fit: true,
              culling: {
                max: 10,
              },
              count: this.xPointsCount,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: this.getSensorCode(this.device.actuatorCodes[this.selectedSensor]).name,
              position: 'outer-middle'
            }
          },

        }
      });
    this.spinner.hide();
    return false;
  }


  getAnnotationGraph() {    // editable annotateable graph representation
    const data = this.sensorData;
    const colors = this.colorCodes;

    let value = [];
    let time = [];
    let label = [];
    const xs = {};
    const xColor = {};
    const columns = [];
    const labelColumns = [];
    const myArray = [];

    value = _.pluck(data, 'value');
    time = _.pluck(data, 'time');
    label = _.pluck(data, 'label');

    if (value[0] && value[0].includes('/')) {
      value[0].split('/').map((element, index) => (
        xs['Reading' + (index + 1)] = 'x'
      ));

      value.map((element, i) => {
        myArray.push(element.split('/'));
      });

      for (let i = 0; i < myArray[0].length; i++) {
        const arr = [];
        myArray.map((e, ind) => (
          arr.push(e[i])
        ));
        columns.push(['Reading' + (i + 1), ...arr]);
      }
    } else {
      xs['Reading'] = 'x';
      xColor['Color'] = 'xc';
      columns.push(['Reading', ...value]);
      labelColumns.push(['Label', ...label]);
    }
    this.spinner.show();
    this.c3Chart = c3.generate(
      {
        bindto: '#chart',
        data: {
          xs: {
            ...xs,
          },
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: [
            ...columns,
            ['x'].concat(time),
          ],
          selection: {
            enabled: true,
            multiple: true,
            draggable: true,
          },

          onselected: (d, element) => {
            const selectedIndex = parseInt(d.index);
            this.pointsHighlighted.push(selectedIndex);
          },
          onunselected: (d, element) => {
            const unselectedIndex = d.index;
            this.pointsHighlighted = this.pointsHighlighted.filter((point) => point !== unselectedIndex);
          },
          color: function (color, d) {
            return colors[d.index];
          }
        },
        subchart: {
          show: true
        },
        axis: {
          x: {
            show: true,
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },

            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed
              fit: true,
              culling: {
                max: 10,
              },
              count: this.xPointsCount,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: this.getSensorCode(this.device.actuatorCodes[this.selectedSensor]).name,
              position: 'outer-middle'
            }
          },
        }
      });
    this.spinner.hide();
    return false;
  }

  switchGraph() {
    this.isAnnotation = !this.isAnnotation;
    this.isAnnotation ? this.getAnnotationGraph() : this.drawNormalGraph();
  }

  saveLabel() {
    this.isAnnotation = !this.isAnnotation;
    const labelChangeDetails = {
      sensorDTOS: this.pointsForLabeling,
      sensorCodeDTOS: this.selectedSensors.map(sensor => ({
          code: sensor.code,
          codeValue: sensor.codeValue
      }))
    };
    this.accountService.updateLabels(this.deviceId, labelChangeDetails)
      .subscribe(response => {
        console.log('put response', response);
      });
    this.getSensorData();
    this.selectedLabel = null;
  }

  openAddLabel(content) {  // To open the modal for getting new label
    console.log('Add Label modal opened');
    this.modalRef = this.modalService.open(content);
  }

  addNewLabel() {
    const newLabel = this.newLabel;

    if (newLabel) {
      if (this.labels.includes(newLabel)) {
        this.addLabelErr = 'This label is already existing, Please add different label!';
      } else {
        this.labels.push(newLabel);
        this.modalRef.close();
        this.addLabelErr = null;
      }
    } else {
      this.addLabelErr = 'Please enter a label name!';
    }
  }

  exportFunction() {
    const modifiedSet = _.map(this.sensorData, e => {
      return _.pick(e, ['time', 'value']);
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: ['Date', this.getSensorCode(this.device.actuatorCodes[this.selectedSensor]).name],
      nullToEmptyString: true,
    };


    const csv = new Angular5Csv(modifiedSet,
      this.deviceId + this.getSensorCode(this.device.actuatorCodes[this.selectedSensor]).name + 'propertyName' + '-Report', opt);
  }

  addKit() {
    this.formType = FORM_TYPE.ADD;
    this.showForm = true;
  }

  getDataFromFirebase() {
    const data = this.db.list('devices/' + this.deviceId).valueChanges();

    data.subscribe((item) => {
      this.newSensorData = item;
      this.newSensorData.forEach((sensor) => {
        const dateObject = new Date(sensor.time.millis);
        const timeObject = dateObject.toTimeString().split(/\s/)[0];
        const dateStr = formatDate(dateObject, 'yyyy-MM-dd', 'lk');
        const dateTime = dateStr + ' ' + timeObject;
        sensor.time = dateTime;

        if (sensor.code === this.getSensorCode(this.device.actuatorCodes[this.selectedSensor]).code && (sensor.number == 0 || sensor.number == 1)) {
          const filerSensor = sensor
          const existingSensorIndex = this.sensorData.findIndex((existingSensor) => existingSensor.id === filerSensor.id);
          if (existingSensorIndex === -1 && dateStr === this.todayDate) {
            if (!this.sensorData.some(sens => sens.time === sensor.time.replace("AM" || "PM", ""))) {
              this.sensorData.unshift(sensor);
            }
          }
        }
      });
    })
    this.spinner.hide();
  }

  getSensorActuatorDetails() {
    this.spinner.show()
    this.showForm = true;
    this.spinner.hide()
  }

  getSensorByCode(sensor) {
    return this.sensors.filter(s => {
      return s.code === sensor;
    }, 1)[0];
  }

  private getActuatorByCode(actuator) {
    return this.actuators.filter(a => {
      return a.code === actuator;
    }, 1)[0];
  }

  addDevice(value) {
    if (value.sensor != null || value.actuator != null) {
      if (value.sensor != null) {
        this.sensorName.push(value.sensor.charAt(0).toUpperCase() + value.sensor.slice(1).toLowerCase())
      }
      if (value.actuator != null) {
        this.actuatorName.push(value.actuator.charAt(0).toUpperCase() + value.actuator.slice(1).toLowerCase())
      }
    }
    this.deviceForm.reset()
  }
}

