import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'

interface SelectAModelComponentProps {
    open: boolean;
    handleCloseWindow: any;
    description:string
  }

export const DescriptionComponent :React.FC<SelectAModelComponentProps> = ({ open,handleCloseWindow,description }) => {

    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>Description</span>
                    <IconButton
                        onClick={handleCloseWindow}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style = {{color:'#5E5C5C'}}>
                <Typography  style={{padding:'20px 0'}}>
                    {description}
                </Typography>
            </DialogContent>
        </Dialog>
    )
}
