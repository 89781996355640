import * as React from "react";
import {Link} from 'react-router-dom';
import {Box, Button, createTheme, Grid, makeStyles, Theme, ThemeProvider, Typography} from "@material-ui/core";
import {primaryBlue, white} from "src/components/colors";

export function MaintenanceComponent() {
  const classes = useStyles(); 
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });

  return (
  <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <Grid container spacing={2}>

        <Grid item md={4}>
          <Box className={classes.boxContainer}>
            <div className={classes.buttonContainer}>
              <Grid item md={2}>
                <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="29" cy="28.5" r="28.5" fill="#F4F5F7"/>
                  <path d="M29.125 33.5312C29.125 33.6688 29.2313 33.7812 29.3594 33.7812H35.1406C35.2688 33.7812 35.375 33.6688 35.375 33.5312V32.0312C35.375 31.8938 35.2688 31.7812 35.1406 31.7812H29.3594C29.2313 31.7812 29.125 31.8938 29.125 32.0312V33.5312ZM23.0344 33.7219L29.0344 28.6906C29.1531 28.5906 29.1531 28.4062 29.0344 28.3063L23.0344 23.2781C22.9982 23.2473 22.9539 23.2276 22.9067 23.2212C22.8596 23.2148 22.8117 23.2221 22.7686 23.2422C22.7255 23.2622 22.689 23.2943 22.6636 23.3344C22.6381 23.3746 22.6247 23.4212 22.625 23.4688V25.4281C22.625 25.5031 22.6562 25.5719 22.7156 25.6187L26.1469 28.5L22.7156 31.3813C22.6877 31.4046 22.6651 31.4338 22.6495 31.4667C22.6339 31.4996 22.6255 31.5355 22.625 31.5719V33.5312C22.625 33.7438 22.8719 33.8594 23.0344 33.7219ZM40.5 16H17.5C16.9469 16 16.5 16.4469 16.5 17V40C16.5 40.5531 16.9469 41 17.5 41H40.5C41.0531 41 41.5 40.5531 41.5 40V17C41.5 16.4469 41.0531 16 40.5 16ZM39.25 38.75H18.75V18.25H39.25V38.75Z" fill="#555555"/>
                </svg>
              </Grid>
              <Grid item md={10}>
                <Typography className={classes.titleContainer}>
                  Device Pulse
                </Typography>
                <Typography className={classes.descriptionContainer}>
                  Description about the Device Pulse
                </Typography>
              </Grid>
            </div>
            <div className={classes.buttonContainer}>
              <Link to={{
                  pathname: `/TestAutomation`,
                }}>
                  <Button
                    variant="contained"
                    className={classes.getStarted}
                    style={{textTransform: "none"}}
                  >
                    Get Started
                  </Button>
              </Link>
            </div>
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box className={classes.boxContainer}>
              <div className={classes.buttonContainer}>
                <Grid item md={2}>
                  <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="29" cy="28.5" r="28.5" fill="#F4F5F7"/>
                    <path d="M29.0006 17.4545C27.03 17.4532 25.0909 17.9711 23.3623 18.9603C21.6337 19.9496 20.1714 21.3784 19.1105 23.1147L18.4766 24.1469L16.5 22.8226L17.1338 21.7893C18.4073 19.7066 20.162 17.9929 22.236 16.8063C24.31 15.6197 26.6364 14.9985 29.0006 15C33.9856 15 38.3649 17.7086 40.8673 21.7893L41.5 22.8226L39.5234 24.1481L38.8895 23.1147C37.8286 21.3787 36.3665 19.9501 34.6382 18.9608C32.9098 17.9715 30.971 17.4535 29.0006 17.4545ZM28.9865 22.3489C28.0014 22.3484 27.032 22.6074 26.168 23.102C25.3039 23.5967 24.5729 24.311 24.0426 25.179L23.4088 26.2124L21.4333 24.8881L22.0672 23.8548C22.8099 22.6402 23.8332 21.6407 25.0427 20.9485C26.2522 20.2564 27.6089 19.8938 28.9877 19.8944C30.3667 19.8936 31.7236 20.2561 32.9333 20.9482C34.1431 21.6404 35.1665 22.64 35.9094 23.8548L36.5432 24.8881L34.5666 26.2136L33.9327 25.179C33.4023 24.3108 32.6712 23.5964 31.8069 23.1017C30.9426 22.6071 29.9718 22.3482 28.9865 22.3489ZM20.7795 27.2727H37.2122V42H34.8647V29.7273H23.1271V42H20.7795V27.2727ZM27.8221 32.1818H30.1744V34.6413H27.8221V32.1818Z" fill="#555555"/>
                  </svg>
                </Grid>
                <Grid item md={10}>
                  <Typography className={classes.titleContainer}>
                    Remote Manager
                  </Typography>
                  <Typography className={classes.descriptionContainer}>
                    Description about the Remote Manager
                  </Typography>
                </Grid>
              </div>
              <div className={classes.buttonContainer}>
              <Link to={{
                  pathname: `/Remote-Manager`,
                }}>
                <Button
                  variant="contained"
                  className={classes.getStarted}
                  style={{textTransform: "none"}}
                  // onClick={handleGetStartedRemoteManager}
                >
                  Get Started
                </Button>
              </Link>
              </div>
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box className={classes.boxContainer}>
              <div className={classes.buttonContainer}>
                <Grid item md={2}>
                <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="29" cy="28.5" r="28.5" fill="#F4F5F7"/>
                  <path d="M12.5 23C12.5 21.3431 13.8431 20 15.5 20H41.3333C42.9902 20 44.3333 21.3431 44.3333 23V35.3C44.3333 36.9569 42.9902 38.3 41.3333 38.3H15.5C13.8431 38.3 12.5 36.9569 12.5 35.3V23Z" stroke="#555555" strokeWidth="2"/>
                  <path d="M31.6648 29.4877C31.6648 31.5715 30.1448 33.136 28.4164 33.136C26.6879 33.136 25.1679 31.5715 25.1679 29.4877C25.1679 27.4039 26.6879 25.8393 28.4164 25.8393C30.1448 25.8393 31.6648 27.4039 31.6648 29.4877Z" stroke="#555555" strokeWidth="1.5"/>
                  <path d="M40.2773 29.1497C40.2773 29.9255 39.7212 30.4297 39.1818 30.4297C38.6425 30.4297 38.0864 29.9255 38.0864 29.1497C38.0864 28.3739 38.6425 27.8697 39.1818 27.8697C39.7212 27.8697 40.2773 28.3739 40.2773 29.1497Z" fill="#555555" stroke="#555555" strokeWidth="1.5"/>
                  <path d="M18.747 29.1497C18.747 29.9255 18.1909 30.4297 17.6515 30.4297C17.1122 30.4297 16.5561 29.9255 16.5561 29.1497C16.5561 28.3739 17.1122 27.8697 17.6515 27.8697C18.1909 27.8697 18.747 28.3739 18.747 29.1497Z" fill="#555555" stroke="#555555" strokeWidth="1.5"/>
                </svg>
                </Grid>
                <Grid item md={10}>
                  <Typography className={classes.titleContainer}>
                    OTA Manager
                  </Typography>
                  <Typography className={classes.descriptionContainer}>
                    Description about the OTA Manager
                  </Typography>
                </Grid>
              </div>
              <div className={classes.buttonContainer}>
              <Link to={{
                  pathname: `/OTA-Manager`,
                }}>
                <Button
                  variant="contained"
                  className={classes.getStarted}
                  style={{textTransform: "none"}}
                  // onClick={handleGetStartedOTAManager}
                >
                  Get Started
                </Button>
                </Link>
              </div>
            </Box>
          </Grid>

        </Grid>
    </div>
  </ThemeProvider>
  );
}
export default MaintenanceComponent


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: white,
     marginTop: "5px", 
     padding: "10px 50px 30px",
     borderRadius: "3px"
  },
  boxContainer: {
    position: "relative",
    padding: "20px",
    marginTop: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 8px #00000012",
    borderRadius: "16px",
    opacity: 1,
  },
  titleContainer: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 600,
    marginLeft: "20px",
    marginRight: "80px",
     whiteSpace:"nowrap"
  },
  descriptionContainer: {
    fontWeight: 200,
    fontSize: "13px",
    margin: "10px 50px 30px 20px",
  },
  getStarted: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },

}));
