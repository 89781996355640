import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {primaryBlue} from 'src/components/colors';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: '15px',
    color: primaryBlue,
    cursor: 'pointer',
  },
  buttonHover: {
    textDecoration: 'underline', // Add underline on hover
  },
}));

const BrowseButton = (props) => {
  const classes = useStyles();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(props.isFileSelected);

  useEffect(() => {
    setIsFileSelected(props.isFileSelected);
  }, [props.isFileSelected]);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  return (
    <span
      className={`${classes.button} ${isMouseOver ? classes.buttonHover : ''}`}
      onClick={props.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isFileSelected ? '' : ' Browse'}
    </span>
  );
};

export default BrowseButton;
