import { Grid,IconButton,Tooltip,Typography } from '@material-ui/core';
import { Close, ThumbDown, ThumbUp } from '@material-ui/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useStyles from 'src/app/maintenance/assets/styles';
import { CloseIcon, ThumpsDownIcon, ThumpsUpIcon } from 'src/components/Icons';
import { updateAcknowledgement } from '../../components/apiHelper';
import Cookies from "js-cookie";

export const AcknowledgementComponent = (props) =>{
    const classes = useStyles()
    const [color,setColor] = useState('none')
    const [type,setType] = useState(null)
    
    useEffect(() =>{
        props.setData((prev) =>({
            ...prev,
        }))
    },[])

    const changeColor = (type) =>{
        setColor('#2A7BEC')
        setType(type)
        const data = {
            userId:Cookies.get('USER_ID'),
            rootcauseId:props.rootCauseId,
            referenceId:Cookies.get('ACCOUNT'),
            Like: type == 'like' ? true : false
        }
        props.setData(data)
        try{
            const response = updateAcknowledgement(data)
        }catch(error){

        }
    }
    return (
        <Grid container className={classes.acknowledgement} alignItems='center' justifyContent='space-between'>
            <Grid item className={classes.acknowledgementContent}>
                <Tooltip title="Helpful">
                    <IconButton onClick={() => changeColor("like")}>
                        <ThumpsUpIcon color={props.data.Like == true ? {color} : "none"} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Not Helpful">
                    <IconButton onClick={() => changeColor("dislike")}>
                        <ThumpsDownIcon color={props.data.Like == false ? {color} : "none"}  />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}