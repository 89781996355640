import * as React from "react";
import {useState} from "react";
import {Button, Grid, makeStyles, Theme,alpha} from "@material-ui/core";
import {primaryBlue, white} from "src/components/colors";
import {Link} from 'react-router-dom';
import {TextBar} from "src/components/sub-components/TextBar";
import {RightArrow} from "../../../components/Icons";
import useStyles from "src/app/maintenance/assets/styles";

export function DirectAPIComponent(props) {
  const classes = localStyle();
  const style = useStyles();
  const [url, setUrl] = useState(props.step == 'step3' ? "https://agro.senzmate.com/dev/swagger-ui.html#/kit-core-api/getKitUsingGET_1" : "https://agro.senzmate.com/dev/swagger-ui.html#/swagger-ui.html#/core-api/getSensorsDataHistoryUsingGET")
  const [urlValue, setUrlValue] = useState(props.step == 'step3' ? `agro.senzmate.com/dev/core/kit/${props.lastKitId}` : `agro.senzmate.com/dev/core/device/${props.lastDeviceId}/getAllDirectData`)


  const handleComplete = () => {
    localStorage.setItem('connectUserApplication', 'true')
  }

  return (

    <div>
      <Grid container spacing={5} style={{marginTop: '200px'}}>
        <Grid item xs={5} md={12}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={props.step == 'step3' ? 11 : 8} md={props.step == 'step3' ? 11 : 8}>
              <Grid container>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={9} md={11}>
                    <TextBar error={{error: false}} className={classes.input} style={classes.input}
                             value={urlValue}></TextBar>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs={9} md={11}>
                    <a href={url}
                       target="_blank"
                       className={classes.link}
                    >
                      Go to Swagger
                      <span className={classes.arrowContainer}>
                      <RightArrow/>
                      </span>  
                    </a>
                  </Grid>

                </Grid>
                {props.step != 'step3' ?
                  <Grid container style={{marginTop: '75px'}} justifyContent="center">
                    <Grid item xs={9} md={11} style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button
                        className={style.saveButton}
                        style={{textTransform: "none"}}
                        onClick={() => props.setIsApiClick(false)}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                  :
                  <Grid container style={{marginTop: '75px'}} justifyContent="center">
                    <Grid item xs={11} md={11}>
                      <Grid container justifyContent="flex-end">
                        <Grid item style={{marginRight: '15px'}}>
                          <Button
                            className={style.backButton}
                            style={{textTransform: "none"}}
                            onClick={props.decreaseStep}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item onClick={() => handleComplete()} style={{marginTop: '0px'}}>
                          <Link to="">
                            <Button
                              className={style.saveButton}
                              style={{textTransform: "none"}}
                            >
                              Done
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>


  );
}

const localStyle= makeStyles((theme: Theme) => ({
  root: {
    transition: "0.55s",
    textAlign: "left",
    margin: "40px 0px 40px 100px",
  },
  container: {
    paddingTop: "20px",
  },
  redChip: {
    border: "10px",
    color: "red",
    height: "30px",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    top: '100px',
    position: 'relative'
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #ced4da",
    fontSize: 14,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // width:'405px',
    height: '40px',
    fontFamily: "'Poppins', sans-serif",
  },
  link: {
    color: '#2A7BEC',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
    alignItems: 'center',
    textDecoration: 'none'
  },
  arrowContainer: {
    '& svg': {
      width: '15px',  
      height: '10px', 
    },
  },
  
}));
