import * as React from "react";
import {useEffect, useState} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {primaryBlue, primaryGray, searchBarBorderColor,} from "src/components/colors";
import {Step1} from "./steps/Step1";
import {Step2} from "./steps/Step2";
import {TextBar} from "src/components/sub-components/TextBar";
import {Step3} from "./steps/Step3";
import {Step4} from "./steps/Step4";
import {submit} from "../api-helper/apiHelper";
import {Alert} from "@material-ui/lab";
import {CloseIconColor} from "src/components/Icons";

export function AddNewFirmWare(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(true);
  const [message, setMessage] = useState("");

  const [step, setStep] = useState(0);
  const [productTypeError, setProductTypeError] = useState(false);
  const [versionError, setVersionError] = useState({
    isError: false,
    message: "",
  });
  const [binError, setBinError] = useState({error: false, errorMsg: ""});
  const [parameterError, setParameterError] = useState({
    error: false,
    errorMsg: "",
  });
  const [idError, setIdError] = useState({error: false, errorMsg: ""});
  const [steps, setSteps] = useState([
    {
      text: "Basic Details",
      activate: true,
    },
    {
      text: "Remote Configuration",
      activate: false,
    },
    {
      text: "Add Technology Flow Chart",
      activate: false,
    },
    {
      text: "Select Testing Device",
      activate: false,
    },
  ]);
  const [addParameter, setAddParameter] = useState(false);
  const [mainType, setMainType] = useState(""); // network , topic or message
  const [type, setType] = useState("");
  const [index, setIndex] = useState(-1);

  //parameter inputs
  const [parameter, setParameter] = useState("");
  const [id, setId] = useState("");
  const [inputType, setInputType] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  const [format, setFormat] = useState("");
  const [join, setJoin] = useState([]);

  //final states that should send to BE ***************
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [selectedVersionClone, setSelectedVersionClone] = useState("");
  const [previousVersions, setPreviousVersions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedBin, setSelectedBin] = useState();
  const [binFileName, setBinFileName] = useState("");
  const [parameters, setParameters] = useState([]);
  const [parametersAddedError, setParametersAddedError] = useState(false);
  const [existingFlowChart, setExistingFlowChart] = useState("");
  const [flowChart, setFlowChart] = useState("");
  const [flowChartError, setFlowChartError] = useState(false);
  const [flowChartName, setFlowChartName] = useState("");
  const [flowChartFileName, setFlowChartFileName] = useState("");

  const [deviceIds, setDeviceIds] = useState([]);
  const [majorVersionUpgrade, setMajorVersionUpgrade] = useState(false);
  const [networkVisibility, setNetworkVisibility] = useState(false);
  const [topicVisibility, setTopicVisibility] = useState(false);
  const [messageVisibility, setMessageVisibility] = useState(false);
  const [AddFlowChart,setAddFlowChart]=useState("")
  const [inputValue, setInputValue] = useState("");
  const [defaultValues, setDefaultValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setDefaultValues((prevChips) => [...prevChips, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeleteChip = (index) => {
    if (type === "edit" || type === "add-new") {
      const updatedValues = [...defaultValues];
      updatedValues.splice(index, 1);
      setDefaultValues(updatedValues);
    }
  };

  const renderChips = (array) => {
    if (array && array.length > 0) {
      return array.map((chip, index) => (
        <Chip
          key={index}
          label={chip}
          onDelete={type !== "view" ? () => handleDeleteChip(index) : undefined}
          className={classes.chipDefault}
        />
      ));
    } else {
      return null;
    }
  };

  const openNetworkVisibility = () => {
    setNetworkVisibility(!networkVisibility);
  };
  const openTopicVisibility = () => {
    setTopicVisibility(!topicVisibility);
  };
  const openMessageVisibility = () => {
    setMessageVisibility(!messageVisibility);
  };

  const isVersionValid = (targetVersion) => {
    const isValidNumericVersion = /^[0-9]+(\.[0-9]+)*$/.test(targetVersion);
    return isValidNumericVersion;
  };

  //read all input fields
  const readProductType = (productType) => {
    setSelectedProductType(productType);
    setProductTypeError(false);
  };

  const readMajorVersionUpgrade = (upgrade) => {
    setMajorVersionUpgrade(upgrade);
  };

  const readVersion = (version) => {
    setSelectedVersion(version);
    setVersionError({isError: false, message: ""});
  };

  const readVersionClone = (version) => {
    setSelectedVersionClone(version);
    setVersionError({isError: false, message: ""});
  };
  const readBin = (files) => {
    const acceptedFile = files[0];

    if (acceptedFile) {
      setBinFileName(acceptedFile.name);
      setSelectedBin(acceptedFile);

      if (!acceptedFile.name.endsWith(".bin")) {
        setBinError({error: true, errorMsg: "Invalid file type"});
      } else {
        setBinError({error: false, errorMsg: ""});
      }
    }
  };

  const readParametersCloned = (parameters) => {
    setParameters(parameters);
    setParametersAddedError(false);
  };

  const readParameters = (values) => {
    if (type === "add-new") {
      let error;
      if (values.parameter === "" || values.id === "") {
        error = true;
        if (values.parameter === "") {
          setParameterError({
            error: true,
            errorMsg: "Parameter Name is required",
          });
        }
        if (values.id === "") {
          setIdError({error: true, errorMsg: "Id is required"});
        }
        if (error === true) {
          return;
        }
      }

      if (parameters.filter((obj) => obj.id === values.id).length > 0) {
        setIdError({error: true, errorMsg: "Id Already exist"});
        return;
      }
      setParameters((current) => [...current, values]);
    }
    if (type === "edit" && index !== -1) {
      const keys = Object.keys(values);
      keys.map((k) => {
        if (values[k] !== "") {
          parameters[index][k] = values[k];
        }
      });
      setParameters(parameters);
      setType("");
    }
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setAddParameter(false);
    setMajorVersionUpgrade(false);
  };
  const readExistingFlowChart = (fileName) => {
    setExistingFlowChart(fileName);
    setFlowChartError(false);
  };
  const readFlowChart = (file) => {
    setFlowChartName(file[0].name);
    setFlowChart(file[0]);
    setFlowChartError(false);
  };
  const readDevices = (devices) => {
    setDeviceIds(devices);
  };

  const readMessage = (message) => {
    if (message === "Data Cloned Successfully") {
      setStatus(true);
      setMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    } else {
      setStatus(false);
      setMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    }
  };

  const openAddParameter = (mainType, type, i) => {
    setAddParameter(true);
    setMainType(mainType);
    setType(type);
    setIndex(i);
    if (type === "edit") {
      setInputType(parameters[i].inputType);
      setFormat(parameters[i].format);
      setJoin(parameters[i].joinParameter);
      setDefaultVal(parameters[i].defaultValue);
      if (
        parameters[i] &&
        parameters[i].defaultValues !== null &&
        parameters[i].defaultValues !== undefined
      ) {
        setDefaultValues(parameters[i].defaultValues);
      }
      setId(parameters[i].id);
    }
  };
  const closeAddParameter = () => {
    setType("");
    setAddParameter(false);
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setParameterError({error: false, errorMsg: ""});
    setIdError({error: false, errorMsg: ""});
    setDefaultValues([]);
    setMajorVersionUpgrade(false);
  };

  const increaseStep = async () => {
    let error;
    if (binError.error) {
      return;
    }
    if (
      step === 0 &&
      (selectedProductType === "" ||
        selectedVersion === "" ||
        binFileName === "")
    ) {
      if (selectedProductType === "") {
        setProductTypeError(true);
      }
      if (selectedVersion === "") {
        setVersionError({isError: true, message: `Version Required`});
      }
      if (binFileName === "") {
        setBinError({error: true, errorMsg: "Bin file is required"});
      }
      return;
    }
    if (step === 0 && id === "") {
      try {
        const matchingProduct = props.products.find(
          (product) =>
            product.productName === selectedProductType
        );
        if (matchingProduct) {
          setSelectedId(matchingProduct.id);
          const approvedVersions = matchingProduct.versions.filter(
            (version) => version.status === "APPROVED"
          );

          const versionNums = approvedVersions.map(
            (version) => version.versionNum
          );
          setPreviousVersions(versionNums);

          if (!isVersionValid(selectedVersion)) {
            setVersionError({
              isError: true,
              message: `Version is not valid`,
            });
            return;
          }
        } else {
          setVersionError({
            isError: true,
            message: `No matching product found for the selected type and category`,
          });
          return;
        }
      } catch (error) {
        setVersionError({
          isError: true,
          message: `No matching product found for the selected type and category`,
        });
        return;
      }
    }

    if (step === 2 && existingFlowChart === "" && flowChart === "") {
      error = true;
      setFlowChartError(true);
      return;
    }
    if (step === 1 && parameters.length === 0) {
      error = true;
      setParametersAddedError(true);
      return;
    }

    if (step === 2) {
      setLoading(true);
      const result = await submit(
        selectedBin,
        binFileName,
        flowChart,
        selectedVersion,
        parameters,
        existingFlowChart,
        selectedId,
        majorVersionUpgrade,
        AddFlowChart,
        flowChartFileName
      );
      setLoading(false);
      if (result.message === "Success") {
        setStatus(true);
        setMessage(result.message);
        setOpen(true);
        props.refresh();
        setTimeout(() => {
          setOpen(false);
          closePopUp();
        }, 1000);
      } else {
        setStatus(false);
        setMessage(result.message);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 6000);
      }
    } else {
      setStep(step + 1);
      steps.map((s, i) => {
        if (i <= step + 1) {
          s.activate = true;
        } else {
          s.activate = false;
        }
      });
      setSteps(steps);
    }
  };

  const decreaseStep = () => {
    if (step === 0) {
      closePopUp();
      return;
    } else {
      setStep(step - 1);
      steps.map((s, i) => {
        if (i <= step - 1) {
          s.activate = true;
        } else {
          s.activate = false;
        }
      });
      setSteps(steps);
    }
  };

  const selectJoin = (e) => {
    setJoin(e.target.value);
  };
  const removeJoin = (value) => {
    setJoin(join.filter((d) => d !== value));
  };
  const removeDevice = (value) => {
    setDeviceIds(deviceIds.filter((d) => d !== value));
  };

  const closePopUp = () => {
    props.onClose();
    setSelectedProductType("");
    setSelectedVersion("");
    setSelectedCategory("");
    setSelectedBin(undefined);
    setBinFileName("");
    setParameters([]);
    setExistingFlowChart("");
    setFlowChart(undefined);
    setFlowChartName("");
    setDeviceIds([]);
    setProductTypeError(false);
    setVersionError({isError: false, message: ""});
    setBinError({error: false, errorMsg: ""});
    setFlowChartError(false);
    setParametersAddedError(false);
    setSelectedId("");
    setSelectedVersionClone("");
    setPreviousVersions([]);
    setMajorVersionUpgrade(false);
  };
  useEffect(() => {
    setStep(0);
    steps.map((s, i) => {
      if (i === 0) {
        s.activate = true;
      } else {
        s.activate = false;
        setFlowChartError(false);
        setParametersAddedError(false);
      }
    });
    setSteps(steps);
    setParameter("");
    setId("");
    setInputType("");
    setDefaultVal("");
    setFormat("");
    setJoin([]);
    setParameters([]);
    setDefaultValues([]);
  }, [props.open]);

  return (
    <Dialog open={props.open}>
      <Grid container>
        <Grid item xs={10} md={10}>
          <DialogTitle>
            {type === "view" ? "View" : type === "edit" ? "Edit" : "Add"}{" "}
            {addParameter ? "Parameter" : "Firmware"}
          </DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{textAlign: "right"}}>
          <IconButton
            onClick={() => {
              addParameter ? closeAddParameter() : closePopUp();
            }}
          >
            <CloseIconColor/>
          </IconButton>
        </Grid>
      </Grid>

      <Divider/>
      {addParameter ? (
        // ***** This is add new parameter input filed set ***** //
        <>
          <DialogContent>
            <Grid container style={{textAlign: "center", marginTop: "20px"}}>
              <Grid
                item
                xs={6}
                md={6}
                style={{textAlign: "left", paddingBottom: "20px"}}
              >
                <Typography className={classes.label}>
                  Enter Parameter
                </Typography>
                <TextBar
                  disabled={type === "view" ? true : false}
                  error={parameterError}
                  onChange={(e) => {
                    setParameter(e.target.value);
                    setParameterError({error: false, errorMsg: ""});
                  }}
                  value={
                    type === "edit" || type === "view"
                      ? parameters[index].parameter
                      : ""
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{
                  textAlign: "left",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                }}
              >
                <Typography className={classes.label}>Enter Id</Typography>
                <TextBar
                  disabled={type === "add-new" ? false : true}
                  type={"number"}
                  error={idError}
                  onChange={(e) => {
                    setId(e.target.value);
                    setIdError({error: false, errorMsg: ""});
                  }}
                  onWheel={(e) => e.target.blur()}
                  value={
                    type === "edit" || type === "view"
                      ? parameters[index].id
                      : ""
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{textAlign: "left", paddingBottom: "20px"}}
              >
                <Typography className={classes.label}>
                  Enter Input Type
                </Typography>
                <FormControl style={{padding: "0px", width: "78%"}}>
                  <NativeSelect
                    disabled={type === "view" ? true : false}
                    id="demo-customized-select-native"
                    value={
                      type === "view" ? parameters[index].inputType : inputType
                    }
                    className={classes.input}
                    onChange={(e) => setInputType(e.target.value)}
                    input={<BootstrapInput/>}
                  >
                    <option value="" disabled hidden>
                      Select input type
                    </option>
                    <option value="text">Text</option>
                    <option value="json">Json</option>
                    <option value="Html">Html</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{
                  textAlign: "left",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                }}
              >
                <Typography className={classes.label}>Default</Typography>
                <TextBar
                  disabled={type === "view" ? true : false}
                  error={{error: false}}
                  onChange={(e) => setDefaultVal(e.target.value)}
                  value={
                    type === "edit" || type === "view"
                      ? parameters[index].defaultValue
                      : ""
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{textAlign: "left", paddingBottom: "20px"}}
              >
                <Typography className={classes.label}>Enter Format</Typography>
                <FormControl style={{padding: "0px", width: "78%"}}>
                  <NativeSelect
                    disabled={type === "view" ? true : false}
                    id="demo-customized-select-native"
                    value={type === "view" ? parameters[index].format : format}
                    className={classes.input}
                    onChange={(e) => setFormat(e.target.value)}
                    input={<BootstrapInput/>}
                  >
                    <option value="" disabled hidden>
                      Select format type
                    </option>
                    <option value="String">string</option>
                    <option value="int">int</option>
                    <option value="double">double</option>
                    <option value="float">float</option>
                    <option value="char">char</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{
                  textAlign: "left",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                }}
              >
                <Typography className={classes.label}>
                  Join parameter
                </Typography>
                <FormControl style={{minWidth: "78%"}}>
                  <Select
                    disabled={type === "view" ? true : false}
                    id="demo-customized-select-native"
                    value={
                      type === "view" ? parameters[index].joinParameter : join
                    }
                    className={classes.input}
                    onChange={selectJoin}
                    multiple
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    renderValue={(selected: string[]) => (
                      <div>
                        {selected.map((value, i) => (
                          <Chip
                            key={i}
                            label={value}
                            className={classes.chip}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={() => removeJoin(value)}
                          />
                        ))}
                      </div>
                    )}
                    input={<BootstrapInput/>}
                  >
                    {type === "add-new" ? (
                      parameters
                        .filter((obj) => obj.parameterCategory === mainType)
                        .map((p, j) => {
                          return (
                            <MenuItem value={p.id} key={j}>
                              <ListItemText
                                primary={p.id}
                                style={{color: primaryGray}}
                              />
                            </MenuItem>
                          );
                        })
                    ) : type === "edit" ? (
                      parameters
                        .filter((obj) => obj.parameterCategory === mainType)
                        .filter((ob) => ob.id !== parameters[index].id)
                        .map((p, j) => {
                          return (
                            <MenuItem value={p.id} key={j}>
                              <ListItemText
                                primary={p.id}
                                style={{color: primaryGray}}
                              />
                            </MenuItem>
                          );
                        })
                    ) : (
                      <div></div>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{textAlign: "left", paddingBottom: "20px"}}
              >
                <Typography className={classes.labelDefaultValue}>
                  Default Values
                </Typography>
                <div className={classes.inputDefaultValue}>
                  {renderChips(defaultValues)}

                  {type !== "view" && (
                    <TextField
                      label="Add Default Values"
                      variant="outlined"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          {type !== "view" && (
            <DialogActions style={{width: "100%"}} hidden>
              <Button
                variant="contained"
                className={classes.cancel}
                onClick={closeAddParameter}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.add}
                disabled={type === "view" ? true : false}
                onClick={() => {
                  const updatedDefaultValues = defaultValues.filter(
                    (val) => val !== defaultVal
                  );

                  readParameters({
                    parameterCategory: mainType,
                    parameter: parameter,
                    id: id,
                    inputType: inputType,
                    format: format,
                    // joinParameter: join,
                    defaultValue: defaultVal,
                    defaultValues: updatedDefaultValues,
                  });
                }}
              >
                {type === "edit" ? "Update" : "Save"}
              </Button>
            </DialogActions>
          )}
        </>
      ) : (
        // ***** This is add normal pop-up steps ***** //
        <>
          <DialogContent>
            <Grid container style={{textAlign: "center", marginTop: "20px"}}>
              {steps.map((step, index) => (
                <Hidden key={index}>
                  {index === 0 && <Grid item xs={1} md={1}></Grid>}
                  <Grid item xs={1} md={1}>
                    <Circle
                      number={index + 1}
                      active={step.activate}
                      step={step}
                    />
                  </Grid>
                  {index !== 3 && (
                    <Grid item xs={2} md={2} style={{width: "100px"}}>
                      <hr
                        style={{
                          marginTop: "20px",
                          opacity: steps[index + 1].activate ? "1" : "0.12",
                        }}
                        color={
                          steps[index + 1].activate
                            ? primaryBlue
                            : "rgba(112, 112, 112, .12)"
                        }
                      />
                    </Grid>
                  )}
                </Hidden>
              ))}
              {steps.map((step, index) => (
                <Grid item xs={3} md={3} key={index}>
                  <Typography
                    style={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12} md={12}>
                {step === 0 && (
                  <Step1
                    productTypeError={productTypeError} 
                    versionError={versionError} 
                    binError={binError}
                    readVersion={(version) => readVersion(version)}
                    defaultVersion={selectedVersion}
                    readProductType={(productType) =>
                      readProductType(productType)
                    }
                    defaultProductType={selectedProductType}
                    readBin={(file) => readBin(file)}
                    binFileName={binFileName}
                  />
                )}
                {step === 1 && (
                  <Step2
                    openNetworkVisibility={() => openNetworkVisibility()}
                    openTopicVisibility={() => openTopicVisibility()}
                    openMessageVisibility={() => openMessageVisibility()}
                    networkVisibility={networkVisibility}
                    topicVisibility={topicVisibility}
                    messageVisibility={messageVisibility}
                    open={(mainType, type, i) =>
                      openAddParameter(mainType, type, i)
                    }
                    parameters={parameters}
                    productType={selectedProductType}
                    deviceCategory={selectedCategory}
                    version={selectedVersion}
                    readVersionClone={(version) => readVersionClone(version)}
                    previousVersions={previousVersions}
                    selectedVersionClone={selectedVersionClone}
                    readParameters={(parameters) => readParameters(parameters)}
                    readParametersCloned={(parameters) =>
                      readParametersCloned(parameters)
                    }
                    readMessage={(message) => readMessage(message)}
                    parametersAddedError={parametersAddedError}
                  />
                )}
                {step === 2 && (
                  <Step3
                    readExistingFlowChart={(fileName) =>
                      readExistingFlowChart(fileName)
                    }
                    defaultFlowChart={existingFlowChart}
                    flowChartError={flowChartError}
                    readFlowChart={(file) => readFlowChart(file)}
                    chartFilename={flowChartName}
                  />
                )}
                {step === 3 && (
                  <Step4
                    readDevices={(devices) => readDevices(devices)}
                    readMajorVersionUpgrade={(upgrade) =>
                      readMajorVersionUpgrade(upgrade)
                    }
                    majorVersionUpgrade={majorVersionUpgrade}
                    selectedId={selectedId}
                    removeDevice={(device) => removeDevice(device)}
                    defaultDevices={deviceIds}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{width: "100%"}}>
            <Button
              onClick={decreaseStep}
              variant="contained"
              className={classes.cancel}
            >
              {step === 1 || step === 2 || step === 3 ? "Back" : "Cancel"}
            </Button>
            <Button
              onClick={increaseStep}
              variant="contained"
              className={classes.add}
              disabled={loading}
            >
              {step === 0 || step === 1 || step === 2 ? (
                "Next"
              ) : loading ? (
                <CircularProgress size={24} color="inherit"/>
              ) : (
                "Submit"
              )}
            </Button>
          </DialogActions>
        </>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert severity={status === true ? "success" : "warning"}>
          <h3 style={{textAlign: "left"}}>
            {status === true ? "Success" : "Error"}
          </h3>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export const Circle = (props) => {
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          backgroundColor: "white",
          border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{color: props.active ? primaryBlue : primaryGray}}>
          {props.number}
        </h5>
      </div>
    </div>
  );
};

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    add: {
      backgroundColor: primaryBlue,
      color: "white",
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    cancel: {
      backgroundColor: "white",
      color: primaryGray,
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    input: {
      minWidth: "100%",
      maxWidth: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px",
    },
    inputDefaultValue: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    labelDefaultValue: {
      marginBottom: "20px",
    },
    chipDefault: {
      borderRadius: "2px",
      backgroundColor: "#C7C4C436",
      marginLeft: "2px",
      marginBottom: "10px",
    },
  })
);


const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
