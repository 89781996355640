import { Box, Button, Card, CardContent, Grid, IconButton, Popper, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { CopilotIcon, PhoneIcon, ViewIcon } from 'src/components/Icons'
import useStyles from '../../assets/styles'
import { Chatbot } from './Chatbot'
import { parseResponse } from './ParseRespose'
import { checkDbStatus, CopilotInitialApi, getNotifications, markAsRead } from '../components/apiHelper'
import { DialogPopup } from '../components/DialogPopup'
import { LineChart } from '../components/LineChart'
import { Toast } from 'react-toastify/dist/components'
import { Loader } from '../components/Loader'
import { ToasterComponent } from '../components/Toaster'
import { send } from 'process'
import axios from 'axios'
import { environment } from 'src/environments/environment'
import { azureService, formatLargeNumber } from 'src/app/utils'
import Cookies from "js-cookie";

export const NotificationComponent = ({ 
    notification,
    closeNotification,
    message,
    isToaster,
    success,
    toastMessage,
    chatCount,
    notificationCount,
    isLoading,
    isNotificationOpen
}) => {
    const [notificationData, setNotificationData] = useState([])
    const [notificationBatch,setNotificationBatch] = useState('')
    const classes = useStyles()
    const [isNeedHelp,setIsNeedHelp] = useState(false)
    const [rootCauseId,setRootCauseId] = useState('')
    const [messages,setMessages] = useState(message)
    const [isToasterOpen, setIsToasterOpen] = useState(isToaster)
    const [toasterMessage, setToasterMessage] = useState(toastMessage)
    const [reqSuccess, setReqSuccess] = useState(success)
    const [startIndex, setStartIndex] = useState(0); 
    const [endIndex, setEndIndex] = useState(50); 
    const [hasMoreNotifications, setHasMoreNotifications] = useState(true);
    const [shownNotificationsIds, setShownNotificationsIds] = useState([]) 
    const [graphDetails,setGraphDetails] = useState({})
    const [chatIndex,setChatIndex] = useState(chatCount)
    const [isViewGraph,setIsViewGraph] = useState(false)
    const [loading,setLoading] = useState(isLoading)
    const [notificationNewIds,setNotificationNewIds] = useState([])
    const [acknowledgementStatus,setAcknowledgementStatus] = useState(null)
    const [responseCount,setResponseCount] = useState(0)
    
    const expandGraph = () =>{
        // setLoading(true)
        setIsViewGraph(true)
        setTimeout(() =>{
            setLoading(false)
        },3000)
    }

    const openGraph = async (path) => {
        try {
            setLoading(true)
            const response = await axios.post(`${environment.chatbotHost}/get-img-content/`, { "img_path": path },{
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
                }
              });
            const answer = response.data.data;

            console.log('answer:', answer);

            if (!Array.isArray(answer.values)) {
                throw new Error("Graph data values are missing or malformed.");
            }
    
            const scale = formatLargeNumber(Math.max(...answer.values)).scale; 
            const formattedValues = answer.values.map((value) => {
                const { value: formattedValue, scale } = formatLargeNumber(value);
                return formattedValue;
            });
    
            const graphDetails = {
                x_label: answer.x_label,
                y_label: `Values (in ${scale})`,
                title: answer.title,
                values: formattedValues,
                creation_dates: answer.creation_dates
            };
    
            setGraphDetails(graphDetails);
            setIsViewGraph(true);
        } catch (error) {
            console.error("Error fetching graph data:", error);
        }finally{
            setLoading(false)
        }
    };

    const closeGraph = () =>{
        setIsViewGraph(false)
    }

    useEffect(() => {
        loadNotifications(startIndex, endIndex);
    }, []);

    const loadNotifications = async (start, end) => {
        try {
            const { content, unreadNotifications } = await getNotifications(start, end);
            if (content.length === 0) {
                setHasMoreNotifications(false);
            }
            if (content && content.length > 0) {
                let notifications = content.map(data => {
                    return {
                        date: data.time,
                        predictions: data.rootCauseResults[0].predictions,
                        deviceId: data.rootCauseResults[0].deviceId,
                        id: data.id,
                        batchNumber: data.batchNumber,
                        failedSubTestCaseName: data.failedSubTestCaseName,
                        read : data.read
                    };
                });
                setNotificationData(prevData => [...prevData, ...notifications]);
                let count = content.filter(notification => notification.read == false).length

                if (notificationCount) {
                    notificationCount(count);  // This is calling the function passed from Angular
                }
                const newIds = content.map(data => data.id);
                setNotificationNewIds(newIds)
                setShownNotificationsIds(prevIds => [...prevIds, ...newIds]);
                if(isNotificationOpen){
                    await markAsRead(newIds);
                }   
            }
        } catch (err) {
            console.error('Error Fetching notifications:', err);
        }
    };

    useEffect(() =>{
        if(isNotificationOpen){
            markAsRead(notificationNewIds)
            if (notificationCount) {
                notificationCount(0);  // This is calling the function passed from Angular
            }
        } 
    },[isNotificationOpen])

    const handleSeeMore = () => {
        const newStartIndex = endIndex + 1;
        const newEndIndex = endIndex + 9; 
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
        loadNotifications(newStartIndex, newEndIndex);
    };
    

    const handleCopilot = async (event, note) => {
        closeNotification()
        let isNeed = isNeedHelp;
        if (isNeed == true) {
            setIsNeedHelp(false);
            setMessages([]);
            setResponseCount(0)
        } else {
            try {
                setLoading(true)
                const dbStatus = await checkDbStatus(note.batchNumber);
                if (dbStatus.message === "No file is processing for this batch.") {
                    setIsNeedHelp(!isNeedHelp);
                    const data = { 
                        "rootCauseId": note.id,
                        referenceId : Cookies.get('ACCOUNT') ,
                        userId : Cookies.get('USER_ID')
                    };
                    setRootCauseId(note.id);
                    setLoading(false)
                    setNotificationBatch(note.batchNumber);
                    setMessages((prev) => {
                        const updatedMessages = [
                            ...prev,
                            { sender: "bot", loading: true }, // Add the new message
                        ];
                        return updatedMessages; // Return the updated state
                    });
                        const response = await CopilotInitialApi(data);
                        if (response) {
                            if(response.chat_history.length > 0){
                                setResponseCount(response.chat_history.length);
                            }else{
                                setResponseCount((prevCount) => prevCount + 1);
                            }
                            const newMessages = [];
                            setAcknowledgementStatus(response.acknowledgement_status)
                            // Add the NOTIFICATION first
                            if (response.NOTIFICATION) {
                                newMessages.push({ text: response.NOTIFICATION, sender: "bot" });
                            }
                            if (response.chat_history && response.chat_history.length > 0) {
                                response.chat_history.forEach((chat) => {
                                    if(chat.Type === 'text'){
                                        newMessages.push({ text: chat.User, sender: "user" });
                                        newMessages.push({ text: chat.Bot_Response, sender: "bot" });
                                    } else if(chat.Type === 'graph'){
                                        newMessages.push({ text: chat.User, sender: "user" });
                                        newMessages.push({ image: (
                                            <Button
                                                variant="outlined"
                                                startIcon={<ViewIcon color="#2A7BEC" />}
                                                style={{ color: '#2A7BEC', borderColor: '#2A7BEC1A' }}
                                                onClick={() => openGraph(chat.Bot_Response)}
                                            >
                                                View Graph
                                            </Button>
                                        ), sender: "bot" });
                                    }
                                });
                            }
        
                            setMessages((prev) => {
                                // Replace the last "loading" message with the actual bot response
                                const updatedMessages = prev.map((message, index) => {
                                    if (index === prev.length - 1 && message.loading) {
                                        return { ...message, ...newMessages.shift(), loading: false };
                                    }
                                    return message;
                                });
                        
                                // Add any remaining new messages
                                return [...updatedMessages, ...newMessages];
                            });
                        
                        }
                    
                } else {
                    setIsToasterOpen(true);
                    setReqSuccess(false);
                    setToasterMessage(dbStatus.message);
                    setLoading(false)
                    return;
                }
            } catch (error) {
                setIsToasterOpen(true)
                setReqSuccess(false)
                setToasterMessage(error.message)
            }finally{
                setLoading(false)
                setTimeout(() => {
                    setIsToasterOpen(false);
                }, 5000);
            }
        }
    };

    useEffect(() =>{
        setNotificationData(notification)
    },[notification])


    return (
        <Grid container style={{ maxWidth: '400px'}}>
            <Grid item xs={12} md={12} className={classes.notification}>
                <Grid item>
                    <Typography style={{ padding: '20px', color: '#555555', fontSize: '20px' }}>Notification</Typography>
                </Grid>
                <Grid item >
                    <IconButton onClick={closeNotification}>
                        <Close />
                    </IconButton>

                </Grid>

            </Grid>
            <Grid item xs={12} md={12} style={{overflow:'auto',maxWidth:'auto'}}>
                {notificationData.map((note, index) =>
                    <Grid item key={index}>
                        <CardNotification 
                            note={note} 
                            handleCopilot = {(event) =>handleCopilot(event,note)}
                        />
                    </Grid>
                )}
                {hasMoreNotifications && (
                    <Grid item xs={12} md={12} style={{ padding: '10px', display: 'flex', justifyContent: 'center',marginBottom:"50px" }}>
                        <Button
                            variant="outlined"
                            style={{ color: '#2A7BEC', borderColor: '#2A7BEC1A', borderRadius: '22px', textTransform: 'none' }}
                            onClick={handleSeeMore}
                        >
                            See More
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Popper
                open={isNeedHelp}
                className = {classes.notificationPopup}
                style={{
                    top: "70px",
                    left:'520px'
                }}
            >  
                <Chatbot 
                    handleClose={handleCopilot}
                    messages={messages} 
                    setMessages={setMessages}   
                    parseResponse={parseResponse}
                    selectedBatch={notificationBatch}
                    type={'copilot'}
                    rootCauseId = {rootCauseId}
                    setGraphDetails = {setGraphDetails}
                    expandGraph = {(path) =>openGraph(path)}
                    setChatIndex = {setChatIndex}
                    acknowledgementStatus = {acknowledgementStatus}
                    setResponseCount = {setResponseCount}
                    responseCount={responseCount}
                />
            </Popper>
            <DialogPopup 
                    open={isViewGraph} 
                    onClose = {closeGraph} 
                    title = {'Expand Graph'} 
                    dialogWidth = {'1800px'}
                    dialogContent = {<LineChart graph_details={graphDetails} isExpand={true} index={chatIndex}/>}
            />
            <Popper
                style={{zIndex : 1000}}
                
                open={loading}
            >
                <Loader loading={loading} />
            </Popper>
            <Popper
                open={isToasterOpen}
            > 
                <ToasterComponent 
                    reqSuccess={reqSuccess} 
                    message={toasterMessage} 
                    toaster={isToasterOpen} 
                />

            </Popper>
            
            
        </Grid>    
    )
}

export const CardNotification = ({ note,handleCopilot }) => {
    const classes = useStyles()
    const {isAzureFreePackage, isAzureBasicPackage, isAzurePremiumPackage} = azureService()
    const isDisabled = isAzureFreePackage() || isAzureBasicPackage();
    return (
        <Box>
            <Card>
                <CardContent style={{display:'flex', overflowY: 'auto' ,flexGrow:1}}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Typography>
                                The <b>{note.deviceId}</b>{" "}
                                    {"(Batch: " + note.batchNumber + ")"} encountered an {note.failedSubTestCaseName} failure{" "}
                                    {note.predictions !== "" ? (
                                    <>
                                        due to <b>{note.predictions}</b>.
                                    </>
                                    ) : (
                                    "."
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{display:'flex',justifyContent:'space-between'}}>
                            <Typography style={{ color: '#2A7BEC', fontSize: '10px', paddingTop: '10px' }}>{note.date}</Typography>
                            <Button 
                                startIcon={<CopilotIcon />} 
                                variant="outlined" 
                                className={`${classes.needHelpButton} ${isDisabled ? 'disabled' : ''}`}
                                onClick ={(event) => handleCopilot(event,note)}
                                disabled={isDisabled ? true : false}
                                > 
                                Need a Help?
                            </Button>
                        </Grid>
                    </Grid>
                    
                </CardContent>
            </Card>
        </Box>
    )
}