import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Circle } from '../../maintenance/test-automation/components/Circle';
import { primaryBlue, primaryGray } from 'src/components/colors';
import { EditProject } from './EditProjectConfig';
import { EditFiles } from './EditUploadFiles';
import { DeleteFile, updateProjectClientConfigurations, uploadFile } from 'src/app/maintenance/test-automation/components/apiHelper';
import { environment } from 'src/environments/environment';
import { Loader } from 'src/app/setup-senzmatica/step-3/components/Loader';
import Cookies from "js-cookie";
import { UploadIcon } from 'src/components/Icons';

export const EditChatbotConfigComponent = ({ 
    open, 
    onClose, 
    chatConfig , 
    steps , 
    setStep , 
    step, 
    STEPS,
    selectedBatchNumber,
    projectNames,
    setErrors,
    errors,
    setSteps,
    setLoading,
    setIsToasterOpen,
    setToasterMessage,
    setReqSuccess,
    handleExpand
}) => {
    const [files, setFiles] = useState([])
    const [fileList, setFileList] = useState([])
    const [isDeleteConformation,setIsDeleteConformation] = useState(false)
    const [deleteFileType,setDeleteFileType] = useState('')
    const [fileIndex,setFileIndex] = useState(0)
    const [deleteFileList,setDeleteFileList] = useState([])
    const [uploadedFile,setUploadedFile] = useState([])
    const [existFiles,setExistFiles] = useState(fileList)

    const formValidate = () =>{
        const error : any= {}

        if(fileList && fileList.length == 0 && step == 1){
            error.KnowledgedBase = "Attaching at least one document is required"
        }

        return error
    }

    const increaseStep = () => {
        let validate = formValidate();
        const hasError = Object.values(errors).some(error => error != null)
        if(hasError){
            setErrors(prevError => ({
                ...prevError,
                ...validate
            }));
        }else{
            setErrors(validate)
        }
        // Return early if there are existing errors in the state or new validation errors
        if (hasError || Object.keys(validate).length > 0) {       
            return;
        }
        if (step === 1) {

            if(uploadedFile.length > 0){
                updateFiles();
            }
            setUploadedFile([])
            onClose()
        } 
    };

    useEffect(() => {
        updateStepActivation(step);
      }, [step]);
    
    function updateStepActivation(currentStep) {
      const updatedSteps = steps.map((step, index) => ({
        ...step,
        activate: index === currentStep,
      }));
      setSteps(updatedSteps);
    }

    const updateFiles = async () =>{
        let formData = new FormData()
        uploadedFile.forEach((file,index)=>{
            if (file.file) {
                formData.append('files', file.file, file.name);
            }
        })
        const jsonFile = {
            batchNumber: selectedBatchNumber, 
            // header_token: "Bearer " + Cookies.get("ACCESS_TOKEN"),
            // proto: environment.host,
            deleteFiles : deleteFileList ,
            referenceId : Cookies.get('ACCOUNT')
        };
        formData.append('json_data', JSON.stringify(jsonFile));
        try{
            uploadFile(formData)
            await setIsToasterOpen(true)
            setToasterMessage("File Updated Successfully.")
            setReqSuccess(true)
        }catch(error){
            setIsToasterOpen(true)
            setToasterMessage(error.message)
            setReqSuccess(false)
        }finally{
            setTimeout(() =>{
                setIsToasterOpen(false)
            },2500)
        }
    }

    const handleDeleteFile = async (data, message) => {
        try {
            setLoading(true);
            await DeleteFile(data);
            setIsToasterOpen(true);
            setToasterMessage(message);
            setReqSuccess(true);
        } catch (error) {
            setIsToasterOpen(true);
            setToasterMessage(error.message);
            setReqSuccess(false);
        } finally {
            setLoading(false);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
        }
    };

    useEffect(() =>{
        if(chatConfig.files !== 'EMPTY'){
            setExistFiles(chatConfig.files)
        }else{
            setFiles([])
            setFileList([])
        }
    },[chatConfig])

    
    const removeAFile = async () => {
        if (deleteFileType === "removeAllFile") {
            setFileList([]);
            setUploadedFile([])
            setIsDeleteConformation(false);
            const existFileName = deleteFileList.filter(name => 
                existFiles.some(file => file.name === name)
            );            
            console.log(existFileName)
            const data = {
                batchNumber: selectedBatchNumber,
                deleteFiles: existFileName,
                referenceId: Cookies.get('ACCOUNT')
            };
            await handleDeleteFile(data, `Successfully removed the files`);
        } else {
            const selectedFiles = [...fileList];
            const file = selectedFiles[fileIndex];
            if (file) {
                selectedFiles.splice(fileIndex, 1);
                const data = {
                    batchNumber: selectedBatchNumber,
                    deleteFiles: [file.name],
                    referenceId : Cookies.get('ACCOUNT')
                };
                if(existFiles.includes(file)){
                    await handleDeleteFile(data, `Successfully removed the file ${file.name}`);
                }
                
                setFileList(selectedFiles);
                setIsDeleteConformation(false);
                setUploadedFile(selectedFiles)
                let updatedFileErrors = { ...errors.fileError };
                updatedFileErrors[fileIndex] = null;
                const allErrorsNull = Object.values(updatedFileErrors).every(error => error === null);

                setErrors(prevError => ({
                    ...prevError,
                    fileError: allErrorsNull ? null : updatedFileErrors // Clear fileError if all errors are null
                }));
            }
        }
    };

    const removeAllFile = () =>{
        let deleteList = []
        setIsDeleteConformation(true)
        setDeleteFileType('removeAllFile')
        fileList.map(file =>{
            deleteList.push(file.name)
        })
        setDeleteFileList(deleteList)
    }

    return (
        <Grid>
            {step == 1 && <EditFiles 
                files={chatConfig.files} 
                setFiles={setFiles} 
                fileList={fileList} 
                setFileList={setFileList} 
                removeAFile={removeAFile} 
                isDeleteConformation={isDeleteConformation}
                setIsDeleteConformation={setIsDeleteConformation}
                fileIndex={fileIndex}
                setFileIndex={setFileIndex}
                setDeleteFileType={setDeleteFileType}
                deleteFileType={deleteFileType}
                errors={errors}
                setErrors={setErrors}
                setDeleteFileList ={setDeleteFileList}
                setUploadedFile = {setUploadedFile}
                setIsToasterOpen={setIsToasterOpen}
                setReqSuccess = {setReqSuccess}
                setToasterMessage = {setToasterMessage}
            />}
            <Grid container justifyContent='flex-end' style={{marginBottom:'15px'}}>
                    {/* <Button variant='outlined' style={{ marginRight: '10px' }} onClick={onClose}>cancel</Button> */}
                    {step == 1 && fileList.length > 0 && <Button variant='outlined' style={{ color: 'red', borderColor: 'red',marginRight: '10px' }} onClick={removeAllFile}>Remove All</Button>}
                    <Button 
                        variant='contained' 
                        style={{ backgroundColor: '#2A7BEC', color: 'white' }} 
                        onClick={increaseStep} 
                        startIcon={<UploadIcon color='white' />}
                    >
                        Upload All
                    </Button>
                </Grid>
        </Grid>
    )
}

