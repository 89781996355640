import React, { ReactNode } from 'react';
import { Tooltip, Box, makeStyles } from '@material-ui/core';

interface HoverTooltipProps {
  title: string;
  children: ReactNode;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  width?: string | number;
}

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#324054',
    width: '362px',
    minHeight: '98px',
    padding: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    fontSize: '16px',
    borderLeft: '9px solid #005B96',
  },
  customArrow: {
    color: '#ffffff',
  },
});

export const HoverTooltip: React.FC<HoverTooltipProps> = ({ 
  title, 
  children, 
  placement = 'left',
  width = '100%'
}) => {
  const classes = useStyles();
  
  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      enterDelay={50}
      leaveDelay={50}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      <Box width={width}>
        {children}
      </Box>
    </Tooltip>
  );
};