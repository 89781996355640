const settingInitialState = {
  isChangePasswordVisible: false,
  isLoading: false,
  isLoadingCount: 0,
  confirmationDialog: {},
  timePicker: {},
  isLoaderCount: 0,
};

const settingReducer = (state = settingInitialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default settingReducer;
