import { createTheme, Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Arrow, ChatCardIcon, ChatHistoryIcon, NonSuccessCriteriaIcon } from 'src/components/Icons';
import { convertToSriLankanTime, handleDevicePulseClicked } from 'src/app/utils';
import { useState, useEffect } from 'react';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { fetchChatSessions } from '../maintenance/test-automation/components/apiHelper';
import useStyles from '../maintenance/assets/styles';
import Cookies from "js-cookie";

type ChatSession = {
    creationDate: string;
    deviceId: string;
    title: string;
    notification: string;
    batch_number: string;
};

export const ChatHistoryComponent = () => {
    const [chatData, setChatData] = useState({
        today: { count: 0, sessions: [] },
        yesterday: { count: 0, sessions: [] },
        previous30Days: { count: 0, sessions: [] },
    });
    const [loading, setLoading] = useState(false);
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    const userId = Cookies.get('USER_ID');
    const referenceId = Cookies.get('ACCOUNT');

    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const history = useHistory();
    const classes = useStyles();

    const handleCardClick = async (sessionId, batchNumber) => {
      setLoading(true);
  
      try {
          // Extract from the response
          const referenceId = sessionId.split('_')[0];
          const userId = sessionId.split('_')[1];
          const rootCauseId = sessionId.split('_')[2];
  
          const requestBody = {
            referenceId: referenceId,
            userId: userId,
            rootCauseId: rootCauseId,
          };
  
          const response = await axios.post(
              `${environment.chatbotHost}/get-chat-history/`,
              requestBody,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
                }
              }
          );
  
          history.push(`/ChatHistory/ChatBot/${rootCauseId}`, {
              chatbotData: response.data,
              batchNumber: batchNumber,
              rootCauseId: rootCauseId,
          });
      } catch (error) {
        let errorMessage = 'An unexpected error occurred'; // Default error message

        if (error.response && error.response.status) {
          const statusCode = error.response.status;

          if (statusCode === 500) {
            errorMessage = 'Something went wrong.';
          } else if (statusCode === 422) {
            errorMessage = error.response.data.message;
          }
        }
        setIsToasterOpen(true);
        setReqSuccess(false);
        setToasterMessage(errorMessage);
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 5000);
      } finally {
        setLoading(false);
      }
      
    };

    const getAllChatSessions = async () => {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
  
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
  
      const isSameDate = (date1: Date, date2: Date) =>
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
  
        const groupByDateRange = (sessions: any[]) => {
          const sortedSessions = sessions.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
  
          return {
            today: sortedSessions.filter(session => isSameDate(new Date(session.creationDate), today)),
            yesterday: sortedSessions.filter(session => isSameDate(new Date(session.creationDate), yesterday)),
            previous30Days: sortedSessions.filter(session => {
                const sessionDate = new Date(session.creationDate);
                return sessionDate >= thirtyDaysAgo && sessionDate < yesterday;
            }),
          };
        };
    
        try {
            setLoading(true);
            const response = await fetchChatSessions(referenceId, userId);
            const sessions = Object.entries(response.data).map(([key, value]: [string, ChatSession]) => ({
                ...value,
                creationDate: convertToSriLankanTime(value.creationDate),
                id: key,
                title: value.title,
                description: value.notification,
                batchNumber: value.batch_number
            }));
    
            const grouped = groupByDateRange(sessions);
    
            return {
                today: { count: grouped.today.length, sessions: grouped.today },
                yesterday: { count: grouped.yesterday.length, sessions: grouped.yesterday },
                previous30Days: { count: grouped.previous30Days.length, sessions: grouped.previous30Days },
            };
        } catch (error) {
          let errorMessage = 'An unexpected error occurred';

          if (error.response && error.response.status) {
            const statusCode = error.response.status;
  
            if (statusCode === 500) {
              errorMessage = 'Something went wrong.';
            } else if (statusCode === 422) {
              errorMessage = error.response.data.message;
            }
          }
          setIsToasterOpen(true);
          setReqSuccess(false);
          setToasterMessage(errorMessage);
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 5000);
            return null;
        } finally {
            setLoading(false);
        }
    };
  

    useEffect(() => {
        const fetchChatSessions = async () => {
          const data = await getAllChatSessions();
          if (data) {
            setChatData(data);
          }
        };
    
        fetchChatSessions();
    }, []);
      

    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ padding: '25px' }}>
          <Grid item xs={12} md={12}>
            <Grid className={classes.pageTitle} style={{margin:'0px'}}>Chat History</Grid>
          <Grid
            container
            className={classes.redirectContainer}
          >
            <Grid
              item
              style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
            >
              
              <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
              <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                <NonSuccessCriteriaIcon />
              </IconButton>
              </Link>
            </Grid>
            <Arrow />
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <ChatHistoryIcon />
              <Typography className={classes.redirectTitle}>Chat History</Typography>
            </Grid>
          </Grid>
    
          {['today', 'yesterday', 'previous30Days'].map((key) => {
            // Check if the category has any sessions
            if (chatData[key].count > 0) {
              return (
                <Grid
                  container
                  className={classes.backgroundContainer}
                  style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                  key={key}
                >
                  <Grid
                    item
                    style={{ flexDirection: 'row', display: 'flex', gap: '10px', alignItems: 'center' }}
                  >
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        padding: '5px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {key === 'today'
                        ? 'Today'
                        : key === 'yesterday'
                        ? 'Yesterday'
                        : 'Previous 30 Days'}
                    </Typography>
                    <Typography
                      style={{
                        color: '#949494',
                        padding: '10px',
                        display: 'flex',
                        height: '25px',
                        width: '25px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        border: '1px solid #D9D9D9',
                      }}
                    >
                      {chatData[key].count}
                    </Typography>
                  </Grid>
    
                  <Grid container spacing={2}>
                    {chatData[key].sessions.map((session) => (
                      <Grid item xs={12} sm={6} md={4} key={session.id}>
                        <CardComponent
                          color={
                            key === 'today' ? '#2A7BEC' : key === 'yesterday' ? '#02C170' : '#949494'
                          }
                          onClick={() => handleCardClick(session.id, session.batchNumber)}
                          title={session.title}
                          description={session.description}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
          </Grid>
        </Grid>
        <Loader loading={loading} />
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
      />
      </ThemeProvider>
    );
};
    
export const CardComponent = ({ color, onClick, title, description }) => {
    return (
    <Grid container style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '20px', border: '1px solid #E5E7EB', borderRadius: '15px', margin: '10px 0px', backgroundColor: '#FFFFFF', cursor: 'pointer' }} onClick={onClick}>
        <IconButton style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid #E5E7EB', borderRadius: '5px', alignContent: 'center', padding: '6px', justifyContent: 'center', width: '15%' }}>
        <ChatCardIcon color={color} />
        </IconButton>
        <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>{title}</Typography>
        <Typography variant="body1" style={{ color: '#838383' }}>{description}</Typography>
    </Grid>
    );
};