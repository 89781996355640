import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import {useEffect, useState} from "react";
import AddNewDecoder from "src/app/setup-senzmatica/define-product-type-form/CreateCodec";
import {primaryBlue, white} from "src/components/colors";
import {TextBar} from "src/components/sub-components/TextBar";
import {environment} from "src/environments/environment";
import ReactSwitch from "react-switch";
import {CloseIcon, DownArrow, UpArrow} from "../../../components/Icons";
import axios from "axios";

export function EditProductType(props) {
  const classes = useStyles();
  const {
    productName,
    sensorCodes,
    actuatorCodes,
    connectivity,
    protocol,
    persistence,
    codecName,
    transcoder,
    id
  } = props.productType.productType;

  const [showSensorPopup, setShowSensorPopup] = useState(false);
  const [showActuatorPopup, setShowActuatorPopup] = useState(false);
  const [isToggled, setIsToggled] = useState(!transcoder);
  const [isTranscoding, setIsTranscoding] = useState(false);

  const [sensorsOfDevice, setSensorsOfDevice] = useState([]);
  const [actuatorsOfDevice, setActuatorsOfDevice] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState('');
  const [selectedActuator, setSelectedActuator] = useState('');
  const [sensors, setSensors] = useState([]);
  const [actuators, setActuators] = useState([]);
  const [step, setStep] = useState("");
  const [codecs, setCodecs] = useState([]);
  const [selectedCodec, setSelectedCodec] = useState('');
  const [codecToSend, setCodecToSend] = useState("");
  const [isCodecDropDown, setIsCodecDropDown] = useState(false);

  const [metaData, setMetaData] = useState(null);
  const [updatedPersistence, setUpdatedPersistence] = useState(persistence);
  const [updatedProductName, setUpdatedProductName] = useState(productName);
  const [updatedConnectivity, setUpdatedConnectivity] = useState(connectivity);
  const [updatedProtocol, setUpdatedProtocol] = useState(protocol);
  const [updatedCodecName, setUpdatedCodecName] = useState(codecName);
  const [sensorCodesOfDevice, setSensorCodesOfDevice] = useState(sensorCodes);
  const [actuatorCodesOfDevice, setActuatorCodesOfDevice] = useState(actuatorCodes);

  //Error Messages
  const [productNameError, setProductNameError] = useState(false);
  const [sensorsError, setSensorsError] = useState(false);
  const [showCreateCodecDialog, setShowCreateCodecDialog] = useState(false);

  React.useEffect(() => {
    fetchCodecs();
    fetchMetaData();
    if (codecName !== null) {
      setStep("select");
      setCodecToSend(codecName);
    }
  }, [codecName]);

  // Event listener to handle clicks outside dropdowns
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isCodecDropDown) {
        if (!event.target.closest('.codec-dropdown')) {
          setIsCodecDropDown(false);
        }
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isCodecDropDown]);

  useEffect(() => {
    if (sensorCodes && metaData) {
      const names = sensorCodes.map(code => {
        const sensor = metaData.sensors.find(s => s.code === code);
        return sensor ? sensor.name : code;
      });
      setSensorsOfDevice(names);
    }
    if (actuatorCodes && metaData) {
      const actuatorNames = actuatorCodes.map(code => {
        const actuator = metaData.actuators.find(s => s.code === code);
        return actuator ? actuator.name : code;
      });
      setActuatorsOfDevice(actuatorNames);
    }
  }, [props.productType, metaData]);

  useEffect(() => {
    setUpdatedPersistence(props.productType.productType.persistence);
  }, [props.productType]);

  useEffect(() => {
    console.log("Updated actuatorsOfDevice:", actuatorsOfDevice);
  }, [actuatorsOfDevice, actuatorCodesOfDevice]);

  // Fetch MetaData from the API endpoint
  const fetchMetaData = () => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    axios.get(`${environment.host}/core/meta-data`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        setMetaData(response.data.content);
        setSensors(response.data.content.sensors.sort((a, b) => (a.name < b.name ? -1 : 1)));
        setActuators(response.data.content.actuators.sort((a, b) => (a.name < b.name ? -1 : 1)));
      })
      .catch((error) => {
        console.error('Error fetching metadata:', error);
      });
  };

  //API endpoint for the update product type
  const updateProductType = async () => {
    checkTranscoding();
      const accessToken = localStorage.getItem('ACCESS_TOKEN');

      const updatedData = {
        id: id,
        productName: updatedProductName,
        connectivity: updatedConnectivity,
        protocol: updatedProtocol,
        persistence: updatedPersistence,
        sensorCodes: sensorCodesOfDevice,
        actuatorCodes: actuatorCodesOfDevice,
        codecName: codecToSend,
        transcoder: !isToggled ? true : false
      };

      await axios.put(`${environment.host}/core/product/productType/${id}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const updatedProductType = response.data;
        console.log("Updated Product Type:", updatedProductType);
      })
      .catch((error) => {
        console.error('Error updating product type:', error);
      });
  };

  // Fetch product types from the API endpoint
  const fetchCodecs = async () => {
      const accessToken = localStorage.getItem('ACCESS_TOKEN');
      await axios.get(`${environment.host}/core/codec`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) =>{
        setCodecs(response.data.content);
      })
      .catch((error) => {
        console.error('Error fetching codecs:', error.response.statusText);
      });
  };

  // Functions to handle changes in form fields
  const handleProductNameChange = (event) => {
    setUpdatedProductName(event.target.value);
    setProductNameError(false);
  };
  const handleConnectivityChange = (event) => {
    setUpdatedConnectivity(event.target.value);
  };
  const handleProtocolChange = (event) => {
    setUpdatedProtocol(event.target.value);
  };
  const handlePersistenceChange = (value) => {
    const newPersistenceValue = value === 'true';
    setUpdatedPersistence(newPersistenceValue);
  };

  const handleSensorSelection = (event) => {
    const selectedSensorName = event.target.value;
    if (selectedSensorName === 'AddSensor') {
      setShowSensorPopup(true);
    } else {
      const selectedSensor = sensors.find(sensor => sensor.name === selectedSensorName);
      if (selectedSensor && !sensorsOfDevice.includes(selectedSensorName)) {
        setSensorsOfDevice(prevSensors => [...prevSensors, selectedSensorName]);
        setSensorCodesOfDevice(prevSensors => [...prevSensors, selectedSensor.code]);
      }
      setSelectedSensor('');
      setSensorsError(false);
    }
  };

  const removeSensorFromDevice = (indexToRemove) => {
    setSensorsOfDevice(prevSensors => {
      const updatedSensors = [...prevSensors];
      updatedSensors.splice(indexToRemove, 1); // Remove the sensor at the specified index
      return updatedSensors;
    });
    setSensorCodesOfDevice(prevSensorCodes => {
      const updatedSensorCodes = [...prevSensorCodes];
      updatedSensorCodes.splice(indexToRemove, 1); // Remove the sensor code at the specified index
      return updatedSensorCodes;
    });
  };

  const handleActuatorSelection = (event) => {
    const selectedActuatorName = event.target.value;
    if (selectedActuatorName === 'AddActuator') {
      setShowActuatorPopup(true);
    } else {
      const selectedActuator = actuators.find(actuator => actuator.name === selectedActuatorName);
      if (selectedActuator && !actuatorsOfDevice.includes(selectedActuatorName)) {
        setActuatorsOfDevice(prevActuators => [...prevActuators, selectedActuatorName]);
        setActuatorCodesOfDevice(prevActuatorCodes => {
          if (!Array.isArray(prevActuatorCodes)) {
            prevActuatorCodes = []; // Initialize as an empty array if not already defined
          }
          return [...prevActuatorCodes, selectedActuator.code];
        });
        console.log("setActuatorsOfDevice", actuatorsOfDevice)
      }
      setSelectedActuator('');
    }
  };

  const removeActuatorFromDevice = (indexToRemove) => {
    setActuatorsOfDevice(prevActuators => {
      const updatedActuators = [...prevActuators];
      updatedActuators.splice(indexToRemove, 1);
      return updatedActuators;
    });
    setActuatorCodesOfDevice(prevActuatorCodes => {
      const updatedActuatorCodes = [...prevActuatorCodes];
      updatedActuatorCodes.splice(indexToRemove, 1);
      return updatedActuatorCodes;
    });
  };

  const handleCodecChange = (option) => {
    setCodecToSend(option);
  };
  const handleChangeToggle = () => {
    handleTranscoding();
    setIsToggled(!isToggled)
    setIsCodecDropDown(false);
    setSelectedCodec("");
    setCodecToSend("");
    setStep("select");
  }
  const handleTranscoding = () => {
    if (isToggled) {
      setIsTranscoding(true);
    }
  }
  const checkTranscoding = () => {
    if (isTranscoding) {
      setUpdatedCodecName(updatedCodecName);
      if (step === "create" && codecName.trim() !== "") {
        setUpdatedCodecName(codecName);
      }
    }
  }
  const handleCreateCodecClick = () => {
    setStep("create");
    setShowCreateCodecDialog(!showCreateCodecDialog);
  };
  const handleSelectCodecClick = () => {
    setStep("select");
    setSelectedCodec("");
    fetchCodecs();
  };
  const handleCodecCreation = (name) => {
    setCodecToSend(name);
  };

  const SensorPopup = () => {
    return (
      <Popup
        type="sensor"
        onClose={() =>
          setShowSensorPopup(false)
        }/>
    );
  };
  const ActuatorPopup = () => {
    return (
      <Popup
        type="actuator"
        onClose={() =>
          setShowActuatorPopup(false)
        }/>
    );
  };
  const Popup = ({type, onClose}) => {
    return (
      <div style={{color: 'black'}}>
        <p>
          Need to add a {type === 'sensor' ? 'sensor' : 'actuator'}? <br/>
          Contact the admin for assistance!
        </p>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '0px',
            right: '5px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CloseIcon/>
        </button>
      </div>
    );
  };

  const handleSave = async () => {
    let isError = false;

    if (updatedProductName.trim() === "") {
      setProductNameError(true);
      isError = true;
    }
    if (sensorCodesOfDevice.length === 0) {
      setSensorsError(true);
      isError = true;
    }
    if (isError) {
      return;
    }
    try {
      await updateProductType();
      props.onClose();
      window.location.reload();

    } catch (error) {
      console.error('Error updating product type:', error);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "750px"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.2",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "1",
            boxShadow: "0px 4px 30px #00000008",
          }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>Edit Product Type</DialogTitle>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={props.onClose}>
              <CloseIcon/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Product Name :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextBar
                  variant="outlined"
                  value={updatedProductName}
                  onChange={handleProductNameChange}
                  error={{error: false}}
                  style={classes.textBarStyle}
                />
                {productNameError && (
                  <span className="form-error" style={{marginBottom: "10px"}}>
                    Product Type is Required
                  </span>
                )}
              </Grid>


              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Sensors :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}
                    className={`cloud-container ${showSensorPopup ? classes.popup : ''}`}
                    style={{marginBottom: "10px"}}>
                {sensorsOfDevice.length === 0 && (
                  <label className="no-data-available"
                         style={{width: "100%"}}>
                    {showSensorPopup ? (
                      <SensorPopup/>
                    ) : (
                      <> </>
                    )}
                  </label>
                )}
                {sensorsOfDevice.map((sensor, index) => (
                  <div key={index} className="cloud-item">
                    <span className="sensor-number">{index + 1}</span>
                    <span className="cloud-item-text">{sensor}</span>
                    <div className="cloud-item-close">
                      <i onClick={() => removeSensorFromDevice(index)}
                         className="fa fa-times-circle"
                         style={{color: "#d94737"}}>
                      </i>
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={3} md={3}></Grid>
              <Grid item xs={9} md={9} style={{marginBottom: "22px"}}>
                {!showSensorPopup && (
                  <>
                    <FormControl className={classes.input}>
                      <select
                        value={selectedSensor}
                        onChange={handleSensorSelection}
                        className={classes.formInput}
                      >
                        <option value="" disabled hidden>Add Sensors</option>
                        <option value="AddSensor">
                          {/*<AddIcon />*/}
                          Add Sensor
                        </option>
                        {sensors.map((sensor, index) => (
                          <option key={index} value={sensor.name}>
                            {sensor.name}
                          </option>
                        ))}
                      </select>
                      {sensorsError && (
                        <span className="form-error" style={{marginBottom: "10px"}}>
                          Sensors are Required
                        </span>
                      )}
                    </FormControl>
                  </>
                )}
              </Grid>


              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Actuators :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}
                    className={`cloud-container ${showActuatorPopup ? classes.popup : ''}`}
                    style={{marginBottom: "10px"}}>
                {actuatorsOfDevice.length === 0 && (
                  <label className="no-data-available" style={{width: "100%"}}>
                    {showActuatorPopup ? (
                      <ActuatorPopup/>
                    ) : (
                      <>  </>
                    )}
                  </label>
                )}
                {actuatorsOfDevice.map((actuator, index) => (
                  <div key={index} className="cloud-item">
                    <span className="sensor-number">{index + 1}</span>
                    <span className="cloud-item-text">{actuator}</span>
                    <div className="cloud-item-close">
                      <i
                        onClick={() => removeActuatorFromDevice(index)}
                        className="fa fa-times-circle"
                        style={{color: "#d94737"}}
                      ></i>
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={3} md={3}></Grid>
              <Grid item xs={9} md={9} style={{marginBottom: "22px"}}>
                {!showActuatorPopup && (
                  <>
                    <FormControl className={classes.input}>
                      <select
                        value={selectedActuator}
                        onChange={handleActuatorSelection}
                        className={classes.formInput}
                      >
                        <option value="" disabled hidden>Add Actuators</option>
                        <option value="AddActuator">Add Actuator</option>
                        {actuators.map((actuator, index) => (
                          <option key={index} value={actuator.name}>
                            {actuator.name}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </>
                )}
              </Grid>


              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Connectivity :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <FormControl className={classes.input}>
                  <select
                    className={classes.formInput}
                    value={updatedConnectivity}
                    onChange={handleConnectivityChange}
                  >
                    <option value={connectivity}>{connectivity}</option>
                    {connectivity !== 'WIFI' && <option value="WIFI">Wifi</option>}
                    {connectivity !== 'GSM' && <option value="GSM">GSM</option>}
                  </select>
                </FormControl>
              </Grid>


              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Protocol :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <FormControl className={classes.input}>
                  <select
                    className={classes.formInput}
                    value={updatedProtocol}
                    onChange={handleProtocolChange}
                  >
                    <option value={protocol}>{protocol}</option>
                    {protocol !== 'MQTT' && <option value="MQTT">MQTT</option>}
                    {protocol !== 'HTTP' && <option value="HTTP">HTTP</option>}
                  </select>
                </FormControl>
              </Grid>


              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Persistence :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <FormControl>
                  <RadioGroup row
                              value={updatedPersistence.toString()}
                              onChange={(e) => {
                                handlePersistenceChange(e.target.value);
                              }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio/>}
                      label="True"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio/>}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>


              <Grid item xs={3} md={3} className={classes.gridStyle}>
                <Typography className={classes.labelStyle}>
                  Transcoding :
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <Grid item xs={8} md={9} style={{
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}>
                  <FormControl>
                    <ReactSwitch
                      checked={!isToggled}
                      onChange={handleChangeToggle}
                      offColor='#D9D9D9'
                      onColor='#2A7CED'
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={30}
                      width={60}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {!isToggled && (
                <Grid container>
                  <Grid container style={{marginBottom: "22px"}}>
                    <Grid item xs={6} md={6}
                          onClick={handleSelectCodecClick}
                          className={classes.formInput}
                          style={{
                            backgroundColor: step === "select" ? "#77acf3" : "#ECEDEF",
                            cursor: 'pointer',
                            border: 'none',
                          }}
                    >
                      <div> Select Codec Method</div>
                    </Grid>
                    <Grid item xs={6} md={6}
                          onClick={handleCreateCodecClick}
                          className={classes.formInput}
                          style={{
                            backgroundColor: step === "create" ? "#77acf3" : "#ECEDEF",
                            cursor: 'pointer',
                            border: 'none',
                          }}
                    >
                      <div> Create Codec</div>
                    </Grid>
                  </Grid>

                  {/* create codec form elements */}
                  {step === 'create' && (
                    <AddNewDecoder
                      onClose={handleSelectCodecClick}
                      codecs={codecs}
                      onCodecToPlaceholder={handleCodecCreation}
                    />
                  )}

                  {/* select codec form elements */}
                  {step !== 'create' && (
                    <Grid container style={{marginBottom: "22px"}}>
                      <FormControl className={classes.input}>
                        <Grid container style={{height: '40px'}}>
                          <Grid container className={classes.formInput}
                                style={{cursor: 'pointer'}}
                                alignContent='center'
                                onClick={() => setIsCodecDropDown(true)}
                          >
                            <Grid item style={{flex: 1}}>
                              <input
                                placeholder={codecToSend === '' ? "Select Codec" : codecToSend}
                                className={classes.optionInput}
                                defaultValue={selectedCodec}
                                readOnly
                              />
                            </Grid>
                            <Grid item> {isCodecDropDown ? <UpArrow/> : <DownArrow/>} </Grid>
                          </Grid>
                          {isCodecDropDown && (
                            <div style={{width: '100%', zIndex: 1}}>
                              <div className={classes.dropdown}>
                                {codecs.map((codec, index) => {
                                  return (
                                    <Grid item xs={12} md={12}
                                          className={classes.option}
                                          key={index}
                                          onClick={() => {
                                            handleCodecChange(codec.codecName)
                                          }}
                                    >
                                      <span style={{marginLeft: '10px'}}> {codec.codecName} </span>
                                    </Grid>
                                  )
                                })
                                }
                              </div>
                              <div style={{marginTop: '20px'}}/>
                            </div>
                          )}
                        </Grid>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: 'white',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  backgroundColor: primaryBlue,
                }}
                onClick={handleSave}
              >
                Update
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
    </div>
  );
};


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  input: {
    width: "100%",
    textAlign: "left",
  },
  textBarStyle: {
    height: "38px",
    borderRadius: "5px",
    border: "1px solid black",
  },
  popup: {
    backgroundColor: "#CEEBCE",
  },
  formInput: {
    height: "38px",
    borderRadius: "5px",
    padding: "10px 15px",
    fontFamily: "'Poppins', sans-serif",
    border: '0.5px solid',
    backgroundColor: white,
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  gridStyle: {
    textAlign: "left",
    marginBottom: "25px",
    paddingRight: "2%",
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "25px",
  },
  optionInput: {
    cursor: 'pointer',
    border: 'none',
    fontFamily: "'Poppins', sans-serif"
  },
  dropdown: {
    textAlign: 'left',
    border: '0.5px solid #324054',
    margin: 'px',
    borderRadius: '5px',
    backgroundColor: white,
    width: '100%',
    zIndex: 1,
    maxHeight: '160px',
    overflowY: 'auto',
  },
  option: {
    paddingLeft: '10px',
    height: '30px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: "13px"
  },
}));
