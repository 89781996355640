import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import {primaryBlue, primaryGray, white} from "src/components/colors";
import {CreateKitModels} from "./steps/CreateKitModels"
import {MappedKitModel} from "./steps/MappedKitModel";
// import { Direct } from "protractor/built/driverProviders";
import {DirectAPIComponent} from './DirectAPI'
import {ToasterComponent} from "../../maintenance/test-automation/components/Toaster";
import {DropDown} from "./components/DropDown";
import {Loader} from "./components/Loader";
import useStyles from "src/app/maintenance/assets/styles";


export function KitModelAPIComponent(props) {
  const [steps, setSteps] = useState([
    {
      text: "Define Kit Model",
      activate: true,
    },
    {
      text: "Mapped Device",
      activate: false,
    },
    {
      text: "Swagger",
      activate: false,
    },
  ]);
  const [step, setStep] = useState(-1)
  const classes = useStyles();
  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [lastCreatedKitModel, setLastCreatedKitModel] = useState("")
  const [lastKitId, setLastKitId] = useState("")
  const [kitModelFormType, setKitModelFormType] = useState("")
  const [isSelectStep3, setIsSelectStep3] = useState(false)
  const [isExistKitModel, setIsExistKitModel] = useState(false)
  const [selectKitModel, setSelectKitModel] = useState("")
  const [isSelectKitModel, setIsSelectKitModel] = useState(false)

  const [modelError, setModelError] = useState({error: false, errorMessage: ''})

  const increaseStep = () => {
    setStep(step + 1);
    const updatedSteps = steps.map((s, i) => ({
      ...s,
      activate: i === step + 1,
    }));
    setSteps(updatedSteps);
  };

  const fetchSetupStatus = async () => {
    if (props.step3Completed == 'true') {
      setIsSelectStep3(props.step3Completed)
    } else {
      increaseStep()
    }
  };

  const selectModelType = () => {
    setIsSelectKitModel(true);
    setStep(0)
    setSteps([{
      text: "Create Kit Model",
      activate: true,
    },
      {
        text: "Mapped Device",
        activate: false,
      },
      {
        text: "Swagger",
        activate: false,
      }])
    setIsExistKitModel(false)
    setSelectKitModel("")
    setIsSelectStep3(false)
  }

  const selectCreateKit = () => {
    setIsExistKitModel(true)
    setStep(0)
    setSteps([{
      text: "Kit model Selection",
      activate: true,
    },
      {
        text: "Mapped Device",
        activate: false,
      },
      {
        text: "Swagger",
        activate: false,
      }])
  }

  const decreaseStep = () => {
    if(step === 0){
      setSteps([
        {
          text: "Define Kit Model",
          activate: true,
        },
        {
          text: "Mapped Device",
          activate: false,
        },
        {
          text: "Swagger",
          activate: false,
        },
      ])
    } else {
      setStep(step - 1);
      const updatedSteps = steps.map((s, i) => ({
        ...s,
        activate: i === step - 1,
      }));
      setSteps(updatedSteps);
    }

  };

  const handleCancelButton = () => {
    props.setIsApiClick(false)
    setSelectKitModel("")

  }

  const toggleNext = () => {
    if (selectKitModel == "") {
      setModelError({error: true, errorMessage: "Selecting a Kit Model is Required"})
    } else {
      setStep(1)
      setSteps([{
        text: "Define Kit Model",
        activate: false,
      },
        {
          text: "Mapped Device",
          activate: true,
        },
        {
          text: "Swagger",
          activate: false,
        }])
      setIsExistKitModel(false)
      setIsSelectStep3(false)
    }
  }


  useEffect(() => {
    localStorage.setItem('step3Completed', `${props.step3Completed}`)
    fetchSetupStatus()
  }, []);


  return (
    <div>
      <Grid container direction="column" alignItems="center"
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}>
        {/* <Grid item xs={1} md={4} ></Grid> */}
        <Typography
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: '16px',
            color: primaryGray,
          }}>Map Device To Applications</Typography>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: "30px" }}>
        <Grid item xs={1} md={3}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Circle number={index + 1} active={step.activate}completed={steps.findIndex(s => s.activate) > index}/>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "13px",
                      paddingTop: "15px",
                      fontFamily: "Poppins, sans-serif",
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}<span style={{color: step.activate ? '#FF4E4E' : primaryGray}}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                    borderColor: steps[index + 1].activate ? primaryBlue : 'rgba(112, 112, 112, .12)', 
                  }}
                  color="rgba(112, 112, 112, .12)"

                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <Grid container justifyContent="center">
        {isSelectStep3 ?
          <Grid item style={{marginTop: '20px'}}>
            <Grid container justifyContent="center">
              <Grid item onClick={() => selectModelType()}>
                <Grid container className={classes.selectModel}>
                  <Grid item>
                    <input type="radio" className={classes.radio} checked={steps[0].activate && !isSelectStep3}
                           onChange={() => selectModelType()}/>
                  </Grid>
                  <Grid item style={{cursor: 'pointer'}}>
                    <Typography>Create New Kit Model</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{marginLeft: '30px'}} onClick={() => selectCreateKit()}>
                <Grid container className={classes.selectModel}>
                  <Grid item>
                    <input type="radio" className={classes.radio} checked={isExistKitModel}
                           onChange={() => selectCreateKit()}/>
                  </Grid>
                  <Grid item>
                    <Typography>Select Existing Kit Model</Typography>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
            {isExistKitModel ?
              <Grid container justifyContent="center" style={{marginTop: '40px'}}>
                <DropDown
                  options={props.allKitModels.map(model => model.name)}
                  type='kitModel'
                  emptyTag='Select Kit Model'
                  isAddRequired={false}
                  setSelectKitModel={setSelectKitModel}
                  selectKitModel={selectKitModel}
                  isSingle={true}
                  value={selectKitModel}
                  setModelError={setModelError}
                  kitModelFormType={props.kitModelFormType}
                />
                {modelError.error ?
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={4} md={9}>
                      <Grid container justifyContent='flex-end'>
                        <Typography className={classes.errorText}>{modelError.errorMessage}</Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                  :
                  <></>
                }
              </Grid>

              :
              <></>
            }
            {isSelectStep3 ?
              <Grid container style={{marginTop: isExistKitModel ? '100px' : '50px'}} justifyContent="flex-end">
                <Grid item xs={6} md={6}></Grid>
                <Grid container justifyContent="flex-end">
                  <Grid item >
                    <Button
                    className={classes.backButton}
                    style={{
                    marginRight: isExistKitModel ? '10px': '0px'
                  }}onClick={handleCancelButton}>
                    Back
                    </Button>  
                  </Grid>
                  {isExistKitModel ?
                    <Grid item>
                    <Button 
                      className={classes.saveButton} 
                      onClick={toggleNext}>
                      Next
                    </Button>
                    </Grid>
                    :
                    <></>

                  }
                </Grid>
              </Grid>
              : <></>
            }
          </Grid>
          :
          <></>
        }
        <Grid container>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            {steps[0].activate && !isSelectStep3 ?
              <CreateKitModels
                increaseStep={increaseStep}
                decreaseStep={() => decreaseStep()}
                allSensors={props.allSensors}
                allActuators={props.allActuators}
                allTypes={props.allTypes}
                allKitModelsName={props.allKitModels}
                setIsToasterOpen={setIsToasterOpen}
                setToasterMessage={setToasterMessage}
                setReqSuccess={setReqSuccess}
                getKitModels={props.getKitModels}
                setLastCreatedKitModel={setLastCreatedKitModel}
                setIsApiClick={props.setIsApiClick}
                setLoading={props.setLoading}
                kitModelFormType={kitModelFormType}
                setKitModelFormType={setKitModelFormType}
                setIsSelectStep3={setIsSelectStep3}
                isselectStep3={isSelectStep3}
                getKitType={() => props.getKitType()}
                isSelectKitModel={isSelectKitModel}
                setIsSelectKitModel={setIsSelectKitModel}
                isSelectStep3={isSelectStep3}
                loading={props.loading}
              />
              :
              steps[1].activate && !isSelectStep3 ?
                <MappedKitModel
                  increaseStep={increaseStep}
                  decreaseStep={() => decreaseStep()}
                  setIsToasterOpen={setIsToasterOpen}
                  setToasterMessage={setToasterMessage}
                  setReqSuccess={setReqSuccess}
                  allKitModels={props.allKitModels}
                  allDevices={props.allDevices}
                  allKits={props.allKits}
                  allSensors={props.allSensors}
                  getKitModels={props.getKitModels}
                  allKitModelsName={props.allKitModels}
                  allTypes={props.allTypes}
                  getKits={props.getKits}
                  lastCreatedKitModel={selectKitModel == "" ? lastCreatedKitModel : selectKitModel}
                  setLastKitId={setLastKitId}
                  setLoading={props.setLoading}
                  allActuators={props.allActuators}
                  setKitModelFormType={setKitModelFormType}
                  kitModelFormType={kitModelFormType}
                  setIsSelectStep3={setIsSelectStep3}
                  isSelectStep3={isSelectStep3}
                  setSteps={setSteps}
                  setIsExistKitModel={setIsExistKitModel}
                  isExistKitModel={isExistKitModel}
                  isSelectKitModel={isSelectKitModel}
                  setIsSelectKitModel={setIsSelectKitModel}
                  loading={props.loading}
                  setIsApiClick={props.setIsApiClick}
                />
                : steps[2].activate && !isSelectStep3 ?
                  <DirectAPIComponent increaseStep={increaseStep} decreaseStep={decreaseStep} step='step3'
                                      lastKitId={lastKitId}/>
                  : <></>


            }
          </Grid>
          <Grid item xs={1} md={3}></Grid>
          <ToasterComponent toaster={isToasterOpen} message={toasterMessage} reqSuccess={reqSuccess}/>
        </Grid>
      </Grid>
    </div>
  );
}

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: props.completed ? primaryBlue : white,
          border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};

