import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../../account/account.service';
import {ToastrService} from 'ngx-toastr';
import {Device, FORM_TYPE, FormKitRadioFields, Kit, KitModel, MetaData} from '../../models';
import {AuthService} from '../../authentication/auth.service';
import * as _ from 'underscore';
import {environment} from '../../../environments/environment';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-kit-form',
  templateUrl: './kit-form.component.html',
  styleUrls: ['./kit-form.component.css']
})
export class KitFormComponent implements OnInit {
  @Input() kit: Kit;
  @Input() formType;
  @Input() isCloning;
  @Output() getKitTrigger = new EventEmitter();
  @Output() hideFormTrigger = new EventEmitter();

  isSubmitted: boolean;
  error;
  formKit: FormGroup;
  formKitRadioFields: FormKitRadioFields = {maintain: false, persistence: true};
  devicesOfKit: Device[] = [];
  devices: Device [];
  devicesAll: Device [];
  kitModels: KitModel[];
  allKitModels: KitModel[];
  showKitModelForm = false;
  selectedKitModel;
  propertiesOfKitModel: string [];
  sensors = [];
  formTypeVar = FORM_TYPE.EDIT;
  isCloningVar = false;
  metaData: MetaData;
  searchTerm = '';
  kitModelLocalSelected;
  selectedPropIndex: any;
  propertyObj = {};
  objectOfProperty: any;
  isAdvanceOption = false
  propertyCalMethod = ['ADD', 'SUB', 'MUL', 'DIV'];
  selectedDevice;
  showDeviceForm = false;
  selectedDeviceSensor;
  selectedDeviceActuator;
  actuators = [];
  batches;
  originalFormValue;
  dropdownKitSettings: IDropdownSettings;
  selectedDevices: any[] = [];
  selectedKitModels: any[] = [];
  dropdownSettings: IDropdownSettings;
  singleSelectSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    enableCheckAll: false,
    showCheckbox: false,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  kitModelLookup: any[];
  deviceLookup: any[];
  isView: boolean = false;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private accountService: AccountService, private toaster: ToastrService) {
    this.kitModelLocalSelected = [];
  }

  ngOnInit() {
    this.formKit = this.formBuilder.group({
      id: [null, [Validators.required, Validators.pattern('^[^.$\\[\\]#]*$')]],
      name: [null, Validators.required],
      kitModel: [null, Validators.required],
      soilType: [null],
      alertLevel: [null, [Validators.required, Validators.pattern('^[1-9]{1}[0-9]*$')]],
      interval: [null, [Validators.pattern('^[1-9]{1}[0-9]*$')]],
      persistence: [null],
      maintain: [null],
      shiftMapArray: this.formBuilder.array([]),
      devices: [null],
      inputMethod: [null]
    });

    this.isSubmitted = false;
    if (this.kit && this.kit.metaData && this.kit.metaData.propertyNameMap) {
      this.objectOfProperty = JSON.parse(this.kit.metaData.propertyNameMap);
    } else {
      this.objectOfProperty = {};
    }
    this.originalFormValue = JSON.parse(JSON.stringify(this.formKit.value));
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
    this.dropdownKitSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      allowSearchFilter: true
    };
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.getDevices().then(() => {
      return Promise.all([
        this.getKitModels(),
        this.getMetaData(),
        this.getBatches()
      ]);
    }).then(() => {
      this.getFormReady();
      this.setInitialSelectedDevices();
      this.initializeSelectedValues();
      this.deviceLookup = this.getDeviceTypes();
    }).catch(error => {
      console.error('Error loading initial data', error);
    }).finally(() => {
      this.spinner.hide();
    });
  }

  onKitItemSelect(item: any) {
    this.kitModelLocalSelected = item.item_id;
    this.selectedKitModels = [{item_id: item.item_id, item_text: item.item_text}];
    this.formKit.patchValue({
      kitModel: this.selectedKitModels
    });
    this.getSelectedKitModelProperty();
  }

  onKitItemDeSelect(item: any) {
    this.kitModelLocalSelected = null;
    this.selectedKitModels = [];
    this.propertiesOfKitModel = [];
    this.formKit.patchValue({
      kitModel: null
    });
  }

  onItemSelect(item: any) {
    const selectedValue = item.item_id;
    this.addDeviceToKit(selectedValue);
    this.selectedDevices.push({item_id: selectedValue, item_text: item.item_text});
  }

  onItemDeSelect(item: any) {
    const selectedValue = item.item_id;
    this.removeDeviceFromKit(selectedValue);

  }

  getKitModel(): any[] {
    if (!this.kitModels) {
      return [];
    }
    const kitModelLookup = [];
    this.kitModels.forEach(kitModel => {
      kitModelLookup.push({item_id: kitModel.id, item_text: kitModel.name});
    });
    return kitModelLookup;
  }

  getDeviceTypes(): string[] {
    if (!this.devices) {
      return [];
    }
    const deviceLookup = [];
    this.devices.forEach(device => {
      deviceLookup.push({item_id: device.id, item_text: device.name});
    });
    return deviceLookup;
  }

  getBatches() {
    this.accountService.getBatches().subscribe((data) => {
      this.batches = data.content;
    }, (error) => {
      this.spinner.hide();
    });
  }


  getSelectedKitModelProperty() {

    let selectedKitModel: KitModel;

    if (this.allKitModels) {
      selectedKitModel = this.allKitModels.find(model => model.id === this.kitModelLocalSelected);
      console.log(selectedKitModel);
    }

    this.propertiesOfKitModel = [];
    if (this.kit && this.kit.model && this.kit.model.properties) {
      this.propertiesOfKitModel = this.kit.model.properties.map(val => {
        const sensor = this.sensors.filter(s => s.code === val, 1)[0];
        return sensor ? sensor.name : null;
      }).filter(name => name !== null);
    } else if (selectedKitModel && selectedKitModel.properties) {
      this.propertiesOfKitModel = selectedKitModel.properties.map(value => {
        const sensor = this.sensors.filter(s => s.code === value, 1)[0];
        return sensor ? sensor.name : null;
      }).filter(name => name !== null);
      console.log(this.propertiesOfKitModel);
    } else {
      console.log("No properties found");
    }

    this.updateShiftMapArray();
  }

  updateShiftMapArray() {
    const shiftMapArray = this.formKit.get('shiftMapArray') as FormArray;
    shiftMapArray.clear();

    this.propertiesOfKitModel.forEach((property, index) => {
      shiftMapArray.push(this.formBuilder.group({
        propertyNumber: [index, Validators.required],
        operation: [null],
        type: [null],
      }));
    });
  }

  getPropertyMappingName(property: any, index: number): string {
    if (!this.kit || !this.kit.metaData || !this.kit.metaData.propertyNameMap) {
      return '';
    }

    try {
      const propertyNameMap = JSON.parse(this.kit.metaData.propertyNameMap);
      return propertyNameMap[index] || '';
    } catch (e) {
      console.error('Error parsing propertyNameMap:', e);
      return '';
    }
  }

  enableEditPropertyName(index) {
    this.selectedPropIndex = index;
  }

  editPropertyName(index, value) {
    this.selectedPropIndex = null;
    if (this.kit.metaData && this.kit.metaData.propertyNameMap) {
      this.objectOfProperty[index] = value.trim();
    } else {
      this.propertyObj[index] = value.trim();

    }
  }


  getMetaData() {
    this.accountService.getMetaData().subscribe((data) => {
      this.metaData = data.content;
      console.log('metaData ###' + data);

      this.sensors = this.metaData.sensors.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      this.actuators = data.content.actuators.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });

    }, () => {
      this.spinner.hide();
    }, () => {
      this.getSelectedKitModelProperty();
      this.spinner.hide();
    });
  }

  editKitModel(kitModelLocal) {
    const selectedKitModelDetails = (this.getKitModelsFiltered(kitModelLocal))[0];

    this.formTypeVar = FORM_TYPE.EDIT;
    this.selectedKitModel = selectedKitModelDetails;
    this.isSubmitted = false;

    console.log('kit model', kitModelLocal);
    console.log('values', this.selectedKitModel);

    console.log('kit model filtered', (this.getKitModelsFiltered(kitModelLocal)));

    this.showKitModelForm = true;
  }

  viewKitModel(kitModelLocal) {
    if (!kitModelLocal) {
      console.error('No kit model selected');
      return;
    }

    const selectedKitModelDetails = this.kitModels.find(km =>
      km.id.toString() === kitModelLocal.toString()
    );

    if (selectedKitModelDetails) {
      this.isView = true;
      this.selectedKitModel = selectedKitModelDetails;
      this.isSubmitted = false;
      this.showKitModelForm = true;
    }

    this.showKitModelForm = true;
  }

  editDevice(device) {
    this.showDeviceForm = true

    this.formTypeVar = FORM_TYPE.EDIT;
    this.selectedDevice = device
    console.log('selected device', device);
    this.selectedDeviceSensor = device.sensorCodes ? device.sensorCodes.map(s => this.getSensorByCode(s)) : [];
    this.selectedDeviceActuator = device.actuatorCodes ? device.actuatorCodes.map(a => this.getActuatorByCode(a)) : [];
    this.isSubmitted = false;

  }

  getKitModelsFiltered(searchTerm: string): KitModel[] {
    console.log('getting filtered');
    if (!searchTerm || typeof searchTerm !== 'string') {
      return this.kitModels; // Return the original array if searchTerm is not a string or is undefined/null
    }
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();
    return this.kitModels.filter(km => {
      const kitModelValues = Object.values(km).map(value => (typeof value === 'string') ? value.toLowerCase() : value);
      return JSON.stringify(kitModelValues).toLowerCase().includes(trimmedSearchTerm) &&
        km.id.toString().length === trimmedSearchTerm.length;
    });
  }

  getSensorByCode(sensor) {
    return this.sensors.filter(s => {
      return s.code === sensor;
    }, 1)[0];
  }

  getActuatorByCode(actuator) {
    return this.actuators.filter(a => {
      return a.code === actuator;
    }, 1)[0];
  }

  updateKit(kit) {
    kit.persistence = this.formKitRadioFields.persistence;
    kit.maintain = this.formKitRadioFields.maintain;

    Object.keys(this.formKit.controls).forEach((key) => {
        if (typeof kit[key] === 'string') {
          kit[key] = kit[key].trim();
        }
      }
    );

    this.isSubmitted = true;
    if (!AuthService.hasNewUserAccess && !this.isSuperUser) {
      this.error = 'You Don\'t Have Permission ';
      return;
    }
    if (!this.formKit.valid) {
      return;
    }
    if (!this.devicesOfKit.length) {
      return;
    }
    this.error = null;


    kit.metaData = {};

    if (this.kit && this.kit.metaData && this.kit.metaData.propertyNameMap) {
      kit.metaData.propertyNameMap = JSON.stringify(this.objectOfProperty);

    } else {
      kit.metaData.propertyNameMap = JSON.stringify(this.propertyObj);
    }
    kit.metaData.soilType = kit.soilType;

    const isUserLogEnabled = environment.enableUserLog;
    if (this.formType === FORM_TYPE.ADD || this.isCloning) {
      kit.devices = this.devicesOfKit.map(device => {
        return device.id;
      });
      this.spinner.show();
      this.propertiesOfKitModel = [];
        this.accountService.createKitWithCreatedBy(kit).subscribe(response => {
          this.spinner.hide();
          this.hideFormTrigger.emit(true);
          this.getKitTrigger.emit(true);
          this.toaster.success('Kit Created Successfully', 'Success');
        }, error => {
          this.spinner.hide();
          if (error.status === 422) {
            this.error = error.error.message;
          } else {
            this.toaster.error('Oops... Something went wrong', 'Error!');
          }
        }, () => {
          this.spinner.hide();
        });
    } else {
      kit.devices = this.devicesOfKit.map(device => {
        if (!device) {
          this.error = 'Choose valid device';
          return;
        }
        return device.id;
      });

      if (this.error) {
        return;
      }
      kit.id = this.kit.id;
      kit.kitModelId = kit.kitModel[0].item_id;

      const shiftMap = {};
      kit.shiftMapArray.forEach((p) => {
        if (p.operation) {
          shiftMap[p.propertyNumber] = {};
          shiftMap[p.propertyNumber].meta = {pivot: p.type};
          shiftMap[p.propertyNumber].operation = p.operation;
        }
      });

      kit.shiftMap = shiftMap;
      this.spinner.show();
        this.accountService.updateKitWithModifiedBy(this.kit.id, kit).subscribe(response => {
          this.spinner.hide();
          this.hideFormTrigger.emit(true);
          this.getKitTrigger.emit(true);
          this.toaster.success('Kit Updated Successfully', 'Success');
        }, error => {
          this.spinner.hide();
          if (error.status === 422) {
            this.error = error.error.message;
          } else {
            this.toaster.error('Oops... Something went wrong', 'Error!');
          }
        }, () => {
          this.spinner.hide();
        });
    }
    this.isSubmitted = false;
  }

  getDevices(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getDevices().subscribe(
        response => {
          this.devices = response.content;
          this.devicesAll = [...this.devices];
          this.formKit.controls.devices.setValue(this.devices);
          console.log(this.devices);
          resolve();
        },
        error => {
          console.error('Error fetching devices', error);
          reject(error);
        });
    });
  }

  setInitialSelectedDevices() {
    this.selectedDevices = this.devicesOfKit.map(device => ({item_id: device.id, item_text: device.name}));
  }

  getKitModels() {
    this.spinner.show();
    this.accountService.getKitModels().subscribe(response => {
      console.log("kit model", response.content);
      this.allKitModels = response.content;
      this.kitModels = response.content;
      console.log('kit models from kitModel', response.content);
      this.initializeSelectedValues();
      this.kitModelLookup = this.getKitModel();
    }, error => {
      this.spinner.hide();
    });
  }

  initializeSelectedValues() {
    if (this.formType === FORM_TYPE.EDIT && this.devices && this.kitModels) {
      this.selectedDevices = this.devicesOfKit.map(device => ({
        item_id: device.id,
        item_text: device.name
      }));
      if (this.kit.model) {
        this.selectedKitModels = [{
          item_id: this.kit.model.id,
          item_text: this.kit.model.name
        }];
        this.kitModelLocalSelected = this.kit.model.id;
      }
    } else {
      this.selectedDevices = [];
      this.selectedKitModels = [];
    }
  }

  addDeviceToKit(deviceId: string) {
    const selectedDevice = this.devices.find(device => device.id === deviceId);

    if (selectedDevice) {
      this.devicesOfKit.push(selectedDevice);
      // this.selectedDevices.push(selectedDevice); // Track selected devices
    }
  }

  removeDeviceFromKit(deviceId: string) {
    const indexInKit = this.devicesOfKit.findIndex(device => device.id === deviceId);

    if (indexInKit !== -1) {
      const removedDevice = this.devicesOfKit.splice(indexInKit, 1)[0];
      this.devices.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
    }
  }

  hideForm() {
    this.kitModelLocalSelected = null;
    this.hideFormTrigger.emit(true);
    this.error = null;
  }


  hideFormInner(event) {
    console.log('here event', event);
    this.showKitModelForm = false;
    this.selectedKitModel = null;
  }

  hideDeviceForm(event) {
    this.showDeviceForm = false;
    this.selectedDevice = null;
  }

  getFormReady() {
    if (this.formType === FORM_TYPE.ADD) {
      this.formKit.reset();
      this.formKitRadioFields.persistence = true;
      this.formKitRadioFields.maintain = false;
      this.devicesOfKit = [];
    } else if (this.formType === FORM_TYPE.EDIT) {
      this.formKit.reset();
      this.formKit.patchValue(this.kit);
      this.formKitRadioFields.persistence = this.kit.persistence;
      this.formKitRadioFields.maintain = this.kit.maintain;
      this.devicesOfKit = this.kit.devices.map(device => {
        return _.findWhere(this.devices, {id: device});
      });
      this.devicesOfKit = this.devicesOfKit.filter(device => device !== undefined);

      if (this.kit.model) {
        this.selectedKitModels = [{
          item_id: this.kit.model.id,
          item_text: this.kit.model.name
        }];
        this.kitModelLocalSelected = this.kit.model.id;
        console.log('selected kit model details', this.selectedKitModels);
        console.log('kit model selected', this.kitModelLocalSelected);
      }

      if (!this.isCloning) {
        this.formKit.controls['id'].disable();
      }
      this.error = null;

      this.kit.model.properties.forEach((p, index) => {
        this.addOperationsFormGroup(index);
      });

      this.selectedDevices = this.devicesOfKit.map(device => ({item_id: device.id, item_text: device.name}));
    }
  }

  addOperationsFormGroup(index) {
    const shiftMapArray = this.formKit.get('shiftMapArray') as FormArray;
    shiftMapArray.push(this.formBuilder.group({
      propertyNumber: [index, Validators.required],
      operation: [this.kit.shiftMap[index] ? this.kit.shiftMap[index].operation : null],
      type: [this.kit.shiftMap[index] ? this.kit.shiftMap[index].meta.pivot : null],
    }));
  }

  getPropertyCalMethod() {
    return this.propertyCalMethod;
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  getWindowWidth() {
    return window.innerWidth;
  }

  changesMade(): boolean {
    // Compare the current form value with the original form value
    return this.formKit.dirty;
  }
}
