import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import useStyles from '../../assets/styles'; 


function createData(id, values) {
  return { id, values };
}

export const DeviceConfig = ({
  filteredSubTestCases, 
  selectedSubTestCasesTitles, 
  devices
}) => {

  const classes = useStyles();

  // Map filteredSubTestCases to get minVal and maxVal
  const subTestCaseMap = filteredSubTestCases.reduce((acc, testCase) => {
    acc[testCase.subTestTitle] = {
      minVal: testCase.minVal,
      maxVal: testCase.maxVal
    };
    return acc;
  }, {});

  // Create rows with formatted minVal and maxVal
  const rows = devices.map(device => {
    const values = selectedSubTestCasesTitles.map(title => {
      const testCase = subTestCaseMap[title];
      return testCase ? `${testCase.minVal}-${testCase.maxVal}` : '';
    });
    return createData(device, values);
  });

  return (
    <div style={{ margin: "0px" }}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.deviceId}>
                  Device Id
                </Typography>
              </TableCell>
              {selectedSubTestCasesTitles.map((subTestCase, i) => (
                <TableCell align="center" key={i}>
                  <Typography className={classes.topicText}>
                    {subTestCase}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                style={{
                  backgroundColor: i % 2 === 0 ? "white" : "#ECEDEF" ,
                }}
              >
                <TableCell align="left">
                  <Typography className={classes.contentText}>
                    {row.id}
                  </Typography>
                </TableCell>
                {row.values.map((item, index) => (
                  <TableCell key={index} style={{textAlign:"center"}}>
                    <Typography className={classes.contentText}>
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DeviceConfig;
