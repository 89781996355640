import { Box, Button, createTheme, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, ThemeProvider, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import useStyles from '../../assets/styles'
import { DropDown } from '../components/DropDown'
import { FormField } from './FormFiled'
import { useState } from 'react'
import { apiConfiguration } from '../components/apiHelper'
import { AddIcon, AddIconLarge, AddIconTwo, ApiUploadIcon, ExpandCollapseIcon, ExpandIcon, TrashIcon, UploadIcon } from 'src/components/Icons'
import DataTable from 'src/app/auto-ml/layouts/data-table'
import { SearchBar } from 'src/components/sub-components/SearchBar'
import { filter } from 'rxjs/operators'
import { ApiTable } from './Component/ApiTable'

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

export const ApiConfigurationComponent = (props) => {
    const classes = useStyles()
    const [jsonName, setJsonName] = useState("")
    const [expandedRow, setExpandedRow] = useState(null)
    const [tableData, setTableData] = useState([])
    const [jsonFilesData,setJsonFilesData] = useState([])

    useEffect(() => {
        setTableData(props.jsonData)
    }, [props.jsonData])

    useEffect(() =>{
        setJsonFilesData(props.jsonFiles)
        console.log(props.jsonFiles)
    },[props.jsonFiles])



    const handleExpand = (index) => {
        setExpandedRow(prevIndex => (prevIndex === index ? null : index));
    };

    const searchFile = (event) =>{
        const value = event.target.value.toLowerCase()
        const filterFiles = props.jsonFiles.filter(file => file.name.toLowerCase().includes(value))
        console.log(filterFiles)
        setJsonFilesData(filterFiles)
    }

    const searchPath = (index, event) => {
        const value = event.target.value.toLowerCase();
        const filteredTableData = props.jsonData[index].filter(endpoint =>
            endpoint.apiName.toLowerCase().includes(value)
        );
        setTableData(prev => {
            const newData = [...prev]; // Create a shallow copy
            newData[index] = filteredTableData; // Replace only the filtered index
            return newData;
        });
    }

    return (
        <Grid container className={classes.apiUploadContainer}>
            <Grid item xs={12} md={12}>
                <Box sx={{ width: '100%' }}>
                    {props.describe && <Grid container>
                        <Box className={classes.uploadTextContainer}>
                            <ApiUploadIcon />
                            <p className={classes.uploadText}>Upload a JSON file with API specifications, we accept open API specification. For each API include clear and concise description for query parameters and response.</p>
                        </Box>
                    </Grid>}

                    <Grid container style={{ marginTop: '15px' }}>
                        <Typography>Json File</Typography>
                        <Grid container style={{ width: '100%' }}>
                            <Grid item style={{ flexGrow: 1, marginRight: '10px' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={props.selectedJsonFile ? props.selectedJsonFile.name : ""}
                                    InputProps={{
                                        style: { height: "35px" },
                                        inputProps: { readOnly: true },
                                    }}
                                    placeholder={props.isAddJson ? 'Upload another json file here' : 'Upload json file here'}
                                />
                            </Grid>

                            <Grid item
                                className={classes.apiConfigUploadButton}
                            >
                                {props.selectedJsonFile && props.selectedJsonFile.name && !props.isAddJson ?
                                    <Button
                                        className={classes.devicePulseSaveButton}
                                        variant='contained'
                                        style={{ width: '100px' }}
                                        onClick={() => {
                                            props.handleAddJson()
                                            setJsonName("")
                                        }}
                                    >
                                        Add
                                    </Button>
                                    :
                                    <><input
                                        type="file"
                                        accept=".json"
                                        style={{ display: "none" }}
                                        id="json-upload"
                                        onChange={(event) => {
                                            props.handleJsonUpload(event);
                                        }}
                                    />
                                        <label htmlFor="json-upload">
                                            <Button
                                                variant={props.isAddJson ? "outlined" : "contained"}
                                                startIcon={props.isAddJson ? <AddIconTwo color='#005B96' /> : <UploadIcon color="white" />}
                                                component="span"
                                                style={{
                                                    backgroundColor: props.isAddJson ? "transparent" : "#005B96",
                                                    color: props.isAddJson ? "#005B96" : "white", // Ensure text is visible
                                                    width: props.isAddJson ? "230px" : "150px",
                                                    border: props.isAddJson ? "1px solid #005B96" : "none"
                                                }}
                                            >
                                                {props.isAddJson ? 'Add Another json' : 'Upload'}
                                            </Button>

                                        </label>
                                    </>
                                }

                            </Grid>
                            {props.isAddToken && <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                <Button
                                    variant='contained'
                                    startIcon={<AddIconTwo color='#FFFFFF' />}
                                    onClick={props.handleAddToken}
                                    className={classes.devicePulseSaveButton}
                                >
                                    Add Token
                                </Button>
                            </Grid>}
                            
                            <Grid container>
                                {props.jsonFiles && props.jsonFiles.length > 0 && 
                                    <Grid item xs={5} md={5} style={{ margin: "5px 0px" }}>
                                        <SearchBar onChange={(e) => searchFile(e)} />
                                    </Grid>
                                }
                                {jsonFilesData.length > 0 && 
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell style={{ backgroundColor: 'white' }}>Source</TableCell>
                                                <TableCell style={{ backgroundColor: 'white' }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {jsonFilesData.length > 0 && jsonFilesData.map((json, index) => (
                                            <React.Fragment key={index}>
                                                <TableBody>
                                                    <TableRow style={{ backgroundColor: index % 2 == 1 ? '#FFFFFF' : '#ECEFF1', width: '100%' }}>
                                                        <TableCell style={{ width: '90%' }} onClick={() => handleExpand(index)}>{json.name}</TableCell>
                                                        <TableCell style={{ textAlign: "right", width: "10%" }}>
                                                            <Grid container justifyContent="flex-end">
                                                                <IconButton size='small' onClick={() =>props.handleDeletFile(index)}>{<TrashIcon />}</IconButton>
                                                                <IconButton size='small' onClick={() => handleExpand(index)}>
                                                                    {expandedRow == index ? <ExpandCollapseIcon /> : <ExpandIcon />}
                                                                </IconButton>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                    {expandedRow === index && (
                                                        <TableRow>
                                                            <TableCell colSpan={2} style={{ padding: 0 }}>
                                                                <Grid item xs={12} md={12} lg={12} style={{ width: "100%" }}>
                                                                    <Grid container style={{ padding: "10px 15px", width: "100%" }}>
                                                                        <Grid item xs={5} md={5} style={{ margin: "5px 0px" }}>
                                                                            <SearchBar onChange={(e) => searchPath(index, e)} />
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ width: "100%" ,overflowY:'hidden'}}>
                                                                            <ApiTable 
                                                                                data={tableData[index]}
                                                                                handleSelectApi={props.handleSelectApi}
                                                                                fileIndex={index} 
                                                                                handleSelectAll = {props.handleSelectAll}
                                                                                selectedData = {props.allTableData}
                                                                                isSelect ={true}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </React.Fragment>
                                        ))}
                                    </Table>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}