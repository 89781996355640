import { 
    TableHead, TableRow, Table, TableCell, FormControlLabel, Checkbox, 
    TableBody, 
    IconButton
} from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import useStyles from 'src/app/maintenance/assets/styles';
import { EditIconLarge, TrashIcon } from 'src/components/Icons';

export const ApiTable = (props) => {
    const classes = useStyles()
    return (
        <Table style={{border:props.border ? '1px solid #E2E5ED' : 'none'}}>
            <TableHead>
                <TableRow style={{backgroundColor:'white'}}>
                    {props.isSelect &&  <TableCell>
                        <FormControlLabel
                            label=""
                            control={
                                <Checkbox
                                    style={{ padding: "0px" }}
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={
                                        props.selectedData.some(entry => entry.fileName === props.data[props.fileIndex].source) &&
                                        props.selectedData.find(entry => entry.fileName === props.data[props.fileIndex].source).apis.length === props.data.length
                                    }
                                    indeterminate={
                                        props.selectedData.some(entry => entry.fileName === props.data[props.fileIndex].source) &&
                                        props.selectedData.find(entry => entry.fileName === props.data[props.fileIndex].source).apis.length > 0 &&
                                        props.selectedData.find(entry => entry.fileName === props.data[props.fileIndex].source).apis.length < props.data.length
                                    }
                        
                                />
                            }
                            onChange={() =>props.handleSelectAll(props.fileIndex)}
                            labelPlacement="start"
                        />
                    </TableCell>
                    }
                   
                    <TableCell>Path</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Base URL</TableCell>
                    <TableCell>Authentication Token</TableCell>
                    {props.isAction && <TableCell>Action</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((data, index) => {
                    return ( 
                        <TableRow key={index} style={{backgroundColor : index % 2 == 0 ? '#F5F5F5' : 'white'}}>
                            {props.isSelect && <TableCell>
                                <FormControlLabel
                                    label=""
                                    control={
                                        <Checkbox
                                            style={{ padding: "0px" }}
                                            color="primary"
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            onChange={() =>props.handleSelectApi(props.fileIndex,index)}
                                            checked={
                                                props.selectedData.some(entry => entry.fileName === data.source) &&
                                                props.selectedData.find(entry => entry.fileName === data.source).apis.some(api => api.apiName === data.apiName)
                                            }
                                        />
                                    }
                                    labelPlacement="start"
                                />
                            </TableCell>
                            }
                            <TableCell className={classes.apiTableCell}>{data.apiName || 'N/A'}</TableCell>
                            <TableCell className={classes.apiTableCell}>{data.description || 'N/A'}</TableCell>
                            <TableCell className={classes.apiTableCell}>{data.serverUrl || 'N/A'}</TableCell>
                            <TableCell className={classes.apiTableCell}>{data.authenticationToken || 'N/A'}</TableCell>
                            {props.isAction && 
                                <TableCell>
                                    <IconButton size={'small'} onClick={() => props.handlePopup('edit',data)}>
                                        <EditIconLarge />
                                    </IconButton>
                                    <IconButton size={'small'} onClick={() => props.handlePopup('delete',data)}>
                                        <TrashIcon />
                                    </IconButton>
                                </TableCell>}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
