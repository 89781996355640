import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './device/device.component';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {SignUpComponent} from './authentication/sign-up/sign-up.component';
import {AuthGuard} from './auth.guard';
import {LoginComponent} from './authentication/login/login.component';
import {ForgetPasswordComponent} from './authentication/forget-password/forget-password.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {ResetPasswordComponent} from './authentication/reset-password/reset-password.component';
import {KitBoardComponent} from './kitboard/kitboard.component';
import {KitModelBoardComponent} from './kitmodelboard/kitmodelboard.component';
import {DeviceViewComponent} from './kitboard/device-view/device-view.component';
import {DeviceDataComponent} from './device/device-data/device-data.component';
import {KitHistoryComponent} from './kitboard/kit-history/kit-history.component';
import {WifiConfigComponent} from './wifi-config/wifi-config.component';
import {MyComponentWrapperComponent} from './dashboard/DashBoardComponentWrapper';
import {TestingAutomationComponentWrapper} from './testing-automation/TestingAutomationComponentWrapper';
import {ProductTypComponentWrapper} from './product-types/ProductTypesComponentWrapper';
import {OTAManagerComponentWrapper} from './otamanager/OTAManagerComponentWrapper';
import {KitComponentWrapper} from './kit/KitComponentWrapper';
import {KitModelComponentWrapper} from './kitModel/KitModelComponentWrapper';
import {RemoteManagerComponentWrapper} from './remotemanager/RemoteManagerComponentWrapper';
import {DeviceConnectivityComponent} from './device-connectivity/device-connectivity.component';
import {AssignedDeviceComponent} from './device-connectivity/assigned-device/assigned-device.component'
import {DecodersComponentWrapper} from './transcoding/DecodersComponentWrapper';
import {DeviceTroubleshotComponent} from './device/device-troubleshot/device-troubleshot.component';
import {DeviceDataActuatorComponent} from "./device/device-data-actuator/device-data-actuator.component";
import {SetupSenzMaticaComponentWrapper} from './setup-senzmatica/SetupSenzMaticaComponentWrapper';
import {DeviceMaintanenceComponent} from './device/device-maintanence/device-maintanence.component'
import {CallbackComponent} from './authentication/login/callback.component';
import {LandingPageComponent} from './authentication/login/landing-page/landingpage.component';
import { AutomLWrapper } from './auto-ml/AutoMLWrapper';
import { AutoMLDashboardWrapper } from './auto-ml/AutoMLDashboardWrapper';


const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: SetupSenzMaticaComponentWrapper},
      // {path: 'connectDevice', component: ConnectDeviceWrapper},
      {path: 'dashboard', component: MyComponentWrapperComponent},
      {path: 'devices', component: DashboardComponent},
      {path: 'devices/:coperateId/:filter/:favourite', component: DashboardComponent},
      {path: 'kits-old', component: KitBoardComponent},
      {path: 'react', component: MyComponentWrapperComponent},
      {path: 'kit-models-old', component: KitModelBoardComponent},
      {path: 'wifi-config', component: WifiConfigComponent},
      {path: 'device-view/:deviceId', component: DeviceViewComponent},
      {path: 'device/:deviceId', component: DeviceDataComponent},
      {path: 'device/maintenance/:deviceId', component: DeviceMaintanenceComponent},
      {path: 'device/:deviceId/actuator', component: DeviceDataActuatorComponent},
      {path: 'kit/:kitId', component: KitHistoryComponent},
      {path: 'testing', component: TestingAutomationComponentWrapper},
      {path: 'product-types', component: ProductTypComponentWrapper},
      {path: 'product-types/configurations', component: ProductTypComponentWrapper},
      {path: 'OTAManager', component: OTAManagerComponentWrapper},
      {path: 'KitModel', component: KitModelComponentWrapper},
      {path: 'Kit', component: KitComponentWrapper},
      {path: 'RemoteManager', component: RemoteManagerComponentWrapper},
      {path: 'device-connectivity', component: DeviceConnectivityComponent},
      {path: 'device-connectivity/mqtt', component: AssignedDeviceComponent},
      {path: 'codec', component: DecodersComponentWrapper},
      {path: ':codecId/codec', component: DecodersComponentWrapper},
      {path: 'device/:deviceId/troubleshoot', component: DeviceTroubleshotComponent},
      {path: 'User-Application', component: SetupSenzMaticaComponentWrapper},
      {path: 'User-Application/Direct-API', component: SetupSenzMaticaComponentWrapper},
      {path: 'User-Application/1', component: SetupSenzMaticaComponentWrapper},
      {path: 'User-Creation', component: SetupSenzMaticaComponentWrapper},
      {path: 'DefineProductType', component: SetupSenzMaticaComponentWrapper},
      {path: 'ConnectDevice', component: SetupSenzMaticaComponentWrapper},
      {path: 'TestAutomation', component: SetupSenzMaticaComponentWrapper},
      {path: 'DataStream', component: SetupSenzMaticaComponentWrapper},
      {path: 'ConnectDataStream', component: SetupSenzMaticaComponentWrapper},
      {path: 'DefineTestCases', component: SetupSenzMaticaComponentWrapper},
      {path: 'TestReport', component: SetupSenzMaticaComponentWrapper},
      {path: 'StartTesting', component: SetupSenzMaticaComponentWrapper},
      {path: 'RootCauseAnalysis', component: SetupSenzMaticaComponentWrapper},
      {path: 'HumanoidNotificationSystem', component: SetupSenzMaticaComponentWrapper},
      {path: 'OTA-Manager', component: SetupSenzMaticaComponentWrapper},
      {path: 'Remote-Manager', component: SetupSenzMaticaComponentWrapper},
      {path: 'ResultDashboard',component:SetupSenzMaticaComponentWrapper},
      {path: 'Batches', component:SetupSenzMaticaComponentWrapper},
      {path: 'TestCases', component: SetupSenzMaticaComponentWrapper},
      {path: 'RootCauseConfig', component: SetupSenzMaticaComponentWrapper},
      {path: 'ChatbotConfig', component: SetupSenzMaticaComponentWrapper},
      {path: 'ChatHistory', component: SetupSenzMaticaComponentWrapper},
      {path: 'ReportHistory', component: SetupSenzMaticaComponentWrapper},
      {path: 'DevicePulseDevices' , component: SetupSenzMaticaComponentWrapper},
      {path: 'Alert' , component:SetupSenzMaticaComponentWrapper},
      {path: 'SubscriptionUsage' , component: SetupSenzMaticaComponentWrapper},
      {path: 'Configuration', component: SetupSenzMaticaComponentWrapper},
      {path: 'DeviceFailure', component: SetupSenzMaticaComponentWrapper},
      {path: 'LandingPage' , component: LandingPageComponent},
      {path:'automl', component: AutomLWrapper},
      {path:'project-init', component: AutomLWrapper},
      {path:'pre-processing', component: AutomLWrapper},
      {path:'data-transformation', component: AutomLWrapper},
      {path:'model-tuning', component: AutomLWrapper},
      {path:'model-saving', component: AutomLWrapper},
      {path:'automl-dashboard', component: AutoMLDashboardWrapper},

    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        component: ForgetPasswordComponent
      },
      {
        path: 'sign-up',
        component: SignUpComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      { path: 'callback', component: CallbackComponent }, // Add callback route

    ]
  },
  {path: '**', redirectTo: ''}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
