import {AfterViewInit, Component, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {DefineProductTypeComponent} from './DefineProductTypeComponent';
import * as React from 'react';
import {SideNavService} from 'src/app/layouts/side-menu/sidenav/sidenav.service';
import * as ReactDOM from 'react-dom';


@Component({
  selector: 'app-my-component',
  template: `
    <div [id]="rootId" style="transition: 0.6s" [ngClass]="isSideNavVisible()?'side-menu-padding':'side-menu-padding-zero'"></div>`,
  styleUrls: ['../../../components/react.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DefineProductTypeComponentWrapper implements OnChanges, OnDestroy, AfterViewInit {

  constructor(private sideNavService: SideNavService) {
  }

  public rootId = "rootId"

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
    this.sideNavService.hideSideNav();
  }

  ngOnDestroy() {
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  toggleSideNav() {
    this.sideNavService.toggleSideNav();
    this.render();
  }

  private render() {
    ReactDOM.render(React.createElement(DefineProductTypeComponent), document.getElementById(this.rootId))
  }
}

export {DefineProductTypeComponent};

