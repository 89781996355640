import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { LoginResponse } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-callback',
  template: `<div>Processing Azure Login...</div>`,
})
export class CallbackComponent implements OnInit {
  @Output() tokenExchangeSuccess = new EventEmitter<any>();
  loginError: string;
  loginResponse: LoginResponse;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      const state = params['state'];
      const marketplaceToken = state && state.split('|')[1];

      if (code) {
        console.log("Authorization code received: {}", code);
        console.log("marketplace token: {}", marketplaceToken)

        this.exchangeAuthorizationCode(code, marketplaceToken);
      } else {
        console.error("No authorization code received.");
        this.router.navigate(['/login']);
      }
    });
  }

  private exchangeAuthorizationCode(code: string, marketplaceToken?: string) {
    const payload = {
      code,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      marketplaceToken,
    };
  
    this.http.post(environment.host + '/open/azure-login', { payload }).subscribe({
      next: (response: any) => {
        const loginResponse = response.content;

        if (loginResponse) {
          AuthService.loadCredential(loginResponse);
  
          const role = loginResponse.role;
  
          if (role < 10) {
            this.router.navigate(['']);
          } else if (role >= 10 && role < 100) {
            this.router.navigate(
              this.authService.isTestAutomationUser()
                ? ['/ResultDashboard']
                : ['/TestAutomation']
            );
          } else {
            this.loginError = 'You don\'t have permission';
          }
        } else {
          console.error('Login response is invalid:');
          this.loginError = 'Failed to authenticate.';
          this.router.navigate(['/login']);
        }
      },
      error: (error) => {
        console.error('Error during token exchange:', error);
  
        // Redirect to login on error
        this.router.navigate(['/login']);
      },
    });
  }  
}
