import * as React from "react";
import { useState} from "react";
import {  Button, createTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import {  useHistory } from 'react-router-dom';
import useStyles from "../../assets/styles";
import { primaryBlue, primaryGray } from "src/components/colors";
import Step1ConnectDevices from './Steps/Step1ConnectDevices';
import { environment } from "src/environments/environment";
import Step2SelectTestCases from './Steps/Step2SelectTestCases';
import Step3GetDeviceData from './Steps/Step3GetDeviceData';
import Step4DataPreview from './Steps/Step4DataPreview';
import { Loader } from "../../test-automation/components/Loader";
import { ToasterComponent } from "../../test-automation/components/Toaster";
import axios from 'axios';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const STEPS = [
  {
    text: 'Connect Devices',
    activate: true,
  },
  {
    text: 'Select Test Devices',
    activate: false,
  },
  {
    text: 'Get Device Data',
    activate: false,
  },
  {
    text: 'Data Preview',
    activate: false,
  },
];


export function AzureConnectDeviceComponent() {
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState(STEPS);
  const classes = useStyles();
  const history = useHistory();
  const [apiVersion, setApiVersion] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [url, setUrl] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [connectDeviceId, setConnectDeviceId] = useState([]);
  const [devices, setDevices] = useState([]);
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [message, setMessage] = useState("");
  const [isExistingBatchNumber, setIsExistingBatchNumber] = useState(false);
  const [selectedDeviceFinal, setSelectedDeviceFinal] = useState([]);
  const [inputValue, setInputValue] = useState(batchNumber);
  const [connectionString, setConnectionString] = useState("");
  const [primaryKey, setPrimaryKey] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [containerName, setContainerName] = useState("");
  const [transcoding, setTranscoding] = useState("");
  const [dataTranscoding, setDataTranscoding] = useState("");


  //Errors
  const [urlError, setUrlError] = useState(false);
  const [primaryKeyError, setPrimaryKeyError] = useState(false);
  const [databaseNameError, setDatabaseNameError] = useState(false);
  const [containerNameError, setContainerNameError] = useState(false);
  const [apiVersionError, setApiVersionError] = useState(false);
  const [apiKeyError, setApiKeyError] = useState(false);
  const [selectedDeviceError, setSelectedDeviceError] = useState(false);
  const [connectionStringError, setConnectionStringError] = useState(false);
  const [transcodingError, setTranscodingError] = useState(false);
  const [batchNumberError, setBatchNumberError] = useState(false);
  const [dataTranscodingError, setDataTranscodingError] = useState(false);

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    connectionString: "",
    apiVersion: "",
    url: "",
    primaryKey: "",
    databaseName: "",
    containerName: "",
    transcoding: "",
    dataTranscoding: "",
    batch: ""

  });
  console.log(step);



  const validationForm = async () => {
    const connectionString_Error = connectionString.trim() === "";
    const apiVersion_Error = apiVersion.trim() === "";

    setConnectionStringError(connectionString_Error);
    setApiVersionError(apiVersion_Error);

    if (connectionString_Error || apiVersion_Error) {
      return false;
    }

    return true;
  };

  const validateForm = () => {
    const selectDeviceError = selectedDeviceFinal.length === 0;
    const batchNumber_Error = batchNumber.trim() === "";
    const transcoding_Error = transcoding.trim() === "";

    setSelectedDeviceError(selectDeviceError);
    setTranscodingError(transcoding_Error);
    setBatchNumberError(batchNumber_Error);

    if (selectDeviceError || batchNumber_Error || transcoding_Error) {
      return false;
    }

    return true;
  };
  const step3ValidateForm = () => {
    const url_Error = url.trim() === "";
    const primaryKey_Error = primaryKey.trim() === "";
    const databaseName_Error = databaseName.trim() === "";
    const containerName_Error = containerName.trim() === "";
    const dataTranscoding_Error = dataTranscoding.trim() === "";

    setUrlError(url_Error);
    setPrimaryKeyError(primaryKey_Error);
    setDatabaseNameError(databaseName_Error);
    setContainerNameError(containerName_Error);
    setDataTranscodingError(databaseName_Error);

    if (url_Error || primaryKey_Error || databaseName_Error ||  containerName_Error || dataTranscoding_Error) {
      return false;
    }
    return true;
  };
  const connectDevices = () => {
    setLoading(true);

    axios({
      method: 'POST',
      url: `${environment.host}/core/azureIoT/devices`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      data: {
        connectionString: formData.connectionString,
        apiVersion: formData.apiVersion,
      },
    })
      .then(response => {
        const data = response.data;
        if (data.message === 'Success') {
          const connectedDeviceId = [data.content];
          setConnectDeviceId(connectedDeviceId);
          setConnectedDevices(data.content);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setIsToasterOpen(true);
          setReqSuccess(false);
          setToasterMessage(error.response.data.message);
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 5000);
        } else {
          console.error(error);
        }
        setLoading(false);
      });
  };


  const connectCosmosDb = () => {
    setLoading(true);

    axios({
      method: 'POST',
      url: `${environment.host}/core/azureIoT/connectCosmosDb/getDocs`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      data: {
        cosmosEndpoint: formData.url,
        cosmosKey: formData.primaryKey,
        databaseName: formData.databaseName,
        containerName: formData.containerName,
        dataTranscoding: dataTranscoding,
        deviceIds: selectedDeviceFinal,
      },
    })
      .then(response => {
        const data = response.data;
        if (data.message === 'Success') {
          console.log("data", data.content);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setIsToasterOpen(true);
          setReqSuccess(false);
          setToasterMessage(error.response.data.message);
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 5000);
        } else {
          console.error(error);
        }
        setLoading(false);
      });
  };

  const selectDevices = () => {
    const batch = inputValue;
    setLoading(true);

    axios({
      method: 'POST',
      url: `${environment.host}/core/azureIoT/devices/save`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params: {
        transcoding: formData.transcoding,
        batchNumber: formData.batch,
        connectionString: formData.connectionString,
        apiVersion: formData.apiVersion,
      },
      data: selectedDeviceFinal,
    })
      .then(response => {
        const data = response.data;

        if (data.message === 'Success') {
          setSelectedDevice(selectedDeviceFinal);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          if (error.response.data.message === 'Batch number already exist') {
            setMessage(error.response.data.message);
            setIsExistingBatchNumber(true);
          } else {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage(error.response.data.message);
            setTimeout(() => {
              setIsToasterOpen(false);
            }, 5000);
          }
        } else {
          console.error(error);
        }
        setLoading(false);
      });
  };

  const fetchDevices = async () => {
    const deviceIdsParam = selectedDeviceFinal.join(',');
    const url = `${environment.host}/core/device${deviceIdsParam ? `?deviceIds=${deviceIdsParam}` : ''}`;
    setLoading(true);

    axios({
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
      .then(response => {
        const data = response.data;
        setDevices(data.content);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reloadData = async () => {
    await fetchDevices();
  }


  const increaseStep = async () => {
    if(step === 0){
      const isValid = await validationForm();
      if (!isValid) {
       return;
         // setStep(step + 1);

      } else {
           // setStep(step + 1);
          connectDevices();
      }
    } else if(step === 1){
      const isValid = await validateForm();
      if(!isValid){
        return;

      } else {
        // setStep(step + 1);
          selectDevices();
      }
    } else if(step === 2){
      const isValid = await step3ValidateForm();
      if(!isValid){
           return;

      } else {
        await connectCosmosDb();
        // setStep(step + 1);
      }
    } else {
      setStep(step + 1);
      const updatedSteps = steps.map((s, i) => ({
        ...s,
        activate: i <= step + 1,
      }));
      setSteps(updatedSteps);
    }
  }

  const decreaseStep = () => {
    if(step === 0){
      history.push('/DataStream');
    } else {
      setStep(step - 1);
      const updatedSteps = steps.map((s, i) => ({
        ...s,
        activate: i <= step - 1,
      }));
      setSteps(updatedSteps);
    }
  }

  function onClickDone() {
    setStep(0);
    setSteps(STEPS);
    history.push('/TestAutomation');
  }

  function renderStepsComponent() {
    if(step === 1) {
      return <Step2SelectTestCases
        formData={formData}
        setFormData={setFormData}
        loading={loading}
        connectedDevices={connectedDevices}
        selectedDeviceFinal={selectedDeviceFinal}
        setSelectedDeviceFinal={setSelectedDeviceFinal}
        selectedDeviceError={selectedDeviceError}
        message={message}
        setMessage={setMessage}
        setInputValue={setInputValue}
        isExistingBatchNumber={isExistingBatchNumber}
        batchNumber={batchNumber}
        SetBatchNumber = {setBatchNumber}
        batchNumberError={batchNumberError}
        transcoding ={transcoding}
        setTranscoding={setTranscoding}
        transcodingError ={transcodingError}
        setTranscodingError={setTranscodingError}
      />;
    } else if(step === 2) {
      return <Step3GetDeviceData
        formData={formData}
        setFormData={setFormData}
        apiKey={apiKey}
        setApiKey={setApiKey}
        apiKeyError={apiKeyError}
        setApiKeyError={setApiKeyError}
        url={url}
        setUrl={setUrl}
        urlError={urlError}
        setUrlError={setUrlError}
        primaryKey={primaryKey}
        setPrimaryKey={setPrimaryKey}
        primaryKeyError={primaryKeyError}
        setPrimaryKeyError={setPrimaryKeyError}
        databaseName={databaseName}
        setDatabaseName={setDatabaseName}
        databaseNameError={databaseNameError}
        setDatabaseNameError={setDatabaseNameError}
        containerName={containerName}
        setContainerName={setContainerName}
        containerNameError={containerNameError}
        setContainerNameError={setContainerNameError}
        dataTranscoding={dataTranscoding}
        setDataTranscoding={setDataTranscoding}
        dataTranscodingError = {dataTranscodingError}
        setDataTranscodingError={setDataTranscodingError}/>;
    } else if (step === 3) {
      return <Step4DataPreview
        loading={loading}
        fetchDevices={fetchDevices}
        reloadData={reloadData}
        devices={devices}
        selectedDevice={selectedDevice} />;
    } else {
      return <Step1ConnectDevices
        formData={formData}
        setFormData={setFormData}
        connectionString = {connectionString}
        setConnectionString = {setConnectionString}
        connectionStringError ={connectionStringError}
        setConnectionStringError = {setConnectionStringError}
        apiVersion ={apiVersion}
        setApiVersion ={setApiVersion}
        apiVersionError = {apiVersionError}
        setApiVersionError = {setApiVersionError}
      />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step-01
        </div>

        <Grid container alignItems="center">
          <Grid item xs={12} md={12}
                style={{
                  textAlign: "center",
                  paddingBottom: "40px"
                }}
          ><h2 style={{color:"#324054"}}>Azure IoT</h2>
          </Grid>
        </Grid>

        <Grid container
              style={{
                textAlign: "center",
                marginTop: "20px",
                paddingRight: '250px'
              }}>
          <Grid item xs={1} md={4}></Grid>
          {steps.map((stepItem, index) => (
            <React.Fragment key={index}>
              <Grid item xs={2} md={1}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Circle number={index + 1} active={index < step} current={index === step}/>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "17px",
                        paddingTop: "15px",
                        fontFamily: "Poppins, sans-serif",
                        color: index <= step ? "#2A7CED" : "#8F8F8F",
                      }}
                    >
                      {stepItem.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {index !== steps.length - 1 && (
                <Grid item xs={2} md={1}>
                  <hr
                    style={{
                      marginTop: "32px",
                      opacity: index < step ? "1" : "0.12",
                    }}
                    color={index < step ? "#2A7CED" : "rgba(112, 112, 112, .12)"}
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={1} md={3}></Grid>
        </Grid>

        <Grid item xs={12} md={12}
              style={{
                marginTop: "20px",
                justifyContent: "center",
                alignContent: "center",
              }}>
          {renderStepsComponent()}
        </Grid>


        {step === 0 ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={1} md={1}></Grid>
              <Grid item xs={12} md={9} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px", marginRight: "20px" }}>
                  <Button onClick={decreaseStep} className={classes.backButton}>
                    Back
                  </Button>
                </Grid>
                <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px", marginRight: "20px" }}>
                  <Button onClick={decreaseStep} className={classes.backButton}>
                    Disconnect
                  </Button>
                </Grid>
                <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px" }}>
                  <Button onClick={increaseStep} className={classes.submitButton}>
                    Connect
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {step === 1 ? (
              <Grid container spacing={1}>
                <Grid item xs={12} md={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px", marginRight: "20px" }}>
                    <Button onClick={decreaseStep} className={classes.backButton}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px" }}>
                    <Button onClick={increaseStep} className={classes.submitButton}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                {step === 2 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={1} md={1}></Grid>
                    <Grid item xs={12} md={9} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px", marginRight: "20px" }}>
                        <Button onClick={decreaseStep} className={classes.backButton}>
                          Back
                        </Button>
                      </Grid>
                      <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px" }}>
                        <Button onClick={increaseStep} className={classes.submitButton}>
                          Connect
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px", marginRight: "20px" }}>
                        <Button onClick={decreaseStep} className={classes.backButton}>
                          Back
                        </Button>
                      </Grid>
                      <Grid item className={classes.buttonContainer} style={{ marginBottom: "30px" }}>
                        <Button onClick={onClickDone} className={classes.submitButton}>
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </>
        )}

        <Loader loading={loading}/>
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        />
      </div>
    </ThemeProvider>
  );
};

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: props.current ?"white" : props.active ? "#2A7CED" : "white",
          border: props.current ? "2px solid #2A7CED" : props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.current ? primaryBlue : props.active ? "white" : primaryGray
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};
