import React, { useState, useEffect } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useDispatch, useSelector } from "react-redux";
import { USER_GUIDE_INFO } from '../models';
import { useLocation } from 'react-router-dom';
import { updateGuideUser } from '../maintenance/test-automation/components/apiHelper';
import { toCamelCaseWithSpaces } from '../utils';

export const UserGuide = ({isTourRunning, steps}) => {
  const location = useLocation();
  const [runTour, setRunTour] = useState(false);
  const [tourSteps, setTourSteps] = useState([]);
  const [tourKeys, setTourKeys] = useState([]);
  const [visitedPageMap, setVisitedPageMap] = useState({});

  const [joyRideKey, setJoyRideKey] = useState([]);
  const [joyRideSteps, setJoyRideSteps] = useState([]);

  useEffect(() => {
    if (isTourRunning) {
      const setupDevicePulseStep = {
        target: '#nav-SETUP_DEVICE_PULSE',
        title: 'Setup Device Pulse',
        content: USER_GUIDE_INFO.SETUP_DEVICE_PULSE,
        disableBeacon: true,
      };
  
      const filteredSteps = steps.filter(step => step.id && step.id !== 'Step_06');
  
      const remainingSteps = filteredSteps.map((step, index) => ({
        target: `#nav-${step.id}`,
        title: `${step.title} - ${step.number}`,
        content: USER_GUIDE_INFO[step.id] || '',
        disableBeacon: true,
      }));
  
      const joyRideSteps = [setupDevicePulseStep, ...remainingSteps];
      setTourSteps(joyRideSteps);
      setTourKeys(joyRideSteps.map(step => step.target.replace('#nav-', '')));
      setRunTour(true);
    }
  }, [isTourRunning, steps]);

  useEffect(() => {
    if (!joyRideSteps || joyRideSteps.length === 0 || !joyRideKey || joyRideKey.length === 0) return;

    const visitedPages = localStorage.getItem('userGuidePages');
    let parsedPages = { visitedPageMap: {} };

    if (visitedPages) {
      try {
        parsedPages = JSON.parse(visitedPages);
        if (!parsedPages.visitedPageMap) {
          parsedPages.visitedPageMap = {};
        }
      } catch (error) {
        parsedPages = { visitedPageMap: {} };
      }
    }

    const notVisitedKeys = joyRideKey.filter(key => !parsedPages.visitedPageMap[key]);
    const notVisitedSteps = joyRideSteps.filter(step => notVisitedKeys.includes(step.key));

    if (notVisitedKeys.length > 0) {
      const steps = notVisitedSteps.map((data, index) => ({
        target: `#nav-${data.key}`,
        title: toCamelCaseWithSpaces(`${data.key}`),
        content: USER_GUIDE_INFO[data.key] || '',
        disableBeacon: index === 0,
        placement: 'right',
      }));

      setTourSteps(steps);
      setTourKeys(notVisitedKeys);
      setTimeout(() => {
        setRunTour(true);
      }, 500);
    }
  }, [joyRideSteps, joyRideKey]);

  useEffect(() => {
    if (isTourRunning) {
      setRunTour(true);
      console.log('isTourRunning', isTourRunning);
    }
  }, [isTourRunning]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize')); 
    }, 500);
  }, [runTour]);

  const handleJoyrideCallback = (data) => {
    const { status, action, type } = data;
    if (status === 'finished' || status === 'skipped' || action === 'close') {
      const visitedPages = localStorage.getItem('userGuidePages');
      let parsedPages = { visitedPageMap: {} };

      if (visitedPages) {
        parsedPages = JSON.parse(visitedPages);
      }

      const updatedVisitedPageMap = { ...parsedPages.visitedPageMap };
      tourKeys.forEach(key => {
        if (key) {
          updatedVisitedPageMap[key] = true;
        }
      });

      localStorage.setItem('userGuidePages', JSON.stringify({ visitedPageMap: updatedVisitedPageMap }));
      setVisitedPageMap(updatedVisitedPageMap);
      setRunTour(false);
    }
  };

  useEffect(() => {
    if (Object.keys(visitedPageMap).length > 0) {
      updateGuideUser({visitedPageMap});
    }
  }, [visitedPageMap]);
  

  return (
    <Joyride
      run={runTour}
      steps={tourSteps}
      continuous
      showSkipButton
      showProgress
      disableScrolling
      scrollToFirstStep={false}
      callback={handleJoyrideCallback}
      floaterProps={{placement:'right'}}
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: '#005B96',
          backgroundColor: '#005B96',
          textColor: '#F2FAFF',
        },
        overlay:{
          margin: '0px',
        },
        spotlight:{
          margin: '0px',
        },
        tooltipContainer: {
          margin: '0px',
        },
        tooltipTitle:{
          textAlign:'left',
          padding:'0px 10px',
        },
        tooltipContent:{
          textAlign: 'left'
        },
        tooltip: {
          height: '250px',
          width: '550px',
        },
        buttonNext: {
          backgroundColor: '#FFFFFF',
          color:'#005B96',
          marginLeft: '8px',
        },
        buttonBack: {
          color: '#C4C2C2',
          backgroundColor:'#005B96',
          border:'1px solid #C4C2C2',
          display: 'none',
        },
        tooltipFooter: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 10px',
          margin: '0px',
        },
        buttonSkip: {
          color: '#C4C2C2',
          backgroundColor:'#005B96',
          border:'1px solid #C4C2C2',
          placeSelf: 'right'
        },
        beacon: {
          backgroundColor: '#005B96', 
          borderColor: '#005B96',
        },

      }} 
      locale={{
        next: tourSteps.length > 1 ? 'Next' : 'Got it',
        skip: 'End Tour',
        last: 'Got it',
      }}
    />
  );
};
