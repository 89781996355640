import * as React from "react";
import {useState} from "react";
//import {useState} from 'react';
import {Chip, Grid} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {TestSuccessCriteria} from "./test-success-criteria/testSuccessCriteria";
import {TestSummaryReport} from "./test-summary-report/testSummaryReport";
import {DataTable} from "./data-table/dataTable";

const useStyles = makeStyles((theme: Theme) => ({}));

export function TestDashboard(props) {
  const [selectedMainTest, setSelectedMainTest] = useState(
    ""
  );

  const changeMainTest = (title) => {
    setSelectedMainTest(title);
    props.handleTestDashboardClick(title);
  };
  
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6}>
          {props.testSummaryData !== undefined &&
          props.mainTestCase !== undefined ? (
            <TestSuccessCriteria
              summary={props.testSummaryData}
              mainCase={props.mainTestCase}
              mainTestTitle={selectedMainTest}
            />
          ) : (
            <>Test Success Criteria Empty</>
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{paddingRight: "20px"}}>
          {props.testSummaryData !== undefined &&
          props.mainTestCase !== undefined ? (
            <TestSummaryReport
              summary={props.testSummaryData}
              mainTestTitle={selectedMainTest}
              mainCase={props.mainTestCase}
            />
          ) : (
            <>Test Summary Report Empty</>
          )}
        </Grid>
        <Grid item xs={12} md={12} style={{margin: "20px"}}>
          {props.testSummaryData !== undefined &&
          props.allMainTestCases !== undefined ? (
            <>
              <Grid container>
                <Grid item xs={12} md={12} style={{textAlign: "left"}}>
                  {[
                    "Continuous Transmission Test",
                    "Coverage Test",
                    "Flash Saving Test",
                    "GSM Connectivity Test",
                  ].map((button, index) => {
                    return (
                      <Chip
                        key={index}
                        label={button}
                        style={{
                          borderRadius: "4px",
                          margin: "10px",
                          backgroundColor:
                            selectedMainTest === button
                              ? "rgba(9, 30, 66, 0.2)"
                              : "rgba(9, 30, 66, 0.04)",
                          color: "#606060",
                        }}
                        onClick={() => changeMainTest(button)}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <DataTable
                allMainTestCases={props.dataTableMainTestCases}
                testSummaryData={props.testSummaryData}
                loadingClose={props.loadingClose}
                mainTestTitle={selectedMainTest}
              />
            </>
          ) : (
            <>Data Table Empty</>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
