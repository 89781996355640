import { Box, Button, createTheme, Grid, IconButton, InputAdornment, ListItem, Paper, TextField, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import {Link, useHistory, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import { Arrow, AttachIcon, BackIcon, BatchesIcon, ChatBotIcon, ChatCardIcon, ChatHistoryIcon, CopyIcon, NonSuccessCriteriaIcon, SentIcon, ShareIcon, SoundIcon, ViewIcon } from 'src/components/Icons';
import useStyles from '../maintenance/assets/styles';
import { answerChat } from '../maintenance/test-automation/components/apiHelper';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { formatLargeNumber, handleDevicePulseClicked } from '../utils';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { DialogPopup } from '../maintenance/test-automation/components/DialogPopup';
import { LineChart } from '../maintenance/test-automation/components/LineChart';
import { Loader } from '../setup-senzmatica/step-3/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import { AcknowledgementComponent } from '../maintenance/test-automation/step7/components/AcknowledgementComponent';
import Cookies from "js-cookie";

export const ChatBotComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [isViewGraph, setIsViewGraph] = useState(false);
    const [chatIndex, setChatIndex] = useState(0);
    const [graphDetails, setGraphDetails] = useState({
        x_label: "",
        y_label: "",
        title: "",
        values: [],
        creation_dates: [],
    });
    
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState('')
    const [reqSuccess, setReqSuccess] = useState(false)
    const [responseCount,setResponseCount] = useState(0)
    const [isPromptVisible,setIsPropmtVisible] = useState(false)
    const [data,setData] = useState({
        userId:"",
        rootcauseId : "",
        referenceId : "",
        Like : null
    })

    const [chatbotData, setChatbotData] = useState(location.state && location.state.chatbotData ? location.state.chatbotData : null);
    console.log('chatbotData:', chatbotData);
    const [loading, setLoading] = useState(false);

    const batchNumber = location.state && location.state.batchNumber ? location.state.batchNumber : null;
    console.log('batchNumber:', batchNumber);

    const rootCauseId = location.state && location.state.rootCauseId ? location.state.rootCauseId : null;
    console.log('rootCauseId:', rootCauseId);

    const expandGraph = async (path) => {
        try {
            setLoading(true)
            const response = await axios.post(`${environment.chatbotHost}/get-img-content/`, { "img_path": path }, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
                }
              });
            const answer = response.data.data;

            // console.log('answer:', answer);
    
            const scale = formatLargeNumber(Math.max(...answer.values)).scale; 
            const formattedValues = answer.values.map((value) => {
                const { value: formattedValue, scale } = formatLargeNumber(value);
                return formattedValue;
            });
    
            const graphDetails = {
                x_label: answer.x_label,
                y_label: `Values (in ${scale})`,
                title: answer.title,
                values: formattedValues,
                creation_dates: answer.creation_dates
            };
    
            setGraphDetails(graphDetails);
            setIsViewGraph(true);
        } catch (error) {
            console.error("Error fetching graph data:", error);
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        }finally{
            setLoading(false)
        }
    };

    const openGraph = () =>{
        // setLoading(true)
        setIsViewGraph(true)
        setTimeout(() =>{
            setLoading(false)
        },3000)
    }

    const closeGraph = () => {
        setIsViewGraph(false);
        setGraphDetails(null);
    };

    useEffect(() => {
        console.log('chatbotData:', chatbotData);
    
        if (chatbotData ) {
            const notificationMessage = chatbotData.notification;
            const formattedMessages = notificationMessage ? [
                {
                    sender: 'bot',
                    text: notificationMessage,
                    parsed: parseResponse(notificationMessage)
                }
            ] : [];
            const chatHistoryMessages = chatbotData.chat_history.flatMap((entry) => {
                if (entry.Type === 'text') {
                    return [
                        { sender: 'user', text: entry.User, parsed: parseResponse(entry.User) },
                        { sender: 'bot', text: entry.Bot_Response, parsed: parseResponse(entry.Bot_Response) }
                    ];
                } else if (entry.Type === 'graph') {
                    return [
                        { sender: 'user', text: entry.User, parsed: parseResponse(entry.User) },
                        {
                            sender: 'bot',
                            image: (
                                <Button
                                    variant="outlined"
                                    startIcon={<ViewIcon color="#2A7BEC" />}
                                    style={{ color: '#2A7BEC', borderColor: '#2A7BEC1A' }}
                                    onClick={() => expandGraph(entry.Bot_Response)}
                                >
                                    View Graph
                                </Button>
                            )
                        }
                    ];
                }
                return [];
            });
    
            setMessages([...formattedMessages, ...chatHistoryMessages]);
        }
    }, [chatbotData]);

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            setMessages([...messages, {parsed: inputMessage, sender: "user" }]);
            setInputMessage("");
            let questions : any = {
                question: inputMessage.trim(),
                batchNumber: batchNumber,
                rootCauseId: rootCauseId,
                referenceId : Cookies.get('ACCOUNT'),
                userId : Cookies.get('USER_ID')     
            };            
            setInputMessage('')

            handleResponse(questions);
        }
    };

    const handleResponse = async (question) => {
        setLoading(true)
        setMessages(prev => [
            ...prev,
            { sender: "bot", loading: true}, // This triggers the Skeleton
        ]);
        const answer = await answerChat(question)
        if (answer.result) {
            // Replace the loading message with the bot's response
            setMessages(prev =>
                prev.map((msg, index) =>
                    index === prev.length - 1 && msg.loading ? { parsed: parseResponse(answer.result), sender: "bot" } : msg
                )
            );
        } else if (answer.graph_details) {
            let scale = "";
            const formattedValues = answer.graph_details.values.map((value) => {
                const { value: formattedValue, scale: newScale } = formatLargeNumber(value);
                scale = newScale; // Update scale
                return formattedValue;
            });
            const graphDetails = {
                x_label : answer.graph_details.x_label,
                y_label : `Values (in ${scale})`,
                title : answer.graph_details.title,
                values : formattedValues,
                creation_dates : answer.graph_details.creation_dates
            }
            setGraphDetails(graphDetails)
            setMessages((prev) =>
                prev.map((msg, index) => {
                  if (index === prev.length - 1 && msg.loading) {
                    setChatIndex(index); // Set the chat index if the condition matches
                    return {
                      image: <Button variant="outlined"
                                startIcon={<ViewIcon color = '#2A7BEC'/>}
                                style={{color:'#2A7BEC',borderColor:'#2A7BEC1A',}}
                                onClick ={() =>openGraph()}>
                                    View Graph
                            </Button>,
                      sender: "bot",
                    };
                  }
                  return msg; // Return the unchanged message otherwise
                })
            );          
        }
        setLoading(false)
    }
    
    const parseResponse = (text) => {
        // Split response into paragraphs by double newlines
        const paragraphs = text.split(/\n\n/);
        return paragraphs.map((para, paraIndex) => {
            if (para.includes('*')) {
                const listItems = para.split('\n');
                if(listItems){
                    return (
                        <div key={`list-${paraIndex}`} style={{ paddingBottom: '20px' }}>
                            {listItems.map((item, index) => {
                                // Check if the item is a list item (starts with '*')
                                if (item.startsWith('*')) {
                                    let htmlContent = convertToBold(item);
                                    return (
                                        <ul key={`list-${paraIndex}-${index}`} style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                            <li dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                        </ul>
                                    );
                                } else {
                                    // Handle as a paragraph with bold formatting
                                    let htmlContent = convertToBold(item);
                                    return (
                                        <p key={`para-${paraIndex}-${index}`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                                    );
                                }
                            })}
                        </div>
                    );
                }
            } else if(para.includes('##')){
                const formattedPara = para.replace('##', '').trim(); // Remove '##' and trim whitespace
                return (
                    <h3 key={`header-${paraIndex}`} style={{marginBottom:'20px'}} dangerouslySetInnerHTML={{ __html: convertToBold(formattedPara) }} />
                );
            } else {
                return (
                    <p key={`paragraph-${paraIndex}`} dangerouslySetInnerHTML={{ __html: convertToBold(para) }} />
                );
            }
        });
    };
    
    const convertToBold = (text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    };

    const handleBack = () => {
        history.push('/ChatHistory');
    };

    useEffect(() =>{
        setResponseCount(chatbotData.chat_history.length)
    },[])

    useEffect(() =>{
        if(messages && messages.every(message => !message.loading) && responseCount >= 5 && data.Like == null ){
            setIsPropmtVisible(true)            
        }
    },[responseCount,data,messages])
    
    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ padding: '25px' }}>
                <Grid item xs={12} md={12}>
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>Chat History</Grid>
                <Grid container className={classes.redirectContainer} style={{ margin: '30px 0px 10px 20px'}}>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                            <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                                <NonSuccessCriteriaIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <ChatHistoryIcon />
                        <Link to={'/ChatHistory'} style={{ color: '#858DAA', textDecoration: 'none' }}>
                            <Typography className={classes.redirectTitle}>Chat History</Typography>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ color: '#858DAA', display: 'flex', alignItems: 'center' }}>
                        <Typography className={classes.redirectTitle}>Device Pulse ChatBot</Typography>
                    </Grid>
                </Grid>

                <Grid container direction="column" style={{ padding: '20px', flexGrow: 1, gap:'5px' }}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '5px', padding:'5px', backgroundColor: '#FFFFFF' }}>
                        <Grid item style={{ display: 'flex' }}>
                            <Grid item onClick={handleBack} style={{ padding: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <BackIcon />
                            </Grid>
                            <Grid item style={{ backgroundColor: '#F2F8FF', borderRadius: '50%', padding: '10px', display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                                <ChatBotIcon color='#2A7BEC' size="32" />
                            </Grid>
                            <Typography variant="h6" className={classes.typo} style={{ alignContent: 'center', fontWeight: 'bold' }}>Device Pulse ChatBot</Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" style={{ padding: "10px", margin: "0px", width: '100%', height: '42vh', flexGrow: 1, overflowY: "auto" ,flexDirection:'row', display:'flex' }}>
                        {messages.map((msg, index) => (
                            <Grid container key={index}>
                                <ListItem style={{ justifyContent: msg.sender === "user" ? "flex-end" : "flex-start", alignItems:'flex-start'}}>
                                    {msg.sender === "bot" && (
                                        <Grid item style={{ backgroundColor: '#F2F8FF', borderRadius: '50%', padding: '10px', display: 'flex', marginRight: '10px', alignSelf:'flex-start' }}>
                                            <ChatBotIcon color='#2A7BEC' size="20" />
                                        </Grid>
                                    )}
                                    <Grid container direction={msg.sender === "bot" ? 'column' : null} style={{ justifyContent: msg.sender === "user" ? "flex-end" : "flex-start", alignItems:'flex-start',  margin:'0px' }}>
                                        <Paper
                                            style={{
                                                padding: "10px",
                                                backgroundColor: msg.sender === "user" ? "#1976d2" : "#f5f5f5",
                                                color: msg.sender === "user" ? "#fff" : "#000",
                                                wordWrap: "break-word",
                                                maxWidth: msg.text ? '80%' : '90%',
                                                borderRadius: msg.sender !== "user" ? '0px 15px 15px 15px' : '15px 15px 0px 15px',
                                                fontSize: '12px',
                                                overflow:'auto',
                                                height: 'auto',
                                                margin: '0px'
                                            }}
                                            className={classes.typo}
                                        >
                                            {msg.loading ? (
                                            <Skeleton variant="text" width={'100%'} height={20} />
                                        ) : (
                                            msg.parsed ? msg.parsed : msg.image
                                        )}
                                        </Paper>
                                    </Grid>
                                </ListItem>
                                {msg.sender == "bot" && !msg.loading && index == messages.length -1 &&( 
                                    <AcknowledgementComponent 
                                        rootCauseId = {rootCauseId}
                                        data={data}
                                        setData={setData} 
                                    />
                                )}
                            </Grid>
                        ))}
                        {(messages && messages.every(message => !message.loading) && responseCount >= 5 && data.Like == null && isPromptVisible) && (
                            <Grid container className={classes.chatBotPropmt}>
                                <Typography>Is this conversation helpful so far?</Typography>
                                <AcknowledgementComponent 
                                    rootCauseId={rootCauseId}
                                    data={data}
                                    setData={setData}
                                />
                                <IconButton onClick={() =>setIsPropmtVisible(false)}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>

                    <Grid 
                        container 
                        style={{
                            boxShadow: "0px -4px 16px 0px #00000014",
                            padding: "13px",
                            background: "var(--bg-footer, #FFFFFF)",
                            alignSelf: "flex-end"
                        }}
                    >
                        <TextField
                            fullWidth
                            placeholder="Type your message here..."
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            variant="filled"
                            className={classes.chatBotInput}
                            InputProps={{
                                classes: {
                                    root: classes.chatBotInput,
                                    input: classes.chatBotInput
                                },
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <AttachIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleSendMessage()}>
                                            <SentIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            <DialogPopup 
                open={isViewGraph} 
                onClose={closeGraph} 
                title={'Expand Graph'} 
                dialogWidth = {'1800px'}
                dialogContent={graphDetails && <LineChart graph_details={graphDetails} isExpand={true} index={chatIndex} />}
            />
            <ToasterComponent
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
        </ThemeProvider>
    );
};


export const EncodedImage = ({ encodedString }) => {
    return (
        <div>
            <img src={`data:image/jpeg;base64,${encodedString}`}
                alt="Encoded"
                style={{ width: '400px', height: '400px' }}
            />
        </div>
    );
};