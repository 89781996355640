import * as React from "react";
import {useEffect, useState} from "react";
import {Button, makeStyles, Theme,alpha, Grid} from "@material-ui/core";
import {primaryBlue, primaryGray, white} from "src/components/colors";
import {Link, useLocation} from 'react-router-dom';
import {AddUserComponent} from "./AddUser";
import {AddUserRoleComponent} from "./AdduserRole";
import {AlertBar} from "src/components/sub-components/AlertBar";
import {environment} from "src/environments/environment";
import {Loader} from "../step-3/components/Loader";
import {UserIcon, UserRoleIcon} from "src/components/Icons";
import axios from "axios";


export function UserCreationComponent(props) {
  const classes = useStyles();
  const location = useLocation();
  const [userType, setUserType] = useState("")
  const [isUserClick, setIsUserClick] = useState(false);
  const [isUserRoleCreated, setIsUserRoleCreated] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const { step4Completed } = location.state || { step4Completed: parseInt(localStorage.getItem('step4Completed'), 10) || 0 };

  //alert states
  const [alertOpen, setAlertOpen] = useState(false)
  const [severity, setSeverity] = useState("success")
  const [topic, setTopic] = useState("")
  const [message, setMessage] = useState("")
  const [userRoles, setUserRoles] = useState([])

  //loading state
  const [loading, setLoading] = useState(false)

  const showToastMessage = () => {
    setAlertOpen(true)
    setSeverity("error");
    setTopic("Error");
    setMessage("Please Create a User Role First");
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  }

  const getUserRoles = () => {
    setLoading(true);
    axios.get(`${environment.host}/ums/super/user-role`,
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')}
      })
      .then((response) => {
        if (response.data.message === "Success") {
          setUserRoles(response.data.content.sort((role1, role2) =>
            role1.id - role2.id
          ));
        } 
        setLoading(false) 
      })
  }

  const runUseEffect = () => {
    if (localStorage.getItem('userRoleCreated')) {
      setIsUserRoleCreated(true);
    }
  }

  const userRoleClick = () => {
    setIsUserClick(true)
    setUserType('userRole')
    console.log('userRole')
  }

  useEffect(() => {
    if (step4Completed !== undefined) {
      const userCreated = step4Completed === 2;
      setIsUserCreated(userCreated);
      console.log('step4Completed:', userCreated);
    }
    runUseEffect();
    getUserRoles();
  }, [step4Completed]);

  return (
    <div className={`testing`}>
      <div className="page-heading">
        Step 04
      </div>

      <div style={{fontSize: '16px', lineHeight: '24px', marginTop: '50px'}}>
        <h4>User Management System [Optional]</h4>
      </div>
      {isUserClick == false ?
        (
          <div style={{marginTop: '50px'}}>
            <Grid container>
            <Grid item xs ={2} md={2}></Grid>
            <Grid item xs ={8} md={8}>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
              <div className={classes.options}  onClick={() => userRoleClick()}>
                <div>
                <UserRoleIcon fillColor1={primaryBlue} strokeColor={primaryBlue} fillColor2={primaryBlue} />
                <p>Add User Role</p>
                </div>
              </div>

              {isUserRoleCreated || isUserCreated ?
                (
                  <div className={classes.options} onClick={() => {
                    setIsUserClick(true), setUserType('user')
                  }}>
                    <div>
                    <UserIcon color={primaryBlue} strokeOpacity={0.5}/>
                      <p>Add User</p>
                    </div>
                  </div>
                )
                :
                (
                  <div className={classes.disabledOptions} onClick={() => {
                    setIsUserClick(false);
                    showToastMessage();
                  }}>
                    <div>
                    <UserIcon color={primaryBlue} strokeOpacity={0.5}/>
                      <p>Add User</p>
                    </div>
                  </div>
                )
              }
            </div>
            </Grid>
            <Grid item xs ={2} md={2}></Grid>
            </Grid>
            <Grid container>
            <Grid item xs ={2} md={2}></Grid>
            <Grid item xs ={8} md={8}>
            <div className={classes.buttonContainer}>
              <Link to="">
                <Button
                  className={classes.getStarted}
                  style={{textTransform: "none"}}
                >
                  BACK
                </Button>
              </Link>
            </div>
            </Grid>
            <Grid item xs ={2} md={2}></Grid>
            </Grid>
          </div>
        )
        :
        userType == 'userRole' ?
          <AddUserRoleComponent setIsUserClick={setIsUserClick} userRoles={userRoles} getUserRoles={getUserRoles}
                                setAlertOpen={setAlertOpen} setSeverity={setSeverity} setTopic={setTopic}
                                setMessage={setMessage} isAddUserRoleCompleted={() => runUseEffect()}/> :
          <AddUserComponent setIsUserClick={setIsUserClick}/>
      }
      <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
      <Loader loading={loading}/>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: "0.55s",
    textAlign: "left",
    margin: "30px 100px 0px 100px",
  },
  container: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center'
  },
  getStarted: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      
    },
    width: '161px',
    height: '40px',
    lineHeight:"24px",
    fontSize:"16px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '40px',
  },
  options: {
    width: 'calc((100% - 40px ) / 2)',
    height: '400px',
    borderRadius: '5px',
    backgroundColor:white,
    boxShadow: '0 2px 10px #0000000A',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: primaryBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.8
    },
    margin: 0,
  },
  disabledOptions: {
    width: 'calc((100% - 40px ) / 3)',
    height: '400px',
    borderRadius: '5px',
    backgroundColor: '#D9D9D9',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: "#555555",
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.8
    },
    margin: 0,
  },
  type: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '45px',
    backgroundColor: '#2A7CED26',
    height: '29px',
    fontSize: 16,
    color: '#000000',
    lineHeight: 24,
    alignItems: 'center',
    borderRadius: '5px',
  },
  pageHeading: {
    borderRadius: '6px',
    width: '200px',
    top: '30px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 8px #0000000A',
    border: '0.48px solid #EDEDED',
    padding: '10px 20px 4px',
    color: '#324054',
    fontWeight: 500,
    textAlign: 'center'
  },
  
}));



