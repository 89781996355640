import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import {createStyles, makeStyles, Theme, withStyles,alpha} from '@material-ui/core/styles';
import {SearchBar} from 'src/components/sub-components/SearchBar';
import {environment} from 'src/environments/environment';
import {primaryBlue, white} from 'src/components/colors';
import {AddNewRoleAccordingList} from './AddNewRoleAccordingList';
import {AddIcon, DeleteIcon, EditIcon, ExpandMoreIconFill} from 'src/components/Icons';
import {PopUp} from './PopUp';
import {DeletePrivilege} from './DeletePrivilege';
import {AlertBar} from '../step-3/components/AlertBar';
import {Loader} from '../step-3/components/Loader';
import axios from 'axios';
import useStyles from "src/app/maintenance/assets/styles";
import Cookies from "js-cookie";

export function AddUserRoleComponent(props) {
  const classes = localStyle()
  const style = useStyles()
  const [hidden, setHidden] = useState(false)
  const [allPrivileges, setAllPrivileges] = useState([])
  const [allActions, setAllActions] = useState([])
  const [currentPrivileges, setCurrentPrivileges] = useState([])
  const [search, setSearch] = useState("")
  const [selectAll, setSelectAll] = useState([])
  const [open, setOpen] = useState(false);
  const [popUpType, setPopUpType] = useState();
  const [parentId, setParentId] = useState();
  const [defaultValues, setDefaultValues] = useState({name: "", id: "", description: "", actions: []})
  const [content, setContent] = useState([])
  const [allPrivilege, setAllPrivilege] = useState([])
  const [hover, setHover] = React.useState(-1)

  const [idError, setIdError] = useState({error: false, errorMsg: ""})
  const [nameError, setNameError] = useState({error: false, errorMsg: ""})
  const [privilegeError, setPrivilegeError] = useState({error: false, errorMsg: ""})

  const [selectedName, setSelectedName] = useState("")
  const [selectedId, setSelectedId] = useState("")
  const [deleteId, setDeleteId] = useState("")
  const [selectedDescription, setSelectedDescription] = useState("")

  //alert states
  const [alertOpen, setAlertOpen] = useState(false)
  const [severity, setSeverity] = useState("success")
  const [topic, setTopic] = useState("")
  const [message, setMessage] = useState("")
  const [isSelectAllPrivileges, setIsSelectAllPrivileges] = useState(false)

  const [selectedPrivilege, setSelectedPrivilege] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectChild, setSelectChild] = useState([])

  //Add another option
  const [isAddAnotherOption, setIsAddAnotherOption] = useState(false)

  //loading state
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState(-1)
  const openEdit = (i) => {
    if (i === show) {
      setShow(-1)
    } else {
      setShow(i)
    }
  }
  const onHover = (id) => {
    setHover(id)
  }
  const removeHover = () => {
    setHover(-1)
  }

  const openAddNew = (e, i, type, defaultVal) => {
    e.stopPropagation();
    setPopUpType(type)
    setParentId(i)
    setDefaultValues(defaultVal)
    setOpen(!open)
  }

  const clearForm = () => {
    let all = [...allPrivileges]
    console.log('clear form')
    setSelectedName("")
    setSelectedId("")
    setCurrentPrivileges([])
    all.map(privilege => {
      if (privilege != null) {
        privilege.selected = false
        if (privilege.actions) {
          privilege.actions.map(action => action.selected = false)
        }
      }
      idFinder(privilege, all)
      ids.map(privilege => {
        if (privilege != null) {
          privilege.selected = false
          if (privilege.actions) {
            privilege.actions.map(action => action.selected = false)
          }
        }

      })
    })

    console.log(all)
    setAllPrivileges(all)
    setSelectedDescription("")
    setIsAddAnotherOption(false)
  }

  const closeAddNew = () => {
    setOpen(false)
    getPrivileges()
  }

  const closeCancel = () => {
    setOpen(false)
  }

  //getAll privileges function
  let newAllPrivilege = []
  let allNewPrivileges = []
  let all = []
  const getPrivileges = () => {
    setLoading(true);
    axios.get(`${environment.host}/ums/super/user-privileges`,
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")}
      })
      .then((response) => {
        if (response.data.message === "Success") {

          let actions = []
          response.data.content.map((privilege) => {
            if (privilege != null) {
              if (privilege.actions && Array.isArray(privilege.actions)) {
                privilege.actions.map((action) => {
                  if (!actions.includes(action.action_name)) {
                    actions.push(action.action_name)
                  }
                })
              }

              if (privilege.child.length == 0) {
                privilege.child.map(c => {
                  if (c.actions) {
                    c.actions.map(a => a.selected = null)
                  }
                })
              }
              pushAllActions(privilege.child, actions)
            }

          })
          setAllActions(actions);

          response.data.content.map(p => {
            if (p != null) {
              p.selected = false
              if (p.actions) {
                p.actions.map(a => a.selected = false)
              }
              allNewPrivileges.push(p)
              all.push(p)
              idFinder(p, response.data.content)
            }

          })
          ids.map(p => {
            if (p != null) {
              p.selected = false
              if (p.actions) {
                p.actions.map(a => a.selected = false)
              }
              if (!allNewPrivileges.some(priv => priv.id == p.id)) {
                allNewPrivileges.push(p)
              }

            }

          })
          setSelectAll(allNewPrivileges)
          setAllPrivileges(all);
          newAllPrivilege = response.data.content;
          setLoading(false)
          if (props.type == "edits") {
            getCurrentPrivilege()
          }
        }
      })
      .catch((error) => {
        setAlertOpen(true)
          setSeverity("error")
          setTopic("Error")
          setMessage(error.response.data.message)
          setLoading(false)
          setTimeout(() => {
            setAlertOpen(false)
          }, 6000)
      })
  }
  //set All actions function
  const pushAllActions = (privileges, array) => {
    privileges.forEach((privilege) => {
      if (privilege != null) {
        if (privilege.actions && Array.isArray(privilege.actions)) {
          privilege.actions.forEach((action) => {
            if (!array.includes(action.action_name)) {
              array.push(action.action_name);
            }
          });
          pushAllActions(privilege.child, array);
        } else {
        }
      }

    });
  };


  let ids = []
  const idFinder = (privilege, allPrivileges) => {
    allPrivileges.map((p) => {
      if (p != null) {
        idFinder(privilege, p.child)
        if (privilege != null && privilege.id == p.id) {
          privilege.child.map(child => {
            ids.push(child)
            idFinder(child, privilege.child)
          })

        }
      }

    })
  }


  //select privilege
  const selectPrivileges = (privilege) => {
    setPrivilegeError({ error: false, errorMsg: "" });

    idFinder(privilege, allPrivileges);
    findParent1(privilege, selectAll);

    let oldPrivileges = [...currentPrivileges];
    let parentIds = [];

    parent1.forEach(p => {
        if (p && !parentIds.some(item => item.id === p.id)) {
            parentIds.push(p);
        }
    });

    let existingPrivilegeIndex = oldPrivileges.findIndex(p => p.id === privilege.id);

    if (existingPrivilegeIndex !== -1) {
        handleExistingPrivilege(privilege, oldPrivileges, parentIds, existingPrivilegeIndex);
    } else {
        handleNewPrivilege(privilege, oldPrivileges, parentIds);
    }

    setCurrentPrivileges(oldPrivileges);
};

  
  const handleExistingPrivilege = (privilege, oldPrivileges, parentIds, existingPrivilegeIndex) => {
    if (privilege.selected === false || privilege.selected === 'partial') {
      selectPrivilege(privilege, oldPrivileges, parentIds, existingPrivilegeIndex);
    } else if (privilege.selected === true) {
      deselectPrivilege(privilege, oldPrivileges, parentIds, existingPrivilegeIndex);
    }
  };
  
  const selectPrivilege = (privilege, oldPrivileges, parentIds, index) => {
    privilege.selected = true;
  
    if (privilege.actions) {
      privilege.actions.forEach(a => a.selected = true);
    }
  
    oldPrivileges[index] = privilege;
  
        //select child
        ids.forEach(child => {
          if(child){
        child.selected = true;
        if (child.actions) {
              child.actions.forEach(action => {
                action.selected = true
              })
        }
        updateOrAddPrivilege(child, oldPrivileges);
      }
    });
  
    // Update parent privileges
    updateParentPrivileges(parentIds, oldPrivileges);
  };
  
  const deselectPrivilege = (privilege, oldPrivileges, parentIds, index) => {
    privilege.selected = false;
    setIsSelectAllPrivileges(false);
  
    if (privilege.actions) {
      privilege.actions.forEach(a => a.selected = false);
    }
  
    // Remove the privilege from oldPrivileges
    oldPrivileges.splice(index, 1);
  
    // Deselect child privileges
    ids.forEach(child => {
      if (child) {
        child.selected = false;
        if (child.actions) {
          child.actions.forEach(action => action.selected = false);
        }
        oldPrivileges = oldPrivileges.filter(p => p.id !== child.id);
      }
    });
  
    // Update parent privileges
    updateParentPrivileges(parentIds, oldPrivileges);
  };
  
  const handleNewPrivilege = (privilege, oldPrivileges, parentIds) => {
    privilege.selected = true
    if (privilege.actions) {
      privilege.actions.forEach(action => action.selected = true)
    }
      oldPrivileges.push(privilege)
  
    // Select child privileges
    ids.forEach(child => {
      if (child) {
        child.selected = true;
        if (child.actions) {
          child.actions.forEach(action => action.selected = true);
        }
        updateOrAddPrivilege(child, oldPrivileges);
      }
    });
  
    // Update parent privileges
    updateParentPrivileges(parentIds, oldPrivileges);
  };
  
  const updateOrAddPrivilege = (privilege, oldPrivileges) => {
    let index = oldPrivileges.findIndex(p => p.id === privilege.id);
    if (index !== -1) {
      oldPrivileges[index] = privilege;
    } else {
      oldPrivileges.push(privilege);
    }
  };
  
  const updateParentPrivileges = (parentIds, oldPrivileges) => {
    parentIds.forEach(parent => {
      if (parent) {
        parent.child.forEach((child, index) => {
          if (child && oldPrivileges.some(p => p.id === child.id)) {
            let matchChildPrivilege = oldPrivileges.filter(p => p.id === child.id);
            parent.child[index] = matchChildPrivilege[0];
          }
        });
  
        const allChildSelect = parent.child.every(child => child && child.selected === true);
        const partialChildSelect = parent.child.some(child => child && (child.selected === true || child.selected === 'partial'));
  
        let parentIndex = oldPrivileges.findIndex(p => p.id === parent.id);
        if (allChildSelect) {
          parent.selected = true
        } else if (partialChildSelect) {
          parent.selected = 'partial'
        } else {
          parent.selected = false;
        }
        updateOrAddPrivilege(parent, oldPrivileges);
      }
    });
  };
  
  let allPrivilegeIds = []
  const selectAllPrivileges = (allPrivileges) => {
    setPrivilegeError({error: false, errorMsg: ""});
    if (!isSelectAllPrivileges) {
      setIsSelectAllPrivileges(true)
      allPrivileges.forEach(privilege => {
        if (privilege) {
          privilege.selected = true
          if (privilege.actions) {
            privilege.actions.forEach(a => {
              a.selected = true
            })
          }
          if (!allPrivilegeIds.some(priv => priv.id == privilege.id)) {
            allPrivilegeIds.push(privilege)
          }

          selectAllPrivileges(privilege.child)
        }

      })
      setCurrentPrivileges(allPrivilegeIds)
    } else {
      setIsSelectAllPrivileges(false)
      currentPrivileges.forEach(p => {
        if (p) {
          p.selected = false
          if (p.actions) {
            p.actions.forEach(a => a.selected = false)
          }
        }

      })
      setCurrentPrivileges([])
    }
  }
  //Function to read privilege name
  const readName = (e) => {
    const enteredValue = e.target.value;
    setSelectedName(enteredValue);

    const trimmedValue = enteredValue.trim();
    if (trimmedValue === "") {
      setNameError({error: true, errorMsg: "Name is required"});
    } else {
      setNameError({error: false, errorMsg: ""});
    }
  }

  //Function to read new privilege id
  const readId = (e) => {
    if (props.type == "edits") {
      if (e.target.value === undefined || e.target.value === "") {
        setSelectedId(props.userRoleId)
      }
    }

    setSelectedId(e.target.value)
    if (e.target.value.trim() === "") {
      setIdError({error: true, errorMsg: "ID is required"});
    } else {
      setIdError({error: false, errorMsg: ""});
    }
  }
  //Function to read description
  const readDescription = (e) => {
    setSelectedDescription(e.target.value)
  }

  //Submit data
  const submit = () => {
    const errors = [];

    const addError = (field, message) => {
      errors.push({field, message});
    };
    const trimmedName = selectedName.trim();
    const trimmedDescription = selectedDescription.trim();
    const data = {
      id: parseInt(selectedId),
      name: trimmedName,
      description: trimmedDescription,
      userPrivileges: currentPrivileges
    }
    console.log(data.id)

    if (currentPrivileges.length == 0 || !currentPrivileges.some(p => p.selected === true)) {
      addError("privilege", "Please select atleast one privilege")
    }
    if (Number.isNaN(data.id) && selectedId == "") {
      addError("id", "Id is required");
    }
    if (Number.isNaN(data.id) && selectedId != "") {
      addError("id", "ID Should Contain Only Numbers")
    }

    if (data.name === undefined || data.name === "") {
      addError("name", "Name is required");
    }
    // }

    if (errors.length > 0 || idError.error === true || nameError.error === true || privilegeError.error === true) {
      errors.forEach((error) => {
        if (error.field === "name") {
          setNameError({ error: true, errorMsg: error.message });
        } else if (error.field === "id") {
          setIdError({ error: true, errorMsg: error.message });
        } else if (error.field === "privilege") {
          setPrivilegeError({ error: true, errorMsg: error.message });
        }
      });
      return;
    }
    console.log(data)
    apiRequest(data, "POST");
  }

  const handleDeleteIconClick = (id, privilege) => {
    setDeleteId(id);
    setSelectedPrivilege(privilege)
    idFinder(privilege, content)
    setDeleteDialogOpen(true);
  };

  const handleDeletePrivilegeClose = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  const apiRequest = (body, method) => {
    setLoading(true)
    let url = `${environment.host}/ums/super/user-role`
    if (method === "PUT") {
      url = url + "/" + props.userRoleId //When changing the id request parameter id should be the old id
    }
    axios({
        url,
        method: method,
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")},
        data: body
    })
      .then((response) => {
          setLoading(false)
          // props.onClose()
          props.getUserRoles()
          props.setAlertOpen(true)
          props.setSeverity("success");
          props.setTopic("Success");
          if (isAddAnotherOption) {
            clearForm()
            console.log('Add another options')
            localStorage.setItem('userRoleCreated', 'true')
            props.setMessage("Role Added Successfully.")

          } else {
            if (method == "POST") {
              props.setMessage("Role Added Successfully.")
              localStorage.setItem('userRoleCreated', 'true')
              props.setIsUserClick(false)
              props.isAddUserRoleCompleted()

            } else if (method == "PUT") {
              props.setMessage("Successfully Updated User Role: " + body.name)
            }
          }


          setTimeout(() => {
            props.setAlertOpen(false)

          }, 5000)
      })
      .catch((error) => {
        props.setAlertOpen(true)
          props.setSeverity("error")
          props.setTopic("Error")
          props.setMessage(error.response.data.message)
          setLoading(false)
          setTimeout(() => {
            props.setAlertOpen(false)
          }, 6000)
      })
  }
  useEffect(() => {
    getPrivileges()
}, [])

  const getCurrentPrivilege = () => {
    axios.get(`${environment.host}/ums/super/user-role/${props.userRoleId}`,
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")}
      })
      .then((response) => {
          let newCurrent = []
          let current = response.data.content.userPrivileges.sort((a, b) => b.id - a.id)
          current.forEach(p => {
            let parent = current.filter(priv => priv.id == parseInt(p.parentId))
            if (p.parentId != 'high-privilege') {
              if (parent.length > 0) {
                if (!newCurrent.some(priv => priv.id == p.id)) {

                  if (!newCurrent.some(p => p.id == parent[0].id)) {
                    newCurrent.push(p)
                    newCurrent.push(parent[0])
                  } else {
                    let parentIndex = newCurrent.findIndex(p => p.id == parent[0].id)
                    newCurrent.splice(parentIndex, 0, p)
                  }

                } else {
                  if (!newCurrent.some(p => p.id == parent[0].id)) {
                    newCurrent.push(parent[0])
                  }
                }
              }
            } else {
              if (!newCurrent.some(priv => priv.id == p.id)) {
                newCurrent.push(p)
              }
            }
          })
          newCurrent.map((p, index) => {
            if (p.child.length > 0) {
              p.child.map((child, index) => {
                if (response.data.content.userPrivileges.some(p => p.id === child.id)) {
                  let matchChildPrivilege = response.data.content.userPrivileges.filter(p => p.id === child.id)
                  p.child[index] = matchChildPrivilege[0]
                } else {
                  child.selected = false
                }
              })
              const allChildSelect = p.child.every(p => p.selected == true)
              const partiallySelect = p.child.some(p => p.selected == true || p.selected == 'partial');

              //find actions selection
              if (p.actions) {

                if (p.actions.every(a => a.selected == true)) {
                  if (allChildSelect) {
                    p.selected = true
                  } else {
                    p.selected = 'partial'
                  }
                } else {
                  p.selected = 'partial'
                }
              } else {
                if (allChildSelect) {
                  p.selected = true

                } else if (partiallySelect) {
                  p.selected = 'partial'
                }
              }
            } else {
              if (p.actions) {
                if (p.actions.every(a => a.selected == true)) {
                  p.selected = true
                } else if (p.actions.some(a => a.selected == true)) {
                  p.selected = 'partial'
                }
              }

            }

            newCurrent[index] = p
          })
          setCurrentPrivileges(newCurrent)

          let newData = newAllPrivilege.map(p => {
            let matchedUserPrivilege = newCurrent.filter(cp => cp.id === p.id);
            if (matchedUserPrivilege.length > 0) {
              let matchActions
              if (matchedUserPrivilege[0].actions) {
                matchActions = matchedUserPrivilege[0].actions.filter(action => {
                  return p.actions.some(pAction => pAction.action_name === action.action_name)
                })
              }
              matchedUserPrivilege[0].actions = matchActions
              return matchedUserPrivilege[0];
            } else {
              p.selected = false
              return p;
            }
          });
          setAllPrivileges(newData)
      })
      .catch((error) => {
        props.setAlertOpen(true)
        props.setSeverity("error")
        props.setTopic("Error")
        props.setMessage(error.response.data.message)
        setTimeout(() => {
          setAlertOpen(false)
        }, 6000)
      })
  }


  //select privilege using actions
  const selectActionsFrom = (privilege, action) => {
    findParent1(privilege, selectAll);
    
    // Get all parent privileges
    let parentIds = [];
    parent1.forEach(p => {
        if (p && !parentIds.some(item => item.id === p.id)) {
            parentIds.push(p);
        }
    });

    idFinder(privilege, allPrivileges);
    let oldCurrentPrivilege = [...currentPrivileges];
    
    // Filter selected privilege
    let filterPrivilege = oldCurrentPrivilege.filter(p => p.id === privilege.id);
    
    if (filterPrivilege.length > 0) {
        parentIds.forEach((p, index) => {
            let parent = oldCurrentPrivilege.find(priv => priv.id === p.id);
            if (parent) {
                parentIds[index] = parent;
            }
        });

        let selectPrivilegeIndex = oldCurrentPrivilege.findIndex(p => p.id === privilege.id);

        if (action.selected === false || action.selected === null) {
            action.selected = true;
            let actionIndex = privilege.actions.findIndex(a => a.action_name === action.action_name);
            privilege.actions[actionIndex] = action;

            if (privilege.actions.every(a => a.selected === true)) {
                privilege.selected = privilege.child.length === 0 ? true : privilege.child.every(pri => pri.selected === true) ? true : 'partial';
                oldCurrentPrivilege[selectPrivilegeIndex] = privilege;
            } else {
                privilege.selected = 'partial';
                oldCurrentPrivilege[selectPrivilegeIndex] = privilege;
            }

            // Update parent privileges
            parentIds.forEach(parent => {
                if (parent && parent.child) {
                    parent.child.forEach((child, index) => {
                        if (child && oldCurrentPrivilege.some(p => p.id === child.id)) {
                            let matchChildPrivilege = oldCurrentPrivilege.find(p => p.id === child.id);
                            parent.child[index] = matchChildPrivilege;
                        }
                    });

                    let parentIndex = oldCurrentPrivilege.findIndex(p => p.id === parent.id);
                    const allParentUnSelect = parent.child.every(child => child && child.selected === true);
                    const partialChildSelect = parent.child.some(child => child && child.selected === true);

                    parent.selected = allParentUnSelect
                        ? (parent.actions ? (parent.actions.every(a => a.selected === true) ? true : 'partial') : true)
                        : (partialChildSelect ? 'partial' : parent.selected);

                    oldCurrentPrivilege[parentIndex] = parent;
                }
            });
        } else if (action.selected === true) {
            action.selected = false;
            setIsSelectAllPrivileges(false);
            let actionIndex = privilege.actions.findIndex(a => a.action_name === action.action_name);
            privilege.actions[actionIndex] = action;

            if (privilege.actions.every(a => a.selected === false)) {
                if (privilege.child.length === 0) {
                    privilege.selected = false;
                    oldCurrentPrivilege = oldCurrentPrivilege.filter(p => p.id !== privilege.id);
                } else {
                    privilege.selected = privilege.child.every(child => child && child.selected === false) ? false : 'partial';
                    oldCurrentPrivilege = privilege.selected === false ? oldCurrentPrivilege.filter(p => p.id !== privilege.id) : oldCurrentPrivilege;
                }
            } else {
                privilege.selected = 'partial';
                oldCurrentPrivilege[selectPrivilegeIndex] = privilege;
            }

            // Update parent privileges
            parentIds.forEach(parent => {
              if (parent && parent.child) {
                parent.child.forEach((child, index) => {
                    if (child && oldCurrentPrivilege.some(p => p.id === child.id)) {
                        let matchChildPrivilege = oldCurrentPrivilege.find(p => p.id === child.id);
                        parent.child[index] = matchChildPrivilege;
                    }
                });

                let parentIndex = oldCurrentPrivilege.findIndex(p => p.id === parent.id);
                const allParentUnSelect = parent.child.every(child => child && child.selected === false);
                const partialChildSelect = parent.child.some(child => child && (child.selected === false || child.selected === 'partial'));
                let parentMatch = allPrivileges.findIndex(p => p.id === parent.id);

                parent.selected = allParentUnSelect
                  ? (parent.actions ? (parent.actions.every(a => a.selected === false) ? false : 'partial') : false)
                  : (partialChildSelect ? 'partial' : parent.selected);

                if (parent.selected === false) {
                  oldCurrentPrivilege = oldCurrentPrivilege.filter(p => p.id !== parent.id);
                  allPrivileges[parentMatch] = parent;
                } else {
                  oldCurrentPrivilege[parentIndex] = parent;
                }
            }
          });
        }
      } else {
        action.selected = true;
        let actionIndex = privilege.actions.findIndex(a => a.action_name === action.action_name);
        privilege.actions[actionIndex] = action;

        privilege.selected = privilege.actions.every(a => a.selected === true) && privilege.child.length === 0 ? true : 'partial';
        oldCurrentPrivilege.push(privilege);

        // Update parent privileges
        parentIds.forEach(parent => {
          if (oldCurrentPrivilege.some(p => p.id === parent.id)) {
            let parentPrivilege = oldCurrentPrivilege.find(p => p.id === parent.id);
            parent = parentPrivilege;

            if (parent.child) {
              parent.child.forEach((child, index) => {
                  if (child && oldCurrentPrivilege.some(p => p.id === child.id)) {
                      let matchChildPrivilege = oldCurrentPrivilege.find(p => p.id === child.id);
                      parent.child[index] = matchChildPrivilege;
                  }
              });

              let parentIndex = oldCurrentPrivilege.findIndex(p => p.id === parent.id);
              const allParentUnSelect = parent.child.every(child => child && child.selected === true);
              const partialChildSelect = parent.child.some(child => child && child.selected === true);

              parent.selected = allParentUnSelect
                  ? (parent.actions ? (parent.actions.every(a => a.selected === true) ? true : 'partial') : true)
                  : (partialChildSelect ? 'partial' : parent.selected);

              oldCurrentPrivilege[parentIndex] = parent;
            }
          } else {
            parent.selected = 'partial';
            oldCurrentPrivilege.push(parent);
          }
          if (props.type === 'edits') {
            allPrivileges.forEach((p, index) => {
                if (parent.id === p.id) {
                  allPrivileges[index] = parent;
                }
            });
          }
        });
      }
      setCurrentPrivileges(oldCurrentPrivilege);
  }

  const handleAnotherOption = () => {
    let isClick = isAddAnotherOption
    console.log(!isClick)
    setIsAddAnotherOption(!isAddAnotherOption)
  }

  let parent1 = [];
  const findParent1 = (privilege, allPrivileges) => {

    let filtered = allPrivileges.filter(p => p.id === privilege.parentId);
    filtered.forEach(p => {
      if (p != null) {
        parent1.push(p)
      }
    });

    if (filtered.length > 0 && filtered[0].parentId !== "high-privilege") {
      let grandParent = findParent1(filtered[0], allPrivileges);
      parent1.push(grandParent);
    }
  }

  const filterPrivileges = (privileges, search) => {
    if (!privileges) return [];
  
    return privileges.filter((privilege) => {
      if (!privilege || !privilege.name) return false;
  
      const matches = privilege.name.toLowerCase().includes(search.toLowerCase());
      const childMatches = privilege.child && privilege.child.length > 0 && filterPrivileges(privilege.child, search).length > 0;
  
      return matches || childMatches;
    }).map((privilege) => {
      if (!privilege) return null;
  
      return {
        ...privilege,
        child: privilege.child && privilege.child.length > 0 ? filterPrivileges(privilege.child, search) : []
      };
    });
  };

  const filteredPrivileges = filterPrivileges(allPrivileges, search);

  return (
    <div>
      <div style={{fontSize: '16px', lineHeight: '24px', marginTop: '15px'}}>
        <h4>Add User Role</h4>
      </div>
      <Grid container style={{textAlign: "left"}}>

        <Grid item xs={12} md={12} style={{backgroundColor: '#fafafa'}}>
          <Paper className={classes.paperContainer} style={{backgroundColor: '#fafafa'}}>
            <Grid container alignItems='center'>
              <Grid item xs={12} md={4} style={{padding: "15px", height: '140px'}}>
                <Typography className={classes.text}>Enter ID</Typography>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={(e) => readId(e)}
                    value={selectedId}
                  />
                  {idError.error ?
                    <Typography
                      style={{
                        color: '#f44336',
                        padding: '5px',
                        fontFamily: 'poppins',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        display: 'contents'
                      }}>
                      {idError.errorMsg}
                    </Typography>
                    :
                    <></>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{padding: "15px", height: '140px'}}>
                <Typography className={classes.text}>Enter Role Name</Typography>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={(e) => readName(e)}
                    value={selectedName}
                  />
                  {nameError.error ?
                    <Typography
                      style={{
                        color: '#f44336',
                        padding: '5px',
                        fontFamily: 'poppins',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        display: 'contents'
                      }}>
                      {nameError.errorMsg}
                    </Typography>
                    :
                    <></>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{padding: "15px", height: '140px'}}>
                <Typography className={classes.text}>Enter Description</Typography>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={(e) => readDescription(e)}
                    value={selectedDescription}
                  />
                </FormControl>
              </Grid>

              <CustomAccordion expanded={hidden} elevation={0} TransitionProps={{unmountOnExit: true}}
                               className={classes.highPrivilegeAccording} style={{backgroundColor: '#fff'}}>
                <AccordionSummary
                  style={{height: "70px", display: "flex", justifyContent: "center", backgroundColor: '#fafafa'}}
                  expandIcon={<ExpandMoreIconFill fill={'#5E5C5C'}/>} onClick={() => setHidden(!hidden)}>
                  <Grid item xs={1} md={2}>
                    <Typography className={classes.text}>Privileges</Typography>
                  </Grid>
                  <Grid item md={10} style={{paddingTop: '15px'}}>
                    <Divider/>
                  </Grid>
                </AccordionSummary>
              </CustomAccordion>

              {
                hidden === false ?
                  <>
                    <Grid container style={{padding: "0px 15px"}}>
                      <Grid item xs={6} md={6} style={{padding: "16px 0px"}}>
                    <Grid container>
                        <Grid item  style={{width:"200px"}}>
                            <SearchBar onChange={(e) => setSearch(e.target.value)}/>
                        </Grid>

                        </Grid>
                      </Grid>
                    <Grid item xs={6} md={6} style={{padding: "16px 0px"}}>
                        <Grid container justifyContent='flex-end'>
                        <Grid item  onClick={(e) => openAddNew(e, undefined, "add-high", undefined)}>
                            <button className={style.saveButton} style={{
                              paddingTop: '8px',
                              paddingBottom: '8px',
                              border: 'none',
                              cursor: 'pointer'
                            }}>
                              Add High Privilege
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid container className={classes.tableHeader}>
                      <Grid item xs={6} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                        
                        <Checkbox
                          className={classes.checkbox}
                          style={{ cursor: 'pointer' }} 
                                  checked={currentPrivileges.length == selectAll.length && currentPrivileges.every(p => p.selected == true) ? true : false}
                          onClick={() => selectAllPrivileges(allPrivileges)} 
                        />
                        <Typography className={classes.tableHeaderText}>
                          Privilages
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={8} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography className={classes.tableHeaderText} style={{ paddingLeft: "50px" }}>Actions</Typography>
                    </Grid>
                    </Grid>
                    <Grid container style={{marginBottom: "16px", padding:"0px 16px"}}>
                      {
                        allPrivileges.filter((obj) => {
                          if (search === "") {
                            return obj
                          } else if (obj.name.toLowerCase().includes(search.trim().toLowerCase())) {
                            return obj
                          }
                        }).map((privilege, index) => {
                          const parentBackgroundColor = index % 2 === 0 ? "#F4F4F4": white;
                          return (
                            <CustomAccordion key={index} expanded={index == show} elevation={0}
                                             TransitionProps={{unmountOnExit: true}}
                                             className={classes.highPrivilegeAccording}>
                              <AccordionSummary style={{
                                height: "70px",
                                paddingRight: privilege.child.length == 0 ? '25px' : '0px',
                                backgroundColor:parentBackgroundColor,
                                color: '#5E5C5C'
                              }} expandIcon={privilege.child.length > 0 ?
                                <ExpandMoreIconFill fill={'#5E5C5C'} /> : <></>}
                                                onClick={() => {
                                                  openEdit(index)
                                                }}>
                                <Grid container>
                                  <Grid item xs={3} md={3}>
                                    <Grid container>
                                      <Grid item style={{display: "flex", alignItems: "center"}}>
                                        <Checkbox className={classes.checkbox}
                                                  onChange={() => selectPrivileges(privilege)}
                                                  checked={privilege.selected == true}
                                                  onClick={(e) => {
                                                    e.stopPropagation(), selectPrivileges(privilege)
                                                  }}
                                                  indeterminate={(privilege.selected == 'partial')}/>
                                      </Grid>
                                      <Grid item xs={10} md={10} style={{display: "flex", alignItems: "center"}}>
                                        <Tooltip title={privilege.name} placement="bottom-start" arrow>
                                          <Typography
                                            style={{textTransform: 'capitalize'}}>{privilege.name.length > 10 ? (privilege.name.substring(0, 10) + "...").replace("_", " ") : privilege.name.replace("_", " ")}</Typography>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid key={index} item md={7} xs={7} style={{display: 'flex', overflowX: 'auto'}}>
                                    {privilege.actions ?
                                      privilege.actions.map((action, actionIndex) => {
                                        return <Grid key={actionIndex} item md={4} xs={4} style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          fontFamily: 'poppins',
                                          marginRight: '10px',
                                          maxWidth: '150px'
                                        }}>
                                          <Grid container>
                                            <Grid item>
                                              <Checkbox className={classes.checkbox}
                                                        onChange={() => selectActionsFrom(privilege, action)}
                                                        checked={action.selected == true}
                                                        onClick={(e) => {
                                                          e.stopPropagation(), selectActionsFrom(privilege, action)
                                                        }}
                                                        indeterminate={(action.selected == 'partial')}/>
                                            </Grid>
                                            <Grid item style={{display: 'flex', alignItems: 'center'}}>
                                              <Typography
                                                style={{fontFamily: 'poppins'}}>{action.action_name}</Typography>
                                            </Grid>
                                          </Grid>


                                        </Grid>
                                      }) : <></>

                                    }
                                  </Grid>

                                  <Grid item xs={2} md={2}
                                        style={{display: 'flex', alignItems: 'start', marginTop: "10px"}}>
                                    <Grid container justifyContent='flex-end' spacing={1}>
                                      <Grid item data-toggle='tooltip' title='Edit'
                                            onClick={(e) => openAddNew(e, privilege.id, "edits", {
                                              name: privilege.name,
                                              id: privilege.id,
                                              description: privilege.description,
                                              actions: privilege.actions
                                            })}
                                      >
                                        <EditIcon stroke={"#5E5C5C"}/>
                                      </Grid>
                                      <Grid item data-toggle='tooltip' title='Delete'
                                            onClick={() => {
                                              handleDeleteIconClick(privilege.id, privilege)
                                            }}
                                      >
                                        <DeleteIcon/>
                                      </Grid>
                                      <Grid item style={{display: 'absolute', justifyContent: 'end', marginTop: '1px'}}>
                                        {hover === privilege.id ?
                                          <Typography style={{
                                            fontSize: '12px',
                                            fontFamily: 'poppins',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            backgroundColor: white,
                                            color: '#5E5C5C',
                                            minWidth: '100px'
                                          }}>Add Sub privilege</Typography>
                                          : <></>}
                                      </Grid>
                                      {privilege.child.length < 5 ?
                                        <Grid item style={{display: 'flex', justifyContent: 'end'}}
                                              onClick={(e) => openAddNew(e, privilege.id, "add-new", undefined)}
                                              onMouseEnter={() => onHover(privilege.id)}
                                              onMouseLeave={() => removeHover()}
                                        >
                                          <AddIcon fill={'none'} opacity={1}/>


                                        </Grid> :

                                        <Grid item style={{display: 'flex', justifyContent: 'end', cursor: 'default' }}data-toggle='tooltip' title='Disabled'>
                                          <AddIcon aria-disabled="true" fill="none" opacity="0.5"/>
                                        </Grid>
                                      }
                                    </Grid>
                                  </Grid>

                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails
                                style={{paddingBottom: "0px", padding: "0px"}}>
                                <AddNewRoleAccordingList allActions={allActions}
                                                         onChange={(privilege) => selectPrivileges(privilege)}
                                                         data={privilege.child} opacity={0.2} padding={0.5}
                                                         openAddNew={openAddNew} hover={hover} onHover={onHover}
                                                         removeHover={removeHover}
                                                         currentPrivileges={currentPrivileges}
                                                         selectActionsFrom={(privilege, action) => selectActionsFrom(privilege, action)}
                                                         onDelete={(id, privilage) => handleDeleteIconClick(id, privilage)} search={search}/>
                              </AccordionDetails>
                            </CustomAccordion>
                          )
                        })
                      }
                    </Grid>
                    {privilegeError.error ?
                      <Typography
                        style={{
                          color: '#f44336',
                          padding: '5px',
                          fontFamily: 'poppins',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          display: 'contents'
                        }}>
                        {privilegeError.errorMsg}
                      </Typography>
                      :
                      <></>
                    }

                  </>
                  : <div></div>
              }
              <Grid container>
               
                <Grid item>
                <Checkbox className={classes.checkbox} checked={isAddAnotherOption} onClick={() => handleAnotherOption()}></Checkbox>
                </Grid>
                <Grid item style={{display: 'flex', alignItems: 'center'}} onClick={() => handleAnotherOption()}>
                  <Typography>Add Another Role</Typography>

                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={8} xs={8}></Grid>
                <Grid item xs={4} md={4} style={{marginTop: "30px"}}>
                  <Grid container justifyContent='flex-end'>
                    <Grid item>
                      <Button variant='contained' className={style.backButton}
                              style={{marginRight:"10px"}}
                              onClick={() => props.setIsUserClick(false)}>Cancel</Button>
                    </Grid>
                    <Grid item>
                      <Button variant='contained' className={style.saveButton}
                              onClick={submit}>{props.type === "edits" ? "Update" : "Add"}</Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <DeletePrivilege
        open={deleteDialogOpen}
        id={deleteId}
        privilege={selectedPrivilege}
        getPrivileges={getPrivileges}
        onClose={handleDeletePrivilegeClose}
        child={selectChild}
        setAlertOpen={props.setAlertOpen}
        setSeverity={props.setSeverity}
        setTopic={props.setTopic}
        setMessage={props.setMessage}
      />

      <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
      <PopUp open={open} onClose={closeAddNew} onCancel={closeCancel} type={popUpType} parentId={parentId}
             defaultValues={defaultValues} allPrivilege={allPrivileges} allIds={allPrivilege}
             getPrivileges={() => getPrivileges()} allPrivileges={selectAll}
             setAlertOpen={props.setAlertOpen} setSeverity={props.setSeverity} setTopic={props.setTopic}
             setMessage={props.setMessage}
      />
      <Loader loading={loading}/>
    </div>
  )
}

const CustomAccordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto"
    },
        '&:before': {
      top: 0,
  },
    
  },
  expanded: {}
})(Accordion);

const localStyle = makeStyles((themes: Theme) =>
  createStyles({
    checkbox:{
      '&.Mui-checked': {
      color: primaryBlue,
    },
   '&:hover': {
    backgroundColor: alpha(primaryBlue, 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
    },
    addNew: {
      fontWeight: "bold"
    },
    paperContainer: {
      width: "100%",
      boxShadow: "none",
      marginTop: "16px",
      marginBottom: "10px"
    },
    text: {
      color: "#707070",
      paddingBottom: "10px"
    },
    tableHeader: {
      fontWeight:"bold",
      color: "black",
      margin: "15px 16px 0px ",
      fontSize:"14px",
      padding:"10px 16px",
      backgroundColor: white
    },
    actionsBar: {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    highPrivilegeAccording: {
      //   backgroundColor: mainAccordingColor,
      width: "100%",
      marginBottom: "1px",
    },
    iconBackground: {
      width: "24px",
      height: "24px",
      borderRadius: "100%",
      backgroundColor: "white"
    },
    input: {
      width: "95%",
    },

   tableHeaderText:{
    fontWeight:"bold",
    fontSize:"14px",
    fontFamily:"poppins"
   }
  }),
);

const WhiteBackgroundCheckbox = withStyles(theme => ({
  root: {
    color: "red",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1
    }
  },
  checked: {}
}))(Checkbox);




