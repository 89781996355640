import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { Grid, InputBase, Button, Typography, IconButton, InputAdornment, Box} from '@material-ui/core';
import { lightGray} from 'src/components/colors';
import { ThemeProvider, createTheme} from '@material-ui/core/styles';
import { DropDown } from '../components/DropDown';
import useStyles from '../../assets/styles';
import { TextBar } from 'src/components/sub-components/TextBar';
import { formatDateString } from 'src/app/testing-automation/api-helper/apiHelper';
import { 
  fetchBatchNumbers,
  fetchMainTestCases, 
  startTesting
} from '../components/apiHelper'; 
import { Loader } from '../components/Loader';
import { ToasterComponent } from '../components/Toaster';
import DeviceConfig from './DeviceConfig';
import {DecreaseIcon, IncreaseIcon, RedirectIcon } from 'src/components/Icons';
import { DateTimeSelectorComponent} from '../components/DateTimeSelector'
import { clickBack, convertToMinutes } from 'src/app/utils';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function StartTestingComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
  const [selectedTestCaseType, setSelectedTestCaseType] = useState('');
  const [streamingMethods, setStreamingMethods] = useState([
    "SenzMatica", "MQTT", "AWS", "Rest API", "Azure IoT"
  ]);
  const [testCaseTypes, setTestCaseTypes] = useState([
    "Production Feasibility Testing", "Continuous Testing"
  ]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
  const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
  const [selectedMainTestCaseTitle, setSelectedMainTestCaseTitle] = useState('');
  const [selectedMainTestCaseId, setSelectedMainTestCaseId] = useState('');
  const [subTestCaseTitles, setSubTestCaseTitles] = useState([]);
  const [selectedSubTestCasesTitles, setSelectedSubTestCasesTitles] = useState([]);
  const [relatedSubTestCases, setRelatedSubTestCases] = useState([]);
  const [filteredSubTestCases, setFilteredSubTestCases] = useState([]);
  const [filteredSubTestCasesIds, setFilteredSubTestCasesIds] = useState([]);
  const [devices, setDevices] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndtDate] = useState(new Date());
  const [responseTime, setResponseTime] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [shedulePeriod, setShedulePeriod] = useState([
      "Minutes", "Hours", "Days"
  ]);
  const [isResponseTimeFixed, setIsResponseTimeFixed] = useState(false);

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  //handel errors
  const [errors, setErrors] = useState({
    streamNameError: null,
    batchNoError: null,
    mainTestNameError: null,
    subTestCasesError: null,
    startDateError: null,
    endDateError: null,
    testingMethodError: null,
    period:null,
    rootCauseNotificationDurationInMinutes:null
  })   
  
  const streamingMethodsMap = {
    "SenzMatica":"SENZMATE", 
    "MQTT":"MQTT",
    "AWS":"AWS", 
    "Rest API":"RESTAPI", 
    "Azure IoT":"AZUREIOT"
  };

  useEffect(() => {
    const getBatchNumbers = async () => {
      if (selectedStreamingMethod) {
        setLoading(true)
        try {
          const batchNumbers = await fetchBatchNumbers();
          setBatchNumbers(batchNumbers);

          if(batchNumbers.length > 0){
            setSelectedBatch(batchNumbers[0]);
            handleSelectBatchNumber(batchNumbers[0]);
          }
        } catch (err) {
          console.error('Error fetching batch numbers:', err);
          setIsToasterOpen(true)
          setReqSuccess(false)
          setToasterMessage(err.message)
        }finally{
          setLoading(false)
        }
      }
    };
    getBatchNumbers();
  }, [selectedStreamingMethod]);  
  
  const handleSelectStreamingMethod = (value) => {
    setSelectedStreamingMethod(value);
    handleError('streamNameError')
  };

  const handleSelectBatchNumber = async (value) => {
    if (selectedStreamingMethod) {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: null
      }));
    }
    setSelectedBatch(value);
    handleError('batchNoError')
    try {
      setLoading(true)
      const { mainTestTitles, content, uniqueDevices} = await fetchMainTestCases(value);
      const uniqueMainTestTitles = [...new Set(mainTestTitles)];

      setMainTestCasesTitles(uniqueMainTestTitles);
      setMainTestCasesContent(content);
      setDevices(uniqueDevices);
    } catch (error) {
      console.error('Error fetching main test cases:', error);
      setIsToasterOpen(true)
      setReqSuccess(false)
      setToasterMessage(error.message)
    }finally{
      setLoading(false)
    }
  };

  const handleSelectTestingMethod = (value) => {
    setSelectedTestCaseType(value);
    handleError('testingMethodError')
  };

  const handleIncrease = () => {
    const currentValue = responseTime === '' ? 0 : parseInt(responseTime, 10);
    setResponseTime(String(currentValue + 1));
    setErrors((prevError) => ({
      ...prevError,
      rootCauseNotificationDurationInMinutes: null
    }));
  };
  
  
  const handleDecrease = () => {
    const currentValue = responseTime === '' ? 0 : parseInt(responseTime, 10);
    setResponseTime(currentValue > 0 ? String(currentValue - 1) : '0');
    setErrors((prevError) => ({
      ...prevError,
      rootCauseNotificationDurationInMinutes: null
    }));
  };

  // Handle click on batch No dropdown
  const onBatchDropClick = () => {
    if (selectedStreamingMethod === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        streamNameError: "Data Streaming Method is Required"
      }));
    }
  };
  
  // Handle click on the main test case dropdown
  const onMainDropClick = () => {
    if (selectedBatch === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        batchNoError: "Batch is required"
      }));
    }
  };

  // Handle click on the sub test case dropdown
  const onSubsDropClick = () => {
    if (selectedMainTestCaseTitle === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        mainTestNameError: "Main Test Case Name is required"
      }));
    }
  };
  
  const handleSelectMainTestCase = (value) => {
    if (selectedBatch) {
      setErrors(prevErrors => ({
        ...prevErrors,
        batchNoError: null
      }));
    }
    setSelectedMainTestCaseTitle(value);
    handleError('mainTestNameError')

    const selectedMainTestCase = mainTestCasesContent.find(testCase => 
      testCase.mainTestTitle === value
    );
    if (selectedMainTestCase) {
      setSelectedMainTestCaseId(selectedMainTestCase.id);
      const subTestTitles = selectedMainTestCase.subTestCases.map(subTestCase => 
        subTestCase.subTestTitle
      );
      setSubTestCaseTitles(subTestTitles);
      setRelatedSubTestCases(selectedMainTestCase.subTestCases); 
    } 
    else {
      setSubTestCaseTitles([]);
      setRelatedSubTestCases([]); 
    }
  };


  const handleSelectSubTestCase = (selectedTitles) => {
    if (selectedMainTestCaseTitle) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mainTestNameError:null
      }));
    }
    setSelectedSubTestCasesTitles(selectedTitles);
    handleError('subTestCasesError')

    const filteredCases = relatedSubTestCases.filter(subTestCase => 
      selectedTitles.includes(subTestCase.subTestTitle)
    );
    setFilteredSubTestCases(filteredCases);
  
    // Extract the IDs of the filtered subtest cases
    const filteredCaseIds = filteredCases.map(subTestCase => subTestCase.id);
    setFilteredSubTestCasesIds(filteredCaseIds);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = formatDateString(e);
    setSelectedStartDate(e)
    if (newStartDate > endDate) {
      setEndDate("");
      setSelectedEndtDate(new Date())
  }
    setStartDate(newStartDate);
    handleError('startDateError')
  };
  
  const handleEndDateChange = (e) => {
    const newEndDate = formatDateString(e);
    setSelectedEndtDate(e)
    if (newEndDate < startDate) {
      setStartDate("");
      setSelectedStartDate(new Date())
    }
    setEndDate(newEndDate);
    handleError('endDateError')
  };

  const convertToHours = (value: number, period: string): number => {
    switch (period) {
      case "Days":
        return value * 24;
      case "Weeks":
        return value * 24 * 7;
      case "Months":
        return value * 24 * 30;
      case "Hours":
      default:
        return value;
    }
  };
  
  const handleStartTesting = async () => {
    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }

    const selectedMainTestCase = mainTestCasesContent.find(testCase => testCase.mainTestTitle === selectedMainTestCaseTitle);

    const basePayload = {
      batchNumber: selectedBatch,
      status: null,
      mainTestTitle: selectedMainTestCaseTitle,
      subTestCases: filteredSubTestCases,
      subTestCasesIdList: filteredSubTestCasesIds,
      dataStreamingMethod: streamingMethodsMap[selectedStreamingMethod],
      expectedInterval: 1,
      description: selectedMainTestCase.description
    };
  
    const payload = selectedTestCaseType === "Production Feasibility Testing" 
      ? {
          ...basePayload,
          endDate,
          startDate
        }
      : {
          ...basePayload,
          testCaseType: "CONTINUOUS",
          continuousTestEndType: "NEVER",
          rootCauseNotificationDurationInMinutes:convertToMinutes(parseInt(responseTime), selectedPeriod),
          period:selectedPeriod
        };

    try {
      await startTesting(
          payload, 
          setLoading, 
          setIsToasterOpen, 
          setToasterMessage, 
          setReqSuccess,
          selectedTestCaseType,
          history
      );
    } catch (error) {
      console.error('Error starting test case:', error);
    }
  };

  const validationForm = () => {
    let error: any = {}
    if (selectedStreamingMethod== "") {
      error.streamNameError = "Data Streaming Method is Required"
    }
    if (selectedBatch == "") {
      error.batchNoError = "Batch is required"
    }
    if (selectedMainTestCaseTitle == "") {
      error.mainTestNameError = "Main Test Case Name is required"
    }
    if (selectedSubTestCasesTitles.length === 0) {
      error.subTestCasesError = "At least one sub-test case is required.";
    }
    if (selectedTestCaseType == "") {
      error.testingMethodError = "Testing Method is Required"
    }
    if(selectedTestCaseType == "Continuous Testing"){
      if (responseTime== "") {
        error.rootCauseNotificationDurationInMinutes = "Time is required"
      }
      if(selectedPeriod == ""){
        error.period = "Period is required"
      }
    }
    if (selectedTestCaseType === "Production Feasibility Testing") {
      if(endDate === ""){
        error.endDateError = "End Date and Time is required";
      }
      if(startDate== ""){
        error.startDateError = "Start Date and Time is required"
      }
    }
    return error
  }

  const handleError = (name) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }

  const resetFormFeilds = () =>  {
    setSelectedStreamingMethod('');
    setSelectedBatch('');
    setSelectedMainTestCaseTitle('');
    setSelectedSubTestCasesTitles([]);
    setInterval('');
    setStartDate('');
    setEndDate('');
  }

  const handleBack = () => {
    resetFormFeilds();
    history.push(`/TestAutomation`);
  }

  const handleResponseTime = (value: number | string) => {
    const stringValue = String(value);
    const numericValue = stringValue.replace(/[^0-9]/g, '');
    
    setResponseTime(numericValue);
    
    setErrors((prevError) => ({
      ...prevError,
      rootCauseNotificationDurationInMinutes: null
    }));
  };

  const handleSchedulePeriod = (period) => {
    const periodInMinutes = convertToMinutes(parseInt(responseTime), period);
    setSelectedPeriod(periodMap[period]);

    setErrors((prevError) => ({
      ...prevError,
      period: null
    }));
  };

  const periodMap = { 
    "Hours": "HOURS", 
    "Days": "DAYS", 
    "Minutes": "MINUTES",
    "Weeks":"WEEKS",
    "MONTHS":"Months"
   };


  return (
    <ThemeProvider theme={theme}>
      <div className="testing">
        <Grid container className={classes.pageTitleContainer} style={{paddingLeft:'0px'}}>
          <IconButton onClick={() => clickBack(history, "/TestAutomation")}>
            <RedirectIcon />
          </IconButton>
          <Grid className={classes.pageTitle} style={{margin:'0px'}}>Start Monitoring - Step 03</Grid>
        </Grid>
        <Grid container className={classes.root}>
          <Grid item xs={12} md={12}>
            <Grid container className={classes.formContent}>
              <Grid container className={classes.dataStreamBoxContainer}>
                <Grid item xs={12} md={12} style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"10px"}}>
                  {/* Data Streaming Method */}
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    alignItems="flex-start"
                    style={{ marginBottom: "20px" }}
                  >
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.typo}>
                        Data Streaming Method <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <DropDown
                        options={streamingMethods}
                        type='streamingMethod'
                        emptyTag='-Select-'
                        setSelectOption={handleSelectStreamingMethod}
                        value={selectedStreamingMethod}
                        handleError={handleError}
                        isSingle
                      />
                    </Grid>
                    {errors.streamNameError && (
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{ padding: "0px 8px" }}
                      >
                        <Typography className={classes.errorText}>
                          {errors.streamNameError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
      
                  {/* New Batch */}
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    alignItems="flex-start"
                    style={{ marginBottom: "20px" }}
                  >
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.typo}>
                        Test Batch
                        <span style={{ color: 'red' }}>*</span>
                      </Typography> 
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <DropDown
                        options={batchNumbers.map(batch => batch)}
                        type='batchNumber'
                        emptyTag='-Select-'
                        setSelectOption={handleSelectBatchNumber}
                        value={selectedBatch}
                        onBatchDropClick={onBatchDropClick}
                        isSingle
                      />
                    </Grid>
                    {errors.batchNoError && (
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{ padding: "0px 8px" }}
                      >
                        <Typography className={classes.errorText}>
                          {errors.batchNoError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
      
                  {/* Main Test Case Name */}
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    alignItems="flex-start"
                    style={{ marginBottom: "20px" }}
                  >
                    <Grid item container xs={12} md={12}>
                      <Typography className={classes.typo}>
                        Main Test Case Name
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>  
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <DropDown
                        options={mainTestCasesTitles}
                        type='mainTestCase'
                        emptyTag='-Select-'
                        setSelectOption={handleSelectMainTestCase}
                        value={selectedMainTestCaseTitle}
                        isSingle
                        onMainDropClick={onMainDropClick}
                      />
                      {errors.mainTestNameError && (
                        <Grid container justifyContent="flex-end" alignItems="center">
                          <Typography className={classes.errorText}>
                            {errors.mainTestNameError}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
  
              {/* Sub Test Case Name */}
              <Grid item xs={12} md={12} className={classes.subTestCaseContainer}>
                <Grid
                  container
                  item xs={12} md={6}                
                  alignItems="center"
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.typo}>
                      Sub Test Case Name
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>  
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <DropDown
                      options={subTestCaseTitles}
                      type='subTestCase'
                      emptyTag='-Select-'
                      setSelectOption={handleSelectSubTestCase}
                      value={selectedSubTestCasesTitles}
                      isSingle={false}
                      onSubsDropClick={onSubsDropClick}
                      disabled = {false}
                    />
                    {errors.subTestCasesError && (
                      <Grid container justifyContent="flex-end" alignItems="center">
                        <Typography className={classes.errorText}>
                          {errors.subTestCasesError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Testing Method */}
                <Grid
                  container
                  item xs={12} md={6}
                  alignItems="center"
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.typo}>
                      Testing Method
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>  
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <DropDown
                      options={testCaseTypes}
                      type='testingMethod'
                      emptyTag='-Select-'
                      setSelectOption={handleSelectTestingMethod}
                      value={selectedTestCaseType}
                      handleError={handleError}
                      isSingle
                    />
                    {errors.testingMethodError && (
                      <Grid container justifyContent="flex-end" alignItems="center">
                        <Typography className={classes.errorText}>
                          {errors.testingMethodError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {selectedTestCaseType === "Production Feasibility Testing" && (
                <Grid container spacing={2} style={{ marginTop: "20px"}}>
                  <Grid item xs={12} md={12} style={{borderRadius: "5px", backgroundColor: lightGray }}>
                    <Grid container alignItems="center" spacing={2} style={{ margin: "20px" }}>
                      
                      {/* Start Time */}
                      <Grid item xs={12} md={2}>
                        <Typography className={classes.typo}>
                          Start Time <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={11} md={9}>
                      <DateTimeSelectorComponent selectedDate={selectedStartDate} handleDateChange={handleStartDateChange}/>
                        {errors.startDateError && (
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                              {errors.startDateError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      {/* End Time */}
                      <Grid item xs={12} md={2}>
                        <Typography className={classes.typo}>
                          End Time <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={11} md={9}>
                        <DateTimeSelectorComponent selectedDate={selectedEndtDate} handleDateChange={handleEndDateChange}/>
                        {errors.endDateError && (
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                              {errors.endDateError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {selectedTestCaseType === "Continuous Testing" && (
                <Grid container spacing={2} style={{ marginTop: "20px"}}>
                  <Grid item xs={12} md={12} style={{borderRadius: "5px", backgroundColor: lightGray }}>
                    <Grid container alignItems="center" spacing={2} style={{ margin: "20px" }}>

                      <Grid item xs={12} md={12}>
                        <Typography className={classes.typo} style={{color:'#324054',fontWeight:600}}>
                          Alert Schedule
                        </Typography>
                      </Grid>
                      
                      {/* Start Time */}
                      <Grid item xs={12} md={12}>
                        <Typography className={classes.typo}>
                          Repeat Every <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <InputBase
                        className={classes.formInput}
                        placeholder="Enter Duration"
                        name="responseTime"
                        value={responseTime}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          handleResponseTime(inputValue === '' ? '' : parseInt(inputValue, 10))
                        }}                        style={{paddingRight:'0px'}}
                        endAdornment={
                          <InputAdornment position="end">
                            <Box display="flex" flexDirection="column" style={{marginTop:'-14px'}}>
                              <IconButton size="small" onClick={handleIncrease} style={{height:'15px'}}>
                                <IncreaseIcon />
                              </IconButton>
                              <IconButton size="small" onClick={handleDecrease} style={{height:'15px'}}>
                                <DecreaseIcon />
                              </IconButton>
                            </Box>
                          </InputAdornment>
                        }
                      />
                      {errors.rootCauseNotificationDurationInMinutes && (
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                          <Typography className={classes.errorText}>
                            {errors.rootCauseNotificationDurationInMinutes}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} md={5} >
                      <DropDown
                        options={shedulePeriod}
                        type="shedulePeriod"
                        emptyTag="-Select-"
                        setSelectOption={handleSchedulePeriod}
                        value={selectedPeriod}
                        isSingle={true}
                        isDisabled={isResponseTimeFixed}
                      />
                      {errors.period && (
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                          <Typography className={classes.errorText}>
                            {errors.period}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

  
              {/* Device Config */}
              {filteredSubTestCases.length !== 0 && (
                <Grid container style={{ marginTop: "40px" }}>
                  <Grid item xs={12}>
                    <DeviceConfig 
                      filteredSubTestCases={filteredSubTestCases}
                      selectedSubTestCasesTitles={selectedSubTestCasesTitles}
                      devices={devices}
                    />
                  </Grid>
                </Grid>
              )}
  
              {/* Buttons */}
              <Grid
                container
                spacing={2}
                style={{ marginTop: "20px" }}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.devicePulseSaveButton} ${classes.button}`}
                    onClick={handleStartTesting}
                  >
                    Start
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Loader loading={loading}/>
        <ToasterComponent
          toaster={isToasterOpen}
          message={toasterMessage}
          reqSuccess={reqSuccess}
        />
      </div>
    </ThemeProvider>
  );
}
