import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Button, Chip, createTheme, Grid, makeStyles, Theme, ThemeProvider, Typography} from "@material-ui/core";
import {primaryBlue, white} from "src/components/colors";
import {Link, useHistory} from 'react-router-dom';
import {environment} from "src/environments/environment";
import {Loader} from "./step-3/components/Loader";
import axios from "axios";
import {StepIcon} from "src/components/Icons";
import useStyles from "src/app/maintenance/assets/styles";
import Cookies from "js-cookie";

export function SetupSenzMaticaComponent() {
  const classes = localStyles();
  const style = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });

  const [transcodingPending, setTranscodingPending] = useState(false);
  const [stepCompleted, setStepCompleted] = useState({
    step1Completed: null,
    step2Completed: null,
    step3Completed: null,
    step4Completed: null,
    step5Completed: null
  });
  const [loading, setLoading] = useState(false)
  const [isDirectApiClick, setIsDirectApiClick] = useState(false)
  const history = useHistory()
  const handleGetStartedStep1Click = () => {
    history.push(`/DefineProductType`)
  };
  const handleGetStartedStep2Click = () => {
    history.push(`/ConnectDevice`)
  };

  useEffect(() => {
    console.log(Cookies.get('USER_ID'))
    fetchSetupStatus();

    if (localStorage.getItem('transcodingIsPending')) {
      setTranscodingPending(true);
      localStorage.removeItem('transcodingIsPending');
    }
  }, []);

  // Fetch the status of steps from the API endpoint
  const fetchSetupStatus = async () => {
    setLoading(true)
      const accessToken = Cookies.get("ACCESS_TOKEN");
      await axios.get(`${environment.host}/core/setupSenzmatica`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        const steps1To3 = response.data.content;
        axios.get(`${environment.host}/ums/user/setupSenzmatica`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
          const step4 = response.data.content;
          setStepCompleted({
            step1Completed: steps1To3.step1Completed,
            step2Completed: steps1To3.step2Completed,
            step3Completed: steps1To3.step3Completed,
            step4Completed: null,
            step5Completed: false
          });

          if (steps1To3.step3Completed) {
            setStepCompleted(prevState => ({
              ...prevState,
              step4Completed: step4.step4Completed,
            }));
          } else {
            setStepCompleted(prevState => ({
              ...prevState,
              step4Completed: false,
            }));
          }
        })
        .catch(error => {
          console.error('Error fetching setup status:', error);
        })
        .finally(() => {
          setLoading(false)
        });
      })
      .catch(error => {
        console.error('Error fetching setup status:', error);
        setLoading(false)
      });
  };

  const directApiClick = () => {
    const apiClick = isDirectApiClick
    console.log(apiClick)
    if (!apiClick) {
      setIsDirectApiClick(true)
    }
  }

  return (
  <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <Grid container>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box className={style.boxContainer}>
              <div className={style.statusContainer}>
                <Box className={style.step}> <StepIcon stepNumber="01" /> </Box>
                <div className={style.chipContainer}>
                  <Chip
                    variant="outlined"
                    label={
                      stepCompleted.step1Completed == null
                        ? ""
                        : !stepCompleted.step1Completed
                          ? "Pending..."
                          : transcodingPending
                            ? "Transcoding is pending"
                            : "Completed"
                    }
                    className={
                      !stepCompleted.step1Completed
                        ? classes.redChip
                        : transcodingPending
                          ? classes.yellowChip
                          : classes.greenChip
                    }
                  />
                </div>
              </div>
              <Typography className={style.titleContainer}>
                Define Product Types
              </Typography>
              <Typography className={style.descriptionContainer}>
                Begin by defining a product to organize your devices effectively
              </Typography>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={style.getStarted}
                  style={{textTransform: "none"}}
                  onClick={handleGetStartedStep1Click}
                >
                  {stepCompleted.step1Completed ? "Add Another Product" : "Get Started"}
                </Button>
              </div>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box className={`${style.boxContainer} ${!stepCompleted.step1Completed && classes.blur}`}>
              <div className={style.statusContainer}>
                <Box className={style.step}> <StepIcon stepNumber="02" /></Box>
                <div className={style.chipContainer}>
                  <Chip
                    variant="outlined"
                    className={stepCompleted.step2Completed ? classes.greenChip : classes.redChip}
                    label={stepCompleted.step2Completed == null ? "" : stepCompleted.step2Completed ? "Completed" : "Pending..."}
                  />
                </div>
              </div>
              <Typography className={style.titleContainer}>
                Connect Test Devices
              </Typography>
              <Typography className={style.descriptionContainer}>
                Specify unique device types and their configurations for streamline management
              </Typography>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={style.getStarted}
                  style={{textTransform: "none"}}
                  onClick={handleGetStartedStep2Click}
                >
                  {stepCompleted.step2Completed ? "Add Another Device" : "Get Started"}
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box className={`${style.boxContainer} ${!stepCompleted.step2Completed && classes.blur}`}>
              <div className={style.statusContainer}>
                <Box className={style.step}> <StepIcon stepNumber="03" /></Box>
                <div className={style.chipContainer}>
                  <Chip
                    variant="outlined"
                    className={stepCompleted.step2Completed ? classes.greenChip : classes.redChip}
                    label={stepCompleted.step3Completed == null ? "" : stepCompleted.step3Completed || isDirectApiClick ? "Completed" : "Pending..."}
                  />
                </div>
              </div>
              <Typography className={style.titleContainer}>
                Connect with the User Application
              </Typography>
              <Typography className={style.descriptionContainer}>
                Connect your devices to the application for seamless integration and monitoring
              </Typography>
              <div className={classes.buttonContainer}>
                <Link to={{
                  pathname: `/User-Application`,
                  data: {stepCompleted: stepCompleted, directApiClick: directApiClick}
                }}>
                  <Button
                    variant="contained"
                    className={style.getStarted}
                    style={{textTransform: "none"}}
                  >
                    {stepCompleted.step3Completed || isDirectApiClick ? "Add Another Application" : "Get Started"}
                  </Button>
                </Link>
              </div>

            </Box>
          </Grid>
          <Grid item md={6}>
            <Box className={`${style.boxContainer} ${!stepCompleted.step3Completed && classes.blur}`}>
              <div className={style.statusContainer}>
                <Box className={style.step}> <StepIcon stepNumber="04" /></Box>
                <div className={style.chipContainer}>
                  <Chip
                    variant="outlined"
                    className={
                      stepCompleted.step4Completed == 2 ? classes.greenChip : stepCompleted.step4Completed == 1 ? classes.yellowChip : classes.redChip
                    }
                    label={
                      stepCompleted.step4Completed == null ? "" : stepCompleted.step4Completed == 2 ? "Completed" : stepCompleted.step4Completed == 1 ? "Partially Complete" : "Pending..."
                    }
                  />
                </div>
              </div>
              <Typography className={style.titleContainer}>
                User Management System [Optional]
              </Typography>
              <Typography className={style.descriptionContainer}>
                Set privileges for user roles to manage user access through privileges
              </Typography>
              <div className={classes.buttonContainer}>
                <Link to={{pathname: `/User-Creation`, state: {step4Completed: stepCompleted.step4Completed}}}
                      onClick={() => localStorage.setItem('step4Completed', stepCompleted.step4Completed)}>
                  <Button
                    variant="contained"
                    className={style.getStarted}
                    style={{textTransform: "none"}}
                  >
                    {stepCompleted.step4Completed == 2 ? "Add Another User" : stepCompleted.step4Completed == 1 ? "Add User" : "Get Started"}
                  </Button>
                </Link>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box className={style.boxContainer}>
              <div className={style.statusContainer}>
                <Box className={style.step}> <StepIcon stepNumber="05" /> </Box>
                <div className={style.chipContainer}>
                  <Chip
                    variant="outlined"
                    className={classes.redChip}
                    label="Pending..."
                  />
                </div>
              </div>
              <Typography className={style.titleContainer}>
                ML Core [Optional]
              </Typography>
              <Typography className={style.descriptionContainer}>

              </Typography>
              <div className={classes.buttonContainer}>
                <Link to={{pathname: `/automl`, state: {}}}>
                  <Button
                    variant="contained"
                    className={style.getStarted}
                    style={{textTransform: "none"}}
                  >
                    Get Started
                  </Button>
                </Link>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Loader loading={loading}/>
    </div>
  </ThemeProvider>
  );
}

const localStyles = makeStyles((theme: Theme) => ({
  root: {
     backgroundColor: white,
     marginTop: "5px", 
     padding: "10px 50px 30px",
     borderRadius: "3px"
  },
  blur: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  redChip: {
    border: "10px",
    color: "#FF6F17",
    height: "30px",
    fontSize: "15px",
    fontWeight: "bold"
  },
  yellowChip: {
    border: "10px",
    color: "#FFBF00",
    height: "30px",
    fontSize: "15px",
    fontWeight: "bold"
  },
  greenChip: {
    border: "10px",
    color: "#02C170",
    height: "30px",
    fontSize: "15px",
    fontWeight: "bold"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },

}));
