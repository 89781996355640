import {Button, FormControl, Grid, IconButton, makeStyles, Snackbar, Theme, Typography,} from "@material-ui/core";
import * as React from "react";
import {useState} from "react";
import {environment} from "src/environments/environment";
import {primaryBlue, primaryGray, white} from "../../../components/colors";
import {EditIconTwo} from "../../../components/Icons";
import {Alert} from "@material-ui/lab";
import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";

export function HTTPProtocol(props) {
  const {protocol, onSuccess, credentialsError, setClientId} = props;
  const classes = local();
  const style = useStyles();

  const [id, setID] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [accessKey, setAccessKey] = useState("");

  const [showForm, setShowForm] = useState(true);
  const [clickAdd, setClickAdd] = useState(false);
  const [clickUpdate, setClickUpdate] = useState(false);
  const [clickFinish, setClickFinish] = useState(false);
  const [toUpdate, setToUpdate] = useState(false);
  const [toEdit, setToEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [toaster, setToaster] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [errors, setErrors] = useState({
    client_id: null,
    client_secret: null,
    grant_type: null
  })

  React.useEffect(() => {
    resetErrors();
  }, []);
  React.useEffect(() => {
    resetErrors();
  }, [protocol]);

  
  //Get Client
  const getClient = (id) => {
    axios({
      method: 'GET',
      url: `${environment.host}/core/http-acl/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
      },
    })
      .then((response) => {
        const data = response.data;
        setClientID(data.content.client_id);
        setClientSecret( data.content.client_secret);
        setToUpdate(true);
        setIsChange(false);
      })
      .catch((error) => {
        console.error('Error fetching client:', error.response.data.message);
        FormClear();
        setReqSuccess(false);
        setErrorMessage(error.response.data.message);
        setToaster(true);
      });
  };

  // POST Protocol Credentials
  const generateCredentials = (data) => {
    axios({
      method: 'POST',
      url: `${environment.host}/core/http-acl`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
      },
      data: JSON.stringify(data),
    })
      .then((response) => {
        const id = response.data.content.id;
        if (response.status === 200) {
          setReqSuccess(true);
          setErrorMessage('Credentials Generated Successfully');
          generateAccessToken(id);
          setID(id);
          setToaster(true);
        }
      })
      .catch((error) => {
        console.error('Error generating HTTP credentials:', error.response.status);
        if (error.response.status === 422) {
          setReqSuccess(false);
          setErrorMessage(error.response.data.message);
          setToaster(true);
          onSuccess(false);
        }
      });
  };

  // Update Protocol Credentials
  const updateCredentials = (data) => {
    axios({
      method: 'PUT',
      url: `${environment.host}/core/http-acl/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
      },
      data: JSON.stringify(data),
    })
      .then((response) => {
        const id = response.data.content.id;
        if (response.status === 200) {
          setReqSuccess(true);
          setErrorMessage('Credentials Updated Successfully');
          setToaster(true);
          generateAccessToken(id);
          onSuccess(true);
        }
      })
      .catch((error) => {
        console.error('Error updating HTTP credentials:', error);
        if (error.response && error.response.data.errorCode === 'E554') {
          FormClear();
          setReqSuccess(false);
          setErrorMessage(error.response.data.message);
          setToaster(true);
          onSuccess(false);
        }
      });
  };

  //Generate Access Token
  const generateAccessToken = (httpAclId) => {
    axios({
      method: 'POST',
      url: `${environment.host}/core/http-acl/${httpAclId}/access-token`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
      .then((response) => {
        const data = response.data;
        setAccessKey(data.content.access_token);
        onSuccess(true);
      })
      .catch((error) => {
        console.error('Error generating access token:', error.response.data.message);
        setAccessKey('');
        setReqSuccess(false);
        setErrorMessage('Error in Generating Access Token');
        setToaster(true);
        onSuccess(false);
      });
  
    if (toUpdate) {
      setClickUpdate(true);
      setShowForm(true);
    } else {
      setClickAdd(true);
      setClickFinish(true);
      setToEdit(false);
    }
  };
  
  const handleInputChange = (e) => {
    setIsChange(true);
    const { name, value } = e.target;
  
    if (name === 'client_id') {
      setClientID(value);
    } else if (name === 'client_secret') {
      setClientSecret(value);
    }
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSave = async () => {
    const data = {
      client_id: clientID,
      client_secret: clientSecret,
      grant_type: 'client_credentials',
      authorities: [],
      scope: 'read,write,trust',
    };
  
    const validationErrors = validateForm(data);
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
  
    if (toUpdate && !clickUpdate) {
      await updateCredentials(data);
    } else if (!clickAdd) {
      await generateCredentials(data);
    }
  
    setClientId(id);
  
    if (clickFinish) {
      if (clickUpdate) {
        setShowForm(false);
      } else {
        setToEdit(true);
        setShowForm(false);
      }
    }
  };
  
  const validateForm = (values) => {
    let errors: any = {};
    if (!values.client_id) {errors.client_id = 'Client ID is Required';}
    if (!values.client_secret) {errors.client_secret = 'Client Secret is Required';}
    return errors;
  };
  
  const resetErrors = () => {
    setErrors({
      client_id: null,
      client_secret: null,
      grant_type: null,
    });
  };
  
  const resetFormFields = () => {
    setClientID('');
    setClientSecret('');
    setAccessKey('');
    resetErrors();
    setIsChange(false);
  };
  
  const FormClear = () => {
    setToUpdate(false);
    setClickFinish(false);
    setClickAdd(false);
    setToEdit(false);
    setShowForm(true);
  };
  
  const handleEditClick = () => {
    console.log('id:', id);
    getClient(id);
    setShowForm(!showForm);
    setClickUpdate(false);
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };
  
  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Protocol
                <span className={style.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <FormControl className={classes.input}>
                <Typography className={style.formInput}>
                  {protocol}
                  {toEdit && (
                    <IconButton onClick={handleEditClick}>
                      <EditIconTwo/>
                    </IconButton>
                  )}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      {showForm && (
        <Grid container spacing={5}>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={4} md={3}></Grid>
              <Grid item xs={8} md={9}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "15px",
                    marginBottom: '10px'
                  }}>
                  <FormControl className={classes.input}>
                    Client ID
                    <input
                      className={style.formInput}
                      type="text"
                      value={clientID}
                      name="client_id"
                      onChange={handleInputChange}
                      placeholder="Enter Client ID"
                    />
                    {errors.client_id && (
                      <span className="form-error">
                        {errors.client_id}
                      </span>
                    )}
                  </FormControl>
                  <Grid item
                        style={{
                          display: 'flex',
                          marginTop: '10px'
                        }}>
                    <FormControl
                      className={classes.input}
                      style={{marginRight: '10px'}}
                    >
                      Client Secret
                      <input
                        className={style.formInput}
                        type="text"
                        value={clientSecret}
                        name="client_secret"
                        onChange={handleInputChange}
                        placeholder="Enter Client Secret"
                      />
                      {errors.client_secret && (
                        <span className="form-error">
                          {errors.client_secret}
                        </span>
                      )}
                    </FormControl>
                    <FormControl className={classes.input}>
                      Grant Type
                      <input
                        className={style.formInput}
                        type="text"
                        value={"Client Credentials"}
                        readOnly
                      />
                    </FormControl>
                  </Grid>
                  {clickAdd && (
                    <Grid item
                          style={{
                            display: 'flex',
                            marginTop: '10px'
                          }}
                    >
                      <FormControl className={classes.input}>
                        Access Key
                        <input
                          className={style.formInput}
                          type="text"
                          value={accessKey}
                          readOnly
                        />
                      </FormControl>
                    </Grid>
                  )}
                  
                  <Grid item
                        style={{
                          display: 'flex',
                          marginTop: '10px'
                        }}>
                    <FormControl
                      className={classes.input}
                      style={{marginRight: '10px'}}
                    >
                      {!clickUpdate && (toUpdate || !clickAdd) && (
                        <Button variant='contained'
                                className={classes.cancel}
                                onClick={resetFormFields}
                        >
                          Clear
                        </Button>
                      )}
                    </FormControl>                   
                    <FormControl
                      className={classes.input}
                      variant="outlined"
                    >
                      <Button variant='contained' 
                              className={classes.add}
                              onClick={handleSave}
                              disabled={!isChange}
                      >
                        {clickUpdate ? "Finish" : toUpdate ? "Update" : clickAdd ? "Finish" : "Add"}
                      </Button>
                    </FormControl>
                  </Grid>
                  {credentialsError && (
                    <span className="form-error">
                      Add Credentials is Required
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      )}
      <Snackbar
        open={toaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          onClose={handleCloseToaster}
          severity={reqSuccess === true ? "success" : "error"}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};


const  local= makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif"
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  toolTip: {
    backgroundColor: "#CFD8DC",
    color: "#444",
    borderRadius: "4px",
    padding: "10px",
    fontSize: "13px",
    transition: "opacity 0.3s",
  },
  add: {
    backgroundColor: "#0096FF",
    color: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#0096FF",
      color: white,
      boxShadow: "none",
    },
    borderRadius: '8px',
  },
  cancel: {
    backgroundColor: "white",
    color: primaryGray,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    border: '1px solid black',
    borderRadius: '8px',
  },

}));


