import * as React from "react";
import {useEffect, useState} from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {getOneSubCase, submitDeviceConfig} from "../api-helper/apiHelper";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import { primaryBlue } from "src/components/colors";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  topic: {
    color: "#606060",
    borderBottomColor: "white",
    borderTopColor: "white",
    backgroundColor: "white",
    height: "20px",
  },
  topicText: {
    fontSize: "14px",
    marginTop: "20px",
  },
  deviceId: {
    fontSize: "14px",
    marginTop: "-20px",
  },
  content: {
    borderColor: "white",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export function DeviceConfig(props) {
  const classes = useStyles();

  const [mainTest, setMainTest] = useState("");
  const [tableHeadData, setTableHeadData] = useState([]);
  const [subTest, setSubTest] = useState([]);
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  //alert messages
  const configured = "Configured.";
  const device = "At least one device required.";
  const test = "At least one test condition required.";
  const error = "Something went wrong.";

  const submit = async () => {
    if (props.devicesData.length === 0 || props.devicesData === undefined) {
      setMessage(device);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
      return;
    }
    if (props.testCondition.length === 0 || props.testCondition === undefined) {
      setMessage(test);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
      return;
    }
    let subTestCaseIds = []
    props.selectedSubTestCase !== null && props.selectedSubTestCase !== undefined &&
    props.selectedSubTestCase.map((s) => {
      subTestCaseIds.push(s.id);
    })
    const result = await submitDeviceConfig(
      props.batch,
      mainTest,
      props.startDate,
      props.endDate,
      subTestCaseIds,
      props.devicesData
    );
    if (result === undefined) {
      setMessage(error);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    }
    if (result.message === "Success") {
      setMessage(configured);
      setOpen(true);
      props.setSubTestCasesForParameter(result.content)
      setTimeout(() => {
        setOpen(false);
      }, 6000);
      props.onNavigateToNextStep();
    }
  };

  useEffect(() => {
    const index = props.allData.findIndex(
      (obj) => obj.id === props.mainTestCase
    );
    if (props.allData[index] !== undefined) {
      setMainTest(props.allData[index].mainTestTitle);
    }
    const getSub = async () => {
      if (props.subTestCase !== undefined) {
        const subTestCaseIds = Array.isArray(props.subTestCase) ? props.subTestCase : [props.subTestCase];

        const result = [];
        subTestCaseIds.map(async (id: string) => {
          const data = getOneSubCase(props.batch, id);
          result.push(data);
        });

        Promise.all(result).then((responses) => {
          setTableHeadData(["Device Id", ...responses.map(value => {
            return value.subTestTitle
          })]);
          setSubTest(responses);
          let row = [];
          props.devicesData.map((device) => {
            const data =
              responses.map((value) => {
                return value.valueType === "percentage"
                  ? `${parseInt(value.minVal)}% - ${parseInt(value.maxVal)}%`
                  : `${parseInt(value.minVal)} - ${parseInt(value.maxVal)}`;
              })
            row.push(createData(device, data));
            return row;
          });
          setRows(row);
        });
      }
    };

    getSub();
  }, [
    props.batch,
    props.mainTestCase,
    props.subTestCase,
    props.allData,
    props.devicesData,
  ]);

  return (
    <div>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{backgroundColor: "white", borderBottomColor: "white"}}
              ></TableCell>
              <TableCell
                align="left"
                style={{
                  backgroundColor: "#ECEDEF",
                  textAlign: "center",
                  borderBottomColor: "white",
                  borderTopColor: "white",
                }}
                colSpan={4}
              >
                <Typography
                  style={{
                    margin: "10px",
                    fontSize: "14px",
                    color: "rgba(0,0,0,0.5)",
                    fontWeight: "bold",
                  }}
                >
                  {mainTest}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {tableHeadData.map((topic, i) => {
                return (
                  <TableCell align="left" className={classes.topic} key={i}>
                    <Typography
                      className={
                        topic === "Device Id"
                          ? classes.deviceId
                          : classes.topicText
                      }
                      style={{color: "rgba(0,0,0,0.5)", fontWeight: "bold"}}
                    >
                      {topic}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                style={{
                  backgroundColor:
                    i % 2 === 0 || i / 2 === 0 ? "#ECEDEF" : "white",
                }}
              >
                <TableCell align="left" className={classes.content}>
                  <Typography className={classes.contentText}>
                    {row.id}
                  </Typography>
                </TableCell>
                {row.percentage.map((item, index) => {
                  return <TableCell key={index} align="left" className={classes.content}>
                    <Typography className={classes.contentText}>
                      {item}
                    </Typography>
                  </TableCell>
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container>
        <Grid item xs={6} md={6}></Grid>
        <Grid
          item
          xs={6}
          md={6}
          style={{textAlign: "right", padding: "10px"}}
        >
          <Button
            style={{
              backgroundColor: "#F2F2F2",
              color: "black",
              margin: "10px",
            }}
            onClick={(e) => props.onClick()}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: primaryBlue,
              color: "white",
              margin: "10px",
            }}
            onClick={submit}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          severity={
            message === configured
              ? "success"
              : message === device || message === test
                ? "warning"
                : "error"
          }
        >
          <h3 style={{textAlign: "left"}}>
            {message === configured
              ? "Success"
              : message === device || message === test
                ? "Warning"
                : "Error"}
          </h3>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function createData(id, percentage) {
  return {id, percentage};
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
