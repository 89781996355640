import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  Select,
  MenuItem, 
  FormControl, 
  InputLabel
} from "@material-ui/core";
import {
  AddIconTwo,
  DeleteIcon,
  EditIconTwo,
  CopyIcon,
  CloseIcon,
  ViewIconTwo,
  ReportHistoryIcon,
  DeviceIcon,
  ReferenceImgIcon
} from '../../components/Icons';
import { useHistory } from "react-router-dom";
import { ToasterComponent } from "../maintenance/test-automation/components/Toaster";
import { makeStyles } from "@material-ui/core/styles";
import { environment } from "../../environments/environment";
import { SearchBar } from "../../components/sub-components/SearchBar";
import React, { useState, useEffect } from "react";
import useStyles from "../../app/maintenance/assets/styles";
import { Alert, Pagination } from "@material-ui/lab";
import { AccountService } from '../account/account.service';
import { CloseIconColor } from "../../components/Icons";
import { AuthService } from '../../app/authentication/auth.service'
import { Kit, KitModel, Device } from "../models";
import axios from 'axios';
import { MappedKitModel } from "../setup-senzmatica/step-3/steps/MappedKitModel";
import Cookies from "js-cookie";

export function KitComponent() {
  const classes = localStyles();
  const style = useStyles();
  const history = useHistory();

  const [kitModels, setKitModels] = useState<KitModel[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [allKits, setAllKits] = useState<Kit[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedKit, setSelectedKit] = useState<Kit>(null);
  const [selectedKitDelete, setSelectedKitDelete] = useState<Kit>(null);
  const [isCloning, setIsCloning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [error, setError] = useState(null);
  const [allSensors, setAllSensors] = useState([]);
  const [allActuators, setAllActuators] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [reqSuccess, setReqSuccess] = useState(false);
  const [lastCreatedKit, setLastCreatedKit] = useState("");
  const [filterBtnEnable, setFilterBtnEnable] = useState(false);
  const [selectedValidity, setSelectedValidity] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [formType, setFormType] = useState("Add");
  const [kitModelFormType, setKitModelFormType] = useState("Edit");
  const [referenceImgLoaded, setReferenceImgLoaded] = useState(false);
  const [refImageModal, setRefImageModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);


  const statusList = ['All', 'Approved', 'Submitted', 'Rejected'];
  const [statusCount, setStatusCount] = useState(
    {
      APPROVED: 0,
      SUBMITTED: 0,
      REJECTED: 0,
    }
  );

  useEffect(() => {
    getKits();
    getKitModels();
    getDevices();
    getMetaData();
    fetchAllKitTypes();
  }, []);

  const fetchImagesForKit = async (kit) => {
    setLoadingScreen(true);
    try {
      const response = await fetch(`${environment.host}/user/${Cookies.get("USER_ID")}/kit/${kit.id}/referenceImage`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setImages(data.content.referenceImages || []);

    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoadingScreen(false);
    }
  }

  const fetchImages = async (kit) => {
    try {
      const response = await fetch(`${environment.host}/user/${Cookies.get("USER_ID")}/kit/${kit.id}/referenceImage`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      kit.refImageStatus = (
        (data && data.content && data.content.submissionStatus) || "NAN"
      ).toUpperCase();      

      setStatusCount(prevStatusCount => ({
        ...prevStatusCount,
        [kit.refImageStatus]: (prevStatusCount[kit.refImageStatus] || 0) + 1
      }));

    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchAllKitTypes = async () => {
    try {
      const response = await fetch(`${environment.host}/core/kitType`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const types = data.content;

      setAllTypes(types);

    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  };

  const getKits = async () => {
    setLoadingScreen(true);
    try {
      const response = await fetch(`${environment.host}/core/kit`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch kit models');
  
      const data = await response.json();
      setAllKits(data.content);

      await Promise.all(data.content.map(kit => fetchImages(kit)));
      setReferenceImgLoaded(true);
  
    } catch (error) {
      console.error('Error fetching allKits:', error);
    } finally {
      setLoadingScreen(false);
    }
  };  

  const getKitModels = async () => {
    setLoadingScreen(true);
    try {
      const response = await fetch(`${environment.host}/core/kit-model`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch kit models');
      const data = await response.json();
      setKitModels(data.content);

    } catch (error) {
      console.error('Error fetching kit models:', error);
    } finally {
      setLoadingScreen(false);
    }
  };

  const getDevices = async () => {
    setLoadingScreen(true);
    try {
      const response = await fetch(`${environment.host}/core/device`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch devices');
      const data = await response.json();
      setDevices(data.content);

    } catch (error) {
      console.error('Error fetching devices:', error);
    } finally {
      setLoadingScreen(false);
    }
  };

  const getMetaData = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(`${environment.host}/core/meta-data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`
        }
      })
        .then((response) => {
          const sensors = response.data.content.sensors.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          })
          const actuators = response.data.content.actuators.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          })
          const types = response.data.content.kitTypes;
          setAllSensors(sensors)
          setAllActuators(actuators)
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching metaData:', error);
          reject(error);
        });
    })
  }

  const isMlEngineer = () => {
    return AuthService.isMlEngineer()
  }

  function toggleHideById(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.classList.toggle('hide');
    }
  }

  const updateValidity = (validity) => {
    setSelectedValidity(validity === 'All' ? null : validity);
    setFilterBtnEnable(true);
  };

  const updateStatus = (status) => {
    setSelectedStatus(status);
    setFilterBtnEnable(true);
  };

  const referenceOpen = (kit) => {
    console.log(kit);
    setSelectedKit(kit);
    setRefImageModal(true);
    fetchImagesForKit(kit);
  };

  const closePopup = () => {
    setRefImageModal(false);
    setSelectedKit(null);
  }

  const updateImgStatus = async () => {
    setLoadingScreen(true);
    try {
      const response = await fetch(
        `${environment.host}/user/${Cookies.get("USER_ID")}/kit/${selectedKit.id}/referenceImageStatus?status=${status}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        }
      );
  
      if (!response.ok) throw new Error("Failed to update reference image status");
      const data = await response.json();
      setKitModels(data.content);
      setStatusCount(prevStatusCount => ({
       ...prevStatusCount,
        [status]: (prevStatusCount[status] || 0) + 1,
        [selectedKit.refImageStatus.toUpperCase()]: (prevStatusCount[selectedKit.refImageStatus.toUpperCase()] || 0) - 1
      }));
      setAllKits(prevKits => 
        prevKits.map(kit => 
          kit.id === selectedKit.id ? { ...kit, refImageStatus: status } : kit
        )
      );
    } catch (error) {
      console.error("Error updating reference image status:", error);
    } finally {
      setLoadingScreen(false);
    }
  }  

  const openImagePopup = (image) => {
    console.log("open image popup")
  }

  const hideForm = () => {
    console.log("hide form")
  }

  const getKitsFiltered = () => {
    if (!allKits) {
      return [];
    }
  
    if (isMlEngineer() && referenceImgLoaded) {
      return allKits.filter((kit) => {
  
        const matchSearch = JSON.stringify(Object.values(kit)).toLowerCase().includes(searchTerm.trim().toLowerCase());

        if (selectedStatus === "All" && matchSearch) return true;
  
        const status = kit.refImageStatus != null ? kit.refImageStatus.toUpperCase() : "NAN";
  
        return status === selectedStatus.toUpperCase() && matchSearch;
      });
    }
  
    return allKits.filter((kit: Kit) => {
      const isValid = selectedValidity === 'Valid' ? kitValidity(kit.id) : selectedValidity === 'Invalid' ? !kitValidity(kit.id) : true;
  
      const matchSearch = JSON.stringify(Object.values(kit)).toLowerCase().includes(searchTerm.trim().toLowerCase());
  
      return isValid && matchSearch;
    });
  };  

  const kitValidity = (id: string) => {
    if (!kitModels) return false;

    const kit = allKits.find(res => id === res.id);
    if (!kit) {
      console.log('Kit not found');
      return false;
    }

    const kitModel = kitModels.find(kitModel => kit.model.id === kitModel.id);
    if (!kitModel) {
      console.log('Kit model not found');
      return false;
    }

    const kitModelActuators = kitModel.actuators || [];
    const kitModelSensors = kitModel.sensors || [];

    const currentDevices = kit.devices
      .map(deviceId => devices.find(dev => deviceId === dev.id))
      .filter(Boolean) as Device[];

    const deviceActuatorCodes = currentDevices.reduce((acc, device) => acc.concat(device.actuatorCodes), []);
    const deviceSensorCodes = currentDevices.reduce((acc, device) => acc.concat(device.sensorCodes), []);

    const actuatorsMatch = deviceActuatorCodes.every(actuatorCode =>
      kitModelActuators.some(actuator => actuator.code === actuatorCode)
    );

    const sensorsMatch = deviceSensorCodes.every(sensorCode =>
      kitModelSensors.some(sensor => sensor.code === sensorCode)
    );

    const sensorCount = deviceSensorCodes.length;
    const actuatorCount = deviceActuatorCodes.length;

    const kitMatches =
      kitModelSensors.length === sensorCount &&
      kitModelActuators.length === actuatorCount &&
      actuatorsMatch &&
      sensorsMatch;

    return kitMatches;
  };

  const increaseStep = () => {
    console.log("this is increase step");
    setShowForm(false);
  }

  const setIsEyeIconClick = () => {
    console.log("this is edit popup cancel");
    setShowForm(false);
  }

  // Filtering Kit Models based on the search term
  const getFilteredKits = () => {
    return allKits.filter((kitModel) =>
      kitModel.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const assignKit = (selectKit) => {
    let kit
    if (selectKit == "") {
      console.log(lastCreatedKit)
      kit = allKits.filter(kit => kit.name.toLowerCase() == lastCreatedKit.toLowerCase())

    } else {
      console.log(selectKit)
      kit = allKits.filter(kit => kit.name.toLowerCase() == selectKit.name.toLowerCase())
    }

    return kit;
  }

  const editKit = (kit: any) => {
    setFormType("Update");
    setSelectedKit(assignKit(kit));
    setShowForm(true);
  };

  const cloneKit = (kit: any) => {
    setIsCloning(true);
    setFormType("Clone");
    setSelectedKit(assignKit(kit));
    setShowForm(true);
  };

  const addKit = () => {
    setFormType("Add")
    setShowForm(true);
    setSelectedKit(null);
  };

  const handleSubmitKit = (kit: any) => {
    let user = localStorage.getItem("USER_ID");
    const requestMethod = formType === "Update" ? "put" : "post";
    const requestUrl =
      formType === "Update"
        ? `${environment.host}/core/user/${user}/kit/${kit.id}`
        : `${environment.host}/core/user/${user}/kit`;

    axios[requestMethod](requestUrl, kit, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        console.log(response.data.content);
        if (response.data.message === "Success") {
          setLastCreatedKit(response.data.content.name);
          setShowForm(false);
          getKits();
          getKitModels();
          getDevices();
          setIsCloning(false);
        } else {
          console.error("Failed to create kit model:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error creating kit model:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 4000);
      });
  };

  const deleteKit = async () => {
    if (!selectedKitDelete) return;

    try {
      const response = await fetch(`${environment.host}/core/kit-model/${selectedKitDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete kit model: ${response.statusText}`);
      }

      setAllKits((prevModels) => prevModels.filter((model) => model !== selectedKitDelete));
      setSelectedKitDelete(null);
    } catch (error) {
      console.error('Error deleting kit model:', error);
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage); // Ensure both variables are updated
  };

  return (
    <div className={classes.root}>
      <div className="page-heading">
        Kit Board
      </div>

      <Grid container alignItems="center" style={{ flexWrap: "nowrap" }}>
        <Grid item xs={8} md={4} style={{ textAlign: "left", margin: "20px" }}>
          <SearchBar placeholder="Search Kit" onChange={(e) => setSearchTerm(e.target.value)} />
        </Grid>

        <Grid item>
          <div className="filerButton" onClick={() => setFilterBtnEnable(!filterBtnEnable)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ padding: "4px" }}
            >
              <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
            </svg>
            {isMlEngineer() ? "Status" : "Filter"}
            {filterBtnEnable && (
              <div className="dropdown-list">
                {(isMlEngineer() ? statusList : ["All", "Valid", "Invalid"]).map((option) => (
                  <div key={option} onClick={() => isMlEngineer() ? updateStatus(option) : updateValidity(option)}>
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Grid>

        {isMlEngineer() && (
          <Grid item style={{ marginLeft: "auto" }}>
            <div className={classes.statusListStyle}>
              <span className={classes.approvedStyle}>{"Approved (" + statusCount.APPROVED + ")"}</span>
              <span className={classes.pendingStyle}>{"Submitted (" + statusCount.SUBMITTED + ")"}</span>
              <span className={classes.notApprovedStyle}>{"Rejected (" + statusCount.REJECTED + ")"}</span>
            </div>
          </Grid>
        )}
      </Grid>

      <TableContainer className={style.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>ID</TableCell>
              <TableCell className={classes.tableCell}>Kit Name</TableCell>
              {!isMlEngineer() ? (
                <>
                  <TableCell className={classes.tableCell}>Devices</TableCell>
                  <TableCell className={classes.tableCell}>Reference</TableCell>
                  <TableCell className={classes.tableCell}>Validity</TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>Interval</TableCell>
                  <TableCell className={classes.tableCell}>Persistence</TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>Action</TableCell>
                </>
              ) : (
                <>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>Display Image</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getKitsFiltered()
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((kit, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#F9F9F9" : "white",
                  }}
                >
                  <TableCell className={classes.tableCellBody}>{kit.id}</TableCell>
                  <TableCell className={classes.tableCellBody}>{kit.name}</TableCell>
                  {!isMlEngineer() ? (
                    <>
                      <TableCell className={classes.tableCellBody}>
                        {kit.devices.map((dev, i) => (
                          <span
                            key={i}
                            style={{ cursor: "pointer" }}
                          // onClick={() => navigate(`/device/${kit.devices[i]}`)} // Use navigate instead of history.push
                          >
                            {dev}&nbsp;
                          </span>
                        ))}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>{kit.referenceName}</TableCell>
                      <TableCell className={classes.tableCellBody}>
                        <label
                          className={
                            kitValidity(kit.id) ? "enabled" : "disabled"
                          }
                        >
                          {kitValidity(kit.id) ? "Valid" : "Invalid"}
                        </label>
                      </TableCell>
                      <TableCell className={classes.tableCellBody} style={{ textAlign: "center" }}>{kit.interval}</TableCell>
                      <TableCell className={classes.tableCellBody}>{kit.persistence ? "True" : "False"}</TableCell>
                      <TableCell
                        className={classes.tableCellBody}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            kit.properties.length === 0
                              ? "No data available"
                              : "View"
                          }
                        >
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                            disabled={kit.properties.length === 0}
                            onClick={() => {
                              if (kit.properties.length !== 0) {
                                history.push(`/device-view/${kit.id}`);
                              }
                            }}
                          >
                            <ViewIconTwo />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            kit.properties.length === 0
                              ? "No data available"
                              : "Actuator View"
                          }
                        >
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                            disabled={kit.properties.length === 0}
                            onClick={() => {
                              if (kit.properties.length !== 0) {
                                history.push(`/device/${kit.id}/actuator`);
                              }
                            }}
                          >
                            <DeviceIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                            onClick={() => editKit(kit)}
                          >
                            <EditIconTwo />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Clone Kit">
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                            onClick={() => {
                              setIsCloning(true);
                              cloneKit(kit);
                            }}
                          >
                            <CopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Offline History">
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                          // onClick={() =>
                          //     navigate(`/kit/${kit.id}`)
                          // }
                          >
                            <ReportHistoryIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                            onClick={() => {
                              setSelectedKitDelete(kit);
                              toggleHideById("deleteModal");
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell className={classes.tableCellBody}>
                        <label>
                          {kit.refImageStatus ? kit.refImageStatus : 'NaN'}
                        </label>
                      </TableCell>
                      <TableCell className={classes.tableCellBody}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Display Image">
                          <IconButton
                            size="small"
                            style={{ padding: "4px" }}
                            onClick={() => referenceOpen(kit)}
                          >
                            <ReferenceImgIcon color="#707070" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Grid container justifyContent="center">
        {getKitsFiltered().length > rowsPerPage && (
          <Pagination
            className={style.pagination}
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(getKitsFiltered().length / rowsPerPage)}
            shape="rounded"
            defaultPage={1}
          />
        )}
      </Grid>

      {/* Floating Add Button */}
      {!isMlEngineer && (
      <div
        className="material-button-div"
        style={{ position: "fixed", bottom: 20, right: 20 }}
      >
        <Button
          variant="contained"
          onClick={addKit}
          style={{
            minWidth: "50px",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            backgroundColor: "#2A7CED",
            color: "#fff",
            boxShadow: "0 3px 6px rgba(0,0,0,0.275)",
          }}
        >
          <AddIconTwo color="#FFF" />
        </Button>
      </div>
    )}


      {/* Loading Screen */}
      {loadingScreen && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            backgroundColor: "#000000",
            opacity: 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
            width: "100vw",
            height: "100vh",
          }}
        >
          <CircularProgress size={80} />
        </div>
      )}

      {showForm && !isMlEngineer() && (
        <Dialog open={true}>
          <Grid container>
            <Grid item xs={10}>
              <DialogTitle>
                {formType === "Add" ? "Add New" : formType} Kit
              </DialogTitle>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <IconButton onClick={() => setShowForm(false)}>
                <CloseIconColor />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <DialogContent>
            <MappedKitModel
              increaseStep={increaseStep}
              setKitModelFormType={setKitModelFormType}
              kitModelFormType={formType}
              allSensors={allSensors}
              allActuators={allActuators}
              allTypes={allTypes}
              allKits={allKits}
              selectedKit={selectedKit}
              setLoading={setLoadingScreen}
              setIsToasterOpen={setIsToasterOpen}
              setToasterMessage={setToasterMessage}
              setReqSuccess={setReqSuccess}
              getKits={getKits}
              allDevices={devices}
              allKitModels={kitModels}
              setLastCreatedKit={setLastCreatedKit}
              formType={formType}
              selectKit={selectedKit}
              getKitTrigger={() => { }}
              hideFormTrigger={hideForm}
              isCloning={isCloning}
              handleSubmitKit={handleSubmitKit}
            />
          </DialogContent>
        </Dialog>
      )}

      {selectedKitDelete && (
        <Dialog open={true} onClose={() => setSelectedKitDelete(null)}>
          <div className={classes.modal}>
            <div className={classes.modalContent}>
              <Grid container>
                <Grid item xs={10}>
                  <DialogTitle>CONFIRMATION</DialogTitle>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <IconButton onClick={() => setSelectedKitDelete(null)}>
                    <CloseIconColor />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent>
                <Typography variant="body1" style={{ color: "#FF4343" }}>
                  Are you sure you want to delete this kit {selectedKitDelete.name}?
                </Typography>
                <Typography variant="body2">
                  The delete action will remove the kit
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => setSelectedKitDelete(null)}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  className={classes.deleteButton}
                  onClick={deleteKit}
                >
                  DELETE
                </Button>
              </DialogActions>
            </div>
          </div>
        </Dialog>
      )}

      {refImageModal && !isImagePopupOpen && (
        <Dialog open={true} onClose={() => setRefImageModal(false)} maxWidth="md">
          <div className={classes.modal}>
            <div className={classes.modalContent}>
              <Grid container>
                <Grid item xs={10}>
                  <DialogTitle>Reference Images</DialogTitle>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <IconButton onClick={() => setRefImageModal(false)}>
                    <CloseIconColor />
                  </IconButton>
                </Grid>
              </Grid>
                  <DialogContent>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select value={status} onChange={(e) => setStatus(e.target.value)} fullWidth>
                        <MenuItem value="SUBMITTED">Submitted</MenuItem>
                        <MenuItem value="APPROVED">Approved</MenuItem>
                        <MenuItem value="REJECTED">Rejected</MenuItem>
                      </Select>
                    </FormControl>
                    <div className={classes.imageBoxes}>
                      {images.map((image, i) => (
                        <div className={classes.imageBox}>
                          <img src={image} alt={`Reference Image ${i + 1}`} 
                              className={classes.image}
                              loading="lazy" 
                              onClick={() => {
                                setSelectedImage(image);
                                setIsImagePopupOpen(true);
                              }}
                               />
                          <p className={classes.imgText}>IMAGE {i + 1}</p>
                        </div>                      
                      ))}
                    </div>
                  </DialogContent>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button variant="contained" onClick={closePopup}>Cancel</Button>
                    <Button variant="contained" onClick={() => { updateImgStatus(); closePopup(); }} color="primary">Update</Button>
                  </DialogActions>
                </Grid>
            </div>
          </div>
        </Dialog>      
      )}
      {isImagePopupOpen && (
            <Dialog open={true} onClose={() => setIsImagePopupOpen(false)} maxWidth="sm" fullWidth>
              <DialogTitle>
                Reference Image Preview
                <IconButton
                  onClick={() => setIsImagePopupOpen(false)}
                  style={{ position: "absolute", right: 10, top: 10 }}
                >
                  <CloseIconColor />
                </IconButton>
              </DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <img
                  src={selectedImage}
                  alt="Selected Reference"
                  style={{ width: "100%", maxHeight: "400px", objectFit: "contain" }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = selectedImage;
                    link.download = "reference_image.jpg";
                    link.click();
                  }}
                >
                  Download
                </Button>
              </DialogActions>
            </Dialog>
      )}
    </div>
  );
};

const localStyles = makeStyles((theme: Theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  versionChip: {
    backgroundColor: "#e6edf7",
    color: "#2a7ced",
    height: "20px",
    border: `1px solid #2A7CED`,
  },
  deleteButton: {
    backgroundColor: "#F06A6A",
    color: "white",
    "&:hover": {
      backgroundColor: "#D9534F",
    },
  },
  tableCell: {
    textAlign: "left",
    borderBottomColor: "white",
    borderTopColor: "white",
    backgroundColor: "white",
  },
  tableCellBody: {
    textAlign: "left",
    borderBottomColor: "white",
    borderTopColor: "white",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 3px 6px #00000029",
  },  
  statusListStyle: {
    float: "right",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "10px",
    marginRight: "0",
  },
  statusBadgeStyle: {
    display: "inline-block",
    padding: "8px 16px",
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    minWidth: "120px",
    border: "2px solid transparent",
  },
  approvedStyle: {
    display: "inline-block",
    padding: "8px 16px",
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    minWidth: "120px",
    border: "2px solid transparent",
    backgroundColor: "#d4f8d4",
    color: "#9cf79c",
    borderColor: "#9cf79c",
  },
  pendingStyle: {
    display: "inline-block",
    padding: "8px 16px",
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    minWidth: "120px",
    border: "2px solid transparent",
    backgroundColor: "#fde8cd",
    color: "#f8d394",
    borderColor: "#f8d394",
  },
  notApprovedStyle: {
    display: "inline-block",
    padding: "8px 16px",
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    minWidth: "120px",
    border: "2px solid transparent",
    backgroundColor: "#f8d7da",
    color: "#f47c88",
    borderColor: "#f47c88",
  },
  imageBoxes: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  imageBox: {
    textAlign: "center",
    width: "28%",
    backgroundColor: "#eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    padding: "10px",
  },
  image: {
    width: "120px",
    height: "90px",
    margin: "20px",
  },
  imgText: {
    margin: "20px",
  }
}));
