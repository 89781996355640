export const environment = {
  production: false,
  client_id: 'senzagro-client',
  client_secret: 'senzagro-secret',
  autoMl:"",
  skipOauth: false,
  chatbotHost:"http://20.62.197.230:8007",
  rootCauseHost:"http://143.198.186.210:6001",
  enableUserLog: true,
  devicePulse: false,
  host: 'https://agro.senzmate.com/dev',
  testHost: 'http://polar.senzmatica.com/service',
  azureAd: {
    clientId: '61f3d41a-a359-49a4-9b01-8237a0e868b6',
    tenantId: 'common',
  },
  azurepackageFreeId: "plan_free_001",
  azurepackageBasicId: "plan_basic_001",
  azurepackagePremiumId: "plan_premium_001",
  azurePackageUpgradeLink:"",
  azurepageLink: "",
  contactUsLink: "",

  firebase: {
    apiKey: 'AIzaSyA9uKFEIqgW63abTdvYTqb3gS6r-60FRpI',
    authDomain: 'senzagro-c3d33.firebaseapp.com',
    databaseURL: 'https://senzagro-c3d33.firebaseio.com',
    projectId: 'senzagro-c3d33',
    storageBucket: 'senzagro-c3d33.appspot.com',
    messagingSenderId: '283658892245',
    appId: '1:283658892245:web:e206dc51d4a2719e75c35c',
    measurementId: 'G-E5NKHDMS7N'
  },
  useFirebase:true
};
