import {Directive, ElementRef, Input} from '@angular/core';
import {ACCESS_TYPE, SECTIONS} from './models';
import {AuthService} from './authentication/auth.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective {

  @Input() section: SECTIONS;
  @Input() accessType: ACCESS_TYPE;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (AuthService.hasAccess(this.section, this.accessType)) {
      // this.el.nativeElement.style.visibility = 'visible';
      this.el.nativeElement.style.display = 'initial';
    } else if (AuthService.hasNewUserAccess(this.section, this.accessType)) {
      this.el.nativeElement.style.display = 'initial';
    } else {
      this.el.nativeElement.style.opacity='0.3';
      this.el.nativeElement.style.pointerEvents = 'none';
      this.el.nativeElement.style.color = '#a2a6ac'
    }
  }

}
