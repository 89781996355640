import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import * as React from 'react'
import { alpha } from '@material-ui/core/styles'
import useStyles from '../../assets/styles';

export const DateTimeSelectorComponent = (props) =>{
     const classes = useStyles()
    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={props.selectedDate}
                onChange={props.handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                style={{ width: '150px', marginTop: '0px',marginRight:'10px' }}
                keyboardIcon={<CalendarTodayIcon style={{ fontSize: 16 }}/>}  
            />

            <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                value={props.selectedDate}
                onChange={props.handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                style={{ width: '150px', marginTop: '0px' }}
                keyboardIcon={<AccessTimeIcon style={{ fontSize: 18 }} />} 
            />
        </MuiPickersUtilsProvider>
    )
}