import { Grid, IconButton, Table, TableBody, TableCell, TableHead,TableContainer, TablePagination, TableRow, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import useStyles from "src/app/maintenance/assets/styles";
import { FilterBatchNumber } from "src/app/maintenance/test-automation/components/FilterBatchNumber";
import { TrashIcon } from "src/components/Icons";
import { SearchBar } from "src/components/sub-components/SearchBar";

export const DeviceTable = ({filterDevices, toggleFilter, handlePrefixSelect, filterVisible, setFilterVisible, prefixes, selectedPrefix, filterBatch, setSelectedPrefix, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, searchTerm, handleSearch, handleDeleteIcon, isDelete}) => {
    const deviceCount = Array.isArray(filterDevices) ? filterDevices.filter((device) => device.id.toLowerCase().includes(searchTerm.trim().toLowerCase())).length : 0;
    const totalDevicePages = Math.ceil(deviceCount / rowsPerPage);
    const classes = useStyles();
    return (
        <div>
            <Grid container className={classes.backgroundContainer}>
                <Grid item xs={6} md={3} style={{marginRight:'10px'}}>
                    <SearchBar onChange={handleSearch}/>
                </Grid>
                <Grid item xs={6} md={2}>
                    <FilterBatchNumber 
                        toggleFilter={toggleFilter} 
                        handlePrefixSelect={handlePrefixSelect} 
                        filterVisible={filterVisible} 
                        setFilterVisible ={setFilterVisible}
                        prefixes = {prefixes}
                        selectedPrefix={selectedPrefix}
                        filterBatch={filterBatch}
                        setSelectedPrefix ={setSelectedPrefix}
                        filterType="batchNumber"
                    />
                </Grid>  
                <TablePagination style={{marginRight: '8px', marginTop: '10px'}}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={deviceCount}
                    rowsPerPage={rowsPerPage}
                    page={Math.min(page, Math.max(totalDevicePages - 1, 0))}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> 
                <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={classes.tableHeader}>Device</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Batch Name</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Added By</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Last Onboarded Time</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Last Seen</TableCell>
                            <TableCell align="center" className={classes.tableHeader}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterDevices.length > 0 ? 
                            (filterDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device,index) =>(
                                <TableRow key={index} style={{backgroundColor: index % 2 == 1 ? '#F5F5F5':'#FFFFFF'}}>
                                    <TableCell >{device.id}</TableCell>
                                    <TableCell>{device.batchNumber && device.batchNumber != '' ? device.batchNumber : 'N/A'}</TableCell>
                                    <TableCell>{device.createdBy && device.createdBy != '' ? device.createdBy.username : 'N/A'}</TableCell>
                                    <TableCell>{device.creationDate && device.creationDate != '' ? device.creationDate : 'N/A'}</TableCell>
                                    <TableCell>{device.lastSeen && device.lastSeen != '' ? device.lastSeen : 'N/A'}</TableCell> 
                                    <TableCell style={{display:'flex',justifyContent:'center'}}>
                                    {device.isArchived ? (
                                        <Tooltip
                                        title={
                                          <div className="tooltip-content" style={{ textAlign: 'left', display:'flex', justifyContent:'space-between' }}>
                                            <Typography style={{fontSize:'10px'}}>{device.userInfo ? device.userInfo.username : 'N/A'}</Typography>
                                            <Typography style={{fontSize:'10px'}}>{device.deletedAt}</Typography>    
                                          </div>
                                        }
                                        arrow
                                      >
                                        <span style={{ color: '#868DAA', cursor: 'pointer' }}>Deleted</span>
                                        </Tooltip>
                                    ) : (
                                        <IconButton size="small" onClick={() => handleDeleteIcon(device.id)}>
                                        <TrashIcon />
                                        </IconButton>
                                    )}
                                    </TableCell>
                                </TableRow>
                            )))
                            : (
                            <TableRow>
                                <TableCell colSpan={6} style={{ textAlign:'center' }}>
                                    No Devices Available
                                </TableCell>
                            </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}