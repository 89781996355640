import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {CircularProgress, FormControl, Grid, Typography} from "@material-ui/core";
import {makeStyles, Theme,alpha} from "@material-ui/core/styles";
import {primaryBlue, primaryGray, white} from "src/components/colors";
import {environment} from "src/environments/environment";
import {MQTTProtocol} from "../MQTTProtocol";
import {HTTPProtocol} from "../HTTPProtocol";
import {DropDown} from "src/app/setup-senzmatica/step-3/components/DropDown";
import {AlertBar} from "src/components/sub-components/AlertBar";
import axios from "axios";
import { ViewProductType } from "../../../product-types/configure-product/ViewProductType";
import { DownArrowIcon } from "src/components/Icons";
import useStyles from "src/app/maintenance/assets/styles";
import Cookies from "js-cookie";

export default function Step1ConnectDevice({
                                             formData,
                                             setFormData,
                                             setDeviceName,
                                             setInterval,
                                             setDeviceId,
                                             setProductType,
                                             setProductTypeId,
                                             selectedBatch,
                                             setOTATopic,
                                             setOTAUpgradableTopic,
                                             nameError,
                                             idError,
                                             batchError,
                                             productTypeError,
                                             intervalError,
                                             setNameError,
                                             setIdError,
                                             setProductTypeError,
                                             setIntervalError,
                                             setBatchError,
                                             setOTATopicError,
                                             setOTAUpgradbleTopicError,
                                             OTATopicError,
                                             OTAUpgradbleTopicError,
                                             setOtaUpgradableStatus,
                                             setRemoteConfigTopic,
                                             setRemoteConfigTopicError,
                                             setRemoteConfigAckTopic,
                                             setRemoteConfigAckTopicError,
                                             RemoteConfigTopicError,
                                             RemoteConfigAckTopicError,
                                             setRemoteConfigStatus,
                                             setAddCredentialsSuccess,
                                             credentialsError,
                                             setCredentialsError,
                                             setClientId,
                                             setSelectedBatch,
                                             setCodecId,
                                             setSelectedProtocol,
                                             OTAAckFormatError,
                                             OTARequestFormatError,
                                             remoteConfigFormatError,
                                             remoteConfigAckFormatError,
                                             notSameIdError
                                           }) {
  const classes = local();
  const style = useStyles();
  const [loading, setLoading] = useState(false);
  const [protocol, setProtocol] = useState("");
  const [productTypes, setProductTypes] = useState([]);
  const [allProductTypeData, setAllProductTypeData] = useState([])
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedDataFormat, setSelectedDataFormat] = useState('');
  const [productTypeIds, setProductTypeIds] = useState([]);
  const [productProtocols, setProductProtocols] = useState([]);
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [otaUpgradable, setOtaUpgradable] = useState(false);
  const [remotelyConfigurable, setRemotelyConfigurable] = useState(false);
  const [select, setSelect] = useState(false);
  const [numericalError, setNumericalError] = useState(false);
  const [isAddBatchClick, setIsAddBatchClick] = useState(false);
  const [codecs, setCodecs] = useState([])
  const [isEyeIconClick, setIsEyeIconClick] = useState(false);
  const [selectedProductTypeDetails, setSelectedProductTypeDetails] = useState(null);


  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    fetchCodecs()
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchProductTypes();
    fetchBatchNumbers();
  }, []);

  // Fetch product types from the API endpoint
  const fetchProductTypes = () => {
    setLoading(true);
    axios.get(`${environment.host}/core/products`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (isMounted.current) {
          if (Array.isArray(response.data.content)) {
            setAllProductTypeData(response.data.content)
            const productNames = response.data.content.map(productType => productType.productName);
            const productIDs = response.data.content.map(productType => productType.id);
            const productProtocols = response.data.content.reduce((protocols, productType) => {
              protocols[productType.id] = productType.protocol;
              return protocols;
            }, {});
            setProductTypes(productNames);
            setProductTypeIds(productIDs);
            setProductProtocols(productProtocols);
            setLoading(false);
          } else {
            console.error('Invalid response format');
            setLoading(false);
          }
        }
      })
      .catch(error => {
        if (isMounted.current) {
          console.error('Error fetching product types:', error);
          setLoading(false);
        }
      });
  };


  // Fetch batch numbers from the API endpoint
  const fetchBatchNumbers = () => {
    axios.get(`${environment.host}/core/user/${Cookies.get('USER_ID')}/deviceTest/batchNumbers`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
      },
    })
      .then((response) => {
        if (isMounted.current) {
          setBatchNumbers(response.data.content);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          console.error('Error fetching batch numbers:', err);
        }
      });
  };

  const fetchCodecs = () => {
      const accessToken = Cookies.get("ACCESS_TOKEN");
      axios.get(`${environment.host}/core/codec`,{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(res => {
          if (res.status == 200) {
            const data = res.data.content
            setCodecs(data)
          } else {
            console.error('Failed to fetch codecs:', res.data.message)
          }

        })
        .catch(error => {
          console.error('Error fetching codecs:', error);
        })
  };

  const updateRemoteConfigurations = (deviceName,remoteConfigurations) => {

    // Regular expression to match any text inside curly braces
    const regex = /{([^}]+)}/g;
  
    let updatedRemoteConfigurations = remoteConfigurations.map(config => {
      if (regex.test(config.defaultValue)) {
        return {
          ...config,
          defaultValue: config.defaultValue.replace(regex, deviceName)
        };
      }
      return config;
    });
   return updatedRemoteConfigurations;
  };
  
 
  
  

  const handleProductTypeChange = (event) => {
    const selectedProductTypeName = event.target.value;

    const selectedProductTypeId = productTypeIds.find((id, index) => productTypes[index] === selectedProductTypeName);

    const selectedProductTypeData = allProductTypeData.filter((p) => p.id === selectedProductTypeId)[0];
    console.log(selectedProductTypeData);

    if (selectedProductTypeId !== undefined) {
      const selectedProtocol = productProtocols[selectedProductTypeId];
      let deviceParameterConfiguration ={};
      deviceParameterConfiguration["device"]=formData.name;
      deviceParameterConfiguration["versionNum"]="0.0.0";
      deviceParameterConfiguration["productType"]=selectedProductTypeName;
      deviceParameterConfiguration["remoteConfigurations"]=updateRemoteConfigurations(formData.name,selectedProductTypeData.versions[0]["remoteConfigurations"]);


      setSelectedProductType(selectedProductTypeName);
      setProtocol(selectedProtocol);
      setSelectedProtocol(selectedProtocol);
      setOtaUpgradable(selectedProductTypeData.otaUpgradable)
      setRemotelyConfigurable(selectedProductTypeData.remotelyConfigurable);
      setOtaUpgradableStatus(selectedProductTypeData.otaUpgradable)
      setRemoteConfigStatus(selectedProductTypeData.remotelyConfigurable)

      if (selectedProductTypeData.codecName) {
        const filterCodec = codecs.find(codec => codec.codecName == selectedProductTypeData.codecName)
        setCodecId(filterCodec.id)
      }
      
      setFormData({
        ...formData,
        sensorCodes: selectedProductTypeData.sensorCodes,
        actuatorCodes: selectedProductTypeData.actuatorCodes,
        connectivity: selectedProductTypeData.connectivity,
        persistence: selectedProductTypeData.persistence,
        noOfActuators: selectedProductTypeData.actuatorCodes !== null && selectedProductTypeData.actuatorCodes.length,
        noOfSensors: selectedProductTypeData.sensorCodes !== null && selectedProductTypeData.sensorCodes.length,
        protocol: selectedProtocol,
        batchNumber: selectedBatch,
        productId: selectedProductTypeId,
        deviceParameterConfiguration:deviceParameterConfiguration,
        productType:selectedProductTypeData.productName
      });
      setSelectedProductTypeDetails(selectedProductTypeData);
      setProductType(selectedProductTypeName);
      setProductTypeId(selectedProductTypeId);
      setSelect(true);
      setProductTypeError(false)
    } else {
      console.error('Selected product type ID not found.');
    }
  }

 
  const handleInputChange = (event) => {
    const {name, value} = event.target;
    const trimmedValue = value.trim();
    if (name === 'name') {
      setDeviceName(trimmedValue);
      setNameError(false)
    } else if (name === 'id') {
      setDeviceId(trimmedValue);
      setIdError(false)
    } else if (name === 'batchNumber') {
      setSelectedBatch(trimmedValue);
      setBatchError(false);
    } else if (name === 'otaRequestTopic') {
      setOTATopic(trimmedValue);
      setOTATopicError(false);
    } else if (name === 'otaAckTopic') {
      setOTAUpgradableTopic(trimmedValue);
      setOTAUpgradbleTopicError(false);
    } else if (name === 'remoteConfigTopic') {
      setRemoteConfigTopic(trimmedValue);
      setRemoteConfigTopicError(false);
    } else if (name === 'remoteConfigAckTopic') {
      setRemoteConfigAckTopic(trimmedValue);
      setRemoteConfigAckTopicError(false);
    }

    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const setBatchNumber = (e) => {
    setFormData({
      ...formData,
      batchNumber: e,
    });
  }

  const handleInterval = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setFormData({
        ...formData,
        interval: value
      });
      setNumericalError(false);
      setIntervalError(false)
    } else {
      setNumericalError(true);
      setIntervalError(false);
    }
  };
  const handleSuccess = (success) => {
    if (success) {
      setCredentialsError(false);
      setAddCredentialsSuccess(true);
    } else {
      setCredentialsError(true);
      setAddCredentialsSuccess(false);
    }
  };  
  const handleEyeIcon = () => {
    setIsEyeIconClick(true);
  }
    

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      console.log(e.target.value)
      setIsAddBatchClick(false);
      setSelectedBatch(e.target.value)
      setBatchError(false);
      setFormData({
        ...formData,
        batchNumber: e.target.value
      });
    }
  }

  return (
    <div className={`testing`}>
    <Grid container className={`${style.root} ${style.formContent}`}>    
    <Grid container alignItems="center" spacing={2}>
  <Grid item xs={12} md={12}>
    <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography className={style.typo}>
                Device Name
                <span className={style.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder="Enter Device Name"
                  name="name"
                  onChange={(e) => {
                    handleInputChange(e);
                    setDeviceName(e.target.value.trim());
                  }}
                />
                {nameError && (
                  <span className="form-error">
                    Device Name is Required
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography className={style.typo}>
                Device ID (IMEI)
                <span className={style.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder="Enter ID (IMEI)"
                  name="id"
                  onChange={(e) => {
                    handleInputChange(e);
                    setDeviceId(e.target.value.trim());
                  }}
                />
                {idError && (
                  <span className="form-error">
                    Device ID is Required
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={12}>
              <Typography className={style.typo}>
                Product Type
                <span className={style.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.input}>
                <select
                  style={{appearance:"none"}}
                  className={style.formInput}
                  value={selectedProductType}
                  onChange={handleProductTypeChange}
                >
                  <option value="" disabled hidden>Select Product Type</option>
                  {productTypes.map((productName, index) => (
                    <option key={index} value={productName}>
                      {productName}
                    </option>
                  ))}
                </select>
                <div className={classes.icon}>
                 <DownArrowIcon />
                </div>
                {productTypeError && (
                  <span className="form-error">
                    Product Type is Required
                  </span>
                )}
                {
                  selectedProductType && (
                    <Grid container justifyContent='flex-end'style={{ cursor:'pointer'}} onClick= {() => handleEyeIcon()}>
                      <EyeIcon  width={"22"} height={"20"} />
                    </Grid>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      {otaUpgradable && (
        <>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={12}>
                <Typography className={style.typo}>
                  OTA Config Topic
                  <span className={style.required}>*</span> 
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    placeholder="Enter OTA Topic"
                    name="otaRequestTopic"
                    onChange={(e) => {
                      handleInputChange(e);
                      setOTATopic(e.target.value.trim());
                    }}
                  />
                  {OTATopicError &&(
                    <span className="form-error">
                      OTA Config Topic is required
                    </span>
                  )}
                  {!OTATopicError&&OTARequestFormatError.error&&(
                  <span className="form-error">
                    {OTARequestFormatError.errorMsg}
                  </span>)}
                  {notSameIdError.error&&(
                  <span className="form-error">
                   {notSameIdError.errorMsg}
                  </span>)}
                 
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={12}>
                <Typography className={style.typo}>
                   OTA Config Ack Topic
                  <span className={style.required}>*</span> 
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    placeholder="Enter OTA Acknowledgement Topic"
                    name="otaAckTopic"
                    onChange={(e) => {
                      handleInputChange(e);
                      setOTAUpgradableTopic(e.target.value.trim());
                    }}
                  />
                  {OTAUpgradbleTopicError && (
                    <span className="form-error">
                      OTA Config Ack Topic is Required
                    </span>
                  )}
                  {!OTAUpgradbleTopicError&&OTAAckFormatError.error&&(
                  <span className="form-error">
                  {OTAAckFormatError.errorMsg}
                  </span>)}
                  {notSameIdError.error&&(
                  <span className="form-error">
                   {notSameIdError.errorMsg}
                  </span>)}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
        </>
      )}

{remotelyConfigurable && (
        <>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography className={style.typo}>
                  remoteConfig Topic
                  <span className={style.required}>*</span> 
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    placeholder="Enter remoteConfig Topic"
                    name="remoteConfigTopic"
                    onChange={(e) => {
                      handleInputChange(e);
                      setRemoteConfigTopic(e.target.value.trim());
                    }}
                  />
                  {RemoteConfigTopicError && (
                    <span className="form-error">
                      remoteConfig Topic is Required
                    </span>
                  )}
                  {!RemoteConfigTopicError&&remoteConfigFormatError.error&&(
                  <span className="form-error">
                  {remoteConfigFormatError.errorMsg}
                  </span>)}
                  {notSameIdError.error&&(
                  <span className="form-error">
                   {notSameIdError.errorMsg}
                  </span>)}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography className={style.typo}>
                  remoteConfig Ack Topic
                  <span className={style.required}>*</span> 
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.input}>
                  <input
                    className={style.formInput}
                    type="text"
                    placeholder="Enter remoteConfig Acknowledgement Topic"
                    name="remoteConfigAckTopic"
                    onChange={(e) => {
                      handleInputChange(e);
                      setRemoteConfigAckTopic(e.target.value.trim());
                    }}
                  />
                  {RemoteConfigAckTopicError && (
                    <span className="form-error">
                      remoteConfig Ack Topic is Required
                    </span>
                  )}
                  {!RemoteConfigAckTopicError&&remoteConfigAckFormatError.error&&(
                  <span className="form-error">
                 {remoteConfigAckFormatError.errorMsg}
                  </span>)}
                  {notSameIdError.error&&(
                  <span className="form-error">
                   {notSameIdError.errorMsg}
                  </span>)}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
        </>
      )}

      {!select && (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={12}>
                <Typography className={style.typo}>
                  Protocol
                  <span className={style.required}>*</span> 
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.input}>
                  <Typography className={style.formInput}
                              style={{backgroundColor: "white"}}>
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      )}

      {select && (
        <>
          {protocol === "MQTT" && (
            <MQTTProtocol
              protocol={protocol}
              onSuccess={handleSuccess}
              credentialsError={credentialsError}
              setClientId={setClientId}
            />
          )}
          {protocol === "HTTP" && (
            <HTTPProtocol
              protocol={protocol}
              onSuccess={handleSuccess}
              credentialsError={credentialsError}
              setClientId={setClientId}
            />
          )}
        </>
      )}

<Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={12}>
              <Typography className={style.typo}>
                Interval (minutes)
                <span className={style.required}>*</span> 
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder="Enter Interval"
                  name="interval"
                  onChange={(e) => {
                    handleInterval(e);
                    setInterval(e.target.value);
                  }}
                />
                {!numericalError && intervalError && (
                  <span className="form-error">
                    Add Interval is Required
                  </span>
                )}
                {numericalError && (
                  <span className="form-error">
                    Interval should be numerical
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={12}>
              <Typography className={style.typo}>
                Batch 
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.input}>
                <DropDown
                  options={batchNumbers.map(batch => batch)}
                  type="batchNumber"
                  emptyTag='Select Batch Number'
                  addTag='Add Batch Number'
                  isAddRequired={true}
                  setSelectOption={setSelectedBatch}
                  selectOption={selectedBatch}
                  isSingle={true}
                  value={selectedBatch}
                  setIsAddClick={setIsAddBatchClick}
                  inputStyle={{fontSize:'14px'}}
                  applyCustomPlaceholder={true}
                  setBatchNumber={setBatchNumber}
                />
                {isAddBatchClick && (
                  <input
                    className={style.formInput}
                    type="text"
                    placeholder="Enter New Batch"
                    name="batchNumber"
                    onChange={handleInputChange}
                    onKeyDown={(e) => keyPress(e)}
                  />
                )}
                {batchError && (
                  <span className="form-error">
                    Batch Number is Required
                  </span>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isEyeIconClick && selectedProductTypeDetails && (
        <ViewProductType
          open={isEyeIconClick}
          onClose={() => setIsEyeIconClick(false)}
          productType={selectedProductTypeDetails}
          opacity={0.7}
        />
      )}
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
      </Grid>
    </div>

  );
};

const local= makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  icon:{
     position: "absolute",
     height:"100%",
     top:"5px",
     right: "10px",
     pointerEvents: "none",
  },
}));

const EyeIcon = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 4.5C10.2044 4.5 9.44129 4.81607 8.87868 5.37868C8.31607 5.94129 8 6.70435 8 7.5C8 8.29565 8.31607 9.05871 8.87868 9.62132C9.44129 10.1839 10.2044 10.5 11 10.5C11.7956 10.5 12.5587 10.1839 13.1213 9.62132C13.6839 9.05871 14 8.29565 14 7.5C14 6.70435 13.6839 5.94129 13.1213 5.37868C12.5587 4.81607 11.7956 4.5 11 4.5ZM11 12.5C9.67392 12.5 8.40215 11.9732 7.46447 11.0355C6.52678 10.0979 6 8.82608 6 7.5C6 6.17392 6.52678 4.90215 7.46447 3.96447C8.40215 3.02678 9.67392 2.5 11 2.5C12.3261 2.5 13.5979 3.02678 14.5355 3.96447C15.4732 4.90215 16 6.17392 16 7.5C16 8.82608 15.4732 10.0979 14.5355 11.0355C13.5979 11.9732 12.3261 12.5 11 12.5ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0Z"
        fill={props.color}/>
    </svg>

  )
}
