import { ThemeProvider, createTheme, Grid, Table, TableBody, TableCell, TableHead, TableRow, IconButton, TablePagination } from '@material-ui/core';
import * as React from 'react'
import { NonSuccessCriteriaIcon, TrashIcon} from 'src/components/Icons';
import { BackToHomeComponent } from '../maintenance/test-automation/components/BackToHome';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';
import { useEffect, useState } from 'react'
import { deleteTestDevice, fetchBatchNumbers, getTestDevices } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { DeletePopup } from '../maintenance/test-automation/components/DeletePopup';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';

export const DevicePulseDevicesComponent = ({}) =>{
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });

    // const [toggleFilter,setToggleFilter] = useState(false)
    const [filterVisible,setFilterVisible] = useState(false)
    const [prefixes,setPrefixes] = useState([])
    const [selectedPrefix,setSelectedPrefix] = useState('')
    const [testDevices,setTestDevices] = useState([])
    const [searchTerm,setSearchTerm] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterDevices,setFilterDevices] = useState([])
    const [isDelete,setIsDelete] = useState(false)
    const [selectedDeviceId,setSelectedDeviceId] = useState('')
 
    //Loader
    const [loading,setLoading] = useState(false)

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false) 

    const handleSearch = (event) =>{
        const {value} = event.target
        const lowerCaseSearchTerm = value.toLowerCase();
        let filterDevice = testDevices.filter(device => {
            const matchesPrefix = selectedPrefix === 'All' || device.batchNumber.startsWith(selectedPrefix);
            const matchesSearch = device.deviceId.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        })
        setFilterDevices(filterDevice)
        setPage(0)
    }

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const filterBatch = () =>{  
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = testDevices.filter(device => {
            const matchesPrefix = selectedPrefix === 'All' || device.batchNumber.startsWith(selectedPrefix);
            const matchesSearch = device.batchNumber.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        })
        console.log(filtered)
        setFilterDevices(filtered);
        setFilterVisible(false)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() =>{
        getDevices()
    },[])

    const formatDate = (dateStr) => {
        if(dateStr != ""){
            const date = new Date(dateStr);
            return date.toISOString().replace("T", " ").substring(0, 19);
        }   
    };

    const getDevices = async () => {
        setLoading(true);
        try {
            let batchNumbers = []
            const devices = await getTestDevices();
            const formattedDevices = devices.map(device => ({
                ...device,
                lastSeen: formatDate(device.lastSeen),
                endDate: formatDate(device.endDate)
            }));
            setTestDevices(formattedDevices);
            setFilterDevices(formattedDevices)
            devices.map(device => {
                if(!batchNumbers.includes(device.batchNumber)){
                    batchNumbers.push(device.batchNumber)
                }
            })
            setPrefixes(batchNumbers)
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    

    const handleDeleteIcon = (id) =>{
        setSelectedDeviceId(id)
        setIsDelete(true)
    }

    const onClose = () =>{
        setIsDelete(false)
    }

    const onDelete = async() =>{
        try{
            setLoading(true)
            const response = await deleteTestDevice(selectedDeviceId)
            if(response){
                setIsToasterOpen(true)
                setReqSuccess(true)
                setToasterMessage("Device Removed Successfully")
            }

        }catch(error){
            setIsToasterOpen(true)
            setReqSuccess(false)
            setToasterMessage(error.message)
        }finally{
            setIsDelete(false)
            setLoading(false)
            await  getDevices()
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
        }
        
        
    }

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px'}}>
                <BackToHomeComponent icon={<NonSuccessCriteriaIcon color={"#2A7BEC"}/>} title={'Devices'} />
                <Grid container style={{marginBottom:'15px'}}>
                    <Grid item xs={6} md={3} style={{marginRight:'10px'}}>
                        <SearchBar onChange={handleSearch}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FilterBatchNumber 
                            toggleFilter={toggleFilter} 
                            handlePrefixSelect={handlePrefixSelect} 
                            filterVisible={filterVisible} 
                            setFilterVisible ={setFilterVisible}
                            prefixes = {prefixes}
                            selectedPrefix={selectedPrefix}
                            filterBatch={filterBatch}
                            setSelectedPrefix = {setSelectedPrefix}
                        />
                    </Grid>  
                    <TablePagination style={{marginRight: '8px', marginTop: '10px'}}
                        rowsPerPageOptions={[]}
                        component="div"
                        count={filterDevices !== undefined && filterDevices.filter((device) => device.deviceId.toLowerCase().includes(searchTerm.trim().toLowerCase())).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />              
                </Grid>
                <Grid container >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width:'20%',fontWeight:'bold'}}>Device</TableCell>
                            <TableCell align="left" style={{width:'20%',fontWeight:'bold'}}>Batch Name</TableCell>
                            <TableCell align="left" style={{width:'20%',fontWeight:'bold'}}>Last Onboarded Time</TableCell>
                            <TableCell align="left" style={{width:'20%',fontWeight:'bold'}}>Last Seen</TableCell>
                            <TableCell style={{width:'20%',fontWeight:'bold'}} align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterDevices.length > 0 ? 
                            (filterDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device,index) =>(
                                <TableRow key={index} style={{backgroundColor: index % 2 == 1 ? '#ECEFF1':'#FFFFFF'}}>
                                    <TableCell style={{width:'20%'}}>{device.deviceId}</TableCell>
                                    <TableCell style={{width:'20%'}}>{device.batchNumber}</TableCell>
                                    <TableCell style={{width:'20%'}}>{device.endDate}</TableCell>
                                    <TableCell style={{width:'20%'}}>{device.lastSeen}</TableCell>
                                    <TableCell style={{display:'flex',justifyContent:'center'}}>
                                        <IconButton size={'small'} onClick={() =>handleDeleteIcon(device.deviceId)}>
                                            <TrashIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )))
                            : (
                            <TableRow>
                                <TableCell colSpan={4} style={{ textAlign:'center' }}>
                                    No Devices Available
                                </TableCell>
                            </TableRow>
                            )
                        } 

                        
                    </TableBody>
                </Table>
            </Grid>
            </Grid>
            <DeletePopup 
                open={isDelete}
                title = {'CONFORMATION'}
                content = {`Are you sure you want delete ${selectedDeviceId} ?`}
                onClose = {onClose}
                onDelete = {onDelete}
            />
            <Loader loading={loading} />
            <ToasterComponent 
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
        </ThemeProvider>
    )
}