import * as React from "react";
import {useEffect, useState} from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {primaryGray, searchBarBorderColor,} from "src/components/colors";
import Dropzone from "react-dropzone";
import {UploadFile} from "src/components/Icons";
import {environment} from "src/environments/environment";
import BrowseButton from "src/components/sub-components/BrowseButton";
import axios from "axios";

export function Step3(props) {
  const classes = useStyles();

  const [existingFlowChart, setExistingFlowChart] = useState([]);

  useEffect(() => {
    axios.get(`${environment.host}/core/product/configDetails`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
          setExistingFlowChart(response.data.content["available flowCharts"][0]);
        }
      })
      .catch((error) => {
        setExistingFlowChart([]);
      });
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        md={12}
        style={{textAlign: "left", marginBottom: "20px"}}
      >
        <Typography className={classes.label}>
          Select Existing Flowchart File
        </Typography>
        <FormControl className={classes.input}>
          <Select
            id="demo-customized-select-native"
            value={props.defaultFlowChart}
            className={classes.input}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: {paper: classes.menuPaper},
            }}
            onChange={(e) => props.readExistingFlowChart(e.target.value)}
            input={<BootstrapInput/>}
          >
            {existingFlowChart.map((chart, i) => (
              <MenuItem value={chart} key={i}>
                <ListItemText primary={chart} style={{color: primaryGray}}/>
              </MenuItem>
            ))}
          </Select>
          {props.flowChartError === true && (
            <FormHelperText className={classes.helperText}>
              Flow Chart Not Selected *
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} style={{textAlign: "left"}}>
        <Typography className={classes.label}>
          Upload the Flowchart file here
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Dropzone
          onDrop={(acceptedFiles) => props.readFlowChart(acceptedFiles)}
        >
          {({getRootProps, getInputProps}) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  border: "1px dashed rgba(117, 117, 117, 0.5)",
                  backgroundColor: "#EDEDED",
                  borderRadius: "8px",
                  height: "150px",
                  padding: "40px",
                }}
              >
                <input {...getInputProps()} />
                <UploadFile/>
                <Typography>
                  <span
                    style={{
                      fontSize: "15px",
                      color: primaryGray,
                    }}
                  >
                    {props.chartFilename === ""
                      ? "Drag & Drop file here to upload or"
                      : props.chartFilename}
                  </span>
                  <BrowseButton
                    isFileSelected={props.chartFilename != ""}
                  />
                </Typography>
              </div>
            </section>
          )}
        </Dropzone>
        {props.flowChartError === true && (
          <FormHelperText className={classes.helperText}>
            Flowchart File not uploaded *
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  input: {
    width: "100%",
    maxWidth: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  label: {
    fontSize: "15px",
    color: primaryGray,
    paddingBottom: "5px",
  },
  menuPaper: {
    maxHeight: "200px",
  },
  helperText: {
    color: "red",
  },
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
