export const  checkDefaultValueFormat=(format,defaultValue,parameterName)=>{
    let result={error:false,errorMsg:""}

    if(!(defaultValue.length>0)){
      result={error:true,errorMsg:`${parameterName} is required`}
        return result;
    }
    format = format.toLowerCase();
    switch (format) {
        case "string":
          if (isNumeric(defaultValue)) {
            result={error:true,errorMsg:"Input cannot be 'integer' only."}
           }
           break
        case "char":
           if (!isCharValid(defaultValue)){
            result={error:true,errorMsg:"Cannot add more than 1 character"}
           }
            break;
        case "double":
        case "float":
            if (!isNumeric(defaultValue)) {
              result={error:true,errorMsg:"Input must be a numeric value"}
            }
            break;
        case "integer":
            if (!isStrictInteger(defaultValue)) {
              result={error:true,errorMsg:"Input must be an integer"}
            }
            break;
        default:
            break;
    }

    return result;
}
function isCharValid(value) {
    return /^.$/.test(value); // any single character
   // return /^[a-zA-Z]$/.test(value);  //any single alphabet
}
function isNumeric(value) {
  return !isNaN(value) && !isNaN(parseFloat(value));
}
function isStrictInteger(value) {
  if(isNaN(value)){
    return false; // Return false for invalid numbers
  }
  const num = parseFloat(value);
  return Number.isInteger(num) && value.indexOf('.') === -1;
}
