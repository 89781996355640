import React, { useState, useEffect } from "react";
import {Button, createTheme, Grid, IconButton, InputBase, ThemeProvider, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import useStyles from '../../assets/styles';
import { clickBack } from '../../../utils';
import { RedirectIcon } from '../../../../components/Icons';
import { createJiraProject, getJiraProject } from "../components/apiHelper";
import { Loader } from "../components/Loader";
import { ToasterComponent } from "../components/Toaster";
import { is } from "date-fns/locale";

export const ITSMConfigurationComponent = () => {
    const theme = createTheme({
        typography: {
            fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();
    const history = useHistory();
    const [jiraBoard, setJiraBoard] = useState("");
    const [jiraURL, setJiraURL] = useState("");
    const [email, setEmail] = useState("");
    const [apiToken, setApiToken] = useState("");
    const [projectKey, setProjectKey] = useState("");
    const [isprojectExist, setIsProjectExist] = useState(false);
    const [projectDetail, setProjectDetail] = useState({
        jiraBoard: "",
        jiraURL: "",
        email: "",
        apiToken: "",
        projectKey: ""
    });
    const [errors, setErrors] = useState({
        nameError: null,
        urlError: null,
        emailError: null,
        tokenError: null,
        keyError:null
    })

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        if(name === "boardName"){
            setJiraBoard(value);
            setErrors({...errors, nameError: null});
        }
        else
        if(name === "url"){
            setJiraURL(value);
            setErrors({...errors, urlError: null});
        }
        else if(name === "email"){
            setEmail(value);
            setErrors({...errors, emailError: null});
        }
        else if(name === "apiToken"){
            setApiToken(value);
            setErrors({...errors, tokenError: null});
        }
        else if(name === "projectKey"){
            setProjectKey(value);
            setErrors({...errors, keyError: null});
        }
    };

    const resetFormFields = () => {
        setJiraURL("");
        setEmail("");
        setApiToken("");
        setProjectKey("");
        setErrors({
            nameError: null,
            urlError: null,
            emailError: null,
            tokenError: null,
            keyError:null
        });
    }

    const handleBack = () => {
        resetFormFields();
        history.push("/TestAutomation");
    }

    const handleRedirect = () => {
        history.push({
            pathname: "/deviceFailure",
            state: { isRedirect: true }
        });
        const event = new CustomEvent('deviceFailureClicked');
        window.dispatchEvent(event);
    }

    useEffect(() => {
        const getProjectDetail = async () => {
            try {
                setLoading(true);
                const response = await getJiraProject();
                if (response.status === 200) {
                    const data = response.data;
                    if(data.jiraBoard !== null && data.jiraBoard !== "" && data.jiraBoard !== undefined) {    
                        setIsProjectExist(true);
                        setProjectDetail(data);
                    }
                } else{
                    console.log("Error fetching data");
                }
            } catch(error) {
            } finally {
                setLoading(false);
            }
        }
        getProjectDetail();
    }, []);

    const validationForm = async () => {
        let isError = false;
        let errors: any = {};
        if(jiraBoard.trim() === ""){
            errors.nameError = "Jira Board Name Required";
            isError = true;
        }    
        if(jiraURL.trim() === ""){
            errors.urlError =  "Jira Url Required";
            isError = true;
        }
        if(email.trim() === ""){
            errors.emailError = "Reporter Email Required";
            isError = true;
        }
        if(apiToken.trim() === ""){
            errors.tokenError = "Api Token Required";
            isError = true;
        }
        if(projectKey.trim() === ""){
            errors.keyError = "Project Key Required";
            isError = true;
        }
        setErrors(errors);
        return isError;
    }

    const handleSubmit = async () => {
        const validationError = await validationForm();
        if (validationError) {
            return;
        }
        const data = {
            jiraBoard: jiraBoard.trim(),
            jiraURL: jiraURL.trim(),
            email: email.trim(),
            apiToken: apiToken.trim(),
            projectKey: projectKey.trim()
        };
        try {
            setLoading(true);
            const response = await createJiraProject(data);
            if(response.status === 200){
                setIsToasterOpen(true);
                setReqSuccess(true);
                setToasterMessage("Configurations Saved Successfully");
                resetFormFields();
                setTimeout(() => history.push("/TestAutomation"), 2000);
            } 
        } catch (error) {
            if(error.status === 422){
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(error.data.message);
            }
        } finally {
            setLoading(false)
            setTimeout(() => setIsToasterOpen(false), 2000);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="testing">
                <Grid container className={classes.pageTitleContainer} style={{paddingLeft:'0px'}}>
                    <IconButton onClick={() => clickBack(history, "/TestAutomation")}>
                        <RedirectIcon />
                    </IconButton>
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>ITSM Configuration - Step 06</Grid>
                </Grid>
                <Grid container className={classes.root}>
                    <Grid item xs={12} md={12}>
                        <Grid container className={classes.formContent}>
                            <Grid container className={classes.dataStreamBoxContainer}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={12}
                                    alignItems="flex-start"
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Grid item xs={12} md={12}>
                                    <Typography className={classes.typo}>
                                        Jira Board Name <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        placeholder="Enter jira borad name here"
                                        name="boardName"
                                        value={isprojectExist ? projectDetail.jiraBoard : jiraBoard}
                                        onChange={handleInputChange}
                                        disabled={isprojectExist}
                                        />
                                    </Grid>
                                    {errors.nameError && (
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        md={12}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        style={{ padding: "0px 8px" }}
                                    >
                                        <Typography className={classes.errorText}>
                                        {errors.nameError}
                                        </Typography>
                                    </Grid>
                                    )}
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        style={{ marginBottom: "20px" }}
                                    >
                                        <Grid item xs={12} md={12}>
                                        <Typography className={classes.typo}>
                                            Jira URL <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputBase
                                            className={classes.formInput}
                                            type="text"
                                            placeholder="Enter jira url here"
                                            name="url"
                                            value={isprojectExist ? projectDetail.jiraURL : jiraURL}
                                            onChange={handleInputChange}
                                            disabled={isprojectExist}
                                            />
                                        </Grid>
                                        {errors.urlError && (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={12}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            style={{ padding: "0px 8px" }}
                                        >
                                            <Typography className={classes.errorText}>
                                            {errors.urlError}
                                            </Typography>
                                        </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        style={{ marginBottom: "20px" }}
                                    >
                                        <Grid item xs={12} md={12}>
                                        <Typography className={classes.typo}>
                                            Reporter's Email
                                            <span style={{ color: 'red' }}>*</span>
                                        </Typography> 
                                        </Grid>                                      
                                        <Grid item xs={12} md={12}>
                                            <InputBase
                                            className={classes.formInput}
                                            type="text"
                                            placeholder="example@gmail.com"
                                            name="email"
                                            value={isprojectExist ? projectDetail.email : email}
                                            onChange={handleInputChange}
                                            disabled={isprojectExist}
                                            />
                                        </Grid>
                                        {errors.emailError && (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={12}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            style={{ padding: "0px 8px" }}
                                        >
                                            <Typography className={classes.errorText}>
                                            {errors.emailError}
                                            </Typography>
                                        </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        style={{ marginBottom: "20px" }}
                                    >
                                        <Grid item container xs={12} md={12}>
                                            <Typography className={classes.typo}>
                                                API Token
                                                <span style={{ color: 'red' }}>*</span>
                                            </Typography>  
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputBase
                                            className={classes.formInput}
                                            type="text"
                                            placeholder="Enter API token here"
                                            name="apiToken"
                                            value={isprojectExist ? projectDetail.apiToken : apiToken}
                                            onChange={handleInputChange}
                                            disabled={isprojectExist}
                                            />
                                        </Grid>
                                        {errors.tokenError && (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={12}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            style={{ padding: "0px 8px" }}
                                        >
                                            <Typography className={classes.errorText}>
                                            {errors.tokenError}
                                            </Typography>
                                        </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        style={{ marginBottom: "20px" }}
                                    >
                                        <Grid item container xs={12} md={12}>
                                            <Typography className={classes.typo}>
                                                Project Key
                                                <span style={{ color: 'red' }}>*</span>
                                            </Typography>  
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputBase
                                            className={classes.formInput}
                                            type="text"
                                            placeholder="Enter project key here"
                                            name="projectKey"
                                            value={isprojectExist ? projectDetail.projectKey : projectKey}
                                            onChange={handleInputChange}
                                            disabled={isprojectExist}
                                            />
                                        </Grid>
                                        {errors.keyError && (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={12}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            style={{ padding: "0px 8px" }}
                                        >
                                            <Typography className={classes.errorText}>
                                            {errors.keyError}
                                            </Typography>
                                        </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: "5px" }} justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                        variant="contained"
                                        className={classes.backButton}
                                        style={{margin: "0px"}}
                                        onClick={handleBack}
                                        >
                                        Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                        variant="contained"
                                        className={`${classes.devicePulseSaveButton} ${classes.button}`}
                                        onClick={isprojectExist ? handleRedirect : handleSubmit}
                                        >
                                        {isprojectExist ? "Edit" : "Submit"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Loader loading={loading} />
            <ToasterComponent 
                reqSuccess={reqSuccess} 
                message={toasterMessage} 
                toaster={isToasterOpen} 
            />
        </ThemeProvider>
    )
}

