import { Dialog, DialogTitle, Grid, IconButton, DialogContent,Button,Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'

export const DialogPopup = (props) =>{
    
    return(
        <Dialog open={props.open} PaperProps={{ style: { width: `${props.dialogWidth}` ,maxWidth : `${props.dialogWidth}`} }}>
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014' }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography style={{fontWeight:'bold'}}>{props.title.toUpperCase()}</Typography>
                    <IconButton onClick={props.onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent style={{margin:'20px 0px 0px 0px'}}>
                {props.dialogContent}
                {props.buttonRequired && <Grid container>
                    <Grid item xs={12} md={12} style={{marginTop: "30px"}}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                <Button variant='outlined' 
                                    style={{marginRight:"10px",width:'110px'}}
                                    onClick={props.onClose}
                                >{props.cancel}</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' style={{backgroundColor:`${props.submitButtonColor}`,color:'#FFFFFF',width:'110px'}} onClick={props.onSubmit}
                                    >{props.submit}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
            </DialogContent>
        </Dialog>
    )
}