import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../authentication/auth.service';
import {AlertLimit, Device, GraphEntity, Kit, KitModel, LoginResponse, MagmaResponse} from '../models';
import {NgxSpinnerService} from 'ngx-spinner';
import {finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  deviceName = new BehaviorSubject('');
  clientId = '';
  clients: any[] = [];

  constructor(private http: HttpClient, private spinner: NgxSpinnerService ) {
  }


  forgotPassword(entity: any) {
    return this.http.post<any>(
      environment.host + '/request-reset-password', entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json'
          }),
      }
    );
  }

  changePassword(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/change-password',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createDevice(device: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/core/device',
      device,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createDeviceWithCreatedBy(device: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/core/user/' + localStorage.getItem("USER_ID") + '/device',
      device,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  getBatches(): Observable<any> {
    return this.http.get<any>(
      environment.host + '/core/deviceTest/batchNumbers',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    )
  }

  runMockDevice(deviceId, clientId): Observable<any> {
    return this.http.put<any>(
      environment.host + '/portal/device/' + deviceId + '/jar-runner?clientId=' + clientId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    )
  }
  updateDeviceMode(deviceId, status): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/device/' + deviceId + '/mode',
      status,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    )
  }

  updateDevice(deviceId, device: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/device/' + deviceId,
      device,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateDeviceWithModifiedBy(deviceId, device: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/user/' + localStorage.getItem("USER_ID") + '/device/' + deviceId,
      device,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateLabels(deviceId, labelChangeDetails: { sensorDTOS: { id: string; label: string; }[]; sensorCodeDTOS: any }): Observable<object> {
    return this.http.put(
      environment.host + '/core/device/' + deviceId + '/sensor/changeLabels',
      labelChangeDetails,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
        }),
      }
    );
  }
  

  getDevice(id): Observable<any> {
    return this.http.get<MagmaResponse<Device>>(
      environment.host + '/core/device/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getBEVersion(): Observable<any> {
    return this.http.get<MagmaResponse<String>>(
      environment.host + '/core/open/info' ,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteDevice(id): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/core/device/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDevices(): Observable<any> {
    return this.http.get<MagmaResponse<Device[]>>(
      environment.host + '/core/device',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + AuthService.getAccessToken()
        })
      }
    )
  }


  getFavouriteDevices(): Observable<any> {
    return this.http.get<MagmaResponse<Device[]>>(
      environment.host + '/core/user/' + localStorage.getItem("USER_ID") + '/device/favourite',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  addOrRemoveFavourite(id, action): Observable<any> {
    return this.http.put<MagmaResponse<Device[]>>(
      environment.host + '/core/user/' + localStorage.getItem("USER_ID") + '/device/' + id + "?action=" + action,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }


  getOneDevice(coperateId, filter, favourite): Observable<any> {
    const userId = localStorage.getItem('USER_ID')
    if (coperateId === undefined && filter === undefined && favourite === undefined) {
      return this.getDevices();
    }
    if (filter === "SensorFailure&sensor" || filter === "ActiveSensors&sensor" || filter === "WrongValued&sensor") {
      return this.http.get<MagmaResponse<Device[]>>(
        environment.host + "/user/" + userId + "/corporate/" + coperateId + "/corporateDevices?dataFilter=" + filter + "=" + favourite,
        {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + AuthService.getAccessToken()
            })
        }
      );
    } else {
      return this.http.get<MagmaResponse<Device[]>>(
        environment.host + "/user/" + userId + "/corporate/" + coperateId + "/corporateDevices?dataFilter=" + filter + "&favouriteFilter=" + favourite,
        {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + AuthService.getAccessToken()
            })
        }
      );
    }
  }

  createKit(kit: any): Observable<any> {
    const kitModelId = kit.kitModel[0].item_id;
    return this.http.post<any>(
      environment.host + '/core/kit-model/' + kitModelId + '/kit',
      kit,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createKitWithCreatedBy(kit: any): Observable<any> {
    const kitModelId = kit.kitModel[0].item_id;
    return this.http.post<any>(
      environment.host + '/core/user/' + localStorage.getItem("USER_ID") + '/kit-model/' + kitModelId + '/kit',
      kit,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateKit(kitId, kit: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/kit/' + kitId,
      kit,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateKitWithModifiedBy(kitId, kit: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/user/' + localStorage.getItem("USER_ID") + '/kit/' + kitId,
      kit,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  getKits(): Observable<any> {
    return this.http.get<MagmaResponse<Kit[]>>(
      environment.host + '/core/kit',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getKit(id): Observable<any> {
    return this.http.get<MagmaResponse<Kit>>(
      environment.host + '/core/kit/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteKit(id): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/core/kit/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createKitModel(kitModel: any): Observable<any> {

    if (kitModel.properties.includes('B')) {


    }
    return this.http.post<any>(
      environment.host + '/core/kit-model',
      kitModel,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateKitModel(kitModelId, kitModel: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/kit-model/' + kitModelId,
      kitModel,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  getKitModel(id): Observable<any> {
    return this.http.get<MagmaResponse<KitModel>>(
      environment.host + '/core/kit-model/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteKitModel(id): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/core/kit-model/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getKitModels(): Observable<any> {
    return this.http.get<MagmaResponse<KitModel[]>>(
      environment.host + '/core/kit-model',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createAlertLimitsForKit(kitId, alertLimit: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/core/kit/' + kitId + '/property/1/alert-limit',
      alertLimit,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  getOfflineAlertsOfKit(kitId, from, to): Observable<any> {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/kit/' + kitId + '/offline-alerts' + '?from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updatePersistence(kitId, value: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/kit/' + kitId + '/persistence',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updateMaintenance(kitId, value: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/kit/' + kitId + '/maintain',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getMetaData(): Observable<any> {
    // return [];
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/meta-data',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  // from polar project service

  getProject(projectId): Observable<any> {
    return this.http.get<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getAlertLimitForProperty(deviceId, propertyId): Observable<MagmaResponse<AlertLimit[]>> {
    return this.http.get<MagmaResponse<AlertLimit[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/property/' + propertyId + '/alert-limit',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDeviceHistory(deviceId, from, to, factor): Observable<MagmaResponse<GraphEntity[]>> {
    return this.http.get<MagmaResponse<GraphEntity[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/property/' + factor + '?from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDeviceHistoryAll(deviceId, from, to): Observable<MagmaResponse<GraphEntity[]>> {
    return this.http.get<MagmaResponse<any>>(
      // tslint:disable-next-line:max-line-length
      environment.host + '/core/kit/' + deviceId + '/graph-kit-history/' + '?from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getSensorData(deviceId, sensorNumber, from, to) {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/device/' + deviceId + '/sensor/' + sensorNumber + '?from=' + from + '&to=' + to + '&order=' + 'DESC',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getMaintenanceData(deviceId, sensorNumber, from, to) {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/device/' + deviceId + '/maintenance/' + sensorNumber + '?from=' + from + '&to=' + to + '&order=' + 'DESC',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getActuatorData(deviceId, sensorNumber, from, to) {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/device/' + deviceId + '/actuator/' + sensorNumber + '?from=' + from + '&to=' + to + '&order=' + 'DESC',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDevicesSummary() {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/devices-summary',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updateConnectivity(deviceId, body: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/core/device/' + deviceId + '/connectivity',
      body,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  getConnectivity(deviceId): Observable<any> {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/core/device/' + deviceId + '/connectivity',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createVmqAcl(inputValue: any): Observable<any> {

    return this.http.post<any>(
      environment.host + '/core/vernemq-acl', inputValue,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    )
  }

  getAllVmqAcl(): Observable<any> {

    return this.http.get<any>(
      environment.host + '/core/vernemq-acl',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    )
  }

  getCurrentAmqAcl(id): Observable<any> {
    return this.http.get(
      environment.host + '/core/vernemq-acl/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        ),
      }
    )
  }

  deleteVmqAcl(id) {
    return this.http.delete(
      environment.host + '/core/vernemq-acl/' + id,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
        })
      }
    )
  }

  updatedVernemq(id, data: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/core/vernemq-acl/' + id,
      data,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    )
  }

  getClientsWithProtocolAndDeviceCount() {
    return this.http.get<any>(
      environment.host + '/core/all-connectivity',
      {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }

  createHttpAclAuth(data: any) {
    return this.http.post<any>(
      environment.host + '/core/http-acl', data, {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }

  updateHttpClient(data, id) {
    return this.http.put<any>(
      environment.host + '/core/http-acl/' + id, data, {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }

  deleteHttpClient(id) {
    return this.http.delete(
      environment.host + '/core/http-acl/' + id, {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }


  getAllHttpAcl() {
    return this.http.get(
      environment.host + '/core/http-acl', {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }

  updateActionKey(id, status) {
    const url = `${environment.host}/core/connectivity/mqtt-status/${id}?status=${status}`;
    return this.http.put<any>(
      url, null, {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }

  updateActionKeyHttp(id, status) {
    const url = `${environment.host}/core/connectivity/httpclient-status/${id}?status=${status}`;
    return this.http.put<any>(
      url, null, {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      }
    )
  }

  getAccessToken(id) {
    return this.http.post<LoginResponse>(environment.host + `/core/http-acl/${id}/access-token`,
      {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }
        )
      })
  }
}
