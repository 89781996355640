import * as React from "react";
import { Box, Button, createTheme, Grid, IconButton, ThemeProvider, Typography } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import useStyles from "../assets/styles";
import { RedirectIcon, RightArrowIcon } from "src/components/Icons";
import { clickBack } from "src/app/utils";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});


const steps = [
  { title: "SenzMatica", description: "SenzMatica is a comprehensive AIoT platform that integrates seamlessly with various AI and IoT device.", pathname:"/ConnectDataStream" },
  { title: "Azure IoT", description: "Azure IoT is a robust platform that enables the connection and management of IoT devices securely", pathname:"/Azure-IoT"},
  { title: "AWS IoT", description: "Coming Soon...", pathname:"" },
  { title: "Rest API", description: "Coming Soon...", pathname:"" }
];

const StepCard = ({ title, description, pathname, disabled }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12}>
      <Box className={classes.dataStreamBoxContainer}>
        <Grid item style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
          <Typography className={classes.dataStreamTitleContainer}>{title}</Typography>
          <Typography className={classes.dataStreamDescription}>{description}</Typography>
        </Grid>
        <div className={classes.buttonContainer} style={{justifyContent: "start", paddingLeft: "10px"}}>
          <Link to={pathname}>
            <Button
              variant="contained"
              className={classes.testAutomationStarted}
              style={{ textTransform: "none" }}
              disabled={disabled}
            >
              Get Started
              <RightArrowIcon />
            </Button>
          </Link>
        </div>
      </Box>
    </Grid>
  );
};

export function DataStreamComponent() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid container className={classes.pageTitleContainer}>
          <IconButton onClick={() => clickBack(history, "/TestAutomation")}>
            <RedirectIcon />
          </IconButton>
          <Grid className={classes.pageTitle} style={{margin:'0px'}}>Connect Data Stream - Step 01</Grid>
        </Grid>
        <Grid container spacing={3}
          style={{
            padding: "0px 20px 30px",
            marginTop: "10px"
          }}
        >
          {steps.map((step, index) => (
            <Grid item xs={12} md={6} key={index}>
              <StepCard title={step.title} description={step.description} pathname={step.pathname} disabled={index === 2 || index === 3}  />
            </Grid>
          ))}
        </Grid>
      </div>
    </ThemeProvider>
  );
}
