import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { primaryBlue, primaryGray, white } from 'src/components/colors';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import { DropDown } from '../components/DropDown';
import { KitModelDropDown } from '../components/KitModelDropDown';
import { CloseIcon, DownArrowIcon, EditIconSmall, EyeIcon, QuestionIconSmall, UpArrow, ViewIcon } from 'src/components/Icons';
import { environment } from 'src/environments/environment';
import { EditKitModel } from '../EditKitModel';
import axios from 'axios';
import { ViewDeviceComponent } from './ViewDevice';
import useStyles from "src/app/maintenance/assets/styles";
import Cookies from "js-cookie";


export function MappedKitModel(props) {
  const classes = localStyle();
  const style = useStyles();
  const [id, setId] = useState("");
  const [kitName, setKitName] = useState("")
  const [selectKitModel, setSelectKitModel] = useState("")
  const [selectKitModelList, setSelectKitModelList] = useState([])
  const [isKitModelAddClick, setKitModelIsAddClick] = useState(false);
  const [alertLevel, setAlertLevel] = useState("")
  const [interval, setInterval] = useState("")
  const [isPersistance, setIsPersistance] = useState(false)
  const [devices, setDevices] = useState([])
  const [isMaintenance, setIsMaintenance] = useState(false)
  const [inputMethod, setInputMethod] = useState("")
  const [isAdvancedOption, setIsAdvancedOption] = useState(false)
  const [soilType, setSoilType] = useState("")
  const [isAnotherKit, setIsAnotherKit] = useState(false)
  const [shiftOperation, setShiftOperation] = useState()
  const [allProperty, setAllProperty] = useState([])
  const [operationsArray, setOperationsArray] = useState([])
  const [metaData, setMetaData] = useState()
  const [isEyeIconClick, setIsEyeIconClick] = useState({ kitModel: false, device: false })
  const [selectModel, setSelectModel] = useState()
  const [selectSensor, setSelectSensor] = useState([])
  const [selectActuator, setSelectActuator] = useState([])
  const [selectDevice, setSelectDevice] = useState({})
  const [sensors, setSensors] = useState([])
  let operations = {}

  //Error
  const [idError, setIdError] = useState({ error: false, errorMessage: '' })
  const [nameError, setNameError] = useState({ error: false, errorMessage: '' })
  const [modelError, setModelError] = useState({ error: false, errorMessage: '' })
  const [alertError, setAlertError] = useState({ error: false, errorMessage: '' })
  const [deviceError, setDeviceError] = useState({ error: false, errorMessage: '' })
  const [intervalError, setIntervalError] = useState({ error: false, errorMessage: '' })
  const [shiftErrors, setShiftErrors] = useState<{ [key: number]: string }>({});
  const [persistance, setPersistance] = useState('');

  const propertyCalMethod = ['ADD', 'SUB', 'MUL', 'DIV'];
  const inputMethods = [
    { name: "Device", value: 'DEVICE' }, 
    { name: "Manual", value: 'MANUAL' }, 
    { name: "Image", value: 'IMAGE' }
  ];
  const theme = createTheme({
    typography: {
      body1: {
        fontSize: '14px',
        fontFamily: 'poppins'
      },
    },
  });
  let user = Cookies.get("USER_ID")

  type Kit = {
    id: string;
    name: string;
    alertLevel: string;
    inputMethod: string;
    devices: number[];
    maintain: boolean;
    persistence: boolean;
    metaData?: { propertyNameMap: string; soilType: string };
    shiftMap?: { [key: number]: { operation: string; meta: { pivot: number } } };
    interval: string;
    kitModel?: any[];
    kitModelId?: string;
    shiftMapArray?: any[];
  };

  useEffect(() => {
    if ((props.kitModelFormType !== 'Add') && props.selectedKit) {
      setId(props.selectedKit[0].id);
      setKitName(props.selectedKit[0].name);
      setSelectKitModel(props.selectedKit[0].model.name);
      setAlertLevel(props.selectedKit[0].alertLevel);
      setInputMethod(props.selectedKit[0].inputMethod);
      setInterval(props.selectedKit[0].interval);
      setDevices(props.selectedKit[0].devices);
      setIsPersistance(props.selectedKit[0].persistence);
      setShiftOperation(props.selectedKit[0].shiftMap);
      setMetaData(props.selectedKit[0].metaData);
      // setSoilType(props.selectModel[0].metaData?.soilType || '');
    }
    handelAdvanceOption();
  }, []);

  const handleKitId = (event) => {
    const id = event.target.value.trim();
    setId(id);
    setIdError({ error: false, errorMessage: '' })
  };

  const handleKitName = (e) => {
    setKitName(e.target.value);
    setNameError({ error: false, errorMessage: '' })
  };

  const handleMaintenance = (e) => {
    setIsMaintenance(e.target.value)
    console.log(e.target.value)
  }

  const handlePersistence = (e) => {
    setIsPersistance(e.target.value)
    console.log(e.target.value)
  }

  const handleAlertLevel = (e) => {
    setAlertLevel(e.target.value)
    setAlertError({ error: false, errorMessage: '' })
  }

  const handleInterval = (e) => {
    setInterval(e.target.value)
    setIntervalError({ error: false, errorMessage: '' })
  }

  const handelEyeIcon = () => {
    setIsEyeIconClick(prevStatus => ({
      ...prevStatus,
      kitModel: true
    }))
    props.setKitModelFormType("Edit")
    let kitModel
    console.log(selectKitModel)
    console.log(props.allKitModels)
    console.log("hello")
    if (selectKitModel == "") {
      console.log(props.lastCreatedKitModel)
      kitModel = props.allKitModels.filter(model => model.name.toLowerCase() == props.lastCreatedKitModel.toLowerCase())

    } else {
      console.log(selectKitModel)
      kitModel = props.allKitModels.filter(model => model.name.toLowerCase() == selectKitModel.toLowerCase())
    }
    console.log(kitModel)
    setSelectModel(kitModel)
  }

  const handelAdvanceOption = () => {
    if (selectKitModel || props.lastCreatedKitModel) {
      const advanceOption = isAdvancedOption
      setIsAdvancedOption(!isAdvancedOption)
      if (advanceOption == false) {
        if (selectKitModel == "") {
          let prop = props.allKitModels
            .filter(model => model.name.toLowerCase() == props.lastCreatedKitModel.toLowerCase());
          setAllProperty(findSensorName(prop[0].properties))
        } else {
          let prop = props.allKitModels
            .filter(model => model.name.toLowerCase() == selectKitModel.toLowerCase());
          setAllProperty(findSensorName(prop[0].properties))
        }
      } else {
      }
    }
  }

  const findSensorName = (codes) => {
    let sensorNames = [];
    codes.forEach(code => {
      let sensor = props.allSensors.find(sensor => sensor.code === code);
      if (sensor) {
        sensorNames.push(sensor.name);
      }
    });

    return sensorNames;
  };

  const handleAnotherKit = () => {
    setIsAnotherKit(!isAnotherKit)
  }

  const removeDevice = (index) => {
    let filterArray = [...devices];
    filterArray.splice(index, 1);
    setDevices(filterArray);
    setDeviceError({ error: false, errorMessage: '' })
  }

  const createKit = () => {
    let errors = []
    let newShiftErrors: { [key: number]: string } = {};

    let addError = (field, message) => {
      errors.push({ field, message });
    };
    let kitModel = props.allKitModels.find(model =>
      model.name.toLowerCase() === (selectKitModel || props.lastCreatedKitModel || "").toLowerCase()
    );
    const method = inputMethod ? inputMethods.filter(method => method.value.includes(inputMethod)).map(method => method.value) : inputMethods[0].value

    let propertyMapName = {}
    let shiftMapValue = {}
    let shiftMapArray = []
    if (isAdvancedOption) {
      allProperty.forEach((property, index) => {
        let value = (document.getElementById(`property${index}`) as HTMLInputElement).value;
        let shiftValue = (document.getElementById(`shiftmap${index}`) as HTMLInputElement).value;
        shiftMapArray.push(shiftValue)
        if (value || (shiftMapArray.length != 0 && operationsArray.length != 0)) {
          propertyMapName[index] = `${value}`
          if (operationsArray[index] && parseInt(shiftValue)) {
            shiftMapValue[index] = { operation: operationsArray[index], meta: { pivot: parseInt(shiftValue) } }
          }
        } else {
          propertyMapName[index] = ``
          shiftMapValue = {}
        }

      })
    }

    let filterDevice = props.allDevices.filter(device => devices.includes(device.name))
    let kit: Kit = {
      id: id,
      name: kitName,
      alertLevel: alertLevel,
      inputMethod: method.toString(),
      devices: filterDevice.map(d => d.id),
      maintain: isMaintenance,
      persistence: isPersistance,
      interval: interval,
    }
    if (props.kitModelFormType !== 'Edit') {
      const kitModelSelected = props.allKitModels.find(s => s.name === selectKitModel);
      kit.kitModelId = kitModelSelected.id;
      kit.kitModel = [{
        item_id: kitModelSelected.id,
        item_name: kitModelSelected.name
      }];
    }
    if (isAdvancedOption) {
      kit = {
        ...kit,
        metaData: { propertyNameMap: JSON.stringify(propertyMapName), soilType: soilType },
        shiftMap: shiftMapValue
      }
    }

    if (id == "") {
      addError("id", "Kit ID is Required")
    }
    if (/[,.#$\[\]]/g.test(id)) {
      addError("id", "Please Enter a Valid Id");
    }

    if (kitName == "") {
      addError("name", "Kit Name is Required")
    }
    if (alertLevel == "") {
      addError("alert", "Alert Level is Required")
    }
    if (isNaN(Number(alertLevel)) && alertLevel !== "") {
      addError("alert", "Alert Level Should Contain Only Numbers")
    }
    if (isNaN(Number(interval)) && interval !== "") {
      addError("interval", "interval Should Contain Only Numbers")
    }
    if (selectKitModel == "" && props.lastCreatedKitModel == "") {
      addError("kitModel", "Selecting a Kit Model is Required")
    }
    if (devices.length == 0) {
      addError("device", "Please Select Atleast One Device")
    }
    if (isAdvancedOption) {
      allProperty.forEach((property, index) => {
        let shiftValue = (document.getElementById(`shiftmap${index}`) as HTMLInputElement).value;
        if (shiftValue) {
          if (!operationsArray[index]) {
            newShiftErrors[index] = "Please Select Atleast One Operation"
          } else {
            delete newShiftErrors[index];
          }
        }
      });
    } else {
      newShiftErrors = {};
    }

    setShiftErrors(newShiftErrors);


    let noOfSensorsInDevice = 0;
    let allSensor = []
    filterDevice.map(device => {
      noOfSensorsInDevice = noOfSensorsInDevice + device.noOfSensors;
      device.sensorCodes.map(sensor => allSensor.push(sensor))
    })
    if (kitModel) {
      if (kitModel.noOfSensors < noOfSensorsInDevice) {
        addError("device", "Number of Sensors in device exceeds Number of Sensors in Kit");
      }

      for (let x = 0; x < kitModel.noOfSensors; x++) {
        if (kitModel.sensors[x] !== allSensor[x] && devices.length !== 0) {
          addError("device", "The sensors on the device do not match the sensors defined in the kit model.");
        }
      }
    } else {
      addError("kitModel", "Select a kit model.");
    }

    if (errors.length > 0 || Object.keys(newShiftErrors).length > 0) {
      errors.forEach((error) => {
        const setError = error.field === "id" ? setIdError : error.field === "name" ? setNameError : error.field == 'device' ? setDeviceError : error.field == 'kitModel' ? setModelError : error.field == 'alert' ? setAlertError : setIntervalError;
        setError({ error: true, errorMessage: error.message });
      });

      return;
    }
    props.setLoading(true)
    if(props.kitModelFormType === "Edit"){
      axios.post(`${environment.host}/core/user/${user}/kit-model/${kitModel.id}/kit`, kit, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`
        },
      })
        .then((response) => {
          if (response.data.message === 'Success') {
            props.setIsToasterOpen(true)
            props.setToasterMessage("Kit Created Successfully")
            props.setReqSuccess(true)
            props.getKitModels()
            setTimeout(() => {
              props.setIsToasterOpen(false);
            }, 4000);
            if (isAnotherKit) {
              setId("")
              setKitName("")
              setSelectKitModel("")
              setDevices([])
              setAlertLevel("")
              setInterval("")
              setIsMaintenance(false)
              setIsPersistance(false)
              setSoilType("")
              setInputMethod("")
              setIsAdvancedOption(false)
              setIsAnotherKit(false)
            } else {
              props.increaseStep()
              console.log(response.data.content.id)
              props.setLastKitId(response.data.content.id)
            }
            props.setLoading(false)
          }
        })
        .catch((error) => {
          props.getKitModels()
          props.setIsToasterOpen(true)
          props.setToasterMessage(error.response.data.message)
          props.setReqSuccess(false)
          props.setLoading(false)
          if (error.response.data.errorCode === 'E4091') {
            props.decreaseStep()
            props.setIsSelectStep3(true)
          }
        })
        .finally(() => {
          setTimeout(() => {
            props.setIsToasterOpen(false);
          }, 4000);
        });
    } else {
      props.handleSubmitKit(kit);
    }
  }

  const handleDropDownChange = () => {
    console.log(shiftOperation)
  }

  const handleCancelButton = () => {
    const isSelectKitModel = props.isSelectKitModel
    const isExistKitModel = props.isExistKitModel
    if (isSelectKitModel) {
      props.setIsApiClick(false)
      props.setIsSelectKitModel(false)
    } else if (!isExistKitModel) {
      props.setIsExistKitModel(true)
      props.setIsSelectStep3(true)
      props.setIsApiClick(false)
    } else {
      props.setSteps([
        {
          text: "Define Kit Model",
          activate: true,
        },
        {
          text: "Mapped Device",
          activate: false,
        },
        {
          text: "Swagger",
          activate: false,
        },
      ])
      props.setIsExistKitModel(true)
    }

  }

  const clearShiftError = (index: number) => {
    setShiftErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      return updatedErrors;
    });
  };

  const viewDevice = (id) => {
    props.setLoading(true)
    const deviceId = props.allDevices.find(device => device.name == id).id
    console.log(deviceId)
    axios({
      url: `${environment.host}/core/device/${deviceId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
      },
    })
      .then(res => {
        const response = res.data.content
        setSelectDevice(response)
        setPersistance(response.persistence.toString())
        setIsEyeIconClick(prevState => ({
          ...prevState,
          device: true
        }))
        if (response.sensorCodes) {
          const sensor = response.sensorCodes.map(val => {
            return props.allSensors.filter(s => s.code === val)[0];
          });
          setSelectSensor(sensor)
        }
        if (response.actuatorCodes) {
          const sensor = response.actuatorCodes.map(val => {
            return props.allActuators.filter(s => s.code === val)[0];
          });
          setSelectActuator(sensor)
        }
        props.setLoading(false);
      })
      .catch(error => {
        props.setLoading(false);
        console.log('Get Device Error', error.message)
      })
  }

  const handleSoilType = (e) => {
    setSoilType(e)
  }

  const handleKitModelValue = (e) => {
    const kitModel = props.allKitModels.find(s => s.name === e);
    setSelectKitModelList(prevKitModels => [
      ...prevKitModels,
      { name: kitModel.name, code: kitModel.code }
    ])
    setSelectKitModel(kitModel.name);
  }

  let device = []
  const handleDevices = (e) => {
    const sensors = props.allDevices.find(device => device.name == e).sensorCodes
    device.push(...devices, e)
    setDevices(device)
    if (device.length != 0) {
      setDeviceError({ error: false, errorMessage: '' })
    }
    sensors.map(s => setSensors([...(props.sensors || []), s]));

  }

  const handleInputMethod = (e) => {
    setInputMethod(e)
  }


  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid container className={style.root}>
          <Grid container className={style.formContent}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Kit ID
                      <span className={style.required}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={classes.input}>
                      <input
                        className={style.formInput}
                        type="text"
                        placeholder="Enter Kit ID"
                        onChange={(e) => handleKitId(e)}
                        value={id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {idError.error ?
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container justifyContent='flex-end'>
                        <Typography className={style.errorText}>{idError.errorMessage}</Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                  :
                  <></>
                }
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Kit Name
                      <span className={style.required}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={classes.input}>
                      <input
                        className={style.formInput}
                        type="text"
                        placeholder="Enter Kit Name"
                        onChange={(e) => handleKitName(e)}
                        value={kitName}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {nameError.error ?
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container justifyContent='flex-end'>
                        <Typography className={style.errorText}>{nameError.errorMessage}</Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                  :
                  <></>
                }
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Kit Model
                      <span className={style.required}>*</span>
                    </Typography>
                  </Grid>

                  {props.kitModelFormType !== 'Edit' && props.allKitModels ?
                    <DropDown 
                        options={props.allKitModels.map(s => s.name)} 
                        type='option'
                        placeholder={'Select Kit Model'} 
                        setSelectKitModel={setSelectKitModel} 
                        isAddRequired={false}
                        isSingle={true} value={selectKitModel}
                        onClick={(event) => handleKitModelValue(event)} />
                    :
                    <Grid item xs={12} md={12}>
                      <FormControl className={classes.input}>
                        <input
                          type="text"
                          value={props.lastCreatedKitModel}
                          className={style.formInput}
                          readOnly={true}
                        />
                      </FormControl>
                    </Grid>
                  }
                </Grid>
                <Grid container style={{ marginTop: '5px' }}>
                  <Grid item xs={12} md={12}></Grid>
                  <Grid item xs={12} md={12} style={{
                    pointerEvents: props.lastCreatedKitModel || selectKitModel ? 'auto' : 'none',
                    opacity: props.lastCreatedKitModel || selectKitModel ? 1 : 0.5,
                    cursor: props.lastCreatedKitModel || selectKitModel ? 'pointer' : 'default'
                  }}>
                    <Grid container justifyContent='flex-end'>
                      <Typography
                        onClick={() => handelEyeIcon()}
                        className={classes.viewKitText}>
                        View Kit Model
                      </Typography>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Alert Level
                      <span className={style.required}>*</span>
                      <Tooltip
                        title="Max no of alerts that the kit model can have"
                        classes={{ tooltip: classes.Tooltip }}
                      >
                        <IconButton style={{ padding: "2px" }}>
                          <QuestionIconSmall />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={classes.input}>
                      <input
                        className={style.formInput}
                        type="text"
                        placeholder="Enter ALert Level"
                        onChange={(e) => handleAlertLevel(e)}
                        value={alertLevel}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {alertError.error ?
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container justifyContent='flex-end'>
                        <Typography className={style.errorText}>{alertError.errorMessage}</Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                  :
                  <></>
                }
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={4} md={3}>
                    <Typography className={style.typo}>
                      interval
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={classes.input}>
                      <input
                        className={style.formInput}
                        type="text"
                        placeholder="Enter interval"
                        onChange={(e) => handleInterval(e)}
                        value={interval}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {intervalError.error ?
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container justifyContent='flex-end'>
                        <Typography className={style.errorText}>{intervalError.errorMessage}</Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                  :
                  <></>
                }
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={4} md={3}>
                    <Typography className={style.typo}>
                      Devices
                      <span className={style.required}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={classes.input}>
                      <Grid container>
                        {devices.length == 0 ?
                          <Grid container>
                            <input
                              className={style.formInput}
                              type="text"
                              placeholder={devices.length != 0 ? "" : "No Devices Added"}
                              style={{
                                height: '80px',
                                marginBottom: '10px',
                                width: '100%',
                                textAlign: 'center',
                                borderRadius: '5px'
                              }}
                              // value={sensorValue}
                              disabled={true}
                            />
                          </Grid>
                          :
                          <Grid container style={{ border: '.75px solid rgba(63, 68, 81, .12156862745098039)', height: '80px', padding: '10px', overflowY: 'auto' }}>
                            {devices.map((sensor, index) => {
                              return (
                                <Grid container key={index} className={style.cloudItem}>
                                  <Grid item className={style.cloudItem_number}>{index + 1}</Grid>
                                  <Grid item className={style.cloudItem_line}></Grid>
                                  <Grid item className={style.cloudItem_text}> {sensor}</Grid>
                                  <Grid item onClick={() => viewDevice(sensor)} style={{ marginRight: '5px', display: 'flex', cursor: 'pointer', }}>
                                    <ViewIcon color="#2A7BEC" />
                                  </Grid>
                                  <Grid item onClick={() => removeDevice(index)} style={{ cursor: 'pointer' }}><CloseIcon color='#2A7BEC' /></Grid>
                                </Grid>
                              )

                            })

                            }
                          </Grid>
                        }
                      </Grid>
                      {props.allDevices && <DropDown
                        options={props.allDevices.map(device => device.name)}
                        type='devices'
                        emptyTag='Add Devices'
                        isAddRequired={false}
                        setDevices={setDevices}
                        devices={devices}
                        setDeviceError={setDeviceError}
                        kitModelFormType={props.kitModelFormType}
                        allDevices={props.allDevices}
                        setSensors={setSensors}
                        sensors={sensors}
                        onClick={(event) => handleDevices(event)}
                      />}

                    </FormControl>
                  </Grid>
                </Grid>
                {deviceError.error ?
                  <Grid container>
                    <Grid item xs={5} md={3}></Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container justifyContent='flex-end'>
                        <Typography className={style.errorText}>{deviceError.errorMessage}</Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                  :
                  <></>
                }
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Persistence
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}>
                    <FormControl>
                      <RadioGroup row defaultValue='true' onChange={(e) => handlePersistence(e)}>
                        <FormControlLabel
                          value="true"
                          control={<Radio className={style.radio} />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio className={style.radio} />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Maintenance
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}>
                    <FormControl>
                      <RadioGroup row defaultValue='false' onChange={(e) => handleMaintenance(e)}>
                        <FormControlLabel
                          value="true"
                          control={<BlueRadio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<BlueRadio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography className={style.typo}>
                      Input Method
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={classes.input}>
                      <DropDown options={inputMethods.map(method => method.name)} type='option'
                        placeholder={inputMethods[0].name} setInputMethod={setInputMethod} isAddRequired={false}
                        isSingle={true} value={inputMethod}
                        onClick={(event) => handleInputMethod(event)} />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: '20px' }}>
              <Grid item xs={4} md={3}>
                <Typography className={classes.text}>Advance Option</Typography>
              </Grid>
              <Grid item xs={7} md={8} style={{ marginTop: '13px', marginLeft: '0px' }}>
                <hr />
              </Grid>
              <Grid item xs={1} md={1} onClick={() => handelAdvanceOption()} style={{ cursor: 'pointer', paddingLeft: '10px' }}>
                {isAdvancedOption ?
                  <Grid item xs={1} md={1}>

                    <UpArrow />
                  </Grid>

                  :
                  <Grid item xs={1} md={1}>
                    <DownArrowIcon />

                  </Grid>
                }
              </Grid>
            </Grid>
            {isAdvancedOption ?
              <>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Typography className={style.typo}>
                          Property Mapping
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid container
                          style={{ border: '0.5px solid #55555580', borderRadius: '5px', backgroundColor: white }}>
                          {allProperty.map((property, index) => (
                            <Grid container key={index} style={{
                              borderBottom: '0.5px solid #55555580',
                              minHeight: '42px',
                              maxHeight: '60px',
                              paddingLeft: '5px'
                            }} alignItems='center'>
                              <Grid item style={{ marginRight: '6px', textAlign: 'left' }}>
                                <Typography>{index + 1}</Typography>
                              </Grid>
                              <Grid item style={{ borderLeft: '0.5px solid #2A7BEC', height: '32px' }}></Grid>
                              <Grid item xs={2} md={4} style={{ textAlign: 'left', paddingLeft: '5px' }}>
                                <Typography>{property} {index + 1}</Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Grid container style={{ border: '0.5px solid #55555580', borderRadius: '5px' }}>
                                  <Grid item xs={7} md={11} style={{ width: '100%', paddingLeft: '5px', textAlign: 'left' }}>
                                    <input
                                      type="text"
                                      style={{ border: 'none', width: '100%' }}
                                      // onChange={(e)=>handlePropertyNameMap(e,index)}
                                      id={`property${index}`}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={1}>
                                    <EditIconSmall />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}


                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} md={3}></Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Typography className={style.typo}>
                          Shift Mapping
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid container
                          style={{ border: '0.5px solid #55555580', borderRadius: '5px', backgroundColor: white }}>
                          {allProperty.map((property, index) => (
                            <Grid container key={index} style={{
                              borderBottom: '0.5px solid #55555580',
                              minHeight: '32px',
                              maxHeight: '60px',
                              padding: '15px 0px 5px 5px',
                              position: 'relative'
                            }}>
                              <Grid item style={{ marginRight: '6px', textAlign: 'left' }}>
                                <Typography>{index + 1}</Typography>
                              </Grid>
                              <Grid item style={{ borderLeft: '0.5px solid #2A7BEC', height: '32px' }}></Grid>
                              <Grid item xs={2} md={4} style={{ textAlign: 'left', paddingLeft: '5px' }}>
                                <Typography>{property} {index + 1}</Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Grid container>
                                  <Grid item xs={4} md={4}></Grid>
                                  <Grid item xs={4} md={4}>
                                    <input
                                      type="text"
                                      placeholder='Enter Value'
                                      style={{ width: '90px' }}
                                      className={classes.mappingDropDown}
                                      id={`shiftmap${index}`}
                                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1} md={1}></Grid>
                                  <Grid item xs={3} md={3} onChange={() => handleDropDownChange()}>
                                    <KitModelDropDown
                                      className={classes.mappingDropDown}
                                      options={propertyCalMethod}
                                      index={index}
                                      // onSelect={(operation) => handleDropDownChange(operation, index)}
                                      setShiftOperation={setShiftOperation}
                                      setOperationsArray={setOperationsArray}
                                      operationsArray={operationsArray}
                                      operations={operations}
                                      clearShiftError={clearShiftError}
                                    />
                                  </Grid>
                                </Grid>
                                {shiftErrors[index] ? (
                                  <Grid container style={{ position: 'absolute', top: '35px', right: '0' }}>
                                    <Grid item xs={5} md={3}></Grid>
                                    <Grid item xs={4} md={12}>
                                      <Grid container justifyContent='flex-end'>
                                        <Typography className={classes.shiftErrorText}>{shiftErrors[index]}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : null
                                }
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} md={3}></Grid>
                </Grid>
              </>
              : <></>
            }
            <Grid container alignItems="center" spacing={2}>
              {props.kitModelFormType === 'Edit' &&
                <Grid item xs={12} md={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <FormControlLabel
                      className={classes.text}
                      label="Add Another Kit"
                      control={
                        <Checkbox
                          className={style.radio}
                          checked={isAnotherKit}
                          onChange={handleAnotherKit} />
                      } />
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Button
                      className={style.backButton}
                      style={{
                        marginRight: '10px',
                      }} onClick={handleCancelButton}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={style.saveButton}
                      onClick={createKit}>Next</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          {isEyeIconClick.kitModel && props.allTypes ?
            <EditKitModel
              setIsApiClick={props.setIsApiClick}
              increaseStep={props.increaseStep}
              decreaseStep={props.decreaseStep}
              allSensors={props.allSensors}
              allActuators={props.allActuators}
              allTypes={props.allTypes}
              allKitModelsName={props.allKitModels}
              setIsToasterOpen={props.setIsToasterOpen}
              setToasterMessage={props.setToasterMessage}
              setReqSuccess={props.setReqSuccess}
              getKitModels={props.getKitModels}
              setLastCreatedKitModel={props.setLastCreatedKitModel}
              setLoading={props.setLoading}
              setIsEyeIconClick={setIsEyeIconClick}
              kitModelFormType={props.kitModelFormType}
              selectModel={selectModel}
              setKitModelFormType={props.setKitModelFormType}
            />
            : isEyeIconClick.device ?
              <ViewDeviceComponent
                selectDevice={selectDevice}
                classes={classes}
                style={style}
                isEyeIconClick={setIsEyeIconClick}
                selectSensors={selectSensor}
                selectActuator={selectActuator}
                radio={<BlueRadio />}
                persistance={persistance}
              />
              :
              <></>

          }
        </Grid>
      </div>
    </ThemeProvider>
  )
}


const localStyle = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif"
  },
  required: {
    color: "red",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  toggleButton: {
    padding: '10px 20px',
    backgroundColor: '#dddddd',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    borderRadius: '50%',
    width: '100px'
  },
  shiftErrorText: {
    color: '#FF4E4E',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    padding: '3px'
  },

  toggleButtonOn: {
    backgroundColor: primaryBlue,
    color: white
  },

  toggleButtonOff: {
    backgroundColor: '#D9D9D9',
    color: white
  },
  mappingDropDown: {
    height: '24px',
    backgroundColor: white,
    padding: '3px 10px',
    border: '0.5px solid #55555580',
    borderRadius: '5px',
    fontSize: '12px',
    fontFamily: 'poppins'
  },
  option: {
    paddingLeft: '10px',
    height: '33px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  Tooltip: {
    backgroundColor: "#334B5A",
    color: 'white',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '12px',
  },
  viewKitText: {
    textDecoration: 'underline',
    fontSize: "12px",
    fontFamily: "poppins",
    color: primaryGray,
    cursor: 'pointer',
    '&:hover': {
      color: primaryBlue,
    },
  },
}));


const BlueRadio = withStyles({
  root: {
    color: primaryGray,
    '&$checked': {
      color: primaryBlue,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
