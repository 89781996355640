import { createTheme, Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Arrow, ChatCardIcon, ChatHistoryIcon, NonSuccessCriteriaIcon } from 'src/components/Icons';

export const ChatHistoryComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const history = useHistory();

    const handleCardClick = () => {
        history.push('/ChatHistory/ChatBot'); 
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ padding: '25px' }}>
                <Grid container style={{ display: 'flex', margin: '50px 0px', alignItems: 'center', backgroundColor: 'white', padding: '15px', width: 'fit-content' }}>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <NonSuccessCriteriaIcon color={"#324054"}/>
                        <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                            <Typography style={{ color: '#858DAA' }}>Setup Device Pulse</Typography>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ color: '#2A7BEC', display: 'flex', alignItems: 'center' }}>
                        <ChatHistoryIcon color="#2A7BEC"/>
                        <Typography style={{ marginLeft: '10px' }}>Chat History</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ display: 'flex', flexDirection: 'column', gap:'20px' }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item style={{ flexDirection: 'row', display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Typography style={{ fontWeight: 'bold', padding: '5px' }}>Today</Typography>
                            <Typography style={{ color:'#949494', padding: '10px', display: 'flex', height: '25px', width: '25px', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', border: '1px solid #D9D9D9' }}>3</Typography>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#2A7BEC" onClick={handleCardClick}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#2A7BEC" onClick={handleCardClick}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#2A7BEC" onClick={handleCardClick}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item style={{ flexDirection: 'row', display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Typography style={{ fontWeight: 'bold', padding: '5px' }}>Yesterday</Typography>
                            <Typography style={{ color:'#949494', padding: '10px', display: 'flex', height: '25px', width: '25px', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', border: '1px solid #D9D9D9' }}>2</Typography>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#02C170" onClick={handleCardClick}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#02C170" onClick={handleCardClick}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item style={{ flexDirection: 'row', display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Typography style={{ fontWeight: 'bold', padding: '5px' }}>Previous 30 Days</Typography>
                            <Typography style={{ color:'#949494', padding: '10px', display: 'flex', height: '25px', width: '25px', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', border: '1px solid #D9D9D9' }}>3</Typography>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#949494" onClick={handleCardClick}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#949494" onClick={handleCardClick}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <CardComponent color="#949494" onClick={handleCardClick}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export const CardComponent = (props) => {
    return (
        <Grid container style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '20px', border: '1px solid #E5E7EB', borderRadius: '15px', margin: '10px 0px', backgroundColor: '#FFFFFF', cursor:'pointer' }} onClick={props.onClick}>
            <IconButton style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid #E5E7EB', borderRadius:'5px', alignContent:'center', padding:'6px', justifyContent: 'center', width: '15%' }}>
                <ChatCardIcon color={props.color}/>
            </IconButton>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>Chat Title</Typography>
            <Typography variant="body1" style={{ color: '#838383' }}>Lorem ipsum dolor sit amet consectetur. Ornare interdum nisi adipiscing...</Typography>
        </Grid>
    );
};