import * as React from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, Grid, DialogContent, Button, DialogActions, Dialog, DialogTitle, Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useStyles from '../../assets/styles';
import { ActionIcon, DetailsIcon } from 'src/components/Icons';
import { fetchMetaData, getRootCauseDetails, getSensorReading, getSuccessCriteria } from '../components/apiHelper';
import { DateAndSummeryReport } from './SummaryGraph'
import { Loader } from '../components/Loader';
import { RootCauseDetailsPopup } from 'src/app/reportHistory/RootCauseDetailsPopup';


// Define the type for the data objects
interface TestDataEntry {
  StartTime: string;
  EndTime: string;
  deviceId: string;
  [key: string]: any;
}

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const dataRestructure = (testSummaryData, mainTestTitle) => {
  return testSummaryData.map(deviceData => {
    const result = {};
    for (const deviceId in deviceData) {
      result[deviceId] = { [mainTestTitle]: {} };
      for (const caseId in deviceData[deviceId]) {
        for (const testName in deviceData[deviceId][caseId]) {
          if (!result[deviceId][mainTestTitle][testName]) {
            result[deviceId][mainTestTitle][testName] = [];
          }
          result[deviceId][mainTestTitle][testName].push(...deviceData[deviceId][caseId][testName]);
        }
      }
    }
    return result;
  });
}

export const DataTable = ({
  selectedMainTestCaseTitle,
  testData,
  selectedTestCaseType,
  loadingClose,
  testCaseType,
  selectedMainTestCaseId,
  step,
  setChatbotData,
  tableRootCause,
  selectedFormattedTime,
  continuousMainTestId,
  selectedBatch
}) => {
  // console.log(tableRootCause)
  const classes = useStyles();
  const [tableHeadData, setTableHeadData] = useState<string[]>([]);
  const [deviceData, setDeviceData] = useState<TestDataEntry[]>([]);
  const [isActionClick, setIsActionClick] = useState(false);
  const [subTasks, setSubTasks] = useState([])
  const [loading, setLoading] = useState(false)
  const [sensorReadingSummary, setSensorReadingSummary] = useState([])
  const [hasData, setHasData] = useState<boolean>(false);
  const [rootCauseData, setRootCauseData] = useState(null);
  const [isDetailsIconClick, setIsDetailIconClick] = useState(false);

  const tableStructure = () => {
    var deviceDataMap = {};
    var testSummary = dataRestructure(testData, selectedMainTestCaseTitle);

    var chatbotResult = []
    Object.entries(testSummary).forEach(([device, mainTestCase]) => {
      Object.entries(mainTestCase).forEach(([deviceId, mainTestArr]) => {
        deviceDataMap[deviceId] = [];
        Object.entries(mainTestArr).forEach(([main, subArr]) => {
          var result = [];
          Object.keys(subArr).forEach((key) => {
            Object.keys(subArr[key]).forEach((testKey) => {
              var index = result.findIndex(
                (obj) =>
                  obj.StartTime === subArr[key][testKey]["subTestCaseStartTime"] &&
                  obj.EndTime === subArr[key][testKey]["subTestCaseEndTime"] &&
                  main === subArr[key][testKey]["mainTestTitle"]
              );
              let failureCount = subArr[key][testKey]["failedCount"];
              if (index !== -1) {
                if (failureCount && failureCount == -1) {
                  result[index][subArr[key][testKey]["subTestTitle"]] = 'No sensor reading'
                } else {
                  result[index][subArr[key][testKey]["subTestTitle"]] =
                    subArr[key][testKey]["testResult"] == 'FAILURE'
                      && (subArr[key][testKey]['subTestTitle'] !== 'No of Data' && subArr[key][testKey]['subTestTitle'] !== 'Average')
                      ?
                      subArr[key][testKey]["testResult"] + `(${failureCount})`
                      : subArr[key][testKey]["testResult"];
                }

              } else {
                var temp: TestDataEntry = {
                  StartTime: subArr[key][testKey]["subTestCaseStartTime"],
                  EndTime: subArr[key][testKey]["subTestCaseEndTime"],
                  deviceId: subArr[key][testKey]["deviceId"],
                  rootCauses: step === 'step7' ? '-' : ''
                };
                if (failureCount && failureCount == -1) {
                  temp[subArr[key][testKey]["subTestTitle"]] = 'No sensor reading'
                } else {
                  temp[subArr[key][testKey]["subTestTitle"]] =
                    subArr[key][testKey]["testResult"] == 'FAILURE'
                      && (subArr[key][testKey]['subTestTitle'] !== 'No of Data' && subArr[key][testKey]['subTestTitle'] !== 'Average')
                      ?
                      subArr[key][testKey]["testResult"] + `(${failureCount})`
                      : subArr[key][testKey]["testResult"];
                }
                result.push(temp);
                chatbotResult.push(temp)
              }
            });
          });
          deviceDataMap[deviceId][main] = result;
        });
      });
    });

    setChatbotData(chatbotResult)
    return deviceDataMap;
  };

  const handleActionButton = async (id, startTime, endTime,testCaseType) => {
    try {
      setLoading(true)
      const data = {
        "deviceId": id,
        "startDate": startTime,
        "endDate": endTime,
        "mainTestCaseTitle": selectedMainTestCaseTitle,
        "testCaseType": testCaseType,
        "batchNumber": selectedBatch
      }
      console.log(data)
      await getSensorsReading(data)
      setIsActionClick(true)
    } catch (error) {
      console.log("Error fetching sensor readings:", error)
    }

  }

  const handleRootCauseDetails = async (id, startTime, endTime) => {
    handleOpenDialog()
    try {
      setLoading(true);
      const rootCauseData = await getRootCauseDetails(
        id,
        startTime,
        endTime,
        continuousMainTestId
      );
      setRootCauseData(rootCauseData);
    } catch (error) {
      console.log("Error fetching sensor readings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setIsDetailIconClick(true);
  };

  const handleCloseDialog = () => {
    setIsDetailIconClick(false);
  };

  const handleCloseWindow = () => {
    setIsActionClick(false)
  }


  const getSensorsReading = async (data) => {
    try {

      //Get Sensors Reading Summary
      const sensorReading = await getSensorReading(data)
      let sensorSummary = { "subTestCase": "", "sensorReadings": [], "dateTime": [], "label": [], "id": [], "deviceId": [] }
      let sensorReadings = []
      sensorReading.map(sensors => {
        let sensorReadingValue = []
        let sensorReadingLabel = [];
        let dateAndTime = []
        let sensorId = [];
        let deviceId = [];
        console.log('sensor Reading', sensorReading);
        if (sensors.sensorReadings.length > 0) {
          sensors.sensorReadings.forEach(value => {
            if (sensors.subTestCaseTitle == 'No of Data') {
              sensorReadingValue.push(value.number)
            } else {
              sensorReadingValue.push(value.value);
              
            }
            dateAndTime.push(value.time);
            sensorReadingLabel.push(value.label || '')
            sensorId.push(value.id)
            deviceId.push(value.deviceId)
          })
        } else {
          sensorReadingValue = []
          dateAndTime = []
          sensorReadingLabel = []
          sensorId = []
          deviceId = []
        }
        sensorSummary = {
          subTestCase: sensors.subTestCaseTitle,
          sensorReadings: sensorReadingValue,
          dateTime: dateAndTime,
          label: sensorReadingLabel,
          id: sensorId,
          deviceId: deviceId

        };
        sensorReadings.push({ ...sensorSummary });
      })

      const rootCause = await getRootCauseDetails(data.deviceId, data.startDate, data.endDate, continuousMainTestId)

      //Get Min Max value of Sensor
      const response = await getSuccessCriteria(selectedMainTestCaseId);
      let sensorRange = []
      response.subTestCases.map(test => {
        let value = {
          maxVal: test.maxVal,
          minVal: test.minVal,
          title: test.subTestTitle,
          successCriteria: test.successCriteria,
          sensorCode: test.sensorCode,
        };
        sensorRange.push(value);
      });
      let combinedData: any[] = [];

      if (response && sensorRange.length !== 0 && sensorReadings.length !== 0) {
        sensorReadings.forEach(sensorReading => {
          let matchingTest = sensorRange.find(test => test.title === sensorReading.subTestCase);

          if (matchingTest) {
            // Combine the data from both sensorReadings and sensorRange
            combinedData.push({
              ...sensorReading,
              maxVal: matchingTest.maxVal,
              minVal: matchingTest.minVal,
              successCriteria: matchingTest.successCriteria,
              sensorCode: matchingTest.sensorCode,
            });
          }
        });
      }
      combinedData.sort((a, b) => {
        if (a.subTestCase.toLowerCase() < b.subTestCase.toLowerCase()) return -1;
        if (a.subTestCase.toLowerCase() > b.subTestCase.toLowerCase()) return 1;
        return 0;
      });

      const metadataResponse = await fetchMetaData();
      const sensorMap = {};
      metadataResponse.forEach((sensor) => {
        sensorMap[sensor.code] = sensor.name;
      });

      const header = combinedData.map((sensor) => {
        const sensorName = sensorMap[sensor.sensorCode] || "Unknown";
        return `${sensor.subTestCase} (${sensorName})`;
      });
      setSensorReadingSummary(combinedData)
      setSubTasks(header)
      console.log(combinedData)

    } catch (error) {
      console.log('Error', error)
    };
    setLoading(false)
  }

  useEffect(() => {
    if (!testData || testData.length === 0) {
      setDeviceData([]);
      setTableHeadData([]);
      setHasData(false);
    } else {
      if (selectedMainTestCaseTitle !== "") {
        if (selectedTestCaseType === "Continuous Testing") {
  
          if (testData && testData.length > 0) {
            setHasData(true);
            const updatedDeviceData = testData.map(deviceData => {
              const updatedDevice = { ...deviceData };
              updatedDevice["Root Causes"] = updatedDevice["mostFrequentRootCauseInTimeRange"];
              delete updatedDevice["mostFrequentRootCauseInTimeRange"];
  
              Object.keys(updatedDevice).forEach(key => {
                if (typeof updatedDevice[key] === "string" && updatedDevice[key].includes("SUCCESS")) {
                  updatedDevice[key] = updatedDevice[key].split("(")[0].trim();
                }
              });
  
              if (updatedDevice["Root Causes"]) {
                updatedDevice["Root Causes"] = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ fontSize: "13px" }}>
                      {updatedDevice["Root Causes"]}
                    </Typography>
                  </div>
                );
              }
              return updatedDevice;
            });
            setDeviceData(updatedDeviceData);
  
            const headers = [
              'deviceId',
              ...Object.keys(updatedDeviceData[0]).filter(
                (key) => !['deviceId', 'StartTime', 'EndTime', 'Action', 'mostFrequentRootCauseInTimeRange'].includes(key)
              ),
              'Action'
            ];
            setTableHeadData(headers);
  
            const subTasks = headers.filter(
              (header) => !['deviceId', 'Action'].includes(header)
            );
            setSubTasks(subTasks);
          }
          loadingClose();
        }
        else {
          const reorganizedData = dataRestructure(testData, selectedMainTestCaseTitle);
          const deviceSet = tableStructure();
  
          let dataArray: TestDataEntry[] = [];
          reorganizedData.forEach((device) => {
            const deviceId = Object.keys(device)[0];
            const dataObj = device[deviceId][selectedMainTestCaseTitle];
            if (dataObj) {
              dataObj["deviceId"] = deviceId;
            }
            dataArray.push(dataObj);
          });
  
          var temp: TestDataEntry[] = [];
          Object.values(deviceSet).forEach((device) => {
            Object.values(device).forEach((test) => {
              test.forEach((entry: TestDataEntry) => {
                temp.push(entry);
              });
            });
          });
  
          // Sort temp array by EndTime in descending order
          temp.sort((a: TestDataEntry, b: TestDataEntry) => {
            const endTimeA = new Date(a.EndTime);
            const endTimeB = new Date(b.EndTime);
            return endTimeB.getTime() - endTimeA.getTime();
          });
  
          if (dataArray[0]) {
            var tableHeaders = Object.keys(dataArray[0]).filter(header => header !== 'deviceId').sort();
            setSubTasks(Object.keys(dataArray[0]).filter(header => header !== 'deviceId').sort())
            tableHeaders.unshift("deviceId");
            step == 'step7' ? tableHeaders.push('Root Causes') : null
            tableHeaders.push("Action");
  
            if (selectedTestCaseType !== "Continuous Testing") {
              tableHeaders.push("StartTime");
              tableHeaders.push("EndTime");
            }
  
            setTableHeadData(tableHeaders);
            temp.map((d) => {
              if (tableRootCause !== undefined && tableRootCause.length > 0) {
                const prediction = tableRootCause.find(p => p.deviceId === d.deviceId);
                if (prediction) {
                  d.rootCauses = prediction.predictions;
                }
              }
            })
            setDeviceData(temp);
            setHasData(temp.length > 0);
          } else {
            setHasData(false);
          }
        }
      }
    }
  }, [testData, selectedTestCaseType, selectedTestCaseType, tableRootCause]);

  return (
    <div>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table" >
          {hasData && tableHeadData.length > 0 && (
            <TableHead>
              <TableRow>
                {tableHeadData.map((topic, i) => (
                  <TableCell
                    align={topic === "deviceId" ? "left" : "center"}
                    key={i}
                    className={classes.customtableCell}
                  >
                    <Typography className={classes.topicText}>
                      {topic === "deviceId" ? "Device Id" : topic}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {hasData ? (
              deviceData.map((test, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#F5F5F5" : "white",
                  }}
                >
                  {tableHeadData.map((head, i) => (
                    <TableCell
                      className={classes.customtableCell}
                      align={head === "deviceId" ? "left" : "center"}
                      key={i}
                      style={{
                        color:
                          test[head] === "SUCCESS"
                            ? "rgba(0, 177, 165, 1)"
                            : typeof test[head] === "string" && (test[head].includes("FAILURE") || test[head] == 'No sensor reading')
                              ? "rgba(255, 67, 67, 1)"
                              : "black",
                              justifyItems: head === "deviceId" ? "flex-start" : "center",
                      }}
                    >
                      {/* {head == "Action" && <ActionGraphIcon />} */}
                      <Typography component="div" className={classes.contentText} style={(head !== "deviceId" && head !== "Action" && head !== "Root Causes") ? typeof test[head] === "string" && (test[head].includes("FAILURE") || test[head] == 'No sensor reading') ?   {backgroundColor:"rgba(255, 67, 67, 0.05)", padding:"7px", borderRadius:"20px"} : {backgroundColor:"rgba(0, 177, 165, 0.1)", padding:"7px", borderRadius:"20px"} : {}}>
                        {head === "Action" ? (
                          <IconButton className={classes.iconStyle}
                            onClick={() => handleActionButton(test.deviceId, test.StartTime, test.EndTime , 'CONTINUOUS')}
                            size="small"
                          >
                            <ActionIcon />
                          </IconButton>
                        ) : head === "Root Causes" ? (
                          <Typography component="span" style={{ fontSize: "13px", display: 'flex', alignItems: 'center' }}>
                            <Typography component="span" style={{ fontSize: "13px", marginRight: 4 }}>
                              {test["rootCauses"] || test["Root Causes"]}
                            </Typography>
                            {test["rootCauses"] !== '-' && test["Root Causes"] !== null && (
                              <Tooltip title="Root Cause Details" arrow>
                                <IconButton
                                  size="small"
                                  onClick={() => handleRootCauseDetails(test.deviceId, test.StartTime, test.EndTime)}
                                >
                                  <DetailsIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Typography>
                        ) : (
                          test[head] || (head === "deviceId" ? test["deviceId"] : "-")
                        )}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={tableHeadData.length}>
                  <Typography className={classes.contentText}>
                    No Data Available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isActionClick &&
        <DateAndSummeryReport
          isClick={isActionClick}
          handleWindowClose={handleCloseWindow}
          headers={subTasks}
          data={sensorReadingSummary}
        />
      }
      {isDetailsIconClick && (
        <RootCauseDetailsPopup
          open={isDetailsIconClick}
          close={handleCloseDialog}
          rootCauseData={rootCauseData}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};
