import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Typography } from '@material-ui/core'
import * as React from 'react'
import { Arrow, BatchesIcon,EditIconLarge, NonSuccessCriteriaIcon, TrashIcon, ViewIcon} from 'src/components/Icons';
import {Link, useLocation, useParams} from 'react-router-dom'
import { useEffect, useState } from 'react';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { Close } from '@material-ui/icons';
import { fetchBatchNumbers, fetchMainTestCases, removeBatch, removeDeviceFromBatch } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../setup-senzmatica/step-3/components/Loader';
import { FilterBatchNumber } from '../maintenance/test-automation/components/FilterBatchNumber';

export const BatchesComponent = () =>{
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
      });
    const [isDeletePopupOpen,setIsDeletePopupOpen] = useState(false)
    const [devices,setDevices] = useState([])
    const [filterDevices,setFilterDevices] = useState([])
    const [isViewBatch,setIsViewBatch] = useState(false)
    const [searchQuery,setSearchQuery] = useState('')
    const [batchNumbers,setBatchNumbers] = useState([])
    const [loading,setLoading] = useState(false)
    const [actionType,setActionType] = useState('')
    const [deleteType,setDeleteType] = useState('')
    const [selectedBatchNumber,setSelectedBatchNumber] = useState('')
    const [selectedDeviceId,setSelectedDeviceId] = useState('')
    const [filterBatchNumbers,setFilterBatchNumbers] = useState([])
    const [searchBatchNumber,setSearchBatchNumber] = useState([])
    const [filterVisible,setFilterVisible] = useState(false)
    const [selectedPrefix,setSelectedPrefix] = useState('')
    const [prefixes,setPrefixes] = useState([])
    const [searchTerm,setSearchTerm] = useState('')

    const handleDeleteIcon = (batch,type) =>{
        if(type == 'batch'){
            setSelectedBatchNumber(batch)
        }else if(type == 'device'){
            setSelectedDeviceId(batch)
        }
        
        setIsDeletePopupOpen(true)
        setDeleteType(type)
    }

    const cancelDeletePopup = () =>{
        setIsDeletePopupOpen(false)
    }

    useEffect(() =>{
        const fetchBatchNumber = async () =>{
            try{
                setLoading(true)
                const response = await fetchBatchNumbers();
                setBatchNumbers(response)
                setFilterBatchNumbers(response)
                getPrefixes(response)
            }catch(err){
                
            }finally{
                setLoading(false)
            }
        }
        fetchBatchNumber()
    },[])

    const getPrefixes = (batchNumbers) =>{
        let prefixes = batchNumbers.map(res =>{
            if(res.includes('_') || res.includes('-')){
                return res.split(/[_-]/)[0]
            }
        }).filter(Boolean)
        setPrefixes(prefixes)
    }

    const handleViewPopup = async (batchNumber,type) =>{
        setLoading(true)
        try{
            const response = await fetchMainTestCases(batchNumber)
            response.uniqueDevices ? setDevices(response.uniqueDevices) : setDevices([])
            setActionType(type)
            setSelectedBatchNumber(batchNumber)
        }catch(err){
            console.log('Fetching device details : ',err)
        }finally{
            setLoading(false)
        }
        
        setIsViewBatch(true)
    }

    const cancelViewPopup = () =>{
        setIsViewBatch(false)
        setSearchQuery('')
    }

    useEffect(() => {
        if (searchQuery === '') {
            // If the search query is empty, show all devices
            setFilterDevices(devices);
        } else {
            // Filter the devices based on the search query
            const results = devices.filter(device =>
                device.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilterDevices(results);
        }
    }, [searchQuery, devices]);

    const handleRemoveBatch = async (type) =>{
        setLoading(true)
        try{
            if(type == 'device'){
                await removeDeviceFromBatch(selectedBatchNumber,selectedDeviceId)
                const response = await fetchMainTestCases(selectedBatchNumber)
                setDevices(response.uniqueDevices)
            }else if(type == 'batch'){
                await removeBatch(selectedBatchNumber)
                const updatedBatchNumbers = await fetchBatchNumbers();
                setBatchNumbers(updatedBatchNumbers);   
                setFilterBatchNumbers(updatedBatchNumbers)
                getPrefixes(updatedBatchNumbers)
            }
        }catch(err){
            console.error('Error removing batch/device:', err);
        }finally{
            setLoading(false)
            cancelDeletePopup()
        }    
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value)
    };

    useEffect(() => {
        const filterRootCauses = () => {
            const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : '';
        
            const filtered = batchNumbers.filter(batch => {
                const matchesSearch = batch.toLowerCase().includes(lowerCaseSearchTerm);
                const matchesPrefix = selectedPrefix === 'All' || selectedPrefix === '' || batch.startsWith(selectedPrefix);
        
                // If no search term is provided and prefix is 'All', show all batches
                if (selectedPrefix === 'All' && !searchTerm) {
                    return true; // Keep all batches
                }
                return matchesPrefix && matchesSearch;
            });
            setFilterBatchNumbers(filtered);
        };
        filterRootCauses();
    }, [searchTerm, batchNumbers]);
      

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        setSelectedPrefix(prefix);
    };

    const filterBatch = () =>{  
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = batchNumbers.filter(batch => {
            const matchesPrefix = selectedPrefix === 'All' || batch.startsWith(selectedPrefix);
            const matchesSearch = batch.toLowerCase().includes(lowerCaseSearchTerm)
            return matchesPrefix && matchesSearch;
        });
        setFilterBatchNumbers(filtered);
        setFilterVisible(false)
    }

 return(
    <ThemeProvider theme={theme} >
        <Grid container style={{padding:'25px'}}>
            <Grid container style={{display: "flex",margin:'50px 0px',alignItems:'center',backgroundColor:'white',padding:'15px',width:'fit-content'}}>
                <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                    <NonSuccessCriteriaIcon color={"#324054"}/>
                    <Link to={'/TestAutomation'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                    <Typography style={{color: '#858DAA'}}>Setup Device Pulse</Typography>
                    </Link>
                </Grid>
                <Arrow/>
                <Grid item style={{color:'#2A7BEC',display:'flex',alignItems:'center'}}>
                    <BatchesIcon />
                    <Typography style={{marginLeft:'10px'}}>Batches</Typography>
                </Grid>      
            </Grid>
            <Grid container style={{marginBottom:'15px'}}>
                <Grid item xs={6} md={3} style={{marginRight:'10px'}}>
                    <SearchBar onChange={handleSearch}/>
                </Grid>
                <Grid item xs={6} md={2}>
                    <FilterBatchNumber 
                        toggleFilter={toggleFilter} 
                        handlePrefixSelect={handlePrefixSelect} 
                        filterVisible={filterVisible} 
                        setFilterVisible ={setFilterVisible}
                        prefixes = {prefixes}
                        selectedPrefix={selectedPrefix}
                        filterBatch={filterBatch}
                        setSelectedPrefix = {setSelectedPrefix}
                    />
                </Grid>                
            </Grid>
            <Grid container >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width:'80%'}}>Batch ID</TableCell>
                            <TableCell style={{width:'20%'}} align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterBatchNumbers.length > 0 ? 
                            (filterBatchNumbers.map((batch,index) =>(
                                <TableRow key={index} style={{backgroundColor: index % 2 == 1 ? '#ECEFF1':'#FFFFFF'}}>
                                    <TableCell style={{width:'80%'}}>{batch}</TableCell>
                                    <TableCell style={{display:'flex',justifyContent:'center'}}>
                                        <IconButton size={'small'} onClick={() => handleViewPopup(batch,'view')}>
                                            <ViewIcon />
                                        </IconButton>
                                        <IconButton size={'small'} onClick={() => handleViewPopup(batch,'edit')} style={{margin:'0px 10px'}}>
                                            <EditIconLarge />
                                        </IconButton>
                                        <IconButton size={'small'} onClick={() =>handleDeleteIcon(batch,'batch')}>
                                            <TrashIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )))
                            : (
                            <TableRow>
                                <TableCell colSpan={4} style={{ textAlign:'center' }}>
                                    No Batch Available
                                </TableCell>
                            </TableRow>
                            )
                        } 

                        
                    </TableBody>
                </Table>
            </Grid>
            <DeletePopup 
                open={isDeletePopupOpen} 
                closePopup={cancelDeletePopup} 
                batchNumber={selectedBatchNumber} 
                type={deleteType}
                deviceId={selectedDeviceId}
                handleDelete={handleRemoveBatch}
            />
            <ViewPopup 
                open={isViewBatch} 
                devices={filterDevices} 
                setSearchQuery={setSearchQuery} 
                handleWindowClose={cancelViewPopup} 
                type={actionType} 
                openDelete={handleDeleteIcon} 
                selectedBatch={selectedBatchNumber}
            />
            <Loader loading={loading} />
        </Grid>
    </ThemeProvider>
 )
}

export const DeletePopup = ({open,closePopup,batchNumber,type,deviceId,handleDelete}) =>{
    return(
        <Dialog open={open}>
            <Box>
                <DialogTitle>
                    <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                    <span>{type == 'batch' ? 'REMOVE BATCH' : 'REMOVE DEVICE'}</span>
                    <IconButton
                        onClick={closePopup}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent style={{margin:'0px'}}>
                    <Grid item style={{marginBottom:'15px',marginRight:'15px'}}>
                        <Typography>
                            {type == 'batch' ? 
                                `Are you sure you want to remove batch ID: ${batchNumber}`
                                : `Are you sure you want to remove device ID: ${deviceId} from batch ID: ${batchNumber} `
                            }
                        </Typography>
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                        <Grid item style={{display:'flex',justifyContent:'flex-end',width:'50%'}}>
                            <Button variant='outlined' onClick={closePopup} style={{marginRight:'10px'}}>Cancel</Button>
                            <Button variant='contained' style={{backgroundColor:'#FF4343',color:'white'}} onClick={()=>handleDelete(type)}>Delete</Button>
                        </Grid>
                    </Grid>
                    
                    
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export const ViewPopup = ({open,devices,setSearchQuery,handleWindowClose,type,openDelete,selectedBatch}) =>{
    const [hoveredIndex, setHoveredIndex] = useState(null);
    return(
        <Dialog open={open}>
            <DialogTitle>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,boxShadow: '0 0 15px 0 #00000014'}}
            >
              <span>{type == 'view' ? 'View Batch' : 'Edit Batch'}</span>
              <IconButton
                onClick={handleWindowClose}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
            <DialogContent>
                <Typography style={{fontSize:"15px",color:'#324054',marginBottom:'10px',fontWeight:'bold'}}>{selectedBatch}</Typography>
                <SearchBar placeholder="Search" onChange={(e) =>  setSearchQuery(e.target.value.trim())}/>
                <Grid container style={{ margin: "25px 0" }}>
                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Devices
                </Typography>
                </Grid>

                <Grid container spacing={3} style={{ width: "500px" }}>
                    {devices.map((device, index) => (
                            <Grid
                            item
                            xs={12}   // Full width on small screens
                            md={6}
                            key={index}
                            onMouseEnter={() => setHoveredIndex(index)}  // Set hovered index on mouse enter
                            onMouseLeave={() => setHoveredIndex(null)}   // Reset hovered index on mouse leave
                        >
                            <Grid
                            container
                            alignItems="center"
                            style={{
                                padding: '10px',
                                borderRadius: '4px',
                                display: 'flex',
                            }}
                            >
                            <Typography style={{ fontSize: '13px' }}>
                                {device}
                            </Typography>
                            
                            {type === 'edit' && hoveredIndex === index && (
                                <IconButton size='small' style={{marginLeft:'10px'}} onClick={() =>openDelete(device,'device')}>
                                    <TrashIcon  />
                                </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        
                    ))}
                
                </Grid>
          </DialogContent>
        </Dialog>
    )
}