import {DialogContent, Divider, FormLabel, Grid, IconButton, makeStyles, Theme, Tooltip, Typography,} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {primaryGray, textBarColor} from "../../colors";
import {TextBar} from "../TextBar";
import {CloseIconColor, ExpandIconTwo} from "src/components/Icons";
import { TextBarColor } from "../TextBarColor";

const ReviewHistory = (props) => {
  const keyOrder = ["productType", "versionNum"];
  const configurations = [
    "Network & Communication",
    "Topic Format & Interval",
    "Message Format",
  ];
  const [openIcons, setOpenIcons] = useState({});
  const [paramList,setParamList]=useState(props.data.updateParamList);
  useEffect(()=>{
  console.log(paramList);
  },[])
  // const [networkData, setNetWorkData] = useState(
  //   typeof groupedParameters.networkAndCommunication === "object"
  //     ? groupedParameters.networkAndCommunication
  //     : {}
  // );
  // const [messageData, setMessageData] = useState(
  //   typeof groupedParameters.messageFormat === "object"
  //     ? groupedParameters.messageFormat
  //     : {}
  // );
  // const [topicData, setTopicData] = useState(
  //   typeof groupedParameters.topicFormat === "object"
  //     ? groupedParameters.topicFormat
  //     : {}
  // );

  const {date, action, actionBy} = props.data;
  const getTooltipMessage = (status, param) => {
    if (status === "PENDING") return "Parameter configuration Pending."
    else if (status === "UPDATED") return "Parameter configured."
    else if (status === "FAILED") return "Parameter configuration failed."
    else if (status === "PARTIALLY_UPDATED") {return param.isinValid != null 
    ? (param.isinValid ? "Parameter ID invalid. Not configured" : "Parameter valid. Configured") 
    : "No configuration details found";}
  };
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: "1",
      boxShadow: "0px 4px 30px #00000008",
      marginBottom: "20px",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    helperText: {
      color: "red",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 1,
      width: "100%",
    },
    textBarStyle: {
      backgroundColor: textBarColor,
      height: "44px",
    },
    textBarHistoryStyle: {
      root: {
        "& .MuiOutlinedInput-root":{
      "& .MuiInputBase-input": {
        border: `1px solid  red`, // Set the solid border
        borderRadius: "4px", // Optional: Add border radius if needed
        padding: "10px", // Optional: Add padding if needed
      },},},
      backgroundColor: textBarColor,
      backgroundSize: "0% 0%",
      backgroundRepeat: "no-repeat",
      padding: "padding-box",
     
      borderRadius: "4px",
      opacity: 1,
      ".MuiInputBase-input": {
        border: `1px solid  red`, // Set the solid border
        borderRadius: "4px", // Optional: Add border radius if needed
        padding: "10px", // Optional: Add padding if needed
      }
    },

    textBarMessageStyle: {
      width: "219px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    labelMessageStyle: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
      width: "121px",
    },
    backdrop: {
      opacity: 0,
      transition: "opacity 225ms cubic-bezier(0.4, 0, 0.1, 1) 0ms",
    },
    paper: {
      border: "solid 1px gray",
    },

  }));

  console.log("device : ", props.device);
  console.log("data : ", props.data);

  const classes = useStyles();

  return (
    <>
    <Grid
                container
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: "1",
                  boxShadow: "0px 4px 30px #00000008"
                }}
              >
    <Grid
              item
              style={{paddingLeft: "12px", fontSize: "18px"}}
              xs={10}
              md={10}
            >
              <h3
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  lineHeight: "25px",
                }}
              >
                Review Parameters - {props.data.updateStatus} 
           
            </h3>
            
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{display: "flex", justifyContent: "end"}}
            >
              <IconButton onClick={props.close}>
                <CloseIconColor/>
              </IconButton>
            </Grid>
            </Grid>
          <Divider/>
    <DialogContent style={{width: "100%"}}>
      <Grid container >
        {keyOrder.map((key, id) => {
          var label = "";
          if (key === "productType") {
            label = "Product Type";
          } else if (key === "versionNum") {
            label = "Version";
          }
           else {
            label = key;
          }
          return (
            <Grid
              key={id}
              item
              xs={6}
              md={12}
              style={{
                textAlign: "left",
                marginBottom: "22px",
                paddingRight: "2%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: primaryGray,
                }}
              >
                {label}
              </Typography>
              <TextBar
                disabled={true}
                style={classes.textBarStyle}
                variant="outlined"
                defaultValue={props.device.deviceParameterConfiguration[key]}
                error={{error: false}}
              />
            </Grid>
          );
        })}
      </Grid>
      <div>
        {configurations.map((category, index) => (
          <div key={category}>
            <Grid container className={classes.container}>
              <Grid item xs={4} md={4}>
                <FormLabel
                  style={{
                    alignItems: "center",
                    color: primaryGray,
                    opacity: "1",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "start",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  {category}
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={7}
                md={7}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Divider className={classes.divider}/>
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: "ButtonShadow",
                    borderRadius: "80%",
                    boxShadow: "0px 3px 6px #00000029",
                    padding: "3px",
                  }}
                  onClick={() => {
                    setOpenIcons((prevState) => ({
                      ...prevState,
                      [category]: !prevState[category],
                    }));
                  }}
                >
                  <ExpandIconTwo rotation={openIcons[category]}/>
                </IconButton>
              </Grid>
            </Grid>

            {openIcons[category] && (
              <Grid container className={classes.container}>
                {category === "Network & Communication" &&
                  Object.keys(paramList).filter(key => {
                    return paramList[key].parameterCategory === category;})
                    .map((key, i) => {
                    const param = paramList[key];
                    return (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        key={i}
                        style={{
                          textAlign: "left",
                          marginBottom: "22px",
                          
                          paddingRight: "2%",
                          display: "flex",
                          alignItems: "center",
                          marginLeft:"50px",
                          marginRight:"50px"
                        }}
                      >
                        
                        <Typography
                          style={{
                            color: primaryGray,
                            fontSize: "14px",
                            lineHeight: "21px",
                            opacity: "1",
                            width: "100%",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                         {param.parameter}
                        </Typography>
                        <Tooltip title={(props.data.updateStatus&&param)?getTooltipMessage(props.data.updateStatus,param):""} placement="right" arrow>
                        <Typography
                          style={{
                            color: primaryGray,
                            fontSize: "14px",
                            lineHeight: "21px",
                            opacity: "1",
                            width: "100%",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            border:`1px solid ${props.data.updateStatus=="PENDING"?"#FFB40A":(param.isinValid!=null?param.isinValid?"red":"green":"grey")}`,
                            borderRadius:"5px",
                            paddingLeft:"10px"
                          }}
                        >
                         {param.defaultValue}
                        </Typography>
                        </Tooltip>
                        {/*
                        <TextBarColor
                        color='green'
                          id={param.name}
                          name={param.name}
                          disabled={true}
                          defaultValue={param.defaultValue}
                          error={{error: false}}
                          style={classes.textBarHistoryStyle}
                          InputProps={{
                            style: {
                              border: `1px solid red`, // Directly set the solid border
                              borderRadius: "4px", // Optional: Add border radius if needed
                              padding: "10px", // Optional: Add padding if needed
                            },}}
                        />*/}
                      </Grid>
                    );
                  })}
                {category === "Topic Format & Interval" &&
                  Object.keys(paramList).filter(key => {
                    return paramList[key].parameterCategory === category;})
                  .map((key, i) => {
                    const param = paramList[key];
                    return (
                      <Grid
                      item
                      xs={12}
                      md={12}
                      key={i}
                      style={{
                        textAlign: "left",
                        marginBottom: "22px",
                        
                        paddingRight: "2%",
                        display: "flex",
                        alignItems: "center",
                        marginLeft:"50px",
                        marginRight:"50px"
                      }}
                    >
                      
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "100%",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                       {param.parameter}
                      </Typography>
                      <Tooltip title={(props.data.updateStatus&&param)?getTooltipMessage(props.data.updateStatus,param):""} placement="right" arrow>
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "100%",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          border:`1px solid ${props.data.updateStatus=="PENDING"?"#FFB40A":(param.isinValid!=null?param.isinValid?"red":"green":"grey")}`,
                          borderRadius:"5px",
                          paddingLeft:"10px"
                        }}
                      >
                       {param.defaultValue}
                      </Typography>
                      </Tooltip>
                      {/*
                      <TextBarColor
                      color='green'
                        id={param.name}
                        name={param.name}
                        disabled={true}
                        defaultValue={param.defaultValue}
                        error={{error: false}}
                        style={classes.textBarHistoryStyle}
                        InputProps={{
                          style: {
                            border: `1px solid red`, // Directly set the solid border
                            borderRadius: "4px", // Optional: Add border radius if needed
                            padding: "10px", // Optional: Add padding if needed
                          },}}
                      />*/}
                    </Grid>
                    );
                  })}
                {category === "Message Format" &&
                  Object.keys(paramList).filter(key => {
                    return paramList[key].parameterCategory === category;}).map((key, i) => {
                    const param = paramList[key];
                    return (
                      <Grid
                      item
                      xs={12}
                      md={12}
                      key={i}
                      style={{
                        textAlign: "left",
                        marginBottom: "22px",
                        
                        paddingRight: "2%",
                        display: "flex",
                        alignItems: "center",
                        marginLeft:"50px",
                        marginRight:"50px"
                      }}
                    >
                      
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "100%",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                       {param.parameter}
                      </Typography>
                      <Tooltip title={(props.data.updateStatus&&param)?getTooltipMessage(props.data.updateStatus,param):""} placement="right" arrow>
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "100%",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          border:`1px solid ${props.data.updateStatus=="PENDING"?"#FFB40A":(param.isinValid!=null?param.isinValid?"red":"green":"grey")}`,
                          borderRadius:"5px",
                          paddingLeft:"10px"
                        }}
                      >
                       {param.defaultValue}
                      </Typography>
                      </Tooltip>
                      {/*
                      <TextBarColor
                      color='green'
                        id={param.name}
                        name={param.name}
                        disabled={true}
                        defaultValue={param.defaultValue}
                        error={{error: false}}
                        style={classes.textBarHistoryStyle}
                        InputProps={{
                          style: {
                            border: `1px solid red`, // Directly set the solid border
                            borderRadius: "4px", // Optional: Add border radius if needed
                            padding: "10px", // Optional: Add padding if needed
                          },}}
                      />*/}
                    </Grid>
                    );
                  })}

                {/* Conditionally render other categories here */}
              </Grid>
            )}
          </div>
        ))}
      </div>
    </DialogContent>
    </>
  );
};

export default ReviewHistory;
