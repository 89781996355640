import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography,Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import useStyles from '../maintenance/assets/styles';


export const WelcomePopUp = ({title,content,onClose,onNext,open}) =>{
    const classes = useStyles()
    return(
        <Dialog open={open} 
                BackdropProps={{
                    style: { backgroundColor: 'transparent' }, 
                }}>
            <DialogTitle>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.popUpTitle}>{title}</Typography>
                    <IconButton onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container >
                    <Typography>{content}</Typography>
                    <Grid container justifyContent='flex-end' style={{ margin: '15px 0px' }}>
                        <Button variant='outlined' style={{ marginRight: '15px', color: '#7F7C7C' }} onClick={onClose}>No Thanks</Button>
                        <Button variant='contained' style={{ backgroundColor: '#005B96', color: 'white' }} onClick={onNext}>Next</Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}