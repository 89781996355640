import {DialogContent, Divider, FormLabel, Grid, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import React, {useState} from "react";
import {primaryGray, textBarColor} from "../../colors";
import {TextBar} from "../TextBar";
import {ExpandIconTwo} from "src/components/Icons";

const ReviewHistory = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const keyOrder = ["productType", "versionNum", "deviceCategory", "deviceIds"];
  const configurations = [
    "Network & Communication",
    "Topic Format & Interval",
    "Message Format",
  ];
  const [openIcons, setOpenIcons] = useState({});
  const groupedParameters =props.data;
  const [networkData, setNetWorkData] = useState(
    typeof groupedParameters.networkAndCommunication === "object"
      ? groupedParameters.networkAndCommunication
      : {}
  );
  const [messageData, setMessageData] = useState(
    typeof groupedParameters.messageFormat === "object"
      ? groupedParameters.messageFormat
      : {}
  );
  const [topicData, setTopicData] = useState(
    typeof groupedParameters.topicFormat === "object"
      ? groupedParameters.topicFormat
      : {}
  );

  const {date, action, actionBy} = props.data;

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: "20px",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    helperText: {
      color: "red",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 1,
      width: "100%",
    },
    textBarStyle: {
      backgroundColor: textBarColor,
      height: "44px",
    },
    textBarHistoryStyle: {
      backgroundColor: textBarColor,
      height: "44px",
      backgroundSize: "0% 0%",
      backgroundRepeat: "no-repeat",
      padding: "padding-box",
      border: "1px solid #02C170",
      borderRadius: "4px",
      opacity: 1,
    },
    textBarMessageStyle: {
      width: "219px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    labelMessageStyle: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
      width: "121px",
    },
    backdrop: {
      opacity: 0,
      transition: "opacity 225ms cubic-bezier(0.4, 0, 0.1, 1) 0ms",
    },
    paper: {
      border: "solid 1px gray",
    },
  }));

  console.log("device : ", props.device);
  console.log("data : ", props.data);

  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <DialogContent>
      <Grid container className={classes.container}>
        {keyOrder.map((key, id) => {
          var label = "";
          if (key === "productType") {
            label = "Product Type";
          } else if (key === "versionNum") {
            label = "Version";
          } else if (key === "deviceCategory") {
            label = "Device Category";
          } else if (key === "deviceIds") {
            label = "Device IDs";
          } else {
            label = key;
          }
          return (
            <Grid
              key={id}
              item
              xs={6}
              md={12}
              style={{
                textAlign: "left",
                marginBottom: "22px",
                paddingRight: "2%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: primaryGray,
                }}
              >
                {label}
              </Typography>
              <TextBar
                disabled={true}
                style={classes.textBarStyle}
                variant="outlined"
                defaultValue={props.device.deviceParameterConfiguration[key]}
                error={{error: false}}
              />
            </Grid>
          );
        })}
      </Grid>
      <div>
        {configurations.map((category, index) => (
          <div key={category}>
            <Grid container className={classes.container}>
              <Grid item xs={4} md={4}>
                <FormLabel
                  style={{
                    alignItems: "center",
                    color: primaryGray,
                    opacity: "1",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "start",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  {category}
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={7}
                md={7}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Divider className={classes.divider}/>
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: "ButtonShadow",
                    borderRadius: "80%",
                    boxShadow: "0px 3px 6px #00000029",
                    padding: "3px",
                  }}
                  onClick={() => {
                    setOpenIcons((prevState) => ({
                      ...prevState,
                      [category]: !prevState[category],
                    }));
                  }}
                >
                  <ExpandIconTwo rotation={openIcons[category]}/>
                </IconButton>
              </Grid>
            </Grid>

            {openIcons[category] && (
              <Grid container className={classes.container}>
                {category === "Network & Communication" &&
                  Object.keys(networkData).map((key, i) => {
                    const param = networkData[key];
                    return (
                      <Grid
                        item
                        xs={6}
                        md={12}
                        key={i}
                        style={{
                          textAlign: "left",
                          marginBottom: "22px",
                          paddingRight: "2%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: primaryGray,
                            fontSize: "14px",
                            lineHeight: "21px",
                            opacity: "1",
                            width: "160px",
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {key}
                        </Typography>
                        <TextBar
                          id={param.name}
                          name={param.name}
                          disabled={true}
                          defaultValue={param}
                          error={{error: false}}
                          style={classes.textBarHistoryStyle}
                        />
                      </Grid>
                    );
                  })}
                {category === "Topic Format & Interval" &&
                  Object.keys(topicData).map((key, i) => {
                    const param = topicData[key];
                    return (
                      <Grid
                        item
                        xs={6}
                        md={12}
                        key={i}
                        style={{
                          textAlign: "left",
                          marginBottom: "22px",
                          paddingRight: "2%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: primaryGray,
                            fontSize: "14px",
                            lineHeight: "21px",
                            opacity: "1",
                            width: "160px",
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {key}
                        </Typography>
                        <TextBar
                          id={param.name}
                          name={param.name}
                          disabled={true}
                          defaultValue={param}
                          error={{error: false}}
                          style={classes.textBarHistoryStyle}
                        />
                      </Grid>
                    );
                  })}
                {category === "Message Format" &&
                  Object.keys(messageData).map((key, i) => {
                    const param = messageData[key];
                    return (
                      <Grid
                        item
                        xs={6}
                        md={12}
                        key={i}
                        style={{
                          textAlign: "left",
                          marginBottom: "22px",
                          paddingRight: "2%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: primaryGray,
                            fontSize: "14px",
                            lineHeight: "21px",
                            opacity: "1",
                            width: "90px",
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {key}
                        </Typography>
                        <TextBar
                          id={param.name}
                          name={param.name}
                          disabled={true}
                          defaultValue={param}
                          error={{error: false}}
                          style={classes.textBarHistoryStyle}
                        />
                      </Grid>
                    );
                  })}

                {/* Conditionally render other categories here */}
              </Grid>
            )}
          </div>
        ))}
      </div>
    </DialogContent>
  );
};

export default ReviewHistory;
