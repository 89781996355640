import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../auth.service';
import { AccountService } from 'src/app/account/account.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  hash;
  username;
  error;
  isDialog = false;
  showPassword={password:false,confirmPassword:false}

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, protected router: Router,
              protected auth:AuthService,private account:AccountService,private spinner:NgxSpinnerService,
            private toast:ToastrService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params['hash'];
      this.username = params['uname'];
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    this.account.validateHashKey(this.hash,this.username,null).subscribe(
      (response) =>{
        if(response.content == "EXPIRED"){
          this.router.navigate(['/forgot-password']);
          this.toast.error('Session expired, please reset again.','Error!')
        }
      },error => {
        this.toast.error(error.error.message, 'Error!!',{
          timeOut: 3000,  
        });
      }
    )

    this.form = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    });
  }

  reset(entity: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    entity.key = this.hash;
    entity.username = this.username;
    this.spinner.show()
    this.account.resetPassword(entity).subscribe(
      (response)=>{
        this.spinner.hide(); // Hide spinner
        this.toast.success("The password reset successfully.");
        // Optionally navigate to the login page after success
        this.router.navigate(['/login']);

      },
      (error)=>{
        this.spinner.hide();
        this.toast.error(error.error.message)
      }
    )
  }

  togglePassword(field: 'password' | 'confirmPassword'): void {
    this.showPassword[field] = !this.showPassword[field];
  }
}
