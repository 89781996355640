import { Grid, InputBase,Typography } from "@material-ui/core"
import React, { useState } from "react"
import useStyles from "src/app/maintenance/assets/styles"

export const EditProject = ({ batch, onChange, project,errors }) => {
    const classes = useStyles()
    return (
        <Grid container style={{ margin: '15px 0px' }}>
            <Grid item xs={4} >
                <Grid container alignItems='center' style={{ border: '0.8px solid #2A7BEC', borderRadius: '6px', padding: '6px 9px', fontFamily: 'Poppins' }}>
                    <Grid item xs={5} md={5}>
                        <Typography style={{ color: '#707070' }}>Batch Number</Typography>
                    </Grid>
                    <Grid item xs={7} md={7}>
                        <Typography style={{ color: '#2A7BEC', backgroundColor: '#2A7BEC0D', fontWeight: 'bold', padding: '4px 10px' }}>{batch}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: '15px' }}>
                <Grid container>
                    <Typography style={{ color: '#707070' }}>Project Name</Typography>
                    <InputBase
                        className={classes.formInput}
                        type="text"
                        name="projectName"
                        value={project || ''}
                        onChange={onChange}
                    />
                    {errors.projectName &&
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                            <Typography className={classes.errorText}>
                                {errors.projectName}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}