import React, { useState } from 'react';
import { Grid, Typography, Box, IconButton, Paper, Tooltip } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import useStyles from 'src/app/maintenance/assets/styles';
import ComingSoonComponent from './ComingSoonComponent';
import { CorrectiveIcon, DeviceMonitoringIcon, FailureIncidentIcon, GoToIcon, GuideIcon, MainTestCaseIcon, RootCauseAnalysisIcon, RootCauseInsightIcon, SolutionIcon, SubTestCaseIcon } from 'src/components/Icons';
import { white } from 'src/components/colors';
import {useHistory} from 'react-router-dom';
import { azureService } from 'src/app/utils';
import DashboardTooltip from './DashboardTooltip';

const DeploymentStageComponent = ({ stageData, loading, activeTab, mainTitle, path, toolTipData  }) => {
    const classes = useStyles();
    const [hoverIndex, setHoverIndex] = useState(null);

    const history = useHistory();
    const {isAzureFreePackage, isAzureBasicPackage} = azureService()
    const cardDisabled = isAzureBasicPackage() || isAzureFreePackage()

    

    const handleGoToIconClick = (deviceIds, stageTitle) => {
        history.push({
            pathname: '/DevicePulseDevices',
            state: { deviceIds: deviceIds, stageTitle: stageTitle, mainTitle: mainTitle, path: path, hideCreateBatch: true },
        });
    };

    return (
        <Grid container style={{marginTop: '20px', marginBottom: '20px'}}>
            <Grid item xs={12} className={classes.mainContainer}>
                <Grid item xs={12} className={classes.card}>               
                    <Grid item className={classes.deviceContainer}>
                        <Grid item xs={4} className={classes.deviceDataContainer}>
                            <Grid item className={classes.cardTitleContainer}>
                                <IconButton style={{ backgroundColor: 'rgba(42, 123, 236, 0.04)', borderRadius: '50%', padding: '15px' }}>
                                    <DeviceMonitoringIcon />
                                </IconButton>
                                <Typography className={classes.cardTitle}>
                                    Device Summary
                                </Typography>
                                <DashboardTooltip title={toolTipData[0].title}/>
                            </Grid>
                            <Box className={classes.deviceBox} style={{borderRadius: '16px'}}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">{stageData[0].count}</Typography>
                                    <Typography>Registered Devices</Typography>
                                </Grid>
                            </Box>
                            <Grid item xs={12} style={{marginTop: '15px'}}>
                                <Grid container spacing={2}>
                                    {stageData[0].deviceData.map((item, index) => (
                                        <Grid
                                            item
                                            xs={6}
                                            key={index}
                                            className={classes.subDeviceDataContainer}
                                            style={{justifyContent: index === 1 ? 'flex-end' : 'flex-start'}}
                                        >
                                            <Tooltip
                                                key={index}
                                                title={
                                                    <Grid>
                                                        <Typography className={classes.toolTipLabel}>
                                                            {item.label}
                                                        </Typography>
                                                        <Typography className={classes.toolTipDescription}>
                                                            {item.description}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                placement="bottom"
                                                >
                                                <Box
                                                    className={classes.subDeviceBoxContainer}
                                                    style={{backgroundColor: item.color}}
                                                />
                                            </Tooltip>
                                            <Box className={classes.doughnutIndicatorBox}>
                                                <Typography className={classes.indicatorLabel} style={{ opacity: item.opacity }}>{item.label}</Typography>
                                                <Typography className={classes.indicatorCount} style={{ opacity: item.opacity }}>
                                                {cardDisabled && item.label == 'Devices with Root Cause Identified' ? 0 : item.count} / {cardDisabled && item.label == 'Devices with Root Cause Identified' ? 0 : item.percentage}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} className={classes.subDougnutContainer}>
                            <Grid item xs={5} className={classes.doughnutContainer} style={{display:'flex'}}>
                            {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <Doughnut data={stageData[0].data} options={stageData[0].options} datasetKeyProvider={(dataset) => dataset.label || Math.random().toString()}/>
                            )}
                            </Grid>
                            <Grid item xs={7}>
                                <Grid container spacing={2} style={{ marginTop: '60px' }}>
                                    {stageData[0].indicatorData.map((item, index) => (
                                        <Grid
                                            item
                                            xs={6}
                                            key={index}
                                            className={`${classes.deviceSummeryTile} ${cardDisabled && item.label == 'Devices with Root Cause Identified' ? 'disabled':''}`}
                                            style={{padding:'0px', marginTop: index === 2 ? '20px' : '0px'}}
                                        >
                                            <Tooltip
                                                key={index}
                                                title={
                                                    <Grid>
                                                        <Typography className={classes.toolTipLabel}>
                                                            {item.label}
                                                        </Typography>
                                                        <Typography className={classes.toolTipDescription}>
                                                            {item.description}
                                                        </Typography>
                                                    </Grid>
                                                } 
                                                placement="bottom"
                                                >
                                                <Box
                                                    className={classes.subDeviceBoxContainer}
                                                    style={{backgroundColor: item.color}}
                                                />
                                            </Tooltip>
                                        <Box className={classes.doughnutIndicatorBox}>
                                            <Typography className={classes.indicatorLabel} style={{ opacity: item.opacity }}>{item.label}</Typography>
                                            <Typography className={classes.indicatorCount} style={{ opacity: item.opacity }}>
                                            {cardDisabled && item.label == 'Devices with Root Cause Identified' ? 0 : item.count} / {cardDisabled && item.label == 'Devices with Root Cause Identified' ? 0 : item.percentage}
                                            </Typography>
                                        </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.commonCardContainer}>
                    <Grid item xs={12} md={4} className={classes.card}>
                        <Grid item className={classes.cardTitleContainer}>
                            <IconButton className={classes.cardTitleIcon}>
                                <FailureIncidentIcon />
                            </IconButton>
                            <Typography className={classes.cardTitle}>
                                Event Summary
                            </Typography>
                            <DashboardTooltip title={toolTipData[1].title}/>
                        </Grid>
                        <Box style={{ position: 'relative', margin: '0px' }}>
                            <Doughnut data={stageData[1].data} options={stageData[1].options} />
                                <Box
                                    className={classes.middleCountBox}
                                >
                                    <Typography className={classes.middleCount}>
                                        {stageData[1].totalCount}
                                    </Typography>
                                    <Typography className={classes.middlelabel}>
                                        {stageData[1].title}
                                    </Typography>
                                </Box>
                        </Box>
                        <Grid item xs={12} className={classes.mainTestBox}>
                            <Grid item xs={6} className={classes.testBox}>
                                <Grid item xs={12} className={classes.testSubBox}>
                                    <Grid item className={classes.sampleBox}>
                                        <IconButton className={classes.testBoxIcon} style={{ backgroundColor: white }}>
                                            <MainTestCaseIcon />
                                        </IconButton>
                                        <Grid item className={classes.testInnerBox}>
                                            <Typography className={classes.testLabel}>Main Test Cases Applied</Typography>
                                            <Typography variant="h4" className={classes.numberStyle} style={{alignSelf:'start'}}>{stageData[1].mainTestCount}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} className={classes.subTestBox}>
                                <Grid item xs={12} className={classes.testSubBox} >
                                    <Grid item className={classes.sampleBox}>
                                        <IconButton className={classes.testBoxIcon} style={{ backgroundColor: '#F5F3F9' }}>
                                            <SubTestCaseIcon />
                                        </IconButton>
                                        <Grid item className={classes.testInnerBox}>
                                            <Typography className={classes.testLabel}>Sub Test Cases Applied</Typography>
                                            <Typography variant="h4" className={classes.numberStyle} style={{alignSelf:'start'}}>{stageData[1].subTestCount}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '15px' }}>
                                {stageData[1].data.labels.map((label, index) => (
                                    <Grid
                                    item
                                    xs={6}
                                    key={index}
                                    className={classes.mainIndicatorBox}
                                >
                                    <Box
                                        className={classes.indicatorBox}
                                    >
                                        <Box
                                            className={classes.indicator}
                                            style={{ backgroundColor: stageData[1].data.datasets[0].backgroundColor[index] }}
                                        />
                                        <Box className={classes.doughnutIndicatorBox}>
                                            <Tooltip title={label} placement="bottom">
                                                <Typography
                                                    className={classes.cardLabel}
                                                    noWrap
                                                >
                                                    {label}
                                                </Typography>
                                            </Tooltip>
                                            <Typography className={classes.cardCount}>
                                                {stageData[1].data.devices[index]} ({Number(stageData[1].data.datasets[0].data[index]).toFixed(2)}%)
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            className={classes.iconButton}
                                            onClick={() => handleGoToIconClick(stageData[1].data.deviceIds[index], stageData[1].data.labels[index])}
                                            onMouseEnter={() => setHoverIndex(index)}
                                            onMouseLeave={() => setHoverIndex(null)}
                                        >
                                            <GoToIcon color={hoverIndex === index ? '#ffffff' : '#324054'} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} className={`${classes.card} ${cardDisabled ? 'disabled' : ''}`}>
                        <Grid item className={classes.cardTitleContainer}>
                            <IconButton className={classes.cardTitleIcon}>
                                <RootCauseInsightIcon />
                            </IconButton>
                            <Typography className={classes.cardTitle}>
                                Root Cause Analytics
                            </Typography>
                            <DashboardTooltip title={toolTipData[2].title}/>
                        </Grid>
                        <Box style={{ position: 'relative', margin: '0px' }}>
                            <Doughnut data={stageData[2].data && stageData[2].data.labels.length > 0 ? stageData[2].data : { labels: [], datasets: [] }}  options={stageData[2].options} />
                                <Box
                                    className={classes.middleCountBox}
                                >
                                    <Typography className={classes.middleCount}>
                                        {cardDisabled ? 0 : stageData[2].totalCount}
                                    </Typography>
                                    <Typography className={classes.middlelabel}>
                                        {stageData[2].title}
                                    </Typography>
                                </Box>
                        </Box>
                        <Box className={classes.deviceBox} style={{marginTop: '10px'}}>
                            <Grid item xs={12} className={classes.rootCauseBox}>
                                <Grid item className={classes.subRootCauseBox}>
                                    <IconButton className={classes.rootCauseBoxIcon}>
                                        <RootCauseAnalysisIcon />
                                    </IconButton>
                                    <Grid item className={classes.testInnerBox}>
                                        <Typography className={classes.rootCauseLabel}>Root Cause Models Applied</Typography>
                                        <Typography variant="h4" className={classes.numberStyle} style={{alignSelf:'start'}}>{cardDisabled ? 0 : stageData[2].modelCount}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2} style={{ marginTop: '15px' }}>
                            {stageData[2].data && stageData[2].data.labels ? (
                                stageData[2].data.labels.map((label, index) => (
                                    <Grid
                                        item
                                        xs={6}
                                        key={index}
                                        className={classes.mainIndicatorBox}
                                    >
                                        <Box
                                            className={classes.indicatorBox}
                                        >
                                            <Box
                                                className={classes.indicator}
                                                style={{ backgroundColor: stageData[2].data.datasets[0].backgroundColor[index] }}
                                            />
                                            <Box className={classes.doughnutIndicatorBox}>
                                                <Tooltip title={label} placement="bottom">
                                                    <Typography
                                                        className={classes.cardLabel}
                                                        noWrap
                                                    >
                                                        {label}
                                                    </Typography>
                                                </Tooltip>
                                                <Typography className={classes.cardCount}>
                                                    {cardDisabled ? 0 : stageData[2].data.devices[index]} ({Number(cardDisabled ? 0 : stageData[2].data.datasets[0].data[index]).toFixed(2)}%)
                                                </Typography>
                                            </Box>
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => handleGoToIconClick(stageData[2].data.deviceIds[index], stageData[2].data.labels[index])}
                                                onMouseEnter={() => setHoverIndex(index)}
                                                onMouseLeave={() => setHoverIndex(null)}
                                            >
                                                <GoToIcon color={hoverIndex === index ? '#ffffff' : '#324054'} />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12} className={classes.emptyRootCauselabel}>
                                    <Typography>N/A</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.card}>
                        <ComingSoonComponent show={true}>
                        <Grid item className={classes.cardTitleContainer}>
                            <IconButton className={classes.cardTitleIcon}>
                                <CorrectiveIcon />
                            </IconButton>
                            <Typography className={classes.cardTitle}>
                                Recommended Solutions
                            </Typography>
                        </Grid>
                        <Box style={{ position: 'relative', margin: '0px' }}>
                            <Doughnut data={stageData[3].data} options={stageData[3].options} />
                                <Box
                                    className={classes.middleCountBox}
                                >
                                    <Typography className={classes.middleCount}>
                                        {stageData[3].totalCount}
                                    </Typography>
                                    <Typography className={classes.middlelabel}>
                                        {stageData[3].title}
                                    </Typography>
                                </Box>
                        </Box>
                        <Box className={classes.deviceBox} style={{marginTop: '10px'}}>
                            <Grid item xs={12} className={classes.chatBotBox}>
                                <IconButton className={classes.chatBotIcon}>
                                    <SolutionIcon />
                                </IconButton>
                                <Typography className={classes.chatLabel}>Total Solution Provided Via Chat</Typography>
                                <Typography className={classes.chatCount} variant="h4">5</Typography>
                            </Grid>
                        </Box>
                        <Grid container spacing={2} style={{ marginTop: '15px' }}>
                            {stageData[3].data.labels.map((label, index) => {
                                const chatCount = stageData[3].data.datasets[0].data[index];
                                const totalChats = stageData[3].data.datasets[0].data.reduce((acc, curr) => acc + curr, 0);
                                const chatPercentage = totalChats > 0 ? ((chatCount / totalChats) * 100).toFixed(2) : '0.00';

                                return (
                                    <Grid
                                        item
                                        xs={6}
                                        key={index}
                                        className={classes.mainIndicatorBox}
                                    >
                                        <Box
                                            className={classes.indicatorBox}
                                        >
                                            <Box className={classes.mainIndicatorBox}>
                                                <Box
                                                    className={classes.indicator}
                                                    style={{ backgroundColor: stageData[3].data.datasets[0].backgroundColor[index] }}
                                                />
                                                <Box className={classes.doughnutIndicatorBox}>
                                                <Tooltip title={label} placement="bottom">
                                                    <Typography
                                                        className={classes.cardLabel}
                                                        noWrap
                                                    >
                                                        {label}
                                                    </Typography>
                                                </Tooltip>
                                                <Typography className={classes.cardCount}>
                                                    {chatCount} ({chatPercentage}%)
                                                </Typography>
                                                </Box>
                                            </Box>
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => handleGoToIconClick(stageData[3].data.deviceIds[index], stageData[3].data.labels[index])}
                                                onMouseEnter={() => setHoverIndex(index)}
                                                onMouseLeave={() => setHoverIndex(null)}
                                            >
                                                <GoToIcon color={hoverIndex === index ? '#ffffff' : '#324054'} />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        </ComingSoonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DeploymentStageComponent;
