import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import {CloseIcon, CloseIconColor} from '../../../components/Icons';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {primaryGray} from 'src/components/colors';
import {environment} from 'src/environments/environment';
import axios from 'axios';
import Cookies from "js-cookie";

export function DeleteDecoder(props) {
  const classes = useStyles();

  const deleteDecoder = () => {
    props.setLoading(true)
    axios.delete(
      environment.host + '/core/codec/' + props.decoderId,
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")},
      })
      .then(response => {
        props.setPage(0)
        props.getAllCodec()
        props.getDeviceDataAll()
        props.setToast(true)
        response.status == 200 ? (props.setReqSuccess(true), props.setErrorMessage('Codec Deleted Successfully.')) : props.setReqSuccess(false)
        props.setLoading(false)
        props.onClose()
      })
      .catch((err) => {
        props.setReqSuccess(false), 
        props.setErrorMessage(err.message)
        props.setLoading(false)
      }).finally(() =>{

        setTimeout(() => {
          props.setToast(false);
        }, 4000);
      })

    closePopup()
  }
  const disConnectDeviceAndDecoder = () => {
    props.setLoading(false)
    axios(
      { url:`${environment.host}/core/codec/${props.decoderId}/device/${props.deviceId}`,
        method:'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")}
      }
    ).then((res) => {
      const disConnectedDevice = res.data.content
      props.setDevicesArray([...props.devicesArray, disConnectedDevice])
      props.setToast(true)
      res.status == 200 ? (props.setReqSuccess(true), props.setErrorMessage('Device deleted successfully from the codec.')) : props.setReqSuccess(false)
      props.setLoading(false)
      props.getParticularDecoder()
      props.getDeviceData()
      props.onClose()
      props.setMultiDevicesId([])
      props.setMultiDevices([])
    }).catch((err) => {
      props.setReqSuccess(false), props.setErrorMessage(err.message)
      console.log(err.message)
      props.onClose()
    })
  }

  const multipleDeviceDisconnect = () =>{
    props.setLoading(true)
    axios(
      {url:`${environment.host}/core/codec/disconnect-devices`,
      method:'PUT',
      data:props.multiDevicesId,
      headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")}
    }
    ).then((res) =>{
      const disconnectedDevices = res.data.content
      if(res.status == 200){
        props.setDevicesArray([...props.devicesArray,...disconnectedDevices])
        props.setReqSuccess(true),
         props.setErrorMessage('Devices deleted successfully from the codec.')
         props.setLoading(false)
         props.getParticularDecoder()
         props.getDeviceData()
         props.setMultiDevicesId([])
         props.setMultiDevices([])
         props.onClose()
      }

    }).catch((error) =>{
      props.setReqSuccess(false),
       props.setErrorMessage(error.message)
      props.onClose()
    })
  }

  const closePopup = () => {
    props.onClose()
  }

  return (
    <Dialog open={props.open} maxWidth="lg" classes={{paper: classes.deletePopup}}
            style={{width: props.type === 'device-delete' || props.type == 'multiple-delete' ? "598px" : "519px"}}>
      <Grid container style={{ width: props.type === 'device-delete' || props.type == 'multiple-delete'? "598px" : "519px"}}
            className={classes.deleteHeader}>
        <Grid item xs={10} md={10} style={{flexBasis: "90%", maxWidth: "90%"}}>
          <DialogTitle
            className={classes.title}>{props.type === 'device-delete' ? 'Are you sure you want to remove this device from the codec?' :props.type == 'multiple-delete'? 'Are you sure you want to remove these devices from the codec?': 'Are you sure you want to delete this codec?'} </DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{textAlign: "right", flexBasis: "5%", maxWidth: "10%"}}>
          <IconButton onClick={() => {
            closePopup()
          }}
                      style={{paddingTop: "20px"}}>
            <CloseIconColor/>
          </IconButton>
        </Grid>
      </Grid>
      {
        <DialogContent style={{margin: "0", overflowY: "hidden"}}>
          <Grid container>
            <Grid style={{margin: "0"}}>
              <Typography className={classes.label}>{
                props.type === 'device-delete' ?
                  'Deleting the device will permanently remove the assigned decoding method from the panel.'
                  : 'Deleting the decoder will permanently remove it from the panel.'}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      }
      {
        <div style={{width: "100%"}} className={classes.footer}>
          <Button variant='contained' className={classes.cancel} onClick={() => closePopup()}>
            Cancel
          </Button>
          <Button variant='contained' className={classes.delete}
                  onClick={props.type == 'device-delete' ? disConnectDeviceAndDecoder :props.type == 'multiple-delete' ?multipleDeviceDisconnect: deleteDecoder}
                  style={{marginRight: props.type == 'device-delete' || props.type == 'multiple-delete' ? "25px" : "21px"}}>
            Delete
          </Button>
        </div>
      }{props.loading &&
      <div style={{
        position: 'fixed',
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        opacity: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        width: '100vw',
        height: '100vh'
      }}>
        <CircularProgress size={80}/>
      </div>
    }
    </Dialog>
  )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    delete: {
      backgroundColor: "#FF4343",
      color: "white",
      marginBottom: "10px",
      marginRight: "21px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    cancel: {
      backgroundColor: "white",
      color: primaryGray,
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    label: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#707070",
      opacity: 1,
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 300,
      paddingTop: '15px',
      height: '70px'

    },
    input: {
      minWidth: "100%",
      maxWidth: "100%",
      height: "44px",
      backgroundColor: "white",
      width: "717px",
      borderColor: "#C7C4C4",
      border: "solid 1px"
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px"
    },
    title: {
      fontSize: "18px",
      paddingRight: "0px"
    },
    deletePopup: {
      margin: '0px',
    },
    deleteHeader: {
      height: "80px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 4px 80px rgba(0,0,0,0.1)",
      opacity: 1
    },
    footer: {
      height: '60px',
      margin: '0px',
      padding: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end'
    }
  }),
);
