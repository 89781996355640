import * as React from "react";
import { Button, createTheme, Grid, IconButton, ThemeProvider, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles";
import { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { Step1 } from "./step1";
import { Step2 } from "./step2/step2";
import { SelectAModelComponent } from "./step2/SelectAModelComponent";
import { fetchBatchNumbers, rootCauseConfiguration } from "../components/apiHelper";
import { ToasterComponent } from "src/app/maintenance/test-automation/components/Toaster";
import { Loader } from "../components/Loader";
import { clickBack } from "src/app/utils";
import { RedirectIcon } from "src/components/Icons";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const STEPS = [
  {
    text: "Define Issue Type",
    activate: true,
  },
  {
    text: "Define Model",
    activate: false,
  }
];

export function RootCauseAnalysisComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [steps, setSteps] = useState(STEPS);
  const [step, setStep] = useState(0);
  const [errors,setErrors] = useState({})
  const [isClickSelectModel,setIsClickSelectModel] = useState(false)
  const [batchNumbers,setBatchNumbers] = useState([])
  const [formValue, setFormValue] = useState({
    batchNumber: null,
    model: null,
    modelUrl: null,
    modelDetails: {},
  });
  const [rootCauseResults,setRootCauseResults] = useState([])
  const [loading,setLoading] = useState(false)

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)

  useEffect(() => {
    updateStepActivation(step);
  }, [step]);

  function updateStepActivation(currentStep) {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      activate: index === currentStep,
    }));
    setSteps(updatedSteps);
  }

  function renderStepsComponent() {
    if (step === 1) {
      return <Step2 
              setSelectModel={setIsClickSelectModel} 
              selectModel={isClickSelectModel}
              handleSelectModel={handleSelectModel}
              setFormValue = {setFormValue}
              errors={errors} 
              setErrors = {setErrors}
              setLoading={setLoading}
              setIsToasterOpen={setIsToasterOpen}
              setReqSuccess={setReqSuccess}
              setToasterMessage={setToasterMessage}
              history={history}
              />;
    }
    else {
      return <Step1 
              errors={errors} 
              batchNumbers = {batchNumbers}
              setFormValue = {setFormValue}
              setErrors = {setErrors}
              formValue = {formValue}
              setLoading={setLoading}
              setIsToasterOpen={setIsToasterOpen}
              setReqSuccess={setReqSuccess}
              setToasterMessage={setToasterMessage}
              />;
    }
  }

  useEffect(()=>{
    const getBatchNumbers = async () => {
        try {
          setLoading(true)
          const batchNumbers = await fetchBatchNumbers();
          setBatchNumbers(batchNumbers);
        } catch (err) {
          console.error('Error fetching batch numbers:', err);
          setIsToasterOpen(true)
          setReqSuccess(false)
          setToasterMessage(err.message)
        }finally{
          setLoading(false)
        }
    };
    if(step == 0){
      getBatchNumbers();
    }
  },[step])

  const rootCauseConfigure = async() =>{
    try{
      setLoading(true)
      const result = await rootCauseConfiguration(formValue);
      setRootCauseResults(result.rootCauseResults)
      setIsToasterOpen(true);
      setReqSuccess(true);
      setToasterMessage("Success");
      setTimeout(() => {
        setIsToasterOpen(false);
        history.push(`/TestAutomation`);
      }, 5000);
    }catch(error){
      console.log(error.message)
      setIsToasterOpen(true);
      setReqSuccess(false);
      setToasterMessage(error.message);
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 5000);
    }finally{
      setLoading(false)
    }
  }

  function increaseStep() {
    let selectModelClick = isClickSelectModel
    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }
    if(selectModelClick){
      setIsClickSelectModel(false)
    }
    if(step == 1 && selectModelClick){
      rootCauseConfigure()
      resetFormFields()
    }
    
    setStep(prevStep => 
      Math.min(prevStep + 1, STEPS.length - 1)
    );
  }

  const resetFormFields = () =>{
    setFormValue(prevValue =>({
      ...prevValue,
      model : null
    }))
    setErrors(prevError=>({
      ...prevError,
      batchNumbers : null,
      model : null
    }))
  }


  function decreaseStep() {
    let selectModelClick = isClickSelectModel
    if (step === 0) {
      // resetFormFields();
      history.push(`/TestAutomation`)
    }else if(selectModelClick){
      setIsClickSelectModel(false)
    } else {
      setStep(prevStep => 
        Math.max(prevStep - 1, 0)
      );
    }
  }

  function handleSelectModel () {
    console.log(step,isClickSelectModel)
    setIsClickSelectModel(true)
  }

  const validationForm = () => {
    let error: any = {}
    if(step == 0){
      if (formValue.batchNumber == null) {
        error.batchNumber = "Please select a batch number"
      }
    }else if(step == 1){
      if (formValue.model == null) {
        error.model = "Please select a model"
      }
    }
    


    return error

  }

  function handleSubmit() {
    let form = formValue;
    // Submit logic here
    if (step === 2) {
      // resetFormFields();
      history.push(`/TestAutomation`)
    }else if(step == 0) {
      if(!form.batchNumber){
        setErrors(prevError =>({
          ...prevError,
          'batchNumber':'Please select a batch number'
        }))
      }
    }else if(step == 1 && isClickSelectModel){
      if(!form.model){
        setErrors(prevError =>({
          ...prevError,
          'model':'Please select a model'
      }))
    }
      console.log(form)
    }
    console.log(step)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
      <Grid container className={classes.pageTitleContainer}>
          <IconButton onClick={() => clickBack(history, "/TestAutomation")}>
            <RedirectIcon />
          </IconButton>
          <Grid className={classes.pageTitle} style={{margin:'0px'}}>Root Cause Analysis - Step 04</Grid>
        </Grid>

        <Grid container className={classes.circleContainer}>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <Grid item xs={2} md={1}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Circle number={index + 1} active={step.activate} />
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "12px",
                        paddingTop: "15px",
                        fontFamily: "Poppins, sans-serif",
                        color: step.activate ? '#005B96' : primaryGray,
                      }}
                    >
                      {step.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {index !== steps.length - 1 && (
                <Grid item xs={2} md={2}>
                  <hr
                    style={{
                      marginTop: "32px",
                      opacity: steps[index + 1].activate ? "1" : "0.12",
                    }}
                    color={
                      steps[index + 1].activate
                        ? "#005B96"
                        : "rgba(112, 112, 112, .12)"
                    }
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>

      <Grid container className={classes.customBoxContainer}>
      <Grid item className={classes.contentContainer}>
            {renderStepsComponent()}
          </Grid>
          <Grid item xs={12} md={12} className={classes.root} style={{width:'100%', padding:'0px', marginTop:'10px'}}>
            <Grid container spacing={2} justifyContent="flex-end" className="testing">
                {step !== 3 && (
                    <Button
                      onClick={decreaseStep}
                      variant="contained"
                      className={classes.backButton}
                      style={{margin:'10px'}}
                    >
                      {step === 1 || step === 2 ? "Back" : "Cancel"}
                    </Button>
                )}
                { (step !== 1 && step !== 2) && 
                    <Button
                      onClick={increaseStep}
                      variant="contained"
                      className={`${classes.devicePulseSaveButton} ${classes.button}`}
                      style={{margin:'10px 0px 10px 10px'}}
                    >
                      {step === 0 ? "Next" : "Done"}
                    </Button>
                }
                {isClickSelectModel &&
                  <Button
                  onClick={increaseStep}
                  variant="contained"
                  className={`${classes.devicePulseSaveButton} ${classes.button}`}
                  style={{margin:'10px'}}
                >
                  Run
                </Button>
                }
                {
                  step ===2 && 
                  <Button
                  onClick={()=>history.push("/TestAutomation")}
                  variant="contained"
                  className={`${classes.devicePulseSaveButton} ${classes.button}`}
                  style={{margin:'10px'}}
                >
                  Done
                </Button>
                }  
              </Grid>
          </Grid>
        </Grid>
      </div>
      <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
      <Loader loading={loading} />
    </ThemeProvider>
  );
}

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: "white",
          border: props.active ? "2px solid #005B96" : "0.25px solid #8F8F8F",
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? "#005B96" : primaryGray
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};