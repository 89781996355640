import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {environment} from "src/environments/environment";
import {secondaryTextColor, white} from "src/components/colors";
import {SearchBar} from "src/components/sub-components/SearchBar";
import {EditProductType} from "./EditProductType";
import {ViewProductType} from "./ViewProductType";
import {DeleteProductType} from "./DeleteProductType";
import { DeleteBlackIcon, DeleteIconLarge, EditIconLarge, EditIconTwo, ViewIcon } from "src/components/Icons";
import { primaryBlue } from "src/components/colors";

import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";
import { DevicesProductType } from "./DeviceProductType";
import { getDevicesWithProductType } from "../api-helper/apiHelper";
import { AuthService } from "src/app/authentication/auth.service";
import { Pagination } from "@material-ui/lab";
import { LoadingScreen } from "src/components/sub-components/LoadingScreen";
import Cookies from "js-cookie";
import { getAllSensors } from "src/app/maintenance/test-automation/components/apiHelper";


export function ConfigurationProduct(props) {
  const style = useStyles();
  const classes = useLocalStyles();
  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState({
    productName: "",
    otaUpgradable: false,
    remotelyConfigurable: false,
    sensorCodes: [],
    actuatorCodes: [],
    connectivity: "",
    protocol: "",
    persistence: "",
    dataFormat: "",
    versions: [],
    codecName: "",
    transcoder: "",
    id: ""
  });
  
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(products);

  //edit product related states 
  const [productTypeConfigView,setproductTypeConfigView]=useState(true);
  const[productTypeDeviceView,setProductTypeDeviceView]=useState(false);
  const [updatedOtaUpgradable, setUpdatedOtaUpgradable] = useState(selectedType.otaUpgradable);
  const [updatedRemotelyConfigurable, setUpdatedRemotelyConfigurable] = useState(selectedType.remotelyConfigurable);
  const [updatedDataFormat, setUpdatedDataFormat] = useState(selectedType.dataFormat);
  const [updatedPersistence, setUpdatedPersistence] = useState(selectedType.persistence);
  const [updatedConnectivity, setUpdatedConnectivity] = useState(selectedType.connectivity);
  const [updatedProtocol, setUpdatedProtocol] = useState(selectedType.protocol);
  const [updatedCodecName, setUpdatedCodecName] = useState(selectedType.codecName);
  const [updatedProductName, setUpdatedProductName] = useState(selectedType.productName);
  const [updatedVersion, setUpdatedVersion] = useState(selectedType.versions);
  const [sensorCodesOfDevice, setSensorCodesOfDevice] = useState(selectedType.sensorCodes);
  const [actuatorCodesOfDevice, setActuatorCodesOfDevice] = useState(selectedType.actuatorCodes);
  const [sensorsOfDevice,setSensorsOfDevice]=useState([]);
  const [actuatorsOfDevice,setActuatorsOfDevice]=useState([]);
  const [parameters, setParameters] = useState([]);
  const [intialParameters,setInitialParameters]=useState([]);
  const [codecToSend, setCodecToSend] = useState("");
  const [selectedCodec, setSelectedCodec] = useState('');

  const [sensors, setSensors] = useState([]);
  const [actuators, setActuators] = useState([]);
  const [step, setStep] = useState("");
  const [codecs, setCodecs] = useState([]);
  const [metaData, setMetaData] = useState(null);

  const [isToggled, setIsToggled] = useState(selectedType.transcoder);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [devicesProd, setDevicesProd] = useState([]);


  useEffect(() => {
    const uId = AuthService.getUserId();
    getAllProducts();
    getAllSensores()
  }, []);
  useEffect(() => {
    if (metaData == null) {
      fetchMetaData();
    }
    if(sensorCodesOfDevice){
      const names = sensorCodesOfDevice.map(code => {
        const sensor = sensors.find(s => s.code === code);
        return sensor
      });
      setSensorsOfDevice(names);
    }
    if (actuatorCodesOfDevice && metaData) {
      const actuatorNames = actuatorCodesOfDevice.map(code => {
        const actuator = metaData.actuators.find(s => s.code === code);
        return actuator ? actuator.name : code;
      });
      setActuatorsOfDevice(actuatorNames);
    }
  }, [sensorCodesOfDevice,sensors]);
  useEffect(() => {
    if (editOpen) {
      if (isToggled) {
        fetchCodecs();
      }
      fetchDevices(selectedType.productName);
    }

  }, [editOpen, isToggled])

  const fetchData = async (productName) => {
    try {
      setLoadingScreen(true);
      await Promise.all([
        fetchDevices(productName),
        fetchCodecs(),
      ]);
    } catch (error) {


    } finally {
      // Set loading to false once both are done (or error occurs)
      setLoadingScreen(false);
    }
  };
  
  const processJoinParameters = (dependencyMap, parameters) => {
    parameters.forEach(param => {
      const paramId = param.parameterId;

      if (dependencyMap[paramId]) {
        param.joinParameter = dependencyMap[paramId];

        // Remove reverse dependency
        param.joinParameter.forEach(joinParamId => {
          if (dependencyMap[joinParamId]) {
            const index = dependencyMap[joinParamId].indexOf(paramId);
            if (index > -1) {
              dependencyMap[joinParamId].splice(index, 1);
            }
            if (dependencyMap[joinParamId].length === 0) {
              delete dependencyMap[joinParamId]; // Clean up empty entries
            }
          }
        });

      } else {
        param.joinParameter = [];
      }
    });
  };
  const removeProductTypeById= async(idToDelete)=>{
    const accessToken = Cookies.get("ACCESS_TOKEN");
     setLoadingScreen(true);
      await axios.delete(`${environment.host}/core/product/productType/${idToDelete}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setProducts((prev) =>{
          const updatedProducts= prev.filter(product => product.id !== idToDelete);
        if(rowsPerPage*(page)>=updatedProducts.length){
          setPage(page-1);
        }
        return updatedProducts;
      }
      )
        setLoadingScreen(false);
      })
      .catch((error) => {
        setLoadingScreen(false);
      });
  }
  const mapDeviceData = (devices) => {
    const deviceDataMapped = devices.map(device => {
      if (updatedRemotelyConfigurable) {
        if ((device.remoteConfigAckTopic && device.remoteConfigTopic) && (device.remoteConfigAckTopic.length >= 0 && device.remoteConfigTopic.length >= 0)) {
          device.rmConfigured = "Configured";
        }
        else {
          device.rmConfigured = "Not-Configured";
        }
      }
      else {
        device.rmConfigured = "N/A"
      }
      if (updatedOtaUpgradable) {
        if ((device.otaAckTopic && device.otaRequestTopic) && (device.otaAckTopic.length >= 0 && device.otaRequestTopic.length >= 0)) {
          device.otaConfigured = "Configured";
        }
        else {
          device.otaConfigured = "Not-Configured";
        }
      }
      else {
        device.otaConfigured = "N/A"
      }
      return device;
    }
    )
    return deviceDataMapped;
  }

  const fetchDevices = async (productName) => {
    const result = await getDevicesWithProductType(productName);

    if (!result.isError) {
      const dataMapped = mapDeviceData(result.data);
      setDevicesProd(dataMapped);


    } else {

      console.error("Failed to fetch devices:", result.error);
    }
  };

  const fetchCodecs = async () => {
    const accessToken = AuthService.getAccessToken()
    await axios.get(`${environment.host}/core/codec`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        setCodecs(response.data.content);
      })
      .catch((error) => {
        console.error('Error fetching codecs:', error.response.statusText);
      });
  };
  const fetchMetaData = async () => {
    const accessToken = AuthService.getAccessToken();
    setLoadingScreen(true);
    const response = await axios.get(`${environment.host}/core/meta-data`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (response.data.content) {
      setMetaData(response.data.content);
      setActuators(response.data.content.actuators.sort((a, b) => (a.name < b.name ? -1 : 1)));
      setLoadingScreen(false);
    }
  };

  const getAllSensores =async () =>{
    try{
      const response =await getAllSensors()
      if(response){
        const sortedSensors = response.sort((a, b) => (a.codeValue < b.codeValue ? -1 : 1));
        setSensors(sortedSensors);
      }
    }catch(error){
      console.log(error.message)
    }
  }

  const handleConfigurationsClick = () => {
    history.push('/product-types');
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const toggleDeviceView = (isDeviceView) => {
    setProductTypeDeviceView(isDeviceView);
    setproductTypeConfigView(!isDeviceView);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleReviewOpen = (props) => {
    setSelectedType(props.productType);
    setReviewOpen(true);
  };
  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleEditOpen = async (props) => {
    const { productType } = props;
    await fetchData(productType.productName);
    setSelectedType(productType);
    setUpdatedOtaUpgradable(productType.otaUpgradable);
    setUpdatedRemotelyConfigurable(productType.remotelyConfigurable);
    setUpdatedCodecName(productType.codecName);
    setActuatorCodesOfDevice(productType.actuatorCodes);
    setIsToggled(productType.transcoder);
    setUpdatedConnectivity(productType.connectivity);
    setSensorCodesOfDevice(productType.sensorCodes);
    setUpdatedVersion(productType.versions);
    setUpdatedProductName(productType.productName);
    setUpdatedProtocol(productType.protocol);
    setUpdatedDataFormat(productType.dataFormat);
    setUpdatedPersistence(productType.persistence)
    const zeroVersion = productType.versions.find(version => version.versionNum === "0.0.0");
    if (zeroVersion) {
      processJoinParameters(zeroVersion.joinParameters, zeroVersion.remoteConfigurations);
      setParameters(zeroVersion.remoteConfigurations);
      setInitialParameters(zeroVersion.remoteConfigurations);
    }
    
    if (productType.codecName !== null) {
      setStep("select");
      setCodecToSend(productType.codecName);
    }
    
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    
  };

  const handleDeleteOpen = (props) => {
    setDeleteOpen(true);
    setSelectedType(props.productType);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const getAllProducts = () => {
    setLoadingScreen(true);
    axios.get(`${environment.host}/core/products`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthService.getAccessToken()}`,
      },
    })
      .then((response) => {
        const productsData = response.data.content || [];
        setProducts(productsData);

        // Flatten the products data to get unique product types
        const allProductTypes = productsData.reduce((acc, curr) => {
          acc[curr.productType] = true;
          return acc;
        }, {});
        setAllProducts(Object.keys(allProductTypes));
        setLoadingScreen(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setProducts([]);
        setAllProducts([]);
        setLoadingScreen(false);
      });
  };

  useEffect(() => {
    const uId = Cookies.get("USER_ID");
    getAllProducts();
  }, []);

    useEffect(() => {
      const results = products.filter((obj) => {
        return  obj.productName.toLowerCase().includes(search.trim().toLowerCase());
      });
      setFilteredProducts(results);
    }, [search,products]);
  

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading">
          Product Types
        </div>
        {productTypeConfigView?
      <Grid container className={style.container}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6} md={3} className={classes.searchBarProductType}>
            <SearchBar placeholder="Search Product Name" value={search} onChange={(e) => {setSearch(e.target.value);setPage(0)}}/>
          </Grid>
          <Grid item md={7}></Grid>
            <Grid
              item
                xs={6}
                md={2}
                style={{textAlign: "right",}}
              >
              <Button
                variant="contained"
                onClick={handleConfigurationsClick}
                className={classes.configuration}
                style={{textTransform: "none", whiteSpace:"nowrap" }}
              >
                Version Management
              </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{marginTop: "20px"}}
        >
  
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                    <Typography className={style.tablehead}                     
                              display="inline"
                              >
                    Product Name
                    </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                    <Typography className={style.tablehead}                     
                                        display="inline"
                                      >
                    Protocol
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                    <Typography className={style.tablehead}                     
                                        display="inline"
                                      >
                    Transcoding Enabled
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                    <Typography className={style.tablehead}                     
                                        display="inline"
                                      >
                    Remote Config
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                    <Typography className={style.tablehead}                     
                                        display="inline"
                                      >
                    OTA Upgradable
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white",
                  }}
                >
                    <Typography className={style.tablehead}                     
                                        display="inline"
                                      >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredProducts !== undefined &&
                filteredProducts  //filtered by search name
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((product, i) => (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <Typography
                          display="inline"
                          className={classes.tableText}
                        >
                          {product.productName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <Typography className={classes.tableText}>
                          {product.protocol}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <Grid item 
                          className={classes.tableText}
                          style={{
                            paddingLeft: "5px",
                             display:"inline"
                          }}>
                           {product.transcoder ?(
                                <Chip
                                  className={style.greenChip}
                                  label="True"
                                />):
                                (<Chip
                                  className={style.redChip}
                                  label="False"
                                />)
                              }
                          </Grid>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                            <Grid item 
                          className={classes.tableText}
                          style={{
                            paddingLeft: "5px",
                             display:"inline"
                          }}>
                
                               {product.remotelyConfigurable ?(
                                <Chip
                                  className={style.greenChip}
                                  label="True"
                                />):
                                (<Chip
                                  className={style.redChip}
                                  label="False"
                                />)
                              }
              </Grid>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                  <Grid item 
                          className={classes.tableText}
                          style={{
                            paddingLeft: "5px",
                             display:"inline"
                          }}>
                               {product.otaUpgradable ?(
                                <Chip
                                  className={style.greenChip}
                                  label="True"
                                />):
                                (<Chip
                                  className={style.redChip}
                                  label="False"
                                />)
                              }
                              </Grid>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor:
                            i % 2 === 0 || i / 2 === 0
                              ? "#F9F9F9"
                              : "white",
                        }}
                      >
                        <IconButton
                           className={style.iconStyle}
                          onClick={() =>
                            handleReviewOpen({
                              productType: product
                            })
                          }
                        >
                          <ViewIcon color = '#707070'/>
                        </IconButton>
                        <IconButton
                          className={style.iconStyle}
                          onClick={() => {
                            handleEditOpen({
                              productType: product
                            });
                          }}
                        >
                          <EditIconLarge/>
                        </IconButton>
                        <IconButton
                           className={style.iconStyle}
                          onClick={() => {
                            handleDeleteOpen({
                              productType: product
                            });
                          }}
                        >
                          <DeleteIconLarge />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
        <Grid container justifyContent="center">
            <Pagination className={style.pagination}
                      count={Math.ceil(filteredProducts.length / rowsPerPage)} // Calculate total pages
                      page={page + 1} // Convert 0-based index to 1-based for Pagination
                      onChange={(event, value) => setPage(value - 1)} // Update page state
            />
        </Grid>      
      {reviewOpen && (
        <ViewProductType
          productType={selectedType}
          open={reviewOpen}
          onClose={handleReviewClose}
        />
      )}
      {editOpen  && (
        <EditProductType
          productType={selectedType}
          open={editOpen}
          onClose={() => handleEditClose()}
          toggleDeviceView={toggleDeviceView}
          updatedOtaUpgradable={updatedOtaUpgradable}
          setUpdatedOtaUpgradable={setUpdatedOtaUpgradable}
          updatedRemotelyConfigurable={updatedRemotelyConfigurable}
          setUpdatedRemotelyConfigurable={setUpdatedRemotelyConfigurable}
          setUpdatedCodecName={setUpdatedCodecName}
          setActuatorCodesOfDevice={setActuatorCodesOfDevice}
          setIsToggled={setIsToggled}
          setUpdatedConnectivity={setUpdatedConnectivity}
          setSensorCodesOfDevice={setSensorCodesOfDevice}
          setUpdatedVersion={setUpdatedVersion}
          setUpdatedProductName={setUpdatedProductName}
          setUpdatedProtocol={setUpdatedProtocol}
          setUpdatedPersistence={setUpdatedPersistence}
          setUpdatedDataFormat={setUpdatedDataFormat}
          setActuatorsOfDevice={setActuatorsOfDevice}
          setSensorsOfDevice={setSensorsOfDevice}
          updatedDataFormat={updatedDataFormat}
          updatedPersistence={updatedPersistence}
          updatedConnectivity={updatedConnectivity}
          updatedProtocol={updatedProtocol}
          updatedCodecName={updatedCodecName}
          updatedProductName={updatedProductName}
          updatedVersion={updatedVersion}
          sensorCodesOfDevice={sensorCodesOfDevice}
          actuatorCodesOfDevice={actuatorCodesOfDevice}
          sensorsOfDevice={sensorsOfDevice}
          actuatorsOfDevice={actuatorsOfDevice}
          isToggled={isToggled}
          id={selectedType.id}
          fetchDevices={fetchDevices} 
          devices={devicesProd} 
          setDevices={setDevicesProd}
          mapDeviceData={mapDeviceData}
          parameters={parameters}
          setParameters={setParameters}
          codecToSend={codecToSend}
          setCodecToSend={setCodecToSend}
          selectedCodec={selectedCodec}
          setSelectedCodec={setSelectedCodec}
          sensors={sensors}
          setSensors={setSensors}
          actuators={actuators}
          setActuators={setActuators}
          step={step}
          setStep={setStep}
          codecs={codecs}
          setCodecs={setCodecs}
          metaData={metaData}
          setMetaData={setMetaData}
          getAllProducts={getAllProducts}
                  />
      )}
      {deleteOpen && (
        <DeleteProductType
          productType={selectedType}
          open={deleteOpen}
          onClose={handleDeleteClose}
          removeProductTypeById={removeProductTypeById}
        />
      )}
      
    </Grid>:""}
    {productTypeDeviceView?<DevicesProductType 
    productType={selectedType}  
    otaToggled={updatedOtaUpgradable} 
    rmToggled={updatedRemotelyConfigurable} 
    
    toggleDeviceView={toggleDeviceView} 
    fetchDevices={fetchDevices} 
    devices={devicesProd} 
    setDevices={setDevicesProd}
    mapDeviceData={mapDeviceData}
    />
    :""}
    {<LoadingScreen loading={loadingScreen}/>}
  </div>
  </ThemeProvider>
  );
}

const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  configuration: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",

    },
  },
  searchBarProductType:{
    display:"flex",
    justifyContent:"left",
    maxWidth:"220px"
  }

}));

