import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";

export default function AlertPopup({ setOpenDialog, openDialog, handleSkip , textYes,textNo,title,description}) {
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {" "}
        <DialogTitle id="alert-dialog-title">
          {" "}
          {title}{" "}
        </DialogTitle>{" "}
        <DialogContent>
          {" "}
          <DialogContentText id="alert-dialog-description">
            {" "}
            {description}{" "}
          </DialogContentText>{" "}
        </DialogContent>{" "}
        <DialogActions>
          {" "}
          <Button onClick={handleSkip}>{textYes}</Button>{" "}
          <Button onClick={handleClose}> {textNo} </Button>{" "}
        </DialogActions>{" "}
      </Dialog>{" "}
    </React.Fragment>
  );
}
