import {AfterViewInit, Component, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ConfigurationProduct } from '././ConfigurationProduct';
import { SideNavService } from 'src/app/layouts/side-menu/sidenav/sidenav.service';

@Component({
  selector: 'app-my-component',
  template: `
    <div [id]="rootId" style="transition: 0.6s" [ngClass]="isSideNavVisible()?'side-menu-padding':''"></div>`,
    styleUrls: ['../../../components/react.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationProductComponentWrapper implements OnChanges, OnDestroy, AfterViewInit {

  constructor(private sideNavService: SideNavService) {
  }

  public rootId = "rootId"

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  private render() {
    ReactDOM.render(React.createElement(ConfigurationProduct), document.getElementById(this.rootId))
  }
}
