import * as React from "react";
import { Box, Button, CircularProgress, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import useStyles from "../assets/styles";
import { useEffect, useState } from "react";
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { DropDown } from "./DropDown";
import axios from "axios";
import { environment } from "src/environments/environment";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step1(props) {
  const classes = useStyles();
  //const [selecedProductTypeObj,setSelectedProductTypeObj]=useState({productName:'',versions:[]})

  useEffect(() => {
    fetchProductType();
    
  }, []);
  
const fetchProductType=async()=>{
  props.setLoading(true);
  axios.get(`${environment.host}/core/products`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
  },
})
  .then((response) => {
    if (response.data.content !== undefined) {
      props.setProductTypes(response.data.content.filter(product=>product.remotelyConfigurable===true));
      props.setLoading(false);

    }
  }
)
  .catch((err) => {
    console.log('Failed to fetch product types:', err);
    props.setProductTypes([]);
    props.setLoading(false);
    let errMsg=err?err.response.data.message:"Failed to get data";
    props.setMessage(errMsg);
    props.setMsgSuccess(false);
    props.setToaster(true);
    setTimeout(() => {
      props.setToaster(false);
    }, 2000);
  }
);}
  const getProductVersions=(productType)=>{
    let activeVersions=[]
    
    productType.versions.forEach(version => {
      if (version.status=='APPROVED'){
        activeVersions.push(version.versionNum);
      }
    });
return activeVersions;
  }
 
  function getProductTypeConfig(productType){
  
    props.setproductTypeRemoteConfigs(productType.versions[0].remoteConfigurations);
    
  }

  const fetchDevicesFiltered=(productType,version)=>{
    props.setLoading(true);
    axios.get(`${environment.host}/core/product/devices?productType=${productType}${(version!='')?(`&currentVersion=${version}`):''}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
          let deviceIds = [];
          
          response.data.content.forEach((device) => {
            deviceIds.push(device.id);
            
          });
          props.setDeviceIds(deviceIds);
          props.setLoading(false);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log('Failed to fetch product types:', err);
        props.setDeviceIds([]);
        let errMsg=err?err.response.data.message:"Failed to get data";
        props.setMessage(errMsg);
        props.setMsgSuccess(false);
        props.setToaster(true);
        setTimeout(() => {
          props.setToaster(false);
        }, 2000);
      });
  }

  const handleError = (name) => {
    props.setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }
  const handleSelectProductType = (value) => {
    props.setSelectedProductType(value);
   props.setErrors(prev => ({
    ...prev,
    productTypeError: { error: false, errorMsg: "" }
  }))
   props.setSelectedVersion('');
   props.setSelectedDeviceIds([]);
    handleError('productType');
    const activeVersions = getProductVersions(value);
    props.setVersions(activeVersions);
 
    if (props.versions.length===0){
    fetchDevicesFiltered(value.productName,'');
   }

   
    
  };
  const handleSelectProductVersion = (value) => {
    props.setSelectedVersion(value);
    props.setErrors(prev => ({
      ...prev,
      versionError: { error: false, errorMsg: "" }
    }))
    props.setSelectedDeviceIds([]);
    handleError('version');
    fetchDevicesFiltered(props.selectedProductType.productName,value);
  };
  const handleSelectDevice = (value) => {
    props.setSelectedDeviceIds(value);
    handleError('deviceID');
    props.setErrors(prev => ({
      ...prev,
      deviceIdError: { error: false, errorMsg: "" }
    }))
    props.setChangeInDeviceIds(true);
    getProductTypeConfig(props.selectedProductType);
    
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>

        <Grid container className={classes.root} style={{padding:"0px 80px"}}>
          <Grid container  className={classes.formContent}   >

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Product Type:
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
                <DropDown
                    options={props.productTypes}
                    setOptions={props.setProductTypes}
                    type='productType'
                    emptyTag='-Select Product Type-'
                    setSelectedOption={handleSelectProductType}
                    value={props.selectedProductType}
                    handleError={handleError}
                    isSingle={true}
                  />
              {props.errors.productTypeError.error?<Typography className={classes.errorText}>{props.errors.productTypeError.errorMsg}</Typography>:""}

              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}}>
              <Grid item xs={12} md={12}>
                  <Typography className={classes.typo}>
                    Version:
                  </Typography>  
                  <DropDown
                    options={props.versions}
                    disabled={!(props.versions.length>0)}
                    setOptions={props.setVersions}
                    type='version'
                    emptyTag='-Select Version-'
                    setSelectedOption={handleSelectProductVersion}
                    value={props.selectedVersion}
                    handleError={handleError}
                    isSingle={true}
                  />
              {props.errors.versionError.error?<Typography className={classes.errorText}>{props.errors.versionError.errorMsg}</Typography>:""}

              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} style={{marginTop: "20px"}}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.typo}>
                    Select Device ID's:
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>  
                  <DropDown
                    options={props.deviceIds}
                    setOptions={props.setDeviceIds}
                    disabled={(props.selectedVersion=="")} 
                    type='deviceId'
                    emptyTag='-Select Device IDs-'
                    setSelectedOption={handleSelectDevice}
                    value={props.selectedDeviceIds}
                    handleError={handleError}
                    isSingle={false}
                  />
              {props.errors.deviceIdError.error?<Typography className={classes.errorText}>{props.errors.deviceIdError.errorMsg}</Typography>:""}
              </Grid>
            </Grid>   
          </Grid>
        </Grid>

      </div>
    </ThemeProvider>
  );
}
