import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {isPasswordMatches} from '../../validator';
import { AccountService } from 'src/app/account/account.service';
import { toast } from 'react-toastify';

declare function sFunction8(): any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  hash;
  email;
  error;
  isDialog = false;
  showPassword={password:false,confirmPassword:false}

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
               private spinner: NgxSpinnerService, protected router: Router, protected accountService : AccountService,private toast: ToastrService) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    this.route.queryParams.subscribe(params => {
      this.hash = params['hash'];
      this.email = params['email'];
    });

    this.accountService.validateHashKey(this.hash,null,this.email).subscribe(
      (response) =>{
        if(response.content == "EXPIRED"){
          this.router.navigate(['/login']);
          this.toast.error('Session expired, please reset again.','Error!')
        }
      },error => {
        this.toast.error(error.error.message, 'Error!!',{
          timeOut: 3000,  
        });
      }
    )

    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    }, {validator: isPasswordMatches('password', 'confirmPassword')});
  }

  register(entity: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    entity.key = this.hash;
    entity.email = this.email;
    this.spinner.show();

    this.accountService.setUpUserNamePassword(entity).subscribe(response => {
          this.router.navigate(['/login']);
        }, error => {
          this.toast.error(error.error.message, 'Error!!',{
            timeOut: 3000,  
          });
          this.spinner.hide();
        }, () => {
          this.spinner.hide();
        });
  }

  togglePassword(field: 'password' | 'confirmPassword'): void {
    this.showPassword[field] = !this.showPassword[field];
  }
}
