import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Grid, makeStyles, Theme, Typography,} from '@material-ui/core';
import {CancelIcon, DownArrowIcon, SearchIcon, UpArrow} from "src/components/Icons";


const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'white',
    fontFamily: 'poppins'
  },
  inputContainer: {
    border: '0.5px solid',
    borderRadius: '5px',
    minHeight: '31px',
    padding: '2px 5px 0px 5px',
    maxHeight: '100px',
    cursor: 'pointer',
    backgroundColor: 'white'
  }
}));

export function MappingDropDown(props) {
  const classes = useStyles();
  const [selectedSensor, setSelectedSensor] = useState(props.kitModelFormType !== 'Add' && props.type == 'selectSensor' ? props.selectedSensor[props.index] : []);
  const [isClick, setIsClick] = useState(false);

  const [calMethod, setCalMethod] = useState("")
  const [calType, setCalType] = useState("")
  const [sensorIndexes, setSensorIndexes] = useState([])

  let allOptions = props.options.map((sensor, index) => `${sensor} ${index + 1}`)
  const [sensors, setSensors] = useState([]);

  const handleSelectedSensor = (e) => {
    const index = allOptions.indexOf(e)
    const updateSensorIndex = [...props.sensorNumberList]
    if (selectedSensor.includes(e)) {
      const filterSensor = selectedSensor.filter(s => s !== e);
      setSelectedSensor(filterSensor);
      const filterIndex = sensorIndexes.filter(i => i !== index)
      updateSensorIndex[props.index] = filterIndex
      setSensorIndexes(filterIndex)
    } else {
      const sensor = [...selectedSensor, e];
      setSelectedSensor(sensor);
      const sensorIndex = [...sensorIndexes, index];
      updateSensorIndex[props.index] = sensorIndex
      setSensorIndexes(sensorIndex)
    }
    props.setSensorNumberList(updateSensorIndex)
    props.handleError('mapError')
  };


  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsClick(false)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropDown = () => {
    const click = isClick
    console.log(click)
    if (props.type == 'selectSensor') {
      let allOptions = props.options.map((sensor, index) => `${sensor} ${index + 1}`)
      setSensors(allOptions)
    } else {
      setSensors(props.options)
    }

    setIsClick(!isClick);
  };


  const allSensorSelect = () => {
    const updateSensorIndex = [...props.sensorNumberList];
    if (sensors.length === selectedSensor.length) {
      setSelectedSensor([]);
      props.setSensorNumberList([]);
      setSensorIndexes([])
    } else {
      setSelectedSensor(sensors);
      const sensorIndex = [];
      sensors.forEach((sensor, index) => {
        sensorIndex.push(index);
      });
      setSensorIndexes(sensorIndex)
      updateSensorIndex[props.index] = sensorIndex;
      props.setSensorNumberList(updateSensorIndex);
    }
    props.handleError('mapError')
  };


  const handleCalMethod = (e) => {
    if (props.type == 'type') {
      let updateOperationList = [...props.operationList]
      if (e == "") {
        updateOperationList[props.index] = props.options[0]

      } else {
        setCalType(e)
        setIsClick(false)
        updateOperationList[props.index] = e
      }
      props.setOperationList(updateOperationList)

    } else if (props.type == 'aggregation') {
      let updateAggregationList = [...props.aggregationList]
      if (e == "") {
        updateAggregationList[props.index] = props.options[0]
      } else {
        setCalMethod(e)
        setIsClick(false)
        updateAggregationList[props.index] = e
      }
      props.setAggregationList(updateAggregationList)
    }
  }

  const searchSensor = (value) => {
    const allSensors = props.type == 'selectSensor' ? allOptions : props.options;
    if (value !== "") {
      const trimmedValue = value.trim().toLowerCase();
      const filterSensor = allSensors.filter(option => option.toLowerCase().includes(trimmedValue));
      setSensors(filterSensor);
    } else {
      setSensors(allSensors);
    }
  };

  const removeSensor = (index) => {
    const filterArray = [...selectedSensor];
    const updateSensorIndex = [...props.sensorNumberList]
    filterArray.splice(index, 1);
    setSelectedSensor(filterArray)
    const filterIndex = [...sensorIndexes]
    filterIndex.splice(index, 1)
    updateSensorIndex[props.index] = filterIndex
    setSensorIndexes(filterIndex)
    props.setSensorNumberList(updateSensorIndex)
  };

  return (
    <div ref={dropdownRef} style={{width:'100%'}}>
      {props.type === 'selectSensor' && (
        <div>
          <Grid
            container
            style={{
              border: '0.5px solid #324054',
              borderRadius: '5px',
              minHeight: '31px',
              paddingLeft: '5px',
              maxHeight: '100px',
              cursor: 'pointer',
              backgroundColor: 'white',
              paddingTop: '2px',
              overflowY: 'auto',
              fontFamily: 'poppins',
              fontSize: '12px'
            }}

            alignItems='center'
          >
            {selectedSensor.length === 0 ?
              <Grid item xs={10} md={10} onClick={handleDropDown}>
                <input
                  type="text"
                  placeholder='Select Sensors'
                  style={{border: 'none', cursor: 'pointer', backgroundColor: 'white', width: '100%'}}
                  // readOnly={true}
                  onChange={() => handleDropDown()}
                />
              </Grid>
              :
              <Grid item xs={7} md={7}>
                <Grid container style={{
                  backgroundColor: '#2A7BEC',
                  color: 'white',
                  padding: '0px 5px 2px 5px',
                  textAlign: 'left',
                  width: 'auto'
                }} alignItems='center'>
                  <Grid item md={8} xs={8} style={{
                    fontSize: '14px',
                    marginRight: '10px'
                  }}>{selectedSensor[0].length > 10 ? selectedSensor[0].substring(0, 10) + "..." : selectedSensor[0]}</Grid>
                  <Grid item md={2} xs={2} onClick={() => props.kitModelFormType != 'Edit' ? removeSensor(0) : null}
                        style={{paddingRight: '5px'}}>
                    <Grid container justifyContent='flex-end'>
                      <CancelIcon color='white'/>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            }
            {selectedSensor.length > 1 ?

              <Grid item xs={5} md={4} onClick={handleDropDown}>
                <Grid container>
                  <Grid item xs={5} md={5}></Grid>
                  <Grid item xs={1} md={3}>{"+" + (selectedSensor.length - 1).toString()}</Grid>
                  <Grid item xs={2} md={4}>
                    {isClick && props.type === 'selectSensor' ? <UpArrow/> : <DownArrowIcon/>}
                  </Grid>

                </Grid>
              </Grid>
              :
              <Grid item xs={selectedSensor.length == 1 ? 7 : false} md={selectedSensor.length == 1 ? 4 : false}
                    onClick={handleDropDown}>
                <Grid container>
                  <Grid item xs={6} md={8}></Grid>
                  {props.kitModelFormType != 'Edit' ?
                    <Grid item xs={2} md={4}>
                      {isClick && props.type === 'selectSensor' ? <UpArrow/> : <DownArrowIcon/>}
                    </Grid>
                    :
                    <></>

                  }

                </Grid>
              </Grid>
            }
          </Grid>
        </div>
      )}
      {(props.type == 'aggregation' || props.type == 'type') && (
        <div>
          <Grid container
                className={classes.inputContainer}
                onClick={handleDropDown}
                style={props.style}
          >
            <Grid item xs={props.type == 'aggregation' ? 10 : 7} md={props.type == 'aggregation' ? 10 : 7}>
              <input
                type="text"
                placeholder={props.placeholder}
                className={classes.inputField}
                readOnly={true}
                onChange={() => handleDropDown()}
                style={props.inputStyle}
                value={props.kitModelFormType != 'Edit' ? (props.type === 'aggregation' ? calMethod : calType) || props.options[0] : props.type === 'aggregation' ? props.aggregationList[props.index] : (props.operationList[props.index]) || props.selectedSensor[0]}
              />
            </Grid>
            {
              props.kitModelFormType != 'Edit' ?
                <Grid item xs={props.type == 'aggregation' ? 2 : 5} md={props.type == 'aggregation' ? 2 : 5}>
                  {props.isClick && (props.type == 'aggregation' || props.type == 'type') ? <UpArrow/> :
                    <DownArrowIcon/>}
                </Grid>
                :
                <></>
            }

          </Grid>
        </div>
      )}
      {isClick && props.type === 'selectSensor' && (
        <div>
          <Grid container style={{
            border: '0.5px solid',
            borderRadius: '5px',
            backgroundColor: 'white',
            zIndex: 1,
            position: 'relative',
            width: '100%',
            cursor: 'pointer'
          }}>
            <Grid item style={{width: '100%'}}>
              <Grid container style={{borderBottom: '0.5px solid #55555580', padding: '10px 5px 0 5px'}}
                    alignItems='center' onClick={allSensorSelect}>
                <Grid item>
                  <input type="checkbox" style={{display: 'inline'}}
                         checked={props.options.length === selectedSensor.length} onChange={() => allSensorSelect()}/>
                </Grid>
                <Grid item style={{marginLeft: '5px'}}>
                  <Typography style={{fontSize: '12px', fontWeight: 400}}>Select All</Typography>
                </Grid>
              </Grid>
              <Grid container style={{padding: '5px', borderBottom: '0.5px solid #55555580'}} alignItems='center'>
                <Grid item>
                  <SearchIcon/>
                </Grid>
                <Grid item style={{marginLeft: '5px'}}>
                  <input
                    placeholder='Search'
                    style={{border: 'none', marginBottom: '5px'}}
                    onChange={(e) => searchSensor(e.target.value)}
                  />
                </Grid>
              </Grid>
              {sensors.map((option, index) => (
                <Grid container key={index} alignItems='center' style={{padding: '5px'}} className={props.className}
                      onClick={() => props.kitModelFormType != 'Edit' ? handleSelectedSensor(option) : null}>
                  <Grid item>
                    <input type="checkbox" style={{display: 'inline'}} checked={selectedSensor.includes(option)}
                           onChange={() => props.kitModelFormType != 'Edit' ? handleSelectedSensor(option) : null}/>
                  </Grid>
                  <Grid item style={{marginLeft: '5px'}}>
                    <Typography style={{fontSize: '12px', fontWeight: 400}}>{option}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      )}
      {isClick && (props.type == 'aggregation' || props.type == 'type') && (
        <div>
          <Grid container style={{
            border: '0.5px solid',
            borderRadius: '5px',
            backgroundColor: 'white',
            zIndex: 1,
            position: 'relative',
            width: '100%',
            cursor: 'pointer'
          }}>
            {sensors.map((option, index) => (
              <Grid container key={index} alignItems='center' style={{padding: '5px'}} className={props.className}
                    onClick={() => handleCalMethod(option)}>

                <Grid item style={{marginLeft: '5px'}}>
                  <Typography style={{fontSize: '12px', fontWeight: 400}}>{option}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};


