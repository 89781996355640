import { Button, Grid, InputBase, Typography } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import useStyles from "src/app/maintenance/assets/styles";
import { primaryBlue } from "src/components/colors";

export const AddSensors = (props)=> {
    const styles = useStyles()
    return(
        <Grid container style={{margin:'10px 10px 10px 20px'}}>
            <Grid item xs={12} md={12} style={{marginBottom:'5px'}}>
                <input 
                    onChange={props.sensorNameChange} 
                    placeholder="Add Sensor name" 
                    className={styles.formInput}
                    type="text"
                />
            </Grid>
            {props.error.sensorNameError&&
                <Typography className={styles.errorText} >{props.error.sensorNameError}</Typography>
            }
            <Grid item container xs={12} md={12}>
                <Grid item xs={9} md={9}>
                    <input 
                        onChange={props.sensorCodeChange} 
                        placeholder="Add Sensor code" 
                        className={styles.formInput}
                        type="text"
                    />
                </Grid>
                <Grid item xs={3} md={3} style={{justifyContent:'flex-end',display:'flex'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            color: 'white',
                            borderRadius: '4px',
                            boxShadow: 'none',
                            backgroundColor: primaryBlue,
                            width:'100%',
                            marginLeft:'5px'
                        }}
                        onClick={props.handleSave}
                    >
                        add
                    </Button>
                </Grid>
            </Grid>
            
            {props.error.sensorCodeError &&
                <Typography className={styles.errorText} >{props.error.sensorCodeError}</Typography>
            }
        </Grid>
    )
}