import * as React from "react";
import {  createTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import { useState } from "react";
import { SelectAModelComponent } from "./SelectAModelComponent";
import useStyles from "src/app/maintenance/assets/styles";
import { BuildNewModelIcon, SelectAModelIcon } from "src/components/Icons";
import { environment } from "src/environments/environment";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function Step2({setSelectModel,selectModel,handleSelectModel,setFormValue,errors,setErrors}) {
  const classes = useStyles();

  const handleNewModel = () => {
    window.open(`${environment.autoMl}project-init`, '_blank'); 
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
          {!selectModel && <Grid container className={classes.root} justifyContent="space-between">
            <Grid item xs={5} md={5} className={classes.options} >
              <Grid container onClick={handleNewModel}>
                <Grid container justifyContent="center">
                  <BuildNewModelIcon color="white" strokeOpacity={0} />
                </Grid>
                <Grid container justifyContent="center" >
                  <Typography>Build New Model</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5} md={5} onClick={handleSelectModel} className={classes.options}>
              <Grid container>
                <Grid container justifyContent="center">
                  <SelectAModelIcon />
                </Grid>
                <Grid container justifyContent="center">
                  <Typography>Select a Model</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}

        {selectModel && <SelectAModelComponent 
                          setFormValue={setFormValue}
                          errors = {errors}
                          setErrors={setErrors}
                          />
        } 
      </div>
    </ThemeProvider>
  );
}
