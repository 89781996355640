import * as React from "react";
import { useCallback, useEffect, useRef, useState} from "react";
import { Box, Button, createTheme, Grid, IconButton, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import useStyles from "../assets/styles";
import { primaryBlue, primaryGray } from "src/components/colors";
import Step1ConnectDevices from "./steps/Step1ConnectDevices";
import { environment } from "src/environments/environment";
import Step2SelectDevices from "./steps/Step2SelectDevices";
import Step3DataPreview from "./steps/Step3DataPreview";
import { Loader } from "../test-automation/components/Loader";
import { ToasterComponent } from "../test-automation/components/Toaster";
import axios from "axios";
import { DialogPopup } from "../test-automation/components/DialogPopup";
import { RedirectIcon } from "src/components/Icons";
import { clickBack } from "src/app/utils";
import { addSensor, getAllSensors } from "../test-automation/components/apiHelper";
import Cookies from "js-cookie";

const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
});

const STEPS = [
    {
      text: "Connect Devices",
      activate: true,
    },
    {
      text: "Select Test Devices",
      activate: false,
    },
    {
      text: "Data Preview",
      activate: false,
    },
];

export function ConnectDataStreamComponent() {
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState(STEPS);
    const classes = useStyles();
    const history = useHistory();
    const [apiKey, setApiKey] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    const [batchNumber, setBatchNumber] = useState("");
    const [connectDeviceId, setConnectDeviceId] = useState([]);
    const [devices, setDevices] = useState([]);
    const [connectedDevices, setConnectedDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);
    const [message, setMessage] = useState("");
    const [isExistingBatchNumber, setIsExistingBatchNumber] = useState(false);
    const [newBatchNumber, setNewBatchNumber] = useState("");
    const [selectedDeviceFinal, setSelectedDeviceFinal] = useState([]);
    const [inputValue, setInputValue] = useState(batchNumber);
    const [isBack, setIsBack] = useState(false);
    const [errors,setErrors] = useState({
      batchNumberError:null,
      deviceError : null
    })
    const [isUpgrade,setIsUpgrade] = useState(false)

    //Errors
    const [baseUrlError, setBaseUrlError] = useState(false);
    const [batchNumberError, setBatchNumberError] = useState(false);
    const [apiKeyError, setApiKeyError] = useState(false);
    const [selectedDeviceError, setSelectedDeviceError] = useState(false);

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isShowSensorPopup,setIsShowSensorPopup] = useState(false)
    const [sensorDetails,setSensorDetails] = useState([])
    const [existSensorCode,setExistSensorCode] = useState([])
    const inputRefs = useRef([]);
    const [sensorNameError,setSensorNameError] = useState("")

    const [formData, setFormData] = useState({
        baseUrl: "",
        batchNumber: "",
        apiKey: ""
    });
    console.log(step);

    useEffect(() =>{
      const getSensorCode = async() =>{
        const sensors = await getAllSensors()
        const sensorCode = sensors.map(sensor => sensor.code)
        setExistSensorCode(sensorCode)
      }
      getSensorCode()
    },[])

    const validationForm = async () => {
      const baseUrlError = baseUrl.trim() === "";
      const batchNumberError = batchNumber.trim() === "";
      const apiKeyError = apiKey.trim() === "";
    
      setBaseUrlError(baseUrlError);
      setBatchNumberError(batchNumberError);
      setApiKeyError(apiKeyError);
    
      if (baseUrlError || batchNumberError || apiKeyError) {
        return false;
      }
    
      return true;
    };

    const validateForm = () => {
      const selectDeviceError = selectedDeviceFinal.length === 0;
      let error : any = {}
      if(selectDeviceError) {
        error.deviceError = 'Selecting At least One Device is Required.'
      }
      return error;
    }

    const connectDevices = async () => {
      localStorage.removeItem('inputValue');
      localStorage.removeItem('fetchedDevices');
      localStorage.removeItem('selectedDevices');
      setLoading(true)
      try{
        await axios.post(`${environment.host}/core/user/${Cookies.get('USER_ID')}/connect-device`,{
          baseUrl: formData.baseUrl,
          batchNumber: formData.batchNumber,
          apiKey: formData.apiKey,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        })
        .then((response) => {
          const connectDeviceId = [response.data.content.id];
          setConnectDeviceId(connectDeviceId);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        })
        .catch((error) => {
          const errorData = error.response.data;
          if (error.response.status === 422) {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage(errorData.message);
            setTimeout(() => {
              setIsToasterOpen(false);
            }, 5000);
          } else {
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage("Something went wrong.");
            setTimeout(() => {
              setIsToasterOpen(false);
            }, 5000);
          }
          throw new Error(errorData.message);
        })
      }catch(err){
        console.log('Error for connect device')
      }finally{
        setLoading(false)
      }

    }    
    const fetchConnectDevices = async (connectDeviceId) => {
      setLoading(true);
        try {
        const response = await axios.get(`${environment.host}/core/user/${Cookies.get('USER_ID')}/connect-device/${connectDeviceId}/devices`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
        console.log('Full response data:', response.data);
        if (response.data && response.data.content) {
          const devices = response.data.content;
          console.log('Fetched devices content:', devices);
          const sensorCodes = []
          devices.map((device) =>{
            device.sensorCodes.map(code =>{
              if(!sensorCodes.includes(code)){
                sensorCodes.push(code)
              }
            })
          })
          const excludeSensorCode = sensorCodes.filter(sensor => !existSensorCode.includes(sensor))
          const excludeSensorDetails = []
          if(excludeSensorCode.length != 0){
            excludeSensorCode.forEach(code =>{
              const sensorObject = {code:code,codeValue:""}
              excludeSensorDetails.push(sensorObject)
            })
          }
          setSensorDetails(excludeSensorDetails)
          setConnectedDevices(devices);
          setLoading(false);
          return devices;
        } else {
          console.error('No devices found in the response');
          setLoading(false);
          return [];
        }
      } catch (error) {
        setLoading(false);
        const errorData = error.response.data;
        setIsToasterOpen(true);
        setReqSuccess(false);
        setToasterMessage(errorData.message);
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 5000);
        console.log('Error fetching devices:', errorData);
        throw new Error(errorData.message || 'An error occurred');
      }
    };

    const selectDevices = async () => {
      setLoading(true);
      const batch = inputValue;
          await axios.post(`${environment.host}/core/user/${Cookies.get('USER_ID')}/connect-device/${connectDeviceId}/devices/${batch}`, selectedDeviceFinal, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          }
        })
        .then((response) => {
          setSelectedDevice(selectedDeviceFinal);
          setSelectedDeviceFinal([]);
          setStep(step + 1);
          const updatedSteps = steps.map((s, i) => ({
            ...s,
            activate: i <= step + 1,
          }));
          setSteps(updatedSteps);
        })
        .catch((error) => {
          const errorData = error.response.data;
          if (error.response.status === 422) {
            if(errorData.message === 'Batch number already exist') {
              setMessage(errorData.message);
              setIsExistingBatchNumber(true);
            } else {
              setIsToasterOpen(true);
              setReqSuccess(false);
              setToasterMessage(errorData.message);
              setTimeout(() => {
                setIsToasterOpen(false);
              }, 5000);
            }
          }
          if(errorData.errorCode == 'E563'){
            setIsUpgrade(true)
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const handleUpgrade = () =>{
      window.open(
          environment.azurePackageUpgradeLink,
          "_blank" // Specifies to open the link in a new tab
      );
      setIsUpgrade(false)
    }

    const fetchDevices = async (selectedDeviceIds = selectedDevice) => {
      const deviceIdsParam = selectedDeviceIds.join(',');
      const url = `${environment.host}/core/device${deviceIdsParam ? `?deviceIds=${deviceIdsParam}` : ''}`;
      setLoading(true);
  
      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
        const data = response.data;
        setDevices(data.content);
      } catch (error) {
        const errorData = error.response.data;
        throw new Error(errorData.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    const updateType = async (connectDeviceId) => {
      try {
        const response = await axios.put(`${environment.host}/core/connectDevice/${connectDeviceId}`, null, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
          },
        });
    
        console.log(response.data);
      } catch (error) {
        console.error('Error updating Connect Device:', error.message);
        if (axios.isAxiosError(error)) {
          const responseData = error.response && error.response.data;
          if (responseData) {
            console.error('Response data:', responseData);
          } else {
            console.error('No response data available');
          }
        }
      }
    };
    

    const reloadData = async () => {
      await fetchDevices();
    }

    const handleSensorName = (index, newName) => {
      let isDuplicate = false;
    
      setSensorDetails((prev) =>
        prev.map((sensor, i) => {
          if (sensor.codeValue === newName) {
            isDuplicate = true;
          }
          return i === index ? { ...sensor, codeValue: newName } : sensor;
        })
      );
    
      if (isDuplicate) {
        setSensorNameError("Sensor name should be unique");
        return;
      } else {
        setSensorNameError(""); // Clear error if no duplicate
      }
    
      setTimeout(() => {
        inputRefs.current[index].focus();
      }, 0);
    };

    const handleSubmitSensorDetails = async () =>{
      setLoading(true)
      try{
        if(sensorNameError == ""){
          const response = await addSensor(sensorDetails)
          console.log(response)
          setIsShowSensorPopup(false)
          setSensorDetails([])
        }else{
          return
        }
      }catch(error){
        setIsToasterOpen(true)
        setReqSuccess(false)
        setToasterMessage(error.message)
      }finally{
        setLoading(false)
      }
    }

    const SensorDialog = () =>{
      return(
        <Grid container >
          <Grid item xs={12} md={12}>
            <Grid container>
              <Typography style={{marginRight:'25px'}}>Sensor Code</Typography>
              <Typography>Sensor Name</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} style={{marginTop:'10px'}}>
            <Grid container>
              {sensorDetails.map((sensor,index) =>(
                <Grid container key={index} style={{marginBottom:'5px'}}>
                  <Grid item xs={2} md={2} className={classes.sensorCodeChip}>{sensor.code}</Grid>
                  <Grid item xs={10} md={10} className={classes.sensorNameInputField}>
                    <InputBase
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      className={classes.formInput}
                      type="text"
                      placeholder="Add sensor name here"
                      name="baseUrl"
                      value={sensor.codeValue || ""}
                      onChange={(e) =>handleSensorName(index,e.target.value)}
                    />
                  </Grid>
                </Grid>
              ))}
              {sensorNameError && (
                <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                  <Typography className={classes.errorText}>
                    {sensorNameError}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )
    }


    const increaseStep = async () => {
      if(step === 0){
        const isValid = await validationForm();
        if (!isValid) {
          return;
        } else {
          connectDevices();
        }
      } else if(step === 1){
        const isValid = await validateForm();
        const updatedErrors = {
          ...errors,    // Existing errors
          ...isValid,   // New validation errors
        };
        setErrors(updatedErrors);
        if(Object.values(updatedErrors).some(value => value !== null)){
          return;
        } else {
          if(sensorDetails.length != 0 ){
            setIsShowSensorPopup(true)
          }else{
            selectDevices();
          }
        }
      } else {
        setStep(step + 1);
        const updatedSteps = steps.map((s, i) => ({
          ...s,
          activate: i <= step + 1,
        }));
        setSteps(updatedSteps);
      }
    }

    const cancelSensorPopup = () =>{
      setIsShowSensorPopup(false)
    }

    const decreaseStep = () => {
      if(step === 0){
        history.push('/DataStream');
      } else if(step === 2){
        setIsBack(true);
        const savedInputValue = localStorage.getItem('inputValue');
        const savedSelectedDevices = localStorage.getItem('selectedDevices');

        if (savedInputValue) setInputValue(savedInputValue);
        if (savedSelectedDevices) setSelectedDeviceFinal(JSON.parse(savedSelectedDevices));
        setStep(step - 1);
        const updatedSteps = steps.map((s, i) => ({
          ...s,
          activate: i <= step - 1,
        }));
        setSteps(updatedSteps);
      } else {
        clearStoredData();
        setSelectedDeviceFinal([]);
        setStep(step - 1);
        const updatedSteps = steps.map((s, i) => ({
          ...s,
          activate: i <= step - 1,
        }));
        setSteps(updatedSteps);
      }
    }

    const clearStoredData = () => {
      localStorage.removeItem('inputValue');
      localStorage.removeItem('fetchedDevices');
      localStorage.removeItem('selectedDevices');
    };

    async function onClickDone() {
      try {
        await updateType(connectDeviceId);
        window.dispatchEvent(new CustomEvent("deviceAdded"));
        
        setStep(0);
        setSteps(STEPS);
        clearStoredData();
        
        history.push({
          pathname: '/TestAutomation',
          state: { connectDeviceId }
        });
      } catch (error) {
        console.error('Error during the update process:', error.message);
      }
    }

    function renderStepsComponent() {
      if(step === 1) {
        return <Step2SelectDevices
          loading={loading}
          connectedDevices={connectedDevices}
          isBack={isBack}
          setIsBack={setIsBack}
          connectDeviceId={connectDeviceId}
          selectedDeviceFinal={selectedDeviceFinal}
          setSelectedDeviceFinal={setSelectedDeviceFinal}
          fetchConnectDevices={fetchConnectDevices}
          message={message}
          setMessage={setMessage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          newBatchNumber={newBatchNumber}
          setNewBatchNumber={setNewBatchNumber}
          isExistingBatchNumber={isExistingBatchNumber}
          batchNumber={batchNumber}
          selectedDeviceError={selectedDeviceError}
          setSelectedDeviceError={setSelectedDeviceError} 
          setErrors = {setErrors}
          errors = {errors} 
           />;
      } else if(step === 2) {
          return <Step3DataPreview 
            loading={loading}
            fetchDevices={fetchDevices}
            connectDeviceId={connectDeviceId} 
            devices={devices}
            setDevices={setDevices}
            reloadData={reloadData}
            selectedDevice={selectedDevice}
          />;
      } else {
          return <Step1ConnectDevices 
            formData={formData}
            setFormData={setFormData}
            apiKey={apiKey}
            batchNumber={batchNumber}
            baseUrl={baseUrl}
            setBaseUrl={setBaseUrl}
            setbatchNumber={setBatchNumber}
            setApiKey={setApiKey}
            baseUrlError={baseUrlError}
            batchNumberError={batchNumberError}
            apiKeyError={apiKeyError}
            setBaseUrlError={setBaseUrlError}
            setBatchNumberError={setBatchNumberError}
            setApiKeyError={setApiKeyError} />;
      } 
    }

    const onCloseUpgrade = () =>{
      setIsUpgrade(false)
    }

    return (
        <ThemeProvider theme={theme}>
          <div className={`testing`}>
          <Grid container className={classes.pageTitleContainer}>
            <IconButton onClick={() => clickBack(history, "/DataStream")}>
              <RedirectIcon />
            </IconButton>
            <Grid className={classes.pageTitle} style={{margin:'0px'}}>Senzmatica</Grid>
          </Grid>
            <Grid container
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    paddingRight: "80px"
                }}>
                <Grid item xs={1} md={4}></Grid>
                {steps.map((stepItem, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={2} md={1}>
                          <Grid container direction="column" alignItems="center">
                              <Grid item>
                              <Circle number={index + 1} active={index < step} current={index === step}/>
                              </Grid>
                              <Grid item>
                              <Typography className={classes.circleText}
                                  style={{
                                  color: index <= step ? "#005B96" : "#8F8F8F",
                                  }}
                              >
                                  {stepItem.text}
                              </Typography>
                              </Grid>
                          </Grid>
                        </Grid>
                        {index !== steps.length - 1 && (
                        <Grid item xs={2} md={1}>
                            <hr
                            style={{
                                marginTop: "32px",
                                opacity: index < step ? "1" : "0.12",
                            }}
                            color={index < step ? "#005B96" : "rgba(112, 112, 112, .12)"}
                            />
                        </Grid>
                        )}
                    </React.Fragment>
                ))}
                <Grid item xs={1} md={3}></Grid>
            </Grid>

            <Grid container className={classes.customBoxContainer}>
              <Grid item className={classes.contentContainer} >
                {renderStepsComponent()}
              </Grid>
                  {step === 0 ? (
                    <>
                      <Grid container justifyContent="flex-end">
                            <Grid item className={classes.buttonContainer} style={{marginRight:"20px"}}>
                                <Button onClick={decreaseStep} className={classes.backButton}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid item className={classes.buttonContainer}>
                                <Button onClick={increaseStep} className={classes.devicePulseSaveButton}>
                                    Connect
                                </Button>
                          </Grid>
                      </Grid> 
                    </>
                  ) : step === 1 ? (
                         <>
                           <Grid container justifyContent="flex-end" spacing={1}>
                              <Grid item className={classes.buttonContainer} style={{marginRight:"20px"}}>
                                <Button onClick={decreaseStep} className={classes.backButton}>
                                    Back
                                </Button>
                              </Grid>
                              <Grid item className={classes.buttonContainer}>
                                  <Button onClick={increaseStep} className={classes.devicePulseSaveButton}>
                                      Submit
                                  </Button>
                              </Grid> 
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item className={classes.buttonContainer} style={{marginRight:"20px"}}>
                                  <Button onClick={decreaseStep} className={classes.backButton}>
                                      Back
                                  </Button>
                              </Grid>
                              <Grid item className={classes.buttonContainer}>
                                  <Button onClick={onClickDone} className={classes.devicePulseSaveButton}>
                                      Done
                                  </Button>
                              </Grid>
                            </Grid> 
                    </>
                  )}
            </Grid>
                <Loader loading={loading}/>
                <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
                <DialogPopup 
                  open = {isUpgrade}
                  title = 'Upgrade Azure Package'
                  dialogContent = "Device limit exceeded. Please upgrade your package."
                  buttonRequired = {true}
                  cancel = 'cancel'
                  submit = 'upgrade'
                  onClose = {onCloseUpgrade}
                  onSubmit = {handleUpgrade}
                  submitButtonColor = '#2A7BEC'
                />
                <DialogPopup 
                  open = {isShowSensorPopup}
                  title = 'Sensor Code Labeling'
                  dialogContent = {<SensorDialog />}
                  buttonRequired = {true}
                  cancel = 'cancel'
                  submit = 'Submit'
                  onClose = {cancelSensorPopup}
                  onSubmit = {handleSubmitSensorDetails}
                  submitButtonColor = '#005B96'
                />
          </div>
        </ThemeProvider>
    );
};

const Circle = (props) => {
    const paddedNumber = (props.number).toString().padStart(2, '0');
    return (
      <div>
        <div
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            backgroundColor: props.current ?"white" : props.active ? "#005B96" : "white",
            border: props.current ? "2px solid #005B96" : props.active ? "2px solid #005B96" : "0.25px solid #8F8F8F",
            textAlign: "center",
            paddingTop: "10px",
            boxShadow: "0px 4px 8px #0000001F",
          }}
        >
          <h5 style={{
            paddingTop: "10px",
            fontSize: "16px",
            color: props.current ? '#005B96' : props.active ? "white" : primaryGray
          }}>
            {paddedNumber}
          </h5>
        </div>
      </div>
    );
  };