import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {FormControl, Grid, IconButton, makeStyles, Theme, Tooltip, Typography,} from '@material-ui/core';
import {primaryBlue, white} from 'src/components/colors';
import ReactSwitch from 'react-switch';
import {DropDown} from '../components/DropDown';
import {MappingDropDown} from '../components/MappingDropDown';
import {environment} from 'src/environments/environment';
import {CancelIcon, CloseIcon, TooltipIcon} from 'src/components/Icons';
import axios from 'axios';
import useStyles from "src/app/maintenance/assets/styles";

export function CreateKitModels(props) {
  const classes = localStyle();
  const style = useStyles();
  const [isToggledLps, setIsToggledLps] = useState(false)
  const [isToggledGps, setIsToggledGps] = useState(false)
  const [isToggledBattery, setIsToggledBattery] = useState(false)

  //select values
  const [selectSensor, setSelectSensor] = useState([])
  const [selectProperty, setSelectProperty] = useState([])
  const [selectActuator, setSelectActuator] = useState([])
  const [selectAction, setSelectAction] = useState([])
  const [modelName, setModelName] = useState("")
  const [selectType, setSelectType] = useState("")
  const [sensorNumberList, setSensorNumberList] = useState([])
  const [aggregationList, setAggregationList] = useState([])
  const [operationList, setOperationList] = useState([])
  const propertyCalMethod = ['SUM', 'MIN', 'MAX', 'AVG', 'PREDICT', 'OR', 'AND', 'TIME', 'LATEST', 'ANY'];
  const propertyType = [{index: 'R', value: 'REAL_TIME'}, {index: 'B', value: 'BULK'}]
  const [optionName, setOptionName] = useState("")
  const [selectedMappingSensor, setSelectedMappingSensor] = useState([]);

  const [isSensorAddClick, setSensorIsAddClick] = useState(false);
  const [isPropertyAddClick, setPropertyIsAddClick] = useState(false);
  const [isActuatorAddClick, setActuatorIsAddClick] = useState(false);
  const [isAddOptionClick, setIsAddOptionClick] = useState(false);

  //handel error
  const [errors, setErrors] = useState({
    modelNameError: null,
    actionError: null,
    mapError: null,
    propertyOrActionError: null
  })
  let ope = []


  useEffect(() => {
    if (props.kitModelFormType == 'Edit' && props.selectModel) {
      setModelName(props.selectModel[0].name)
      if (props.selectModel[0].sensors) {
        const sensor = props.selectModel[0].sensors.map(val => {
          return props.allSensors.filter(s => s.code === val)[0];
        });
        setSelectSensor(sensor)
      }

      if (props.selectModel[0].properties) {
        const property = props.selectModel[0].properties.map(val => {
          return props.allSensors.filter(s => s.code === val, 1)[0];
        });
        setSelectProperty(property)
      }

      if (props.selectModel[0].actuators) {
        const actuator = props.selectModel[0].actuators.map(val => {
          return props.allActuators.filter(s => s.code === val, 1)[0];
        });
        setSelectActuator(actuator)
      }

      if (props.selectModel[0].actions) {
        const action = props.selectModel[0].actions.map(val => {
          return props.allActuators.filter(s => s.code === val, 1)[0];
        });
        setSelectAction(action)
      }

      if (props.selectModel[0].lbsEnabled) {
        setIsToggledLps(props.selectModel[0].lbsEnabled)
      }

      if (props.selectModel[0].gpsEnabled) {
        setIsToggledGps(props.selectModel[0].gpsEnabled)
      }

      if (props.selectModel[0].batteryEnabled) {
        setIsToggledBattery(props.selectModel[0].batteryEnabled)
      }

      if (props.selectModel[0].type) {
        setSelectType(props.selectModel[0].type)
      }

      if (props.selectModel[0].operations) {
        let aggregationLists = []
        let operationsList = []
        let sensorList = []

        ope = props.selectModel[0].operations.map((operation, index) => {
          let sensors = []
          operation['sensorNumberList'].map(sensorIndex => {
            const sensorList = `${props.allSensors.filter(s => s.code === props.selectModel[0].sensors[sensorIndex], 1).map(s => s.name)[0]} ${sensorIndex + 1}`
            sensors.push(sensorList)
          });
          sensorList[index] = sensors
          aggregationLists[index] = operation['aggregation']
          operationsList[index] = propertyType.filter(property => property.value.toLowerCase() == operation['type'].toLowerCase())[0].index
        });
        setAggregationList(aggregationLists)
        setOperationList(operationsList)
        setSelectedMappingSensor(sensorList)
      }

    }

  }, []);


  const handleChangeLps = () => {
    setIsToggledLps(!isToggledLps);
  };

  const handleChangeGps = () => {
    setIsToggledGps(!isToggledGps)
  }

  const handleChangeBattery = () => {
    setIsToggledBattery(!isToggledBattery)
  }

  const handleSensorAddClick = () => {
    setSensorIsAddClick(!isSensorAddClick)
  }

  const handlePropertyAddClick = () => {
    setPropertyIsAddClick(!isPropertyAddClick)
  }

  const handleActuatorAddClick = () => {
    setActuatorIsAddClick(!isActuatorAddClick)
  }
  let sensors = []

  //remove sensor from selected sensor array
  const removeSensor = (name) => {
    let filterSensorArray = [...selectSensor];
    let filterPropertyArray = [...selectProperty]
    let sensorArray = filterSensorArray.filter(sensor => sensor.name !== name)
    let propertyArray = filterPropertyArray.filter(property => property.name !== name)
    setSelectSensor(sensorArray);
    setSelectProperty(propertyArray)
  }

  //remove actuator from selected actuator array
  const removeActuator = (index) => {
    let filterActuator = [...selectActuator];
    let actions = [...selectAction]
    let findActuator = selectActuator[index]
    let filterActions = actions.filter((action) => action.name.toLowerCase() !== findActuator.name.toLowerCase())
    filterActuator.splice(index, 1);
    setSelectActuator(filterActuator);
    setSelectAction(filterActions)
  }

  //remove property from selected property array
  const removeProperty = (index) => {
    let filterProperty = [...selectProperty];
    filterProperty.splice(index, 1);
    setSelectProperty(filterProperty);
  }

  //remove action from selected action array
  const removeAction = (index) => {
    let filterAction = [...selectAction];
    filterAction.splice(index, 1);
    setSelectAction(filterAction);
  }


  // Form values
  const handleKitName = (e) => {
    setModelName(e.target.value)
    setErrors(prevErrors => ({
      ...prevErrors,
      modelNameError: null
    }));
  }

  const handleOptionName = (e) => {
    setOptionName(e.target.value)
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      setIsAddOptionClick(false)
      createKitType(e.target.value)
      setSelectType(e.target.value)
      console.log(e.target.value)
    }
  }

  const getOperation = () => {
    selectProperty.map((property, index) => {
      ope[index] = {
        aggregation: aggregationList[index] == undefined ? propertyCalMethod[0] : aggregationList[index],
        sensorNumberList: sensorNumberList[index] == undefined ? [] : sensorNumberList[index],
        propertyNumber: getPropertyNumber(property, index),
        type: operationList[index] == undefined ? propertyType.map(type => type.value)[0].toString() : propertyType.filter(type => type.index == operationList[index]).map(t => t.value).toString(),
      }
    })
  }


  const createKitModel = () => {
    getOperation()
    const validationError = validationForm()
    console.log(validationError)
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }
    let type = selectType == "" ? props.allTypes.map(a => a.name)[0] : props.allTypes.filter(type => type.value == selectType).map(type => type.name)[0]
    const kitModel = {
      name: modelName,
      sensors: selectSensor.map(s => s.code),
      noOfSensors: selectSensor.length,
      properties: selectProperty.map(s => s.code),
      noOfProperties: selectProperty.length,
      actuators: selectActuator.map(a => a.code),
      noOfActuators: selectActuator.length,
      actions: selectAction.map(a => a.code),
      noOfActions: selectAction.length,
      gpsEnabled: isToggledGps,
      lbsEnabled: isToggledLps,
      batteryEnabled: isToggledBattery,
      operations: ope,
      type: type
    }
    props.setLoading(true)
    axios.post(`${environment.host}/core/kit-model`, kitModel,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        },
      })
      .then((response) => {
        console.log(response.data)
        if (response.data.message === 'Success') {
          props.setIsToasterOpen(true)
          props.setToasterMessage("Kit Model Created Successfully")
          props.setReqSuccess(true)
          setTimeout(() => {
            props.setIsToasterOpen(false);
          }, 4000);
          props.getKitModels()
          props.increaseStep()
          props.setLastCreatedKitModel(response.data.content.name)
          props.setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        props.setLoading(false)
        props.setIsToasterOpen(true)
        props.setToasterMessage("Something went wrong")
        props.setReqSuccess(false)
        setTimeout(() => {
          props.setIsToasterOpen(false);
        }, 4000);
      })
  }

  const validationForm = () => {
    let error: any = {}
    let kitModelNames = props.allKitModelsName.map(s => s.name)
    if (modelName == "") {
      error.modelNameError = "Kit Model Name is Required"
    } else if (kitModelNames.includes(modelName)) {
      error.modelNameError = "Kit Model Name Already Exists"
    }

    if (selectProperty.length == 0 && selectAction.length == 0) {
      error.propertyOrActionError = "A property or Action should be added"
    }

    if (selectActuator.length != 0 && selectAction.length == 0) {
      error.actionError = "An Action should be added"
    }

    selectProperty.map((property, index) => {
      if (sensorNumberList[index] == undefined) {
        error.mapError = "Map All Properties"
      }
    })

    return error

  }

  const handleError = (name) => {
    console.log(name)
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  }

  const createKitType = (value) => {
    const kitType = {
      value: value
    }
    axios.post(`${environment.host}/core/kitType`, kitType,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        },
      })
      .then((response) => {
        if (response.data.message == 'Success') {
          console.log(response.data.content)
          props.getKitType()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSensorsByCode = (code: string) => {
    return props.allSensors.filter(s => s.code === code, 1)[0];
  }

  const getPropertyNumber = (property, index) => {
    return property.name.includes('Battery Level') ? -11 : (property.name.includes('Battery') ? -10 : index);
  }

  const getProperties = () => {
    const properties = [];
    // let sensor = selectSensor.map(s => s.name)
    selectSensor.forEach((val) => {
        if (!properties.includes(val)) {
          if ((val.code === 'MEA' || val.code === 'MEA0' || val.code === 'MEA1' || val.code === 'MEA2' || val.code === 'MEA3' || val.code === 'MEA4')
            && !properties.includes(getSensorsByCode('M'))) {
            properties.push(getSensorsByCode('M'));
            properties.push(getSensorsByCode('CN'));
          } else if ((val.code === 'PHA') && !properties.includes(getSensorsByCode('PH'))) {
            properties.push(getSensorsByCode('PH'));
          } else {
            properties.push(val);
          }
        }
      }
    );
    return properties
  }

  const handleCancelButton = () => {
    const type = props.kitModelFormType
    const step3 = props.isSelectStep3;
    const isSelectKitModel = props.isSelectKitModel
    console.log("step3 :", step3)
    if (type == 'Edit') {
      props.setKitModelFormType('Add')
      props.setIsEyeIconClick(prevState => ({
        ...prevState,
        kitModel: false
      }))
    } else {
      if (step3 == false) {
        props.setIsSelectStep3(true)
        props.decreaseStep()
      } else {
        props.setIsApiClick(false)
      }

    }
  }

  const handleSensorValue = (e) => {
    const sensor = props.allSensors.find(s => s.name === e);
    console.log(sensor)
    setSelectSensor(prevSensors => [
      ...prevSensors,
      {name: sensor.name, code: sensor.code}
    ])
  }

  const handlePropertyValue = (e) => {
    const sensor = props.allSensors.find(s => s.name === e);
    console.log(sensor)
    setSelectProperty(prevSensors => [
      ...prevSensors,
      {name: sensor.name, code: sensor.code}
    ])
    handleError('propertyOrActionError')
  }

  const handleActuatorValue = (e) => {
    let actuator = props.allActuators.find(a => a.name == e)
    console.log(actuator)
    setSelectActuator(prevActuator => [
      ...prevActuator,
      {name: actuator.name, code: actuator.code}
    ])
  }

  const handleActionsValue = (e) => {
    let action = props.allActuators.find(a => a.name == e)
    setSelectAction(prevAction => [
      ...prevAction,
      {name: action.name, code: action.code}
    ])
    handleError('propertyOrActionError')
  }


  const handleOptionValue = (e) => {
    console.log(e)
    if (e == "" && props.allTypes[0]) {

      setSelectType(props.allTypes[0].name)
    } else {
      setSelectType(e)
    }
  }


  return (
    <div className={`testing`} style={{margin: '0', paddingTop: props.kitModelFormType == 'Edit' ? "20px" : "50px"}}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Name
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              <FormControl className={classes.input}>
                <input
                  className={style.formInput}
                  type="text"
                  placeholder="Enter Kit Model Name"
                  onChange={(e) => handleKitName(e)}
                  value={modelName}
                />
              </FormControl>
            </Grid>
          </Grid>
          {errors.modelNameError ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={12} md={props.kitModelFormType == 'Edit' ? 12 : 11}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={style.errorText}>{errors.modelNameError}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }

        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Sensors
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              {!isSensorAddClick ?
                <FormControl className={style.input}>
                  <Grid container>
                    {props.kitModelFormType != 'Edit' && selectSensor.length == 0 ?
                      <Grid container>
                        <input
                          className={style.formInput}
                          type="text"
                          placeholder={selectSensor.length != 0 ? "" : "No Sensor Added"}
                          style={{height: '80px', marginBottom: '10px', width: '100%', textAlign: 'center'}}
                          // value={sensorValue}
                          disabled={true}
                        />
                      </Grid>
                      :
                      <Grid container style={{
                        border: '0.5px solid #55555580',
                        borderRadius: '5px',
                        height: '80px',
                        padding: '10px',
                        overflowY: 'auto',
                        marginBottom: '10px'
                      }}>
                        {selectSensor.map((sensor, index) => {
                          return (
                            <Grid container key={index} style={{
                              border: '0.5px solid #55555580',
                              width: 'auto',
                              borderRadius: '5px',
                              padding: '2px 6px',
                              alignItems: 'center',
                              margin: '0 5px 2px 0',
                              height: '30px'
                            }}>
                              <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                              <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                              <Grid item style={{padding: '2px 5px'}}>{sensor.name}</Grid>
                              {props.kitModelFormType != 'Edit' ?
                                <Grid item onClick={() => removeSensor(sensor.name)}><CloseIcon/></Grid>
                                :
                                <></>

                              }

                            </Grid>
                          )
                        })
                        }
                      </Grid>
                    }
                  </Grid>
                  {props.kitModelFormType !== 'Edit' && props.allSensors ?
                    <DropDown
                      options={props.allSensors.map(s => s.name)}
                      type='Sensors'
                      emptyTag='Add Sensors'
                      isAddRequired={true}
                      setIsAddClick={setSensorIsAddClick}
                      setSelectSensor={setSelectSensor}
                      selectSensor={selectSensor}
                      isSingle={true}
                      kitModelFormType={props.kitModelFormType}
                      allSensors={props.allSensors}
                      onClick = {(event) => handleSensorValue(event)}
                    />
                    :
                    <></>}

                </FormControl>
                :
                <Grid container style={{
                  backgroundColor: '#E6FFE5',
                  color: '#324054',
                  paddingTop: '10px',
                  textAlign: 'center',
                  border: '0.5px solid #3D9840',
                  borderRadius: '5px'
                }} alignItems='center'>
                  <Grid container>
                    <Grid item xs={10} md={11}></Grid>
                    <Grid item onClick={() => handleSensorAddClick()} style={{cursor: 'pointer'}}>
                      <CancelIcon/>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={10} md={12}
                          style={{textAlign: 'center', fontWeight: 500, fontSize: 16, paddingBottom: '20px'}}>
                      <b>Need to add a sensors?</b><br/>
                      Contact the admin for assistance!
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Property
                <span className={classes.required}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              {!isPropertyAddClick ?
                <FormControl className={style.input}>
                  <Grid container>
                    {selectProperty.length == 0 ?
                      <Grid container>
                        <input
                          className={style.formInput}
                          type="text"
                          placeholder={selectProperty.length != 0 ? "" : "No Property Added"}
                          style={{height: '80px', marginBottom: '10px', width: '100%', textAlign: 'center'}}
                          // value={sensorValue}
                          disabled={true}
                        />
                      </Grid>
                      :
                      <Grid container style={{
                        border: '0.5px solid #55555580',
                        borderRadius: '5px',
                        height: '80px',
                        padding: '10px',
                        overflowY: 'auto',
                        marginBottom: '10px'
                      }}>
                        {selectProperty.map((property, index) => {
                          return (
                            <Grid container key={index} style={{
                              border: '0.5px solid #55555580',
                              width: 'auto',
                              borderRadius: '5px',
                              padding: '2px 6px',
                              alignItems: 'center',
                              margin: '0 5px 2px 0',
                              height: '30px'
                            }}>
                              <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                              <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                              <Grid item style={{padding: '2px 5px'}}>{property.name}</Grid>
                              {props.kitModelFormType != 'Edit' ?
                                <Grid item onClick={() => removeProperty(index)}><CloseIcon/></Grid>
                                :
                                <></>
                              }

                            </Grid>
                          )
                        })
                        }
                      </Grid>
                    }
                  </Grid>
                  {props.kitModelFormType !== 'Edit' && props.allSensors ?
                    <DropDown
                      options={getProperties().map(p => p.name)}
                      type='Property'
                      emptyTag='Add Property'
                      isAddRequired={true}
                      setIsAddClick={setPropertyIsAddClick}
                      setSelectProperty={setSelectProperty}
                      selectProperty={selectProperty}
                      isSingle={true}
                      kitModelFormType={props.kitModelFormType}
                      allSensors={props.allSensors}
                      handleError={handleError}
                      onClick = {(event) => handlePropertyValue(event)}
                    />
                    :
                    <></>

                  }

                </FormControl>
                :
                <Grid container style={{
                  backgroundColor: '#E6FFE5',
                  color: '#324054',
                  paddingTop: '10px',
                  textAlign: 'center',
                  border: '0.5px solid #55555580',
                  borderRadius: '5px'
                }} alignItems='center'>
                  <Grid container>
                    <Grid item xs={10} md={11}></Grid>
                    <Grid item onClick={() => handlePropertyAddClick()} style={{cursor: 'pointer'}}>
                      <CancelIcon/>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={10} md={12}
                          style={{textAlign: 'center', fontWeight: 500, fontSize: 16, paddingBottom: '20px'}}>
                      <b>Need to add a property?</b><br/>
                      Contact the admin for assistance!
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          {errors.propertyOrActionError ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={12} md={props.kitModelFormType == 'Edit' ? 12 : 11}>
                <Grid container justifyContent='flex-end'>
                  <Typography className={style.errorText}>{errors.propertyOrActionError}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>
      {selectProperty.length != 0 ?
        <Grid container spacing={5}>
          <Grid item xs={5} md={12}>
            <Grid container>
              <Grid item xs={1} md={2}>
                <Typography className={classes.text}>
                  Mapping
                  <span className={classes.required}>*</span>
                  <Tooltip
                    arrow
                    title={
                      <React.Fragment>
                        <Grid container>
                          <Grid item xs={12} md={10}>
                            <Typography
                              style={{fontSize: "12px", color: '#FFFFFF', fontWeight: 300, fontFamily: 'poppins'}}
                            >
                              Mapping empowers you to perform dynamic calculations on multiple sensors of the same type
                              within this kit. It allows for the application of mathematical
                              operations such as SUM, MIN, MAX, and others to derive aggregate insights from sensor data
                              of the same type.
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    interactive
                    placement="right"
                  >
                    <IconButton style={{paddingLeft: "10px"}}>
                      <TooltipIcon/>
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4} md={1}></Grid>
              <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>

                <FormControl className={classes.input}>
                  <Grid container>
                    {selectProperty.map((property, index) => {
                      return (
                        <Grid container key={index} className={style.formInput}
                              style={{borderRadius: '5px', minHeight: '55px', marginBottom: '10px', maxHeight: '75px'}}>
                          <Grid item style={{padding: '5px'}}>
                            <Typography>{index + 1}</Typography>
                          </Grid>
                          <Grid item style={{borderLeft: '0.5px solid #2A7BEC', maxHeight: '60px'}}></Grid>
                          <Grid item xs={2} md={3} style={{paddingLeft: '6px', textAlign: 'left', paddingTop: '5px'}}>
                            <Typography>{property.name} {index + 1}</Typography>
                          </Grid>
                          <Grid item xs={6} md={8}>
                            <Grid container justifyContent='flex-end'>
                              <Grid item xs={5} md={7} style={{cursor: 'pointer'}}>
                                <MappingDropDown
                                  options={selectSensor.map(s => s.name)}
                                  className={classes.mappingDropDown}
                                  setSensorNumberList={setSensorNumberList}
                                  sensorNumberList={sensorNumberList}
                                  type='selectSensor'
                                  index={index}
                                  setSelectedSensor={setSelectedMappingSensor}
                                  selectedSensor={selectedMappingSensor}
                                  kitModelFormType={props.kitModelFormType}
                                  handleError={handleError}
                                />
                              </Grid>

                              <Grid item xs={3} md={3} style={{paddingLeft: '20px'}}>
                                <FormControl className={classes.input}>
                                  <MappingDropDown
                                    options={propertyCalMethod}
                                    className={classes.mappingDropDown}
                                    setSensorNumberList={setSensorNumberList}
                                    sensorNumberList={sensorNumberList}
                                    type='aggregation'
                                    inputStyle={{width: '70px'}}
                                    placeholder='SUM'
                                    index={index}
                                    setAggregationList={setAggregationList}
                                    aggregationList={aggregationList}
                                    kitModelFormType={props.kitModelFormType}
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xs={3} md={1} style={{marginLeft: '5px', textAlign: 'left'}}>
                                <MappingDropDown
                                  options={propertyType.map(type => type.index)}
                                  className={classes.mappingDropDown}
                                  setSensorNumberList={setSensorNumberList}
                                  sensorNumberList={sensorNumberList}
                                  type='type'
                                  inputStyle={{width: '10px'}}
                                  placeholder='R'
                                  style={{paddingLeft: '3px', paddingRight: '0px'}}
                                  index={index}
                                  setOperationList={setOperationList}
                                  operationList={operationList}
                                  kitModelFormType={props.kitModelFormType}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                    }
                  </Grid>
                </FormControl>

              </Grid>
              {errors.mapError ?
                <Grid container>
                  <Grid item xs={5} md={3}></Grid>
                  <Grid item xs={12} md={props.kitModelFormType == 'Edit' ? 12 : 11}>
                    <Grid container justifyContent='flex-end'>
                      <Typography className={style.errorText}>{errors.mapError}</Typography>
                    </Grid>

                  </Grid>
                </Grid>
                :
                <></>
              }
            </Grid>
          </Grid>
        </Grid>
        : <></>
      }

      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Actuator
              </Typography>
            </Grid>
            <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              {!isActuatorAddClick ?
                <FormControl className={classes.input}>
                  <Grid container>
                    {selectActuator.length == 0 ?
                      <Grid container>
                        <input
                          className={style.formInput}
                          type="text"
                          placeholder={sensors.length != 0 ? "" : "No Actuator Added"}
                          style={{height: '80px', marginBottom: '10px', width: '100%', textAlign: 'center'}}
                          // value={sensorValue}
                          disabled={true}
                        />
                      </Grid>
                      :
                      <Grid container style={{
                        border: '0.5px solid #55555580',
                        borderRadius: '5px',
                        height: '80px',
                        padding: '10px',
                        overflowY: 'auto',
                        marginBottom: '10px'
                      }}>
                        {selectActuator.map((actuator, index) => {
                          return (
                            <Grid container key={index} style={{
                              border: '0.5px solid #55555580',
                              width: 'auto',
                              borderRadius: '5px',
                              padding: '2px 6px',
                              alignItems: 'center',
                              margin: '0 5px 2px 0',
                              height: '30px'
                            }}>
                              <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                              <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                              <Grid item style={{padding: '2px 5px'}}>{actuator.name}</Grid>
                              {props.kitModelFormType != 'Edit' ?
                                <Grid item onClick={() => removeActuator(index)}><CloseIcon/></Grid>
                                :
                                <></>

                              }

                            </Grid>
                          )
                        })
                        }
                      </Grid>
                    }
                  </Grid>
                  {props.kitModelFormType != 'Edit' && props.allActuators ?
                    <DropDown
                      options={props.allActuators.map(s => s.name)}
                      type='Actuator'
                      emptyTag='Add Actuator'
                      isAddRequired={true}
                      setIsAddClick={setActuatorIsAddClick}
                      selectActuator={selectActuator}
                      setSelectActuator={setSelectActuator}
                      isSingle={true}
                      kitModelFormType={props.kitModelFormType}
                      allActuators={props.allActuators}
                      onClick = {(event) => handleActuatorValue(event)}
                    />
                    :
                    <></>
                  }

                </FormControl>
                :
                <Grid container style={{
                  backgroundColor: '#E6FFE5',
                  color: '#324054',
                  paddingTop: '10px',
                  textAlign: 'center',
                  border: '0.5px solid #3D9840',
                  borderRadius: '5px'
                }} alignItems='center'>
                  <Grid container>
                    <Grid item xs={10} md={11}></Grid>
                    <Grid item onClick={() => handleActuatorAddClick()} style={{cursor: 'pointer'}}>
                      <CancelIcon/>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={10} md={12}
                          style={{textAlign: 'center', fontWeight: 500, fontSize: 16, paddingBottom: '20px'}}>
                      <b>Need to add a actuator?</b><br/>
                      Contact the admin for assistance!
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Action
              </Typography>
            </Grid>
            <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              <FormControl className={classes.input}>
                <Grid container>
                  {selectAction.length == 0 ?
                    <Grid container>
                      <input
                        className={style.formInput}
                        type="text"
                        placeholder={sensors.length != 0 ? "" : "No Action Added"}
                        style={{height: '80px', marginBottom: '10px', width: '100%', textAlign: 'center'}}
                        // value={sensorValue}
                        disabled={true}
                      />
                    </Grid>
                    :
                    <Grid container style={{
                      border: '0.5px solid #5E5C5C',
                      height: '80px',
                      padding: '10px',
                      overflowY: 'auto',
                      borderRadius: '5px',
                      marginBottom: '10px'
                    }}>
                      {selectAction.map((action, index) => {
                        return (
                          <Grid container key={index} style={{
                            border: '0.5px solid',
                            width: 'auto',
                            borderRadius: '5px',
                            padding: '2px 6px',
                            alignItems: 'center',
                            margin: '0 5px 2px 0',
                            height: '30px'
                          }}>
                            <Grid item style={{padding: '2px 5px'}}>{index + 1}</Grid>
                            <Grid item style={{borderLeft: '0.5px solid #2A7BEC', height: '28px'}}></Grid>
                            <Grid item style={{padding: '2px 5px'}}>{action.name}</Grid>
                            {props.kitModelFormType != 'Edit' ?
                              <Grid item onClick={() => removeAction(index)}><CloseIcon/></Grid>
                              :
                              <></>
                            }

                          </Grid>
                        )
                      })
                      }
                    </Grid>
                  }
                </Grid>
                {props.kitModelFormType != 'Edit' && props.allActuators?
                  <DropDown
                    options={selectActuator.map(actuator => actuator.name)}
                    type='Actions'
                    emptyTag='Add Actions'
                    isAddRequired={false}
                    setSelectAction={setSelectAction}
                    selectAction={selectAction}
                    isSingle={true}
                    allActuators={props.allActuators}
                    onClick = {(event) => handleActionsValue(event)}
                  />
                  :
                  <></>

                }

              </FormControl>
            </Grid>
          </Grid>
          {errors.propertyOrActionError || errors.actionError ?
            <Grid container>
              <Grid item xs={5} md={3}></Grid>
              <Grid item xs={12} md={props.kitModelFormType == 'Edit' ? 12 : 11}>
                <Grid container justifyContent='flex-end'>
                  <Typography
                    className={style.errorText}>{errors.propertyOrActionError ? errors.propertyOrActionError : errors.actionError}</Typography>
                </Grid>

              </Grid>
            </Grid>
            :
            <></>
          }
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{height: '80px'}}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                Options
              </Typography>
            </Grid>
            <Grid item xs={8} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              <FormControl className={classes.input}>
                {isAddOptionClick == false && props.allTypes?
                  <DropDown
                    options={props.allTypes.map(a => a.name)}
                    type='option'
                    emptyTag='Add Option'
                    placeholder = {props.allTypes[0].name}
                    isAddRequired={true}
                    setSelectOption={setSelectType}
                    selectOption={setSelectType}
                    isSingle={true}
                    value={selectType}
                    setIsAddClick={setIsAddOptionClick}
                    kitModelFormType={props.kitModelFormType}
                    onClick = {(event) => handleOptionValue(event)}
                  />
                  :
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                      <Grid container>
                        <input className={style.formInput}
                               style={{width: '100%'}}
                               type="text"
                               placeholder='Enter New Option'
                               onChange={(e) => handleOptionName(e)}
                               onKeyDown={(e) => keyPress(e)}
                        />
                      </Grid>
                      <Grid container justifyContent='flex-end'>
                        <Grid item style={{
                          border: '0.5px solid #555555',
                          borderRadius: '5px',
                          padding: '5px 30px',
                          marginTop: '10px',
                          cursor: 'pointer'
                        }} onClick={() => setIsAddOptionClick(false)}>
                          Cancel
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                }

              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                LPS
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}>
              <ReactSwitch
                checked={isToggledLps}
                onChange={() => handleChangeLps()}
                offColor='#D9D9D9'
                onColor='#2A7CED'
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={40}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                GPS
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}>
              <ReactSwitch
                checked={isToggledGps}
                onChange={() => handleChangeGps()}
                offColor='#D9D9D9'
                onColor='#2A7CED'
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={40}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography className={classes.text}>
                battery
              </Typography>
            </Grid>
            <Grid item xs={4} md={1}>
              <ReactSwitch
                checked={isToggledBattery}
                onChange={() => handleChangeBattery()}
                offColor='#D9D9D9'
                onColor='#2A7CED'
                uncheckedIcon={false}
                checkedIcon={false}
                style={{margin: '0'}}
                height={20}
                width={40}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{marginBottom: props.kitModelFormType == 'Edit' ? '10px' : '0px'}}>
        <Grid item xs={5} md={12}>
          <Grid container>
            <Grid item xs={5} md={3}></Grid>
            <Grid item xs={4} md={props.kitModelFormType == 'Edit' ? 9 : 8}>
              <Grid container justifyContent='flex-end'>
                <Grid item style={{
                  border: '0.5px solid #555555',
                  borderRadius: '5px',
                  padding: '5px 30px',
                  marginRight: '10px',
                  cursor: 'pointer'
                }} onClick={() => handleCancelButton()}>

                  Cancel
                </Grid>
                {props.kitModelFormType != 'Edit' ?
                  <Grid item style={{
                    borderRadius: '5px',
                    padding: '5px 30px',
                    color: white,
                    backgroundColor: primaryBlue,
                    cursor: 'pointer'
                  }} onClick={() => createKitModel()}>Create Model</Grid>

                  :
                  <></>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </div>
  )
}


const localStyle = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  text: {
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif",
    color: '#5E5C5C'
  },
  required: {
    color: "red",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },
  formInput: {
    height: "auto",
    borderRadius: "5px",
    padding: "10px 15px",
    fontFamily: "'Poppins', sans-serif",
    border: '0.5px solid #5E5C5C'
  },
  toggleButton: {
    padding: '10px 20px',
    backgroundColor: '#dddddd',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    borderRadius: '50%',
    width: '100px'
  },

  toggleButtonOn: {
    backgroundColor: primaryBlue,
    color: white
  },

  toggleButtonOff: {
    backgroundColor: '#D9D9D9',
    color: white
  },

  option: {
    paddingLeft: '10px',
    height: '33px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  mappingDropDown: {
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white
    }
  },
  inputField: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'white',
    fontFamily: 'poppins'
  },
  inputContainer: {
    border: '0.5px solid',
    borderRadius: '5px',
    minHeight: '31px',
    padding: '2px 5px 0px 5px',
    maxHeight: '100px',
    cursor: 'pointer',
    backgroundColor: 'white'
  }
}));















