import axios from "axios";
import {environment} from "src/environments/environment";
import Cookies from "js-cookie";
//get All batches
export const getAllBatches = async () => {
  return axios.get(`${environment.host}/core/user/${Cookies.get('USER_ID')}/deviceTest/batchNumbers`, {
    headers: {"Content-Type": "application/json", Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")},
  })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      return;
    });
};

//get Main Test cases
export const getAllMainTestCases = async (batchNo) => {
  return axios.get(
    `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
    {
      headers: {"Content-Type": "application/json", Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),},
    }
  )
    .then((response) => {
      let mainTestCases = [];
      response.data.content.map((cases) => {
        mainTestCases.push(cases.mainTestTitle);
        return mainTestCases;
      });
      return {mainTestCases: mainTestCases, data: response.data.content} as any;
    })
    .catch((err) => {
      return;
    });
};

export const getAllDefaultMainTestCases = async () => {
  const batchNo = 0;
  return axios.get(
    `${environment.host}/core/deviceTest/testcase/batch/${batchNo}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
      },
    }
  )
    .then((response) => {
      let mainTestCases = [];
      response.data.content.map((cases) => {
        mainTestCases.push(cases.mainTestTitle);
        return mainTestCases;
      });
      return {mainTestCases: mainTestCases, data: response.data.content} as any;
    })
    .catch((err) => {
      return;
    });
};


//get device test summary
export const getDeviceTestSummery = async (batchNo, mainTitle) => {
  return axios.get(`${environment.host}/core/deviceTest/testReportTable/${batchNo}?testCaseTitle=${mainTitle}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
    }
  })
    .then((res) => {
      const filteredData = removeEmptyObjects(res.data.content);
      return filteredData
    }).catch((err) => {
      return err.res.data
    })
};

//send parameter config values
export const sendParameter = (testId, data) => {
  return axios.put(`${environment.host}/core/deviceTest/setupParameter/${testId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
      }
    }
  ).then((res) => {
    return res.data;
  }).catch((err) => {
    return err.response.data;
  });
};

export const getAllDevices = async () => {
  return axios.get(`${environment.host}/core/device`, {
    headers: {"Content-Type": "application/json", Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")},
  })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      return;
    });
};

export const getOneSubCase = (batch, subId) => {
  return axios.get(
    `${environment.host}/core/deviceTest/subTestcase/batch/${batch}/${subId}`,
    {
      headers: {"Content-Type": "application/json", Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")},
    }
  )
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      return;
    });
};

export const submitDeviceConfig = (
  batch,
  mainTest,
  startDate,
  endDate,
  subTestCaseIds,
  devicesData
) => {
  const data = {
    "batchNumber": batch,
    "mainTestTitle": mainTest,
    "startDate": startDate,
    "endDate": endDate,
    "subTestCasesIdList": subTestCaseIds,
    "devices": devicesData
  }
  return axios.post(`${environment.host}/core/deviceTest/setupTesting`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")
    }
  }).then((res) => {
    return res.data
  }).catch((err) => {
    return err.res.data
  })
};

export const getTestCondition = () => {
  return axios.get(`${environment.host}/core/deviceTest/testConditions`, {
    headers: {"Content-Type": "application/json", Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN")},
  })
    .then((response) => {
      return response.data.content;
    })
    .catch((err) => {
      return;
    });
};

export const formatDateString = (inputDate) => {
  const date = new Date(inputDate);

  const padZero = (num) => num.toString().padStart(2, '0');

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

function removeEmptyObjects(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = removeEmptyObjects(obj[key]);
      if (typeof obj[key] === 'object' && obj[key] !== null && Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }

  return obj;
}
