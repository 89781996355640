import React from "react";
import { Close } from '@material-ui/icons';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import useStyles from "../../maintenance/assets/styles";
import { CircleIcon } from "src/components/Icons";
import { getBackgroundColor, getColor } from "src/app/utils";

export const ViewStatusPopUp =({open, handleWindowClose, statusData}) => {
    const classes = useStyles();
    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>{"View Status".toUpperCase()}</span>
                    <IconButton
                        onClick={() => {
                            handleWindowClose();
                        }}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Table className={classes.tableContainer}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={classes.tableHeader}>Ticket No</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Description</TableCell>
                            <TableCell align="left" className={classes.tableHeader}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statusData.length > 0 ? (
                            statusData.map((item, index) => (
                                <TableRow key={index} style={{backgroundColor: index % 2 == 0 ? '#F5F5F5':'#FFFFFF'}}>
                                    <TableCell>
                                        {item.jiraKey}
                                    </TableCell>
                                    <TableCell>
                                        {item.subTestcase}
                                    </TableCell>
                                    <TableCell>
                                        <Box className={classes.viewStatus} style={{color: getColor(item.status), backgroundColor: getBackgroundColor(item.status)}}>
                                            {item.status}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No JIRA issues found for this device
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>

    )
}