import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginResponse, SECTIONS} from '../models';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected http: HttpClient) {
  }


  loginWithUserName(user, rememberMe: boolean) {

    let body = 'client_id=' + user.client_id
      + '&client_secret=' + user.client_secret
      + '&grant_type=' + user.grant_type
      + '&username=' + user.username
      + '&name=' + user.name
      + '&password=' + user.password;
    // Add the "Remember Me" information to the request payload
    if (rememberMe) {
      body += '&remember_me=' + rememberMe;
    }
    localStorage.setItem('USERNAME', user.username);
    console.log(body.toString());
    return this.http.post<LoginResponse>(environment.host + '/oauth/token',
      body,
      {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        })
      });
  }

  static loadCredential(credential) {
    localStorage.setItem('ACCESS_TOKEN', credential.access_token);
    localStorage.setItem('ACCOUNT', credential.references[0]);
    localStorage.setItem('REFRESH_TOKEN', credential.refresh_token);
    localStorage.setItem('ROLE', credential.role);
    localStorage.setItem('USER_ID', credential.id);
    localStorage.setItem('NAME', credential.name)
  }

  refreshToken() {

    const body: URLSearchParams = new URLSearchParams();
    body.set('client_id', 'magma-client');
    body.set('client_secret', 'magma-secret');
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', AuthService.getRefreshToken());

    return this.http.post<LoginResponse>(environment.host + '/oauth/token', body.toString(), {
      headers: new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      })
    });
  }

  static getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN');
  }

  static getAccount() {
    return localStorage.getItem('ACCOUNT');
  }

  static getUsername() {
    return localStorage.getItem('USERNAME');
  }

  static getName() {
    return localStorage.getItem('NAME')
  }

  static getUserId() {
    return localStorage.getItem('USER_ID');
  }

  static getRole() {
    return +localStorage.getItem('ROLE');
  }

  static getAccessToken() {
    return localStorage.getItem('ACCESS_TOKEN');
  }

  static signOut() {
    localStorage.clear();
  }

  static hasProjectAccess() {
    const role = localStorage.getItem('ROLE');
    return !['Client_User'].includes(role);
  }

  static isSuperAdmin() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) === 1 || parseInt(role, 10) === 5;
  }

  static isAccountUser() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) === 10;
  }

  static hasCorporateMenuAccess() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) !== 1000 && parseInt(role, 10) !== 10000;
  }

  static hasUserCreationAccess() {
    const role = localStorage.getItem('ROLE');
    return !['Client_User'].includes(role);
  }

  static hasAccess(section, accessType) {
    const role = localStorage.getItem('ROLE');
    if (section==SECTIONS.D && accessType=="DELETE"){
      return AuthService.isSuperAdmin()
    }
    return parseInt(role, 10) <= 5;
  }

  static hasNewUserAccess(section, accessType) {
    const role = localStorage.getItem('ROLE');
    if (accessType === 'VIEW' || accessType === 'ADD' || accessType === 'CLONE') {
      return true;
    }
  }

  static getUserRoleId():number {
    return parseInt(localStorage.getItem('ROLE'), 10);
  }

  isTestAutomationUser(): boolean {
    const role = AuthService.getUserRoleId();
    return role >= 10 && role < 100;
  }
  
}
