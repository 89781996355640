import { createTheme, ThemeProvider, Grid, Typography, InputBase, Accordion, AccordionSummary, AccordionDetails, Divider, makeStyles } from '@material-ui/core'
import * as React from 'react'
import useStyles from 'src/app/maintenance/assets/styles';
import { ExpandMoreIcon } from 'src/components/Icons';
import { DropDown } from '../components/DropDown';
import { useEffect, useState } from 'react';
import {FormField} from './FormFiled'
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { analyticsConfiguration, fetchBatchNumbers, getKnowledgeBaseDetails, viewChatBotConfig } from '../components/apiHelper';
import Cookies from "js-cookie";

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
});

export const ProjectClientConfigurationsComponent = ({setProjectName,projectName,errors,setErrors,onChangeProjectName,setLoading,setSelectedBatchNumber,selectedBatchNumber}) => {
    const classes = useStyles()
    const [batchNumbers,setBatchNumbers] = useState([])
    const [knowledgeBaseExistBatches,setKnowledgeBaseExistBatches] = useState([])
    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    useEffect(() =>{
        const getBatchNumbers = async () =>{
            setLoading(true)
            try{
                const data ={referenceId : Cookies.get('ACCOUNT')}
                const [batchNumbers,knowledgeBase] = await Promise.all([
                    fetchBatchNumbers(),
                    getKnowledgeBaseDetails(data)
                ]);
                setKnowledgeBaseExistBatches(Object.keys(knowledgeBase))
                const filterBatchNumbers = Object.keys(knowledgeBase).filter(key => knowledgeBase[key].length > 0)
                const batch = batchNumbers.filter(b => !filterBatchNumbers.includes(b))
                setBatchNumbers(batch)
            }catch(error){
                console.log("Error for fetching batch numbers",error)
            }finally{
                setLoading(false)
            }
        }
        getBatchNumbers()
    },[])

    const handleSelectBatchNumber = async (value) =>{
        setErrors(prevError =>({
            ...prevError,
            batchNumberError:null
        }))
        const data = {
            batchNumber : value,
            referenceId : Cookies.get('ACCOUNT')
        }
        setSelectedBatchNumber(value)
        if(knowledgeBaseExistBatches.includes(value)){
            try{
                setLoading(true)
                const configDetails = await viewChatBotConfig(data)
                if(configDetails){
                    setProjectName(configDetails.projectName)
                    setErrors(prevError =>({
                        ...prevError,
                        projectConfig:null
                    }))
                }
            }catch(error){
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(error.message);
                setTimeout(() => {
                    setIsToasterOpen(false);
                }, 2500);
            }finally{
                setLoading(false)
            }
        }else{
            setProjectName('')
        }
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='testing'>
                <Grid container className={`${classes.root} ${classes.formContent}`}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                                    <Typography className={classes.typo}>
                                        Test Batch <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                        </Grid>
                                    <Grid item xs={12} md={12}>
                                    <DropDown
                                        options={batchNumbers ? batchNumbers.map(batch => batch) : []}
                                        type="batchNumber"
                                        emptyTag="Select a batch number"
                                        setSelectOption={handleSelectBatchNumber}
                                        value={selectedBatchNumber}
                                        // onBatchDropClick={onBatchDropClick}
                                        isSingle={true}
                                    />
                                    </Grid>
                                {errors.batchNumberError &&
                                    <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                        <Typography className={classes.errorText}>
                                            {errors.batchNumberError}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                            </Grid>
                            <Grid item xs={1} md={3}></Grid>
                            </Grid>
                            <Grid item xs={12}>
                            <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                    <Typography className={classes.typo}>
                                        Project Name <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                            </Grid>
                                    <Grid item xs={12} md={12}>
                                        <InputBase
                                            className={classes.formInput}
                                            type="text"
                                            placeholder="Enter Project Name Here"           
                                            name="projectName"
                                            value={projectName}
                                            onChange={(event) => onChangeProjectName(event)}
                                        />
                                    </Grid>
                                {errors.projectConfig &&
                                    <Grid item xs={12} style={{ padding: "0px 8px" }}>
                                        <Typography className={classes.errorText}>
                                            {errors.projectConfig}
                                        </Typography>
                                    </Grid>
                                }
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Accordion style={{ marginTop: '10px', padding: '0px',backgroundColor:'rgb(251,251,251)'}} className={classes.accordion}> 
                            <AccordionSummary style={{ padding: '0px' }} expandIcon={<ExpandMoreIcon />}>
                                <Grid container justifyContent='space-between' alignItems='center'>
                                    <Grid item>
                                        <Typography variant="body1">Client Details</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormField
                                            fieldTitle="Name"
                                            inputName="name"
                                            placeholder="Enter Name Here"
                                            isRequired={false}
                                            onChange={(event) =>onChange(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormField
                                            fieldTitle="Email"
                                            inputName="email"
                                            placeholder="Enter Email Here"
                                            isRequired={false}
                                            onChange={(event) =>onChange(event)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormField
                                            fieldTitle="Tel. No"
                                            inputName="telNo"
                                            placeholder="Enter Tel. No Here"
                                            isRequired={false}
                                            onChange={(event) =>onChange(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid> */}
                </Grid>
            </div>
        </ThemeProvider>
    )
}