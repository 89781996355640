import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../custom-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../../account/account.service';
import {SideNavService} from '../side-menu/sidenav/sidenav.service';
import packageJson from 'package.json';
import {InternetConnectionService} from '../../internet-connection/internet-connection.service';
import { isPlanId } from 'src/app/utils';

declare function toggleClass(id, className): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isOnline$ = this.internetConnectionService.isOnline();
  form: FormGroup;
  isSubmitted = false;
  error;
  username;
  version;
  versionBE;
  versionAppBE;
  name;
  notifications = [];
  messages = [];
  rootNotificationId = `notification_${new Date().getTime()}`;
  toastMessage = '';
  isToast = false;
  success = false;
  isPopupOpen = false
  isLoading = false
  isNotificationVisible = false;
  notificationCount: number = 0;
  showPassword = {currentPassword: false, newPassword: false, confirmPassword: false}
  isPlanId = isPlanId;
  isArrowRotated = true;

  constructor(private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private auth: AuthService,
              private toaster: ToastrService, private accountService: AccountService, private sideNavService: SideNavService, private internetConnectionService: InternetConnectionService) {
  }

  ngOnInit() {
    this.name = AuthService.getName();
    this.version = packageJson.version;
    this.form = this.formBuilder.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
      validator: CustomValidators.passwordMatchValidator
    });

    this.accountService.getBEVersion()
      .subscribe(response => {
        this.versionBE=response.content.version
      }, (e) => {
        console.error(e);
      });

    this.accountService.getAppBEVersion()
      .subscribe(response => {
        this.versionAppBE=response.content
      }, (e) => {
        console.error(e);
      });
  }

  updateNotificationCount(count: number) {
    if (this.isNotificationVisible) {
      this.notificationCount = count
    } else {
      this.notificationCount = this.notificationCount + count;
    }

  }

  isAccountUser(): boolean{
    return AuthService.isAccountUser();
  }

  logout() {
    this.auth.signOut();
  }

  changePassword(value: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    this.spinner.show();

    value.username = AuthService.getUsername();
    this.accountService.changePassword(value).subscribe(response => {
      this.spinner.hide();
      toggleClass('changePassword', 'hide');
      this.toaster.success('Password changed successfully', 'Success');
      this.onClearError();
    }, error => {
      console.log(error);
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
    this.form.reset();
  }

  menuStateChange() {
    let state;
    if (localStorage.getItem('MENU_STATE')) {
      state = localStorage.getItem('MENU_STATE');
    } else {
      state = 'OPEN';
    }

    if (state === 'OPEN') {
      localStorage.setItem('MENU_STATE', 'OPEN');
    } else {
      localStorage.setItem('MENU_STATE', 'CLOSE');
    }
  }

  toggleSideNav() {
    this.sideNavService.toggleSideNav();
    this.isArrowRotated = !this.isArrowRotated;
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  toggleNotificationVisibility() {
    this.isNotificationVisible = !this.isNotificationVisible;
  }

  stopEvent(event: Event) {
    event.stopPropagation();
  }

  handleCloseNotification = () => {
    this.isNotificationVisible = false;
  };

  onClearError() {
    this.form.reset();
    this.error = null;
    this.isSubmitted = false;
  };

  isTestAutomationUser(): boolean {
    return this.auth.isTestAutomationUser()
  }

  togglePassword(field: 'newPassword' | 'confirmPassword' | 'currentPassword') {
    this.showPassword[field] = !this.showPassword[field]
  }
}
