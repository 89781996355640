import * as React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import {ViewDecoder} from './connect-devices/view-decoder-details/ViewDecoder';
import {DecodersComponents} from './DecodersComponents';
import {Provider} from 'react-redux';
import store from '../../components/store'

export const RoutLayout = () => {
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <Switch>
            <Route path={"/codec"} component={DecodersComponents}/>
            <Route path="/:codecId/codec" component={ViewDecoder}/>
            <Redirect exact from="/codec" to="/codec"/>
            <Redirect from="/:codecId/codec" to="/codec"/>
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>

  )
}
