import React from "react";
import { primaryBlue, white, primaryGray } from "src/components/colors";


export const Circle = (props) => {
    const paddedNumber = (props.number).toString().padStart(2, '0');
    return (
      <div>
        <div
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            backgroundColor: props.completed ? primaryBlue : white,
            border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
            textAlign: "center",
            paddingTop: "10px",
            boxShadow: "0px 4px 8px #0000001F",
          }}
        >
          <h5 style={{
            paddingTop: "10px",
            fontSize: "16px",
            color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
          }}>
            {paddedNumber}
          </h5>
        </div>
      </div>
    );
};