import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  hash;
  username;
  error;
  isDialog = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, protected router: Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params['hash'];
      this.username = params['uname'];
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    this.form = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    });
  }

  reset(entity: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    entity.key = this.hash;
    entity.username = this.username;
  }
}
