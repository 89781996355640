import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, Button, List, ListItem, Paper, Typography, Grid, InputAdornment, IconButton, InputBase, CircularProgress, makeStyles } from "@material-ui/core";
import { AttachIcon, ChatBotIcon, CollapseIcon, CopilotSendIcon, CopyIcon, Expand, SentIcon, ShareIcon, SoundIcon, User, ViewIcon } from "src/components/Icons";
import { Close } from "@material-ui/icons";
import useStyles from "../../assets/styles";
import { answerChat, commonChat } from "../components/apiHelper";
import { Skeleton } from "@material-ui/lab";
import { LineChart } from "../components/LineChart";
import { Theme } from "react-toastify";
import { AcknowledgementComponent } from "./components/AcknowledgementComponent";
import Cookies from "js-cookie";

export const Chatbot = ({ handleClose,messages,setMessages,parseResponse ,selectedBatch,type,rootCauseId,setGraphDetails,expandGraph,setChatIndex,acknowledgementStatus,setResponseCount,responseCount}) => {
    const [inputMessage, setInputMessage] = useState("");
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const chatEndRef = useRef(null);
    const [isExpand,setIsExpand] = useState(false)
    const [isHover,setIsHover] = useState(false)
    const styles = chatbotStyles({isExpand})
    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);
    const [data,setData] = useState({
            userId:"",
            rootcauseId : "",
            referenceId : "",
            Like : null
    })
    const [isPromptVisible,setIsPropmtVisible] = useState(false)

    useEffect(() =>{
        setData((prevData) =>({
            ...prevData,
            Like:acknowledgementStatus
        }))
    },[acknowledgementStatus])

    useEffect(() =>{
        if(messages && messages.every(message => !message.loading) && responseCount >= 5 && data.Like == null ){
            setIsPropmtVisible(true)
        }
    },[responseCount,data,messages])

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            setMessages([...messages, {text: inputMessage, sender: "user" }]);
            setInputMessage("");
            let questions : any = {
                question: inputMessage.trim(),
                referenceId : Cookies.get('ACCOUNT'),
                userId : Cookies.get('USER_ID')
            };
            if (type === 'copilot') {
                questions.rootCauseId = rootCauseId;
                questions.batchNumber = selectedBatch.toString()
            }else if(localStorage.getItem('COMMON_CHAT_SESSION_ID')){
                questions.sessionId = localStorage.getItem('COMMON_CHAT_SESSION_ID')
            }
            
            setInputMessage('')
            handleResponse(questions);
        }
    };

    const handleResponse = async (question) => {
        setLoading(true)
        setMessages((prev) => {
            const updatedMessages = [
                ...prev,
                { sender: "bot", loading: true }, // Add the new message
            ];// Update the last response index
            return updatedMessages; // Return the updated state
        });
        
        const answer = type === 'copilot' ? await answerChat(question) : await commonChat(question)
        if (answer) {
            setResponseCount((prevCount) => prevCount + 1);
        } else {
            setMessages((prev) => {
                // Check if the last message is still loading
                const updatedMessages = prev.map((message, index) => {
                    if (index === prev.length - 1 && message.loading) {
                        return { ...message, sender: "bot", loading: false };
                    }
                    return message;
                });
                return updatedMessages;
            });
        }
        
        if(answer.sessionId){  
            localStorage.setItem('COMMON_CHAT_SESSION_ID',answer.sessionId)
        }
        if (answer.result) {
            // Replace the loading message with the bot's response
            setMessages(prev =>
                prev.map((msg, index) =>
                    index === prev.length - 1 && msg.loading ? { text: answer.result, sender: "bot" } : msg
                )
            );
        } else if (answer.graph_details) {
                    let scale=""
                    const formatLargeNumber = (num) => {
                        if (num >= 1e9) {
                            scale="Billions"
                            return (num / 1e9).toFixed(3) ; // Billions
                        } else if (num >= 1e6) {
                            scale="Millions"
                            return (num / 1e6).toFixed(3); // Millions
                        } else if (num >= 1e3) {
                            scale="Thousands"
                            return (num / 1e3).toFixed(3); // Thousands
                        }
                        return num.toString(); // Less than 1000, return as-is
                    };
                    const formattedValues = answer.graph_details.values.map((value) =>
                        formatLargeNumber(value)
                    );
                    const graphDetails = {
                        x_label : answer.graph_details.x_label,
                        y_label : `Values (in ${scale})`,
                        title : answer.graph_details.title,
                        values : formattedValues,
                        creation_dates : answer.graph_details.creation_dates
                    }
                    setGraphDetails(graphDetails)
            // Replace the loading message with the bot's image response
            setMessages((prev) =>
                prev.map((msg, index) => {
                    if (index === prev.length - 1 && msg.loading) {
                        setChatIndex(index); // Set the chat index if the condition matches
                        return {
                          image: <Button variant="outlined"
                                    startIcon={<ViewIcon color = '#2A7BEC'/>}
                                    style={{color:'#2A7BEC',borderColor:'#2A7BEC1A',}}
                                    onClick ={() => expandGraph(answer.graph_details.file_path)}>
                                        View Graph
                                </Button>,
                          sender: "bot",
                        };
                    }
                  return msg; // Return the unchanged message otherwise
                })
            );         
        }
        setLoading(false)
    }
    useEffect(() => {
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const IconsComponent = () =>{
        return(
            <Grid container justifyContent="space-around" style={{border: '0.4px solid #D9D9D9',width:'87px',height:'26px',margin:'0px'}}>
                <Grid item >
                    <IconButton size="small">
                        <SoundIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton size="small">
                        <CopyIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton size="small">
                        <ShareIcon />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box
            className={styles.chatbotContainer}
        >
            <Paper className={styles.chatbotHeader}>
                <Grid container justifyContent="space-between">
                    <Grid item style={{display: 'flex'}}>
                        <Grid item style={{ backgroundColor: '#F2F8FF', borderRadius: '50%', padding: '10px', display: 'flex', marginRight: '10px', alignItems: 'center' }} >
                            <ChatBotIcon color='#2A7BEC' size="32" />
                        </Grid>

                        <Typography variant="h6" className={classes.typo} style={{ alignContent: 'center' ,fontWeight:'bold'}}>Device Pulse ChatBot</Typography>
                    </Grid>
                    <Grid item onClick={handleClose} style={{ border: '1px solid #E3E5E5', borderRadius: '50%', padding: '15px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Close />
                    </Grid>
                </Grid>
            </Paper>
            <Paper 
                style={{ padding: "10px", margin: "0px", width: '100%', height: '450px', flexGrow: 1, overflowY: "auto" ,flexDirection:'column',display:'flex' , backgroundColor: type == 'copilot' ? '#F9F8F8' : '#ffffff'}}>
                {messages.map((msg, index) => (
                    <div key={index} style={{margin:'0px'}}>
                        <ListItem 
                            style={{ 
                                justifyContent:
                                    msg.sender === "user" && type == 'chatbot' ? "flex-end" : "flex-start" , 
                                backgroundColor : msg.sender !== 'user' && type == 'copilot' ? '#ffffff' :'none' , 
                                borderRadius : msg.sender !== 'user' && type == 'copilot' ? '12px' :'none' }}
                        >
                            {msg.sender !== "user" && (
                                <Grid
                                    item
                                    style={{
                                        backgroundColor: isHover && msg.img ? 'blue' :  '#F2F8FF',
                                        borderRadius: '50%',
                                        padding: '10px',
                                        display: 'flex',
                                        marginRight: '10px',
                                        alignItems: 'flex-start',
                                        alignSelf: 'flex-start',
                                        transition: 'background-color 0.3s',
                                        opacity : isHover ? 0.5 : 1
                                    }}

                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <ChatBotIcon color='#2A7BEC' size="20" />
                                </Grid>
                            )}
                            {msg.sender == "user" && type == 'copilot'&& (
                                <Grid item>
                                    <User  />
                                </Grid>
                            )}
                            <Grid
                                container
                                direction={msg.sender !== "user" ? 'column' : null}
                                style={{ justifyContent: msg.sender === "user" && type == 'chatbot' ? "flex-end" : "flex-start" }}
                            >
                                <Paper
                                    style={{
                                        padding: "10px",
                                        backgroundColor: 
                                            msg.sender === "user" && type == 'chatbot' ? "#2A7BEC" 
                                            : msg.sender !== "user" && type == 'chatbot' ? "#F2F4F5" 
                                            :msg.sender === "user" && type == 'copilot' ? "transparent" 
                                            : "#ffffff",
                                        color: msg.sender === "user" && type == 'chatbot' ? "#fff" : "#000",
                                        wordWrap: "break-word",
                                        maxWidth: '100%',
                                        margin: '0px',
                                        borderRadius: 
                                            msg.sender !== "user"  && type == 'chatbot' ? '0px 15px 15px 15px' 
                                            :msg.sender === "user" && type == 'chatbot' ? '15px 15px 0px 15px' 
                                            :msg.sender !== "user"  && type == 'copilot' ? 'none' 
                                            : '12px',
                                        fontSize: '12px',
                                        boxShadow : type == 'copilot' ? 'none' : 'inherit',
                                        marginLeft : msg.sender === "user" && type == 'copilot' ? '15px' : '0px',
                                        overflow:'auto',
                                        height:'auto',
                                        cursor : msg.image ? 'pointer' : 'default'
                                    }}
                                    className={classes.typo}
                                >
                                    {msg.loading ? (
                                        // Show Skeleton if message is still loading
                                        <Skeleton variant="text" width={100} height={20} />
                                    ) : (
                                        // Render the message text or image
                                        msg.text ? parseResponse(msg.text) : msg.image
                                    )}
                                </Paper>
                            </Grid>
                        </ListItem>
                        {msg.sender == "bot" && type === 'copilot' && !msg.loading && index == messages.length -1 &&( 
                            <AcknowledgementComponent 
                                rootCauseId = {rootCauseId}
                                data={data}
                                setData={setData} 
                            />
                        )}
                    </div>
                ))}
                {(messages && messages.every(message => !message.loading) && responseCount >= 5 && data.Like == null && isPromptVisible) && (
                    <Grid container className={classes.chatBotPropmt}>
                        <Typography>Is this conversation helpful so far?</Typography>
                        <AcknowledgementComponent 
                            rootCauseId={rootCauseId}
                            data={data}
                            setData={setData}
                        />
                        <IconButton onClick={() =>setIsPropmtVisible(false)}>
                            <Close />
                        </IconButton>
                    </Grid>
                )}
            </Paper>
            <Grid
                container
                style={{
                    boxShadow: "0px -4px 16px 0px #00000014",
                    padding: "13px",
                    background: "var(--bg-footer, #FFFFFF)",
                    alignSelf: "flex-end", // Move alignSelf to sx
                }}
            >
                <TextField
                    fullWidth
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="Type your message here..."
                    variant="filled"
                    className={classes.chatBotInput}
                    InputProps={{
                        classes: {
                            root: classes.chatBotInput,
                            input: classes.chatBotInput
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* <IconButton>
                                    <AttachIcon />
                                </IconButton> */}
                                <IconButton onClick={() => handleSendMessage()}>
                                    <SentIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />
            </Grid>
        </Box>
    );
};

export const EncodedImage = ({ encodedString }) => {
    return (
        <div>
            <img src={`data:image/jpeg;base64,${encodedString}`}
                alt="Encoded"
                style={{ width: '400px', height: '400px' }}
            />
        </div>
    );
};

interface StyleProps {
    isExpand: boolean;
}

export const chatbotStyles = makeStyles<Theme,StyleProps>((theme) => ({
    chatbotContainer :(props) => ( {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f0f0f0",
        minWidth: '1000px',
        width: props.isExpand ? '100vw' : '1000px',
        height: props.isExpand ? '100vh' : 'auto',
        position: props.isExpand ? 'fixed' : 'relative',
        top: props.isExpand ? 0 : 'auto',
        left: props.isExpand ? 0 : 'auto',
        maxHeight : props.isExpand ? '700px' : '610px'
      }),
      chatbotHeader : {
        padding: "10px",
         margin: "0px", 
         width: '100%', 
         flexGrow: 1, 
         overflowY: "hidden"
      }
}))
