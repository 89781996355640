import * as React from "react";
import {useEffect, useState} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {primaryBlue, primaryGray, searchBarBorderColor,} from "src/components/colors";
import {CloseIconColor} from "src/components/Icons";
import { AddnewProductVersion } from "../../maintenance/otamanager/AddnewProductVersion";

export function AddNewFirmWarePopup(props) {

  const closePopUp = () => {
    props.onClose();
  }

  return (
    <Dialog open={props.open}>
      <Grid container>
        <Grid item xs={10} md={10}>
          <DialogTitle>
            <Typography variant="h6" style={{ color: primaryBlue }}>
              Firmware
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() => {
              closePopUp();
            }}
          >
            <CloseIconColor />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <AddnewProductVersion goBack={closePopUp}/>
    </Dialog>
  );
}

