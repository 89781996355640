import {
  Button,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import {useState} from "react";
import {SearchBar} from "src/components/sub-components/SearchBar";
import {primaryBlue, secondaryTextColor, white,} from "src/components/colors";
import {environment} from "src/environments/environment";
import * as _ from "underscore";
import {Alert, Pagination} from "@material-ui/lab";
import {EditIconLarge, FilterIcon, ListIcon, ViewIcon} from "src/components/Icons";
import {timeSince} from "../utils";
import FilterBar from "src/components/sub-components/FilterBar";
import ConfigureDevice from "src/components/sub-components/ConfigureDevice/ConfigureDevice";
import ViewConfiguration from "src/components/sub-components/ViewConfiguration";
import EditConfiguration from "src/components/sub-components/EditConfigureDevice/EditConfiguration";
import HistoryDevice from "src/components/sub-components/HistoryDevice/HistoryDevice";
import axios from "axios";


export function RemoteManagerComponent(props) {
  const classes = useStyles();
  const userId = localStorage.getItem("USER_ID");
  const [devices, setDevices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [allDevices, setAllDevices] = useState([]);

  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);

  //Filter sets
  const [product, setProduct] = useState("ALL");
  const [update, setUpdate] = useState("ALL");
  const [filterOpen, setFilterOpen] = useState(false);
  const [configureOpen, setConfigureOpen] = useState(false);

  //FilterOptions
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);

  React.useEffect(() => {
    //getting states to default values
    setDevices([]);
    getAllConfiguredDevices();
  }, []);

  React.useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    setProjects([]);
    axios.get(`${environment.host}/user/${userId}/project`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content) {
          setProjects(response.data.content);
        } else {
          setProjects([]);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  React.useEffect(() => {
    if (projects.length > 0 && devices.length > 0) {
      let deviceAnnex = [];
      devices.map((device) =>
        projects.map((project) => {
          if (project.kits.includes(device.id)) {
            device["client"] = project.name;
            deviceAnnex.push(device);
          }
        })
      );
    }
  }, [projects, devices]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function getFilterOptions() {
    var products = [];
    var statuses = [];

    axios.get(`${environment.host}/core/device/deviceParameterConfiguration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        var arr = [];
        arr = response.data.content;

        arr.map((item) => {
          if (item.product && item.product.productType) {
            products.push(item.product.productType);
          }
          if (item.product && item.product.currentVersionStatus) {
            statuses.push(item.product.currentVersionStatus);
          }
        });
      })
      .then(() => {
        products = products.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        statuses = statuses.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        setFilterProduct(products);
        setFilterStatus(statuses);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    getFilterOptions();
  }, []);

  const getAllConfiguredDevices = () => {
    axios.get(`${environment.host}/core/device/deviceParameterConfiguration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content) {
          setAllDevices(response.data.content);
          setDevices(response.data.content);
        } else {
          setDevices([]);
          setAllDevices([]);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  React.useEffect(() => {
    const uId = localStorage.getItem("USER_ID");
    axios.get(`${environment.host}/core/product/configDetails`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
      })
      .catch((err) => {
      });
  }, []);

  const handleOpen = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  const handleConfigureOpen = () => {
    setConfigureOpen(true);
  };

  const handleConfigureClose = () => {
    setConfigureOpen(false);
  };

  const [reviewOpen, setReviewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");

  const handleEditOpen = (props) => {
    setEditOpen(true);
    setSelectedDevice(props);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleReviewOpen = (props) => {
    setReviewOpen(true);
    setSelectedDevice(props);
  };

  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleUpdateOpen = (props) => {
    setSelectedDevice(props);
    setUpdateOpen(true);
  };

  function handleFilter(productType = "ALL", update = "ALL") {
    axios.get(`${environment.host}/core/device/deviceParameterConfiguration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (productType == "ALL" && update != "ALL") {
          const filterDevice = response.data.content.filter(
            (obj) =>
              obj.product &&
              obj.product.currentVersionStatus &&
              obj.product.currentVersionStatus.includes(update)
          );
          setDevices(filterDevice);
          setPage(1);
        }
        if (productType != "ALL" && update != "ALL") {
          const filterDevice = response.data.content.filter(
            (obj) =>
              obj.product &&
              obj.product.productType &&
              obj.product.currentVersionStatus &&
              obj.product.productType.includes(productType) &&
              obj.product.currentVersionStatus.includes(update)
          );
          setDevices(filterDevice);
          setPage(1);
        }
        if (productType != "ALL" && update == "ALL") {
          const filterDevice = response.data.content.filter(
            (obj) =>
              obj.product &&
              obj.product.productType &&
              obj.product.productType.includes(productType)
          );
          setDevices(filterDevice);
          setPage(1);
        }

        if (productType == "ALL" && update == "ALL") {
          getAllConfiguredDevices();
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue === "") {
      getAllConfiguredDevices();
    } else {
      // If searchValue is not an integer, filter devices by name, productType, or device id
      const searchDevice = allDevices.filter((device) => {
        const nameMatch = device.name.toLowerCase().includes(searchValue);

        const productIdMatch = device.product
          ? device.product.productType
            ? device.product.productType.toLowerCase().includes(searchValue)
            : false
          : false;

        const deviceIdMatch = device.id.toLowerCase().includes(searchValue);

        return nameMatch || productIdMatch || deviceIdMatch;
      });

      setDevices(searchDevice);
      setPage(1);

    }

  };

  return (
    <div className={`testing`}>
      <div className="page-heading">
        Remote Manager
      </div>
      {/* <div>{console.log('selectVersions', selectVersions)}</div> */}
      <Grid container className={classes.container} spacing={2}>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            textAlign: "right",
            display: "flex",
            //justifyContent: "right",
          }}
        >
          <SearchBar placeholder="Search" onChange={(e) => handleSearch(e)}/>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          style={{
            color: "white",
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{margin: 0}}>
            <Button
              variant="outlined"
              style={{
                color: secondaryTextColor,
                textTransform: "none",
                border: "0.47999998927116394px solid searchBarBorderColor",
                opacity: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                fontSize: "14px",
                width: "105px",
                height: "40px",
                lineHeight: "20px",
                background:"white"
              }}
              onClick={handleOpen}
            >
              <span
                style={{
                  marginRight: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {FilterIcon()}
              </span>
              <span>Filter</span>
            </Button>
            <FilterBar
              open={filterOpen}
              productType={product}
              update={update}
              setProductType={setProduct}
              setUpdate={setUpdate}
              onClose={() => handleClose()}
              productOptions={filterProduct}
              updateOptions={filterStatus}
              handleFilter={(productType, update) =>
                handleFilter(productType, update)
              }
            />
          </div>

          <div style={{margin: 0}}>
            <Button
              style={{
                backgroundColor: primaryBlue,
                color: white,
                textTransform: "none",
              }}
              onClick={handleConfigureOpen}
            >
              Configure Device
            </Button>
            <ConfigureDevice
              open={configureOpen}
              onClose={() => handleConfigureClose()}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{marginTop: "20px"}}
        >
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        ID
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Device Name
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Updated Status
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Product Type
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Server IP Address
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Last Connected
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Created
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Actions
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {devices.length > 0 &&
                  devices
                    .slice((page-1) * rowsPerPage, page * rowsPerPage )
                    .map((ele, i) => {

                      return (
                        <TableRow key={ele.id}>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.id}
                            </Typography>
                            {/* <Typography className={classes.tableText}>{ele.product && ele.product.Date && ele.product.Date.toString().slice(0, 10)}</Typography> */}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              paddingTop: "18px",
                              paddingBottom: "18px",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            {ele.product &&
                              ele.product.currentVersionStatus ===
                              "APPROVED" && (
                                <Chip
                                  variant="outlined"
                                  className={classes.greenChip}
                                  label="Updated"
                                />
                              )}
                            {ele.product &&
                              ele.product.currentVersionStatus ===
                              "PENDING" && (
                                <Chip
                                  variant="outlined"
                                  className={classes.yellowChip}
                                  label="Pending"
                                />
                              )}
                            {ele.product &&
                              ele.product.currentVersionStatus ===
                              "FAILURE" && (
                                <Chip
                                  variant="outlined"
                                  className={classes.redChip}
                                  label="Failure"
                                />
                              )}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.product ? ele.product.productType : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.deviceParameterConfiguration.serverIpAddress
                                ? ele.deviceParameterConfiguration
                                  .serverIpAddress
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.product &&
                                ele.modifiedDate &&
                                timeSince(ele.modifiedDate)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.deviceParameterConfiguration &&
                                ele.creationDate &&
                                timeSince(
                                  ele.creationDate
                                )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <div style={{display: "inline-flex"}}>
                              <Tooltip title="View" placement="bottom">
                                <IconButton
                                  style={{padding: "0px"}}
                                  onClick={() =>
                                    handleReviewOpen({
                                      device: ele,
                                    })
                                  }
                                >
                                  <ViewIcon/>
                                </IconButton>
                              </Tooltip>
                              {reviewOpen === true && (
                                <ViewConfiguration
                                  device={selectedDevice}
                                  open={reviewOpen}
                                  onClose={() => handleReviewClose()}
                                />
                              )}

                              <Tooltip title="Edit" placement="bottom">
                                <IconButton
                                  style={{padding: "0px", paddingLeft: "10px"}}
                                  onClick={() =>
                                    handleEditOpen({
                                      device: ele,
                                    })
                                  }
                                >
                                  <EditIconLarge/>
                                </IconButton>
                              </Tooltip>
                              {editOpen === true && (
                                <EditConfiguration
                                  device={selectedDevice}
                                  open={editOpen}
                                  onClose={() => handleEditClose()}
                                />
                              )}

                              <Tooltip title="List" placement="bottom">
                                <IconButton
                                  style={{padding: "0px", paddingLeft: "10px"}}
                                  onClick={() => {
                                    handleUpdateOpen({
                                      device: ele,
                                    });
                                  }}
                                >
                                  <ListIcon/>
                                </IconButton>
                              </Tooltip>
                              {updateOpen === true && (
                                <HistoryDevice
                                  device={selectedDevice}
                                  close={() => {
                                    setUpdateOpen(false);
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container justifyContent="center">
          {devices!== undefined && devices.length>rowsPerPage &&
        <Pagination
                    page={page}
                    onChange={handleChangePage}
                    count={devices !== undefined ? Math.ceil(devices.length / rowsPerPage) : 0}
                    shape="rounded"
                    defaultPage={1}
                    />
          }
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{textAlign: "left"}}>
            {reqSuccess === true ? "Success" : "Error"}
          </h3>
          {reqSuccess
            ? "Successfully updated"
            : errorMessage.length > 0
              ? errorMessage
              : "Something went wrong"}
        </Alert>
      </Snackbar>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  container: {
    paddingTop: "20px",
    paddingRight: "9px",
    paddingLeft:"9px",
  },
  versionSelected: {
    backgroundColor: theme.palette.grey[50],
    border: `2px solid #2A7CED`,
    color: "#2A7CED",
    margin: "6",
  },
  version: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid #707170`,
    color: "#707170",
    margin: "6",
  },
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  addFirmware: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  versionCircleText: {
    fontSize: "10px",
  },
  versionText: {
    fontSize: "14px",
    color: "#2A7CED",
  },
  greenChip: {
    // backgroundColor: "#03C69F1F",
    border: "0px",
    color: "#03C69F",
    height: "20px",
  },

  versionChip: {
    // backgroundColor: "#e6edf7",
    border: "0px",
    color: "#2a7ced",
    height: "20px",
    // border: `1px solid #2A7CED`,
  },

  availableVersionChip: {
    // backgroundColor: "#fff",
    color: "#707070",
    height: "20px",
    border: "0px",
  },

  yellowChip: {
    // backgroundColor: "#FFB40A1F",
    color: "#FFB40A",
    height: "20px",
    border: "0px",
  },

  redChip: {
    // backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
    border: "0px",
  },

  blueChip: {
    backgroundColor: "#2a7ced",
    color: "#fff",
    height: "20px",
    border: "0px",
  },

  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px",
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px",
  },
  pagination:{
    alignItems:'center',
    position:"sticky",
    bottom:"5px"
  },
}));

const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px",
  },
})(Tooltip);
