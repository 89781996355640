import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../authentication/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  ACCESS_TYPE,
  Actuator,
  Device,
  FORM_TYPE,
  MetaData,
  PROCESS_TYPE,
  PROPERTY_TYPE,
  SECTIONS,
  Sensor
} from '../../models';
import {AccountService} from '../../account/account.service';
import {ToastrService} from 'ngx-toastr';
import {SideNavService} from '../../layouts/side-menu/sidenav/sidenav.service';
import moment from 'moment-timezone';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {isValidBatchNumber, numericValidator} from '../../utils';

declare function toggleClass(id, className): any;

declare function removeClass(id, className): any;


@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.css']
})
export class DeviceFormComponent implements OnInit {
  @Input() deviceModel: Device;
  @Input() metaData: MetaData;
  @Input() formType;
  @Input() isCloning;
  @Input() batches
  @Output() getDeviceTrigger = new EventEmitter();
  @Output() hideFormTrigger = new EventEmitter();

  formDevice: FormGroup;
  devicesOfKit: Device[] = [];
  isSubmitted: boolean;
  error;
  propertyType = PROPERTY_TYPE;
  processTypes = PROCESS_TYPE;
  propertyCalMethod = ['SUM', 'MIN', 'MAX', 'AVE', 'PREDICT', 'ANY','NONE'];
  dropdownSettings;
  sensorsOfDevice: Sensor[] = [];
  actuatorsOfDevice: Actuator[] = [];
  devices: Device [];
  devicesAll: Device [] = [];
  selectedDeviceId;
  p = 1;
  sensorsOfKitModel = [];
  showKitForm = false;
  showKitModelForm = false;
  isDataFetched = false;
  showDeviceForm = false
  actuators;
  sensors;
  devicesSummary;
  favouriteDevices = [];
  searchTerm = '';
  selectedDevice: Device;
  connectivityOfDevice
  mqttClient;
  clicked = '';
  persistanceOfDevice
  ACCESS_TYPE = ACCESS_TYPE;
  SECTION = SECTIONS;
  protocolOfDevice
  corporateId;
  filter;
  favourite;
  interval;
  simNumber;
  batchNumberSelected = false

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private accountService: AccountService, private toaster: ToastrService,
              private sideNavService: SideNavService, private router: ActivatedRoute,
              private authService: AuthService) {
  }

  ngOnInit() {

    this.router.params.subscribe(params => {
      this.corporateId = params['corporateId'];
      this.filter = params['filter']
      this.favourite = params['favourite'];

    });

    this.formDevice = this.formBuilder.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      interval: [null, [Validators.required, numericValidator, Validators.min(0)]],
      persistence: [null, Validators.required],
      protocol: [null, Validators.required],
      connectivity: [null, Validators.required],
      batchNumber: ['', [Validators.required]],
      simNumber: [null],
    });


    this.sensors = this.metaData.sensors.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });

    this.actuators = this.metaData.actuators.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    var formInput = document.getElementById('formInputId') as HTMLInputElement;
    this.isSubmitted = false;
    if (!this.isCloning) {
      formInput.disabled = true
    } else {
      formInput.disabled = false
    }
    if (this.formType === FORM_TYPE.ADD) {
      this.formDevice.reset();
      this.formDevice.patchValue({
        lbsEnabled: false,
      });
      this.sensorsOfKitModel = [];
      this.actuatorsOfDevice = [];
    } else {
      this.formDevice.reset();
      this.protocolOfDevice = this.deviceModel.protocol
      this.connectivityOfDevice = this.deviceModel.connectivity
      this.persistanceOfDevice = this.deviceModel.persistence
      this.interval = this.deviceModel.interval
      this.sensorsOfDevice = this.deviceModel.sensorCodes ? this.deviceModel.sensorCodes.map(val => {
        return this.sensors.filter(s => s.code === val, 1)[0];
      }) : [];
      this.actuatorsOfDevice = this.deviceModel.actuatorCodes ? this.deviceModel.actuatorCodes.map(val => {
        return this.actuators.filter(a => a.code === val, 1)[0];
      }) : [];
      this.formDevice.patchValue({
        ...this.deviceModel,
        simNumber: this.deviceModel.metaData && this.deviceModel.metaData.simNumber ? this.deviceModel.metaData.simNumber : null
      });
    }
  }

  onInputChange() {
    this.batchNumberSelected = false; // Reset the flag if the user starts typing in the input
    this.updateBatchValidation();
  }

  onSelectChange(event: any) {
    this.batchNumberSelected = true; // Set flag when a batch is selected from the dropdown
    this.updateBatchValidation();
  }

  updateBatchValidation() {
    const batchControl = this.formDevice.controls['batchNumber'];
    if(batchControl.value && batchControl.value.trim() == ''){
      if (this.batchNumberSelected) {
        batchControl.clearValidators();
      } else {
        batchControl.setValidators([Validators.required, isValidBatchNumber]);
      }
    }
    batchControl.updateValueAndValidity(); // Update the validation status
  }

  hideForm() {
    this.hideFormTrigger.emit(true);
  }

  getMetaData() {
    this.spinner.show();
    this.accountService.getMetaData().subscribe((data) => {
      this.metaData = data.content;
      this.sensors = data.content.sensors.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      this.actuators = data.content.actuators.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      this.spinner.hide()
    }, (error) => {
      this.spinner.hide();
    });
  }

  createDevice(device) {
    const batchControl = this.formDevice.controls['batchNumber']
    if (batchControl.value && batchControl.value.trim() !== '') {
      batchControl.clearValidators();
      batchControl.updateValueAndValidity(); // Ensure the form control reflects the changes
    }
    Object.keys(this.formDevice.controls).forEach((key) => {
        if (typeof device[key] === "string") {
          device[key] = device[key].trim();
        }
      }
    );

    this.isSubmitted = true;
    if (!this.formDevice.valid || (!this.isSuperUser() && !AuthService.hasNewUserAccess)) {
      return;
    }

    this.spinner.show();
    this.error = null;
    device.sensorCodes = this.sensorsOfDevice.map(s => s.code);
    device.actuatorCodes = this.actuatorsOfDevice.map(a => a.code);
    device.noOfSensors = this.sensorsOfDevice.length;
    device.noOfActuators = this.actuatorsOfDevice.length;

    device.metaData = {};
    device.metaData.simNumber = this.formDevice.value.simNumber;

    const isUserLogEnabled = environment.enableUserLog;
    if (this.formType === FORM_TYPE.ADD || this.isCloning) {
        this.accountService.createDeviceWithCreatedBy(device).subscribe(response => {
          // this.spinner.hide();
          toggleClass('addDevice', 'hide');
          this.toaster.success('Device Created Successfully', 'Success');
          this.getDeviceTrigger.emit(true);
          this.hideFormTrigger.emit(true);
          this.getBatches();
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 422) {
            this.error = error.error.message;
          } else {
            this.toaster.error('Oops... Something went wrong', 'Error!');
          }
        }, () => {
          this.isCloning = this.isCloning ? !this.isCloning : this.isCloning;
        });
    } else {
        this.accountService.updateDeviceWithModifiedBy(this.deviceModel.id, device).subscribe(response => {
          this.spinner.show();
          toggleClass('addDevice', 'hide');
          this.toaster.success('Device Updated Successfully', 'Success');
          this.getDeviceTrigger.emit(true);
          this.hideFormTrigger.emit(true);
          this.getBatches();
          this.hideForm();
        }, error => {
          this.spinner.hide();
          if (error.status === 422) {
            this.error = error.error.message;
          } else {
            this.toaster.error('Oops... Something went wrong', 'Error!');
          }
        });
      this.isSubmitted = false;
    }
  }

  getBatches() {
    this.spinner.show();
    this.accountService.getBatches().subscribe((data) => {
      this.batches = data.content;
    }, () => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  getDevices(isSilent?) {
    if (!isSilent) {
      this.spinner.show();
    }
    this.accountService.getOneDevice(this.corporateId, this.filter, this.favourite).subscribe(response => {
      this.devices = response.content;
      this.devicesAll = Object.assign([], this.devices);
      this.devices.sort((a, b) => {

        if (!a.name || !b.name) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      if (this.devicesSummary) {
        this.devicesSummary.alive = {noOfDevice: 0, deviceList: []};
        this.devicesSummary.noDevicesOffline = {noOfDevice: 0, deviceList: []};

        this.devices.forEach(device => {
          if (this.isDeviceOnline(device.lastSeen, device.interval)) {
            this.devicesSummary.alive.noOfDevice++;
            this.devicesSummary.alive.deviceList.push(device.id);
          } else if (device.lastSeen) {
            this.devicesSummary.noDevicesOffline.noOfDevice++;
            this.devicesSummary.noDevicesOffline.deviceList.push(device.id);
          }
        });
      }
      this.spinner.hide();
      this.isDataFetched = true;
    }, error => {
      this.spinner.hide();
    })
  }

  isBatterySelected() {
    return this.sensorsOfDevice.includes(this.getSensorByCode('B'));
  }

  isDeviceOnline(time, interval) {
    if (!time) {
      return false;
    }
    const ist = moment.tz(time, 'Asia/Colombo');
    const ut = ist.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return moment().diff(ut) / (1000 * 60) <= interval;
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  addSensorToDevice(index) {
    this.sensorsOfDevice.push(this.sensors[index]);
  }

  removeSensorFromDevice(index) {
    this.sensorsOfDevice.splice(index, 1);
  }

  addActuatorToDevice(index) {
    this.actuatorsOfDevice.push(this.actuators[index]);
  }

  removeActuatorFromDevice(index) {
    this.actuatorsOfDevice.splice(index, 1);
  }

  editDevice(device) {
    this.formDevice.reset();
    this.formDevice.patchValue(device);
    this.sensorsOfDevice = device.sensorCodes ? device.sensorCodes.map(s => this.getSensorByCode(s)) : [];
    console.log(this.sensorsOfDevice)
    this.actuatorsOfDevice = device.actuatorCodes ? device.actuatorCodes.map(s => this.getActuatorByCode(s)) : [];
    if (!this.isCloning) {
      this.formDevice.controls['id'].disable();
    } else {
      this.formDevice.controls['id'].enable();
    }
    this.formType = FORM_TYPE.EDIT;
    this.selectedDeviceId = device.id;
    this.error = null;
    this.isSubmitted = false;
  }

  getSensorByCode(sensor) {
    return this.sensors.filter(s => {
      return s.code === sensor;
    }, 1)[0];
  }

  private getActuatorByCode(actuator) {
    return this.actuators.filter(a => {
      return a.code === actuator;
    }, 1)[0];
  }

}
