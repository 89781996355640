import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid, InputBase, Chip} from '@material-ui/core';
import { DownArrowIcon, UpArrow } from 'src/components/Icons';
import useStyles from '../assets/styles';
import { white } from 'src/components/colors';


export function DropDown(props) {
  const classes = useStyles();
  const [isDropDown, setIsDropDown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    // if (props.onMainDropClick && props.type === 'mainTestCase') {
    //   props.onMainDropClick ();
    // }
    handleDropDown();
  };

  const handleValue = (e) => {
    if (props.isSingle) {
     if (props.type === 'productType'||'version') {
      handleSelectedValue(e);
     } else {
         props.onClick(e);
       }
      setIsDropDown(false);
    } else {
      const updatedValues = props.value.includes(e)
        ? props.value.filter(val => val !== e)
        : [...props.value, e];
      props.setSelectedOption(updatedValues);
      setIsDropDown(false);
    }
  };

  const handleSelectedValue = (e) => {
    props.setSelectedOption(e);
  };
  const handleDropDown = () => {
    if(!props.disabled){
    setIsDropDown(!isDropDown);
    props.setOptions(props.options);}
  };

  const handleRemove = (value) => {
    const updatedValues = props.value.filter(val => val !== value);
    props.setSelectedOption(updatedValues);
  };

  return (
    <Grid ref={dropdownRef} container style={{ position: 'relative' }}>
      <Grid container 
        className={classes.inputBaseStyle} 
        style={{ backgroundColor: props.type === "successCriteria" ? white : undefined }} 
        onClick={handleClick}
        
        
      >
        <Grid item xs={10} className={classes.inputContainer}>
          {props.isSingle ? (
            <InputBase
              placeholder={props.emptyTag}
              style={{ border: 'none', cursor: 'pointer', marginLeft: '10px', fontSize: '13px' }}
              value={props.type=="productType"?props.value.productName:props.value || ''}
              fullWidth
              autoFocus
              readOnly
              disabled={props.disabled}
            />
          ) : (
            <>
              {props.value.length === 0 ? (
                <InputBase
                  placeholder={props.emptyTag}
                  style={{ border: 'none', cursor: 'pointer', marginLeft: '10px', fontSize: '13px',opacity:`${props.disabled?0.5:1}` }}
                  value=""
                  fullWidth
                  autoFocus
                  readOnly
                />
              ) : (
                props.value.map((value, index) => (
                  <Chip
                    key={index}
                    label={value}
                    className={classes.chip}
                    onDelete={() => handleRemove(value)}
                    style={{ margin: '2px' }}
                  />
                ))
              )}
            </>
          )}
        </Grid>
        <Grid item xs={2} className={classes.iconContainer}>
          {isDropDown ? <UpArrow /> : <DownArrowIcon />}
        </Grid>
      </Grid>

      {isDropDown && (
        <div className={classes.dropdown}>
          {props.options.map((option, index) => (
            <Grid
              item
              className={classes.option}
              key={index}
              onClick={() => handleValue(option)}
            >
              {props.type=="productType"?option.productName:option}
            </Grid>
          ))}
        </div>
      )}
    </Grid>
  );
}