import React, {useEffect, useState} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {DownArrowIcon, UpArrow} from 'src/components/Icons';

export function KitModelDropDown(props) {
  const [isClick, setIsClick] = useState(false);
  const sensors = props.options;

  const [operation, setOperation] = useState("")

  const handleDropDown = () => {
    setIsClick(!isClick);
  };

  const handleCalMethod = (e) => {
    setOperation(e);
    const updatedOperationsArray = [...props.operationsArray];
    updatedOperationsArray[props.index] = e;
    props.setOperationsArray(updatedOperationsArray);
    props.clearShiftError(props.index);
    setIsClick(!isClick)
  };


  return (
    <div>

      <Grid
        container
        className={props.className}
        onClick={handleDropDown}
      >

        <Grid item xs={8} md={8} onClick={handleDropDown}>
          <input
            type="text"
            name="shift"
            placeholder=''
            style={{border: 'none', cursor: 'pointer', backgroundColor: 'white', width: '100%'}}
            readOnly={true}
            value={operation}
            id={props.id}
          />
        </Grid>
        <Grid item xs={3} md={3}>
          {isClick ?
            <UpArrow/>
            : <DownArrowIcon/>}
        </Grid>


      </Grid>

      {isClick ?
        <Grid container style={{
          border: '0.5px solid #55555580',
          borderRadius: '5px',
          backgroundColor: 'white',
          zIndex: 1,
          position: 'relative',
          width: '100%',
          cursor: 'pointer'
        }}>
          {sensors.map((option, index) => (
            <Grid container key={index} alignItems='center' style={{padding: '5px'}}
                  onClick={() => handleCalMethod(option)}>

              <Grid item style={{marginLeft: '5px'}}>
                <Typography style={{fontSize: '12px', fontWeight: 400}}>{option}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        : <></>

      }
    </div>
  );
};
