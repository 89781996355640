import {combineReducers} from 'redux';
import decoderListReducer from "./service";
import settingReducer from './settingReducer';

const reducers = combineReducers({
  decoder: decoderListReducer,
  setting: settingReducer,


});

export default reducers;
