import * as React from "react";
import { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  NativeSelect,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Dropzone, { useDropzone } from "react-dropzone";
import { environment } from "src/environments/environment";
import { TextBar } from "src/components/sub-components/TextBar";
import {
  primaryGray,
  searchBarBorderColor,
  textBarColor,
} from "src/components/colors";
import { UploadFile } from "src/components/Icons";
import BrowseButton from "src/components/sub-components/BrowseButton";
import { DragDrop } from "src/components/sub-components/DragAndDrop";
import axios from "axios";
import useStyles from "../../assets/styles";
import DropZoneWithFileFilter from "../DropZoneWithFileFilter";

export function Step01(props) {
  const classes = useLocalStyles();
  const styles =useStyles();

  const [productTypes, setProductTypes] = useState([]);
  const [allProductTypes, setAllProductTypes] = useState([]);

  useEffect(() => {
    axios.get(`${environment.host}/core/product/configDetails`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
          let proTypes = [];

          // Access the array inside productTypes[0]
          response.data.content.productTypes[0].forEach((product) => {
            if (product.otaUpgradable === true) {
              proTypes.push(product.productName);
            }
          });        
          setProductTypes(proTypes);
          setAllProductTypes(response.data.content.productTypes[0].filter(product => product.otaUpgradable === true));   
        }
      })
      .catch((err) => {
        console.error("Failed to fetch product types:", err);
        setProductTypes([]);
      });
  }, []);

  const readProductType = (e) => {
    props.readProductType(e.target.value);
  };

  return (
    <Grid container style={{ padding: "30px 200px" }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={6} md={6}>
          <Typography
            className={classes.label}
            style={{ textAlign: "left" }}
          >
            Product Type
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl className={classes.input}>
            <NativeSelect
              id="demo-customized-select-native"
              value={props.defaultProductType}
              className={classes.input}
              onChange={readProductType}
              input={<BootstrapInput />}
            >
              <option value="" disabled hidden>
                Select product type
              </option>
              {productTypes.map((productType, i) => (
                <option value={productType} key={i}>
                  {productType}
                </option>
              ))}
            </NativeSelect>
            {props.productTypeError === true && (
              <FormHelperText className={styles.helperText}>
                Product Type Required *
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography
            className={classes.label}
            style={{ textAlign: "left" }}
          >
            Version
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl className={classes.input}>
            <TextBar
              error={{ error: false }}
              defaultValue={props.defaultVersion}
              className={classes.input}
              onChange={(e) => props.readVersion(e.target.value)}
              style={classes.input}
            />
            {props.versionError.isError === true && (
              <FormHelperText className={styles.helperText}>
                {props.versionError.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        style={{ textAlign: "left", paddingTop: "30px" }}
      >
        <Typography className={classes.label}>
          Upload Bin file
          <span style={{ color: "red" }}>*</span>
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
       {/* 
        <Dropzone onDrop={(acceptedFiles) => props.readBin(acceptedFiles)}
          accept={{
        "application/octet-stream ": [".bin"], 
      }} >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  border: "1px dashed rgba(117, 117, 117, 0.5)",
                  backgroundColor: "#EDEDED",
                  borderRadius: "8px",
                  height: "220px",
                  padding: "40px",
                }}
              >
               
                <DragDrop/>
                <Typography>
                  <span
                    style={{
                      fontSize: "15px",
                      color: primaryGray,
                    }}
                  >
                    {props.binFileName === ""
                      ? "Drag & Drop file here to upload or"
                      : props.binFileName}
                  </span>

                  <BrowseButton
                    isFileSelected={props.binFileName !== ""}
                  />
                </Typography>
              </div>
            </section>
          )}
        </Dropzone> */}
        <DropZoneWithFileFilter readFiles={props.readBin} fileName={props.binFileName} fileExtensions={[".bin"]} />

        {props.binError.error === true && (
          <FormHelperText className={styles.helperText}>
            {props.binError.errorMsg}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: textBarColor,
  },
  label: {
    fontSize: "15px",
    color: primaryGray,
    paddingBottom: "5px",
  },

  textBarStyle: {
    width: "230px",
    backgroundColor: textBarColor,
    height: "44px",
  },
}));

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);


