import React, {useState, useEffect} from "react";
import { Box, Button, createTheme, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputBase, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from '../../maintenance/assets/styles';
import ReactSwitch from "react-switch";
import { createJiraProject, updateProjectStatus } from "src/app/maintenance/test-automation/components/apiHelper";

export const EditConfigurationPopUp = ({ open, handleWindowClose, setIsToasterOpen, setReqSuccess, setToasterMessage, setLoading, projectDetail, fetchData }) => {
    const classes = useStyles();
    const [isToggled, setIsToggled] = useState(false);
    const [formData, setFormData] = useState({
        jiraBoard: '',
        jiraURL: '',
        email: '',
        apiToken: '',
        projectKey: ''
    });

    const [errors, setErrors] = useState({
        jiraBoardError: '',
        jiraURLError: '',
        emailError: '',
        apiTokenError: '',
        projectKeyError: ''
    });

    useEffect(() => {
        if (projectDetail) {
            setFormData({
                jiraBoard: projectDetail.jiraBoard || '',
                jiraURL: projectDetail.jiraURL || '',
                email: projectDetail.email || '',
                apiToken: projectDetail.apiToken || '',
                projectKey: projectDetail.projectKey || ''
            });
            setIsToggled(projectDetail.status || false);
        }
    }, [projectDetail]);

    const validateForm = () => {
        let isError = false;
        let errors: any = {};
        if (formData.jiraBoard.trim() === '') {
            errors.jiraBoardError = 'Jira Board Name is required';
            isError = true;
        }
        if (formData.jiraURL.trim() === '') {
            errors.jiraURLError = 'Jira URL is required';
            isError = true;
        }
        if (formData.email.trim() === '') {
            errors.emailError = 'Reporter Email is required';
            isError = true;
        }
        if (formData.apiToken.trim() === '') {
            errors.apiTokenError = 'Api Token is required';
            isError = true;
        }
        if (formData.projectKey.trim() === '') {
            errors.projectKeyError = 'Project Key is required';
            isError = true;
        }
        setErrors(errors);
        return isError;
    }

    const handleSubmit = async () => {
        const validationError = validateForm();
        if (validationError) {
            return;
        }
        const data = {
            jiraBoard: formData.jiraBoard,
            jiraURL: formData.jiraURL,
            email: formData.email,
            apiToken: formData.apiToken,
            projectKey: formData.projectKey
        };
        try {
            setLoading(true);
            const response = await createJiraProject(data);
            if(response.status === 200){
                setIsToasterOpen(true);
                setReqSuccess(true);
                setToasterMessage("Configurations Updated Successfully");
                handleWindowClose();
                await fetchData();
            }
        } catch (error) {
            if(error.status === 422){
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(error.data.message);
            }
        } finally {
            setLoading(false)
            setTimeout(() => setIsToasterOpen(false), 3000);
        }
    }

    const handleChangeToggle = async (checked: boolean) => {
        setIsToggled(checked);
    
        try {
            setLoading(true);
            await updateProjectStatus(checked);
            setIsToasterOpen(true);
            setReqSuccess(true);
            setToasterMessage("Status Updated Successfully")
        } catch (error) {
            setIsToggled(!checked); 
            setIsToasterOpen(true);
            setReqSuccess(false);
            setToasterMessage("Failed to update status");
        } finally {
            setLoading(false);
            setTimeout(() => setIsToasterOpen(false), 3000);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    
        setErrors((prevErrors) => ({
            ...prevErrors,
            [`${name}Error`]: ''
        }));
    };

    return(
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>{"Edit Configuration".toUpperCase()}</span>
                    <IconButton
                        onClick={() => {
                            handleWindowClose();
                        }}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:'560px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                Jira Board Name
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                name="jiraBoard"
                                value={formData.jiraBoard}
                                onChange={handleInputChange}
                            />
                            {errors.jiraBoardError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.jiraBoardError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                Jira URL
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                name="jiraURL"
                                value={formData.jiraURL}
                                onChange={handleInputChange}
                            />
                            {errors.jiraURLError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.jiraURLError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                Reporter's Email
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {errors.emailError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.emailError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                API Token
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                name="apiToken"
                                value={formData.apiToken}
                                onChange={handleInputChange}
                            />
                            {errors.apiTokenError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.apiTokenError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                Project Key
                                <span style={{ color: 'red' }}> * </span>
                                :
                            </Typography>
                            <InputBase
                                className={classes.formInput}
                                type="text"
                                name="projectKey"
                                value={formData.projectKey}
                                onChange={handleInputChange}
                            />
                            {errors.projectKeyError && (
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.errorText}>
                                        {errors.projectKeyError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'column', gap: '5px' }}>
                            <Typography>
                                Jira Board Connection
                            </Typography>
                            <Grid item xs={4} md={4} style={{
                                display: 'flex',
                                }}>
                                <FormControl>
                                    <Grid item  style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                        <ReactSwitch
                                            checked={isToggled}
                                            onChange={handleChangeToggle}
                                            offColor='#F0F1F4'
                                            onColor="#005B96"
                                            offHandleColor='#BEBFC5'
                                            onHandleColor="#F0F1F4"
                                            height={15}
                                            width={40}
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}   
                                        />
                                        <Typography style={{ fontSize: "14px", color: isToggled ? "#00B1A5" : "#868DAA" }}>{isToggled ? "Connected" : "Not Connected"}</Typography>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{paddingRight:"8px"}} justifyContent="flex-end">
                            <Grid item>
                                <Button
                                variant="contained"
                                className={classes.backButton}
                                onClick={handleWindowClose}
                                >
                                Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                variant="contained"
                                className={`${classes.devicePulseSaveButton} ${classes.button}`}
                                onClick={handleSubmit}
                                >
                                Update 
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}