import React, { useState, useEffect } from 'react';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Checkbox, Dialog, DialogTitle, Box, IconButton, DialogContent, Button, Tooltip, InputBase, FormGroup, FormControlLabel } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from 'src/app/maintenance/assets/styles';
import { isValidBatchNumber } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { getAllDevices } from 'src/app/testing-automation/api-helper/apiHelper';
import Cookies from "js-cookie";

export const CreateBatchPopup = ({
    open,
    handleClose,
    devices,
    setLoading,
    setIsToasterOpen,
    setReqSuccess,
    setToasterMessage,
    onBatchCreated,
}) => {
    const classes = useStyles();
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [newBatchNumber, setNewBatchNumber] = useState('');
    const [isExistingBatchNumber, setIsExistingBatchNumber] = useState(false);
    const [message, setMessage] = useState('');

    const [errors, setErrors] = useState({
        deviceError: null,
        batchNumberError: null,
    });

    const handleCheckBoxChange = (deviceId) => (event) => {
        setSelectedDevices((prevSelected) =>
            event.target.checked
                ? [...prevSelected, deviceId]
                : prevSelected.filter((id) => id !== deviceId)
        );
        setErrors((prevError) => ({
            ...prevError,
            deviceError: null,
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const trimmedValue = value.trim();
        const isValid = isValidBatchNumber(event.target);

        if (trimmedValue !== '') {
            if (isValid !== null) {
                setErrors((prevError) => ({
                    ...prevError,
                    batchNumberError: 'Enter a valid batch number: it must contain at least one letter, one digit, and either an underscore (_) or a hyphen (-).',
                }));
            } else {
                setErrors((prevError) => ({
                    ...prevError,
                    batchNumberError: null,
                }));
            }
        }

        if (name === 'newbatch') {
            setNewBatchNumber(trimmedValue);
        }
    };

    const resetFormFields = () => {
        setNewBatchNumber('');
        setSelectedDevices([]);
        setErrors({
            deviceError: null,
            batchNumberError: null,
        });
    };

    const validateForm = () => {
        const selectDeviceError = selectedDevices.length === 0;
        let error: any = {}
        if (selectDeviceError) {
            error.deviceError = 'Selecting At least One Device is Required.'
        }
        if (newBatchNumber === '') {
            error.batchNumberError = 'Batch Number is Required.'
        }
        return error;
    }

    const handleCreate = async () => {
        setLoading(true);

        const error = validateForm();
        if (Object.keys(error).length > 0) {
            setErrors(error);
            setLoading(false);
            return;
        }

        const batch = newBatchNumber;

        try {
            const response = await axios.post(
                `${environment.host}/core/user/${Cookies.get('USER_ID')}/batch/${batch}/updateBatchNumber`,
                selectedDevices,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${Cookies.get("ACCESS_TOKEN")}`,
                    },
                }
            );
            setIsToasterOpen(true);
            setReqSuccess(true);
            setToasterMessage('Batch Created successfully');
            onBatchCreated();
            setTimeout(() => {
                setIsToasterOpen(false);
                handleClose();
                resetFormFields();
            }, 5000);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                const errorData = error.response.data;
                if (errorData.message === 'Batch number already exist') {
                    setMessage(errorData.message);
                    setIsExistingBatchNumber(true);
                } else {
                    setIsToasterOpen(true);
                    setReqSuccess(false);
                    setToasterMessage(errorData.message);
                    setTimeout(() => {
                        setIsToasterOpen(false);
                    }, 5000);
                }
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    Create Batch
                    <IconButton
                        onClick={() => {
                            handleClose();
                            resetFormFields();
                        }}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style={{ margin: '0px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" style={{ marginTop: "15px" }}>
                            <Grid item xs={3} className={classes.label}>
                                Test Batch: <span style={{ color: 'red' }}>*</span>
                            </Grid>
                            <Grid item xs={9}>
                                <Tooltip title="Please include '_' or '-' in the input" arrow placement='top-start'>
                                    <InputBase
                                        className={classes.formInput}
                                        type="text"
                                        name="newbatch"
                                        value={newBatchNumber}
                                        onChange={handleInputChange}
                                    />
                                </Tooltip>
                                {
                                    isExistingBatchNumber && (
                                        <Typography className={classes.errorText} >
                                            {message}
                                        </Typography>
                                    )
                                }
                                {errors.batchNumberError &&
                                    <Typography className={classes.errorText} style={{ textAlign: 'left' }}>
                                        {errors.batchNumberError}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="flex-end" style={{ marginTop: "30px", marginBottom: "15px" }}>
                            <TableContainer>
                                <Table style={{border:"1px solid #ddd"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="center">Entity ID</TableCell>
                                            <TableCell align="center">Last Seen</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {devices && devices.length > 0 ? (
                                            devices.map((device) => (
                                                <TableRow key={device.id}>
                                                    <TableCell align="center">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                label=""
                                                                control={
                                                                    <Checkbox
                                                                        className={classes.radio}
                                                                        checked={selectedDevices.includes(device.id)}
                                                                        onChange={handleCheckBoxChange(device.id)}
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </TableCell>
                                                    <TableCell align="center">{device.id}</TableCell>
                                                    <TableCell align="center">{device.lastSeen || 'N/A'}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" colSpan={3}>
                                                    <Typography>No Devices Available</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                errors.deviceError && (
                                    <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: "5px" }}>
                                        <Typography className={classes.errorText}>
                                            {errors.deviceError}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container justifyContent="flex-end" style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', marginBottom: '20px' }} spacing={2}>
                            <Grid item className={classes.buttonContainer}>
                                <Button className={classes.backButton} style={{ margin: '0px' }} onClick={() => { handleClose(); resetFormFields(); }}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item className={classes.buttonContainer}>
                                <Button className={classes.submitButton} onClick={() => handleCreate()}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
