import React, { useEffect, useState } from 'react';
import { Grid, InputBase, IconButton, Button, Typography, InputAdornment, useTheme, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { lightGray, white } from 'src/components/colors';
import useStyles from '../../assets/styles';
import { ParameterDropDown } from './ParameterDropDown';
import { DropDown } from '../components/DropDown';
import { matchSensorNames} from '../components/apiHelper';
import { AddTestIcon, DecreaseIcon, IncreaseIcon } from 'src/components/Icons';

export const SubTestCases = ({
    subTestCases, 
    setSubTestCases,
    subTestCaseErrors,
    selectedBatch,
    setSubTestCaseErrors,
    setErrors,
    mainTestCaseName
}) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [parameters, setParameters] = useState([]);

  const [successCriterias, setSuccessCriterias] = useState([
    "No of Data",
    "Greater than",
    "Greater than or equal to",
    "Less than",
    "Less than or equal to",
    "Is equal to",
    "Is not equal to",
    "Is between",
    "Is not between",
    "Average",
    "Increasing",
    "Decreasing",
    "Is Value Present"
  ]);
  const [shedulePeriod, setShedulePeriod] = useState([
    "Minutes", "Hours", "Days", "Weeks", "Months"
  ]);
  const [subSuccessCriterias, setSubSuccessCriterias] = useState([
    "Greater than",
    "Greater than or equal to",
    "Less than",
    "Less than or equal to",
  ]);

  useEffect(() => {
    const getParameters = async () => {
      try {
        const parameters = await matchSensorNames(selectedBatch);
        setParameters(parameters);
      } catch (err) {
        console.error('Error fetching parameters:', err);
      }
    };
    if (selectedBatch) {
      getParameters();
    }
  }, [selectedBatch]);

  const parameterOptions = parameters.map(param => ({
    label: param.name,
    value: param.code   
  }));

  const handleSubTestCaseChange = (index, { target: { name, value } }) => {
    const newSubTestCases = [...subTestCases];

    if (mainTestCaseName === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        mainTestNameError: "Main test case is required"
      }));
      return;
    }

    newSubTestCases[index][name] = value;
  
    setSubTestCaseErrors(prevErrors => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) {
        newErrors[index] = {};
      }
  
      // Clear relevant error messages based on input changes
      const clearErrors = (field) => {
        if (newSubTestCases[index][field] !== '') {
          newErrors[index][`${field}Error`] = null;
        }
      };
  
      clearErrors('subTestCaseName');
      clearErrors('successCriteria');
      clearErrors('minValue');
      clearErrors('maxValue');
      clearErrors('subSuccessCriteria');
  
      // Additional logic for minValue error handling
      if (newErrors[index].minValueError !== '' && 
          ((newSubTestCases[index].minValue === '' && newSubTestCases[index].maxValue !== '') || 
          newSubTestCases[index].minValue < newSubTestCases[index].maxValue)) {
        newErrors[index].minValueError = null;
      }
  
      return newErrors;
    });
    setSubTestCases(newSubTestCases);
  };

  const handleParameterChange = (index, selectedParameter) => {
    const newSubTestCases = [...subTestCases];
    if (mainTestCaseName === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        mainTestNameError: "Main test case is required"
      }));
      return;
    }else{
    newSubTestCases[index].parameter = selectedParameter;
    setSubTestCases(newSubTestCases);
    setSubTestCaseErrors(prevError =>{
      const newError = [...prevError]
      if (!newError[index]) {
        newError[index] = {};
      }
      newError[index].parameterError = null;
    
      return newError;
    })
  }
  };

  const handleDurationChange = (index, newValue) => {
    const updatedValue = Math.max(newValue, 1);
    const updatedSubTestCases = [...subTestCases];
    updatedSubTestCases[index].durationValue = updatedValue;
    setSubTestCases(updatedSubTestCases);
  };

  const handleAddSubTestCase = () => {
    const criteriaValueRequired = [
      "No of Data", 
      "Greater than", 
      "Greater than or equal to", 
      "Less than", 
      "Less than or equal to", 
      "Is equal to", 
      "Is not equal to", 
      "Average",
      "Is Value Present"
    ];
    const hasEmptyFields = subTestCases.some((testCase) => {
      const isCriteriaValueRequired = criteriaValueRequired.includes(testCase.successCriteria);
      return (
        testCase.subTestCaseName == "" ||
        testCase.successCriteria == "" ||
        testCase.parameter == "" ||
        isCriteriaValueRequired ? testCase.criteriaValue == "" :( testCase.minValue == "" || testCase.maxValue == "")
        
      );
    });
    if (hasEmptyFields) {
      return;
    }
      setSubTestCases([...subTestCases, {
        subTestCaseName: '',
        successCriteria: '',
        parameter: '',
        criteriaValue: '',
        subSuccessCriteria: '',
        durationValue: null,
        shedulePeriod: '',
        minValue: '',
        maxValue: ''
      }]);

  };

  const handleDeleteSubTestCase = (index) => {
    if (subTestCases.length > 1) {
      const newSubTestCases = subTestCases.filter((_, i) => i !== index);
      setSubTestCases(newSubTestCases);
    }
  };

  return (
    <>
    <Grid container justifyContent="flex-end" alignItems="flex-start" spacing={2} style={{ marginTop: "5px" }}>
        <Button
          variant="contained"
          className={classes.addNew}
          style={{ textTransform: "none", backgroundColor: "#005B96", color: "white" }}
          onClick={handleAddSubTestCase}
        >
          <AddTestIcon />
          Add New Sub Test Case
        </Button>
      </Grid>
      {subTestCases.map((subTestCase, index) => (
        <Grid container alignItems="center" spacing={2} key={index} className={classes.subTestMainContainer}>
          <Grid container alignItems="center"  style={{ margin: "20px" }}>
            <Grid item xs={12} md={12} className={classes.subTestMainFlexContainer}>
              <Grid item xs={12} md={12} className={classes.subTestSecondFlexContainer}>
                <Grid item xs={12} md={12} className={classes.subTestThirdFlexContainer}>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} md={12}>
                        <Typography className={classes.typo}>
                          Sub Test Case 
                          <span style={{ color: 'red' }}>*</span>
                        </Typography> 
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                      <InputBase
                        className={classes.formInput}
                        style={{ backgroundColor: white }}
                        type="text"
                        placeholder="Enter Sub Test Case Name Here"
                        name="subTestCaseName"
                        value={subTestCase.subTestCaseName}
                        onChange={(e) => handleSubTestCaseChange(index, e)}
                      />
                      {subTestCaseErrors[index] && subTestCaseErrors[index].subTestCaseNameError && (
                        <Grid container justifyContent="flex-end" alignItems="center" >
                          <Typography className={classes.errorText}>
                            {subTestCaseErrors[index].subTestCaseNameError}
                          </Typography>
                        </Grid>                
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} md={12}>
                        <Typography className={classes.typo}>
                          Parameter 
                          <span style={{ color: 'red' }}>*</span>
                        </Typography> 
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                      <ParameterDropDown
                        options={parameterOptions}
                        type='parameter'
                        emptyTag='Select Parameter'
                        setSelectOption={(selectedParameter) => 
                            handleParameterChange(index, selectedParameter)
                        }
                        value={subTestCase.parameter}
                        isSingle={true}
                        mainTestCaseName={mainTestCaseName}
                        setErrors={setErrors}
                        disabled={subTestCase.subTestCaseName === ''}
                      />
                      {subTestCaseErrors[index] && subTestCaseErrors[index].parameterError && (
                        <Grid container justifyContent="flex-end" alignItems="center" >
                          <Typography className={classes.errorText}>
                            {subTestCaseErrors[index].parameterError}
                          </Typography>
                        </Grid>                
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} md={12}>
                        <Typography className={classes.typo}>
                          Success Criteria
                          <span style={{ color: 'red' }}>*</span>
                        </Typography> 
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                      <DropDown
                        options={successCriterias}
                        type='successCriteria'
                        emptyTag='Select Success Criteria'
                        setSelectOption={(value) => 
                            handleSubTestCaseChange(index, { target: { name: 'successCriteria', value } })}
                        value={subTestCase.successCriteria}
                        isSingle={true}
                        disabled={subTestCase.parameter === ''}
                      />
                      {subTestCaseErrors[index] && subTestCaseErrors[index].successCriteriaError && (
                        <Grid container justifyContent="flex-end" alignItems="center" >
                          <Typography className={classes.errorText}>
                            {subTestCaseErrors[index].successCriteriaError}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    {["Greater than", "Greater than or equal to", "Less than", "Less than or equal to", "Is equal to", "Is not equal to","Is Value Present"]
                      .includes(subTestCase.successCriteria) && (
                      <>
                        <Grid item md={3}></Grid>
                        <Grid item xs={12} md={9}>
                          <InputBase
                            className={classes.formInput}
                            style={{ backgroundColor: white }}
                            type="text"
                            placeholder="Enter Value Here"
                            name="criteriaValue"
                            value={subTestCase.criteriaValue}
                            onChange={(e) => handleSubTestCaseChange(index, e)}
                          />
                          {subTestCaseErrors[index] && subTestCaseErrors[index].criteriaValueError && (
                            <Grid container justifyContent="flex-end" alignItems="center">
                              <Typography className={classes.errorText}>
                                {subTestCaseErrors[index].criteriaValueError}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}

                    {["Is between", "Is not between", "Increasing", "Decreasing"]
                      .includes(subTestCase.successCriteria) && (
                      <>
                      <Grid container>
                        <Grid item xs={3} md={3}></Grid>
                        {isMdUp && (
                            <Typography style={{ marginBottom: "5px", visibility: "hidden" }}>
                              Success Criteria Value:
                            </Typography>
                          )}
                          <Grid item xs={4} md={4} style={{margin:'0px 15px 0px 9px'}}>
                            <InputBase
                              className={classes.formInput}
                              style={{ backgroundColor: white }}
                              type="text"
                              placeholder="Min Value"
                              name="minValue"
                              value={subTestCase.minValue}
                              onChange={(e) => handleSubTestCaseChange(index, e)}
                            />
                            {subTestCaseErrors[index] && subTestCaseErrors[index].minValueError && (
                              <Grid container justifyContent="flex-end" alignItems="center">
                                <Typography className={classes.errorText}>
                                  {subTestCaseErrors[index].minValueError}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <InputBase
                              className={classes.formInput}
                              style={{ backgroundColor: white }}
                              type="text"
                              placeholder="Max Value"
                              name="maxValue"
                              value={subTestCase.maxValue}
                              onChange={(e) => handleSubTestCaseChange(index, e)}
                            />
                            {subTestCaseErrors[index] && subTestCaseErrors[index].maxValueError && (
                              <Grid container justifyContent="flex-end" alignItems="center">
                                <Typography className={classes.errorText}>
                                  {subTestCaseErrors[index].maxValueError}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className={classes.subTestThirdFlexContainer} style={{gap:"15px"}}>
                  {["No of Data", "Average"]
                  .includes(subTestCase.successCriteria) && (
                    <>
                      <Grid item xs={12} md={6} style={{ marginBottom: "10px" }}>
                        {isMdUp && (
                          <Typography className={classes.typo} style={{ marginBottom: "5px" }}>
                            Sub Success Criteria Value:
                          </Typography>
                        )}
                        <DropDown
                          options={subSuccessCriterias}
                          type="subSuccessCriteria"
                          emptyTag="-Select-"
                          setSelectOption={(value) =>
                            handleSubTestCaseChange(index, { target: { name: 'subSuccessCriteria', value } })
                          }
                          value={subTestCase.subSuccessCriteria}
                          isSingle={true}
                        />
                        {subTestCaseErrors[index] && subTestCaseErrors[index].subSuccessCriteriaError && (
                          <Typography className={classes.errorText} align="right">
                            {subTestCaseErrors[index].subSuccessCriteriaError}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                  {["No of Data", "Average"]
                    .includes(subTestCase.successCriteria) && (
                        <>
                          <Grid item xs={12} md={6} style={{ marginBottom: "10px" }}>
                          {isMdUp && (
                            <Typography className={classes.typo} style={{ marginBottom: "5px" }}>
                              Criteria Value:
                            </Typography>
                          )}
                            <InputBase
                              className={classes.formInput}
                              style={{ backgroundColor: white }}
                              type="text"
                              placeholder="Enter Value Here"
                              name="criteriaValue"
                              value={subTestCase.criteriaValue}
                              onChange={(e) => handleSubTestCaseChange(index, e)}
                            />
                            {subTestCaseErrors[index] && subTestCaseErrors[index].criteriaValueError && (
                              <Grid container justifyContent="flex-end" alignItems="center">
                                <Typography className={classes.errorText}>
                                  {subTestCaseErrors[index].criteriaValueError}
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      </>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {["No of Data", "Average"].includes(subTestCase.successCriteria) && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography className={classes.typo} style={{ marginBottom: "5px" }}>
                          Time Duration:
                        </Typography>
                        <InputBase
                          className={classes.formInput}
                          style={{ backgroundColor: "white" }}
                          type="number"
                          name="durationValue"
                          placeholder="Enter Duration"
                          value={subTestCase.durationValue}
                          onChange={(e) => handleSubTestCaseChange(index, e)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={() => handleDurationChange(index, subTestCase.durationValue - 1)}
                              >
                                <DecreaseIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => handleDurationChange(index, subTestCase.durationValue + 1)}
                              >
                                <IncreaseIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {isMdUp && (
                          <>
                            <Typography className={classes.typo} style={{ marginBottom: "5px" }}>
                              Duration Period
                            </Typography>
                            <DropDown
                              options={shedulePeriod}
                              type="shedulePeriod"
                              emptyTag="-Select-"
                              setSelectOption={(value) =>
                                handleSubTestCaseChange(index, { target: { name: 'shedulePeriod', value } })
                              }
                              value={subTestCase.shedulePeriod}
                              isSingle={true}
                            />
                          </>
                        )} 
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={1} container justifyContent="center">
                <IconButton
                  className={classes.deleteButton}
                  onClick={() => handleDeleteSubTestCase(index)}
                  disabled={subTestCases.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

