import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'

interface SelectAModelComponentProps {
    open: boolean;
    handleCloseWindow: any;
  }

export const DescriptionComponent :React.FC<SelectAModelComponentProps> = ({ open,handleCloseWindow }) => {

    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <span>Description</span>
                    <IconButton
                        onClick={handleCloseWindow}
                        style={{ paddingRight: '0px' }}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style = {{color:'#5E5C5C'}}>
                <Typography  style={{padding:'20px 0'}}>
                The root cause analysis model is designed to identify and diagnose issues within solar tracking systems by analyzing SCADA (Supervisory Control and Data Acquisition) solar tracking data. Trained on a comprehensive dataset of operational metrics, the model leverages advanced machine learning algorithms to detect anomalies and correlate them with potential root causes. By processing real-time and historical tracking data, the model outputs insights that help engineers and technicians quickly understand the underlying problems affecting system performance, enabling more efficient troubleshooting and maintenance strategies to optimize solar energy generation.
                </Typography>
            </DialogContent>
        </Dialog>
    )
}
