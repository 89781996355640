import { createTheme, Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import * as React from 'react';
import { Arrow, NonSuccessCriteriaIcon, ReportHistoryIcon } from 'src/components/Icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TextBar } from 'src/components/sub-components/TextBar';
import { formatDateString } from 'src/app/testing-automation/api-helper/apiHelper';
import useStyles from '../maintenance/assets/styles';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { TestSuccessCriteria } from '../maintenance/test-automation/step4/TestSuccessCriteria';
import { TestSummaryReport } from '../maintenance/test-automation/step4/TestSummaryReport';
import { DataTable } from '../maintenance/test-automation/step4/DataTable';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';
import {
    fetchBatchNumbers,
    fetchMainTestCases,
    getContinuousTestCase,
    getContinuousTestSummery,
    getFeasibilityTestSummery,
    getRecentRecord,
    getRootCauseDetails,
    getSuccessCriteria,
} from '../maintenance/test-automation/components/apiHelper';
import { DateTimeSelectorComponent } from '../maintenance/test-automation/components/DateTimeSelector';
import { handleDevicePulseClicked } from '../utils';


const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
})

export const ReportHistoryComponent = () => {
    const classes = useStyles()
    const [selectedStreamingMethod, setSelectedStreamingMethod] = useState('');
    const [selectedTestCaseType, setSelectedTestCaseType] = useState('');
    const [testCaseTypes, setTestCaseTypes] = useState([
        "Production Feasibility Testing", "Continuous Testing"
    ]);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [batchNumbers, setBatchNumbers] = useState([]);
    const [mainTestCasesTitles, setMainTestCasesTitles] = useState([]);
    const [selectedMainTestCaseTitle, setSelectedMainTestCaseTitle] = useState('');
    const [selectedMainTestCaseId, setSelectedMainTestCaseId] = useState('');
    const [subTestCaseTitles, setSubTestCaseTitles] = useState([]);
    const [devices, setDevices] = useState([]);
    const [testData, setTestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [continuousMainTestId, setContinuousMainTestId] = useState("");
    const [selectedFormattedTime, setSelectedFormattedTime] = useState('');
    const [testCaseType, setTestCaseType] = useState('')
    const [mainTestCasesContent, setMainTestCasesContent] = useState([]);
    const [chatbotData, setChatbotData] = useState([])
    const [testCaseInfo, setTestCaseInfo] = useState({})
    const [tableRootCause, setTableRootCause] = useState([])
    const [selectedSubTestCase, setSelectedSubTestCase] = useState('');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedStartDate,setSelectedStartDate] = useState(new Date())
    const [selectedEndtDate,setSelectedEndtDate] = useState(new Date())

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [reqSuccess, setReqSuccess] = useState(false)

    const handleStartDateChange = (e) => {
        const newStartDate = formatDateString(e);
        setSelectedStartDate(e)
        if (newStartDate > endDate) {
            setEndDate("");
            setSelectedEndtDate(new Date())
        }
        setStartDate(newStartDate);
    };

    const handleEndDateChange = (e) => {
        const newEndDate = formatDateString(e);
        setSelectedEndtDate(e)
        if (newEndDate < startDate) {
            setStartDate("");
            setSelectedStartDate(new Date())
        }
        setEndDate(newEndDate);
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}_${month}_${day}_${hours}_${minutes}`;
    }

    useEffect(() => {
        const getMostRecentRecord = async () => {

            // Set default dates on component mount
            const today = new Date();
            const oneDayAgo = new Date(today);
            oneDayAgo.setDate(today.getDate() - 1);
            setStartDate(formatDateString(oneDayAgo));
            setEndDate(formatDateString(today));
            setSelectedEndtDate(today)
            setSelectedStartDate(oneDayAgo)

            try {
                const data = await getRecentRecord();
                setSelectedStreamingMethod(data.dataStreamingMethod === "SENZMATE" ? "SenzMatica" : data.dataStreamingMethod)
                setSelectedBatch(data.batchNumber)
                setSelectedMainTestCaseTitle(data.mainTestTitle)
                setSelectedTestCaseType("Continuous Testing")
                setTestCaseType(data.testCaseType)
                setSelectedMainTestCaseId(data.id)
                let d = {
                    "mainTestTitle": data.mainTestTitle,
                    "subTestCases": data.subTestCases
                }
                setTestCaseInfo(d)
                let subs = []
                data.subTestCases.map((s) => {
                    subs.push(s.subTestTitle);
                })
                setSubTestCaseTitles(subs)
                try {
                    const { mainTestTitles, content, uniqueDevices } = await fetchMainTestCases(data.batchNumber);
                    const uniqueMainTestTitles = [...new Set(mainTestTitles)];

                    setMainTestCasesTitles(uniqueMainTestTitles);
                    setMainTestCasesContent(content);
                    setDevices(uniqueDevices);
                } catch (error) {
                    console.error('Error fetching main test cases:', error);
                }

            } catch (err) {
                console.error('Error fetching batch numbers:', err);
            }
        };
        getMostRecentRecord();
    }, [])

    useEffect(() => {
        const getBatchNumbers = async () => {
            try {
                const batchNumbers = await fetchBatchNumbers();
                setBatchNumbers(batchNumbers);
            } catch (err) {
                console.error('Error fetching batch numbers:', err);
            }
        };
        getBatchNumbers();
    }, []);


    // Get Continuous Test
    useEffect(() => {
        const fetchContinuousTestId = async () => {
            if (selectedTestCaseType === "Continuous Testing") {
                try {
                    const continuousTestId = await getContinuousTestCase(selectedBatch, selectedMainTestCaseTitle);
                    setContinuousMainTestId(continuousTestId);
                } catch (err) {
                    console.error('Error fetching continuous test case ID:', err);
                }
            } else {
                setContinuousMainTestId("");
            }
        };
        fetchContinuousTestId();
    }, [selectedTestCaseType, selectedBatch, selectedMainTestCaseTitle]);



    // Fetch feasibility test summary data
    useEffect(() => {
        const getTestSummaryData = async () => {
            try {
                if (!selectedBatch || !selectedMainTestCaseTitle || !selectedTestCaseType ||
                    selectedTestCaseType === "Continuous Testing") {
                    setTestData([]);
                    return;
                }
                setLoading(true);
                const testSummaryData = await getFeasibilityTestSummery(
                    selectedBatch,
                    selectedMainTestCaseTitle
                );
                setTestData(testSummaryData);
            } catch (err) {
                console.error('Error fetching test summary data:', err);
            } finally {
                setLoading(false);
            }
        };

        getTestSummaryData();
    }, [
        selectedBatch,
        selectedMainTestCaseTitle,
        selectedTestCaseType
    ]);

    // Get continuous test summary data
    useEffect(() => {
        const fetchContinuousTestSummaryData = async () => {
            try {
                if (startDate && endDate && selectedTestCaseType === "Continuous Testing") {
                    setLoading(true);
                    const continuousTestSummaryData = await getContinuousTestSummery(
                        startDate,
                        endDate,
                        continuousMainTestId
                    );
                    setTestData(continuousTestSummaryData);
                    setLoading(false)
                }
            } catch (err) {
                console.error('Error fetching continuous test summary data:', err);
                setLoading(false)
            }
        };
        if (continuousMainTestId && continuousMainTestId !== 'null') {
            fetchContinuousTestSummaryData();
        }
    }, [
        startDate,
        endDate,
        continuousMainTestId,
        selectedTestCaseType
    ]);

    const handleSelectBatchNumber = async (value) => {
        setSelectedBatch(value);
        setLoading(true)
        try {
            const { mainTestTitles, content, uniqueDevices } = await fetchMainTestCases(value);
            const uniqueMainTestTitles = [...new Set(mainTestTitles)];

            setMainTestCasesTitles(uniqueMainTestTitles);
            setMainTestCasesContent(content);
            setDevices(uniqueDevices);
        } catch (error) {
            console.error('Error fetching main test cases:', error);
        } finally {
            // Clear dependent dropdown states
            setTestData([]);
            setSubTestCaseTitles([]);
            setSelectedMainTestCaseTitle('');
            setSelectedSubTestCase('')
            setSelectedTestCaseType('');
            setSelectedFormattedTime('')
            setLoading(false)
        }
    };

    const handleSelectMainTestCase = async (value) => {
        const trimmedValue = value.trim();
        setSelectedMainTestCaseTitle(trimmedValue);

        const selectedMainTestCase = mainTestCasesContent.find(testCase =>
            testCase.mainTestTitle === value
        );
        if (selectedMainTestCase) {
            setSelectedMainTestCaseId(selectedMainTestCase.id);
            const subTestTitles = selectedMainTestCase.subTestCases.map(subTestCase =>
                subTestCase.subTestTitle
            );
            setSubTestCaseTitles(subTestTitles);
        } else {
            setSubTestCaseTitles([]);
        }
        setSelectedSubTestCase('')
        setSelectedTestCaseType('');
        setSelectedFormattedTime('')
    };

    const handleSelectTestingMethod = (value) => {
        setSelectedTestCaseType(value);
        if (typeof value == "string" && value.includes('Continuous')) {
            setTestCaseType('CONTINUOUS')
        } else {
            setTestCaseType('INITIAL')
        }
    };

    useEffect(() => {
        const fetchSuccessCriteria = async () => {
            try {
                if (selectedTestCaseType != '') {
                    const response = await getSuccessCriteria(selectedMainTestCaseId);

                    // Check if the response is valid
                    if (response) {
                        let result = {
                            mainTestTitle: response.mainTestTitle,
                            subTestCases: response.subTestCases
                        };
                        setTestCaseInfo(result)
                    }
                }

            } catch (error) {
                console.error("Error fetching success criteria:", error);
            }
        };

        fetchSuccessCriteria();
    }, [selectedTestCaseType]); // Ensure dependencies include relevant IDs

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{ padding: '25px' }}>
                <Grid item xs={12} md={12}>
                    <Grid className={classes.pageTitle} style={{margin:'0px'}}>Report History</Grid>
                <Grid container className={classes.redirectContainer}>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>                       
                        <Link to={'/TestAutomation'} style={{ paddingLeft: '10px', textDecoration: 'none' }}>
                        <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                            <NonSuccessCriteriaIcon />
                        </IconButton>
                        </Link>
                    </Grid>
                    <Arrow />
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <ReportHistoryIcon />
                        <Typography className={classes.redirectTitle}>Report History</Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.backgroundContainer}>
                    <Grid container className={classes.formContent}>
                        <Grid container spacing={2} style={{ marginBottom: "30px" }}>
                            {/* Batch */}
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.typo}>
                                            Batch: <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropDown
                                            options={batchNumbers.map(batch => batch)}
                                            type='batchNumber'
                                            emptyTag='-Select-'
                                            setSelectOption={handleSelectBatchNumber}
                                            value={selectedBatch}
                                            // onBatchDropClick={onBatchDropClick}
                                            isSingle={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Main Test Case Name */}
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.typo}>
                                            Main Test Case Name: <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropDown
                                            options={mainTestCasesTitles}
                                            type='mainTestCase'
                                            emptyTag='-Select-'
                                            setSelectOption={handleSelectMainTestCase}
                                            value={selectedMainTestCaseTitle}
                                            isSingle={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Testing Methods */}
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.typo}>
                                            Testing Methods: <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropDown
                                            options={testCaseTypes}
                                            type='testingMethod'
                                            emptyTag='-Select-'
                                            setSelectOption={handleSelectTestingMethod}
                                            value={selectedTestCaseType}
                                            isSingle
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Date Range */}
                        {selectedTestCaseType === "Continuous Testing" && continuousMainTestId && (
                            <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px"}}>
                                <Grid item xs={12} md={4} className={classes.dateTime} >
                                    <DateTimeSelectorComponent selectedDate={selectedStartDate} handleDateChange={handleStartDateChange}/>
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.dateTime}>
                                    <DateTimeSelectorComponent selectedDate={selectedEndtDate} handleDateChange={handleEndDateChange}/>
                                </Grid>
                            </Grid>
                        )}

                        {/* Test Success Criteria and Summary Report */}
                        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                            <Grid item xs={12} md={12} className={classes.testContainer}>
                                <Grid item xs={12} md={6} className={classes.testSubContainer}>
                                    <TestSuccessCriteria
                                        testData={testData}
                                        selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                        selectedMainTestCaseId={selectedMainTestCaseId}
                                        subTestCaseTitles={subTestCaseTitles}
                                        setSelectedSubTestCase={setSelectedSubTestCase}
                                        selectedSubTestCase={selectedSubTestCase}
                                        selectedTestCaseType={selectedTestCaseType}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.testSubContainer}>
                                    <TestSummaryReport
                                        testData={testData}
                                        selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                        selectedMainTestCaseId={selectedMainTestCaseId}
                                        selectedTestCaseType={selectedTestCaseType}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Data Table */}
                        <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                            <Grid item xs={12} md={12}>
                                <DataTable
                                    selectedMainTestCaseTitle={selectedMainTestCaseTitle}
                                    testData={testData}
                                    selectedTestCaseType={selectedTestCaseType}
                                    testCaseType={testCaseType}
                                    loadingClose={() => {
                                        setLoading(false);
                                    }}
                                    selectedMainTestCaseId={selectedMainTestCaseId}
                                    step="step7"
                                    setChatbotData={setChatbotData}
                                    tableRootCause={tableRootCause[0] !== undefined && tableRootCause[0]["rootCauseResults"][formatDate(selectedFormattedTime)]}
                                    selectedFormattedTime={formatDate(selectedFormattedTime)}
                                    continuousMainTestId={continuousMainTestId}
                                    selectedBatch={selectedBatch}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Loader loading={loading} />
                </Grid>
            </Grid>
            <ToasterComponent
                toaster={isToasterOpen}
                message={toasterMessage}
                reqSuccess={reqSuccess}
            />
        </ThemeProvider>
    )
}