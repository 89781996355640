import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, Grid, Typography} from "@material-ui/core"
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Bar} from 'react-chartjs-2';
import {dataRestructure} from '../data-table/dataTable';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "20px",
    padding: "20px",
    borderRadius: "20px"
  },
}));

export function TestSummaryReport(props) {
  const classes = useStyles()

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'SUCCESS',
        data: [0, 0],
        backgroundColor: '#2A7CED',
      },
      {
        label: 'OFFLINE',
        data: [0, 0],
        backgroundColor: '#D1D7DA',
      },
    ],
  })

  useEffect(() => {
    if (props.mainTestTitle !== "") {
      let structuredData = dataRestructure(props.summary, props.mainCase);

      let subTestCases = []
      let success = []
      let failed = []


      if (structuredData.length > 0 && structuredData[0] !== null && structuredData[0] !== undefined) {
        let data = structuredData[0]
        const result = [];
        for (const device in data) {
          for (const testCase in data[device]) {
            for (const testType in data[device][testCase]) {
              const tests = data[device][testCase][testType];
              for (const test of tests) {
                result.push(test);
              }
            }
          }
        }

        result.map((obj) => {
          if (!subTestCases.includes(obj.subTestTitle)) {
            subTestCases.push(obj.subTestTitle)
          }
        })
        subTestCases.map((s) => {
          success.push(0)
          failed.push(0)
        })

        result.map((r) => {
          if (r.testResult === "SUCCESS") {
            let subTestIndex = subTestCases.indexOf(r.subTestTitle)
            success[subTestIndex] = success[subTestIndex] + 1
          }
          if (r.testResult === "FAILURE") {
            let subTestIndex = subTestCases.indexOf(r.subTestTitle)
            failed[subTestIndex] = failed[subTestIndex] + 1
          }
        })
      }

      setData(
        {
          labels: subTestCases,
          datasets: [
            {
              label: 'SUCCESS',
              data: success,
              backgroundColor: '#2A7CED',
            },
            {
              label: 'OFFLINE',
              data: failed,
              backgroundColor: '#D1D7DA',
            },
          ],
        }
      )
    }

  }, [props.mainCase, props.summary])


  const isEmptyData =
    data.labels.length === 0 &&
    data.datasets.every((dataset) =>
      dataset.data.every((value) => value === 0)
    );
  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant='h6'>TEST SUMMARY REPORT</Typography>
          </Grid>
          <Grid item xs={12} md={12} xl={9} style={{marginTop: "20px", marginBottom: "20px"}}>
            {isEmptyData ? (
              <Typography variant="body1">No data available</Typography>
            ) : (
              <Bar data={data} options={{
                maintainAspectRatio: true,
                responsive: true,
                legend: {
                  display: false
                },
                scales: {
                  xAxes: [{
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Test categories '
                    },
                    gridLines: {
                      display: false,
                    },
                  }],
                  yAxes: [{
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Number of Device'
                    },
                    ticks: {
                      beginAtZero: true,
                      precision: 0
                    },
                  }]
                },
                plugins: {
                  labels: {
                    render: () => {
                    }
                  },
                },
              }}/>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
