import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as React from 'react';
import {SideNavService} from 'src/app/layouts/side-menu/sidenav/sidenav.service';
import * as ReactDOM from 'react-dom';
import {SetupLayout} from './setup-layout';
import { Router } from '@angular/router';
import Cookies from "js-cookie";

@Component({
  selector: 'app-my-component',
  template: `
    <div [id]="rootId" style="transition: 0.6s" [ngClass]="isSideNavVisible()?'side-menu-padding':'side-menu-padding-zero'"></div>`,
  styleUrls: ['../../components/react.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupSenzMaticaComponentWrapper implements OnChanges, OnDestroy, AfterViewInit,OnInit {
  userId = Cookies.get('USER_ID')
  private authChannel = new BroadcastChannel('auth-channel');

  constructor(private sideNavService: SideNavService,private router:Router) {
  }

  ngOnInit(): void {
    if(!this.userId){
      this.router.navigate(['/login'])
      return
    }
    this.sideNavService.isSideNavVisible = true; 
    localStorage.setItem('IS_SIDE_NAV_VISIBLE', 'true'); 

    this.authChannel.onmessage = (event) => {
      if (event.data.type === 'logout') {
        this.router.navigate(['/login']);
      }
    };
  }

  public rootId = "rootId"

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.authChannel.close();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  private render() {
    if(this.userId){
      ReactDOM.render(React.createElement(SetupLayout), document.getElementById(this.rootId))
    }
  }
}

export {SetupLayout};

