import * as React from 'react';
import {useEffect, useState,useRef} from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import {primaryBlue, primaryGray} from 'src/components/colors';
import {CloseIcon, CloseIconColor} from '../../../components/Icons';
import axios from "axios";
import {toast} from "react-toastify";
import {environment} from 'src/environments/environment';
import Cookies from "js-cookie";

export function TestCodec(props) {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [errors, setErrors] = useState({
    inputError: null
  })

  const closePopup = () => {
    props.onclose()
    setIsResponseReceived(false);
    setInput('');
    setOutput('');
    setErrors(prevError => ({
        ...prevError,
        inputError: null
      }))
  }

  const testCodec = () => {
    if (input.trim() === '') {
        setErrors(prevError => ({
          ...prevError,
          inputError: 'Input is required'
        }));
        return;
    }
  
    setLoading(true);
    setIsResponseReceived(false);
    setOutput(''); 
    axios.post(`${environment.host}/core/codec/${props.codecId}/test`, input,
        {
            headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")},
        })
        
      .then((res) => {
        setLoading(false);
        setOutput(res.data.content);
        setIsResponseReceived(true);
      })
      .catch((err) => {
        setLoading(false); 
        setOutput(err.response.data.message);
        setIsResponseReceived(true);
      });
  };


  return (
    <div>
      <Dialog open={props.open} maxWidth="md" style={{ height: '300px' }} classes={{ paperScrollPaper: classes.dialogBox }}>
  <Grid container>
    <Grid item xs={10} md={10}>
      <DialogTitle>Test Codec</DialogTitle>
    </Grid>
    <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
      <IconButton onClick={() => closePopup()} style={{ paddingTop: "20px" }}>
        <CloseIconColor />
      </IconButton>
    </Grid>
  </Grid>

  <DialogContent style={{ margin: "0", height: '100px' }}>
    <Grid container>
      <Grid item xs={12}>
        <Grid style={{ textAlign: "left", paddingBottom: "20px", margin: "20px 0 0 0", overflow: 'hidden' }}>
          <Typography className={classes.label}>
            Input String: <span style={{ color: 'red' }}>*</span>
          </Typography>

          <Grid container alignItems="center">
            <Grid item xs>
              <input
                className={classes.input}
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  setErrors(prevError => ({ ...prevError, inputError: null }));
                }}
                placeholder='Enter the input string'
                style={{ width: '680px', color: '#464646', fontSize: '16px', fontWeight: '300', overflow: 'auto' }}
              />
              {errors.inputError && (
                <FormHelperText className={classes.errorText} error>
                  {errors.inputError}
                </FormHelperText>
              )}
            </Grid>
            {isResponseReceived && (
              <Grid item>
                <Button variant='contained' className={classes.test} style={{marginLeft: '10px', marginTop:'8px'}} onClick={testCodec}>
                  Retest
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isResponseReceived && (
          <Grid style={{ textAlign: "left", paddingBottom: "20px", margin: "20px 0 0 0", overflow: 'hidden' }}>
            <Typography className={classes.label}>output:</Typography>
            <input
              className={classes.input}
              value={output}
              style={{ width: '680px', color: '#464646', fontSize: '16px', fontWeight: '300', overflow: 'auto' }}
              readOnly
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  </DialogContent>

  <DialogActions style={{ width: "100%" }}>
    <Button variant='contained' className={classes.cancel} onClick={() => closePopup()}>
      Cancel
    </Button>
    <Button variant='contained' className={classes.test} onClick={isResponseReceived ? closePopup : testCodec}>
      {isResponseReceived ? 'Done' : 'Test'}
    </Button>
  </DialogActions>
</Dialog>


      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>

      }
    </div>
  )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    test: {
      backgroundColor: primaryBlue,
      color: "white",
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    cancel: {
      backgroundColor: "white",
      color: primaryGray,
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    label: {
      display: 'flex',
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
      textAlign: "left"
    },
    input: {
      minWidth: "100%",
      maxWidth: "100%",
      height: "44px",
      backgroundColor: "white",
      width: "717px",
      border: "solid 1px #C7C4C4",
      color: '#707070',
      paddingLeft: '8px',
      borderRadius: '4px',
      "&:after": {
        borderBottom: 'solid 1px #C7C4C4'
      }
    },
    errorText: {
        color: "red",
        display: "flex",
        justifyContent: "flex-end",
    },
    dialogBox: {
      height: '610px',
      maxHeight: '100%'
    }
  }),
);
