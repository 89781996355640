import {
  Breadcrumbs,
  Button,
  createStyles,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  makeStyles,
  NativeSelect,
  Paper,
  Snackbar,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import * as React from "react";
import {useEffect, useState} from "react";
import {primaryBlue, primaryGray, searchBarBorderColor,} from "src/components/colors";
import {TextBar} from "src/components/sub-components/TextBar";
import {environment} from "src/environments/environment";
import {Arrow, ExpandIconTwo, HomeIcon, RemoteConfig, TechnologyFlow, TestedDevice, UploadFile} from "../../../components/Icons";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dropzone from "react-dropzone";
import BrowseButton from "src/components/sub-components/BrowseButton";
import axios from "axios";

export function EditProduct(props) {
  const classes = useStyles();

  const [allVersionData, setAllVersionData] = useState([]);
  const [defaultProductName, setDefaultProductName] = useState("");
  const [defaultCategory, setDefaultCategory] = useState("");
  const [defaultVersion, setDefaultVersion] = useState("");
  const [defaultBin, setDefaultBin] = useState("");
  const [flowchart, setFlowchart] = useState("");
  const [allVersions, setAllVersions] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [devices, setDevices] = useState({
    id: "pending",
  });
  const [percentage, setPercentage] = useState(0);
  const [fraction, setFraction] = useState("");
  const [status, setStatus] = useState("approve");
  const [versionApproved, setVersionApproved] = useState(false);
  const [approvedBy, setApprovedBy] = useState(null);

  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState("");

  const [remoteConfigVisibility, setRemoteConfigVisibility] = useState(false);
  const [flowChartVisibility, setFlowChartVisibility] = useState(false);
  const [testedVisibility, setTestedVisibility] = useState(false);

  const [parameterVisibility, setParameterVisibility] = useState(-1);
  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);
  const [message, setMessage] = useState("");
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [successDevices, setSuccessDevices] = useState([]);
  const [binFileName, setBinFileName] = useState("");
  const [selectedBin, setSelectedBin] = useState("");

  const showParameters = (i) => {
    setParameterVisibility(i);
  };

  const changeVersion = (e) => {
    setParameterVisibility(-1);
    setDefaultVersion(e);
    let parm, bin, fileName, flow, devices, approval, approvedBy;
    let numerator = 0;
    let denominator = 0;
    allVersionData
      .filter((obj) => obj.versionNum === e)
      .map((objc) => {
        parm = objc.remoteConfigurations;
        bin = objc.binURL;
        fileName = objc.fileName;
        flow = objc.flowChartURL;
        devices = objc.deviceTestResults;
        approval = objc.status;
        approvedBy = objc.statusChangedBy ? objc.statusChangedBy : null;


        setSuccessDevices([]);
        Object.keys(objc.deviceTestResults).map((d) => {
          if (objc.deviceTestResults[d] === "SUCCESS") {
            setSuccessDevices((prevSuccessDevices) => {
              const uniqueDevices = new Set(prevSuccessDevices);
              uniqueDevices.add(d);
              return [...uniqueDevices];
            });
            numerator = numerator + 1;
          }
          denominator += 1;
        });
      });
    setVersionApproved(approval === "NOT_APPROVED" ? false : true);
    setParameters(parm);
    setApprovedBy(approvedBy);

    setDefaultBin(bin);
    setFlowchart(flow);
    setDevices(devices);
    setPercentage((numerator / denominator) * 100);
    setFraction(`${numerator}/${denominator}`);
  };

  const updateTextInputs = (index, key, value) => {
    setStatus("update");
    let newParm = [...parameters];
    newParm[index][key] = value;
    setParameters(newParm);
  };

  const updateDefaultValues = (index, newValue, oldValue) => {
    setStatus("update");
    let newParm = [...parameters];
    const oldValues = newParm[index]["defaultValues"];

    const updatedDefaultValues = oldValues.map((value) =>
      value === oldValue ? newValue : value
    );

    newParm[index]["defaultValues"] = updatedDefaultValues;
    setParameters(newParm);
  };

  const addDefaultValue = (index) => {
    setStatus("update");
    let newParm = [...parameters];
    const defaultValues = newParm[index]["defaultValues"];
    defaultValues.push("");
    newParm[index]["defaultValues"] = defaultValues;
    setParameters(newParm);
  };

  const removeDefaultValue = (index, valueToRemove) => {
    setStatus("update");
    let newParm = [...parameters];
    const defaultValues = newParm[index]["defaultValues"];
    const updatedDefaultValues = defaultValues.filter(
      (value) => value !== valueToRemove
    );
    newParm[index]["defaultValues"] = updatedDefaultValues;
    setParameters(newParm);
  };

  const readBin = (file) => {
    setStatus("update");
    setBinFileName(file[0].name);
    setSelectedBin(file[0]);
  };

  const sendPutRequest = async (url, body, successMessage) => {
      await axios.put(url, body,{
        headers: {
          "Content-Type": body instanceof FormData ? "multipart/form-data" : "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setReqSuccess(true);
        setOpen(true);
        setMessage(successMessage);

        setTimeout(() => {
          setOpen(false);
          setStatus("approve");
        }, 6000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const reject = () => {
    setStatus("reject");

    const finalData = {
      productId: productId,
      productType: defaultProductName,
      deviceCategory: defaultCategory,
      version: {
        versionNum: defaultVersion,
        devices: successDevices,
      },
    };

    const url = `${environment.host}/core/product/version/reject`;
    const successMessage = "Rejected Version Successfully";

    sendPutRequest(url, JSON.stringify(finalData), successMessage);
  };

  const retestDevice = () => {
    setStatus("retest");

    const finalData = {
      versionNum: defaultVersion,
    };

    const formData = new FormData();

    if (selectedBin !== "" && binFileName !== "") {
      if (!finalData.hasOwnProperty("fileName")) {
        finalData["fileName"] = binFileName;
      }
      formData.append("binFile", selectedBin);
    }

    formData.append("version", JSON.stringify(finalData));

    const url = `${environment.host}/core/product/${productId}/user/${localStorage.getItem("USERNAME")}`;
    const successMessage = "Retest Triggered Successfully";

    sendPutRequest(url, formData, successMessage);
  };

  const updateOrApprove = async () => {
    const finalData = {
      versionNum: defaultVersion,
      remoteConfigurations: parameters,
    };

    const formData = new FormData();

    if (selectedBin !== "" && binFileName !== "") {
      if (!finalData.hasOwnProperty("fileName")) {
        finalData["fileName"] = binFileName;
      }
      formData.append("binFile", selectedBin);
    }

    formData.append("version", JSON.stringify(finalData));

    if (status === "update") {
      const url = `${environment.host}/core/product/${productId}/user/${localStorage.getItem("USERNAME")}`;
      const successMessage = "Update Triggered Successfully";
      sendPutRequest(url, formData, successMessage);
    }

    if (status === "approve") {
      const url = `${environment.host}/core/product/${productId}/version/${defaultVersion}/status/APPROVED/${localStorage.getItem("USERNAME")}`;
      const successMessage = "Version Approved Successfully";
      sendPutRequest(url, formData, successMessage);
    }
  };


  useEffect(() => {
    setLoading(true);
    axios.get(`${environment.host}/core/product/${props.editProductId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        const verObj = response.data.content.versions.filter(
          (ver) => ver.status === "NOT_APPROVED"
        );
        if (response.data.content !== undefined) {
          if (props.type === "edit") {
            setProductId(response.data.content.id);
            setDefaultProductName(response.data.content.productName);
            setDefaultCategory(response.data.content.deviceCategory);

            setDefaultVersion(verObj[0].versionNum);
            setDefaultBin(verObj[0].binURL);
            setFlowchart(verObj[0].flowChartURL);
            setParameters(verObj[0].remoteConfigurations);
            setAllVersionData(verObj);
            setDevices(verObj[0].deviceTestResults);
            setVersionApproved(
              verObj[0].status === "NOT_APPROVED" ? false : true
            );
            setApprovedBy(verObj[0].statusChangedBy ? verObj[0].statusChangedBy : null);

            let numerator = 0;
            let denominator = 0;
            Object.keys(verObj[0].deviceTestResults).map((d) => {
              if (verObj[0].deviceTestResults[d] === "SUCCESS") {
                numerator = numerator + 1;
                setSuccessDevices((prevSuccessDevices) => {
                  const uniqueDevices = new Set(prevSuccessDevices);
                  uniqueDevices.add(d);
                  return [...uniqueDevices];
                });
              }
              denominator += 1;
            });
            setPercentage((numerator / denominator) * 100);
            setFraction(`${numerator}/${denominator}`);

            let versions = [];
            verObj.map((obj) => {
              versions.push(obj.versionNum);
            });
            setAllVersions(versions);

            setLoading(false);
          }
          if (props.type === "view") {
            setProductId(response.data.content.id);
            setDefaultProductName(response.data.content.productType);
            setDefaultCategory(response.data.content.deviceCategory);
            setDefaultVersion(response.data.content.versions[0].versionNum);
            setDefaultBin(response.data.content.versions[0].binURL);
            setFlowchart(response.data.content.versions[0].flowChartURL);
            setParameters(response.data.content.versions[0].remoteConfigurations);
            setAllVersionData(response.data.content.versions);
            setDevices(response.data.content.versions[0].deviceTestResults);
            setVersionApproved(
              response.data.content.versions[0].status === "NOT_APPROVED" ? false : true
            );
            setApprovedBy(verObj[0].statusChangedBy ? verObj[0].statusChangedBy : null);

            let numerator = 0;
            let denominator = 0;
            Object.keys(response.data.content.versions[0].deviceTestResults).map((d) => {
              if (response.data.content.versions[0].deviceTestResults[d] === "SUCCESS") {
                numerator = numerator + 1;
              } else {
                denominator = denominator + 1;
              }
            });
            setPercentage((numerator / (denominator + numerator)) * 100);
            setFraction(`${numerator}/${denominator + numerator}`);

            let versions = [];
            response.data.content.versions.map((obj) => {
              versions.push(obj.versionNum);
            });
            setAllVersions(versions);

            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Grid container>
        <Grid container
           justifyContent="flex-start" 
           style={{ padding: "20px 20px 0px ", cursor: "pointer", width: "auto", marginLeft:'0px'}}
          onClick={props.onClose}
        >
          <Paper elevation={0} className={classes.breadcrumbs} style={{ padding: '10px', display: 'inline-block' }}>
            <Breadcrumbs separator={<Arrow/>} style={{ width: 'auto', display: 'inline-flex' }}>
              <HomeIcon/>
              <Typography
                display="inline"
                style={{fontSize: "14px", color: "#003847"}}
              >
                Product Type
              </Typography>
              <Typography
                display="inline"
                style={{fontSize: "14px", color: "#003847"}}
              >
                {props.setEditProductName}
              </Typography>
            </Breadcrumbs>
          </Paper>
        </Grid>

        {loading === false ? (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: "left",
              padding: "20px",
              paddingLeft: "25px",
            }}
          >
            <Paper elevation={0} style={{padding:'20px'}}>
              {props.type === "edit" ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.firmware}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Edit Firmware
                    </Typography>
                    <Divider style={{width: "100%", marginBottom: "10px",backgroundColor:"rgba(0, 0, 0, 0.05)"}}/>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                      <Typography style={{ marginBottom: "8px", color: "#1C1C1C" }}>
                        Product Type
                      </Typography>
                      <TextBar
                        error={{ error: false }}
                        defaultValue={defaultProductName}
                        disabled={true}
                      />
                    </div>
                  </Grid>

                  {/* Device Category */}
                  <Grid item xs={12} md={4}>
                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                      <Typography style={{ marginBottom: "8px", color: "#1C1C1C" }}>
                        Device Category
                      </Typography>
                      <TextBar
                        error={{ error: false }}
                        defaultValue={defaultCategory}
                        disabled={true}
                      />
                    </div>
                  </Grid>

                  {/* Version */}
                  <Grid item xs={12} md={4}>
                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                      <Typography style={{ marginBottom: "8px", color: "#1C1C1C" }}>
                        Version
                      </Typography>
                      <FormControl style={{ display: "inline" }}>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={defaultVersion}
                          className={classes.input}
                          onChange={(e) => changeVersion(e.target.value)}
                          input={<BootstrapInput />}
                        >
                          <option value="" disabled>
                            Select version number
                          </option>
                          {allVersions.map((v, i) => (
                            <option value={v} key={i}>
                              {v}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </Grid>

                  {/* Bin File */}
                  <Grid item xs={12} md={4}>
                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                      <Typography style={{ marginBottom: "8px", color: "#1C1C1C" }}>
                        Bin File
                      </Typography>
                      <TextBar
                        error={{error: false}}
                        defaultValue={defaultBin}
                        disabled={true}
                      />
                    </div>
                  </Grid>

                  {/* Upload Section */}
                    <Grid item xs={12} md={12}>
                      <Dropzone
                        onDrop={(acceptedFiles) => readBin(acceptedFiles)}
                      >
                        {({getRootProps, getInputProps}) => (
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                display: "flex",                    
                                flexDirection: "column",            
                                justifyContent: "center",           
                                alignItems: "center",               
                                border: "1px dashed rgba(117, 117, 117, 0.5)",
                                backgroundColor: "#EDEDED",
                                borderRadius: "8px",
                                height: "150px",
                                padding: "40px",
                              }}
                            >
                              <input {...getInputProps()} />
                              <UploadFile/>
                              <Typography style={{ textAlign: "center" }}> 
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: primaryGray,
                                  }}
                                >
                                  {binFileName === ""
                                    ? "Drag & Drop file here to upload or"
                                    : binFileName}
                                </span>
                                {binFileName !== "" && (
                                  <button
                                    style={{
                                      border: "1px solid #ccc",
                                      background: primaryBlue,
                                      padding: "5px 10px",
                                      margin: "5px",
                                      fontSize: "15px",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setBinFileName("");
                                      setSelectedBin("");
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                                <BrowseButton
                                  isFileSelected={binFileName !== ""}
                                />
                              </Typography>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.firmware}
                      style={{fontSize: "16px", fontWeight: 'bold'}}
                    >
                      View Firmware
                    </Typography>
                    <Divider style={{width: "100%", marginBottom: "10px",backgroundColor:"rgba(0, 0, 0, 0.05)"}}/>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Product Type
                    </Typography>
                    <br/>
                    <br/>
                    <Typography
                      display="inline"
                      style={{
                        margin: "20px",
                        color: "#8691A2",
                        marginTop: "20px",
                      }}
                    >
                      {defaultProductName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Device Category
                    </Typography>
                    <br/>
                    <br/>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#8691A2"}}
                    >
                      {defaultCategory}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Bin File
                    </Typography>
                    <br/>
                    <br/>
                    <Tooltip title={defaultBin}>
                      <Typography
                        display="inline"
                        style={{margin: "20px", color: "#8691A2"}}
                      >
                        {defaultBin !== null &&
                          defaultBin.substring(0, 10) + "..."}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{color: "#1C1C1C" , marginLeft:"34px"}}
                    >
                      Version
                    </Typography>
                    <br/>
                    <br/>
                    <FormControl>
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={defaultVersion}
                        className={classes.input}
                        onChange={(e) => changeVersion(e.target.value)}
                        input={<BootstrapInput/>}
                        style={{marginLeft:"30px"}}
                      >
                        <option value="" disabled>
                          Select version number
                        </option>
                        {allVersions.map((v, i) => (
                          <option value={v} key={i}>
                            {v}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Paper elevation={1} style={{margin: "20px 0px",  boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.2)" }}>
                <Grid container style={{ margin:"0"}}>
                <Grid item xs={1} md={1} style={{textAlign: "left", padding: "20px" }}>
                    <div className={classes.iconDivRed}>
                      <RemoteConfig/>
                    </div>
                  </Grid>
                  <Grid item xs={10} md={8} style={{ padding: "18px 0px" }}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#324054",
                        paddingTop: "6px",
                        fontWeight:"bold",
                        padding:"10px 0px 0px 0",
                      }}
                    >
                      Remote Configuration
                    </Typography>
                  </Grid>
                 <Grid item xs={1} md={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "16px" }}>
                    <IconButton
                      onClick={() =>
                        setRemoteConfigVisibility(!remoteConfigVisibility)
                      }
                       style={{ marginLeft: 'auto' }} 
                    >
                      <div className={classes.subIconButtonDiv}>
                        <ExpandIconTwo rotation={remoteConfigVisibility}/>
                      </div>
                    </IconButton>
                  </Grid>

                  {remoteConfigVisibility === true && (
                    <Grid container>
                      <Divider
                        style={{width: "100%", marginBottom: "20px",backgroundColor:"rgba(0, 0, 0, 0.05)"}}
                      />
                      {parameters.length === 0 && (
                        <h5>No configuration parameters added.</h5>
                      )}
                      {parameters.map((parameter, i) => (
                        <Hidden key={i}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              paddingLeft: "30px",
                              paddingBottom: "20px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                onClick={() => showParameters(i)}
                                style={{cursor: "pointer"}}
                              >
                                <Typography style={{fontWeight: "bold"}}>
                                  {parameter.parameter}
                                </Typography>
                              </Grid>
                              {parameterVisibility === i ? (
                                <Grid
                                  item
                                  xs={6}
                                  md={4}
                                  style={{textAlign: "left"}}
                                ></Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={6}
                                  md={4}
                                  style={{textAlign: "left"}}
                                >
                                  <TextBar
                                    disabled={props.type === "view"}
                                    error={{error: false}}
                                    defaultValue={parameter.defaultValue}
                                    onChange={(e) =>
                                      updateTextInputs(
                                        i,
                                        "defaultValue",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                              <Grid item md={4}></Grid>
                            </Grid>
                          </Grid>

                          {parameterVisibility === i &&
                            props.type === "edit" && (
                              <Grid
                                container
                                style={{
                                  backgroundColor: "#F9F9F9",
                                  padding: "20px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Id
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextBar
                                        disabled={versionApproved}
                                        error={{error: false}}
                                        defaultValue={parameter.id}
                                        onChange={(e) =>
                                          updateTextInputs(
                                            i,
                                            "id",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Input Type
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormControl>
                                        <NativeSelect
                                          disabled={versionApproved}
                                          id="demo-customized-select-native"
                                          value={parameters[i].inputType}
                                          style={{width: "100%"}}
                                          onChange={(e) =>
                                            updateTextInputs(
                                              i,
                                              "inputType",
                                              e.target.value
                                            )
                                          }
                                          input={<BootstrapInput/>}
                                        >
                                          <option value="" disabled>
                                            Select input type
                                          </option>
                                          <option value="text">Text</option>
                                          <option value="json">Json</option>
                                          <option value="Html">Html</option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Input Format
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormControl>
                                        <NativeSelect
                                          disabled={versionApproved}
                                          id="demo-customized-select-native"
                                          value={parameter.format}
                                          style={{width: "100%"}}
                                          onChange={(e) =>
                                            updateTextInputs(
                                              i,
                                              "format",
                                              e.target.value
                                            )
                                          }
                                          input={<BootstrapInput/>}
                                        >
                                          <option value="" disabled>
                                            Select format type
                                          </option>
                                          <option value="String">string</option>
                                          <option value="int">int</option>
                                          <option value="double">double</option>
                                          <option value="float">float</option>
                                          <option value="char">char</option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Default
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextBar
                                        disabled={versionApproved}
                                        error={{error: false}}
                                        defaultValue={parameter.defaultValue}
                                        onChange={(e) =>
                                          updateTextInputs(
                                            i,
                                            "defaultValue",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {parameter.defaultValues && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    style={{
                                      marginTop: "20px",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} md={12}>
                                        <Typography
                                          display="inline"
                                          style={{
                                            margin: "20px",
                                            color: "#63738C",
                                          }}
                                        >
                                          Default Values
                                        </Typography>
                                        <IconButton
                                          style={{marginLeft: "10px"}}
                                          onClick={() => addDefaultValue(i)}
                                        >
                                          <AddCircleOutlineIcon
                                            style={{
                                              fontSize: "24px",
                                              color: "blue",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                      {parameter.defaultValues.map(
                                        (value, index) => (
                                          <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            key={index}
                                            style={{marginBottom: "10px"}}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <TextBar
                                                disabled={versionApproved}
                                                error={{error: false}}
                                                defaultValue={value}
                                                onChange={(e) =>
                                                  updateDefaultValues(
                                                    i,
                                                    e.target.value,
                                                    value
                                                  )
                                                }
                                              />
                                              <IconButton
                                                style={{marginLeft: "10px"}}
                                                onClick={() =>
                                                  removeDefaultValue(i, value)
                                                }
                                              >
                                                <RemoveCircleOutlineIcon
                                                  style={{
                                                    fontSize: "24px",
                                                    color: "red",
                                                  }}
                                                />
                                              </IconButton>
                                            </div>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          {parameterVisibility === i &&
                            props.type === "view" && (
                              <Grid
                                container
                                style={{
                                  backgroundColor: "#F9F9F9",
                                  padding: "20px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Id
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameter.id}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Input Type
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameters[i].inputType}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Input Format
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameter.format}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Default
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameter.defaultValue}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                        </Hidden>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Paper elevation={1} style={{margin: "20px 0px 20px 0px", boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.2)"}}>
                <Grid container style={{margin: "0"}}>
                  <Grid item xs={1} md={1} style={{textAlign: "left",padding:"20px"}}>
                    <div className={classes.iconDivGreen}>
                      <TechnologyFlow/>
                    </div>
                  </Grid>
                  <Grid item xs={10} md={8} style={{padding: "18px 0px"}}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#324054",
                        paddingTop: "6px",
                        fontWeight:"bold",
                        padding:"10px 0px 0px 0",
                      }}
                    >
                      Technology Flowchart
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={1} md={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "16px" }}>
                    <IconButton
                      onClick={() =>
                        setFlowChartVisibility(!flowChartVisibility)
                      }
                    >
                      <div className={classes.subIconButtonDiv}>
                        <ExpandIconTwo rotation={flowChartVisibility}/>
                      </div>
                    </IconButton>
                  </Grid>
                  {flowChartVisibility === true && (
                    <Grid container>
                      <Divider
                        style={{width: "100%", marginBottom: "20px",backgroundColor:"rgba(0, 0, 0, 0.05)"}}
                      />
                      <h5>
                        {flowchart === "" || flowchart === null ? (
                          "Flow chart not found."
                        ) : (
                          <a
                            href={flowchart}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: "blue", textDecoration: "none"}}
                          >
                            Flow Chart Download Link
                          </a>
                        )}
                      </h5>
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Paper elevation={1} style={{margin: "20px 0px 20px 0px",boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.2)"}}>
                <Grid container style={{margin: "0"}}>
                  <Grid item xs={1} md={1} style={{textAlign: "left",padding:"20px"}}>
                    <div className={classes.iconDivBlue}>
                      <TestedDevice/>
                    </div>
                  </Grid>
                  <Grid item xs={10} md={8} style={{padding: "18px 0px"}}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#324054",
                        paddingTop: "6px",
                        fontWeight:"bold",
                        padding:"10px 0px 0px 0",
                      }}
                    >
                      Tested Device
                    </Typography>
                  </Grid>
                
                  <Grid item xs={1} md={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "16px" }}>
                    <IconButton
                      onClick={() => setTestedVisibility(!testedVisibility)}
                    >
                      <div className={classes.subIconButtonDiv}>
                        <ExpandIconTwo rotation={testedVisibility}/>
                      </div>
                    </IconButton>
                  </Grid>
                  {testedVisibility === true && (
                    <Grid container>
                      <Divider
                        style={{width: "100%", marginBottom: "20px",backgroundColor:"rgba(0, 0, 0, 0.05)"}}
                      />
                      <Grid
                        item
                        xs={12}
                        md={6}
                        hidden={
                          props.type === "view" || versionApproved === true
                            ? true
                            : false
                        }
                        style={{padding: "14px 0px 14px 16px"}}
                      >
                        <Typography
                          style={{
                            color: "#1C1C1C",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          display="inline"
                        >
                          Do you want to retest the failed devices ?
                        </Typography>
                        &nbsp;&nbsp;&nbsp;
                        <div
                          style={{display: "inline", cursor: "pointer"}}
                          onClick={retestDevice}
                        >
                          <span
                            style={{
                              color: "#2A7CED",
                              fontSize: "14px",
                              textDecoration: isMouseOver
                                ? "underline"
                                : "none",
                            }}
                            onMouseEnter={() => setIsMouseOver(true)}
                            onMouseLeave={() => setIsMouseOver(false)}
                          >
                            Retest Here
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} style={{paddingTop: "30px"}}>
                        <Grid container>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            style={{textAlign: "center"}}
                          >
                            <Typography
                              style={{
                                color: "#1C1C1C",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                                marginLeft: "54px",
                                textAlign: "left"
                              }}
                            >
                              ID
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            style={{textAlign: "center"}}
                          >
                            <Typography
                              style={{
                                color: "#1C1C1C",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                                marginRight: "54px",
                              }}
                            >
                              DEVICE NAME
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            style={{textAlign: "center"}}
                          >
                            <Typography
                              style={{
                                color: "#1C1C1C",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                                marginRight: "54px",
                                textAlign: "right"
                              }}
                            >
                              TEST STATUS
                            </Typography>
                          </Grid>
                        </Grid>
                        {Object.keys(devices).map((device, j) => (
                          <Grid container key={j}>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  j % 2 === 0 || j / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                            >
                              <Typography className={classes.text}
                                style={{
                                  margin: "16px 350px 15px 54px",
                                  textAlign:"left"
                                }}
                              >
                                {"0" + (j + 1)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  j % 2 === 0 || j / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                            >
                              <Typography className={classes.text}
                              >
                                {device}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  j % 2 === 0 || j / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                            >
                              <Typography 
                                style={{
                                  textAlign: "right"
                                }}
                              >
                                {devices[device]}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Paper>

              {testedVisibility === true && props.type === "edit" && (
                <Paper elevation={1} style={{boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.2)"}}>
                  <Grid container style={{padding: "20px"}}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{paddingTop: "20px", paddingBottom: "20px"}}
                    >
                      <Typography
                        display="inline"
                        style={{fontSize: "16px", fontWeight: "bold"}}
                      >
                        Test Passed Rate -{" "}
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#2A7CED",
                        }}
                      >
                        {percentage}%
                      </Typography>
                      &nbsp;&nbsp;&nbsp;
                      <Typography
                        display="inline"
                        style={{fontSize: "14px", color: "#707070"}}
                      >{`(${fraction} Devices passed the test)`}</Typography>
                    </Grid>
                    <Grid
                      hidden={versionApproved}
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "right",
                      }}
                    >
                      <Button
                        disabled={successDevices.length == 0}
                        style={{color: "#707070"}}
                        onClick={() => {
                          if (status === "update") {
                            props.onClose();
                          } else {
                            reject();
                          }
                        }}
                      >
                        {status === "update" ? "CANCEL" : "REJECT"}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        disabled={
                          versionApproved ||
                          (status !== "update" && percentage < 80)
                        }
                        style={{
                          backgroundColor: primaryBlue,
                          color: "white",
                        }}
                        onClick={updateOrApprove}
                      >
                        {status === "update" ? "UPDATE" : "APPROVE"}
                      </Button>
                      <br/>
                      <Typography
                        style={{fontSize: "12px", color: "#EE3939"}}
                      >
                        Passed rate should be greater than 80% to approve the
                        version
                      </Typography>
                    </Grid>
                    <Grid
                      hidden={!versionApproved}
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        style={{fontSize: "12px", color: "#EE3939"}}
                      >
                        Version already approved !
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              )}
              {testedVisibility === true && props.type === "view" && (
                <Paper elevation={1} style={{margin: "20px",boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.2)"}}>
                  <Grid container style={{padding: "20px"}}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{fontSize: "16px", fontWeight: "bold"}}
                      >
                        Test Passed Rate -{" "}
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#2A7CED",
                        }}
                      >
                        {percentage}%
                      </Typography>
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        <Typography
                          display="inline"
                          style={{fontSize: "14px", color: "#707070"}}
                        >{`(${fraction} Devices passed the test)`}</Typography>
                        {versionApproved && approvedBy !== null && (
                          <>
                            <Typography
                              display="inline"
                              style={{
                                fontSize: "14px",
                                color: "#707070",
                                fontWeight: "bold",
                              }}
                            >{`, Approved By - ${approvedBy}`}</Typography>
                          </>
                        )}
                      </span>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Paper>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{textAlign: "left"}}>
            {reqSuccess === true ? "Success" : "Error"}
          </h3>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbs: {
    backgroundColor: "white",
  },
  firmware: {
    color: primaryBlue,
    paddingLeft: "1px",
  },
  iconDivRed: {
    width: "40px",
    height: "40px",
    backgroundColor: "#EE393914",
    display: 'flex',
    padding: "10px",
    justifyContent:"center",
    alignItems:"center",
    alignContent:"center",
  },
  iconDivGreen: {
    width: "40px",
    height: "40px",
    backgroundColor: "#03C69F08",
    padding: "10px",
    justifyContent:"center",
    alignItems:"center",
    alignContent:"center",
  },
  iconDivBlue: {
    width: "40px",
    height: "40px",
    backgroundColor: "#2A49AE14",
    padding: "8px",
    justifyContent:"center",
    alignItems:"center",
    alignContent:"center",
  },
  subIconButtonDiv: {
    backgroundColor: "#F5F5F5",
    width: "25px",
    height: "25px",
    borderRadius: "100%",
    paddingTop: "0px",
  },
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  inputs: {
    minWidth: "100%",
    maxWidth: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  text:{
    color: "#3C3C3C",
    fontSize: "14px",
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "54px",
  }
}));


const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      textIndent: "0.5rem",
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 2px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
