import * as React from 'react';
import { Line } from "react-chartjs-2";
import { ChartOptions,ChartData} from 'chart.js';
import { Grid } from '@material-ui/core';


interface GraphDetails {
    x_label: string;
    y_label: string;
    title: string;
    values: string[];
    creation_dates: string[];
}
  
interface Props {
    graph_details: GraphDetails;
}


export const DataChart : React.FC<Props>= ({ graph_details }) => {
    // Prepare data for the chart
    const chartData: ChartData<'line'> = {
      labels: graph_details.creation_dates,
      datasets: [
        {
          label: graph_details.title,
          data: graph_details.values,
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.4)',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
        },
      ],
    };
  
    // Define chart options
    const options: ChartOptions<'line'> = {
      responsive: true,
      scales: {
        xAxes: [{
          scaleLabel: {
              display: true,
              labelString: graph_details.x_label,
          },
        }],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 20
            },
            scaleLabel: {
              display: true,
              labelString: graph_details.y_label,
            },
          },
        ],
      },
      title: {
        display: true,
        text: graph_details.title,
      },
    };
  
    return (
      <Grid container style={{width:graph_details.creation_dates.length > 100 ? '3500px' : 'auto',overflow:'auto'}}>
        <Line data={chartData} options={options} />
      </Grid>
    );
};