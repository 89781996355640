import * as React from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {searchBarBorderColor} from "../colors";
import {SearchIconLarge} from "../Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      height: "1px",
    },
    endAdornment: {
      paddingRight: "0.1em",
      backgroundColor: "yellow",
    },
  })
);

export function SearchBar(props) {
  const classes = useStyles();

  return (
    <CssTextField
      InputLabelProps={{shrink: false}}
      placeholder={props.placeholder || "Search"}
      InputProps={{
        style: {width: "100%", height: "40px", paddingRight: "8px", fontSize: "14px",fontFamily:"poppins"},
        classes: {
          input: classes.inputField,
        },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIconLarge/>
          </InputAdornment>
        ),
      }}
      style={{width: "100%"}}
      onChange={props.onChange}
      variant="outlined"
      value={props.value}
    ></CssTextField>
  );
}


const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: searchBarBorderColor,
      background: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: searchBarBorderColor,
    },
    "& .MuiOutlinedInput-input": {
      backgroundColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: searchBarBorderColor,
      },
      "&:hover fieldset": {
        borderColor: searchBarBorderColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: searchBarBorderColor,
      },
    },
  },
})(TextField);
