import React, {useState} from 'react';
import {Button, Dialog, DialogActions, Grid, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {environment} from '../../../environments/environment';
import axios from 'axios';
import Cookies from "js-cookie";

export function DeletePrivilege(props) {
  const classes = useStyles();

  const removeChild = async (privilege) => {
    console.log(privilege);
    console.log(props.child);

    for (const p of props.child) {
      await deletePrivilege(p.id);
    }

    removePrivilege(privilege);
  };

  const deletePrivilege = async (id) => {
      const response = await axios.delete(`${environment.host}/ums/super/user-privilege/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get("ACCESS_TOKEN")
        }
      })
      .then((response) => {
        props.setAlertOpen(true);
        props.setSeverity("success");
        props.setTopic("Success");
        props.setMessage("Privilege deleted successfully");
        props.onClose();
        props.getPrivileges();
        setTimeout(() => {
          props.setAlertOpen(false);
        }, 4000);
      })
      .catch((error) => {
        console.error('Error deleting privilege:', error);
        props.setAlertOpen(true);
        props.setSeverity("error");
        props.setTopic("Error");
        props.setMessage(error.response.data.message);
        props.onClose();
        setTimeout(() => {
          props.setAlertOpen(false);
        }, 3000);
      });
  };
  const removePrivilege = (privilege) => {
    deletePrivilege(privilege.id)
  }


  return (
    <div>
      <Dialog open={props.open} maxWidth="md" className={classes.dialogOverlay}>
        <Grid item xs={12} md={12} style={{padding: '15px'}}>
          <Typography style={{fontWeight: "500"}}>Do you really want to Delete the Privilege?</Typography>
        </Grid>
        <DialogActions style={{width: "100%", justifyContent: 'right', marginTop: '5px'}}>
          <Button variant='contained' className={classes.cancel} onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button variant='contained' className={classes.delete} onClick={() => {
            removeChild(props.privilege)
          }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    dialogOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      },
      "& .MuiPaper-root": {
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      },
    },

    delete: {
      backgroundColor: "#FF4343",
      color: "white",
      marginBottom: "10px",
      marginRight: "10px",
      width: "30%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    cancel: {
      backgroundColor: "white",
      color: "#707070",
      border: "1px solid #000000",
      marginBottom: "10px",
      marginRight: "10px",
      width: "30%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    }
  }),
);
