import React from 'react';
import {Button, Grid, makeStyles, Theme} from "@material-ui/core";
import {white} from "src/components/colors";
import {LearnMoreIcon} from 'src/components/Icons';

export function LearnMoreComponent(props) {
  const classes = useStyles();
  return (
    <div className={`testing`}>
      <Grid container justifyContent="center">
        <Grid item xs={8} md={9}>
          <Grid item style={{marginTop: "30px"}}>
            <p className={classes.para}>
              Kits act as customizable units representing specific aspects of your environment, facilitating seamless
              integration between the physical and digital realms of your application. Whether you're managing plots of
              land or overseeing cold rooms, kits streamline tasks, enhance productivity, and enable data-driven
              decision-making. They break down the environment into manageable units, allowing for monitoring, control,
              and optimization. Kit models serve as blueprints defining device characteristics, ensuring compatibility
              and consistency across devices. By incorporating kits and kit models, technology seamlessly integrates
              into your environment, empowering efficient resource management and data-driven decision-making.
            </p>

          </Grid>
          <Grid item style={{marginTop: "40px", justifyContent: "center"}}>
            <LearnMoreIcon/>
          </Grid>
          <Grid container justifyContent="flex-end" style={{marginTop: "20px", marginBottom: "10px"}}>
            <Grid item>
              <Button
                className={classes.getStarted}
                style={{textTransform: "none"}}
                onClick={() => props.setIsLearnMoreClick(false)}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: "0.55s",
    textAlign: "left",
    margin: "30px 100px 0px 100px",
  },
  container: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center'
  },
  getStarted: {
    backgroundColor: white,
    color: '#555555',
    boxShadow: "none",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      backgroundColor: '#555555',
      color: white,
      boxShadow: "none",
      border: 'solid 1px #000000'
    },
    border: 'solid 1px #555555',
    width: '100px',
    height: '34px'
  },
  para: {
    fontFamily: "'Poppins'",
    fontWeight: 300
  }
}));


