import { Box, Button, Checkbox, createTheme, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputBase, Radio, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Arrow, CancelIcon, DocumentIcon, EditIcon, FilterIcon, NonSuccessCriteriaIcon, RootCauseIcon, TrashIcon, ViewIcon } from 'src/components/Icons';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Close, Delete, Description, Edit } from '@material-ui/icons';
import useStyles from '../maintenance/assets/styles';
import { DropDown } from '../maintenance/test-automation/components/DropDown';
import { formatSuccessCriteria } from '../utils';
import { DescriptionComponent } from '../maintenance/test-automation/step5/step2/Description';
import { batch } from 'react-redux';
import { SelectAModelComponent } from '../maintenance/test-automation/step5/step2/SelectAModelComponent';
import { getAvailableModels, fetchMainTestCases } from '../maintenance/test-automation/components/apiHelper';
import { Loader } from '../maintenance/test-automation/components/Loader';
import { ToasterComponent } from '../maintenance/test-automation/components/Toaster';

export const RootCauseConfigComponent = () => {
    const theme = createTheme({
        typography: {
          fontFamily: "'Poppins', sans-serif",
        },
    });
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [rootCauses, setRootCauses] = useState([]);
    const [filteredRootCauses, setFilteredRootCauses] = useState([]);
    const [selectedRootCause, setSelectedRootCause] = useState(null);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [formValue, setFormValue] = useState({
        batchNumber: '',
        analysisName: '',
        model: '',
        modelUrl: '',
    });

    //Toaster
    const [isToasterOpen, setIsToasterOpen] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [reqSuccess, setReqSuccess] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterVisible, setFilterVisible] = useState(false);
    const [batchPrefixes, setBatchPrefixes] = useState([]);
    const [selectedPrefix, setSelectedPrefix] = useState(null);
    const [tempSelectedPrefix, setTempSelectedPrefix] = useState(null); 

    const handleEditPopup = (rootCause) => {
        setSelectedRootCause(rootCause);
        setFormValue({
            batchNumber: rootCause.batchNumber || '',
            analysisName: rootCause.analysisName || '',
            model: rootCause.model || '',
            modelUrl: rootCause.modelUrl || '',
        });
        setIsEditPopupOpen(true);
    }

    const cancelEditPopup = () => {
        setIsEditPopupOpen(false);
    }

    const handleViewPopup = (rootCause) =>{
        setSelectedRootCause(rootCause);
        setIsViewPopupOpen(true)
    }

    const cancelViewPopup = () =>{
        setIsViewPopupOpen(false)
    }

    const handleDeletePopup = (rootCause) => {
        setSelectedRootCause(rootCause);
        setIsDeletePopupOpen(true);
    }

    const cancelDeletePopup = () => {
        setIsDeletePopupOpen(false);
    }

    const fetchRootCauses = async () => {
        setLoading(true);
         try {
            const response = await axios.get(`${environment.host}/core/rootCause`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            const data = response.data;
            setRootCauses(data.content);
            setFilteredRootCauses(data.content);
            extractBatchPrefixes(data.content);
        } catch(err) {
            console.log('Error fetching root causes', err);
        } finally {
            setLoading(false);
        }
    };

    const extractBatchPrefixes = (rootCauses: any[]) => {
        const prefixes = Array.from(
            new Set(
                rootCauses
                    .map(rootcause => {
                        if (rootcause.batchNumber && typeof rootcause.batchNumber === 'string') {
                            const match = rootcause.batchNumber.match(/^[A-Za-z]+/);
                            return match ? match[0] : null;
                        }
                        return null;
                    })
                    .filter(prefix => prefix !== null)
            )
        );
        setBatchPrefixes(prefixes);
    };
    

    const updateRootCauses = async (rootCause) => {
        setLoading(true);
        try {
            const response = await axios.put(`${environment.host}/core/rootCause/${selectedRootCause.id}`, rootCause, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            console.log('Root Cause Updated Successfully', response.data);
            setIsToasterOpen(true);
            setToasterMessage('Root Cause Model Updated Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchRootCauses();
        } catch(err) {
            const errorData = err.response.data;
            if(err.response.status === 422){
                setIsToasterOpen(true);
                setReqSuccess(false);
                setToasterMessage(errorData.message);
                setTimeout(() => {
                    setIsToasterOpen(false);
                }, 5000);
            }
            setIsEditPopupOpen(false);
            console.log('Error updating root cause', err);
        } finally {
            setLoading(false);
        }
    };

    const deleteRootCause = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${environment.host}/core/rootCause/${selectedRootCause.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            console.log('Root Cause Deleted Successfully', response.data);
            setIsDeletePopupOpen(false);
            setIsToasterOpen(true);
            setToasterMessage('Root Cause Model Removed Successfully');
            setReqSuccess(true);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 5000);
            await fetchRootCauses();
        } catch(err) {
            console.log('Error deleting root cause', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRootCauses();
    }, []);

    useEffect(() => {
        const filterRootCauses = () => {
            if (!selectedPrefix && !searchTerm) {
                setFilteredRootCauses(rootCauses);
            } else {
                const lowerCaseSearchTerm = searchTerm.toLowerCase();
                const filtered = rootCauses.filter(rootcause => {
                    const matchesPrefix = !selectedPrefix || rootcause.batchNumber.startsWith(selectedPrefix);
                    const matchesSearch = rootcause.batchNumber.toLowerCase().includes(lowerCaseSearchTerm) ||
                        rootcause.analysisName.toLowerCase().includes(lowerCaseSearchTerm);
                    return matchesPrefix && matchesSearch;
                });
                setFilteredRootCauses(filtered);
            }
        };
        filterRootCauses();
    }, [searchTerm, selectedPrefix, rootCauses]);   

    const toggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handlePrefixSelect = (prefix) => {
        if (tempSelectedPrefix === prefix) {
            setTempSelectedPrefix(null);
        } else {
            setTempSelectedPrefix(prefix);
        }
    };

    const applyFilter = () => { 
        setSelectedPrefix(tempSelectedPrefix);
        setFilterVisible(false);
    };
    
    const cancelFilter = () => {
        setTempSelectedPrefix(null);
        setSelectedPrefix(null);
        setFilterVisible(false);
    };

    return (
        <ThemeProvider theme={theme} >
            <Grid container style={{padding:'25px'}}>
                <Grid container style={{display: "flex",margin:'50px 0px',alignItems:'center',backgroundColor:'white',padding:'15px',width:'fit-content'}}>
                    <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                        <NonSuccessCriteriaIcon color={"#324054"}/>
                        <Link to={'/TestAutomation'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                        <Typography style={{color: '#858DAA'}}>Setup Device Pulse</Typography>
                        </Link>
                    </Grid>
                    <Arrow/>
                    <Grid item style={{color:'#2A7BEC',display:'flex',alignItems:'center'}}>
                        <RootCauseIcon />
                        <Typography style={{marginLeft:'10px'}}>Root Cause Models</Typography>
                    </Grid>      
                </Grid>
                <Grid container alignItems="center" style={{ flexWrap: 'nowrap', gap: '15px', marginBottom:'15px' }}>
                    <Grid item className="search-box">
                        <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search"
                        className="search-input"
                        style={{ marginRight: '8px' }} 
                        />
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='search-icon'>
                            <path d="M10.7579 9.99018L8.7483 7.99685C9.52835 7.02424 9.90611 5.78972 9.80391 4.54714C9.7017 3.30455 9.12729 2.14836 8.19879 1.31628C7.27029 0.484206 6.05828 0.0394997 4.81197 0.0736033C3.56565 0.107707 2.37977 0.618028 1.49817 1.49963C0.616563 2.38124 0.106242 3.56712 0.0721384 4.81343C0.0380349 6.05974 0.482741 7.27176 1.31482 8.20025C2.14689 9.12875 3.30309 9.70316 4.54567 9.80537C5.78825 9.90758 7.02277 9.52982 7.99538 8.74976L9.98872 10.7431C10.0391 10.7939 10.099 10.8342 10.165 10.8617C10.231 10.8892 10.3018 10.9033 10.3733 10.9033C10.4448 10.9033 10.5156 10.8892 10.5816 10.8617C10.6476 10.8342 10.7075 10.7939 10.7579 10.7431C10.8555 10.6421 10.9101 10.5071 10.9101 10.3666C10.9101 10.2262 10.8555 10.0912 10.7579 9.99018ZM4.95663 8.74976C4.20671 8.74976 3.47363 8.52739 2.8501 8.11075C2.22656 7.69412 1.74057 7.10194 1.45359 6.40911C1.16661 5.71627 1.09152 4.95389 1.23782 4.21838C1.38412 3.48287 1.74525 2.80726 2.27552 2.27699C2.80579 1.74671 3.48141 1.38559 4.21692 1.23929C4.95243 1.09299 5.71481 1.16807 6.40764 1.45506C7.10048 1.74204 7.69266 2.22802 8.10929 2.85156C8.52592 3.4751 8.7483 4.20818 8.7483 4.9581C8.7483 5.96371 8.34882 6.92814 7.63775 7.63921C6.92667 8.35029 5.96225 8.74976 4.95663 8.74976Z" fill="#7F7C7C"/>
                        </svg>

                    </Grid>
                    <Grid item className="filter-validity" style={{ position: 'relative', display: 'inline-block', zIndex: 1 }}>
                        <div className="filerButton" onClick={toggleFilter} style={{ display: 'flex', alignItems: 'center' }}>
                            <FilterIcon />
                            <span style={{ marginLeft: '4px' }}>Filter</span>
                        </div>
                        {filterVisible && (
                            <div className="filter-options-container" style={{
                                position: 'absolute', top: '100%', left: 0,
                                width: 'auto', zIndex: 1, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}>
                                <div style={{
                                    fontWeight: 'bold', padding: '10px', backgroundColor: '#FFFFFF', 
                                    width: '100%', textAlign: 'start', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                                }}>
                                    Filter
                                </div>

                                <div style={{
                                    backgroundColor: '#f9f9f9', padding: '5px 5px',
                                    maxHeight: '200px', overflowY: 'auto'
                                }}>
                                    {batchPrefixes.map((prefix, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Radio
                                                className={classes.filterRadio}
                                                value={prefix}
                                                checked={tempSelectedPrefix === prefix || tempSelectedPrefix === ''}
                                                onChange={() => handlePrefixSelect(prefix)}
                                                color="default"
                                            />
                                            <label style={{ marginLeft: '8px' }}>{prefix}</label>
                                        </div>
                                    ))}

                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                                        <Button className={`${classes.filterBack} ${classes.filterButton}`} onClick={cancelFilter} style={{ marginRight: '5px' }}>Cancel</Button>
                                        <Button className={`${classes.getStarted} ${classes.filterButton}`} onClick={applyFilter}>Apply Filter</Button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </Grid>
                </Grid>
                <Grid container >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width:'30%'}}>Batch Name</TableCell>
                                <TableCell align="left" style={{width:'50%'}}>Analysis Name</TableCell>
                                <TableCell style={{width:'20%'}} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRootCauses.length > 0 ? (
                                filteredRootCauses.map((rootcause, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell align="left">{rootcause.batchNumber}</TableCell>
                                            <TableCell align="left">{rootcause.analysisName}</TableCell>
                                            <TableCell style={{ display: 'flex', justifyContent: 'flex-end', paddingRight:'55px', alignItems:'center', gap:'10px'  }}>
                                                <IconButton size={'small'} onClick={() => handleViewPopup(rootcause)}>
                                                    <ViewIcon />
                                                </IconButton>
                                                <IconButton size={'small'} onClick={() => handleEditPopup(rootcause)}>
                                                    <EditIcon stroke={'#707070'} />
                                                </IconButton>
                                                <IconButton size={'small'} onClick={() => handleDeletePopup(rootcause)}>
                                                    <TrashIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>  
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign:'center' }}>
                                        No Root Causes Available
                                    </TableCell>
                                </TableRow>
                            )}     
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <ViewPopup 
                openView={isViewPopupOpen} 
                handleWindowClose={cancelViewPopup}
                rootCause={selectedRootCause}
            />
            <EditPopup 
                open={isEditPopupOpen} 
                handleWindowClose={cancelEditPopup} 
                rootCause={selectedRootCause} 
                updateRootCauses={updateRootCauses}
                formValue={formValue}
                setFormValue={setFormValue}
            />
            <DeletePopup
                open={isDeletePopupOpen}
                closePopup={cancelDeletePopup}
                rootCause={selectedRootCause}
                deleteRootCause={deleteRootCause}
            />
            <Loader loading={loading} />
            <ToasterComponent 
                toaster={isToasterOpen}
                message={toasterMessage}
                reqSuccess={reqSuccess}
            />
        </ThemeProvider>
    );
};

export const ViewPopup = ({openView,handleWindowClose, rootCause }) => {
    const [isShowDescription, setIsShowDescription] = useState(false);

    const handleShowDescription = () => {
        setIsShowDescription(true);
    };

    const cancelShowDescription = () => {
        setIsShowDescription(false);
    };

    return(
        <Dialog open={openView}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>View Root Cause Config</span>
                <IconButton onClick={handleWindowClose} style={{ paddingRight: '0px' }} size="small">
                    <Close />
                </IconButton>
                </Box>
            </DialogTitle>
  
            <DialogContent>
                <Box sx={{ minHeight: '135px' }}>
                    {rootCause && (
                        <Grid container spacing={3} style={{ padding: '15px' }} key={rootCause.id}>
                            <Grid item xs={4}>
                                <Typography>Batch Name</Typography>
                                <Typography style={{ fontWeight: 'bold', marginTop: '5px' }}>
                                    {rootCause.batchNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Analysis Name</Typography>
                                <Typography style={{ fontWeight: 'bold', marginTop: '5px' }}>
                                    {rootCause.analysisName}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Model</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        {formatSuccessCriteria(rootCause.model)}
                                    </Typography>
                                    <Tooltip title="Show Description" >
                                        <IconButton size="small" onClick={handleShowDescription} style={{ marginLeft: '5px' }}>
                                            <DocumentIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </DialogContent>
            
            <DescriptionComponent open={isShowDescription} handleCloseWindow={cancelShowDescription} />
            </Dialog>

    )
}

export const DeletePopup = ({open,closePopup,rootCause, deleteRootCause}) =>{
    if (!rootCause) {
        return null;
    }
    return (
        <Dialog open={open}>
            <Grid item style={{ display: 'flex', flexDirection: 'row', boxShadow: '0 0 15px 0 #00000014'  }}>
                <DialogTitle style={{ width: '90%'}}>Delete RootCause Config</DialogTitle>
                <IconButton style={{ justifyContent: "flex-end", cursor: "pointer" }} onClick={closePopup}>
                    <CancelIcon />
                </IconButton>
            </Grid>
            <DialogContent>
                    <Box>
                        <Grid item style={{ marginBottom: '15px', padding: '10px' }}>
                            <Typography>
                                Are you sure you want to delete rootcause {rootCause.analysisName} from batch name {rootCause.batchNumber}?
                            </Typography>
                        </Grid>
                        <Grid container justifyContent='flex-end'>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-between', width: '40%', marginBottom:'10px', gap:'10px' }}>
                                <Button variant='outlined' style={{ flexGrow: 1 }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' style={{ backgroundColor: '#FF4343', color: 'white' }} onClick={deleteRootCause}>
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
            </DialogContent>
            
        </Dialog>
    );
};

export const EditPopup = ({open, handleWindowClose, rootCause, updateRootCauses, formValue, setFormValue}) => {
    const classes = useStyles();
    const [selectedMOdel,setSelectedModel] = useState("")
    const [mainTestTitles, setMainTestTitles] = useState([]);
    const [showDescription,setShowDescription] = useState(false)

    const [models, setModels] = useState([])
    
    const showDescriptionDialog = () =>{
        setShowDescription(true); 
    }

    const handleCloseWindow = () =>{
        setShowDescription(false)
    }

    const [errors, setErrors] = useState({
        analysisName: '',
        model: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue({
            ...formValue,
            [name]: value,
        });
    };

    const handleSelectModel = (value) =>{
        setSelectedModel(value)
        setFormValue(prevValue =>({
          ...prevValue,
          "model":value,
          "modelUrl":models.filter((m)=>m.model_name===value)[0]["model_path"]
        }))
        setErrors(prevError => ({
          ...prevError,
          "model": null
        }))
    
    }

    useEffect(()=>{
        const getAvailableModel = async () => {
          try {
            const models = await getAvailableModels({
              "projectname": "RootCause"
            });
            if(models && models.status==="success"){
              let modelData = []
              let keys = Object.keys(models).filter((k)=>k!=="status")
              keys.map((k)=>{
                modelData.push(models[k])
              })
              setModels(modelData);
            }
          } catch (err) {
            console.error('Error fetching batch numbers:', err);
          }
      };
      getAvailableModel()
    },[]);

    const validateForm = () => {
        let error: any = {};

        if(formValue.analysisName === '') {
            error.analysisName = 'Analysis Name is required';
        }
        if(formValue.model === '') {
            error.model = 'Model is required';
        }
        setErrors(error);
        return error
    }

    const resetFormFields = () =>  {
        setFormValue({
            batchNumber: '',
            analysisName: '',
            model: '',
            modelUrl: '',
        });
        setErrors({
            analysisName: '',
            model: '',
        });
    }

    const handleSubmit = async () => {
        const validationError = validateForm();
        if(Object.keys(validationError).length > 0) {
            return;
        }
        try {
            await updateRootCauses(formValue);
            resetFormFields();
            handleWindowClose();
        } catch(err) {
            console.log('Error updating root cause', err);
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle style={{ boxShadow: '0 0 15px 0 #00000014' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span>Edit Root Cause Config</span>
              <IconButton
                onClick={() => {
                    handleWindowClose();
                    resetFormFields();
                }}
                style={{ paddingRight: '0px' }}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
          <Box sx={{minHeight:'385px'}}>
            <Grid container spacing={3} style={{ padding: '10px' }}>
                <Grid item xs={6} style={{ border: '1px solid #2A7BEC', borderRadius: '5px', marginTop: '10px', marginLeft: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                        <Typography style={{width:'75%'}}>
                            Batch Name
                        </Typography>
                            <InputBase
                                style={{fontWeight: 'bold', color: '#2A7BEC', backgroundColor: '#F9F9FA', padding: '10px'}}
                                type="text"
                                name="batchNumber"
                                value={formValue.batchNumber}
                            />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography>
                        Analysis Name
                    </Typography>
                    <InputBase
                        className={classes.formInput}
                        type="text"
                        name="analysisName"
                        value={formValue.analysisName}
                        onChange={handleInputChange}
                    />
                    {errors.analysisName && (
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.errorText}>
                                {errors.analysisName}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} style={{ flexDirection: 'column' }}>
                    <Typography className={classes.typo}>
                    Model
                    </Typography> 
                    <DropDown
                        options={models.map((m)=>m.model_name)}
                        type='model'
                        emptyTag='Select Model'
                        setSelectOption={setSelectedModel}
                        value={formValue.model}
                        // handleError={handleError}
                        onClick= {handleSelectModel}
                        isSingle={true}
                        icon ={<DocumentIcon />}
                        iconClick = {showDescriptionDialog}
                    />
                    {errors.model &&
                        <Grid item xs={12} style={{ padding: "0px 8px" }}>
                        <Typography className={classes.errorText}>
                            {errors.model}
                        </Typography>
                        </Grid>
                    }
                </Grid>
                {showDescription && 
                    <DescriptionComponent 
                    open={showDescription} 
                    handleCloseWindow={handleCloseWindow}
                    />
                }
                <Grid container spacing={2} style={{ marginTop: "20px" }} justifyContent="flex-end">
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.backButton} ${classes.button}`}
                            onClick={() => {
                                handleWindowClose();
                                resetFormFields();
                            }}
                        >
                            Cancel
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button
                            variant="contained"
                            className={`${classes.getStarted} ${classes.button}`}
                            onClick={handleSubmit}
                        >
                            Update 
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
          </DialogContent>
        </Dialog>
    );
};