import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Circle } from '../../maintenance/test-automation/components/Circle';
import { primaryBlue, primaryGray } from 'src/components/colors';
import { EditProject } from './EditProjectConfig';
import { EditFiles } from './EditUploadFiles';
import { DeleteFile, updateProjectClientConfigurations, uploadFile } from 'src/app/maintenance/test-automation/components/apiHelper';
import { environment } from 'src/environments/environment';
import { Loader } from 'src/app/setup-senzmatica/step-3/components/Loader';


export const EditChatbotConfigComponent = ({ 
    open, 
    onClose, 
    chatConfig , 
    steps , 
    setStep , 
    step, 
    STEPS,
    selectedBatchNumber,
    selectedProject,
    projectNames,
    setErrors,
    errors,
    setSteps,
    setLoading,
    setIsToasterOpen,
    setToasterMessage,
    setReqSuccess
}) => {
    const [projectName, setProjectName] = useState(selectedProject)
    const [files, setFiles] = useState([])
    const [fileList, setFileList] = useState([])
    const [isDeleteConformation,setIsDeleteConformation] = useState(false)
    const [deleteFileType,setDeleteFileType] = useState('')
    const [fileIndex,setFileIndex] = useState(0)
    const [deleteFileList,setDeleteFileList] = useState([])
    const [uploadedFile,setUploadedFile] = useState([])

    const handleProjectName = (event) => {
        let { value } = event.target;
        if(value != ''){
            setErrors(prevError => ({
                ...prevError,
                projectName:null
            }))
        }
        setProjectName(value)
    }

    const formValidate = () =>{
        const error : any= {}
        if(projectName == ''){
            error.projectName = "Project Name is Required"
        }else if(selectedProject.toLowerCase() != projectName.toLowerCase() && projectNames.some(name => name.toLowerCase() == projectName.toLowerCase())){
            error.projectName = "Project Name Already Exists"
        }

        if(fileList && fileList.length == 0 && step == 1){
            error.KnowledgedBase = "Attaching at least one document is required"
        }

        return error
    }

    const increaseStep = () => {
        let validate = formValidate();
        const hasError = Object.values(errors).some(error => error != null)
        if(hasError){
            setErrors(prevError => ({
                ...prevError,
                ...validate
            }));
        }else{
            setErrors(validate)
        }
        // Return early if there are existing errors in the state or new validation errors
        if (hasError || Object.keys(validate).length > 0) {       
            return;
        }
        // Assuming step is passed as a prop or comes from state
        if (step === 0) {
            updateProjectName();
            setStep(prevStep => Math.min(prevStep + 1, STEPS.length - 1));
        } else if (step === 1) {

            if(uploadedFile.length > 0){
                updateFiles();
            }
            setUploadedFile([])
            onClose()
        } 
    };

    useEffect(() => {
        updateStepActivation(step);
      }, [step]);
    
    function updateStepActivation(currentStep) {
      const updatedSteps = steps.map((step, index) => ({
        ...step,
        activate: index === currentStep,
      }));
      setSteps(updatedSteps);
    }

    const updateProjectName = async () =>{
        const data = {
            batchNumber : selectedBatchNumber,
            analysis_configs : projectName
        }
        try{
            setLoading(true)
            await updateProjectClientConfigurations(data)
        }catch{

        }finally{
            setLoading(false)
        }
    }

    const updateFiles = async () =>{
        let formData = new FormData()
        uploadedFile.forEach((file,index)=>{
            if (file.file) {
                formData.append('files', file.file, file.name);
            }
        })
        const jsonFile = {
            batchNumber: selectedBatchNumber, 
            // header_token: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
            // proto: environment.host,
            deleteFiles : deleteFileList 
        };
        formData.append('json_data', JSON.stringify(jsonFile));
        try{
            uploadFile(formData)
            await setIsToasterOpen(true)
            setToasterMessage("File Updated Successfully.")
            setReqSuccess(true)
        }catch(error){
            setIsToasterOpen(true)
            setToasterMessage(error.message)
            setReqSuccess(false)
        }finally{
            setTimeout(() =>{
                setIsToasterOpen(false)
            },2500)
        }
    }

    const handleDeleteFile = async (data, message) => {
        try {
            setLoading(true);
            await DeleteFile(data);
            setIsToasterOpen(true);
            setToasterMessage(message);
            setReqSuccess(true);
        } catch (error) {
            setIsToasterOpen(true);
            setToasterMessage(`Something went wrong`);
            setReqSuccess(false);
        } finally {
            setLoading(false);
            setTimeout(() => {
                setIsToasterOpen(false);
            }, 2500);
        }
    };

    useEffect(() =>{
        setProjectName(selectedProject)
        if(chatConfig.files !== 'EMPTY'){
            setFiles(chatConfig.files)
            setFileList(chatConfig.files)
        }else{
            setFiles([])
            setFileList([])
        }
    },[selectedProject,chatConfig])

    
    const removeAFile = async () => {
        if (deleteFileType === "removeAllFile") {
            setFileList([]);
            setIsDeleteConformation(false);
            const data = {
                batchNumber: selectedBatchNumber,
                deleteFiles: deleteFileList,
            };
            await handleDeleteFile(data, `Successfully removed the files`);
        } else {
            const selectedFiles = [...fileList];
            const file = selectedFiles[fileIndex];
            if (file) {
                selectedFiles.splice(fileIndex, 1);
                const data = {
                    batchNumber: selectedBatchNumber,
                    deleteFiles: [file.name],
                };
                await handleDeleteFile(data, `Successfully removed the file ${file.name}`);
                setFileList(selectedFiles);
                setIsDeleteConformation(false);
            }
        }
    };

    const removeAllFile = () =>{
        let deleteList = []
        setIsDeleteConformation(true)
        setDeleteFileType('removeAllFile')
        fileList.map(file =>{
            deleteList.push(file.name)
        })
        setDeleteFileList(deleteList)
    }

    return (
        <Dialog open={open} PaperProps={{ style: { minWidth: '950px' } }}>
            <DialogTitle style={{ boxShadow: '0px 4px 40px 0px #00000014' }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography>{'edit root cause config'.toUpperCase()}</Typography>
                    <IconButton onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ margin: '0px' }}>
                <Grid container style={{ textAlign: "center", margin: '20px 0px' }}>
                    <Grid item xs={3} md={3} ></Grid>
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <Grid item >
                                <Grid container direction="column" alignItems="center">
                                    <Grid item xs={3} md={3}>
                                        <Circle number={index + 1} active={step.activate} completed={steps.findIndex(s => s.activate) > index} />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                paddingTop: "15px",
                                                fontFamily: "Poppins, sans-serif",
                                                color: step.activate ? primaryBlue : primaryGray,
                                            }}
                                        >
                                            {step.text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {index !== steps.length - 1 && (
                                <Grid item xs={1} md={1}>
                                    <hr
                                        style={{
                                            marginTop: "32px",
                                            opacity: steps[index + 1].activate ? "1" : "0.12",
                                        }}
                                        color={
                                            steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                                        }
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
                {step == 0 && <EditProject batch={selectedBatchNumber} onChange={handleProjectName} project={projectName} errors={errors}/>}
                {step == 1 && <EditFiles 
                    files={chatConfig.files} 
                    setFiles={setFiles} 
                    fileList={fileList} 
                    setFileList={setFileList} 
                    removeAFile={removeAFile} 
                    isDeleteConformation={isDeleteConformation}
                    setIsDeleteConformation={setIsDeleteConformation}
                    fileIndex={fileIndex}
                    setFileIndex={setFileIndex}
                    setDeleteFileType={setDeleteFileType}
                    deleteFileType={deleteFileType}
                    errors={errors}
                    setErrors={setErrors}
                    setDeleteFileList ={setDeleteFileList}
                    setUploadedFile = {setUploadedFile}
                />}
                
                <Grid container justifyContent='flex-end' style={{marginBottom:'15px'}}>
                    <Button variant='outlined' style={{ marginRight: '10px' }} onClick={onClose}>cancel</Button>
                    {step == 1 && fileList.length > 0 && <Button variant='outlined' style={{ color: 'red', borderColor: 'red',marginRight: '10px' }} onClick={removeAllFile}>Remove All</Button>}
                    <Button variant='contained' style={{ backgroundColor: '#2A7BEC', color: 'white' }} onClick={increaseStep}>{step == 1 ? 'Save All' : step == 0 ? 'Next' : 'Submit'}</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

