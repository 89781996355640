import { Grid, IconButton, Typography } from '@material-ui/core'
import * as React from 'react'
import { Link } from 'react-router-dom';
import { Arrow, NonSuccessCriteriaIcon } from 'src/components/Icons'
import { classicNameResolver } from 'typescript';
import useStyles from '../../assets/styles';
import { handleDevicePulseClicked } from 'src/app/utils';

export const BackToHomeComponent = ({icon,path,title}) => {
    const classes = useStyles();
    return(
        <Grid container style={{display: "flex",margin:'30px 0px',alignItems:'center',backgroundColor:'white',padding:'15px',width:'fit-content'}}>
                    <Grid item style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                        <Link to={path} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                        <IconButton onClick={handleDevicePulseClicked} style={{padding:"0px", marginBottom:"1px"}}>
                            <NonSuccessCriteriaIcon />
                        </IconButton>
                        </Link>
                    </Grid>
                    <Arrow/>
                    <Grid item style={{display:'flex',alignItems:'center'}}>
                        {icon}
                        <Typography className={classes.redirectTitle}>{title}</Typography>
                    </Grid>
                </Grid>
    )
}