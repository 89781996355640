import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './device/device.component';
import {HeaderComponent} from './layouts/header/header.component';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {LoginComponent} from './authentication/login/login.component';
import {SignUpComponent} from './authentication/sign-up/sign-up.component';
import {ForgetPasswordComponent} from './authentication/forget-password/forget-password.component';
import {ResetPasswordComponent} from './authentication/reset-password/reset-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {SideMenuComponent} from './layouts/side-menu/side-menu.component';
import {KitBoardComponent} from './kitboard/kitboard.component';
import {KitModelBoardComponent} from './kitmodelboard/kitmodelboard.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import {KitFormComponent} from './forms/kit-form/kit-form.component';
import {KitModelFormComponent} from './forms/kit-model-form/kit-model-form.component';
import {DeviceViewComponent} from './kitboard/device-view/device-view.component';
import {registerLocaleData} from '@angular/common';
import localeLk from '@angular/common/locales/ta-LK';
import {DeviceDataComponent} from './device/device-data/device-data.component';
import {GraphComparableComponent} from './graph-comparable/graph-comparable.component';
import {AuthInterceptor} from './authentication/authInterceptor';
import {KitHistoryComponent} from './kitboard/kit-history/kit-history.component';
import {PermissionDirective} from './permission.directive';
import {WifiConfigComponent} from './wifi-config/wifi-config.component';
import {MyComponentWrapperComponent} from './dashboard/DashBoardComponentWrapper';
import {TestingAutomationComponentWrapper} from './testing-automation/TestingAutomationComponentWrapper'
import {ProductTypComponentWrapper} from './product-types/ProductTypesComponentWrapper';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OTAManagerComponentWrapper} from './otamanager/OTAManagerComponentWrapper';
import {RemoteManagerComponentWrapper} from './remotemanager/RemoteManagerComponentWrapper';
import {DeviceConnectivityComponent} from './device-connectivity/device-connectivity.component';
import {AssignedDeviceComponent} from './device-connectivity/assigned-device/assigned-device.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {environment} from '../environments/environment';
import {ClipboardModule} from 'ngx-clipboard';
import {DecodersComponentWrapper} from './transcoding/DecodersComponentWrapper';
import {DeviceTroubleshotComponent} from './device/device-troubleshot/device-troubleshot.component';
import {DeviceFormComponent} from './forms/device-form/device-form.component';
import {InternetConnectionService} from './internet-connection/internet-connection.service';
import {DeviceDataActuatorComponent} from "./device/device-data-actuator/device-data-actuator.component";
import {SetupSenzMaticaComponentWrapper} from './setup-senzmatica/SetupSenzMaticaComponentWrapper';
import {ConnectDeviceWrapper} from './setup-senzmatica/connect-devices/ConnectDeviceWrapper';
import {DefineProductTypeComponentWrapper} from './setup-senzmatica/define-product-type-form/DefineProductTypeComponentWrapper';
import { ConfigurationProductComponentWrapper } from './product-types/configure-product/ConfigurationProductComponentWrapper';
import { DeviceMaintanenceComponent } from './device/device-maintanence/device-maintanence.component';
import { CallbackComponent } from './authentication/login/callback.component'; // Add this import

registerLocaleData(localeLk, 'lk');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    SignUpComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    SideMenuComponent,
    KitBoardComponent,
    KitModelBoardComponent,
    KitFormComponent,
    KitModelFormComponent,
    DeviceViewComponent,
    DeviceDataComponent,
    DeviceDataActuatorComponent,
    GraphComparableComponent,
    KitHistoryComponent,
    PermissionDirective,
    WifiConfigComponent,
    MyComponentWrapperComponent,
    TestingAutomationComponentWrapper,
    ProductTypComponentWrapper,
    ConfigurationProductComponentWrapper,
    OTAManagerComponentWrapper,
    RemoteManagerComponentWrapper,
    DeviceConnectivityComponent,
    AssignedDeviceComponent,
    DecodersComponentWrapper,
    DeviceTroubleshotComponent,
    DeviceFormComponent,
    SetupSenzMaticaComponentWrapper,
    ConnectDeviceWrapper,
    DefineProductTypeComponentWrapper,
    DeviceMaintanenceComponent,
    CallbackComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({timeOut: 1000, preventDuplicates: true, positionClass: 'toast-bottom-right'}),
    NgxPaginationModule,
    DateRangePickerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    ClipboardModule
  ],
  providers: [
    InternetConnectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
