import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SideNavService} from './sidenav/sidenav.service';
import {hasPermision, isPlanId} from './../../../app/utils';
import {environment} from './../../../environments/environment';
import isThisHour from 'date-fns/isThisHour';
import { AccountService } from 'src/app/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { th } from 'date-fns/locale';
import Cookies from "js-cookie";


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  error;
  selectedMenu;
  isSetupDevicePulseClick
  isSetupSummaryClick
  isSystemHistoryClick
  isAutomlClicked
  isReportHistoryButtonClick
  azurePackageId
  hasPermision = hasPermision;
  isPlanId = isPlanId;
  remainingUsersPercentage = 0; 
  billedDevicesPercentage = 0;
  deviceCount: number = 0;
  archivedDeviceCount: number = 0;
  userCount: number = 0;
  totalUsers: number = 0;
  totalDevices: number = 0;
  remainingUsers: number = 0;
  remainingDevices: number = 0;
  devicesPercentage: number = 0;
  userCountPercentage: number = 0;
  remainingDevicesPercentage: number = 0;
  archivedDevicesPercentage: number = 0;
  sideBarStepId: string;
  private sidebarUpdateListener: () => void;

  constructor(protected router: Router, private sideNavService: SideNavService, private route: ActivatedRoute, private auth: AuthService, private accountService: AccountService, private spinner: NgxSpinnerService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedMenu = this.router.url.split('/')[1];
      }
    });
  }

  ngOnInit() {
    window.addEventListener('startUserGuide', this.handleStartUserGuide.bind(this));
    // this.selectedMenu = this.router.url.split('/')[1];
    this.sideNavService.hideSideNav();
    const events = ['deviceDeleted', 'userDeleted', 'deviceAdded', 'userAdded'];
    window.addEventListener('reportHistoryClicked', this.handleReportHistoryClick.bind(this));
    window.addEventListener('automlClicked',this.handleAutoMlClick.bind(this))

    if(this.isPlanId() && this.isAccountUser()){
      this.sidebarUpdateListener = () => this.updateSidebarCounts();
      events.forEach(event => window.addEventListener(event, this.sidebarUpdateListener));

      this.fetchDeviceCounts();
      this.getUserCount();
      this.getTotalUserCount();
      this.totalUsers = this.getTotalUserCount();
      this.updateRemainingUsers();
    }

    window.addEventListener('setupDevicePulseClicked',this.handleSetupSensmaticaClick.bind(this))
    window.addEventListener('devicePulseClicked', this.handleDevicePulseClick.bind(this));
    window.addEventListener('deviceFailureClicked', this.handleDeviceFailureClick.bind(this));
  }

  ngOnDestroy() {
    const events = ['deviceDeleted', 'userDeleted', 'deviceAdded', 'userAdded'];
    window.removeEventListener('reportHistoryClicked', this.handleReportHistoryClick.bind(this));
    window.removeEventListener('automlClicked',this.handleAutoMlClick.bind(this))
    
    events.forEach(event => window.removeEventListener(event, this.sidebarUpdateListener));
    window.removeEventListener('setupDevicePulseClicked',this.handleSetupSensmaticaClick.bind(this))
    window.removeEventListener('devicePulseClicked', this.handleDevicePulseClick.bind(this));
    window.removeEventListener('deviceFailureClicked', this.handleDeviceFailureClick.bind(this));
    window.removeEventListener('startUserGuide', this.handleStartUserGuide.bind(this));
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  closeMenu() {
    const view = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(view)) {
      this.sideNavService.hideSideNav();
    }

  }

  isActive(menu: string): boolean {
    return this.selectedMenu === menu;
  }

  setActive(menu: string): void {
    this.selectedMenu = menu;
  }

  isTestAutomationUser(): boolean {
    return this.auth.isTestAutomationUser()
  }

  isMlEngineer(): boolean {
    return  this.auth.isMlEngineer()
  }

  handleDevicePulse(){
    this.isSetupSummaryClick = true
    if(this.isSetupSummaryClick){
      this.isSystemHistoryClick = false
      this.isAutomlClicked = false
    }
    console.log(this.isSetupSummaryClick)
  }

  collapseMenu() {
    this.isSetupSummaryClick = false
    console.log(this.isSetupSummaryClick)
  }

  handleSystemHistory(){
    this.isSystemHistoryClick = true
    this.isReportHistoryButtonClick = false
    if(this.isSystemHistoryClick){
      this.isSetupSummaryClick = false
      this.isAutomlClicked = false
    }

    console.log(this.isSystemHistoryClick)
  }

  collapseSystemHistory() {
    this.isSystemHistoryClick = false
    this.isReportHistoryButtonClick = false
    console.log(this.isSystemHistoryClick)
    console.log(this.isReportHistoryButtonClick)
  }
  
  handleAutoml(){
    this.isAutomlClicked = true
    if(this.isAutomlClicked){
      this.isSetupSummaryClick = false
      this.isSystemHistoryClick = false
    }
    console.log(this.isAutomlClicked)
  }

  collapseAutoml() {
    this.isAutomlClicked = false
    console.log(this.isAutomlClicked)
  }

  handleReportHistoryClick() {
    this.isSystemHistoryClick = true;
    this.setActive('report-history');
    this.router.navigate(['/ReportHistory']);  
  }

  handleReportHistoryButton() {
    this.isReportHistoryButtonClick = true;
    
  }

  handleDevicePulseClick() {
    this.setActive('setupSenzmatica')
    this.isSystemHistoryClick = false
    this.isAutomlClicked = false
    this.isSetupSummaryClick = false
    this.router.navigate(['/TestAutomation']);
    console.log(this.isActive('setupSenzmatica'))
    console.log(this.isSetupSummaryClick)
  }

  handleAutoMlClick(){
    this.setActive('automl');
    this.router.navigate(['/automl']);  
    this.isAutomlClicked = true
  }

  handleSetupSensmaticaClick(){
    this.setActive('setupSenzmatica')
    this.router.navigate(['/RootCauseAnalysis'])
  }

  handleDeviceFailureClick(){
    this.setActive('deviceFailure')
    this.router.navigate(['/DeviceFailure'])
  }

  getUserRole(): String {
    return  Cookies.get("ROLE")
  }

  handleUsageClick(){
    this.router.navigate(['/SubscriptionUsage']);
  }
  
  isAzureFreePackage = () =>{
    const planId = Cookies.get('PLANID')
    return environment.azurepackageFreeId == planId
  }
  
  isAzureBasicPackage = () =>{
    const planId = Cookies.get('PLANID')
    return environment.azurepackageBasicId == planId
  }
    
  isAzurePremiumPackage = () =>{
    const planId = Cookies.get('PLANID')
    return environment.azurepackagePremiumId == planId
  }

  updateSidebarCounts() {
    this.fetchDeviceCounts();
    this.getUserCount();
  }

  navigateTo(url: string): void {
    window.location.href = url;
  }

  getPackageLink(): string {
    if (this.isAzurePremiumPackage()) {
      return environment.contactUsLink;
    }
    return environment.azurepageLink;
  }

  fetchDeviceCounts(): void {
    this.accountService.getAllDevices().subscribe(response => {
      this.deviceCount = response.content.length || 0;
      this.updateTotalDevices();
    }, error => {
      console.error('Error fetching devices:', error);
    });

    this.accountService.getAllArchivedDevices(Cookies.get("PLANID")).subscribe(response => {
      this.archivedDeviceCount = response.content.length || 0;
      this.updateTotalDevices();
    }, error => {
      console.error('Error fetching archived devices:', error);
    });
  }

  getUserCount(): void {
    this.accountService.getAllUsers().subscribe(response => {
      this.userCount = response.content.length || 0;
      this.updateRemainingUsers();
    }, error => {
      console.error('Error fetching users:', error);
    });
  }

  updateTotalDevices(): void {
    if (this.isAzureFreePackage()) {
        this.totalDevices = 10;
    } else {
        this.totalDevices = this.deviceCount + this.archivedDeviceCount;
    }
    this.updateBilledDevices();
}

  getTotalUserCount(){
    if(this.isAzureFreePackage()){
      return 2
    } else if(this.isAzureBasicPackage()){
      return 5
    } else if(this.isAzurePremiumPackage()){
      return 10
    }

  }

  updateRemainingUsers(): void {
    this.remainingUsers = Math.max(this.totalUsers - this.userCount, 0);
    this.userCountPercentage = Math.min((this.userCount / this.totalUsers) * 100, 100);
    this.remainingUsersPercentage = 100 - this.userCountPercentage;
}


  updateBilledDevices(): void {
    if (this.isAzureFreePackage()) {
        this.remainingDevices = Math.max(this.totalDevices - this.deviceCount - this.archivedDeviceCount, 0);
        this.billedDevicesPercentage = ((this.deviceCount + this.archivedDeviceCount) / this.totalDevices) * 100;
        this.remainingDevicesPercentage = 100 - this.billedDevicesPercentage;
    } else {
        const total = this.deviceCount + this.archivedDeviceCount;
        this.devicesPercentage = total > 0 ? (this.deviceCount / total) * 100 : 0;
        this.archivedDevicesPercentage = total > 0 ? (this.archivedDeviceCount / total) * 100 : 0;
    }
  }

  handleStartUserGuide(event: CustomEvent) {
    console.log('Received startUserGuide event:', event.detail);
    const id = event.detail.id;
    console.log('Updating sideBarStepId to:', id); 
    this.sideBarStepId = id;
  }

  isAccountUser(): boolean{
    return AuthService.isAccountUser();
  }
}