import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {primaryGray, searchBarBorderColor} from "../colors";
import InputBase from "@material-ui/core/InputBase";
import {environment} from "src/environments/environment";
import {Checkbox, Chip, ListItemText} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      border: "1px solid #ced4da",
      fontSize: 16,
      background: "white",
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        background: "white",
      },
    },
  })
)(InputBase);

const DropDown = (props) => {
  const {
    defaultProductType,
    defaultDeviceCategory,
    defaultVersion,
  } = props;
  const [productTypes, setProductTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [deviceIds, setDeviceIds] = useState([]);
  const [versionNum, setVersionNum] = useState([]);

  const [productType, setProductType] = useState(defaultProductType);
  const [category, setCategory] = useState(defaultDeviceCategory);
  const [version, setVersion] = useState(defaultVersion);
  const [devices, setDevices] = useState([]);

  function RetrieveDependentData() {
    const allCategories = new Set();

    devices.forEach((item) => {

      if (item.deviceParameterConfiguration.productType === productType) {
        allCategories.add(item.deviceParameterConfiguration.deviceCategory);
      }
    });

    const categoryTemp = Array.from(allCategories);

    const allVersions = new Set();

    devices.forEach((item) => {
      if (
        item.deviceParameterConfiguration.productType === productType &&
        item.deviceParameterConfiguration.deviceCategory === category
      ) {
        allVersions.add(item.deviceParameterConfiguration.versionNum);
      }
    });

    const versionTemp = Array.from(allVersions);

    const matchingDevices = devices.filter((item) => {
      let matchesProductType =
        !productType ||
        item.deviceParameterConfiguration.productType === productType;
      let matchesCategory =
        !category ||
        item.deviceParameterConfiguration.deviceCategory === category;
      let matchesVersion =
        !version || item.deviceParameterConfiguration.versionNum === version;

      return matchesProductType && matchesCategory && matchesVersion;
    });

    const deviceIds = [].concat.apply(
      [],
      matchingDevices.map((item) => item.id)
    );

    setCategories(categoryTemp);
    setVersionNum(versionTemp);
    setDeviceIds([...new Set(deviceIds)]);
    if (productType && version && category) {
      props.readDeviceData(matchingDevices);
    }
  }

  function RetrieveData() {
    axios.get(`${environment.host}/core/device/deviceParameterConfiguration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        let productEle = [];
        let deviceIdEle = [];
        let versionEle = [];
        let categoryEle = [];

        if (response.data.content !== undefined) {
          setDevices(response.data.content);
          response.data.content.map((p) => {
            productEle.push(p.deviceParameterConfiguration.productType);
            versionEle.push(p.deviceParameterConfiguration.versionNum);
            categoryEle.push(p.deviceParameterConfiguration.deviceCategory);
            deviceIdEle.push(p.name);

          });
        }

        categoryEle = categoryEle.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        productEle = productEle.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        deviceIdEle = deviceIdEle.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        versionEle = versionEle.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        setProductTypes(productEle);
        setDeviceIds(deviceIdEle);
        setVersionNum(versionEle);
        setCategories(categoryEle);
      })
      .catch((err) => {
        setProductTypes([]);
        setDeviceIds([]);
        setVersionNum([]);
        setCategories([]);
      });
  }

  useEffect(() => {
    if (productType === "" && category === "" && version === "") {
      RetrieveData();
    }
  }, [productType, category, version]);

  useEffect(() => {
    RetrieveDependentData();
  }, [productType, category, version]);

  useEffect(() => {
    setProductType(defaultProductType);
  }, [defaultProductType]);

  useEffect(() => {
    setCategory(defaultDeviceCategory);
  }, [defaultDeviceCategory]);

  useEffect(() => {
    setVersion(defaultVersion);
  }, [defaultVersion]);

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
      color: primaryGray,
    },
    label: {
      fontSize: "16px",
      color: primaryGray,
      lineHeight: "25px",
      paddingBottom: "5px",
      margin: "auto",
    },
    helperText: {
      color: "red",
      fontSize: "small",
      paddingTop: "7px",
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px",
    },
    menuPaper: {
      maxHeight: "200px",
    },
  }));
  const classes = useStyles();

  const readProductType = (e) => {
    props.handleProductType(e.target.value);
    setProductType(e.target.value);
  };

  const readCategory = (e) => {
    props.handleDeviceCategory(e.target.value);
    setCategory(e.target.value);
  };

  const readVersion = (e) => {
    props.handleVersion(e.target.value);
    setVersion(e.target.value);
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={6}
        md={6}
        style={{
          textAlign: "left",
          marginBottom: "22px",
          paddingRight: "2%",
        }}
      >
        <Typography className={classes.label} style={{}}>
          Product Type*
        </Typography>
        <FormControl className={classes.input}>
          <Select
            displayEmpty
            id="demo-customized-select-native"
            value={defaultProductType}
            className={classes.input}
            onChange={readProductType}
            input={<BootstrapInput/>}
            MenuProps={{
              anchorOrigin: {vertical: "bottom", horizontal: "left"},
              transformOrigin: {vertical: "top", horizontal: "left"},
              getContentAnchorEl: null,
            }}
          >
            <MenuItem
              style={{color: primaryGray, opacity: "1"}}
              value=""
              disabled
              hidden
            >
              Select product type
            </MenuItem>
            {productTypes.map((productType, i) => (
              <MenuItem
                value={productType}
                style={{color: primaryGray, opacity: "1"}}
                key={i}
              >
                {productType}
              </MenuItem>
            ))}
          </Select>
          {props.productTypeError && (
            <span className={classes.helperText}>*ProductType is Required</span>
          )}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={6}
        md={6}
        style={{
          textAlign: "left",
          marginBottom: "22px",
          paddingRight: "3%",
        }}
      >
        <Typography className={classes.label} style={{}}>
          Version*
        </Typography>
        <FormControl className={classes.input}>
          <Select
            displayEmpty
            id="demo-customized-select-native"
            value={defaultVersion}
            className={classes.input}
            onChange={readVersion}
            input={<BootstrapInput/>}
            MenuProps={{
              anchorOrigin: {vertical: "bottom", horizontal: "left"},
              transformOrigin: {vertical: "top", horizontal: "left"},
              getContentAnchorEl: null,
            }}
          >
            <MenuItem
              value=""
              disabled
              hidden
              style={{color: primaryGray, opacity: "1"}}
            >
              Select Version
            </MenuItem>
            {versionNum.map((version, i) => (
              <MenuItem
                style={{color: primaryGray, opacity: "1"}}
                value={version}
                key={i}
              >
                {version}
              </MenuItem>
            ))}
          </Select>
          {props.versionError && (
            <span className={classes.helperText}>*Version is Required </span>
          )}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={6}
        md={6}
        style={{
          textAlign: "left",
          marginBottom: "25px",
          paddingRight: "2%",
          marginTop: "10px",
        }}
      >
        <Typography className={classes.label} style={{}}>
          Device Category*
        </Typography>
        <FormControl className={classes.input}>
          <Select
            id="demo-customized-select-native"
            displayEmpty
            value={defaultDeviceCategory}
            className={classes.input}
            onChange={readCategory}
            input={<BootstrapInput/>}
            MenuProps={{
              anchorOrigin: {vertical: "bottom", horizontal: "left"},
              transformOrigin: {vertical: "top", horizontal: "left"},
              getContentAnchorEl: null,
            }}
          >
            <MenuItem
              value=""
              disabled
              hidden
              style={{color: primaryGray, opacity: "1"}}
            >
              Select Device Category
            </MenuItem>
            {categories.map((c, i) => (
              <MenuItem
                value={c}
                key={i}
                style={{color: primaryGray, opacity: "1"}}
              >
                {c}
              </MenuItem>
            ))}
          </Select>
          {props.categoryError && (
            <span className={classes.helperText}>
              *Device Category is Required
            </span>
          )}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={6}
        md={6}
        style={{
          textAlign: "left",
          marginBottom: "25px",
          paddingRight: "3%",
          marginTop: "10px",
        }}
      >
        <Typography className={classes.label} style={{}}>
          Select Device ID's*
        </Typography>

        <FormControl className={classes.input}>
          <Select
            id="demo-customized-select-native"
            displayEmpty
            value={props.defaultDevices ? props.defaultDevices : []}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: {paper: classes.menuPaper},
            }}
            className={classes.input}
            onChange={(e) => props.readDevices(e.target.value)}
            multiple
            renderValue={(selected: string[]) => (
              <div>
                {selected.map((value, i) => (
                  <Chip
                    key={i}
                    label={value}
                    className={classes.chip}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => props.removeDevice(value)}
                  />
                ))}
              </div>
            )}
            input={<BootstrapInput/>}
          >
            {deviceIds.map((d, index) => {
              return (
                <MenuItem
                  value={d}
                  key={index}
                  divider={true}
                  style={{color: primaryGray, opacity: "1"}}
                >
                  <Checkbox
                    checked={props.defaultDevices.includes(d)}
                    style={{color: "#8F8F8F", display: "inline"}}
                  />
                  <ListItemText
                    primary={d}
                    style={{color: primaryGray, display: "inline"}}
                  />
                </MenuItem>
              );
            })}
          </Select>
          {props.deviceIdsError && (
            <span className={classes.helperText}>*DeviceIds is required</span>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DropDown;
