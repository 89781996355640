import * as React from "react";
import { Box, Button, createTheme, Grid, InputBase, ThemeProvider, Typography } from "@material-ui/core";
import { DropDown } from "../../components/DropDown";
import useStyles from "src/app/maintenance/assets/styles";
import {useState, useEffect} from 'react';
import { DocumentIcon } from "src/components/Icons";
import { DescriptionComponent } from "./Description";
import { getAvailableModels } from "../../components/apiHelper";
import Cookies from "js-cookie";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export function SelectAModelComponent({setFormValue,setErrors,errors,setLoading,setIsToasterOpen,setReqSuccess,setToasterMessage}) {
  const classes = useStyles();
  const [selectedMOdel,setSelectedModel] = useState("")
  const [showDescription,setShowDescription] = useState(false)

  const [models, setModels] = useState([])
  const [descriptions, setDescriptions] = useState({})
  const [codes,setCodes] = useState({})
  const [selectedDescription,setSelectedDescription]=useState("")
 
  const handleSelectModel = (value) => {
    setSelectedModel(value);
    const modelDetails = models.find((model) => model.model_name === value);
    setFormValue((prevValue) => ({
      ...prevValue,
      model: value,
      modelUrl: modelDetails.model_path,
      mainTestId:prevValue.mainTestCaseId,
      inferenceCodes:codes[modelDetails.model_path],
      inferenceNoOfData:null,
      modelDetails: {
        ...prevValue.modelDetails,
        [prevValue.mainTestCaseId]: {
          model: value,
          modelUrl: modelDetails.model_path,
        },
      },
    }));
    setErrors((prevError) => ({ ...prevError, model: null }));
  };
  const showDescriptionDialog = (e) =>{
    setSelectedDescription(descriptions[e])
    setShowDescription(true); 
  }
  const handleCloseWindow = () =>{
    setShowDescription(false)
  }

  useEffect(()=>{
    const getAvailableModel = async () => {
      try {
        setLoading(true)
        const models = await getAvailableModels({
          "userid": Cookies.get('USER_ID')
        });
        const mod = models["modelDetails"]

        if(mod!==null || mod!==undefined){
          let modelData = []
          mod.map((k,i)=>{
            modelData.push({"model_path":k,"model_name":k.match(/[^/]+\.joblib$/)[0].replace('.joblib', '')})
          })
          setModels(modelData);
          setDescriptions(models["descripstions"])
          setCodes(models["codes"])
        }
      } catch (err) {
        console.error('Error fetching batch numbers:', err);
        setIsToasterOpen(true)
        setReqSuccess(false)
        setToasterMessage(err.message)
      }finally{
        setLoading(false)
      }
  };
  getAvailableModel()
  },[])

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>

      <Grid container className={classes.root} style={{paddingBottom: "0px"}}>
        <Grid container className={classes.formContent}>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typo}>
                  Model
                  <span style={{ color: 'red' }}>*</span>
                </Typography> 
                <DropDown
                    options={models.map((m)=>m.model_name)}
                    type='model'
                    emptyTag='Select Model'
                    setSelectOption={setSelectedModel}
                    value={selectedMOdel}
                    // handleError={handleError}
                    onClick= {handleSelectModel}
                    isSingle={true}
                    icon ={<DocumentIcon />}
                    iconClick = {(e)=>showDescriptionDialog(e)}
                  />
                  {errors.model &&
                    <Grid item xs={12} style={{ padding: "0px 8px" }}>
                      <Typography className={classes.errorText}>
                        {errors.model}
                      </Typography>
                    </Grid>
                  }
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      {showDescription && 
        <DescriptionComponent 
          open={showDescription} 
          handleCloseWindow={handleCloseWindow}
          description={selectedDescription}
        />

      }   
        
      </div>
    </ThemeProvider>
  );
}
