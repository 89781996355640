import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginResponse, SECTIONS} from '../models';
import {environment} from '../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import Cookies from "js-cookie";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authChannel = new BroadcastChannel('auth-channel');

  constructor(protected http: HttpClient,protected router : Router,private toast : ToastrService,private spinner:NgxSpinnerService) {
  }


  loginWithUserName(user, rememberMe: boolean) {

    let body = 'client_id=' + user.client_id
      + '&client_secret=' + user.client_secret
      + '&grant_type=' + user.grant_type
      + '&username=' + user.username
      + '&name=' + user.name
      + '&password=' + user.password;
    // Add the "Remember Me" information to the request payload
    if (rememberMe) {
      body += '&remember_me=' + rememberMe;
    }
    Cookies.set("USERNAME", user.username, {
      expires: 1, // Expires in 1 day
      secure: true,
      sameSite: "Strict", // Prevent CSRF attacks
    });
    console.log(body.toString());
    return this.http.post<LoginResponse>(environment.host + '/oauth/token',
      body,
      {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        })
      });
  }

  static loadCredential(credential) {
    // Set cookies with security attributes
    Cookies.set("ACCESS_TOKEN", credential.access_token, {expires: 1,secure: true,sameSite: "Strict",});
    Cookies.set("ACCOUNT", credential.references[0], { expires: 1,secure: true,sameSite: "Strict" });
    Cookies.set("REFRESH_TOKEN", credential.refresh_token, { expires: 1,secure: true, sameSite: "Strict" });
    Cookies.set("ROLE", credential.role, { expires: 1,secure: true, sameSite: "Strict" });
    Cookies.set("USER_ID", credential.id, { expires: 1,secure: true, sameSite: "Strict" });
    Cookies.set("NAME", credential.name, { expires: 1, secure: true, sameSite: "Strict" });
    Cookies.set("PLANID", credential.planId, { expires: 1, secure: true, sameSite: "Strict" });
  }

  refreshToken() {

    const body: URLSearchParams = new URLSearchParams();
    body.set('client_id', 'magma-client');
    body.set('client_secret', 'magma-secret');
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', AuthService.getRefreshToken());

    return this.http.post<LoginResponse>(environment.host + '/oauth/token', body.toString(), {
      headers: new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      })
    });
  }

  static getRefreshToken() {
    return Cookies.get("REFRESH_TOKEN");
  }

  static getAccount() {
    return Cookies.get("ACCOUNT");
  }

  static getUsername() {
    return Cookies.get("USERNAME");
  }

  static getName() {
    return Cookies.get("NAME");
  }

  static getUserId() {
    return Cookies.get("USER_ID");
  }

  static getRole() {
    return +Cookies.get("ROLE");
  }

  static getAccessToken() {
    return Cookies.get("ACCESS_TOKEN");
  }

  clearCredential() {
    Cookies.remove("ACCESS_TOKEN");
    Cookies.remove("ACCOUNT");
    Cookies.remove("REFRESH_TOKEN");
    Cookies.remove("ROLE");
    Cookies.remove("USER_ID");
    Cookies.remove("NAME");
    Cookies.remove("PLANID");
    Cookies.remove("USERNAME")
  }

  signOut(){
    this.authChannel.postMessage({ type: 'logout' });
    const accessToken = AuthService.getAccessToken();
    localStorage.clear();
    this.clearCredential();
    this.spinner.show()
    if (accessToken) {
      return this.http.post<any>(environment.host + '/oauth/revoke',
        {},
        {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${accessToken}`
          })
        }).subscribe(response => {
          this.spinner.hide()
          this.router.navigate(['/login']);
        }, (e) => {
          this.spinner.hide()
          this.toast.error(e.error.message)
        }
      );
    }
  }

  static hasProjectAccess() {
    const role = Cookies.get('ROLE');
    return !['Client_User'].includes(role);
  }

  static isSuperAdmin() {
    const role = Cookies.get('ROLE');
    return parseInt(role, 10) === 1 || parseInt(role, 10) === 5;
  }

  static isMlEngineer(){
    const role = Cookies.get('ROLE');
    return parseInt(role, 10) == 1123;
  }

  static isAccountUser() {
    const role = Cookies.get('ROLE');
    return parseInt(role, 10) === 10;
  }

  static hasCorporateMenuAccess() {
    const role = Cookies.get('ROLE');
    return parseInt(role, 10) !== 1000 && parseInt(role, 10) !== 10000;
  }

  static hasUserCreationAccess() {
    const role = Cookies.get('ROLE');
    return !['Client_User'].includes(role);
  }

  static hasAccess(section, accessType) {
    const role = Cookies.get('ROLE');
    if (section==SECTIONS.D && accessType=="DELETE"){
      return AuthService.isSuperAdmin()
    }
    return parseInt(role, 10) <= 5;
  }

  static hasNewUserAccess(section, accessType) {
    const role = Cookies.get('ROLE');
    if (accessType === 'VIEW' || accessType === 'ADD' || accessType === 'CLONE') {
      return true;
    }
  }

  static getUserRoleId():number {
    return parseInt(Cookies.get('ROLE'), 10);
  }

  isTestAutomationUser(): boolean {
    const role = AuthService.getUserRoleId();
    return role >= 10 && role < 100;
  }

  isMlEngineer(): boolean {
    const role = AuthService.getUserRoleId();
    return role == 1123;
  }

  getUserGuide(){
    return this.http.get<any>(
      `${environment.host}/ums/${Cookies.get("USER_ID")}/get-guide`,
      {
        headers: new HttpHeaders(
          {
            'content-Type': 'application/json',
            Authorization: "Bearer " + Cookies.get("ACCESS_TOKEN"),
          }
        )
      }
    )
  }
}
