import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import {primaryRed} from 'src/components/colors';
import {environment} from 'src/environments/environment';
import axios from 'axios';

export function DeleteProductType(props) {
  const {productName, id} = props.productType.productType;

  const handleDelete = async () => {
      const accessToken = localStorage.getItem('ACCESS_TOKEN');
      await axios.delete(`${environment.host}/core/product/productTypes/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log('Product type deleted successfully');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Failed to delete product type:', error);
      });
      props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={"md"}
      style={{width: "650px"}}
      BackdropProps={{
        style: {
          backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
          opacity: "0.2",
        },
      }}
      PaperProps={{
        style: {
          boxShadow: "inherit",
        },
      }}
    >
      <DialogContent>
        <Typography>
          Do you want to delete the product type{' '}
          <span style={{fontWeight: 'bold'}}>{productName}</span> ?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="secondary"
          style={{
            color: 'black',
            backgroundColor: 'transparent',
            border: '1px solid black',
            borderRadius: '8px',
            marginRight: '10px'
          }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            color: 'white',
            borderRadius: '8px',
            boxShadow: 'none',
            backgroundColor: primaryRed,
          }}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
