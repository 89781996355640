import {Component, OnInit, Optional} from '@angular/core';
import {SideNavService} from '../../layouts/side-menu/sidenav/sidenav.service';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../../account/account.service'
import {AngularFireDatabase} from '@angular/fire/database';
import {formatDate} from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device-troubleshot',
  templateUrl: './device-troubleshot.component.html',
  styleUrls: ['./device-troubleshot.component.css']
})
export class DeviceTroubleshotComponent implements OnInit {

  constructor(private sideNavService: SideNavService, private accountService: AccountService,
              private router: ActivatedRoute, private spinner: NgxSpinnerService, @Optional() private firebase: AngularFireDatabase) {
  }

  deviceId;
  error
  deviceArray = []
  rowData
  connectedSensor = []
  connectedActuator = []
  isRawDataError = false
  validity = false
  inputRaw
  inputValidation = false
  expectedFormat
  sensorInDevice
  actuatorInDevice
  rawDataTemplate
  newSensorData
  currentTime
  sensorValue
  lastRawDataValue

  ngOnInit() {

    this.router.params.subscribe(params => {
      this.deviceId = params['deviceId'];
    });
    this.spinner.show();

    this.getDeviceData()
    if(environment.useFirebase){
              this.getDataFromFirebase();
            }
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }


  getDeviceData() {
    this.accountService.getDevice(this.deviceId)
      .subscribe(res => {
        this.deviceArray = res.content
        this.rowData = res.content.lastRawData
        this.connectedSensor = res.content.sensorCodes
        this.sensorInDevice = res.content.sensors
        this.rawDataTemplate = this.connectedSensor.map((sensor, index) => {
          return index + '-' + sensor
        }).join(';')
        console.log(this.connectedSensor)
        this.actuatorInDevice = res.content.actuatorCodes
        this.connectedActuator = res.content.actuatorCodes.slice().sort()
        this.spinner.hide()
        this.createExpectedMessageFormat(res.content.sensors)
        this.deviceRowData(res.content.lastRawData)
        if (res.content.lastRawData && this.lastRawDataValidation(this.deviceRowData(res.content.lastRawData), this.deviceRowData(this.expectedFormat))) {
          this.validity = true
        } else {
          this.validity = false
        }

      }, (e) => {
        if (e.error.errorCode === 'E1011') {
          this.error = 'Device Not Found';
        }
        this.spinner.hide();
      })

  }

  deviceRowData(value) {
    if (value) {
      var rawData = value.split(';')
      var splitRawData = rawData ? rawData.filter(value => value != "") : ""
      var innerSplit = splitRawData.map((item) => {
        if (item.includes('|')) {
          var newInnerSplit = item.split('|')
          return newInnerSplit.map(i => i.split(':')[0])
        } else {
          return item.split(':')[0]
        }
      })
      var inputRawData = innerSplit.flat().map(i => i)
      this.sensorValue = splitRawData.map(i => i.split(':')).map(i => parseFloat(i[1]))
      return inputRawData
    }
  }

  checkInputDataValidity() {
    let inputValue = document.getElementById('inputRawData') as HTMLInputElement;
    let inputFormat = this.deviceRowData(inputValue.value)
    console.log(this.sensorValue)
    var validation = !this.sensorValue.some(n => {
      return Number.isNaN(n)
    })
    console.log(this.lastRawDataValidation(inputFormat, this.deviceRowData(this.expectedFormat)), validation)
    if (this.lastRawDataValidation(inputFormat, this.deviceRowData(this.expectedFormat)) && validation) {

      this.inputValidation = true
    } else {
      this.inputValidation = false
    }


  }

  lastRawDataValidation(array1, array2) {
    let areArraysEqual
    const sortedArray1 = array1.slice().sort()
    const sortedArray2 = array2.slice().sort()
    if (sortedArray1.includes('DT') || sortedArray1.includes('ZZ')) {
      areArraysEqual = sortedArray1.some((element, index) => element === sortedArray2[index]);
    } else {
      if (sortedArray1.length === sortedArray2.length) {
        areArraysEqual = sortedArray1.every((element, index) => element === sortedArray2[index]);
      }
    }

    return areArraysEqual

  }

  splitArray(data) {
    var arraySplit = data.split(';')
    const innerSplit = arraySplit.map(item => item.split(/[-:]/))
    return innerSplit.map(item => {
      return item[1]
    }).slice().sort()

  }

  createExpectedMessageFormat(data) {
    if (data == '') {
      if (this.connectedSensor.length == 0) {
        this.expectedFormat = 'There is no sensor connected.'
      } else {
        this.expectedFormat = this.connectedSensor.map((device, index) => {
          return index + "-" + device + ":0"
        }).join(';')
      }
    } else {
      const deviceSensor = data.map((device) => {
        return device.code
      }).slice().sort()
      const sensorValue = {}
      data.map((device) => {
          sensorValue[device.code] = device.value
        }
      )

      const differenceArray = this.connectedSensor.filter((element) => !deviceSensor.includes(element));
      // console.log('ghjk0',differenceArray)

      this.expectedFormat = this.connectedSensor.map((element, index) => `${index}-${element}:${sensorValue[element] ? sensorValue[element] : 0}`).join(';');


    }
  }

  getDataFromFirebase() {
    const data = this.firebase.list('devices/' + this.deviceId).valueChanges();
    const deviceDetails = this.firebase.list('deviceDetails/' + this.deviceId).valueChanges()
    deviceDetails.subscribe((item) => {
      item.forEach((device) => {
        this.rowData = device
      })
    })
    data.subscribe((item) => {
      this.newSensorData = item;
      this.newSensorData.forEach((sensor, index) => {
        const dateObject = new Date(sensor.time.millis);
        const timeObject = dateObject.toTimeString().split(/\s/)[0];
        const dateStr = formatDate(dateObject, 'yyyy-MM-dd', 'lk');
        const dateTime = dateStr + ' ' + timeObject;
        sensor.time = dateTime;
        this.currentTime = sensor.time
      });
    })
    this.spinner.hide();
  }


}
