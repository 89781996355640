import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, Grid, Typography} from "@material-ui/core"
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Pie} from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import {dataRestructure} from '../data-table/dataTable';
import {FiberManualRecordIcon} from '../../../../components/Icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "20px",
    padding: "20px",
    borderRadius: "20px"
  },
  text: {
    fontSize: "14px",
    color: "#5E5873",
    marginBottom: "10px"
  },
  text2: {
    fontSize: "14px",
    marginBottom: "10px",
    marginLeft: "20px",
    marginTop: "2px"
  },
}));

export function TestSuccessCriteria(props) {
  const classes = useStyles()
  const percentageArray = [{color: "#000000", val: "0-20"},
    {color: "#838383", val: "21-40"},
    {color: "#CAC9C9", val: "41-60"},
    {color: "#DADADA", val: "61-80"},
    {color: "#C2C9D1", val: "81-100"}
  ]

  const [data, setData] = useState({
    datasets: [
      {
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          '#000000',
          '#838383',
          '#CAC9C9',
          '#DADADA',
          '#C2C9D1',
        ],
        borderWidth: 0,
      },
    ],
  })

  useEffect(() => {
    if (props.mainTestTitle !== "") {
      let structuredData = dataRestructure(props.summary, props.mainCase);
      console.log(structuredData)

      if (structuredData.length > 0 && structuredData[0] !== null && structuredData[0] !== undefined) {
        let data = structuredData[0]
        const result = [];
        for (const device in data) {
          for (const testCase in data[device]) {
            for (const testType in data[device][testCase]) {
              const tests = data[device][testCase][testType];
              for (const test of tests) {
                result.push(test);
              }
            }
          }
        }
        console.log(result);


        const groupedData: { [key: string]: { totalTests: number; successes: number } } = result.reduce((acc, curr) => {
          const {deviceId, testResult} = curr;
          if (!acc[deviceId]) {
            acc[deviceId] = {totalTests: 0, successes: 0};
          }
          acc[deviceId].totalTests++;
          if (testResult === "SUCCESS") {
            acc[deviceId].successes++;
          }
          return acc;
        }, {});

        // Calculate the success rate for each device
        const successRates = Object.entries(groupedData).map(([deviceId, {totalTests, successes}]) => ({
          deviceId,
          successRate: (successes / totalTests) * 100,
        }));
        console.log(successRates)
        // Categorize the success rates into the specified ranges
        const categorizedSuccessRates = successRates.map(({deviceId, successRate}) => {
          let range;
          if (successRate <= 20) {
            range = "0-20";
          } else if (successRate <= 40) {
            range = "21-40";
          } else if (successRate <= 60) {
            range = "41-60";
          } else if (successRate <= 80) {
            range = "61-80";
          } else {
            range = "81-100";
          }
          return {deviceId, successRate, range};
        });

        const ranges = ["0-20", "21-40", "41-60", "61-80", "81-100"];

        const testSuccessRate = ranges.reduce((acc, range) => {
          const count = categorizedSuccessRates.filter(item => item.range === range).length;
          acc[range] = count;
          return acc;
        }, {});

        console.log(testSuccessRate)

        setData(
          {
            datasets: [
              {
                data: ranges.map(range => testSuccessRate[range] || 0),
                backgroundColor: [
                  '#000000',
                  '#838383',
                  '#CAC9C9',
                  '#DADADA',
                  '#C2C9D1',
                ],
                borderWidth: 0,
              },
            ],
          }
        )
      }
    }

  }, [props.mainCase, props.summary])
  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant="h6">TEST SUCCESS CRITERIA</Typography>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              md={9}
              style={{marginTop: "20px", marginBottom: "20px"}}
            >
              {props.summary.length > 0 ? (
                <Pie
                  data={data}
                  options={{
                    maintainAspectRatio: true,
                    responsive: true,
                    tooltips: {enabled: false},
                    plugins: {
                      labels: {
                        render: "percentage",
                        fontColor: "white",
                        position: "border",
                        fontSize: 14,
                      },
                    },
                  }}
                />
              ) : (
                <div style={{textAlign: "center"}}>No Data</div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} style={{textAlign: "left", marginTop: "20px", marginBottom: "20px"}}
          >
            <Typography className={classes.text}>Test success rate</Typography>
            {percentageArray.map((obj, index) => {
              return (
                <Grid container key={index}>
                  <Grid item xs={1} md={1} style={{marginTop: "5px"}}>
                    <FiberManualRecordIcon color={obj.color}/>
                  </Grid>
                  <Grid item xs={11} md={11}>
                    <Typography className={classes.text2}>{obj.val}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
