import React, { useEffect, useState } from 'react';
import { 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    Divider, 
    Grid, 
    IconButton, 
    Typography
} from '@material-ui/core';
import { CloseIconLargeTwo } from 'src/components/Icons';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { fetchDevices } from '../components/apiHelper';

export const DevicePopup = ({ 
    open, 
    close, 
    batchNumber
}) => {
    const [devices, setDevices] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredDevices, setFilteredDevices] = useState([]);

    useEffect(() => {
        const getDevices = async () => {
            try {
                const devices = await fetchDevices(batchNumber);
                setDevices(devices);
                setFilteredDevices(devices);
            } catch (err) {
                console.error('Error fetching devices:', err);
            }
        };
        getDevices();
    }, [batchNumber]);

    useEffect(() => {
        const results = devices.filter(device => 
            device.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredDevices(results);
    }, [searchQuery, devices]);

    return (
        <Dialog
            open={open}
            onClose={close}
            fullWidth={true}
            maxWidth={"md"}
            style={{width: "600px", height:"600px"}}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    boxShadow: 'inherit'
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
                    opacity: '1',
                },
            }}
        >
            <Grid container
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: "1",
                    boxShadow: "0px 4px 30px #00000008",
                }}
            >
                <Grid item xs={10}>
                    <DialogTitle>
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            Batch : {batchNumber}
                        </Typography>
                    </DialogTitle>
                </Grid>
                <Grid item xs={2}
                    style={{
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center'
                    }}
                >
                    <IconButton onClick={close}>
                        <CloseIconLargeTwo/>
                    </IconButton>
                </Grid>
            </Grid>
            <Divider/>
            <DialogContent 
                style={{ 
                    margin:"0px",
                    padding:"24px 12px 24px 30px", 
                    fontSize: '13px' 
                }}>
                <Grid container  style={{marginBottom:"20px"}}>
                    <Grid item md={6}></Grid>
                    <Grid item xs={12} md={6}>
                        <SearchBar 
                            placeholder="-Search-" 
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container  style={{marginBottom:"25px", paddingLeft: "10px"}}>
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                        Entity ID
                    </Typography>
                </Grid>
                
                <Grid container spacing={3} style={{width: "500px"}}>
                    {filteredDevices.map((device, index) => (
                        <Grid item xs={6} md={4} 
                            key={index} 
                            style={{padding:"10px 10px 10px 20px"}}
                        > 
                            {device}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>    
        </Dialog>
    );
};
