import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../../account/account.service';
import {SideNavService} from '../../layouts/side-menu/sidenav/sidenav.service';
import {formatDate} from '@angular/common';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import * as _ from 'underscore';
import * as c3 from 'c3';
import {timeDurationToReadableForm} from '../../utils';


@Component({
  selector: 'app-kit-history',
  templateUrl: './kit-history.component.html',
  styleUrls: ['./kit-history.component.css']
})
export class KitHistoryComponent implements OnInit {

  kitId;
  kitHistory = [];
  toDate = new Date();
  fromDate = new Date();
  dateRangeType = 0;
  fromDateStr = formatDate(this.fromDate, 'yyyy-MM-dd', 'lk');
  toDateStr = formatDate(this.toDate, 'yyyy-MM-dd', 'lk');
  isCustomDateSet = false;
  isGraph = false; // change at last
  p = 0;
  c3Chart: any;
  count = 10;
  xPointsCount = 8;
  x = [];
  y = [];
  z = [];
  readableDuration = timeDurationToReadableForm;


  constructor(private router: ActivatedRoute, private sideNavService: SideNavService, private spinner: NgxSpinnerService, private accountService: AccountService) {
  }

  ngOnInit() {

    this.router.params.subscribe(params => {
      this.kitId = params['kitId'];
    });
    this.getkitHistory(this.kitId);

    console.log("kit ID", this.kitId);

    this.spinner.show();
    const today = new Date();
    this.selectPeriod();
  }


  getkitHistory(id) {
    this.spinner.show();
    this.accountService.getOfflineAlertsOfKit(id, this.fromDateStr, this.toDateStr).subscribe(response => {
      this.kitHistory = _.uniq((response.content).reverse(), 'startTime').reverse();
      if (this.x.length > 0 || this.y.length > 0 || this.z.length > 0) {
        console.log("clearing data");
        this.x.splice(0, this.x.length);
        this.y.splice(0, this.y.length);
        this.z.splice(0, this.z.length);

      }
      this.kitHistory.map(element => {
        this.x.push(element.endTime, element.endTime, element.startTime, element.startTime);
        this.y.push(null, 10, 10, null);
        this.z.push(null, this.readableDuration(element.time), this.readableDuration(element.time), null);
      });
      console.log("elapsedTime", this.x, this.y);
      this.drawGraph();
    }, error => {
      console.log("error", error);
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });

  }

  public pageChange(event: number): void {
    this.p = event;
  }

  selectPeriod() {
    const today = new Date();
    switch (this.dateRangeType) {
      case 0:
        this.toDate = today;
        this.fromDate = today;
        break;
      case 1:
        this.toDate = today;
        this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        break;
      case 2:
        this.toDate = today;
        this.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        break;
    }
    this.toDateStr = formatDate(this.toDate, 'yyyy-MM-dd', 'lk');
    this.fromDateStr = formatDate(this.fromDate, 'yyyy-MM-dd', 'lk');
    // this.selectedSensor = index;
    this.getkitHistory(this.kitId);
    this.isCustomDateSet = false;
  }

  changeDateRange(rangeType: number) {
    this.dateRangeType = rangeType;
    if (this.dateRangeType !== 3) {
      this.selectPeriod();
    }
    console.log("selected range", this.dateRangeType);
  }

  getSensorDataForCustomRange() {
    if (this.isCustomDateSet) {
      this.selectPeriod();
    } else {
      this.isCustomDateSet = true;
    }
  }

  exportFunction() {
    const modifiedSet = _.map(this.kitHistory, e => {
      return _.pick(e, ['startTime', 'endTime', 'time']);
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: ['Start Time', 'End Time', 'Duration'],
      nullToEmptyString: true,
    };


    const csv = new Angular5Csv(modifiedSet,
      this.kitId + '-Offline-History-Report', opt);

  }


  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  drawGraph() {
    let a = this.z;
    console.log("drawing graph");
    this.spinner.show();
    this.c3Chart = c3.generate(
      {
        bindto: '#chart',
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: [['x'].concat(this.x.reverse()), ['Duration'].concat(this.y.reverse())]
        },
        subchart: {
          show: true
        },
        axis: {
          x: {
            show: true,
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },

            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed
              fit: true,
              culling: {
                max: 10,
              },
              count: this.xPointsCount,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: 'Duration',
              position: 'outer-middle'
            }
          },


        },
        tooltip: {
          format: {
            value: function (value, ratio, id, index) {
              if (a[index] !== null) {
                return a[index];
              }

            }
          },
        }
      });


    this.spinner.hide();
    return false;
  }
}
