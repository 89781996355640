import {Card, FormControl, Grid, Typography} from "@material-ui/core";
import * as React from "react";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import SensorCard from "./sensor-card/sensorCard";
import {environment} from "src/environments/environment";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "50px",
    padding: "20px",
    borderRadius: "20px",
  },
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
}));

export function Sensors(props) {
  const [sensors, setSensors] = React.useState([]);
  const [sensorDetails, setSensorDetails] = React.useState({});
  const [selectedSensor, setSelectedSensor] = React.useState("");

  const [failure, setFailure] = React.useState(0);
  const [active, setActive] = React.useState(0);
  const [wrong, setWrong] = React.useState(0);

  //Read selected sensor
  const readSelectedSensor = (e) => {
    setSelectedSensor(e.target.value);

    //change active, wrong and failed sensor values when select different sensor
    setFailure(
      sensorDetails[e.target.value]["sensor failure"]
        ? sensorDetails[e.target.value]["sensor failure"]
        : 0
    );
    setWrong(
      sensorDetails[e.target.value]["wrong valued sensors"]
        ? sensorDetails[e.target.value]["wrong valued sensors"]
        : 0
    );
    setActive(
      sensorDetails[e.target.value]["active sensors"]
        ? sensorDetails[e.target.value]["active sensors"]
        : 0
    );
  };
  React.useEffect(() => {
    //getting states to default values
    setSelectedSensor("");
    setSensorDetails({});
    setActive(0);
    setFailure(0);
    setWrong(0);
    const userId = localStorage.getItem("USER_ID");
    if (props.coperateId !== "") {
        axios.get(
        `${environment.host}/user/${userId}/corporate/${props.coperateId}/sensorSummary`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
        .then((response) => {
          if (response.data.content) {
            const allPropertySensors = Object.keys(
              response.data.content.propertySensorSummaryMap
            );
            setSensors(allPropertySensors);
            setSelectedSensor(allPropertySensors[0]);
            const finalObj = response.data.content.propertySensorSummaryMap;
            setSensorDetails(finalObj);

            // Checking if finalObj exists and is not an empty object
            if (finalObj && Object.keys(finalObj).length > 0) {
              // Setting up default sensor's values. Default sensor is the first element in sensorsInCorporate array
              setFailure(
                finalObj[allPropertySensors[0]]["sensor failure"]
                  ? finalObj[allPropertySensors[0]]["sensor failure"]
                  : 0
              );
              setWrong(
                finalObj[allPropertySensors[0]]["wrong valued sensors"]
                  ? finalObj[allPropertySensors[0]]["wrong valued sensors"]
                  : 0
              );
              setActive(
                finalObj[allPropertySensors[0]]["active sensors"]
                  ? finalObj[allPropertySensors[0]]["active sensors"]
                  : 0
              );
            } else {
              // Handle the case when finalObj is either undefined or an empty object
              console.log("finalObj is undefined or empty");
            }
          } else {
            setSensors([]);
            setSensorDetails({});
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [props.coperateId]);

  const classes = useStyles();
  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant="h6">SENSOR FAILURES</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={12}
            style={{textAlign: "left", marginTop: "20px"}}
          >
            <Typography
              style={{fontWeight: 400, fontSize: "14px", marginTop: "10px"}}
            >
              Select the sensor
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={12}
            style={{textAlign: "left", marginTop: "20px"}}
          >
            <FormControl className={classes.input}>
              <NativeSelect
                id="demo-customized-select-native"
                value={selectedSensor}
                className={classes.input}
                onChange={(e) => readSelectedSensor(e)}
                input={<BootstrapInput/>}
              >
                {sensors.map((sensor, index) => {
                  return (
                    <option value={sensor} key={index}>
                      {sensor}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{textAlign: "center", marginTop: "20px"}}
          >
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                marginTop: "10px",
              }}
            >
              Details of Selected Sensor
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <a
              href={
                "/devices/" +
                props.coperateId +
                "/SensorFailure&sensor/" +
                selectedSensor
              }
              style={{textDecoration: "none"}}
            >
              <SensorCard name="Sensor Failure" value={failure}/>
            </a>
          </Grid>
          <Grid item xs={12} md={6}>
            <a
              href={
                "devices/" +
                props.coperateId +
                "/ActiveSensors&sensor/" +
                selectedSensor
              }
              style={{textDecoration: "none"}}
            >
              <SensorCard name="Active Sensors" value={active}/>
            </a>
          </Grid>
          <Grid item xs={12} md={6}>
            <a
              href={
                "devices/" +
                props.coperateId +
                "/WrongValued&sensor/" +
                selectedSensor
              }
              style={{textDecoration: "none"}}
            >
              <SensorCard name="Wrong Valued" value={wrong}/>
            </a>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

//Bootstrap like dropdown component
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#F4F5F7",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);
