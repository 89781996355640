import * as React from "react";
import {createTheme, Grid, IconButton, makeStyles, Theme, ThemeProvider, Typography} from "@material-ui/core";
import { primaryGray, white } from "src/components/colors";
import { ExpandIconTwo } from "src/components/Icons";
import { useState } from "react";
import { SetupSenzMaticaComponent } from "../setup-senzmatica/SetupSenzMaticaComponent";
import { MaintenanceComponent } from "../maintenance/MaintenanceComponent";


export function SenzMaticaComponent() {
  const classes = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });

  const [openIcon1, setOpenIcon1] = useState(true);
  const [openIcon2, setOpenIcon2] = useState(false);

  return (
  <ThemeProvider theme={theme}>
    <div className={`testing ${classes.root}`}>
        
        <Grid container 
          style={{
            paddingTop: "20px", 
            paddingRight: "100px",
            marginBottom: theme.spacing(5)}}
        >
          <Grid container direction="column" alignItems="flex-start">
            <Grid item 
              style={{
                marginBottom: theme.spacing(1), 
                color: "#30335b"}}
            >
              <h3>Welcome to SenzMatica AIoT Platform! Create opportunities for growth </h3>
            </Grid>
            <Grid item 
              style={{
                fontSize:"15px", 
                color: primaryGray}}
            >
              To help you get started quickly, follow these simple steps,
            </Grid>
          </Grid>
        </Grid>

        <Grid container className = {classes.container}>
          <Grid item xs={4} md={4}>
            <Typography className= {classes.dropdownGrid}>
                System Preparation
            </Typography>
          </Grid>
          <Grid item xs={7} md={7}></Grid>
          <Grid item xs={1} md={1}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center"}}
          >
            <IconButton className={classes.iconButtonStyle}
              onClick={() => setOpenIcon1(!openIcon1)}
            >
              <ExpandIconTwo rotation={openIcon1}/>
            </IconButton>
          </Grid>
        </Grid>

        {openIcon1 === true && (
          <SetupSenzMaticaComponent />
        )}

        {/* -------------------------------------------------*/}

        <Grid container className = {classes.container}>
          <Grid item xs={4} md={4}>
            <Typography className= {classes.dropdownGrid}>
                Maintenance
            </Typography>
          </Grid>
          <Grid item xs={7} md={7}></Grid>
          <Grid item xs={1} md={1}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center"}}
          >
            <IconButton className={classes.iconButtonStyle}
              onClick={() => setOpenIcon2(!openIcon2)}
            >
              <ExpandIconTwo rotation={openIcon2} />
            </IconButton>
          </Grid>
        </Grid>

        {openIcon2 === true && (
          <MaintenanceComponent />
        )}

    </div>
  </ThemeProvider>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: "0.55s",
    textAlign: "left",
    margin: "40px 100px 40px 0px",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    backgroundColor: white,
    padding : "15px 20px",
    boxShadow: "0 2px 8px #0000000A",
    borderRadius: "5px",
    marginTop: "16px"
  },
  dropdownGrid : {
    color: primaryGray,
    opacity: "1",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    fontSize: "18px",
    fontWeight: 600
  },
  iconButtonStyle:{
    backgroundColor: "ButtonShadow",
    borderRadius: "80%",
    boxShadow: "0px 3px 6px #00000029",
    padding: "3px",
  }
}));
