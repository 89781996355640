import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {useState} from "react";
import {CloseIcon, CloseIconColor, ViewIcon} from "../../Icons";
import {filterTitle} from "../../colors";
import ReviewHistory from "./ReviewHistory";

const HistoryDevice = (props) => {
  const device = props.device.device;
  const history = props.device.device.deviceParameterConfiguration.updateHistory ? props.device.device.deviceParameterConfiguration.updateHistory : [];


  const [isOpen, setIsOpen] = useState(true);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [data, setData] = useState([]);

  function handleReviewClick(data) {
    setIsReviewOpen(true);
    setData(data);
  }

  const handleClose = () => {
    setIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "60%", height: "70%"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.4",
          },
        }}
        PaperProps={{
          // className: classes.paper,
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            boxShadow: "5px",
          }}
        >
          <Grid
            item
            style={{paddingLeft: "12px", fontSize: "18px"}}
            xs={10}
            md={10}
          >
            <h3
              style={{
                fontWeight: "bold",
                color: filterTitle,
                fontSize: "16px",
                lineHeight: "25px",
              }}
            >
              {device.id} -{" >"}
              {device.deviceParameterConfiguration.productType}
            </h3>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{display: "flex", justifyContent: "end"}}
          >
            <IconButton onClick={handleClose}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>

        {isReviewOpen === true ? (
          <ReviewHistory
            data={data}
            open={isReviewOpen}
            close={() => {
              setIsReviewOpen(false);
            }}
            device={device}
          />
        ) : (
          <DialogContent style={{width: "100%"}}>
            <Grid container>
              <Grid item xs={12} md={12} style={{paddingTop: "10px"}}>
                <TableContainer>
                  <Table
                    size="medium"
                    style={{
                      boxShadow: "initial",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              DATE
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              ACTION
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              ACTION BY
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <div style={{display: "inline-flex"}}>
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "16px",
                              }}
                            >
                              ACTION
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {history &&
                        history.length > 0 &&
                        history
                          .slice()
                          .reverse()
                          .map((item, i) => (
                            <TableRow
                              style={{
                                textAlign: "center",
                                borderBottomColor: "white",
                                borderTopColor: "white",
                                backgroundColor:
                                  i % 2 === 0 || i / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                              key={i}
                            >
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.updatedDate.split(" ")[0]}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.networkAndCommunication
                                  ? Object.keys(
                                    item.networkAndCommunication
                                  ).join(", ")
                                  : ""}{" "}
                                <br/>
                                {item.topicFormat
                                  ? Object.keys(item.topicFormat).join(", ")
                                  : ""}{" "}
                                <br/>
                                {item.messageFormat
                                  ? Object.keys(item.messageFormat).join(", ")
                                  : ""}
                              </TableCell>

                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {item.actionBy}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleReviewClick(item)}
                                >
                                  <ViewIcon/>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default HistoryDevice;
