import {IconButton, makeStyles, Theme} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {environment} from "src/environments/environment";
import {ArrowIcon} from "src/components/Icons";

interface deviceDataProps {
  deviceId: string;
}

function Step3ApiCall(props: deviceDataProps) {
  const classes = useStyles();
  const baseUrl = environment.host;
  const [apiLink, setApiLink] = useState("");
  const [urlValue, setUrlValue] = useState("");

  useEffect(() => {
    setApiLink(`${baseUrl}/swagger-ui.html#/core-api/getSensorsDataHistoryUsingGET`);
  }, []);

  useEffect(() => {
    setUrlValue(`${baseUrl}/core/device/${props.deviceId}/getAllDirectData`);
  }, []);


  function openSwaggerUI() {
    window.open(apiLink, "_blank")
  }

  return <div className={`testing`}>
    <div className={classes.formContainer}>
      <div className={classes.textInputContainer}>
        <input
          className={classes.formInput}
          type="text"
          value={urlValue}
          placeholder="Api Link"
          readOnly={true}
        />
        <div className={classes.linkContainer}>
          <IconButton style={{padding: "0px"}} className={classes.disableRipple} onClick={openSwaggerUI}>
            <label className={classes.swaggerLabel}> Go to Swagger</label>
            <ArrowIcon/>
          </IconButton>
        </div>
      </div>
    </div>
  </div>
};

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginTop: "50px",
  },
  textInputContainer: {
    height: "200px",
    flexDirection: "column",
    marginLeft: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formInput: {
    height: "38px",
    width: "580px",
    borderRadius: "5px",
    padding: "10px 5px",
    fontFamily: "'Poppins', sans-serif",
    border: "1px solid silver"
  },
  linkContainer: {
    display: "flex",
    width: "580px",
    margin: "0px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
    flexDirection: "row",
  },
  swaggerLabel: {
    color: "#2A7BEC",
    fontSize: "16px",
    cursor: "pointer"
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default Step3ApiCall;
